(function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);var f=new Error("Cannot find module '"+o+"'");throw f.code="MODULE_NOT_FOUND",f}var l=n[o]={exports:{}};t[o][0].call(l.exports,function(e){var n=t[o][1][e];return s(n?n:e)},l,l.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({1:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _ = require("..");

var _2 = _interopRequireDefault(_);

var _escapeHtml = require("../utils/escape-html");

var _escapeHtml2 = _interopRequireDefault(_escapeHtml);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var AGREEMENT_URL = '<a href="%(url)s" target="_blank">%(agreement)s</a>';

var RegisterLegalFootnote = function RegisterLegalFootnote(props) {
  var errors = props.errors,
      privacyPolicy = props.privacyPolicy,
      termsOfService = props.termsOfService,
      onPrivacyPolicyChange = props.onPrivacyPolicyChange,
      onTermsOfServiceChange = props.onTermsOfServiceChange;


  var termsOfServiceId = _2.default.get("TERMS_OF_SERVICE_ID");
  var termsOfServiceUrl = _2.default.get("TERMS_OF_SERVICE_URL");

  var privacyPolicyId = _2.default.get("PRIVACY_POLICY_ID");
  var privacyPolicyUrl = _2.default.get("PRIVACY_POLICY_URL");

  if (!termsOfServiceId && !privacyPolicyId) return null;

  return _react2.default.createElement(
    "div",
    null,
    _react2.default.createElement(LegalAgreement, {
      agreement: gettext("the terms of service"),
      checked: termsOfService !== null,
      errors: errors.termsOfService,
      url: termsOfServiceUrl,
      value: termsOfServiceId,
      onChange: onTermsOfServiceChange
    }),
    _react2.default.createElement(LegalAgreement, {
      agreement: gettext("the privacy policy"),
      checked: privacyPolicy !== null,
      errors: errors.privacyPolicy,
      url: privacyPolicyUrl,
      value: privacyPolicyId,
      onChange: onPrivacyPolicyChange
    })
  );
};

var LegalAgreement = function LegalAgreement(props) {
  var agreement = props.agreement,
      checked = props.checked,
      errors = props.errors,
      url = props.url,
      value = props.value,
      onChange = props.onChange;


  if (!url) return null;

  var agreementHtml = interpolate(AGREEMENT_URL, { agreement: (0, _escapeHtml2.default)(agreement), url: (0, _escapeHtml2.default)(url) }, true);
  var label = interpolate(gettext("I have read and accept %(agreement)s."), { agreement: agreementHtml }, true);

  return _react2.default.createElement(
    "div",
    { className: "checkbox legal-footnote" },
    _react2.default.createElement(
      "label",
      null,
      _react2.default.createElement("input", {
        checked: checked,
        type: "checkbox",
        value: value,
        onChange: onChange
      }),
      _react2.default.createElement("span", { dangerouslySetInnerHTML: { __html: label } })
    ),
    errors && errors.map(function (error, i) {
      return _react2.default.createElement(
        "div",
        { className: "help-block errors", key: i },
        error
      );
    })
  );
};

exports.default = RegisterLegalFootnote;

},{"..":302,"../utils/escape-html":383,"react":"react"}],2:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _ = require("..");

var _2 = _interopRequireDefault(_);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var StartSocialAuth = function StartSocialAuth(props) {
  var buttonClassName = props.buttonClassName,
      buttonLabel = props.buttonLabel,
      formLabel = props.formLabel,
      header = props.header,
      labelClassName = props.labelClassName;

  var socialAuth = _2.default.get("SOCIAL_AUTH");

  if (socialAuth.length === 0) return null;

  return _react2.default.createElement(
    "div",
    { className: "form-group form-social-auth" },
    _react2.default.createElement(FormHeader, { className: labelClassName, text: header }),
    _react2.default.createElement(
      "div",
      { className: "row" },
      socialAuth.map(function (_ref) {
        var id = _ref.id,
            name = _ref.name,
            button_text = _ref.button_text,
            button_color = _ref.button_color,
            url = _ref.url;

        var className = "btn btn-block btn-default btn-social-" + id;
        var style = button_color ? { color: button_color } : null;
        var finalButtonLabel = button_text || interpolate(buttonLabel, { site: name }, true);

        return _react2.default.createElement(
          "div",
          { className: buttonClassName || "col-xs-12", key: id },
          _react2.default.createElement(
            "a",
            { className: className, style: style, href: url },
            finalButtonLabel
          )
        );
      })
    ),
    _react2.default.createElement("hr", null),
    _react2.default.createElement(FormHeader, { className: labelClassName, text: formLabel })
  );
};

var FormHeader = function FormHeader(_ref2) {
  var className = _ref2.className,
      text = _ref2.text;

  if (!text) return null;
  return _react2.default.createElement(
    "h5",
    { className: className || "" },
    text
  );
};

exports.default = StartSocialAuth;

},{"..":302,"react":"react"}],3:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _ajax = require("../services/ajax");

var _ajax2 = _interopRequireDefault(_ajax);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var AcceptAgreement = function (_React$Component) {
  _inherits(AcceptAgreement, _React$Component);

  function AcceptAgreement(props) {
    _classCallCheck(this, AcceptAgreement);

    var _this = _possibleConstructorReturn(this, (AcceptAgreement.__proto__ || Object.getPrototypeOf(AcceptAgreement)).call(this, props));

    _this.handleDecline = function () {
      if (_this.state.submiting) return;

      var confirmation = confirm(gettext("Declining will result in immediate deactivation and deletion of your account. This action is not reversible."));
      if (!confirmation) return;

      _this.setState({ submiting: true });

      _ajax2.default.post(_this.props.api, { accept: false }).then(function () {
        location.reload(true);
      });
    };

    _this.handleAccept = function () {
      if (_this.state.submiting) return;

      _this.setState({ submiting: true });

      _ajax2.default.post(_this.props.api, { accept: true }).then(function () {
        location.reload(true);
      });
    };

    _this.state = { submiting: false };
    return _this;
  }

  _createClass(AcceptAgreement, [{
    key: "render",
    value: function render() {
      return _react2.default.createElement(
        "div",
        null,
        _react2.default.createElement(
          "button",
          {
            className: "btn btn-default",
            disabled: this.state.submiting,
            type: "buton",
            onClick: this.handleDecline
          },
          gettext("Decline")
        ),
        _react2.default.createElement(
          "button",
          {
            className: "btn btn-primary",
            disabled: this.state.submiting,
            type: "buton",
            onClick: this.handleAccept
          },
          gettext("Accept and continue")
        )
      );
    }
  }]);

  return AcceptAgreement;
}(_react2.default.Component);

exports.default = AcceptAgreement;

},{"../services/ajax":365,"react":"react"}],4:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

exports.ModalHeader = ModalHeader;

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _form = require("./form");

var _form2 = _interopRequireDefault(_form);

var _formGroup = require("./form-group");

var _formGroup2 = _interopRequireDefault(_formGroup);

var _participants = require("../reducers/participants");

var participants = _interopRequireWildcard(_participants);

var _thread = require("../reducers/thread");

var _ajax = require("../services/ajax");

var _ajax2 = _interopRequireDefault(_ajax);

var _modal = require("../services/modal");

var _modal2 = _interopRequireDefault(_modal);

var _snackbar = require("../services/snackbar");

var _snackbar2 = _interopRequireDefault(_snackbar);

var _store = require("../services/store");

var _store2 = _interopRequireDefault(_store);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_Form) {
  _inherits(_class, _Form);

  function _class(props) {
    _classCallCheck(this, _class);

    var _this = _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).call(this, props));

    _this.onUsernameChange = function (event) {
      _this.changeValue("username", event.target.value);
    };

    _this.state = {
      isLoading: false,

      username: ""
    };
    return _this;
  }

  _createClass(_class, [{
    key: "clean",
    value: function clean() {
      if (!this.state.username.trim().length) {
        _snackbar2.default.error(gettext("You have to enter user name."));
        return false;
      }

      return true;
    }
  }, {
    key: "send",
    value: function send() {
      return _ajax2.default.patch(this.props.thread.api.index, [{ op: "add", path: "participants", value: this.state.username }, { op: "add", path: "acl", value: 1 }]);
    }
  }, {
    key: "handleSuccess",
    value: function handleSuccess(data) {
      _store2.default.dispatch((0, _thread.updateAcl)(data));
      _store2.default.dispatch(participants.replace(data.participants));

      _snackbar2.default.success(gettext("New participant has been added to thread."));

      _modal2.default.hide();
    }
  }, {
    key: "render",
    value: function render() {
      return _react2.default.createElement(
        "div",
        { className: "modal-dialog modal-sm", role: "document" },
        _react2.default.createElement(
          "form",
          { onSubmit: this.handleSubmit },
          _react2.default.createElement(
            "div",
            { className: "modal-content" },
            _react2.default.createElement(ModalHeader, null),
            _react2.default.createElement(
              "div",
              { className: "modal-body" },
              _react2.default.createElement(
                _formGroup2.default,
                { "for": "id_username", label: gettext("User to add") },
                _react2.default.createElement("input", {
                  id: "id_username",
                  className: "form-control",
                  disabled: this.state.isLoading,
                  onChange: this.onUsernameChange,
                  type: "text",
                  value: this.state.username
                })
              )
            ),
            _react2.default.createElement(
              "div",
              { className: "modal-footer" },
              _react2.default.createElement(
                "button",
                {
                  className: "btn btn-block btn-primary",
                  disabled: this.state.isLoading
                },
                gettext("Add participant")
              ),
              _react2.default.createElement(
                "button",
                {
                  className: "btn btn-block btn-default",
                  "data-dismiss": "modal",
                  disabled: this.state.isLoading,
                  type: "button"
                },
                gettext("Cancel")
              )
            )
          )
        )
      );
    }
  }]);

  return _class;
}(_form2.default);

exports.default = _class;
function ModalHeader(props) {
  return _react2.default.createElement(
    "div",
    { className: "modal-header" },
    _react2.default.createElement(
      "button",
      {
        "aria-label": gettext("Close"),
        className: "close",
        "data-dismiss": "modal",
        type: "button"
      },
      _react2.default.createElement(
        "span",
        { "aria-hidden": "true" },
        "\xD7"
      )
    ),
    _react2.default.createElement(
      "h4",
      { className: "modal-title" },
      gettext("Add participant")
    )
  );
}

},{"../reducers/participants":351,"../reducers/thread":360,"../services/ajax":365,"../services/modal":371,"../services/snackbar":376,"../services/store":377,"./form":56,"./form-group":55,"react":"react"}],5:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

exports.select = select;

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_React$Component) {
  _inherits(_class, _React$Component);

  function _class() {
    _classCallCheck(this, _class);

    return _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).apply(this, arguments));
  }

  _createClass(_class, [{
    key: "refresh",
    value: function refresh() {
      window.location.reload();
    }
  }, {
    key: "getMessage",
    value: function getMessage() {
      if (this.props.signedIn) {
        return interpolate(gettext("You have signed in as %(username)s. Please refresh the page before continuing."), { username: this.props.signedIn.username }, true);
      } else if (this.props.signedOut) {
        return interpolate(gettext("%(username)s, you have been signed out. Please refresh the page before continuing."), { username: this.props.user.username }, true);
      }
    }
  }, {
    key: "render",
    value: function render() {
      var className = "auth-message";
      if (this.props.signedIn || this.props.signedOut) {
        className += " show";
      }

      return _react2.default.createElement(
        "div",
        { className: className },
        _react2.default.createElement(
          "div",
          { className: "container" },
          _react2.default.createElement(
            "p",
            { className: "lead" },
            this.getMessage()
          ),
          _react2.default.createElement(
            "p",
            null,
            _react2.default.createElement(
              "button",
              {
                className: "btn btn-default",
                type: "button",
                onClick: this.refresh
              },
              gettext("Reload page")
            ),
            _react2.default.createElement(
              "span",
              { className: "hidden-xs hidden-sm" },
              " " + gettext("or press F5 key.")
            )
          )
        )
      );
    }
  }]);

  return _class;
}(_react2.default.Component);

exports.default = _class;
function select(state) {
  return {
    user: state.auth.user,
    signedIn: state.auth.signedIn,
    signedOut: state.auth.signedOut
  };
}

},{"react":"react"}],6:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (props) {
  var size = props.size || 100;
  var size2x = props.size2x || size;

  return _react2.default.createElement("img", {
    alt: "",
    className: props.className || "user-avatar",
    src: getSrc(props.user, size),
    srcSet: getSrc(props.user, size2x),
    width: size,
    height: size
  });
};

exports.getSrc = getSrc;
exports.resolveAvatarForSize = resolveAvatarForSize;

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _ = require("..");

var _2 = _interopRequireDefault(_);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function getSrc(user, size) {
  if (user && user.id) {
    // just avatar hash, size and user id
    return resolveAvatarForSize(user.avatars, size).url;
  } else {
    // just append avatar size to file to produce no-avatar placeholder
    return _2.default.get("BLANK_AVATAR_URL");
  }
}

function resolveAvatarForSize(avatars, size) {
  var avatar = avatars[0];
  avatars.forEach(function (av) {
    if (av.size >= size) {
      avatar = av;
    }
  });
  return avatar;
}

},{"..":302,"react":"react"}],7:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _moment = require("moment");

var _moment2 = _interopRequireDefault(_moment);

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_React$Component) {
  _inherits(_class, _React$Component);

  function _class() {
    _classCallCheck(this, _class);

    return _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).apply(this, arguments));
  }

  _createClass(_class, [{
    key: "getReasonMessage",
    value: function getReasonMessage() {
      if (this.props.message.html) {
        return _react2.default.createElement("div", {
          className: "lead",
          dangerouslySetInnerHTML: {
            __html: this.props.message.html
          }
        });
      } else {
        return _react2.default.createElement(
          "p",
          { className: "lead" },
          this.props.message.plain
        );
      }
    }
  }, {
    key: "getExpirationMessage",
    value: function getExpirationMessage() {
      if (this.props.expires) {
        if (this.props.expires.isAfter((0, _moment2.default)())) {
          var title = interpolate(gettext("This ban expires on %(expires_on)s."), {
            expires_on: this.props.expires.format("LL, LT")
          }, true);

          var message = interpolate(gettext("This ban expires %(expires_on)s."), {
            expires_on: this.props.expires.fromNow()
          }, true);

          return _react2.default.createElement(
            "abbr",
            { title: title },
            message
          );
        } else {
          return gettext("This ban has expired.");
        }
      } else {
        return gettext("This ban is permanent.");
      }
    }
  }, {
    key: "render",
    value: function render() {
      return _react2.default.createElement(
        "div",
        { className: "page page-error page-error-banned" },
        _react2.default.createElement(
          "div",
          { className: "container" },
          _react2.default.createElement(
            "div",
            { className: "message-panel" },
            _react2.default.createElement(
              "div",
              { className: "message-icon" },
              _react2.default.createElement(
                "span",
                { className: "material-icon" },
                "highlight_off"
              )
            ),
            _react2.default.createElement(
              "div",
              { className: "message-body" },
              this.getReasonMessage(),
              _react2.default.createElement(
                "p",
                { className: "message-footnote" },
                this.getExpirationMessage()
              )
            )
          )
        )
      );
    }
  }]);

  return _class;
}(_react2.default.Component);

exports.default = _class;

},{"moment":"moment","react":"react"}],8:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _loader = require("./loader");

var _loader2 = _interopRequireDefault(_loader);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var Button = function (_React$Component) {
  _inherits(Button, _React$Component);

  function Button() {
    _classCallCheck(this, Button);

    return _possibleConstructorReturn(this, (Button.__proto__ || Object.getPrototypeOf(Button)).apply(this, arguments));
  }

  _createClass(Button, [{
    key: "render",
    value: function render() {
      var className = "btn " + this.props.className;
      var disabled = this.props.disabled;

      if (this.props.loading) {
        className += " btn-loading";
        disabled = true;
      }

      return _react2.default.createElement(
        "button",
        {
          className: className,
          disabled: disabled,
          onClick: this.props.onClick,
          type: this.props.onClick ? "button" : "submit"
        },
        this.props.children,
        this.props.loading ? _react2.default.createElement(_loader2.default, null) : null
      );
    }
  }]);

  return Button;
}(_react2.default.Component);

exports.default = Button;


Button.defaultProps = {
  className: "btn-default",

  type: "submit",

  loading: false,
  disabled: false,

  onClick: null
};

},{"./loader":58,"react":"react"}],9:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (props) {
  return _react2.default.createElement(
    "div",
    { className: "categories-list" },
    _react2.default.createElement(
      "ul",
      { className: "list-group" },
      _react2.default.createElement(
        "li",
        { className: "list-group-item empty-message" },
        _react2.default.createElement(
          "p",
          { className: "lead" },
          gettext("No categories exist or you don't have permission to see them.")
        )
      )
    )
  );
};

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

},{"react":"react"}],10:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (_ref) {
  var categories = _ref.categories;

  return _react2.default.createElement(
    "div",
    { className: "categories-list" },
    categories.map(function (category) {
      return _react2.default.createElement(_category2.default, { category: category, key: category.id });
    })
  );
};

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _category = require("./category");

var _category2 = _interopRequireDefault(_category);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

},{"./category":11,"react":"react"}],11:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (_ref) {
  var category = _ref.category;

  var className = "list-group list-group-category";
  if (category.css_class) {
    className += " list-group-category-has-flavor";
    className += " list-group-category-" + category.css_class;
  }

  return _react2.default.createElement(
    "ul",
    { className: className },
    _react2.default.createElement(_listItem2.default, { category: category, isFirst: true }),
    category.subcategories.map(function (category) {
      return _react2.default.createElement(_listItem2.default, { category: category, isFirst: false, key: category.id });
    })
  );
};

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _listItem = require("./list-item");

var _listItem2 = _interopRequireDefault(_listItem);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

},{"./list-item":14,"react":"react"}],12:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (_ref) {
  var category = _ref.category;

  if (!category.description) return null;

  return _react2.default.createElement("div", {
    className: "category-description",
    dangerouslySetInnerHTML: {
      __html: category.description.html
    }
  });
};

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

},{"react":"react"}],13:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (_ref) {
  var category = _ref.category;

  return _react2.default.createElement(
    "div",
    { className: getClassName(category), title: getTitle(category) },
    _react2.default.createElement(
      "span",
      { className: "material-icon" },
      getIcon(category)
    )
  );
};

exports.getClassName = getClassName;
exports.getTitle = getTitle;
exports.getIcon = getIcon;

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function getClassName(category) {
  if (category.is_read) {
    return "read-status item-read";
  }

  return "read-status item-new";
}

function getTitle(category) {
  if (category.is_closed) {
    if (category.is_read) {
      return gettext("This category has no new posts. (closed)");
    }

    return gettext("This category has new posts. (closed)");
  }

  if (category.is_read) {
    return gettext("This category has no new posts.");
  }

  return gettext("This category has new posts.");
}

function getIcon(category) {
  if (category.is_closed) {
    if (category.is_read) {
      return "lock_outline";
    }

    return "lock";
  }

  if (category.is_read) {
    return "chat_bubble_outline";
  }

  return "chat_bubble";
}

},{"react":"react"}],14:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (_ref) {
  var category = _ref.category,
      isFirst = _ref.isFirst;

  var className = "list-group-item";

  if (category.description) {
    className += " list-group-category-has-description";
  } else {
    className += " list-group-category-no-description";
  }

  if (isFirst) {
    className += " list-group-item-first";
  }
  if (category.css_class) {
    className += " list-group-category-has-flavor";
    className += " list-group-item-category-" + category.css_class;
  }

  return _react2.default.createElement(
    "li",
    { className: className },
    _react2.default.createElement(
      "div",
      { className: "row" },
      _react2.default.createElement(_main2.default, { category: category }),
      _react2.default.createElement(_stats2.default, { category: category }),
      _react2.default.createElement(_lastThread2.default, { category: category })
    ),
    _react2.default.createElement(_subcategories2.default, { category: category, isFirst: isFirst })
  );
};

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _main = require("./main");

var _main2 = _interopRequireDefault(_main);

var _lastThread = require("./last-thread");

var _lastThread2 = _interopRequireDefault(_lastThread);

var _stats = require("./stats");

var _stats2 = _interopRequireDefault(_stats);

var _subcategories = require("./subcategories");

var _subcategories2 = _interopRequireDefault(_subcategories);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

},{"./last-thread":15,"./main":16,"./stats":17,"./subcategories":18,"react":"react"}],15:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (_ref) {
  var category = _ref.category;

  return _react2.default.createElement(
    "div",
    { className: "col-xs-12 col-sm-6 col-md-4 category-last-thread" },
    _react2.default.createElement(LastThread, { category: category }),
    _react2.default.createElement(Empty, { category: category }),
    _react2.default.createElement(Private, { category: category }),
    _react2.default.createElement(Protected, { category: category })
  );
};

exports.LastThread = LastThread;
exports.LastPosterAvatar = LastPosterAvatar;
exports.LastPosterName = LastPosterName;
exports.Empty = Empty;
exports.Private = Private;
exports.Protected = Protected;
exports.Message = Message;

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _avatar = require("../../../avatar");

var _avatar2 = _interopRequireDefault(_avatar);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function LastThread(_ref2) {
  var category = _ref2.category;

  if (!category.acl.can_browse) return null;
  if (!category.acl.can_see_all_threads) return null;
  if (!category.last_thread_title) return null;

  return _react2.default.createElement(
    "div",
    { className: "media" },
    _react2.default.createElement(
      "div",
      { className: "media-left hidden-xs" },
      _react2.default.createElement(LastPosterAvatar, { category: category })
    ),
    _react2.default.createElement(
      "div",
      { className: "media-body" },
      _react2.default.createElement(
        "div",
        { className: "media-heading" },
        _react2.default.createElement(
          "a",
          {
            className: "item-title thread-title",
            href: category.url.last_thread_new,
            title: category.last_thread_title
          },
          category.last_thread_title
        )
      ),
      _react2.default.createElement(
        "ul",
        { className: "list-inline" },
        _react2.default.createElement(
          "li",
          { className: "category-last-thread-poster" },
          _react2.default.createElement(LastPosterName, { category: category })
        ),
        _react2.default.createElement(
          "li",
          { className: "divider" },
          "\u2014"
        ),
        _react2.default.createElement(
          "li",
          { className: "category-last-thread-date" },
          _react2.default.createElement(
            "a",
            { href: category.url.last_post },
            category.last_post_on.fromNow()
          )
        )
      )
    )
  );
}

function LastPosterAvatar(_ref3) {
  var category = _ref3.category;

  if (category.last_poster) {
    return _react2.default.createElement(
      "a",
      {
        className: "last-poster-avatar",
        href: category.last_poster.url,
        title: category.last_poster_name
      },
      _react2.default.createElement(_avatar2.default, {
        className: "media-object",
        size: 40,
        user: category.last_poster
      })
    );
  }

  return _react2.default.createElement(
    "span",
    { className: "last-poster-avatar", title: category.last_poster_name },
    _react2.default.createElement(_avatar2.default, { className: "media-object", size: 40 })
  );
}

function LastPosterName(_ref4) {
  var category = _ref4.category;

  if (category.last_poster) {
    return _react2.default.createElement(
      "a",
      { className: "item-title", href: category.last_poster.url },
      category.last_poster_name
    );
  }

  return _react2.default.createElement(
    "span",
    { className: "item-title" },
    category.last_poster_name
  );
}

function Empty(_ref5) {
  var category = _ref5.category;

  if (!category.acl.can_browse) return null;
  if (!category.acl.can_see_all_threads) return null;
  if (category.last_thread_title) return null;

  return _react2.default.createElement(Message, {
    message: gettext("This category is empty. No threads were posted within it so far.")
  });
}

function Private(_ref6) {
  var category = _ref6.category;

  if (!category.acl.can_browse) return null;
  if (category.acl.can_see_all_threads) return null;

  return _react2.default.createElement(Message, {
    message: gettext("This category is private. You can see only your own threads within it.")
  });
}

function Protected(_ref7) {
  var category = _ref7.category;

  if (category.acl.can_browse) return null;

  return _react2.default.createElement(Message, {
    message: gettext("This category is protected. You can't browse it's contents.")
  });
}

function Message(_ref8) {
  var message = _ref8.message;

  return _react2.default.createElement(
    "div",
    { className: "media category-thread-message" },
    _react2.default.createElement(
      "div",
      { className: "media-left" },
      _react2.default.createElement(
        "span",
        { className: "material-icon" },
        "info_outline"
      )
    ),
    _react2.default.createElement(
      "div",
      { className: "media-body" },
      _react2.default.createElement(
        "p",
        null,
        message
      )
    )
  );
}

},{"../../../avatar":6,"react":"react"}],16:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (_ref) {
  var category = _ref.category;

  return _react2.default.createElement(
    "div",
    { className: "col-xs-12 col-sm-6 col-md-6 category-main" },
    _react2.default.createElement(
      "div",
      { className: "media" },
      _react2.default.createElement(
        "div",
        { className: "media-left" },
        _react2.default.createElement(_icon2.default, { category: category })
      ),
      _react2.default.createElement(
        "div",
        { className: "media-body" },
        _react2.default.createElement(
          "h4",
          { className: "media-heading" },
          _react2.default.createElement(
            "a",
            { href: category.url.index },
            category.name
          )
        ),
        _react2.default.createElement(_description2.default, { category: category })
      )
    )
  );
};

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _description = require("./description");

var _description2 = _interopRequireDefault(_description);

var _icon = require("./icon");

var _icon2 = _interopRequireDefault(_icon);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

},{"./description":12,"./icon":13,"react":"react"}],17:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (_ref) {
  var category = _ref.category;

  return _react2.default.createElement(
    "div",
    { className: "col-md-2 hidden-xs hidden-sm" },
    _react2.default.createElement(
      "ul",
      { className: "list-unstyled category-stats" },
      _react2.default.createElement(Threads, { threads: category.threads }),
      _react2.default.createElement(Posts, { posts: category.posts })
    )
  );
};

exports.Threads = Threads;
exports.Posts = Posts;

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _avatar = require("../../../avatar");

var _avatar2 = _interopRequireDefault(_avatar);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function Threads(_ref2) {
  var threads = _ref2.threads;

  var message = ngettext("%(threads)s thread", "%(threads)s threads", threads);

  return _react2.default.createElement(
    "li",
    { className: "category-stat-threads" },
    interpolate(message, {
      threads: threads
    }, true)
  );
}

function Posts(_ref3) {
  var posts = _ref3.posts;

  var message = ngettext("%(posts)s post", "%(posts)s posts", posts);

  return _react2.default.createElement(
    "li",
    { className: "category-stat-posts" },
    interpolate(message, {
      posts: posts
    }, true)
  );
}

},{"../../../avatar":6,"react":"react"}],18:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (_ref) {
  var category = _ref.category,
      isFirst = _ref.isFirst;

  if (isFirst) return null;
  if (category.subcategories.length === 0) return null;

  return _react2.default.createElement(
    "div",
    { className: "row subcategories-list" },
    category.subcategories.map(function (category) {
      return _react2.default.createElement(_listItem2.default, { category: category, key: category.id });
    })
  );
};

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _listItem = require("./list-item");

var _listItem2 = _interopRequireDefault(_listItem);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

},{"./list-item":19,"react":"react"}],19:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (_ref) {
  var category = _ref.category;

  var className = "btn btn-default btn-block btn-sm btn-subcategory";
  if (!category.is_read) {
    className += " btn-subcategory-new";
  }

  return _react2.default.createElement(
    "div",
    { className: "col-xs-12 col-sm-4 col-md-3" },
    _react2.default.createElement(
      "a",
      { className: className, href: category.url.index },
      _react2.default.createElement(
        "span",
        { className: "material-icon" },
        getIcon(category)
      ),
      _react2.default.createElement(
        "span",
        { className: "icon-text" },
        category.name
      )
    )
  );
};

exports.getIcon = getIcon;

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function getIcon(category) {
  if (category.is_closed) {
    if (category.is_read) {
      return "lock_outline";
    }

    return "lock";
  }

  if (category.is_read) {
    return "chat_bubble_outline";
  }

  return "chat_bubble";
}

},{"react":"react"}],20:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

exports.select = select;

var _moment = require("moment");

var _moment2 = _interopRequireDefault(_moment);

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _blankslate = require("./blankslate");

var _blankslate2 = _interopRequireDefault(_blankslate);

var _categoriesList = require("./categories-list");

var _categoriesList2 = _interopRequireDefault(_categoriesList);

var _index = require("../../index");

var _index2 = _interopRequireDefault(_index);

var _polls = require("../../services/polls");

var _polls2 = _interopRequireDefault(_polls);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var hydrate = function hydrate(category) {
  return Object.assign({}, category, {
    last_post_on: category.last_post_on ? (0, _moment2.default)(category.last_post_on) : null,
    subcategories: category.subcategories.map(hydrate)
  });
};

var _class = function (_React$Component) {
  _inherits(_class, _React$Component);

  function _class(props) {
    _classCallCheck(this, _class);

    var _this = _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).call(this, props));

    _this.update = function (data) {
      _this.setState({
        categories: data.map(hydrate)
      });
    };

    _this.state = {
      categories: _index2.default.get("CATEGORIES").map(hydrate)
    };

    _this.startPolling(_index2.default.get("CATEGORIES_API"));
    return _this;
  }

  _createClass(_class, [{
    key: "startPolling",
    value: function startPolling(api) {
      _polls2.default.start({
        poll: "categories",
        url: api,
        frequency: 180 * 1000,
        update: this.update
      });
    }
  }, {
    key: "render",
    value: function render() {
      var categories = this.state.categories;


      if (categories.length === 0) {
        return _react2.default.createElement(_blankslate2.default, null);
      }

      return _react2.default.createElement(_categoriesList2.default, { categories: categories });
    }
  }]);

  return _class;
}(_react2.default.Component);

exports.default = _class;
function select(store) {
  return {
    tick: store.tick.tick
  };
}

},{"../../index":302,"../../services/polls":374,"./blankslate":9,"./categories-list":10,"moment":"moment","react":"react"}],21:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (props) {
  return _react2.default.createElement(
    "select",
    {
      className: props.className || "form-control",
      disabled: props.disabled || false,
      id: props.id || null,
      onChange: props.onChange,
      value: props.value
    },
    props.choices.map(function (item) {
      return _react2.default.createElement(
        "option",
        {
          disabled: item.disabled || false,
          key: item.value,
          value: item.value
        },
        "- - ".repeat(item.level) + item.label
      );
    })
  );
};

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

},{"react":"react"}],22:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _avatar = require("../avatar");

var _avatar2 = _interopRequireDefault(_avatar);

var _button = require("../button");

var _button2 = _interopRequireDefault(_button);

var _ajax = require("../../services/ajax");

var _ajax2 = _interopRequireDefault(_ajax);

var _snackbar = require("../../services/snackbar");

var _snackbar2 = _interopRequireDefault(_snackbar);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_React$Component) {
  _inherits(_class, _React$Component);

  function _class(props) {
    _classCallCheck(this, _class);

    var _this = _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).call(this, props));

    _this.cropAvatar = function () {
      if (_this.state.isLoading) {
        return false;
      }

      _this.setState({
        isLoading: true
      });

      var avatarType = _this.props.upload ? "crop_tmp" : "crop_src";
      var cropit = $(".crop-form");

      var deviceRatio = cropit.cropit("exportZoom");
      var cropitOffset = cropit.cropit("offset");

      _ajax2.default.post(_this.props.user.api.avatar, {
        avatar: avatarType,
        crop: {
          offset: {
            x: cropitOffset.x * deviceRatio,
            y: cropitOffset.y * deviceRatio
          },
          zoom: cropit.cropit("zoom") * deviceRatio
        }
      }).then(function (data) {
        _this.props.onComplete(data);
        _snackbar2.default.success(data.detail);
      }, function (rejection) {
        if (rejection.status === 400) {
          _snackbar2.default.error(rejection.detail);
          _this.setState({
            isLoading: false
          });
        } else {
          _this.props.showError(rejection);
        }
      });
    };

    _this.state = {
      isLoading: false,
      deviceRatio: 1
    };
    return _this;
  }

  _createClass(_class, [{
    key: "getAvatarSize",
    value: function getAvatarSize() {
      if (this.props.upload) {
        return this.props.options.crop_tmp.size;
      } else {
        return this.props.options.crop_src.size;
      }
    }
  }, {
    key: "getImagePath",
    value: function getImagePath() {
      if (this.props.upload) {
        return this.props.dataUrl;
      } else {
        return this.props.options.crop_src.url;
      }
    }
  }, {
    key: "componentDidMount",
    value: function componentDidMount() {
      var _this2 = this;

      var cropit = $(".crop-form");
      var cropperWidth = this.getAvatarSize();

      var initialWidth = cropit.width();
      while (initialWidth < cropperWidth) {
        cropperWidth = cropperWidth / 2;
      }

      var deviceRatio = this.getAvatarSize() / cropperWidth;

      cropit.width(cropperWidth);

      cropit.cropit({
        width: cropperWidth,
        height: cropperWidth,
        exportZoom: deviceRatio,
        imageState: {
          src: this.getImagePath()
        },
        onImageLoaded: function onImageLoaded() {
          if (_this2.props.upload) {
            // center uploaded image
            var zoomLevel = cropit.cropit("zoom");
            var imageSize = cropit.cropit("imageSize");

            // is it wider than taller?
            if (imageSize.width > imageSize.height) {
              var displayedWidth = imageSize.width * zoomLevel;
              var offsetX = (displayedWidth - _this2.getAvatarSize()) / -2;

              cropit.cropit("offset", {
                x: offsetX,
                y: 0
              });
            } else if (imageSize.width < imageSize.height) {
              var displayedHeight = imageSize.height * zoomLevel;
              var offsetY = (displayedHeight - _this2.getAvatarSize()) / -2;

              cropit.cropit("offset", {
                x: 0,
                y: offsetY
              });
            } else {
              cropit.cropit("offset", {
                x: 0,
                y: 0
              });
            }
          } else {
            // use preserved crop
            var crop = _this2.props.options.crop_src.crop;

            if (crop) {
              cropit.cropit("zoom", crop.zoom);
              cropit.cropit("offset", {
                x: crop.x,
                y: crop.y
              });
            }
          }
        }
      });
    }
  }, {
    key: "componentWillUnmount",
    value: function componentWillUnmount() {
      $(".crop-form").cropit("disable");
    }
  }, {
    key: "render",
    value: function render() {
      return _react2.default.createElement(
        "div",
        null,
        _react2.default.createElement(
          "div",
          { className: "modal-body modal-avatar-crop" },
          _react2.default.createElement(
            "div",
            { className: "crop-form" },
            _react2.default.createElement("div", { className: "cropit-preview" }),
            _react2.default.createElement("input", { type: "range", className: "cropit-image-zoom-input" })
          )
        ),
        _react2.default.createElement(
          "div",
          { className: "modal-footer" },
          _react2.default.createElement(
            "div",
            { className: "col-md-6 col-md-offset-3" },
            _react2.default.createElement(
              _button2.default,
              {
                onClick: this.cropAvatar,
                loading: this.state.isLoading,
                className: "btn-primary btn-block"
              },
              this.props.upload ? gettext("Set avatar") : gettext("Crop image")
            ),
            _react2.default.createElement(
              _button2.default,
              {
                onClick: this.props.showIndex,
                disabled: this.state.isLoading,
                className: "btn-default btn-block"
              },
              gettext("Cancel")
            )
          )
        )
      );
    }
  }]);

  return _class;
}(_react2.default.Component);

exports.default = _class;

},{"../../services/ajax":365,"../../services/snackbar":376,"../avatar":6,"../button":8,"react":"react"}],23:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Gallery = exports.GalleryItem = undefined;

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _avatar = require("../avatar");

var _avatar2 = _interopRequireDefault(_avatar);

var _button = require("../button");

var _button2 = _interopRequireDefault(_button);

var _index = require("../../index");

var _index2 = _interopRequireDefault(_index);

var _ajax = require("../../services/ajax");

var _ajax2 = _interopRequireDefault(_ajax);

var _snackbar = require("../../services/snackbar");

var _snackbar2 = _interopRequireDefault(_snackbar);

var _batch = require("../../utils/batch");

var _batch2 = _interopRequireDefault(_batch);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var GalleryItem = exports.GalleryItem = function (_React$Component) {
  _inherits(GalleryItem, _React$Component);

  function GalleryItem() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, GalleryItem);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = GalleryItem.__proto__ || Object.getPrototypeOf(GalleryItem)).call.apply(_ref, [this].concat(args))), _this), _this.select = function () {
      _this.props.select(_this.props.id);
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(GalleryItem, [{
    key: "getClassName",
    value: function getClassName() {
      if (this.props.selection === this.props.id) {
        if (this.props.disabled) {
          return "btn btn-avatar btn-disabled avatar-selected";
        } else {
          return "btn btn-avatar avatar-selected";
        }
      } else if (this.props.disabled) {
        return "btn btn-avatar btn-disabled";
      } else {
        return "btn btn-avatar";
      }
    }
  }, {
    key: "render",
    value: function render() {
      return _react2.default.createElement(
        "button",
        {
          type: "button",
          className: this.getClassName(),
          disabled: this.props.disabled,
          onClick: this.select
        },
        _react2.default.createElement("img", { src: this.props.url })
      );
    }
  }]);

  return GalleryItem;
}(_react2.default.Component);

var Gallery = exports.Gallery = function (_React$Component2) {
  _inherits(Gallery, _React$Component2);

  function Gallery() {
    _classCallCheck(this, Gallery);

    return _possibleConstructorReturn(this, (Gallery.__proto__ || Object.getPrototypeOf(Gallery)).apply(this, arguments));
  }

  _createClass(Gallery, [{
    key: "render",
    value: function render() {
      var _this3 = this;

      return _react2.default.createElement(
        "div",
        { className: "avatars-gallery" },
        _react2.default.createElement(
          "h3",
          null,
          this.props.name
        ),
        _react2.default.createElement(
          "div",
          { className: "avatars-gallery-images" },
          (0, _batch2.default)(this.props.images, 4, null).map(function (row, i) {
            return _react2.default.createElement(
              "div",
              { className: "row", key: i },
              row.map(function (item, i) {
                return _react2.default.createElement(
                  "div",
                  { className: "col-xs-3", key: i },
                  item ? _react2.default.createElement(GalleryItem, _extends({
                    disabled: _this3.props.disabled,
                    select: _this3.props.select,
                    selection: _this3.props.selection
                  }, item)) : _react2.default.createElement("div", { className: "blank-avatar" })
                );
              })
            );
          })
        )
      );
    }
  }]);

  return Gallery;
}(_react2.default.Component);

var _class = function (_React$Component3) {
  _inherits(_class, _React$Component3);

  function _class(props) {
    _classCallCheck(this, _class);

    var _this4 = _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).call(this, props));

    _this4.select = function (image) {
      _this4.setState({
        selection: image
      });
    };

    _this4.save = function () {
      if (_this4.state.isLoading) {
        return false;
      }

      _this4.setState({
        isLoading: true
      });

      _ajax2.default.post(_this4.props.user.api.avatar, {
        avatar: "galleries",
        image: _this4.state.selection
      }).then(function (response) {
        _this4.setState({
          isLoading: false
        });

        _snackbar2.default.success(response.detail);
        _this4.props.onComplete(response);
        _this4.props.showIndex();
      }, function (rejection) {
        if (rejection.status === 400) {
          _snackbar2.default.error(rejection.detail);
          _this4.setState({
            isLoading: false
          });
        } else {
          _this4.props.showError(rejection);
        }
      });
    };

    _this4.state = {
      selection: null,
      isLoading: false
    };
    return _this4;
  }

  _createClass(_class, [{
    key: "render",
    value: function render() {
      var _this5 = this;

      return _react2.default.createElement(
        "div",
        null,
        _react2.default.createElement(
          "div",
          { className: "modal-body modal-avatar-gallery" },
          this.props.options.galleries.map(function (item, i) {
            return _react2.default.createElement(Gallery, {
              name: item.name,
              images: item.images,
              selection: _this5.state.selection,
              disabled: _this5.state.isLoading,
              select: _this5.select,
              key: i
            });
          })
        ),
        _react2.default.createElement(
          "div",
          { className: "modal-footer" },
          _react2.default.createElement(
            "div",
            { className: "row" },
            _react2.default.createElement(
              "div",
              { className: "col-md-6 col-md-offset-3" },
              _react2.default.createElement(
                _button2.default,
                {
                  onClick: this.save,
                  loading: this.state.isLoading,
                  disabled: !this.state.selection,
                  className: "btn-primary btn-block"
                },
                this.state.selection ? gettext("Save choice") : gettext("Select avatar")
              ),
              _react2.default.createElement(
                _button2.default,
                {
                  onClick: this.props.showIndex,
                  disabled: this.state.isLoading,
                  className: "btn-default btn-block"
                },
                gettext("Cancel")
              )
            )
          )
        )
      );
    }
  }]);

  return _class;
}(_react2.default.Component);

exports.default = _class;

},{"../../index":302,"../../services/ajax":365,"../../services/snackbar":376,"../../utils/batch":380,"../avatar":6,"../button":8,"react":"react"}],24:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _avatar = require("../avatar");

var _avatar2 = _interopRequireDefault(_avatar);

var _button = require("../button");

var _button2 = _interopRequireDefault(_button);

var _loader = require("../loader");

var _loader2 = _interopRequireDefault(_loader);

var _ajax = require("../../services/ajax");

var _ajax2 = _interopRequireDefault(_ajax);

var _snackbar = require("../../services/snackbar");

var _snackbar2 = _interopRequireDefault(_snackbar);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_React$Component) {
  _inherits(_class, _React$Component);

  function _class(props) {
    _classCallCheck(this, _class);

    var _this = _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).call(this, props));

    _this.setGravatar = function () {
      _this.callApi("gravatar");
    };

    _this.setGenerated = function () {
      _this.callApi("generated");
    };

    _this.state = {
      isLoading: false
    };
    return _this;
  }

  _createClass(_class, [{
    key: "callApi",
    value: function callApi(avatarType) {
      var _this2 = this;

      if (this.state.isLoading) {
        return false;
      }

      this.setState({
        isLoading: true
      });

      _ajax2.default.post(this.props.user.api.avatar, {
        avatar: avatarType
      }).then(function (response) {
        _this2.setState({
          isLoading: false
        });

        _snackbar2.default.success(response.detail);
        _this2.props.onComplete(response);
      }, function (rejection) {
        if (rejection.status === 400) {
          _snackbar2.default.error(rejection.detail);
          _this2.setState({
            isLoading: false
          });
        } else {
          _this2.props.showError(rejection);
        }
      });
    }
  }, {
    key: "getGravatarButton",
    value: function getGravatarButton() {
      if (this.props.options.gravatar) {
        return _react2.default.createElement(
          _button2.default,
          {
            onClick: this.setGravatar,
            disabled: this.state.isLoading,
            className: "btn-default btn-block btn-avatar-gravatar"
          },
          gettext("Download my Gravatar")
        );
      } else {
        return null;
      }
    }
  }, {
    key: "getCropButton",
    value: function getCropButton() {
      if (!this.props.options.crop_src) return null;

      return _react2.default.createElement(
        _button2.default,
        {
          className: "btn-default btn-block btn-avatar-crop",
          disabled: this.state.isLoading,
          onClick: this.props.showCrop
        },
        gettext("Re-crop uploaded image")
      );
    }
  }, {
    key: "getUploadButton",
    value: function getUploadButton() {
      if (!this.props.options.upload) return null;

      return _react2.default.createElement(
        _button2.default,
        {
          className: "btn-default btn-block btn-avatar-upload",
          disabled: this.state.isLoading,
          onClick: this.props.showUpload
        },
        gettext("Upload new image")
      );
    }
  }, {
    key: "getGalleryButton",
    value: function getGalleryButton() {
      if (!this.props.options.galleries) return null;

      return _react2.default.createElement(
        _button2.default,
        {
          className: "btn-default btn-block btn-avatar-gallery",
          disabled: this.state.isLoading,
          onClick: this.props.showGallery
        },
        gettext("Pick avatar from gallery")
      );
    }
  }, {
    key: "getAvatarPreview",
    value: function getAvatarPreview() {
      var userPeview = {
        id: this.props.user.id,
        avatars: this.props.options.avatars
      };

      if (this.state.isLoading) {
        return _react2.default.createElement(
          "div",
          { className: "avatar-preview preview-loading" },
          _react2.default.createElement(_avatar2.default, { size: "200", user: userPeview }),
          _react2.default.createElement(_loader2.default, null)
        );
      }

      return _react2.default.createElement(
        "div",
        { className: "avatar-preview" },
        _react2.default.createElement(_avatar2.default, { size: "200", user: userPeview })
      );
    }
  }, {
    key: "render",
    value: function render() {
      return _react2.default.createElement(
        "div",
        { className: "modal-body modal-avatar-index" },
        _react2.default.createElement(
          "div",
          { className: "row" },
          _react2.default.createElement(
            "div",
            { className: "col-md-5" },
            this.getAvatarPreview()
          ),
          _react2.default.createElement(
            "div",
            { className: "col-md-7" },
            this.getGravatarButton(),
            _react2.default.createElement(
              _button2.default,
              {
                onClick: this.setGenerated,
                disabled: this.state.isLoading,
                className: "btn-default btn-block btn-avatar-generate"
              },
              gettext("Generate my individual avatar")
            ),
            this.getCropButton(),
            this.getUploadButton(),
            this.getGalleryButton()
          )
        )
      );
    }
  }]);

  return _class;
}(_react2.default.Component);

exports.default = _class;

},{"../../services/ajax":365,"../../services/snackbar":376,"../avatar":6,"../button":8,"../loader":58,"react":"react"}],25:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ChangeAvatarError = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

exports.select = select;

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _index = require("./index");

var _index2 = _interopRequireDefault(_index);

var _crop = require("./crop");

var _crop2 = _interopRequireDefault(_crop);

var _upload = require("./upload");

var _upload2 = _interopRequireDefault(_upload);

var _gallery = require("./gallery");

var _gallery2 = _interopRequireDefault(_gallery);

var _modalLoader = require("../modal-loader");

var _modalLoader2 = _interopRequireDefault(_modalLoader);

var _users = require("../../reducers/users");

var _ajax = require("../../services/ajax");

var _ajax2 = _interopRequireDefault(_ajax);

var _store = require("../../services/store");

var _store2 = _interopRequireDefault(_store);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var ChangeAvatarError = exports.ChangeAvatarError = function (_React$Component) {
  _inherits(ChangeAvatarError, _React$Component);

  function ChangeAvatarError() {
    _classCallCheck(this, ChangeAvatarError);

    return _possibleConstructorReturn(this, (ChangeAvatarError.__proto__ || Object.getPrototypeOf(ChangeAvatarError)).apply(this, arguments));
  }

  _createClass(ChangeAvatarError, [{
    key: "getErrorReason",
    value: function getErrorReason() {
      if (this.props.reason) {
        return _react2.default.createElement("p", { dangerouslySetInnerHTML: { __html: this.props.reason } });
      } else {
        return null;
      }
    }
  }, {
    key: "render",
    value: function render() {
      return _react2.default.createElement(
        "div",
        { className: "modal-body" },
        _react2.default.createElement(
          "div",
          { className: "message-icon" },
          _react2.default.createElement(
            "span",
            { className: "material-icon" },
            "remove_circle_outline"
          )
        ),
        _react2.default.createElement(
          "div",
          { className: "message-body" },
          _react2.default.createElement(
            "p",
            { className: "lead" },
            this.props.message
          ),
          this.getErrorReason(),
          _react2.default.createElement(
            "button",
            {
              className: "btn btn-default",
              "data-dismiss": "modal",
              type: "button"
            },
            gettext("Ok")
          )
        )
      );
    }
  }]);

  return ChangeAvatarError;
}(_react2.default.Component);

var _class = function (_React$Component2) {
  _inherits(_class, _React$Component2);

  function _class() {
    var _ref;

    var _temp, _this2, _ret;

    _classCallCheck(this, _class);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this2 = _possibleConstructorReturn(this, (_ref = _class.__proto__ || Object.getPrototypeOf(_class)).call.apply(_ref, [this].concat(args))), _this2), _this2.showError = function (error) {
      _this2.setState({
        error: error
      });
    }, _this2.showIndex = function () {
      _this2.setState({
        component: _index2.default
      });
    }, _this2.showUpload = function () {
      _this2.setState({
        component: _upload2.default
      });
    }, _this2.showCrop = function () {
      _this2.setState({
        component: _crop2.default
      });
    }, _this2.showGallery = function () {
      _this2.setState({
        component: _gallery2.default
      });
    }, _this2.completeFlow = function (options) {
      _store2.default.dispatch((0, _users.updateAvatar)(_this2.props.user, options.avatars));

      _this2.setState({
        component: _index2.default,
        options: options
      });
    }, _temp), _possibleConstructorReturn(_this2, _ret);
  }

  _createClass(_class, [{
    key: "componentDidMount",
    value: function componentDidMount() {
      var _this3 = this;

      _ajax2.default.get(this.props.user.api.avatar).then(function (options) {
        _this3.setState({
          component: _index2.default,
          options: options,
          error: null
        });
      }, function (rejection) {
        _this3.showError(rejection);
      });
    }
  }, {
    key: "getBody",
    value: function getBody() {
      if (this.state) {
        if (this.state.error) {
          return _react2.default.createElement(ChangeAvatarError, {
            message: this.state.error.detail,
            reason: this.state.error.reason
          });
        } else {
          return _react2.default.createElement(this.state.component, {
            options: this.state.options,
            user: this.props.user,
            onComplete: this.completeFlow,
            showError: this.showError,
            showIndex: this.showIndex,
            showCrop: this.showCrop,
            showUpload: this.showUpload,
            showGallery: this.showGallery
          });
        }
      } else {
        return _react2.default.createElement(_modalLoader2.default, null);
      }
    }
  }, {
    key: "getClassName",
    value: function getClassName() {
      if (this.state && this.state.error) {
        return "modal-dialog modal-message modal-change-avatar";
      } else {
        return "modal-dialog modal-change-avatar";
      }
    }
  }, {
    key: "render",
    value: function render() {
      return _react2.default.createElement(
        "div",
        { className: this.getClassName(), role: "document" },
        _react2.default.createElement(
          "div",
          { className: "modal-content" },
          _react2.default.createElement(
            "div",
            { className: "modal-header" },
            _react2.default.createElement(
              "button",
              {
                type: "button",
                className: "close",
                "data-dismiss": "modal",
                "aria-label": gettext("Close")
              },
              _react2.default.createElement(
                "span",
                { "aria-hidden": "true" },
                "\xD7"
              )
            ),
            _react2.default.createElement(
              "h4",
              { className: "modal-title" },
              gettext("Change your avatar")
            )
          ),
          this.getBody()
        )
      );
    }
  }]);

  return _class;
}(_react2.default.Component);

exports.default = _class;
function select(state) {
  return {
    user: state.auth.user
  };
}

},{"../../reducers/users":364,"../../services/ajax":365,"../../services/store":377,"../modal-loader":61,"./crop":22,"./gallery":23,"./index":24,"./upload":26,"react":"react"}],26:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _crop = require("./crop");

var _crop2 = _interopRequireDefault(_crop);

var _button = require("../button");

var _button2 = _interopRequireDefault(_button);

var _ajax = require("../../services/ajax");

var _ajax2 = _interopRequireDefault(_ajax);

var _snackbar = require("../../services/snackbar");

var _snackbar2 = _interopRequireDefault(_snackbar);

var _fileSize = require("../../utils/file-size");

var _fileSize2 = _interopRequireDefault(_fileSize);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_React$Component) {
  _inherits(_class, _React$Component);

  function _class(props) {
    _classCallCheck(this, _class);

    var _this = _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).call(this, props));

    _this.pickFile = function () {
      document.getElementById("avatar-hidden-upload").click();
    };

    _this.uploadFile = function () {
      var image = document.getElementById("avatar-hidden-upload").files[0];
      if (!image) return;

      var validationError = _this.validateFile(image);
      if (validationError) {
        _snackbar2.default.error(validationError);
        return;
      }

      _this.setState({
        image: image,
        preview: URL.createObjectURL(image),
        progress: 0
      });

      var data = new FormData();
      data.append("avatar", "upload");
      data.append("image", image);

      _ajax2.default.upload(_this.props.user.api.avatar, data, function (progress) {
        _this.setState({
          progress: progress
        });
      }).then(function (data) {
        _this.setState({
          options: data,
          uploaded: data.detail
        });

        _snackbar2.default.info(gettext("Your image has been uploaded and you may now crop it."));
      }, function (rejection) {
        if (rejection.status === 400 || rejection.status === 413) {
          _snackbar2.default.error(rejection.detail);
          _this.setState({
            isLoading: false,
            image: null,
            progress: 0
          });
        } else {
          _this.props.showError(rejection);
        }
      });
    };

    _this.state = {
      image: null,
      preview: null,
      progress: 0,
      uploaded: null,
      dataUrl: null
    };
    return _this;
  }

  _createClass(_class, [{
    key: "validateFile",
    value: function validateFile(image) {
      if (image.size > this.props.options.upload.limit) {
        return interpolate(gettext("Selected file is too big. (%(filesize)s)"), {
          filesize: (0, _fileSize2.default)(image.size)
        }, true);
      }

      var invalidTypeMsg = gettext("Selected file type is not supported.");
      if (this.props.options.upload.allowed_mime_types.indexOf(image.type) === -1) {
        return invalidTypeMsg;
      }

      var extensionFound = false;
      var loweredFilename = image.name.toLowerCase();
      this.props.options.upload.allowed_extensions.map(function (extension) {
        if (loweredFilename.substr(extension.length * -1) === extension) {
          extensionFound = true;
        }
      });

      if (!extensionFound) {
        return invalidTypeMsg;
      }

      return false;
    }
  }, {
    key: "getUploadRequirements",
    value: function getUploadRequirements(options) {
      var extensions = options.allowed_extensions.map(function (extension) {
        return extension.substr(1);
      });

      return interpolate(gettext("%(files)s files smaller than %(limit)s"), {
        files: extensions.join(", "),
        limit: (0, _fileSize2.default)(options.limit)
      }, true);
    }
  }, {
    key: "getUploadButton",
    value: function getUploadButton() {
      return _react2.default.createElement(
        "div",
        { className: "modal-body modal-avatar-upload" },
        _react2.default.createElement(
          _button2.default,
          { className: "btn-pick-file", onClick: this.pickFile },
          _react2.default.createElement(
            "div",
            { className: "material-icon" },
            "input"
          ),
          gettext("Select file")
        ),
        _react2.default.createElement(
          "p",
          { className: "text-muted" },
          this.getUploadRequirements(this.props.options.upload)
        )
      );
    }
  }, {
    key: "getUploadProgressLabel",
    value: function getUploadProgressLabel() {
      return interpolate(gettext("%(progress)s % complete"), {
        progress: this.state.progress
      }, true);
    }
  }, {
    key: "getUploadProgress",
    value: function getUploadProgress() {
      return _react2.default.createElement(
        "div",
        { className: "modal-body modal-avatar-upload" },
        _react2.default.createElement(
          "div",
          { className: "upload-progress" },
          _react2.default.createElement("img", { src: this.state.preview }),
          _react2.default.createElement(
            "div",
            { className: "progress" },
            _react2.default.createElement(
              "div",
              {
                className: "progress-bar",
                role: "progressbar",
                "aria-valuenow": "{this.state.progress}",
                "aria-valuemin": "0",
                "aria-valuemax": "100",
                style: { width: this.state.progress + "%" }
              },
              _react2.default.createElement(
                "span",
                { className: "sr-only" },
                this.getUploadProgressLabel()
              )
            )
          )
        )
      );
    }
  }, {
    key: "renderUpload",
    value: function renderUpload() {
      return _react2.default.createElement(
        "div",
        null,
        _react2.default.createElement("input", {
          type: "file",
          id: "avatar-hidden-upload",
          className: "hidden-file-upload",
          onChange: this.uploadFile
        }),
        this.state.image ? this.getUploadProgress() : this.getUploadButton(),
        _react2.default.createElement(
          "div",
          { className: "modal-footer" },
          _react2.default.createElement(
            "div",
            { className: "col-md-6 col-md-offset-3" },
            _react2.default.createElement(
              _button2.default,
              {
                onClick: this.props.showIndex,
                disabled: !!this.state.image,
                className: "btn-default btn-block"
              },
              gettext("Cancel")
            )
          )
        )
      );
    }
  }, {
    key: "renderCrop",
    value: function renderCrop() {
      return _react2.default.createElement(_crop2.default, {
        options: this.state.options,
        user: this.props.user,
        upload: this.state.uploaded,
        dataUrl: this.state.preview,
        onComplete: this.props.onComplete,
        showError: this.props.showError,
        showIndex: this.props.showIndex
      });
    }
  }, {
    key: "render",
    value: function render() {
      if (this.state.uploaded) return this.renderCrop();

      return this.renderUpload();
    }
  }]);

  return _class;
}(_react2.default.Component);

exports.default = _class;

},{"../../services/ajax":365,"../../services/snackbar":376,"../../utils/file-size":384,"../button":8,"./crop":22,"react":"react"}],27:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_React$Component) {
  _inherits(_class, _React$Component);

  function _class() {
    _classCallCheck(this, _class);

    return _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).apply(this, arguments));
  }

  _createClass(_class, [{
    key: "getClassName",
    value: function getClassName() {
      if (this.props.dropdown) {
        return "btn btn-default btn-aligned btn-icon btn-dropdown-toggle open hidden-md hidden-lg";
      } else {
        return "btn btn-default btn-aligned btn-icon btn-dropdown-toggle hidden-md hidden-lg";
      }
    }
  }, {
    key: "render",
    value: function render() {
      return _react2.default.createElement(
        "button",
        {
          className: this.getClassName(),
          type: "button",
          onClick: this.props.toggleNav,
          "aria-haspopup": "true",
          "aria-expanded": this.props.dropdown ? "true" : "false"
        },
        _react2.default.createElement(
          "i",
          { className: "material-icon" },
          "menu"
        )
      );
    }
  }]);

  return _class;
}(_react2.default.Component);

exports.default = _class;

},{"react":"react"}],28:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (_ref) {
  var display = _ref.display;

  if (!display) return null;

  return _react2.default.createElement(_panelMessage2.default, {
    helpText: gettext("No profile details are editable at this time."),
    message: gettext("This option is currently unavailable.")
  });
};

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _panelMessage = require("../panel-message");

var _panelMessage2 = _interopRequireDefault(_panelMessage);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

},{"../panel-message":94,"react":"react"}],29:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _select = require("../select");

var _select2 = _interopRequireDefault(_select);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_React$Component) {
  _inherits(_class, _React$Component);

  function _class() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, _class);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = _class.__proto__ || Object.getPrototypeOf(_class)).call.apply(_ref, [this].concat(args))), _this), _this.onChange = function (ev) {
      var _this$props = _this.props,
          field = _this$props.field,
          onChange = _this$props.onChange;

      onChange(field.fieldname, ev.target.value);
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(_class, [{
    key: "render",
    value: function render() {
      var _props = this.props,
          disabled = _props.disabled,
          field = _props.field,
          value = _props.value;
      var input = field.input;


      if (input.type === "select") {
        return _react2.default.createElement(_select2.default, {
          choices: input.choices,
          disabled: disabled,
          id: "id_" + field.fieldname,
          onChange: this.onChange,
          value: value
        });
      }

      if (input.type === "textarea") {
        return _react2.default.createElement("textarea", {
          className: "form-control",
          disabled: disabled,
          id: "id_" + field.fieldname,
          onChange: this.onChange,
          rows: "4",
          type: "text",
          value: value
        });
      }

      if (input.type === "text") {
        return _react2.default.createElement("input", {
          className: "form-control",
          disabled: disabled,
          id: "id_" + field.fieldname,
          onChange: this.onChange,
          type: "text",
          value: value
        });
      }

      return null;
    }
  }]);

  return _class;
}(_react2.default.Component);

exports.default = _class;

},{"../select":210,"react":"react"}],30:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (_ref) {
  var disabled = _ref.disabled,
      errors = _ref.errors,
      fields = _ref.fields,
      name = _ref.name,
      onChange = _ref.onChange,
      value = _ref.value;

  return _react2.default.createElement(
    "fieldset",
    null,
    _react2.default.createElement(
      "legend",
      null,
      name
    ),
    fields.map(function (field) {
      return _react2.default.createElement(
        _formGroup2.default,
        {
          "for": "id_" + field.fieldname,
          helpText: field.help_text,
          key: field.fieldname,
          label: field.label,
          validation: errors[field.fieldname]
        },
        _react2.default.createElement(_fieldInput2.default, {
          disabled: disabled,
          field: field,
          onChange: onChange,
          value: value[field.fieldname]
        })
      );
    })
  );
};

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _fieldInput = require("./field-input");

var _fieldInput2 = _interopRequireDefault(_fieldInput);

var _formGroup = require("../form-group");

var _formGroup2 = _interopRequireDefault(_formGroup);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

},{"../form-group":55,"./field-input":29,"react":"react"}],31:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

exports.CancelButton = CancelButton;

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _fieldset = require("./fieldset");

var _fieldset2 = _interopRequireDefault(_fieldset);

var _button = require("../button");

var _button2 = _interopRequireDefault(_button);

var _form = require("../form");

var _form2 = _interopRequireDefault(_form);

var _ajax = require("../../services/ajax");

var _ajax2 = _interopRequireDefault(_ajax);

var _snackbar = require("../../services/snackbar");

var _snackbar2 = _interopRequireDefault(_snackbar);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_Form) {
  _inherits(_class, _Form);

  function _class(props) {
    _classCallCheck(this, _class);

    var _this = _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).call(this, props));

    _this.onChange = function (name, value) {
      _this.setState(_defineProperty({}, name, value));
    };

    _this.state = {
      isLoading: false,

      errors: {}
    };

    var groups = props.groups.length;
    for (var i = 0; i < groups; i++) {
      var group = props.groups[i];
      var fields = group.fields.length;
      for (var f = 0; f < fields; f++) {
        var fieldname = group.fields[f].fieldname;
        var initial = group.fields[f].initial;
        _this.state[fieldname] = initial;
      }
    }
    return _this;
  }

  _createClass(_class, [{
    key: "send",
    value: function send() {
      var data = Object.assign({}, this.state, {
        errors: null,
        isLoading: null
      });

      return _ajax2.default.post(this.props.api, data);
    }
  }, {
    key: "handleSuccess",
    value: function handleSuccess(data) {
      this.props.onSuccess(data);
    }
  }, {
    key: "handleError",
    value: function handleError(rejection) {
      if (rejection.status === 400) {
        _snackbar2.default.error(gettext("Form contains errors."));
        this.setState({ errors: rejection });
      } else {
        _snackbar2.default.apiError(rejection);
      }
    }
  }, {
    key: "render",
    value: function render() {
      var _this2 = this;

      return _react2.default.createElement(
        "form",
        { onSubmit: this.handleSubmit },
        _react2.default.createElement(
          "div",
          { className: "panel-body" },
          this.props.groups.map(function (group, i) {
            return _react2.default.createElement(_fieldset2.default, {
              disabled: _this2.state.isLoading,
              errors: _this2.state.errors,
              fields: group.fields,
              name: group.name,
              key: i,
              onChange: _this2.onChange,
              value: _this2.state
            });
          })
        ),
        _react2.default.createElement(
          "div",
          { className: "panel-footer text-right" },
          _react2.default.createElement(CancelButton, {
            disabled: this.state.isLoading,
            onCancel: this.props.onCancel
          }),
          " ",
          _react2.default.createElement(
            _button2.default,
            { className: "btn-primary", loading: this.state.isLoading },
            gettext("Save changes")
          )
        )
      );
    }
  }]);

  return _class;
}(_form2.default);

exports.default = _class;
function CancelButton(_ref) {
  var onCancel = _ref.onCancel,
      disabled = _ref.disabled;

  if (!onCancel) return null;

  return _react2.default.createElement(
    "button",
    {
      className: "btn btn-default",
      disabled: disabled,
      onClick: onCancel,
      type: "button"
    },
    gettext("Cancel")
  );
}

},{"../../services/ajax":365,"../../services/snackbar":376,"../button":8,"../form":56,"./fieldset":30,"react":"react"}],32:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

exports.FormDisplay = FormDisplay;

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _blankslate = require("./blankslate");

var _blankslate2 = _interopRequireDefault(_blankslate);

var _loader = require("./loader");

var _loader2 = _interopRequireDefault(_loader);

var _form = require("./form");

var _form2 = _interopRequireDefault(_form);

var _ajax = require("../../services/ajax");

var _ajax2 = _interopRequireDefault(_ajax);

var _snackbar = require("../../services/snackbar");

var _snackbar2 = _interopRequireDefault(_snackbar);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_React$Component) {
  _inherits(_class, _React$Component);

  function _class(props) {
    _classCallCheck(this, _class);

    var _this = _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).call(this, props));

    _this.state = {
      loading: true,
      groups: null
    };
    return _this;
  }

  _createClass(_class, [{
    key: "componentDidMount",
    value: function componentDidMount() {
      var _this2 = this;

      _ajax2.default.get(this.props.api).then(function (groups) {
        _this2.setState({
          loading: false,

          groups: groups
        });
      }, function (rejection) {
        _snackbar2.default.apiError(rejection);
        if (_this2.props.cancel) {
          _this2.props.cancel();
        }
      });
    }
  }, {
    key: "render",
    value: function render() {
      var _state = this.state,
          groups = _state.groups,
          loading = _state.loading;


      return _react2.default.createElement(
        "div",
        { className: "panel panel-default panel-form" },
        _react2.default.createElement(
          "div",
          { className: "panel-heading" },
          _react2.default.createElement(
            "h3",
            { className: "panel-title" },
            gettext("Edit details")
          )
        ),
        _react2.default.createElement(_loader2.default, { display: loading }),
        _react2.default.createElement(_blankslate2.default, { display: !loading && !groups.length }),
        _react2.default.createElement(FormDisplay, {
          api: this.props.api,
          display: !loading && groups.length,
          groups: groups,
          onCancel: this.props.onCancel,
          onSuccess: this.props.onSuccess
        })
      );
    }
  }]);

  return _class;
}(_react2.default.Component);

exports.default = _class;
function FormDisplay(_ref) {
  var api = _ref.api,
      display = _ref.display,
      groups = _ref.groups,
      onCancel = _ref.onCancel,
      onSuccess = _ref.onSuccess;

  if (!display) return null;

  return _react2.default.createElement(_form2.default, { api: api, groups: groups, onCancel: onCancel, onSuccess: onSuccess });
}

},{"../../services/ajax":365,"../../services/snackbar":376,"./blankslate":28,"./form":31,"./loader":33,"react":"react"}],33:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (_ref) {
  var display = _ref.display;

  if (!display) return null;

  return _react2.default.createElement(
    "div",
    { className: "panel-body" },
    _react2.default.createElement(_loader2.default, null)
  );
};

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _loader = require("../loader");

var _loader2 = _interopRequireDefault(_loader);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

},{"../loader":58,"react":"react"}],34:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

exports.default = function (props) {
  return _react2.default.createElement(
    _action2.default,
    _extends({ execAction: insertSpoiler, title: gettext("Insert spoiler") }, props),
    _react2.default.createElement(
      "span",
      { className: "material-icon" },
      "not_interested"
    )
  );
};

exports.insertSpoiler = insertSpoiler;

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _action = require("./action");

var _action2 = _interopRequireDefault(_action);

var _isUrl = require("../../../utils/is-url");

var _isUrl2 = _interopRequireDefault(_isUrl);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function insertSpoiler(selection, replace) {
  replace("\n\n[spoiler]\n" + selection + "\n[/spoiler]\n\n");
}

},{"../../../utils/is-url":385,"./action":35,"react":"react"}],35:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_React$Component) {
  _inherits(_class, _React$Component);

  function _class() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, _class);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = _class.__proto__ || Object.getPrototypeOf(_class)).call.apply(_ref, [this].concat(args))), _this), _this.onClick = function () {
      _this.props.replaceSelection(_this.props.execAction);
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(_class, [{
    key: "render",
    value: function render() {
      return _react2.default.createElement(
        "button",
        {
          className: "btn btn-icon " + this.props.className,
          disabled: this.props.disabled,
          onClick: this.onClick,
          title: this.props.title,
          type: "button"
        },
        this.props.children
      );
    }
  }]);

  return _class;
}(_react2.default.Component);

exports.default = _class;

},{"react":"react"}],36:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

exports.default = function (props) {
  return _react2.default.createElement(
    _action2.default,
    _extends({ execAction: insertCode, title: gettext("Insert code") }, props),
    _react2.default.createElement(
      "span",
      { className: "material-icon" },
      "functions"
    )
  );
};

exports.insertCode = insertCode;

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _action = require("./action");

var _action2 = _interopRequireDefault(_action);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function insertCode(selection, replace) {
  var syntax = $.trim(prompt(gettext("Enter name of syntax of your code (optional)") + ":"));
  replace("\n\n```" + syntax + "\n" + selection + "\n```\n\n");
}

},{"./action":35,"react":"react"}],37:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

exports.default = function (props) {
  return _react2.default.createElement(
    _action2.default,
    _extends({
      execAction: makeEmphasis,
      title: gettext("Emphase selection")
    }, props),
    _react2.default.createElement(
      "span",
      { className: "material-icon" },
      "format_italic"
    )
  );
};

exports.makeEmphasis = makeEmphasis;

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _action = require("./action");

var _action2 = _interopRequireDefault(_action);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function makeEmphasis(selection, replace) {
  if (selection.length) {
    replace("*" + selection + "*");
  }
}

},{"./action":35,"react":"react"}],38:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

exports.default = function (props) {
  return _react2.default.createElement(
    _action2.default,
    _extends({
      execAction: insertHr,
      title: gettext("Insert horizontal ruler")
    }, props),
    _react2.default.createElement(
      "span",
      { className: "material-icon" },
      "remove"
    )
  );
};

exports.insertHr = insertHr;

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _action = require("./action");

var _action2 = _interopRequireDefault(_action);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function insertHr(selection, replace) {
  replace("\n\n- - - - -\n\n");
}

},{"./action":35,"react":"react"}],39:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

exports.default = function (props) {
  return _react2.default.createElement(
    _action2.default,
    _extends({ execAction: insertImage, title: gettext("Insert image") }, props),
    _react2.default.createElement(
      "span",
      { className: "material-icon" },
      "insert_photo"
    )
  );
};

exports.insertImage = insertImage;

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _action = require("./action");

var _action2 = _interopRequireDefault(_action);

var _isUrl = require("../../../utils/is-url");

var _isUrl2 = _interopRequireDefault(_isUrl);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function insertImage(selection, replace) {
  var url = "";
  var label = "";

  if (selection.length) {
    if ((0, _isUrl2.default)(selection)) {
      url = selection;
    } else {
      label = selection;
    }
  }

  url = $.trim(prompt(gettext("Enter link to image") + ":", url));
  label = $.trim(prompt(gettext("Enter image label (optional)") + ":", label));

  if (url.length) {
    if (label.length > 0) {
      replace("![" + label + "](" + url + ")");
    } else {
      replace("!(" + url + ")");
    }
  }
}

},{"../../../utils/is-url":385,"./action":35,"react":"react"}],40:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

exports.default = function (props) {
  return _react2.default.createElement(
    _action2.default,
    _extends({ execAction: insertLink, title: gettext("Insert link") }, props),
    _react2.default.createElement(
      "span",
      { className: "material-icon" },
      "insert_link"
    )
  );
};

exports.insertLink = insertLink;

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _action = require("./action");

var _action2 = _interopRequireDefault(_action);

var _isUrl = require("../../../utils/is-url");

var _isUrl2 = _interopRequireDefault(_isUrl);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function insertLink(selection, replace) {
  var url = "";
  var label = "";

  if (selection.length) {
    if ((0, _isUrl2.default)(selection)) {
      url = selection;
    } else {
      label = selection;
    }
  }

  url = $.trim(prompt(gettext("Enter link address") + ":", url) || "");
  if (url.length === 0) return false;
  label = $.trim(prompt(gettext("Enter link label (optional)") + ":", label));

  if (url.length) {
    if (label.length > 0) {
      replace("[" + label + "](" + url + ")");
    } else {
      replace(url);
    }
  }
}

},{"../../../utils/is-url":385,"./action":35,"react":"react"}],41:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

exports.default = function (props) {
  return _react2.default.createElement(
    _action2.default,
    _extends({ execAction: insertQuote, title: gettext("Insert quote") }, props),
    _react2.default.createElement(
      "span",
      { className: "material-icon" },
      "format_quote"
    )
  );
};

exports.insertQuote = insertQuote;

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _action = require("./action");

var _action2 = _interopRequireDefault(_action);

var _isUrl = require("../../../utils/is-url");

var _isUrl2 = _interopRequireDefault(_isUrl);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function insertQuote(selection, replace) {
  var title = $.trim(prompt(gettext("Enter quote autor, prefix usernames with @") + ":", title));

  if (title) {
    replace('\n\n[quote="' + title + '"]\n' + selection + "\n[/quote]\n\n");
  } else {
    replace("\n\n[quote]\n" + selection + "\n[/quote]\n\n");
  }
}

},{"../../../utils/is-url":385,"./action":35,"react":"react"}],42:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

exports.default = function (props) {
  return _react2.default.createElement(
    _action2.default,
    _extends({
      execAction: makeStrikethrough,
      title: gettext("Strikethrough selection")
    }, props),
    _react2.default.createElement(
      "span",
      { className: "material-icon" },
      "format_strikethrough"
    )
  );
};

exports.makeStrikethrough = makeStrikethrough;

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _action = require("./action");

var _action2 = _interopRequireDefault(_action);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function makeStrikethrough(selection, replace) {
  if (selection.length) {
    replace("~~" + selection + "~~");
  }
}

},{"./action":35,"react":"react"}],43:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

exports.default = function (props) {
  return _react2.default.createElement(
    _action2.default,
    _extends({
      execAction: makeStrong,
      title: gettext("Bolder selection")
    }, props),
    _react2.default.createElement(
      "span",
      { className: "material-icon" },
      "format_bold"
    )
  );
};

exports.makeStrong = makeStrong;

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _action = require("./action");

var _action2 = _interopRequireDefault(_action);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function makeStrong(selection, replace) {
  if (selection.length) {
    replace("**" + selection + "**");
  }
}

},{"./action":35,"react":"react"}],44:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

exports.Preview = Preview;
exports.Image = Image;
exports.Icon = Icon;
exports.Filename = Filename;
exports.Details = Details;
exports.Actions = Actions;
exports.Insert = Insert;
exports.Remove = Remove;
exports.Undo = Undo;

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _ = require("../../../..");

var _2 = _interopRequireDefault(_);

var _escapeHtml = require("../../../../utils/escape-html");

var _escapeHtml2 = _interopRequireDefault(_escapeHtml);

var _fileSize = require("../../../../utils/file-size");

var _fileSize2 = _interopRequireDefault(_fileSize);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var DATE_ABBR = '<abbr title="%(absolute)s">%(relative)s</abbr>';
var USER_SPAN = '<span class="item-title">%(user)s</span>';
var USER_URL = '<a href="%(url)s" class="item-title">%(user)s</a>';

var _class = function (_React$Component) {
  _inherits(_class, _React$Component);

  function _class() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, _class);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = _class.__proto__ || Object.getPrototypeOf(_class)).call.apply(_ref, [this].concat(args))), _this), _this.onInsert = function () {
      _this.props.replaceSelection(_this.insertAttachment);
    }, _this.insertAttachment = function (selection, replace) {
      var item = _this.props.item;

      if (item.is_image) {
        if (item.url.thumb) {
          replace("[![" + item.filename + "](" + item.url.thumb + ")](" + item.url.index + ")");
        } else {
          replace("[![" + item.filename + "](" + item.url.index + ")](" + item.url.index + ")");
        }
      } else {
        replace("[" + item.filename + "](" + item.url.index + ")");
      }
    }, _this.onRemove = function () {
      _this.updateItem({
        isRemoved: true
      });
    }, _this.onUndo = function () {
      _this.updateItem({
        isRemoved: false
      });
    }, _this.updateItem = function (newState) {
      var updatedAttachments = _this.props.attachments.map(function (item) {
        if (item.id === _this.props.item.id) {
          return Object.assign({}, item, newState);
        } else {
          return item;
        }
      });
      _this.props.onAttachmentsChange(updatedAttachments);
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(_class, [{
    key: "render",
    value: function render() {
      return _react2.default.createElement(
        "li",
        { className: "editor-attachment-complete" },
        _react2.default.createElement(
          "div",
          { className: "row" },
          _react2.default.createElement(
            "div",
            { className: "col-xs-12 col-sm-8 col-md-9" },
            _react2.default.createElement(Preview, this.props),
            _react2.default.createElement(
              "div",
              { className: "editor-attachment-details" },
              _react2.default.createElement(Filename, this.props),
              _react2.default.createElement(Details, this.props)
            )
          ),
          _react2.default.createElement(
            "div",
            { className: "col-xs-12 col-sm-4 col-md-3 xs-margin-top-half" },
            _react2.default.createElement(Actions, _extends({
              onInsert: this.onInsert,
              onRemove: this.onRemove,
              onUndo: this.onUndo
            }, this.props))
          )
        )
      );
    }
  }]);

  return _class;
}(_react2.default.Component);

exports.default = _class;
function Preview(props) {
  if (props.item.is_image) {
    return _react2.default.createElement(Image, props);
  } else {
    return _react2.default.createElement(Icon, props);
  }
}

function Image(props) {
  var thumbnailUrl = props.item.url.thumb || props.item.url.index;

  return _react2.default.createElement(
    "div",
    { className: "editor-attachment-image" },
    _react2.default.createElement("a", {
      href: props.item.url.index + "?shva=1",
      style: { backgroundImage: "url('" + thumbnailUrl + "?shva=1')" },
      target: "_blank"
    })
  );
}

function Icon(props) {
  return _react2.default.createElement(
    "div",
    { className: "editor-attachment-icon" },
    _react2.default.createElement(
      "span",
      { className: "material-icon" },
      "insert_drive_file"
    )
  );
}

function Filename(props) {
  return _react2.default.createElement(
    "h4",
    null,
    _react2.default.createElement(
      "a",
      {
        className: "item-title",
        href: props.item.url.index + "?shva=1",
        target: "_blank"
      },
      props.item.filename
    )
  );
}

function Details(props) {
  var user = null;
  if (props.item.url.uploader) {
    user = interpolate(USER_URL, {
      url: (0, _escapeHtml2.default)(props.item.url.uploader),
      user: (0, _escapeHtml2.default)(props.item.uploader_name)
    }, true);
  } else {
    user = interpolate(USER_SPAN, {
      user: (0, _escapeHtml2.default)(props.item.uploader_name)
    }, true);
  }

  var date = interpolate(DATE_ABBR, {
    absolute: (0, _escapeHtml2.default)(props.item.uploaded_on.format("LLL")),
    relative: (0, _escapeHtml2.default)(props.item.uploaded_on.fromNow())
  }, true);

  var message = interpolate((0, _escapeHtml2.default)(gettext("%(filetype)s, %(size)s, uploaded by %(uploader)s %(uploaded_on)s.")), {
    filetype: props.item.filetype,
    size: (0, _fileSize2.default)(props.item.size),
    uploader: user,
    uploaded_on: date
  }, true);

  return _react2.default.createElement("p", { dangerouslySetInnerHTML: { __html: message } });
}

function Actions(props) {
  return _react2.default.createElement(
    "div",
    { className: "editor-attachment-actions" },
    _react2.default.createElement(
      "div",
      { className: "row" },
      _react2.default.createElement(Insert, props),
      _react2.default.createElement(Remove, props),
      _react2.default.createElement(Undo, props)
    )
  );
}

function Insert(props) {
  if (!!props.item.isRemoved) {
    return null;
  }

  return _react2.default.createElement(
    "div",
    { className: "col-xs-6" },
    _react2.default.createElement(
      "button",
      {
        className: "btn btn-default btn-sm btn-block",
        onClick: props.onInsert,
        type: "button"
      },
      gettext("Insert")
    )
  );
}

function Remove(props) {
  if (!!props.item.isRemoved && props.item.acl.can_delete) {
    return null;
  }

  return _react2.default.createElement(
    "div",
    { className: "col-xs-6" },
    _react2.default.createElement(
      "button",
      {
        className: "btn btn-default btn-sm btn-block",
        onClick: props.onRemove,
        type: "button"
      },
      gettext("Remove")
    )
  );
}

function Undo(props) {
  if (!props.item.isRemoved) {
    return null;
  }

  return _react2.default.createElement(
    "div",
    { className: "col-xs-12" },
    _react2.default.createElement(
      "button",
      {
        className: "btn btn-default btn-sm btn-block",
        onClick: props.onUndo,
        type: "button"
      },
      gettext("Undo removal")
    )
  );
}

},{"../../../..":302,"../../../../utils/escape-html":383,"../../../../utils/file-size":384,"react":"react"}],45:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _escapeHtml = require("../../../../utils/escape-html");

var _escapeHtml2 = _interopRequireDefault(_escapeHtml);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var STRONG = "<strong>%(name)s</strong>";

var _class = function (_React$Component) {
  _inherits(_class, _React$Component);

  function _class() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, _class);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = _class.__proto__ || Object.getPrototypeOf(_class)).call.apply(_ref, [this].concat(args))), _this), _this.onClick = function () {
      var filteredAttachments = _this.props.attachments.filter(function (item) {
        return item.key !== _this.props.item.key;
      });
      _this.props.onAttachmentsChange(filteredAttachments);
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(_class, [{
    key: "render",
    value: function render() {
      var filename = interpolate(STRONG, {
        name: (0, _escapeHtml2.default)(this.props.item.filename)
      }, true);

      var title = interpolate(gettext("Error uploading %(filename)s"), {
        filename: filename,
        progress: this.props.item.progress + "%"
      }, true);

      return _react2.default.createElement(
        "li",
        { className: "editor-attachment-error" },
        _react2.default.createElement(
          "div",
          { className: "editor-attachment-error-icon" },
          _react2.default.createElement(
            "span",
            { className: "material-icon" },
            "warning"
          )
        ),
        _react2.default.createElement(
          "div",
          { className: "editor-attachment-error-message" },
          _react2.default.createElement("h4", { dangerouslySetInnerHTML: { __html: title + ":" } }),
          _react2.default.createElement(
            "p",
            null,
            this.props.item.error
          ),
          _react2.default.createElement(
            "button",
            {
              className: "btn btn-default btn-sm",
              onClick: this.onClick,
              type: "button"
            },
            gettext("Dismiss")
          )
        )
      );
    }
  }]);

  return _class;
}(_react2.default.Component);

exports.default = _class;

},{"../../../../utils/escape-html":383,"react":"react"}],46:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (props) {
  if (props.item.id) {
    return _react2.default.createElement(_complete2.default, props);
  }

  if (props.item.error) {
    return _react2.default.createElement(_error2.default, props);
  }

  return _react2.default.createElement(_upload2.default, props);
};

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _complete = require("./complete");

var _complete2 = _interopRequireDefault(_complete);

var _error = require("./error");

var _error2 = _interopRequireDefault(_error);

var _upload = require("./upload");

var _upload2 = _interopRequireDefault(_upload);

var _ = require("../../../..");

var _2 = _interopRequireDefault(_);

var _escapeHtml = require("../../../../utils/escape-html");

var _escapeHtml2 = _interopRequireDefault(_escapeHtml);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

},{"../../../..":302,"../../../../utils/escape-html":383,"./complete":44,"./error":45,"./upload":47,"react":"react"}],47:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (props) {
  var filename = interpolate(STRONG, {
    name: (0, _escapeHtml2.default)(props.item.filename)
  }, true);

  var message = interpolate(gettext("Uploading %(filename)s... %(progress)s"), {
    filename: filename,
    progress: props.item.progress + "%"
  }, true);

  return _react2.default.createElement(
    "li",
    { className: "editor-attachment-upload" },
    _react2.default.createElement(
      "div",
      { className: "editor-attachment-progress-bar" },
      _react2.default.createElement("div", {
        className: "editor-attachment-progress",
        style: { width: props.item.progress + "%" }
      })
    ),
    _react2.default.createElement("p", {
      className: "editor-attachment-upload-message",
      dangerouslySetInnerHTML: { __html: message }
    })
  );
};

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _escapeHtml = require("../../../../utils/escape-html");

var _escapeHtml2 = _interopRequireDefault(_escapeHtml);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var STRONG = "<strong>%(name)s</strong>";

},{"../../../../utils/escape-html":383,"react":"react"}],48:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (props) {
  if (!_2.default.get("user").acl.max_attachment_size) {
    return null;
  }

  return _react2.default.createElement(
    "div",
    { className: "editor-attachments" },
    _react2.default.createElement(_list2.default, props),
    _react2.default.createElement(_uploader2.default, props)
  );
};

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _list = require("./list");

var _list2 = _interopRequireDefault(_list);

var _uploader = require("./uploader");

var _uploader2 = _interopRequireDefault(_uploader);

var _ = require("../../..");

var _2 = _interopRequireDefault(_);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

},{"../../..":302,"./list":49,"./uploader":51,"react":"react"}],49:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

exports.default = function (props) {
  return _react2.default.createElement(
    "ul",
    { className: "list-unstyled editor-attachments-list" },
    props.attachments.map(function (item) {
      return _react2.default.createElement(_attachment2.default, _extends({ item: item, key: item.id || item.key }, props));
    })
  );
};

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _attachment = require("./attachment");

var _attachment2 = _interopRequireDefault(_attachment);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

},{"./attachment":46,"react":"react"}],50:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _ = require("../../..");

var _2 = _interopRequireDefault(_);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_React$Component) {
  _inherits(_class, _React$Component);

  function _class() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, _class);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = _class.__proto__ || Object.getPrototypeOf(_class)).call.apply(_ref, [this].concat(args))), _this), _this.onClick = function () {
      document.getElementById("editor-upload-field").click();
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(_class, [{
    key: "render",
    value: function render() {
      if (!_2.default.get("user").acl.max_attachment_size) {
        return null;
      }

      return _react2.default.createElement(
        "button",
        {
          className: "btn btn-icon " + this.props.className,
          disabled: this.props.disabled,
          onClick: this.onClick,
          title: gettext("Upload file"),
          type: "button"
        },
        _react2.default.createElement(
          "span",
          { className: "material-icon" },
          "file_upload"
        )
      );
    }
  }]);

  return _class;
}(_react2.default.Component);

exports.default = _class;

},{"../../..":302,"react":"react"}],51:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

exports.getRandomKey = getRandomKey;

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _moment = require("moment");

var _moment2 = _interopRequireDefault(_moment);

var _ = require("../../..");

var _2 = _interopRequireDefault(_);

var _ajax = require("../../../services/ajax");

var _ajax2 = _interopRequireDefault(_ajax);

var _snackbar = require("../../../services/snackbar");

var _snackbar2 = _interopRequireDefault(_snackbar);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_React$Component) {
  _inherits(_class, _React$Component);

  function _class() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, _class);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = _class.__proto__ || Object.getPrototypeOf(_class)).call.apply(_ref, [this].concat(args))), _this), _this.onChange = function (event) {
      var file = event.target.files[0];
      if (!file) {
        return;
      }

      var upload = {
        id: null,
        key: getRandomKey(),
        progress: 0,
        error: null,
        filename: file.name
      };

      _this.props.onAttachmentsChange([upload].concat(_this.props.attachments));

      var data = new FormData();
      data.append("upload", file);

      _ajax2.default.upload(_2.default.get("ATTACHMENTS_API"), data, function (progress) {
        upload.progress = progress;
        _this.props.onAttachmentsChange(_this.props.attachments.concat());
      }).then(function (data) {
        data.uploaded_on = (0, _moment2.default)(data.uploaded_on);
        Object.assign(upload, data);
        _this.props.onAttachmentsChange(_this.props.attachments.concat());
      }, function (rejection) {
        if (rejection.status === 400 || rejection.status === 413) {
          upload.error = rejection.detail;
          _this.props.onAttachmentsChange(_this.props.attachments.concat());
        } else {
          _snackbar2.default.apiError(rejection);
        }
      });
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(_class, [{
    key: "render",
    value: function render() {
      return _react2.default.createElement("input", { id: "editor-upload-field", onChange: this.onChange, type: "file" });
    }
  }]);

  return _class;
}(_react2.default.Component);

exports.default = _class;
function getRandomKey() {
  return "upld-" + Math.round(new Date().getTime());
}

},{"../../..":302,"../../../services/ajax":365,"../../../services/snackbar":376,"moment":"moment","react":"react"}],52:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

exports.Protect = Protect;

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _code = require("./actions/code");

var _code2 = _interopRequireDefault(_code);

var _emphasis = require("./actions/emphasis");

var _emphasis2 = _interopRequireDefault(_emphasis);

var _hr = require("./actions/hr");

var _hr2 = _interopRequireDefault(_hr);

var _image = require("./actions/image");

var _image2 = _interopRequireDefault(_image);

var _link = require("./actions/link");

var _link2 = _interopRequireDefault(_link);

var _Spoiler = require("./actions/Spoiler");

var _Spoiler2 = _interopRequireDefault(_Spoiler);

var _strikethrough = require("./actions/strikethrough");

var _strikethrough2 = _interopRequireDefault(_strikethrough);

var _strong = require("./actions/strong");

var _strong2 = _interopRequireDefault(_strong);

var _quote = require("./actions/quote");

var _quote2 = _interopRequireDefault(_quote);

var _attachments = require("./attachments");

var _attachments2 = _interopRequireDefault(_attachments);

var _uploadButton = require("./attachments/upload-button");

var _uploadButton2 = _interopRequireDefault(_uploadButton);

var _markupPreview = require("./markup-preview");

var _markupPreview2 = _interopRequireDefault(_markupPreview);

var _textutils = require("./textutils");

var textUtils = _interopRequireWildcard(_textutils);

var _button = require("../button");

var _button2 = _interopRequireDefault(_button);

var _ = require("../..");

var _2 = _interopRequireDefault(_);

var _ajax = require("../../services/ajax");

var _ajax2 = _interopRequireDefault(_ajax);

var _modal = require("../../services/modal");

var _modal2 = _interopRequireDefault(_modal);

var _snackbar = require("../../services/snackbar");

var _snackbar2 = _interopRequireDefault(_snackbar);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_React$Component) {
  _inherits(_class, _React$Component);

  function _class(props) {
    _classCallCheck(this, _class);

    var _this = _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).call(this, props));

    _this.onPreviewClick = function () {
      if (_this.state.isPreviewLoading) {
        return;
      }

      _this.setState({
        isPreviewLoading: true
      });

      _ajax2.default.post(_2.default.get("PARSE_MARKUP_API"), { post: _this.props.value }).then(function (data) {
        _modal2.default.show(_react2.default.createElement(_markupPreview2.default, { markup: data.parsed }));

        _this.setState({
          isPreviewLoading: false
        });
      }, function (rejection) {
        if (rejection.status === 400) {
          _snackbar2.default.error(rejection.detail);
        } else {
          _snackbar2.default.apiError(rejection);
        }

        _this.setState({
          isPreviewLoading: false
        });
      });
    };

    _this.replaceSelection = function (operation) {
      operation(textUtils.getSelectionText(), _this._replaceSelection);
    };

    _this._replaceSelection = function (newValue) {
      _this.props.onChange({
        target: {
          value: textUtils.replace(newValue)
        }
      });
    };

    _this.state = {
      isPreviewLoading: false
    };
    return _this;
  }

  _createClass(_class, [{
    key: "componentDidMount",
    value: function componentDidMount() {
      var _this2 = this;

      $("#editor-textarea").atwho({
        at: "@",
        displayTpl: '<li><img src="${avatar}" alt="">${username}</li>',
        insertTpl: "@${username}",
        searchKey: "username",
        callbacks: {
          remoteFilter: function remoteFilter(query, callback) {
            $.getJSON(_2.default.get("MENTION_API"), { q: query }, callback);
          }
        }
      });

      $("#editor-textarea").on("inserted.atwho", function (event, flag, query) {
        _this2.props.onChange(event);
      });
    }
  }, {
    key: "render",
    value: function render() {
      return _react2.default.createElement(
        "div",
        { className: "editor-border" },
        _react2.default.createElement("textarea", {
          className: "form-control",
          value: this.props.value,
          disabled: this.props.loading,
          id: "editor-textarea",
          onChange: this.props.onChange,
          rows: "9"
        }),
        _react2.default.createElement(
          "div",
          { className: "editor-footer" },
          _react2.default.createElement(
            "div",
            { className: "buttons-list pull-left" },
            _react2.default.createElement(_strong2.default, {
              className: "btn-default btn-sm pull-left",
              disabled: this.props.loading || this.state.isPreviewLoading,
              replaceSelection: this.replaceSelection
            }),
            _react2.default.createElement(_emphasis2.default, {
              className: "btn-default btn-sm pull-left",
              disabled: this.props.loading || this.state.isPreviewLoading,
              replaceSelection: this.replaceSelection
            }),
            _react2.default.createElement(_strikethrough2.default, {
              className: "btn-default btn-sm pull-left",
              disabled: this.props.loading || this.state.isPreviewLoading,
              replaceSelection: this.replaceSelection
            }),
            _react2.default.createElement(_hr2.default, {
              className: "btn-default btn-sm pull-left",
              disabled: this.props.loading || this.state.isPreviewLoading,
              replaceSelection: this.replaceSelection
            }),
            _react2.default.createElement(_link2.default, {
              className: "btn-default btn-sm pull-left",
              disabled: this.props.loading || this.state.isPreviewLoading,
              replaceSelection: this.replaceSelection
            }),
            _react2.default.createElement(_image2.default, {
              className: "btn-default btn-sm pull-left",
              disabled: this.props.loading || this.state.isPreviewLoading,
              replaceSelection: this.replaceSelection
            }),
            _react2.default.createElement(_quote2.default, {
              className: "btn-default btn-sm pull-left",
              disabled: this.props.loading || this.state.isPreviewLoading,
              replaceSelection: this.replaceSelection
            }),
            _react2.default.createElement(_Spoiler2.default, {
              className: "btn-default btn-sm pull-left",
              disabled: this.props.loading || this.state.isPreviewLoading,
              replaceSelection: this.replaceSelection
            }),
            _react2.default.createElement(_code2.default, {
              className: "btn-default btn-sm pull-left",
              disabled: this.props.loading || this.state.isPreviewLoading,
              replaceSelection: this.replaceSelection
            }),
            _react2.default.createElement(_uploadButton2.default, {
              className: "btn-default btn-sm pull-left",
              disabled: this.props.loading || this.state.isPreviewLoading
            })
          ),
          _react2.default.createElement(
            _button2.default,
            {
              className: "btn-default btn-sm pull-left",
              disabled: this.props.loading || this.state.isPreviewLoading,
              onClick: this.onPreviewClick,
              type: "button"
            },
            gettext("Preview")
          ),
          _react2.default.createElement(
            _button2.default,
            {
              className: "btn-primary btn-sm pull-right",
              loading: this.props.loading
            },
            this.props.submitLabel || gettext("Post")
          ),
          _react2.default.createElement(
            "button",
            {
              className: "btn btn-default btn-sm pull-right",
              disabled: this.props.loading,
              onClick: this.props.onCancel,
              type: "button"
            },
            gettext("Cancel")
          ),
          _react2.default.createElement("div", { className: "clearfix visible-xs-block" }),
          _react2.default.createElement(Protect, {
            canProtect: this.props.canProtect,
            disabled: this.props.loading,
            onProtect: this.props.onProtect,
            onUnprotect: this.props.onUnprotect,
            protect: this.props.protect
          })
        ),
        _react2.default.createElement(_attachments2.default, {
          attachments: this.props.attachments,
          onAttachmentsChange: this.props.onAttachmentsChange,
          placeholder: this.props.placeholder,
          replaceSelection: this.replaceSelection
        })
      );
    }
  }]);

  return _class;
}(_react2.default.Component);

exports.default = _class;
function Protect(props) {
  if (!props.canProtect) return null;

  var label = props.protect ? gettext("Protected") : gettext("Protect");

  return _react2.default.createElement(
    "button",
    {
      className: "btn btn-icon btn-default btn-protect btn-sm pull-right",
      disabled: props.disabled,
      onClick: props.protect ? props.onUnprotect : props.onProtect,
      title: label,
      type: "button"
    },
    _react2.default.createElement(
      "span",
      { className: "material-icon" },
      props.protect ? "lock" : "lock_outline"
    ),
    _react2.default.createElement(
      "span",
      { className: "btn-text hidden-md hidden-lg" },
      label
    )
  );
}

},{"../..":302,"../../services/ajax":365,"../../services/modal":371,"../../services/snackbar":376,"../button":8,"./actions/Spoiler":34,"./actions/code":36,"./actions/emphasis":37,"./actions/hr":38,"./actions/image":39,"./actions/link":40,"./actions/quote":41,"./actions/strikethrough":42,"./actions/strong":43,"./attachments":48,"./attachments/upload-button":50,"./markup-preview":53,"./textutils":54,"react":"react"}],53:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (props) {
  return _react2.default.createElement(
    "div",
    { className: "modal-dialog", role: "document" },
    _react2.default.createElement(
      "div",
      { className: "modal-content" },
      _react2.default.createElement(
        "div",
        { className: "modal-header" },
        _react2.default.createElement(
          "button",
          {
            "aria-label": gettext("Close"),
            className: "close",
            "data-dismiss": "modal",
            type: "button"
          },
          _react2.default.createElement(
            "span",
            { "aria-hidden": "true" },
            "\xD7"
          )
        ),
        _react2.default.createElement(
          "h4",
          { className: "modal-title" },
          gettext("Preview message")
        )
      ),
      _react2.default.createElement(
        "div",
        { className: "modal-body markup-preview" },
        _react2.default.createElement(_misagoMarkup2.default, { markup: props.markup })
      )
    )
  );
};

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _misagoMarkup = require("../misago-markup");

var _misagoMarkup2 = _interopRequireDefault(_misagoMarkup);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

},{"../misago-markup":60,"react":"react"}],54:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getTextarea = getTextarea;
exports.getValue = getValue;
exports.getSelectionRange = getSelectionRange;
exports.getSelection = getSelection;
exports.getSelectionText = getSelectionText;
exports.setSelection = setSelection;
exports._replace = _replace;
exports.replace = replace;
var textareaId = exports.textareaId = "editor-textarea";

function getTextarea() {
  return document.getElementById(textareaId);
}

function getValue() {
  return document.getElementById(textareaId).value;
}

function getSelectionRange(start, end) {
  return {
    start: start,
    end: end
  };
}

function getSelection() {
  var ctrl = getTextarea();
  if (document.selection) {
    ctrl.focus();
    var range = document.selection.createRange();
    var length = range.text.length;
    range.moveStart("character", -ctrl.value.length);
    return getSelectionRange(range.text.length - length, range.text.length);
  } else if (ctrl.selectionStart || ctrl.selectionStart == "0") {
    return getSelectionRange(ctrl.selectionStart, ctrl.selectionEnd);
  }
}

function getSelectionText() {
  var range = getSelection();
  return $.trim(getValue().substring(range.start, range.end));
}

function setSelection(selectionRange) {
  var ctrl = getTextarea();
  if (ctrl.setSelectionRange) {
    ctrl.focus();
    ctrl.setSelectionRange(selectionRange.start, selectionRange.end);
  } else if (ctrl.createTextRange) {
    var range = ctrl.createTextRange();
    range.collapse(true);
    range.moveStart("character", selectionRange.start);
    range.moveEnd("character", selectionRange.end);
    range.select();
  }
}

function _replace(myRange, replacement) {
  var ctrl = getTextarea();
  var text = ctrl.value;
  var startText = text.substring(0, myRange.start);
  ctrl.value = text.substring(0, myRange.start) + replacement + text.substring(myRange.end);
  setSelection(getSelectionRange(startText.length + replacement.length, startText.length + replacement.length));
  return ctrl.value;
}

function replace(replacement) {
  return _replace(getSelection(), replacement);
}

},{}],55:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_React$Component) {
  _inherits(_class, _React$Component);

  function _class() {
    _classCallCheck(this, _class);

    return _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).apply(this, arguments));
  }

  _createClass(_class, [{
    key: "isValidated",
    value: function isValidated() {
      return typeof this.props.validation !== "undefined";
    }
  }, {
    key: "getClassName",
    value: function getClassName() {
      var className = "form-group";
      if (this.isValidated()) {
        className += " has-feedback";
        if (this.props.validation === null) {
          className += " has-success";
        } else {
          className += " has-error";
        }
      }
      return className;
    }
  }, {
    key: "getFeedback",
    value: function getFeedback() {
      var _this2 = this;

      if (this.props.validation) {
        return _react2.default.createElement(
          "div",
          { className: "help-block errors" },
          this.props.validation.map(function (error, i) {
            return _react2.default.createElement(
              "p",
              { key: _this2.props.for + "FeedbackItem" + i },
              error
            );
          })
        );
      } else {
        return null;
      }
    }
  }, {
    key: "getFeedbackDescription",
    value: function getFeedbackDescription() {
      if (this.isValidated()) {
        return _react2.default.createElement(
          "span",
          { id: this.props.for + "_status", className: "sr-only" },
          this.props.validation ? gettext("(error)") : gettext("(success)")
        );
      } else {
        return null;
      }
    }
  }, {
    key: "getHelpText",
    value: function getHelpText() {
      if (this.props.helpText) {
        return _react2.default.createElement(
          "p",
          { className: "help-block" },
          this.props.helpText
        );
      } else {
        return null;
      }
    }
  }, {
    key: "render",
    value: function render() {
      return _react2.default.createElement(
        "div",
        { className: this.getClassName() },
        _react2.default.createElement(
          "label",
          {
            className: "control-label " + (this.props.labelClass || ""),
            htmlFor: this.props.for || ""
          },
          this.props.label + ":"
        ),
        _react2.default.createElement(
          "div",
          { className: this.props.controlClass || "" },
          this.props.children,
          this.getFeedbackDescription(),
          this.getFeedback(),
          this.getHelpText(),
          this.props.extra || null
        )
      );
    }
  }]);

  return _class;
}(_react2.default.Component);

exports.default = _class;

},{"react":"react"}],56:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _validators = require("../utils/validators");

var _snackbar = require("../services/snackbar");

var _snackbar2 = _interopRequireDefault(_snackbar);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var validateRequired = (0, _validators.required)();

var _class = function (_React$Component) {
  _inherits(_class, _React$Component);

  function _class() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, _class);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = _class.__proto__ || Object.getPrototypeOf(_class)).call.apply(_ref, [this].concat(args))), _this), _this.bindInput = function (name) {
      return function (event) {
        _this.changeValue(name, event.target.value);
      };
    }, _this.changeValue = function (name, value) {
      var newState = _defineProperty({}, name, value);

      var formErrors = _this.state.errors || {};
      formErrors[name] = _this.validateField(name, newState[name]);
      newState.errors = formErrors;

      _this.setState(newState);
    }, _this.handleSubmit = function (event) {
      // we don't reload page on submissions
      if (event) {
        event.preventDefault();
      }

      if (_this.state.isLoading) {
        return;
      }

      if (_this.clean()) {
        _this.setState({ isLoading: true });
        var promise = _this.send();

        if (promise) {
          promise.then(function (success) {
            _this.setState({ isLoading: false });
            _this.handleSuccess(success);
          }, function (rejection) {
            _this.setState({ isLoading: false });
            _this.handleError(rejection);
          });
        } else {
          _this.setState({ isLoading: false });
        }
      }
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(_class, [{
    key: "validate",
    value: function validate() {
      var errors = {};
      if (!this.state.validators) {
        return errors;
      }

      var validators = {
        required: this.state.validators.required || this.state.validators,
        optional: this.state.validators.optional || {}
      };

      var validatedFields = [];

      // add required fields to validation
      for (var name in validators.required) {
        if (validators.required.hasOwnProperty(name) && validators.required[name]) {
          validatedFields.push(name);
        }
      }

      // add optional fields to validation
      for (var _name in validators.optional) {
        if (validators.optional.hasOwnProperty(_name) && validators.optional[_name]) {
          validatedFields.push(_name);
        }
      }

      // validate fields values
      for (var i in validatedFields) {
        var _name2 = validatedFields[i];
        var fieldErrors = this.validateField(_name2, this.state[_name2]);

        if (fieldErrors === null) {
          errors[_name2] = null;
        } else if (fieldErrors) {
          errors[_name2] = fieldErrors;
        }
      }

      return errors;
    }
  }, {
    key: "isValid",
    value: function isValid() {
      var errors = this.validate();
      for (var field in errors) {
        if (errors.hasOwnProperty(field)) {
          if (errors[field] !== null) {
            return false;
          }
        }
      }

      return true;
    }
  }, {
    key: "validateField",
    value: function validateField(name, value) {
      var errors = [];
      if (!this.state.validators) {
        return errors;
      }

      var validators = {
        required: (this.state.validators.required || this.state.validators)[name],
        optional: (this.state.validators.optional || {})[name]
      };

      var requiredError = validateRequired(value) || false;

      if (validators.required) {
        if (requiredError) {
          errors = [requiredError];
        } else {
          for (var i in validators.required) {
            var validationError = validators.required[i](value);
            if (validationError) {
              errors.push(validationError);
            }
          }
        }

        return errors.length ? errors : null;
      } else if (requiredError === false && validators.optional) {
        for (var _i in validators.optional) {
          var _validationError = validators.optional[_i](value);
          if (_validationError) {
            errors.push(_validationError);
          }
        }

        return errors.length ? errors : null;
      }

      return false; // false === field wasn't validated
    }
  }, {
    key: "clean",
    value: function clean() {
      return true;
    }
  }, {
    key: "send",
    value: function send() {
      return null;
    }
  }, {
    key: "handleSuccess",
    value: function handleSuccess(success) {
      return;
    }
  }, {
    key: "handleError",
    value: function handleError(rejection) {
      _snackbar2.default.apiError(rejection);
    }
  }]);

  return _class;
}(_react2.default.Component);

exports.default = _class;

},{"../services/snackbar":376,"../utils/validators":393,"react":"react"}],57:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_React$Component) {
  _inherits(_class, _React$Component);

  function _class() {
    _classCallCheck(this, _class);

    return _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).apply(this, arguments));
  }

  _createClass(_class, [{
    key: "isActive",
    value: function isActive() {
      if (this.props.isControlled) {
        return this.props.isActive;
      } else {
        if (this.props.path) {
          return document.location.pathname.indexOf(this.props.path) === 0;
        } else {
          return false;
        }
      }
    }
  }, {
    key: "getClassName",
    value: function getClassName() {
      if (this.isActive()) {
        return (this.props.className || "") + " " + (this.props.activeClassName || "active");
      } else {
        return this.props.className || "";
      }
    }
  }, {
    key: "render",
    value: function render() {
      return _react2.default.createElement(
        "li",
        { className: this.getClassName() },
        this.props.children
      );
    }
  }]);

  return _class;
}(_react2.default.Component);

exports.default = _class;

},{"react":"react"}],58:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (props) {
  return _react2.default.createElement(
    "div",
    { className: props.className || "loader" },
    _react2.default.createElement("div", { className: "loader-spinning-wheel" })
  );
};

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

},{"react":"react"}],59:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

exports.BestAnswerSelect = BestAnswerSelect;
exports.PollSelect = PollSelect;

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _button = require("./button");

var _button2 = _interopRequireDefault(_button);

var _form = require("./form");

var _form2 = _interopRequireDefault(_form);

var _formGroup = require("./form-group");

var _formGroup2 = _interopRequireDefault(_formGroup);

var _ajax = require("../services/ajax");

var _ajax2 = _interopRequireDefault(_ajax);

var _modal = require("../services/modal");

var _modal2 = _interopRequireDefault(_modal);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_Form) {
  _inherits(_class, _Form);

  function _class(props) {
    _classCallCheck(this, _class);

    var _this = _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).call(this, props));

    _this.handleSuccess = function (success) {
      _this.props.onSuccess(success);
      _modal2.default.hide();
    };

    _this.handleError = function (rejection) {
      _this.props.onError(rejection);
    };

    _this.onBestAnswerChange = function (event) {
      _this.changeValue("bestAnswer", event.target.value);
    };

    _this.onPollChange = function (event) {
      _this.changeValue("poll", event.target.value);
    };

    _this.state = {
      isLoading: false,

      bestAnswer: "0",
      poll: "0"
    };
    return _this;
  }

  _createClass(_class, [{
    key: "clean",
    value: function clean() {
      if (this.props.polls && this.state.poll === "0") {
        var confirmation = confirm(gettext("Are you sure you want to delete all polls?"));
        return confirmation;
      }

      return true;
    }
  }, {
    key: "send",
    value: function send() {
      var data = Object.assign({}, this.props.data, {
        best_answer: this.state.bestAnswer,
        poll: this.state.poll
      });

      return _ajax2.default.post(this.props.api, data);
    }
  }, {
    key: "render",
    value: function render() {
      return _react2.default.createElement(
        "div",
        { className: "modal-dialog", role: "document" },
        _react2.default.createElement(
          "div",
          { className: "modal-content" },
          _react2.default.createElement(
            "div",
            { className: "modal-header" },
            _react2.default.createElement(
              "button",
              {
                "aria-label": gettext("Close"),
                className: "close",
                "data-dismiss": "modal",
                type: "button"
              },
              _react2.default.createElement(
                "span",
                { "aria-hidden": "true" },
                "\xD7"
              )
            ),
            _react2.default.createElement(
              "h4",
              { className: "modal-title" },
              gettext("Merge threads")
            )
          ),
          _react2.default.createElement(
            "form",
            { onSubmit: this.handleSubmit },
            _react2.default.createElement(
              "div",
              { className: "modal-body" },
              _react2.default.createElement(BestAnswerSelect, {
                choices: this.props.bestAnswers,
                onChange: this.onBestAnswerChange,
                value: this.state.bestAnswer
              }),
              _react2.default.createElement(PollSelect, {
                choices: this.props.polls,
                onChange: this.onPollChange,
                value: this.state.poll
              })
            ),
            _react2.default.createElement(
              "div",
              { className: "modal-footer" },
              _react2.default.createElement(
                "button",
                {
                  className: "btn btn-default",
                  "data-dismiss": "modal",
                  disabled: this.state.isLoading,
                  type: "button"
                },
                gettext("Cancel")
              ),
              _react2.default.createElement(
                _button2.default,
                { className: "btn-primary", loading: this.state.isLoading },
                gettext("Merge threads")
              )
            )
          )
        )
      );
    }
  }]);

  return _class;
}(_form2.default);

exports.default = _class;
function BestAnswerSelect(_ref) {
  var choices = _ref.choices,
      onChange = _ref.onChange,
      value = _ref.value;

  if (!choices) return null;

  return _react2.default.createElement(
    _formGroup2.default,
    {
      label: gettext("Best answer"),
      helpText: gettext("Please select the best answer for your newly merged thread. No posts will be deleted during the merge."),
      "for": "id_best_answer"
    },
    _react2.default.createElement(
      "select",
      {
        className: "form-control",
        id: "id_best_answer",
        onChange: onChange,
        value: value
      },
      choices.map(function (choice) {
        return _react2.default.createElement(
          "option",
          { value: choice[0], key: choice[0] },
          choice[1]
        );
      })
    )
  );
}

function PollSelect(_ref2) {
  var choices = _ref2.choices,
      onChange = _ref2.onChange,
      value = _ref2.value;

  if (!choices) return null;

  return _react2.default.createElement(
    _formGroup2.default,
    {
      label: gettext("Poll"),
      helpText: gettext("Please select the poll for your newly merged thread. Rejected polls will be permanently deleted and cannot be recovered."),
      "for": "id_poll"
    },
    _react2.default.createElement(
      "select",
      {
        className: "form-control",
        id: "id_poll",
        onChange: onChange,
        value: value
      },
      choices.map(function (choice) {
        return _react2.default.createElement(
          "option",
          { value: choice[0], key: choice[0] },
          choice[1]
        );
      })
    )
  );
}

},{"../services/ajax":365,"../services/modal":371,"./button":8,"./form":56,"./form-group":55,"react":"react"}],60:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _oneBox = require("../services/one-box");

var _oneBox2 = _interopRequireDefault(_oneBox);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_React$Component) {
  _inherits(_class, _React$Component);

  function _class() {
    _classCallCheck(this, _class);

    return _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).apply(this, arguments));
  }

  _createClass(_class, [{
    key: "componentDidMount",
    value: function componentDidMount() {
      _oneBox2.default.render(this.documentNode);
      $(this.documentNode).find(".spoiler-reveal").click(revealSpoiler);
    }
  }, {
    key: "componentDidUpdate",
    value: function componentDidUpdate(prevProps, prevState) {
      _oneBox2.default.render(this.documentNode);
      $(this.documentNode).find(".spoiler-reveal").click(revealSpoiler);
    }
  }, {
    key: "shouldComponentUpdate",
    value: function shouldComponentUpdate(nextProps, nextState) {
      return nextProps.markup !== this.props.markup;
    }
  }, {
    key: "render",
    value: function render() {
      var _this2 = this;

      return _react2.default.createElement("article", {
        className: "misago-markup",
        dangerouslySetInnerHTML: { __html: this.props.markup },
        ref: function ref(node) {
          _this2.documentNode = node;
        }
      });
    }
  }]);

  return _class;
}(_react2.default.Component);

exports.default = _class;


function revealSpoiler(event) {
  var btn = event.target;
  $(btn).parent().parent().addClass("revealed");
}

},{"../services/one-box":372,"react":"react"}],61:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _loader = require("./loader");

var _loader2 = _interopRequireDefault(_loader);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_React$Component) {
  _inherits(_class, _React$Component);

  function _class() {
    _classCallCheck(this, _class);

    return _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).apply(this, arguments));
  }

  _createClass(_class, [{
    key: "render",
    value: function render() {
      return _react2.default.createElement(
        "div",
        { className: "modal-body modal-loader" },
        _react2.default.createElement(_loader2.default, null)
      );
    }
  }]);

  return _class;
}(_react2.default.Component);

exports.default = _class;

},{"./loader":58,"react":"react"}],62:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _panelMessage = require("./panel-message");

var _panelMessage2 = _interopRequireDefault(_panelMessage);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_PanelMessage) {
  _inherits(_class, _PanelMessage);

  function _class() {
    _classCallCheck(this, _class);

    return _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).apply(this, arguments));
  }

  _createClass(_class, [{
    key: "getHelpText",
    value: function getHelpText() {
      if (this.props.helpText) {
        return _react2.default.createElement(
          "p",
          { className: "help-block" },
          this.props.helpText
        );
      } else {
        return null;
      }
    }
  }, {
    key: "render",
    value: function render() {
      return _react2.default.createElement(
        "div",
        { className: "modal-body" },
        _react2.default.createElement(
          "div",
          { className: "message-icon" },
          _react2.default.createElement(
            "span",
            { className: "material-icon" },
            this.props.icon || "info_outline"
          )
        ),
        _react2.default.createElement(
          "div",
          { className: "message-body" },
          _react2.default.createElement(
            "p",
            { className: "lead" },
            this.props.message
          ),
          this.getHelpText(),
          _react2.default.createElement(
            "button",
            {
              className: "btn btn-default",
              "data-dismiss": "modal",
              type: "button"
            },
            gettext("Ok")
          )
        )
      );
    }
  }]);

  return _class;
}(_panelMessage2.default);

exports.default = _class;

},{"./panel-message":94,"react":"react"}],63:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (data) {
  var filtered = data.filter(function (section) {
    return section.results.count > 0;
  });

  return filtered.map(function (section) {
    return Object.assign({}, section, {
      count: section.results.count,
      results: section.results.results.slice(0, MAX_RESULTS)
    });
  });
};

var MAX_RESULTS = 5;

},{}],64:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var HEADER = exports.HEADER = "HEADER";
var RESULT = exports.RESULT = "RESULT";
var FOOTER = exports.FOOTER = "FOOTER";

},{}],65:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (_ref) {
  var children = _ref.children,
      onChange = _ref.onChange,
      query = _ref.query;

  return _react2.default.createElement(
    "ul",
    { className: "dropdown-menu dropdown-search-results", role: "menu" },
    _react2.default.createElement(
      "li",
      { className: "form-group" },
      _react2.default.createElement(_input2.default, { value: query, onChange: onChange })
    ),
    children
  );
};

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _input = require("./input");

var _input2 = _interopRequireDefault(_input);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

},{"./input":69,"react":"react"}],66:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function () {
  return _react2.default.createElement(
    "li",
    { className: "dropdown-search-message" },
    gettext("Search returned no results.")
  );
};

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

},{"react":"react"}],67:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (results) {
  var flatlist = [];
  flattenProviders(results, flatlist);
  return flatlist;
};

var _constants = require("./constants");

function flattenProviders(results, flatlist) {
  var arrayLength = results.length;
  for (var i = 0; i < arrayLength; i++) {
    var provider = results[i];

    flatlist.push({
      provider: provider,
      type: _constants.HEADER
    });

    flattenProvider(provider, flatlist);
  }
}

function flattenProvider(provider, flatlist) {
  var arrayLength = provider.results.length;
  for (var i = 0; i < arrayLength; i++) {
    var result = provider.results[i];
    flatlist.push({
      provider: provider,
      result: result,
      type: _constants.RESULT
    });
  }

  flatlist.push({
    provider: provider,
    type: _constants.FOOTER
  });
}

},{"./constants":64}],68:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

exports.default = function (_ref) {
  var isLoading = _ref.isLoading,
      onChange = _ref.onChange,
      results = _ref.results,
      query = _ref.query;

  if (!query.trim().length) {
    return _react2.default.createElement(_dropdownMenu2.default, { onChange: onChange, query: query });
  }

  if (results.length) {
    var flatResults = (0, _flattenResults2.default)(results);

    return _react2.default.createElement(
      _dropdownMenu2.default,
      { onChange: onChange, query: query },
      flatResults.map(function (props) {
        var type = props.type,
            provider = props.provider,
            result = props.result;


        if (type === _constants.RESULT) {
          return _react2.default.createElement(_result2.default, _extends({
            key: [provider.id, type, result.id].join("_")
          }, props));
        }

        return _react2.default.createElement(_result2.default, _extends({
          key: [provider.id, type].join("_"),
          query: query
        }, props));
      })
    );
  } else if (isLoading) {
    return _react2.default.createElement(
      _dropdownMenu2.default,
      { onChange: onChange, query: query },
      _react2.default.createElement(_loader2.default, null)
    );
  }

  return _react2.default.createElement(
    _dropdownMenu2.default,
    { onChange: onChange, query: query },
    _react2.default.createElement(_empty2.default, null)
  );
};

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _constants = require("./constants");

var _dropdownMenu = require("./dropdown-menu");

var _dropdownMenu2 = _interopRequireDefault(_dropdownMenu);

var _empty = require("./empty");

var _empty2 = _interopRequireDefault(_empty);

var _loader = require("./loader");

var _loader2 = _interopRequireDefault(_loader);

var _result = require("./result");

var _result2 = _interopRequireDefault(_result);

var _flattenResults = require("./flatten-results");

var _flattenResults2 = _interopRequireDefault(_flattenResults);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

},{"./constants":64,"./dropdown-menu":65,"./empty":66,"./flatten-results":67,"./loader":70,"./result":73,"react":"react"}],69:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (_ref) {
  var value = _ref.value,
      onChange = _ref.onChange;

  return _react2.default.createElement("input", {
    "aria-haspopup": "true",
    "aria-expanded": "false",
    "aria-controls": "dropdown-menu dropdown-search-results",
    autoComplete: "off",
    className: "form-control",
    value: value,
    onChange: onChange,
    placeholder: gettext("Search"),
    role: "combobox",
    type: "text"
  });
};

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

},{"react":"react"}],70:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (_ref) {
  var message = _ref.message;

  return _react2.default.createElement(
    "li",
    { className: "dropdown-search-loader" },
    _react2.default.createElement(_loader2.default, null)
  );
};

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _loader = require("../../loader");

var _loader2 = _interopRequireDefault(_loader);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

},{"../../loader":58,"react":"react"}],71:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (_ref) {
  var provider = _ref.provider,
      query = _ref.query;

  var url = provider.url + "?q=" + encodeURI(query);
  var label = ngettext('See full "%(provider)s" results page with %(count)s result.', 'See full "%(provider)s" results page with %(count)s results.', provider.count);

  return _react2.default.createElement(
    "li",
    { className: "dropdown-search-footer" },
    _react2.default.createElement(
      "a",
      { href: url },
      interpolate(label, {
        count: provider.count,
        provider: provider.name
      }, true)
    )
  );
};

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

},{"react":"react"}],72:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (_ref) {
  var provider = _ref.provider;

  return _react2.default.createElement(
    "li",
    { className: "dropdown-search-header" },
    provider.name
  );
};

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

},{"react":"react"}],73:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (_ref) {
  var provider = _ref.provider,
      result = _ref.result,
      type = _ref.type,
      query = _ref.query;

  if (type === _constants.HEADER) {
    return _react2.default.createElement(_header2.default, { provider: provider });
  } else if (type === _constants.FOOTER) {
    return _react2.default.createElement(_footer2.default, { provider: provider, query: query });
  }

  return _react2.default.createElement(_result2.default, { provider: provider, result: result });
};

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _constants = require("../constants");

var _footer = require("./footer");

var _footer2 = _interopRequireDefault(_footer);

var _header = require("./header");

var _header2 = _interopRequireDefault(_header);

var _result = require("./result");

var _result2 = _interopRequireDefault(_result);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

},{"../constants":64,"./footer":71,"./header":72,"./result":74,"react":"react"}],74:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (_ref) {
  var provider = _ref.provider,
      result = _ref.result;

  if (provider.id === "threads") {
    return _react2.default.createElement(_thread2.default, { result: result });
  }

  return _react2.default.createElement(_user2.default, { result: result });
};

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _thread = require("./thread");

var _thread2 = _interopRequireDefault(_thread);

var _user = require("./user");

var _user2 = _interopRequireDefault(_user);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

},{"./thread":75,"./user":76,"react":"react"}],75:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (_ref) {
  var result = _ref.result;
  var poster = result.poster,
      thread = result.thread;

  var footer = gettext("Posted by %(poster)s on %(posted_on)s in %(category)s.");

  return _react2.default.createElement(
    "li",
    null,
    _react2.default.createElement(
      "a",
      { href: result.url.index, className: "dropdown-search-thread" },
      _react2.default.createElement(
        "h5",
        null,
        thread.title
      ),
      _react2.default.createElement(
        "small",
        { className: "dropdown-search-post-content" },
        $(result.content).text()
      ),
      _react2.default.createElement(
        "small",
        { className: "dropdown-search-post-footer" },
        interpolate(footer, {
          category: result.category.name,
          posted_on: (0, _moment2.default)(result.posted_on).format("LL"),
          poster: result.poster_name
        }, true)
      )
    )
  );
};

var _moment = require("moment");

var _moment2 = _interopRequireDefault(_moment);

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

},{"moment":"moment","react":"react"}],76:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (_ref) {
  var result = _ref.result;
  var rank = result.rank;


  var detail = gettext("%(title)s, joined on %(joined_on)s");
  var title = result.title || rank.title || rank.name;

  return _react2.default.createElement(
    "li",
    null,
    _react2.default.createElement(
      "a",
      { href: result.url, className: "dropdown-search-user" },
      _react2.default.createElement(
        "div",
        { className: "media" },
        _react2.default.createElement(
          "div",
          { className: "media-left" },
          _react2.default.createElement(_avatar2.default, { size: 38, user: result })
        ),
        _react2.default.createElement(
          "div",
          { className: "media-body" },
          _react2.default.createElement(
            "h5",
            { className: "media-heading" },
            result.username
          ),
          _react2.default.createElement(
            "small",
            null,
            interpolate(detail, {
              title: title,
              joined_on: (0, _moment2.default)(result.joined_on).format("LL")
            }, true)
          )
        )
      )
    )
  );
};

var _moment = require("moment");

var _moment2 = _interopRequireDefault(_moment);

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _avatar = require("../../../avatar");

var _avatar2 = _interopRequireDefault(_avatar);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

},{"../../../avatar":6,"moment":"moment","react":"react"}],77:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _ajax = require("../../services/ajax");

var _ajax2 = _interopRequireDefault(_ajax);

var _snackbar = require("../../services/snackbar");

var _snackbar2 = _interopRequireDefault(_snackbar);

var _ = require("../..");

var _2 = _interopRequireDefault(_);

var _cleanResults = require("./clean-results");

var _cleanResults2 = _interopRequireDefault(_cleanResults);

var _dropdown = require("./dropdown");

var _dropdown2 = _interopRequireDefault(_dropdown);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_React$Component) {
  _inherits(_class, _React$Component);

  function _class() {
    _classCallCheck(this, _class);

    var _this = _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).call(this));

    _this.onToggle = function (ev) {
      _this.setState(function (prevState, props) {
        if (!prevState.isOpen) {
          window.setTimeout(function () {
            _this.container.querySelector("input").focus();
          }, 100);
        }

        return { isOpen: !prevState.isOpen };
      });
    };

    _this.onDocumentMouseDown = function (ev) {
      var closeResults = true;
      var node = ev.target;

      while (node !== null && node !== document) {
        if (node === _this.container) {
          closeResults = false;
          return;
        }

        node = node.parentNode;
      }

      if (closeResults) {
        _this.setState({ isOpen: false });
      }
    };

    _this.onEscape = function (ev) {
      if (ev.key === "Escape") {
        _this.setState({ isOpen: false });
      }
    };

    _this.onChange = function (ev) {
      var query = ev.target.value;

      _this.setState({ query: query });
      _this.loadResults(query.trim());
    };

    _this.state = {
      isLoading: false,
      isOpen: false,
      query: "",
      results: []
    };

    _this.intervalId = null;
    return _this;
  }

  _createClass(_class, [{
    key: "componentDidMount",
    value: function componentDidMount() {
      document.addEventListener("mousedown", this.onDocumentMouseDown);
      document.addEventListener("keydown", this.onEscape);
    }
  }, {
    key: "componentWillUnmount",
    value: function componentWillUnmount() {
      document.removeEventListener("mousedown", this.onDocumentMouseDown);
      document.removeEventListener("keydown", this.onEscape);
    }
  }, {
    key: "loadResults",
    value: function loadResults(query) {
      var _this2 = this;

      if (!query.length) return;

      var delay = 300 + Math.random() * 300;

      if (this.intervalId) {
        window.clearTimeout(this.intervalId);
      }

      this.setState({ isLoading: true });

      this.intervalId = window.setTimeout(function () {
        _ajax2.default.get(_2.default.get("SEARCH_API"), { q: query }).then(function (data) {
          _this2.setState({
            intervalId: null,
            isLoading: false,
            results: (0, _cleanResults2.default)(data)
          });
        }, function (rejection) {
          _snackbar2.default.apiError(rejection);

          _this2.setState({
            intervalId: null,
            isLoading: false,
            results: []
          });
        });
      }, delay);
    }
  }, {
    key: "render",
    value: function render() {
      var _this3 = this;

      var className = "navbar-search dropdown";
      if (this.state.isOpen) className += " open";

      return _react2.default.createElement(
        "div",
        {
          className: className,
          ref: function ref(container) {
            return _this3.container = container;
          }
        },
        _react2.default.createElement(
          "a",
          {
            "aria-haspopup": "true",
            "aria-expanded": "false",
            className: "navbar-icon",
            "data-toggle": "dropdown",
            href: _2.default.get("SEARCH_URL"),
            onClick: this.onToggle
          },
          _react2.default.createElement(
            "i",
            { className: "material-icon" },
            "search"
          )
        ),
        _react2.default.createElement(_dropdown2.default, {
          isLoading: this.state.isLoading,
          onChange: this.onChange,
          results: this.state.results,
          query: this.state.query
        })
      );
    }
  }]);

  return _class;
}(_react2.default.Component);

exports.default = _class;

},{"../..":302,"../../services/ajax":365,"../../services/snackbar":376,"./clean-results":63,"./dropdown":68,"react":"react"}],78:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function () {
  return _react2.default.createElement(
    "div",
    { className: "panel panel-default panel-form" },
    _react2.default.createElement(
      "div",
      { className: "panel-heading" },
      _react2.default.createElement(
        "h3",
        { className: "panel-title" },
        gettext("Change username")
      )
    ),
    _react2.default.createElement(_panelLoader2.default, null)
  );
};

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _panelLoader = require("../../panel-loader");

var _panelLoader2 = _interopRequireDefault(_panelLoader);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

},{"../../panel-loader":93,"react":"react"}],79:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _panelMessage = require("../../panel-message");

var _panelMessage2 = _interopRequireDefault(_panelMessage);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_React$Component) {
  _inherits(_class, _React$Component);

  function _class() {
    _classCallCheck(this, _class);

    return _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).apply(this, arguments));
  }

  _createClass(_class, [{
    key: "getHelpText",
    value: function getHelpText() {
      if (this.props.options.next_on) {
        return interpolate(gettext("You will be able to change your username %(next_change)s."), { next_change: this.props.options.next_on.fromNow() }, true);
      } else {
        return gettext("You have used up available name changes.");
      }
    }
  }, {
    key: "render",
    value: function render() {
      return _react2.default.createElement(
        "div",
        { className: "panel panel-default panel-form" },
        _react2.default.createElement(
          "div",
          { className: "panel-heading" },
          _react2.default.createElement(
            "h3",
            { className: "panel-title" },
            gettext("Change username")
          )
        ),
        _react2.default.createElement(_panelMessage2.default, {
          helpText: this.getHelpText(),
          message: gettext("You can't change your username at the moment.")
        })
      );
    }
  }]);

  return _class;
}(_react2.default.Component);

exports.default = _class;

},{"../../panel-message":94,"react":"react"}],80:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _button = require("../../button");

var _button2 = _interopRequireDefault(_button);

var _form = require("../../form");

var _form2 = _interopRequireDefault(_form);

var _formGroup = require("../../form-group");

var _formGroup2 = _interopRequireDefault(_formGroup);

var _ajax = require("../../../services/ajax");

var _ajax2 = _interopRequireDefault(_ajax);

var _snackbar = require("../../../services/snackbar");

var _snackbar2 = _interopRequireDefault(_snackbar);

var _validators = require("../../../utils/validators");

var validators = _interopRequireWildcard(_validators);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_Form) {
  _inherits(_class, _Form);

  function _class(props) {
    _classCallCheck(this, _class);

    var _this = _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).call(this, props));

    _this.state = {
      username: "",

      validators: {
        username: [validators.usernameContent(), validators.usernameMinLength(props.options.length_min), validators.usernameMaxLength(props.options.length_max)]
      },

      isLoading: false
    };
    return _this;
  }

  _createClass(_class, [{
    key: "getHelpText",
    value: function getHelpText() {
      var phrases = [];

      if (this.props.options.changes_left > 0) {
        var message = ngettext("You can change your username %(changes_left)s more time.", "You can change your username %(changes_left)s more times.", this.props.options.changes_left);

        phrases.push(interpolate(message, {
          changes_left: this.props.options.changes_left
        }, true));
      }

      if (this.props.user.acl.name_changes_expire > 0) {
        var _message = ngettext("Used changes become available again after %(name_changes_expire)s day.", "Used changes become available again after %(name_changes_expire)s days.", this.props.user.acl.name_changes_expire);

        phrases.push(interpolate(_message, {
          name_changes_expire: this.props.user.acl.name_changes_expire
        }, true));
      }

      return phrases.length ? phrases.join(" ") : null;
    }
  }, {
    key: "clean",
    value: function clean() {
      var errors = this.validate();
      if (errors.username) {
        _snackbar2.default.error(errors.username[0]);
        return false;
      }
      if (this.state.username.trim() === this.props.user.username) {
        _snackbar2.default.info(gettext("Your new username is same as current one."));
        return false;
      } else {
        return true;
      }
    }
  }, {
    key: "send",
    value: function send() {
      return _ajax2.default.post(this.props.user.api.username, {
        username: this.state.username
      });
    }
  }, {
    key: "handleSuccess",
    value: function handleSuccess(success) {
      this.setState({
        username: ""
      });

      this.props.complete(success.username, success.slug, success.options);
    }
  }, {
    key: "handleError",
    value: function handleError(rejection) {
      _snackbar2.default.apiError(rejection);
    }
  }, {
    key: "render",
    value: function render() {
      return _react2.default.createElement(
        "form",
        { onSubmit: this.handleSubmit },
        _react2.default.createElement(
          "div",
          { className: "panel panel-default panel-form" },
          _react2.default.createElement(
            "div",
            { className: "panel-heading" },
            _react2.default.createElement(
              "h3",
              { className: "panel-title" },
              gettext("Change username")
            )
          ),
          _react2.default.createElement(
            "div",
            { className: "panel-body" },
            _react2.default.createElement(
              _formGroup2.default,
              {
                label: gettext("New username"),
                "for": "id_username",
                helpText: this.getHelpText()
              },
              _react2.default.createElement("input", {
                type: "text",
                id: "id_username",
                className: "form-control",
                disabled: this.state.isLoading,
                onChange: this.bindInput("username"),
                value: this.state.username
              })
            )
          ),
          _react2.default.createElement(
            "div",
            { className: "panel-footer" },
            _react2.default.createElement(
              _button2.default,
              { className: "btn-primary", loading: this.state.isLoading },
              gettext("Change username")
            )
          )
        )
      );
    }
  }]);

  return _class;
}(_form2.default);

exports.default = _class;

},{"../../../services/ajax":365,"../../../services/snackbar":376,"../../../utils/validators":393,"../../button":8,"../../form":56,"../../form-group":55,"react":"react"}],81:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _moment = require("moment");

var _moment2 = _interopRequireDefault(_moment);

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _formLoading = require("./form-loading");

var _formLoading2 = _interopRequireDefault(_formLoading);

var _formLocked = require("./form-locked");

var _formLocked2 = _interopRequireDefault(_formLocked);

var _form = require("./form");

var _form2 = _interopRequireDefault(_form);

var _root = require("../../username-history/root");

var _root2 = _interopRequireDefault(_root);

var _index = require("../../../index");

var _index2 = _interopRequireDefault(_index);

var _usernameHistory = require("../../../reducers/username-history");

var _users = require("../../../reducers/users");

var _ajax = require("../../../services/ajax");

var _ajax2 = _interopRequireDefault(_ajax);

var _pageTitle = require("../../../services/page-title");

var _pageTitle2 = _interopRequireDefault(_pageTitle);

var _snackbar = require("../../../services/snackbar");

var _snackbar2 = _interopRequireDefault(_snackbar);

var _store = require("../../../services/store");

var _store2 = _interopRequireDefault(_store);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_React$Component) {
  _inherits(_class, _React$Component);

  function _class(props) {
    _classCallCheck(this, _class);

    var _this = _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).call(this, props));

    _this.onComplete = function (username, slug, options) {
      _this.setState({
        options: options
      });

      _store2.default.dispatch((0, _usernameHistory.addNameChange)({ username: username, slug: slug }, _this.props.user, _this.props.user));
      _store2.default.dispatch((0, _users.updateUsername)(_this.props.user, username, slug));

      _snackbar2.default.success(gettext("Your username has been changed successfully."));
    };

    _this.state = {
      isLoaded: false,
      options: null
    };
    return _this;
  }

  _createClass(_class, [{
    key: "componentDidMount",
    value: function componentDidMount() {
      var _this2 = this;

      _pageTitle2.default.set({
        title: gettext("Change username"),
        parent: gettext("Change your options")
      });

      Promise.all([_ajax2.default.get(this.props.user.api.username), _ajax2.default.get(_index2.default.get("USERNAME_CHANGES_API"), { user: this.props.user.id })]).then(function (data) {
        _store2.default.dispatch((0, _usernameHistory.hydrate)(data[1].results));

        _this2.setState({
          isLoaded: true,
          options: {
            changes_left: data[0].changes_left,
            length_min: data[0].length_min,
            length_max: data[0].length_max,
            next_on: data[0].next_on ? (0, _moment2.default)(data[0].next_on) : null
          }
        });
      });
    }
  }, {
    key: "getChangeForm",
    value: function getChangeForm() {
      if (!this.state.isLoaded) {
        return _react2.default.createElement(_formLoading2.default, null);
      }

      if (this.state.options.changes_left === 0) {
        return _react2.default.createElement(_formLocked2.default, { options: this.state.options });
      }

      return _react2.default.createElement(_form2.default, {
        complete: this.onComplete,
        options: this.state.options,
        user: this.props.user
      });
    }
  }, {
    key: "render",
    value: function render() {
      return _react2.default.createElement(
        "div",
        null,
        this.getChangeForm(),
        _react2.default.createElement(_root2.default, {
          changes: this.props["username-history"],
          isLoaded: this.state.isLoaded
        })
      );
    }
  }]);

  return _class;
}(_react2.default.Component);

exports.default = _class;

},{"../../../index":302,"../../../reducers/username-history":363,"../../../reducers/users":364,"../../../services/ajax":365,"../../../services/page-title":373,"../../../services/snackbar":376,"../../../services/store":377,"../../username-history/root":280,"./form":80,"./form-loading":78,"./form-locked":79,"moment":"moment","react":"react"}],82:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _button = require("../button");

var _button2 = _interopRequireDefault(_button);

var _ajax = require("../../services/ajax");

var _ajax2 = _interopRequireDefault(_ajax);

var _pageTitle = require("../../services/page-title");

var _pageTitle2 = _interopRequireDefault(_pageTitle);

var _snackbar = require("../../services/snackbar");

var _snackbar2 = _interopRequireDefault(_snackbar);

var _store = require("../../services/store");

var _store2 = _interopRequireDefault(_store);

var _ = require("../..");

var _2 = _interopRequireDefault(_);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_React$Component) {
  _inherits(_class, _React$Component);

  function _class(props) {
    _classCallCheck(this, _class);

    var _this = _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).call(this, props));

    _this.onPasswordChange = function (event) {
      _this.setState({ password: event.target.value });
    };

    _this.handleSubmit = function (event) {
      event.preventDefault();

      var _this$state = _this.state,
          isLoading = _this$state.isLoading,
          password = _this$state.password;
      var user = _this.props.user;


      if (password.length == 0) {
        _snackbar2.default.error(gettext("Enter your password to confirm account deletion."));
        return false;
      }

      if (isLoading) return false;
      _this.setState({ isLoading: true });

      _ajax2.default.post(user.api.delete, { password: password }).then(function (success) {
        window.location.href = _2.default.get("MISAGO_PATH");
      }, function (rejection) {
        _this.setState({ isLoading: false });
        if (rejection.password) {
          _snackbar2.default.error(rejection.password[0]);
        } else {
          _snackbar2.default.apiError(rejection);
        }
      });
    };

    _this.state = {
      isLoading: false,
      password: ""
    };
    return _this;
  }

  _createClass(_class, [{
    key: "componentDidMount",
    value: function componentDidMount() {
      _pageTitle2.default.set({
        title: gettext("Delete account"),
        parent: gettext("Change your options")
      });
    }
  }, {
    key: "render",
    value: function render() {
      return _react2.default.createElement(
        "form",
        { onSubmit: this.handleSubmit },
        _react2.default.createElement(
          "div",
          { className: "panel panel-danger panel-form" },
          _react2.default.createElement(
            "div",
            { className: "panel-heading" },
            _react2.default.createElement(
              "h3",
              { className: "panel-title" },
              gettext("Delete account")
            )
          ),
          _react2.default.createElement(
            "div",
            { className: "panel-body" },
            _react2.default.createElement(
              "p",
              { className: "lead" },
              gettext("You are going to delete your account. This action is nonreversible, and will result in following data being deleted:")
            ),
            _react2.default.createElement(
              "p",
              null,
              "-",
              " ",
              gettext("Stored IP addresses associated with content that you have posted will be deleted.")
            ),
            _react2.default.createElement(
              "p",
              null,
              "-",
              " ",
              gettext("Your username will become available for other user to rename to or for new user to register their account with.")
            ),
            _react2.default.createElement(
              "p",
              null,
              "-",
              " ",
              gettext("Your e-mail will become available for use in new account registration.")
            ),
            _react2.default.createElement("hr", null),
            _react2.default.createElement(
              "p",
              null,
              gettext("All your posted content will NOT be deleted, but username associated with it will be changed to one shared by all deleted accounts.")
            )
          ),
          _react2.default.createElement(
            "div",
            { className: "panel-footer" },
            _react2.default.createElement(
              "div",
              { className: "input-group" },
              _react2.default.createElement("input", {
                className: "form-control",
                disabled: this.state.isLoading,
                name: "password-confirmation",
                type: "password",
                placeholder: gettext("Enter your password to confirm account deletion."),
                value: this.state.password,
                onChange: this.onPasswordChange
              }),
              _react2.default.createElement(
                "span",
                { className: "input-group-btn" },
                _react2.default.createElement(
                  _button2.default,
                  { className: "btn-danger", loading: this.state.isLoading },
                  gettext("Delete my account")
                )
              )
            )
          )
        )
      );
    }
  }]);

  return _class;
}(_react2.default.Component);

exports.default = _class;

},{"../..":302,"../../services/ajax":365,"../../services/page-title":373,"../../services/snackbar":376,"../../services/store":377,"../button":8,"react":"react"}],83:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _moment = require("moment");

var _moment2 = _interopRequireDefault(_moment);

var _button = require("../button");

var _button2 = _interopRequireDefault(_button);

var _ajax = require("../../services/ajax");

var _ajax2 = _interopRequireDefault(_ajax);

var _pageTitle = require("../../services/page-title");

var _pageTitle2 = _interopRequireDefault(_pageTitle);

var _snackbar = require("../../services/snackbar");

var _snackbar2 = _interopRequireDefault(_snackbar);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var DownloadData = function (_React$Component) {
  _inherits(DownloadData, _React$Component);

  function DownloadData(props) {
    _classCallCheck(this, DownloadData);

    var _this = _possibleConstructorReturn(this, (DownloadData.__proto__ || Object.getPrototypeOf(DownloadData)).call(this, props));

    _this.handleLoadDownloads = function () {
      _ajax2.default.get(_this.props.user.api.data_downloads).then(function (data) {
        _this.setState({
          isLoading: false,
          downloads: data
        });
      }, function (rejection) {
        _snackbar2.default.apiError(rejection);
      });
    };

    _this.handleRequestDataDownload = function () {
      _this.setState({ isSubmiting: true });
      _ajax2.default.post(_this.props.user.api.request_data_download).then(function () {
        _this.handleLoadDownloads();
        _snackbar2.default.success(gettext("Your request for data download has been registered."));
        _this.setState({ isSubmiting: false });
      }, function (rejection) {
        console.log(rejection);
        _snackbar2.default.apiError(rejection);
        _this.setState({ isSubmiting: false });
      });
    };

    _this.state = {
      isLoading: false,
      isSubmiting: false,
      downloads: []
    };
    return _this;
  }

  _createClass(DownloadData, [{
    key: "componentDidMount",
    value: function componentDidMount() {
      _pageTitle2.default.set({
        title: gettext("Download your data"),
        parent: gettext("Change your options")
      });

      this.handleLoadDownloads();
    }
  }, {
    key: "render",
    value: function render() {
      return _react2.default.createElement(
        "div",
        null,
        _react2.default.createElement(
          "div",
          { className: "panel panel-default panel-form" },
          _react2.default.createElement(
            "div",
            { className: "panel-heading" },
            _react2.default.createElement(
              "h3",
              { className: "panel-title" },
              gettext("Download your data")
            )
          ),
          _react2.default.createElement(
            "div",
            { className: "panel-body" },
            _react2.default.createElement(
              "p",
              null,
              gettext('To download your data from the site, click the "Request data download" button. Depending on amount of data to be archived and number of users wanting to download their data at same time it may take up to few days for your download to be prepared. An e-mail with notification will be sent to you when your data is ready to be downloaded.')
            ),
            _react2.default.createElement(
              "p",
              null,
              gettext("The download will only be available for limited amount of time, after which it will be deleted from the site and marked as expired.")
            )
          ),
          _react2.default.createElement(
            "table",
            { className: "table" },
            _react2.default.createElement(
              "thead",
              null,
              _react2.default.createElement(
                "tr",
                null,
                _react2.default.createElement(
                  "th",
                  null,
                  gettext("Requested on")
                ),
                _react2.default.createElement(
                  "th",
                  { className: "col-md-4" },
                  gettext("Download")
                )
              )
            ),
            _react2.default.createElement(
              "tbody",
              null,
              this.state.downloads.map(function (item) {
                return _react2.default.createElement(
                  "tr",
                  { key: item.id },
                  _react2.default.createElement(
                    "td",
                    { style: rowStyle },
                    (0, _moment2.default)(item.requested_on).fromNow()
                  ),
                  _react2.default.createElement(
                    "td",
                    null,
                    _react2.default.createElement(DownloadButton, {
                      exportFile: item.file,
                      status: item.status
                    })
                  )
                );
              }),
              this.state.downloads.length == 0 ? _react2.default.createElement(
                "tr",
                null,
                _react2.default.createElement(
                  "td",
                  { colSpan: "2" },
                  gettext("You have no data downloads.")
                )
              ) : null
            )
          ),
          _react2.default.createElement(
            "div",
            { className: "panel-footer text-right" },
            _react2.default.createElement(
              _button2.default,
              {
                className: "btn-primary",
                loading: this.state.isSubmiting,
                type: "button",
                onClick: this.handleRequestDataDownload
              },
              gettext("Request data download")
            )
          )
        )
      );
    }
  }]);

  return DownloadData;
}(_react2.default.Component);

exports.default = DownloadData;


var rowStyle = {
  verticalAlign: "middle"
};

var STATUS_PENDING = 0;
var STATUS_PROCESSING = 1;

var DownloadButton = function DownloadButton(_ref) {
  var exportFile = _ref.exportFile,
      status = _ref.status;

  if (status === STATUS_PENDING || status === STATUS_PROCESSING) {
    return _react2.default.createElement(
      _button2.default,
      {
        className: "btn-info btn-sm btn-block",
        disabled: true,
        type: "button"
      },
      gettext("Download is being prepared")
    );
  }

  if (exportFile) {
    return _react2.default.createElement(
      "a",
      { className: "btn btn-success btn-sm btn-block", href: exportFile },
      gettext("Download your data")
    );
  }

  return _react2.default.createElement(
    _button2.default,
    {
      className: "btn-default btn-sm btn-block",
      disabled: true,
      type: "button"
    },
    gettext("Download is expired")
  );
};

},{"../../services/ajax":365,"../../services/page-title":373,"../../services/snackbar":376,"../button":8,"moment":"moment","react":"react"}],84:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _editDetails = require("../edit-details");

var _editDetails2 = _interopRequireDefault(_editDetails);

var _pageTitle = require("../../services/page-title");

var _pageTitle2 = _interopRequireDefault(_pageTitle);

var _snackbar = require("../../services/snackbar");

var _snackbar2 = _interopRequireDefault(_snackbar);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_React$Component) {
  _inherits(_class, _React$Component);

  function _class() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, _class);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = _class.__proto__ || Object.getPrototypeOf(_class)).call.apply(_ref, [this].concat(args))), _this), _this.onSuccess = function () {
      _snackbar2.default.info(gettext("Your details have been updated."));
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(_class, [{
    key: "componentDidMount",
    value: function componentDidMount() {
      _pageTitle2.default.set({
        title: gettext("Edit details"),
        parent: gettext("Change your options")
      });
    }
  }, {
    key: "render",
    value: function render() {
      return _react2.default.createElement(_editDetails2.default, { api: this.props.user.api.edit_details, onSuccess: this.onSuccess });
    }
  }]);

  return _class;
}(_react2.default.Component);

exports.default = _class;

},{"../../services/page-title":373,"../../services/snackbar":376,"../edit-details":32,"react":"react"}],85:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _button = require("../button");

var _button2 = _interopRequireDefault(_button);

var _form = require("../form");

var _form2 = _interopRequireDefault(_form);

var _formGroup = require("../form-group");

var _formGroup2 = _interopRequireDefault(_formGroup);

var _select = require("../select");

var _select2 = _interopRequireDefault(_select);

var _yesNoSwitch = require("../yes-no-switch");

var _yesNoSwitch2 = _interopRequireDefault(_yesNoSwitch);

var _auth = require("../../reducers/auth");

var _ajax = require("../../services/ajax");

var _ajax2 = _interopRequireDefault(_ajax);

var _pageTitle = require("../../services/page-title");

var _pageTitle2 = _interopRequireDefault(_pageTitle);

var _snackbar = require("../../services/snackbar");

var _snackbar2 = _interopRequireDefault(_snackbar);

var _store = require("../../services/store");

var _store2 = _interopRequireDefault(_store);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_Form) {
  _inherits(_class, _Form);

  function _class(props) {
    _classCallCheck(this, _class);

    var _this = _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).call(this, props));

    _this.state = {
      isLoading: false,

      is_hiding_presence: props.user.is_hiding_presence,
      limits_private_thread_invites_to: props.user.limits_private_thread_invites_to,
      subscribe_to_started_threads: props.user.subscribe_to_started_threads,
      subscribe_to_replied_threads: props.user.subscribe_to_replied_threads,

      errors: {}
    };

    _this.privateThreadInvitesChoices = [{
      value: 0,
      icon: "help_outline",
      label: gettext("Everybody")
    }, {
      value: 1,
      icon: "done_all",
      label: gettext("Users I follow")
    }, {
      value: 2,
      icon: "highlight_off",
      label: gettext("Nobody")
    }];

    _this.subscribeToChoices = [{
      value: 0,
      icon: "star_border",
      label: gettext("No")
    }, {
      value: 1,
      icon: "star_half",
      label: gettext("Notify")
    }, {
      value: 2,
      icon: "star",
      label: gettext("Notify with e-mail")
    }];
    return _this;
  }

  _createClass(_class, [{
    key: "send",
    value: function send() {
      return _ajax2.default.post(this.props.user.api.options, {
        is_hiding_presence: this.state.is_hiding_presence,
        limits_private_thread_invites_to: this.state.limits_private_thread_invites_to,
        subscribe_to_started_threads: this.state.subscribe_to_started_threads,
        subscribe_to_replied_threads: this.state.subscribe_to_replied_threads
      });
    }
  }, {
    key: "handleSuccess",
    value: function handleSuccess() {
      _store2.default.dispatch((0, _auth.patch)({
        is_hiding_presence: this.state.is_hiding_presence,
        limits_private_thread_invites_to: this.state.limits_private_thread_invites_to,
        subscribe_to_started_threads: this.state.subscribe_to_started_threads,
        subscribe_to_replied_threads: this.state.subscribe_to_replied_threads
      }));
      _snackbar2.default.success(gettext("Your forum options have been changed."));
    }
  }, {
    key: "handleError",
    value: function handleError(rejection) {
      if (rejection.status === 400) {
        _snackbar2.default.error(gettext("Please reload page and try again."));
      } else {
        _snackbar2.default.apiError(rejection);
      }
    }
  }, {
    key: "componentDidMount",
    value: function componentDidMount() {
      _pageTitle2.default.set({
        title: gettext("Forum options"),
        parent: gettext("Change your options")
      });
    }
  }, {
    key: "render",
    value: function render() {
      return _react2.default.createElement(
        "form",
        { onSubmit: this.handleSubmit },
        _react2.default.createElement(
          "div",
          { className: "panel panel-default panel-form" },
          _react2.default.createElement(
            "div",
            { className: "panel-heading" },
            _react2.default.createElement(
              "h3",
              { className: "panel-title" },
              gettext("Change forum options")
            )
          ),
          _react2.default.createElement(
            "div",
            { className: "panel-body" },
            _react2.default.createElement(
              "fieldset",
              null,
              _react2.default.createElement(
                "legend",
                null,
                gettext("Privacy settings")
              ),
              _react2.default.createElement(
                _formGroup2.default,
                {
                  label: gettext("Hide my presence"),
                  helpText: gettext("If you hide your presence, only members with permission to see hidden users will see when you are online."),
                  "for": "id_is_hiding_presence"
                },
                _react2.default.createElement(_yesNoSwitch2.default, {
                  id: "id_is_hiding_presence",
                  disabled: this.state.isLoading,
                  iconOn: "visibility_off",
                  iconOff: "visibility",
                  labelOn: gettext("Hide my presence from other users"),
                  labelOff: gettext("Show my presence to other users"),
                  onChange: this.bindInput("is_hiding_presence"),
                  value: this.state.is_hiding_presence
                })
              ),
              _react2.default.createElement(
                _formGroup2.default,
                {
                  label: gettext("Private thread invitations"),
                  "for": "id_limits_private_thread_invites_to"
                },
                _react2.default.createElement(_select2.default, {
                  id: "id_limits_private_thread_invites_to",
                  disabled: this.state.isLoading,
                  onChange: this.bindInput("limits_private_thread_invites_to"),
                  value: this.state.limits_private_thread_invites_to,
                  choices: this.privateThreadInvitesChoices
                })
              )
            ),
            _react2.default.createElement(
              "fieldset",
              null,
              _react2.default.createElement(
                "legend",
                null,
                gettext("Automatic subscriptions")
              ),
              _react2.default.createElement(
                _formGroup2.default,
                {
                  label: gettext("Threads I start"),
                  "for": "id_subscribe_to_started_threads"
                },
                _react2.default.createElement(_select2.default, {
                  id: "id_subscribe_to_started_threads",
                  disabled: this.state.isLoading,
                  onChange: this.bindInput("subscribe_to_started_threads"),
                  value: this.state.subscribe_to_started_threads,
                  choices: this.subscribeToChoices
                })
              ),
              _react2.default.createElement(
                _formGroup2.default,
                {
                  label: gettext("Threads I reply to"),
                  "for": "id_subscribe_to_replied_threads"
                },
                _react2.default.createElement(_select2.default, {
                  id: "id_subscribe_to_replied_threads",
                  disabled: this.state.isLoading,
                  onChange: this.bindInput("subscribe_to_replied_threads"),
                  value: this.state.subscribe_to_replied_threads,
                  choices: this.subscribeToChoices
                })
              )
            )
          ),
          _react2.default.createElement(
            "div",
            { className: "panel-footer" },
            _react2.default.createElement(
              _button2.default,
              { className: "btn-primary", loading: this.state.isLoading },
              gettext("Save changes")
            )
          )
        )
      );
    }
  }]);

  return _class;
}(_form2.default);

exports.default = _class;

},{"../../reducers/auth":350,"../../services/ajax":365,"../../services/page-title":373,"../../services/snackbar":376,"../../services/store":377,"../button":8,"../form":56,"../form-group":55,"../select":210,"../yes-no-switch":300,"react":"react"}],86:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SideNav = SideNav;
exports.CompactNav = CompactNav;

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _reactRouter = require("react-router");

var _li = require("../li");

var _li2 = _interopRequireDefault(_li);

var _index = require("../../index");

var _index2 = _interopRequireDefault(_index);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function SideNav(props) {
  return _react2.default.createElement(
    "div",
    { className: "list-group nav-side" },
    props.options.map(function (option) {
      return _react2.default.createElement(
        _reactRouter.Link,
        {
          to: props.baseUrl + option.component + "/",
          className: "list-group-item",
          activeClassName: "active",
          key: option.component
        },
        _react2.default.createElement(
          "span",
          { className: "material-icon" },
          option.icon
        ),
        option.name
      );
    })
  );
}

function CompactNav(props) {
  return _react2.default.createElement(
    "ul",
    {
      className: props.className || "dropdown-menu stick-to-bottom",
      role: "menu"
    },
    props.options.map(function (option) {
      return _react2.default.createElement(
        _li2.default,
        {
          path: props.baseUrl + option.component + "/",
          key: option.component
        },
        _react2.default.createElement(
          _reactRouter.Link,
          {
            to: props.baseUrl + option.component + "/",
            onClick: props.hideNav
          },
          _react2.default.createElement(
            "span",
            { className: "material-icon hidden-sm" },
            option.icon
          ),
          option.name
        )
      );
    })
  );
}

},{"../../index":302,"../li":57,"react":"react","react-router":"react-router"}],87:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

exports.select = select;
exports.paths = paths;

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _reactRedux = require("react-redux");

var _dropdownToggle = require("../dropdown-toggle");

var _dropdownToggle2 = _interopRequireDefault(_dropdownToggle);

var _navs = require("./navs");

var _deleteAccount = require("./delete-account");

var _deleteAccount2 = _interopRequireDefault(_deleteAccount);

var _editDetails = require("./edit-details");

var _editDetails2 = _interopRequireDefault(_editDetails);

var _downloadData = require("./download-data");

var _downloadData2 = _interopRequireDefault(_downloadData);

var _forumOptions = require("./forum-options");

var _forumOptions2 = _interopRequireDefault(_forumOptions);

var _root = require("./change-username/root");

var _root2 = _interopRequireDefault(_root);

var _root3 = require("./sign-in-credentials/root");

var _root4 = _interopRequireDefault(_root3);

var _withDropdown = require("../with-dropdown");

var _withDropdown2 = _interopRequireDefault(_withDropdown);

var _index = require("../../index");

var _index2 = _interopRequireDefault(_index);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_WithDropdown) {
  _inherits(_class, _WithDropdown);

  function _class() {
    _classCallCheck(this, _class);

    return _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).apply(this, arguments));
  }

  _createClass(_class, [{
    key: "render",
    value: function render() {
      return _react2.default.createElement(
        "div",
        { className: "page page-options" },
        _react2.default.createElement(
          "div",
          { className: "page-header-bg" },
          _react2.default.createElement(
            "div",
            { className: "page-header" },
            _react2.default.createElement(
              "div",
              { className: "container" },
              _react2.default.createElement(
                "h1",
                null,
                gettext("Change your options")
              )
            ),
            _react2.default.createElement(
              "div",
              { className: "page-tabs visible-xs-block visible-sm-block" },
              _react2.default.createElement(
                "div",
                { className: "container" },
                _react2.default.createElement(_navs.CompactNav, {
                  className: "nav nav-pills",
                  baseUrl: _index2.default.get("USERCP_URL"),
                  options: _index2.default.get("USER_OPTIONS")
                })
              )
            )
          )
        ),
        _react2.default.createElement(
          "div",
          { className: "container" },
          _react2.default.createElement(
            "div",
            { className: "row" },
            _react2.default.createElement(
              "div",
              { className: "col-md-3 hidden-xs hidden-sm" },
              _react2.default.createElement(_navs.SideNav, {
                baseUrl: _index2.default.get("USERCP_URL"),
                options: _index2.default.get("USER_OPTIONS")
              })
            ),
            _react2.default.createElement(
              "div",
              { className: "col-md-9" },
              this.props.children
            )
          )
        )
      );
    }
  }]);

  return _class;
}(_withDropdown2.default);

exports.default = _class;
function select(store) {
  return {
    tick: store.tick.tick,
    user: store.auth.user,
    "username-history": store["username-history"]
  };
}

function paths() {
  var SSO_ENABLED = _index2.default.get("SETTINGS").enable_sso;

  var paths = [{
    path: _index2.default.get("USERCP_URL") + "forum-options/",
    component: (0, _reactRedux.connect)(select)(_forumOptions2.default)
  }, {
    path: _index2.default.get("USERCP_URL") + "edit-details/",
    component: (0, _reactRedux.connect)(select)(_editDetails2.default)
  }];

  if (!SSO_ENABLED) {
    paths.push({
      path: _index2.default.get("USERCP_URL") + "change-username/",
      component: (0, _reactRedux.connect)(select)(_root2.default)
    });
    paths.push({
      path: _index2.default.get("USERCP_URL") + "sign-in-credentials/",
      component: (0, _reactRedux.connect)(select)(_root4.default)
    });
  }

  if (_index2.default.get("ENABLE_DOWNLOAD_OWN_DATA")) {
    paths.push({
      path: _index2.default.get("USERCP_URL") + "download-data/",
      component: (0, _reactRedux.connect)(select)(_downloadData2.default)
    });
  }

  if (!SSO_ENABLED && _index2.default.get("ENABLE_DELETE_OWN_ACCOUNT")) {
    paths.push({
      path: _index2.default.get("USERCP_URL") + "delete-account/",
      component: (0, _reactRedux.connect)(select)(_deleteAccount2.default)
    });
  }

  return paths;
}

},{"../../index":302,"../dropdown-toggle":27,"../with-dropdown":299,"./change-username/root":81,"./delete-account":82,"./download-data":83,"./edit-details":84,"./forum-options":85,"./navs":86,"./sign-in-credentials/root":91,"react":"react","react-redux":"react-redux"}],88:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _index = require("../../../index");

var _index2 = _interopRequireDefault(_index);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var UnusablePasswordMessage = function UnusablePasswordMessage() {
  return _react2.default.createElement(
    "div",
    { className: "panel panel-default panel-form" },
    _react2.default.createElement(
      "div",
      { className: "panel-heading" },
      _react2.default.createElement(
        "h3",
        { className: "panel-title" },
        gettext("Change email or password")
      )
    ),
    _react2.default.createElement(
      "div",
      { className: "panel-body panel-message-body" },
      _react2.default.createElement(
        "div",
        { className: "message-icon" },
        _react2.default.createElement(
          "span",
          { className: "material-icon" },
          "info_outline"
        )
      ),
      _react2.default.createElement(
        "div",
        { className: "message-body" },
        _react2.default.createElement(
          "p",
          { className: "lead" },
          gettext("You need to set a password for your account to be able to change your username or email.")
        ),
        _react2.default.createElement(
          "p",
          { className: "help-block" },
          _react2.default.createElement(
            "a",
            {
              className: "btn btn-primary",
              href: _index2.default.get("FORGOTTEN_PASSWORD_URL")
            },
            gettext("Set password")
          )
        )
      )
    )
  );
};

exports.default = UnusablePasswordMessage;

},{"../../../index":302,"react":"react"}],89:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _button = require("../../button");

var _button2 = _interopRequireDefault(_button);

var _form = require("../../form");

var _form2 = _interopRequireDefault(_form);

var _formGroup = require("../../form-group");

var _formGroup2 = _interopRequireDefault(_formGroup);

var _ajax = require("../../../services/ajax");

var _ajax2 = _interopRequireDefault(_ajax);

var _snackbar = require("../../../services/snackbar");

var _snackbar2 = _interopRequireDefault(_snackbar);

var _validators = require("../../../utils/validators");

var validators = _interopRequireWildcard(_validators);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_Form) {
  _inherits(_class, _Form);

  function _class(props) {
    _classCallCheck(this, _class);

    var _this = _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).call(this, props));

    _this.state = {
      new_email: "",
      password: "",

      validators: {
        new_email: [validators.email()],
        password: []
      },

      isLoading: false
    };
    return _this;
  }

  _createClass(_class, [{
    key: "clean",
    value: function clean() {
      var errors = this.validate();
      var lengths = [this.state.new_email.trim().length, this.state.password.trim().length];

      if (lengths.indexOf(0) !== -1) {
        _snackbar2.default.error(gettext("Fill out all fields."));
        return false;
      }

      if (errors.new_email) {
        _snackbar2.default.error(errors.new_email[0]);
        return false;
      }

      return true;
    }
  }, {
    key: "send",
    value: function send() {
      return _ajax2.default.post(this.props.user.api.change_email, {
        new_email: this.state.new_email,
        password: this.state.password
      });
    }
  }, {
    key: "handleSuccess",
    value: function handleSuccess(response) {
      this.setState({
        new_email: "",
        password: ""
      });

      _snackbar2.default.success(response.detail);
    }
  }, {
    key: "handleError",
    value: function handleError(rejection) {
      if (rejection.status === 400) {
        if (rejection.new_email) {
          _snackbar2.default.error(rejection.new_email);
        } else {
          _snackbar2.default.error(rejection.password);
        }
      } else {
        _snackbar2.default.apiError(rejection);
      }
    }
  }, {
    key: "render",
    value: function render() {
      return _react2.default.createElement(
        "form",
        { onSubmit: this.handleSubmit },
        _react2.default.createElement("input", { type: "type", style: { display: "none" } }),
        _react2.default.createElement("input", { type: "password", style: { display: "none" } }),
        _react2.default.createElement(
          "div",
          { className: "panel panel-default panel-form" },
          _react2.default.createElement(
            "div",
            { className: "panel-heading" },
            _react2.default.createElement(
              "h3",
              { className: "panel-title" },
              gettext("Change e-mail address")
            )
          ),
          _react2.default.createElement(
            "div",
            { className: "panel-body" },
            _react2.default.createElement(
              _formGroup2.default,
              { label: gettext("New e-mail"), "for": "id_new_email" },
              _react2.default.createElement("input", {
                type: "text",
                id: "id_new_email",
                className: "form-control",
                disabled: this.state.isLoading,
                onChange: this.bindInput("new_email"),
                value: this.state.new_email
              })
            ),
            _react2.default.createElement("hr", null),
            _react2.default.createElement(
              _formGroup2.default,
              {
                label: gettext("Your current password"),
                "for": "id_confirm_email"
              },
              _react2.default.createElement("input", {
                type: "password",
                id: "id_confirm_email",
                className: "form-control",
                disabled: this.state.isLoading,
                onChange: this.bindInput("password"),
                value: this.state.password
              })
            )
          ),
          _react2.default.createElement(
            "div",
            { className: "panel-footer" },
            _react2.default.createElement(
              _button2.default,
              { className: "btn-primary", loading: this.state.isLoading },
              gettext("Change e-mail")
            )
          )
        )
      );
    }
  }]);

  return _class;
}(_form2.default);

exports.default = _class;

},{"../../../services/ajax":365,"../../../services/snackbar":376,"../../../utils/validators":393,"../../button":8,"../../form":56,"../../form-group":55,"react":"react"}],90:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _button = require("../../button");

var _button2 = _interopRequireDefault(_button);

var _form = require("../../form");

var _form2 = _interopRequireDefault(_form);

var _formGroup = require("../../form-group");

var _formGroup2 = _interopRequireDefault(_formGroup);

var _ajax = require("../../../services/ajax");

var _ajax2 = _interopRequireDefault(_ajax);

var _snackbar = require("../../../services/snackbar");

var _snackbar2 = _interopRequireDefault(_snackbar);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_Form) {
  _inherits(_class, _Form);

  function _class(props) {
    _classCallCheck(this, _class);

    var _this = _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).call(this, props));

    _this.state = {
      new_password: "",
      repeat_password: "",
      password: "",

      validators: {
        new_password: [],
        repeat_password: [],
        password: []
      },

      isLoading: false
    };
    return _this;
  }

  _createClass(_class, [{
    key: "clean",
    value: function clean() {
      var errors = this.validate();
      var lengths = [this.state.new_password.trim().length, this.state.repeat_password.trim().length, this.state.password.trim().length];

      if (lengths.indexOf(0) !== -1) {
        _snackbar2.default.error(gettext("Fill out all fields."));
        return false;
      }

      if (errors.new_password) {
        _snackbar2.default.error(errors.new_password[0]);
        return false;
      }

      if (this.state.new_password !== this.state.repeat_password) {
        _snackbar2.default.error(gettext("New passwords are different."));
        return false;
      }

      return true;
    }
  }, {
    key: "send",
    value: function send() {
      return _ajax2.default.post(this.props.user.api.change_password, {
        new_password: this.state.new_password,
        password: this.state.password
      });
    }
  }, {
    key: "handleSuccess",
    value: function handleSuccess(response) {
      this.setState({
        new_password: "",
        repeat_password: "",
        password: ""
      });

      _snackbar2.default.success(response.detail);
    }
  }, {
    key: "handleError",
    value: function handleError(rejection) {
      if (rejection.status === 400) {
        if (rejection.new_password) {
          _snackbar2.default.error(rejection.new_password);
        } else {
          _snackbar2.default.error(rejection.password);
        }
      } else {
        _snackbar2.default.apiError(rejection);
      }
    }
  }, {
    key: "render",
    value: function render() {
      return _react2.default.createElement(
        "form",
        { onSubmit: this.handleSubmit },
        _react2.default.createElement("input", { type: "type", style: { display: "none" } }),
        _react2.default.createElement("input", { type: "password", style: { display: "none" } }),
        _react2.default.createElement(
          "div",
          { className: "panel panel-default panel-form" },
          _react2.default.createElement(
            "div",
            { className: "panel-heading" },
            _react2.default.createElement(
              "h3",
              { className: "panel-title" },
              gettext("Change password")
            )
          ),
          _react2.default.createElement(
            "div",
            { className: "panel-body" },
            _react2.default.createElement(
              _formGroup2.default,
              { label: gettext("New password"), "for": "id_new_password" },
              _react2.default.createElement("input", {
                type: "password",
                id: "id_new_password",
                className: "form-control",
                disabled: this.state.isLoading,
                onChange: this.bindInput("new_password"),
                value: this.state.new_password
              })
            ),
            _react2.default.createElement(
              _formGroup2.default,
              {
                label: gettext("Repeat password"),
                "for": "id_repeat_password"
              },
              _react2.default.createElement("input", {
                type: "password",
                id: "id_repeat_password",
                className: "form-control",
                disabled: this.state.isLoading,
                onChange: this.bindInput("repeat_password"),
                value: this.state.repeat_password
              })
            ),
            _react2.default.createElement("hr", null),
            _react2.default.createElement(
              _formGroup2.default,
              {
                label: gettext("Your current password"),
                "for": "id_confirm_password"
              },
              _react2.default.createElement("input", {
                type: "password",
                id: "id_confirm_password",
                className: "form-control",
                disabled: this.state.isLoading,
                onChange: this.bindInput("password"),
                value: this.state.password
              })
            )
          ),
          _react2.default.createElement(
            "div",
            { className: "panel-footer" },
            _react2.default.createElement(
              _button2.default,
              { className: "btn-primary", loading: this.state.isLoading },
              gettext("Change password")
            )
          )
        )
      );
    }
  }]);

  return _class;
}(_form2.default);

exports.default = _class;

},{"../../../services/ajax":365,"../../../services/snackbar":376,"../../button":8,"../../form":56,"../../form-group":55,"react":"react"}],91:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _changeEmail = require("./change-email");

var _changeEmail2 = _interopRequireDefault(_changeEmail);

var _changePassword = require("./change-password");

var _changePassword2 = _interopRequireDefault(_changePassword);

var _index = require("../../../index");

var _index2 = _interopRequireDefault(_index);

var _pageTitle = require("../../../services/page-title");

var _pageTitle2 = _interopRequireDefault(_pageTitle);

var _UnusablePasswordMessage = require("./UnusablePasswordMessage");

var _UnusablePasswordMessage2 = _interopRequireDefault(_UnusablePasswordMessage);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_React$Component) {
  _inherits(_class, _React$Component);

  function _class() {
    _classCallCheck(this, _class);

    return _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).apply(this, arguments));
  }

  _createClass(_class, [{
    key: "componentDidMount",
    value: function componentDidMount() {
      _pageTitle2.default.set({
        title: gettext("Change email or password"),
        parent: gettext("Change your options")
      });
    }
  }, {
    key: "render",
    value: function render() {
      if (!this.props.user.has_usable_password) {
        return _react2.default.createElement(_UnusablePasswordMessage2.default, null);
      }

      return _react2.default.createElement(
        "div",
        null,
        _react2.default.createElement(_changeEmail2.default, { user: this.props.user }),
        _react2.default.createElement(_changePassword2.default, { user: this.props.user }),
        _react2.default.createElement(
          "p",
          { className: "message-line" },
          _react2.default.createElement(
            "span",
            { className: "material-icon" },
            "warning"
          ),
          _react2.default.createElement(
            "a",
            { href: _index2.default.get("FORGOTTEN_PASSWORD_URL") },
            gettext("Change forgotten password")
          )
        )
      );
    }
  }]);

  return _class;
}(_react2.default.Component);

exports.default = _class;

},{"../../../index":302,"../../../services/page-title":373,"./UnusablePasswordMessage":88,"./change-email":89,"./change-password":90,"react":"react"}],92:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _stringCount = require("../utils/string-count");

var _stringCount2 = _interopRequireDefault(_stringCount);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_React$Component) {
  _inherits(_class, _React$Component);

  function _class() {
    _classCallCheck(this, _class);

    return _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).apply(this, arguments));
  }

  _createClass(_class, [{
    key: "getClassName",
    value: function getClassName() {
      if (this.props.copy && this.props.copy.length) {
        if ((0, _stringCount2.default)(this.props.copy, "<p") === 1 && this.props.copy.indexOf("<br") === -1) {
          return "page-lead lead";
        }
      }

      return "page-lead";
    }
  }, {
    key: "render",
    value: function render() {
      if (this.props.copy && this.props.copy.length) {
        return _react2.default.createElement("div", {
          className: this.getClassName(),
          dangerouslySetInnerHTML: {
            __html: this.props.copy
          }
        });
      } else {
        return null;
      }
    }
  }]);

  return _class;
}(_react2.default.Component);

exports.default = _class;

},{"../utils/string-count":392,"react":"react"}],93:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _loader = require("./loader");

var _loader2 = _interopRequireDefault(_loader);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_React$Component) {
  _inherits(_class, _React$Component);

  function _class() {
    _classCallCheck(this, _class);

    return _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).apply(this, arguments));
  }

  _createClass(_class, [{
    key: "render",
    value: function render() {
      return _react2.default.createElement(
        "div",
        { className: "panel-body panel-body-loading" },
        _react2.default.createElement(_loader2.default, { className: "loader loader-spaced" })
      );
    }
  }]);

  return _class;
}(_react2.default.Component);

exports.default = _class;

},{"./loader":58,"react":"react"}],94:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_React$Component) {
  _inherits(_class, _React$Component);

  function _class() {
    _classCallCheck(this, _class);

    return _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).apply(this, arguments));
  }

  _createClass(_class, [{
    key: "getHelpText",
    value: function getHelpText() {
      if (this.props.helpText) {
        return _react2.default.createElement(
          "p",
          { className: "help-block" },
          this.props.helpText
        );
      } else {
        return null;
      }
    }
  }, {
    key: "render",
    value: function render() {
      return _react2.default.createElement(
        "div",
        { className: "panel-body panel-message-body" },
        _react2.default.createElement(
          "div",
          { className: "message-icon" },
          _react2.default.createElement(
            "span",
            { className: "material-icon" },
            this.props.icon || "info_outline"
          )
        ),
        _react2.default.createElement(
          "div",
          { className: "message-body" },
          _react2.default.createElement(
            "p",
            { className: "lead" },
            this.props.message
          ),
          this.getHelpText()
        )
      );
    }
  }]);

  return _class;
}(_react2.default.Component);

exports.default = _class;

},{"react":"react"}],95:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _addParticipant = require("../add-participant");

var _addParticipant2 = _interopRequireDefault(_addParticipant);

var _modal = require("../../services/modal");

var _modal2 = _interopRequireDefault(_modal);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_React$Component) {
  _inherits(_class, _React$Component);

  function _class() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, _class);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = _class.__proto__ || Object.getPrototypeOf(_class)).call.apply(_ref, [this].concat(args))), _this), _this.onClick = function () {
      _modal2.default.show(_react2.default.createElement(_addParticipant2.default, { thread: _this.props.thread }));
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(_class, [{
    key: "render",
    value: function render() {
      if (!this.props.thread.acl.can_add_participants) return null;

      return _react2.default.createElement(
        "div",
        { className: "col-xs-12 col-sm-3" },
        _react2.default.createElement(
          "button",
          {
            className: "btn btn-default btn-block",
            onClick: this.onClick,
            type: "button"
          },
          _react2.default.createElement(
            "span",
            { className: "material-icon" },
            "person_add"
          ),
          gettext("Add participant")
        )
      );
    }
  }]);

  return _class;
}(_react2.default.Component);

exports.default = _class;

},{"../../services/modal":371,"../add-participant":4,"react":"react"}],96:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.leave = leave;
exports.remove = remove;
exports.changeOwner = changeOwner;

var _participants = require("../../../reducers/participants");

var participants = _interopRequireWildcard(_participants);

var _thread = require("../../../reducers/thread");

var _ = require("../../..");

var _2 = _interopRequireDefault(_);

var _ajax = require("../../../services/ajax");

var _ajax2 = _interopRequireDefault(_ajax);

var _snackbar = require("../../../services/snackbar");

var _snackbar2 = _interopRequireDefault(_snackbar);

var _store = require("../../../services/store");

var _store2 = _interopRequireDefault(_store);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function leave(thread, participant) {
  _ajax2.default.patch(thread.api.index, [{ op: "remove", path: "participants", value: participant.id }]).then(function () {
    _snackbar2.default.success(gettext("You have left this thread."));
    window.setTimeout(function () {
      window.location = _2.default.get("PRIVATE_THREADS_URL");
    }, 3 * 1000);
  }, function (rejection) {
    _snackbar2.default.apiError(rejection);
  });
}

function remove(thread, participant) {
  _ajax2.default.patch(thread.api.index, [{ op: "remove", path: "participants", value: participant.id }, { op: "add", path: "acl", value: 1 }]).then(function (data) {
    _store2.default.dispatch((0, _thread.updateAcl)(data));
    _store2.default.dispatch(participants.replace(data.participants));

    var message = gettext("%(user)s has been removed from this thread.");
    _snackbar2.default.success(interpolate(message, {
      user: participant.username
    }, true));
  }, function (rejection) {
    _snackbar2.default.apiError(rejection);
  });
}

function changeOwner(thread, participant) {
  _ajax2.default.patch(thread.api.index, [{ op: "replace", path: "owner", value: participant.id }, { op: "add", path: "acl", value: 1 }]).then(function (data) {
    _store2.default.dispatch((0, _thread.updateAcl)(data));
    _store2.default.dispatch(participants.replace(data.participants));

    var message = gettext("%(user)s has been made new thread owner.");
    _snackbar2.default.success(interpolate(message, {
      user: participant.username
    }, true));
  }, function (rejection) {
    _snackbar2.default.apiError(rejection);
  });
}

},{"../../..":302,"../../../reducers/participants":351,"../../../reducers/thread":360,"../../../services/ajax":365,"../../../services/snackbar":376,"../../../services/store":377}],97:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (props) {
  var participant = props.participant;

  var className = "btn btn-default";
  if (participant.is_owner) {
    className = "btn btn-primary";
  }
  className += " btn-user btn-block";

  return _react2.default.createElement(
    "div",
    { className: "col-xs-12 col-sm-3 col-md-2 participant-card" },
    _react2.default.createElement(
      "div",
      { className: "dropdown" },
      _react2.default.createElement(
        "button",
        {
          "aria-haspopup": "true",
          "aria-expanded": "false",
          className: className,
          "data-toggle": "dropdown",
          type: "button"
        },
        _react2.default.createElement(_avatar2.default, { size: "34", user: participant }),
        _react2.default.createElement(
          "span",
          { className: "btn-text" },
          participant.username
        )
      ),
      _react2.default.createElement(
        "ul",
        { className: "dropdown-menu stick-to-bottom" },
        _react2.default.createElement(UserStatus, { isOwner: participant.is_owner }),
        _react2.default.createElement("li", { className: "dropdown-header" }),
        _react2.default.createElement(
          "li",
          null,
          _react2.default.createElement(
            "a",
            { href: participant.url },
            gettext("See profile")
          )
        ),
        _react2.default.createElement("li", { role: "separator", className: "divider" }),
        _react2.default.createElement(_makeOwner2.default, props),
        _react2.default.createElement(_remove2.default, props)
      )
    )
  );
};

exports.UserStatus = UserStatus;

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _makeOwner = require("./make-owner");

var _makeOwner2 = _interopRequireDefault(_makeOwner);

var _remove = require("./remove");

var _remove2 = _interopRequireDefault(_remove);

var _avatar = require("../../avatar");

var _avatar2 = _interopRequireDefault(_avatar);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function UserStatus(_ref) {
  var isOwner = _ref.isOwner;

  if (!isOwner) return null;

  return _react2.default.createElement(
    "li",
    { className: "dropdown-header dropdown-header-owner" },
    _react2.default.createElement(
      "span",
      { className: "material-icon" },
      "start"
    ),
    _react2.default.createElement(
      "span",
      { className: "icon-text" },
      gettext("Thread owner")
    )
  );
}

},{"../../avatar":6,"./make-owner":99,"./remove":100,"react":"react"}],98:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (_ref) {
  var participants = _ref.participants,
      thread = _ref.thread,
      user = _ref.user,
      userIsOwner = _ref.userIsOwner;

  return _react2.default.createElement(
    "div",
    { className: "participants-cards" },
    _react2.default.createElement(
      "div",
      { className: "row" },
      participants.map(function (participant) {
        return _react2.default.createElement(_card2.default, {
          key: participant.id,
          participant: participant,
          thread: thread,
          user: user,
          userIsOwner: userIsOwner
        });
      })
    )
  );
};

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _card = require("./card");

var _card2 = _interopRequireDefault(_card);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

},{"./card":97,"react":"react"}],99:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _actions = require("./actions");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_React$Component) {
  _inherits(_class, _React$Component);

  function _class(props) {
    _classCallCheck(this, _class);

    var _this = _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).call(this, props));

    _this.onClick = function () {
      var confirmed = false;
      if (_this.isUser) {
        confirmed = confirm(gettext("Are you sure you want to take over this thread?"));
      } else {
        var message = gettext("Are you sure you want to change thread owner to %(user)s?");
        confirmed = confirm(interpolate(message, {
          user: _this.props.participant.username
        }, true));
      }

      if (!confirmed) return;

      (0, _actions.changeOwner)(_this.props.thread, _this.props.participant);
    };

    _this.isUser = props.participant.id === props.user.id;
    return _this;
  }

  _createClass(_class, [{
    key: "render",
    value: function render() {
      if (this.props.participant.is_owner) return null;
      if (!this.props.thread.acl.can_change_owner) return null;

      return _react2.default.createElement(
        "li",
        null,
        _react2.default.createElement(
          "button",
          { className: "btn btn-link", onClick: this.onClick, type: "button" },
          gettext("Make owner")
        )
      );
    }
  }]);

  return _class;
}(_react2.default.Component);

exports.default = _class;

},{"./actions":96,"react":"react"}],100:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _actions = require("./actions");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_React$Component) {
  _inherits(_class, _React$Component);

  function _class(props) {
    _classCallCheck(this, _class);

    var _this = _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).call(this, props));

    _this.onClick = function () {
      var confirmed = false;
      if (_this.isUser) {
        confirmed = confirm(gettext("Are you sure you want to leave this thread?"));
      } else {
        var message = gettext("Are you sure you want to remove %(user)s from this thread?");
        confirmed = confirm(interpolate(message, {
          user: _this.props.participant.username
        }, true));
      }

      if (!confirmed) return;

      if (_this.isUser) {
        (0, _actions.leave)(_this.props.thread, _this.props.participant);
      } else {
        (0, _actions.remove)(_this.props.thread, _this.props.participant);
      }
    };

    _this.isUser = props.participant.id === props.user.id;
    return _this;
  }

  _createClass(_class, [{
    key: "render",
    value: function render() {
      var isModerator = this.props.user.acl.can_moderate_private_threads;

      if (!(this.props.userIsOwner || this.isUser || isModerator)) return null;

      return _react2.default.createElement(
        "li",
        null,
        _react2.default.createElement(
          "button",
          { className: "btn btn-link", onClick: this.onClick, type: "button" },
          this.isUser ? gettext("Leave thread") : gettext("Remove")
        )
      );
    }
  }]);

  return _class;
}(_react2.default.Component);

exports.default = _class;

},{"./actions":96,"react":"react"}],101:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

exports.default = function (props) {
  if (!props.participants.length) return null;

  return _react2.default.createElement(
    "div",
    { className: "panel panel-default panel-participants" },
    _react2.default.createElement(
      "div",
      { className: "panel-body" },
      _react2.default.createElement(_cardsList2.default, _extends({
        userIsOwner: getUserIsOwner(props.user, props.participants)
      }, props)),
      _react2.default.createElement(
        "div",
        { className: "row" },
        _react2.default.createElement(_addParticipant2.default, { thread: props.thread }),
        _react2.default.createElement(
          "div",
          { className: "col-xs-12 col-sm-9" },
          _react2.default.createElement(
            "p",
            null,
            utils.getParticipantsCopy(props.participants)
          )
        )
      )
    )
  );
};

exports.getUserIsOwner = getUserIsOwner;

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _addParticipant = require("./add-participant");

var _addParticipant2 = _interopRequireDefault(_addParticipant);

var _cardsList = require("./cards-list");

var _cardsList2 = _interopRequireDefault(_cardsList);

var _utils = require("./utils");

var utils = _interopRequireWildcard(_utils);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function getUserIsOwner(user, participants) {
  return participants[0].id === user.id;
}

},{"./add-participant":95,"./cards-list":98,"./utils":102,"react":"react"}],102:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getParticipantsCopy = getParticipantsCopy;
function getParticipantsCopy(participants) {
  var count = participants.length;
  var message = ngettext("This thread has %(users)s participant.", "This thread has %(users)s participants.", count);

  return interpolate(message, {
    users: count
  }, true);
}

},{}],103:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LABELS = exports.STYLES = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _zxcvbn = require("../services/zxcvbn");

var _zxcvbn2 = _interopRequireDefault(_zxcvbn);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var STYLES = exports.STYLES = ["progress-bar-danger", "progress-bar-warning", "progress-bar-warning", "progress-bar-primary", "progress-bar-success"];

var LABELS = exports.LABELS = [gettext("Entered password is very weak."), gettext("Entered password is weak."), gettext("Entered password is average."), gettext("Entered password is strong."), gettext("Entered password is very strong.")];

var _class = function (_React$Component) {
  _inherits(_class, _React$Component);

  function _class(props) {
    _classCallCheck(this, _class);

    var _this = _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).call(this, props));

    _this._score = 0;
    _this._password = null;
    _this._inputs = [];

    _this.state = {
      loaded: false
    };
    return _this;
  }

  _createClass(_class, [{
    key: "componentDidMount",
    value: function componentDidMount() {
      var _this2 = this;

      _zxcvbn2.default.load().then(function () {
        _this2.setState({ loaded: true });
      });
    }
  }, {
    key: "getScore",
    value: function getScore(password, inputs) {
      var _this3 = this;

      var cacheStale = false;

      if (password !== this._password) {
        cacheStale = true;
      }

      if (inputs.length !== this._inputs.length) {
        cacheStale = true;
      } else {
        inputs.map(function (value, i) {
          if (value.trim() !== _this3._inputs[i]) {
            cacheStale = true;
          }
        });
      }

      if (cacheStale) {
        this._score = _zxcvbn2.default.scorePassword(password, inputs);
        this._password = password;
        this._inputs = inputs.map(function (value) {
          return value.trim();
        });
      }

      return this._score;
    }
  }, {
    key: "render",
    value: function render() {
      if (!this.state.loaded) return null;

      var score = this.getScore(this.props.password, this.props.inputs);

      return _react2.default.createElement(
        "div",
        { className: "help-block password-strength" },
        _react2.default.createElement(
          "div",
          { className: "progress" },
          _react2.default.createElement(
            "div",
            {
              className: "progress-bar " + STYLES[score],
              style: { width: 20 + 20 * score + "%" },
              role: "progress-bar",
              "aria-valuenow": score,
              "aria-valuemin": "0",
              "aria-valuemax": "4"
            },
            _react2.default.createElement(
              "span",
              { className: "sr-only" },
              LABELS[score]
            )
          )
        ),
        _react2.default.createElement(
          "p",
          { className: "text-small" },
          LABELS[score]
        )
      );
    }
  }]);

  return _class;
}(_react2.default.Component);

exports.default = _class;

},{"../services/zxcvbn":378,"react":"react"}],104:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PollChoice = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

exports.generateRandomHash = generateRandomHash;

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_React$Component) {
  _inherits(_class, _React$Component);

  function _class() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, _class);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = _class.__proto__ || Object.getPrototypeOf(_class)).call.apply(_ref, [this].concat(args))), _this), _this.onAdd = function () {
      var choices = _this.props.choices.slice();
      choices.push({
        hash: generateRandomHash(),
        label: ""
      });

      _this.props.setChoices(choices);
    }, _this.onChange = function (hash, label) {
      var choices = _this.props.choices.map(function (choice) {
        if (choice.hash === hash) {
          choice.label = label;
        }

        return choice;
      });
      _this.props.setChoices(choices);
    }, _this.onDelete = function (hash) {
      var choices = _this.props.choices.filter(function (choice) {
        return choice.hash !== hash;
      });
      _this.props.setChoices(choices);
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(_class, [{
    key: "render",
    value: function render() {
      var _this2 = this;

      return _react2.default.createElement(
        "div",
        { className: "poll-choices-control" },
        _react2.default.createElement(
          "ul",
          { className: "list-group" },
          this.props.choices.map(function (choice) {
            return _react2.default.createElement(PollChoice, {
              canDelete: _this2.props.choices.length > 2,
              choice: choice,
              disabled: _this2.props.disabled,
              key: choice.hash,
              onChange: _this2.onChange,
              onDelete: _this2.onDelete
            });
          })
        ),
        _react2.default.createElement(
          "button",
          {
            className: "btn btn-default btn-sm",
            disabled: this.props.disabled,
            onClick: this.onAdd,
            type: "button"
          },
          gettext("Add choice")
        )
      );
    }
  }]);

  return _class;
}(_react2.default.Component);

exports.default = _class;

var PollChoice = exports.PollChoice = function (_React$Component2) {
  _inherits(PollChoice, _React$Component2);

  function PollChoice() {
    var _ref2;

    var _temp2, _this3, _ret2;

    _classCallCheck(this, PollChoice);

    for (var _len2 = arguments.length, args = Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
      args[_key2] = arguments[_key2];
    }

    return _ret2 = (_temp2 = (_this3 = _possibleConstructorReturn(this, (_ref2 = PollChoice.__proto__ || Object.getPrototypeOf(PollChoice)).call.apply(_ref2, [this].concat(args))), _this3), _this3.onChange = function (event) {
      _this3.props.onChange(_this3.props.choice.hash, event.target.value);
    }, _this3.onDelete = function () {
      var deleteItem = confirm(gettext("Are you sure you want to delete this choice?"));
      if (deleteItem) {
        _this3.props.onDelete(_this3.props.choice.hash);
      }
    }, _temp2), _possibleConstructorReturn(_this3, _ret2);
  }

  _createClass(PollChoice, [{
    key: "render",
    value: function render() {
      return _react2.default.createElement(
        "li",
        { className: "list-group-item" },
        _react2.default.createElement(
          "button",
          {
            className: "btn",
            disabled: !this.props.canDelete || this.props.disabled,
            onClick: this.onDelete,
            title: gettext("Delete this choice"),
            type: "button"
          },
          _react2.default.createElement(
            "span",
            { className: "material-icon" },
            "close"
          )
        ),
        _react2.default.createElement("input", {
          disabled: this.props.disabled,
          maxLength: "255",
          placeholder: gettext("choice label"),
          type: "text",
          onChange: this.onChange,
          value: this.props.choice.label
        })
      );
    }
  }]);

  return PollChoice;
}(_react2.default.Component);

function generateRandomHash() {
  var randomHash = "";
  while (randomHash.length != 12) {
    randomHash = Math.random().toString(36).replace(/[^a-zA-Z0-9]+/g, "").substr(1, 12);
  }
  return randomHash;
}

},{"react":"react"}],105:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

exports.PollPublicSwitch = PollPublicSwitch;

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _choicesControl = require("./choices-control");

var _choicesControl2 = _interopRequireDefault(_choicesControl);

var _button = require("../../button");

var _button2 = _interopRequireDefault(_button);

var _form = require("../../form");

var _form2 = _interopRequireDefault(_form);

var _formGroup = require("../../form-group");

var _formGroup2 = _interopRequireDefault(_formGroup);

var _yesNoSwitch = require("../../yes-no-switch");

var _yesNoSwitch2 = _interopRequireDefault(_yesNoSwitch);

var _poll = require("../../../reducers/poll");

var poll = _interopRequireWildcard(_poll);

var _ajax = require("../../../services/ajax");

var _ajax2 = _interopRequireDefault(_ajax);

var _posting = require("../../../services/posting");

var _posting2 = _interopRequireDefault(_posting);

var _snackbar = require("../../../services/snackbar");

var _snackbar2 = _interopRequireDefault(_snackbar);

var _store = require("../../../services/store");

var _store2 = _interopRequireDefault(_store);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_Form) {
  _inherits(_class, _Form);

  function _class(props) {
    _classCallCheck(this, _class);

    var _this = _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).call(this, props));

    _this.setChoices = function (choices) {
      var errors = Object.assign({}, errors, { choices: null });

      _this.setState({
        choices: choices,
        errors: errors
      });
    };

    _this.onCancel = function () {
      var cancel = confirm(gettext("Are you sure you want to discard poll?"));
      if (cancel) {
        _posting2.default.close();
      }
    };

    var poll = props.poll || {
      question: "",
      choices: [{
        hash: "choice-10000",
        label: ""
      }, {
        hash: "choice-20000",
        label: ""
      }],
      length: 0,
      allowed_choices: 1,
      allow_revotes: 0,
      is_public: 0
    };

    _this.state = {
      isLoading: false,
      isEdit: !!poll.question,

      question: poll.question,
      choices: poll.choices,
      length: poll.length,
      allowed_choices: poll.allowed_choices,
      allow_revotes: poll.allow_revotes,
      is_public: poll.is_public,

      validators: {
        question: [],
        choices: [],
        length: [],
        allowed_choices: []
      },

      errors: {}
    };
    return _this;
  }

  _createClass(_class, [{
    key: "send",
    value: function send() {
      var data = {
        question: this.state.question,
        choices: this.state.choices,
        length: this.state.length,
        allowed_choices: this.state.allowed_choices,
        allow_revotes: this.state.allow_revotes,
        is_public: this.state.is_public
      };

      if (this.state.isEdit) {
        return _ajax2.default.put(this.props.poll.api.index, data);
      } else {
        return _ajax2.default.post(this.props.thread.api.poll, data);
      }
    }
  }, {
    key: "handleSuccess",
    value: function handleSuccess(data) {
      _store2.default.dispatch(poll.replace(data));

      if (this.state.isEdit) {
        _snackbar2.default.success(gettext("Poll has been edited."));
      } else {
        _snackbar2.default.success(gettext("Poll has been posted."));
      }

      _posting2.default.close();
    }
  }, {
    key: "handleError",
    value: function handleError(rejection) {
      if (rejection.status === 400) {
        if (rejection.non_field_errors) {
          rejection.allowed_choices = rejection.non_field_errors;
        }

        this.setState({
          errors: Object.assign({}, rejection)
        });

        _snackbar2.default.error(gettext("Form contains errors."));
      } else {
        _snackbar2.default.apiError(rejection);
      }
    }
  }, {
    key: "render",
    value: function render() {
      return _react2.default.createElement(
        "div",
        { className: "poll-form" },
        _react2.default.createElement(
          "div",
          { className: "container" },
          _react2.default.createElement(
            "form",
            { onSubmit: this.handleSubmit },
            _react2.default.createElement(
              "div",
              { className: "panel panel-default panel-form" },
              _react2.default.createElement(
                "div",
                { className: "panel-body" },
                _react2.default.createElement(
                  "fieldset",
                  null,
                  _react2.default.createElement(
                    "legend",
                    null,
                    gettext("Question and choices")
                  ),
                  _react2.default.createElement(
                    _formGroup2.default,
                    {
                      label: gettext("Poll question"),
                      "for": "id_questions",
                      validation: this.state.errors.question
                    },
                    _react2.default.createElement("input", {
                      className: "form-control",
                      disabled: this.state.isLoading,
                      id: "id_questions",
                      onChange: this.bindInput("question"),
                      type: "text",
                      maxLength: "255",
                      value: this.state.question
                    })
                  ),
                  _react2.default.createElement(
                    _formGroup2.default,
                    {
                      label: gettext("Available choices"),
                      validation: this.state.errors.choices
                    },
                    _react2.default.createElement(_choicesControl2.default, {
                      choices: this.state.choices,
                      disabled: this.state.isLoading,
                      setChoices: this.setChoices
                    })
                  )
                ),
                _react2.default.createElement(
                  "fieldset",
                  null,
                  _react2.default.createElement(
                    "legend",
                    null,
                    gettext("Voting")
                  ),
                  _react2.default.createElement(
                    "div",
                    { className: "row" },
                    _react2.default.createElement(
                      "div",
                      { className: "col-xs-12 col-sm-6" },
                      _react2.default.createElement(
                        _formGroup2.default,
                        {
                          label: gettext("Poll length"),
                          helpText: gettext("Enter number of days for which voting in this poll should be possible or zero to run this poll indefinitely."),
                          "for": "id_length",
                          validation: this.state.errors.length
                        },
                        _react2.default.createElement("input", {
                          className: "form-control",
                          disabled: this.state.isLoading,
                          id: "id_length",
                          onChange: this.bindInput("length"),
                          type: "text",
                          value: this.state.length
                        })
                      )
                    ),
                    _react2.default.createElement(
                      "div",
                      { className: "col-xs-12 col-sm-6" },
                      _react2.default.createElement(
                        _formGroup2.default,
                        {
                          label: gettext("Allowed choices"),
                          "for": "id_allowed_choices",
                          validation: this.state.errors.allowed_choices
                        },
                        _react2.default.createElement("input", {
                          className: "form-control",
                          disabled: this.state.isLoading,
                          id: "id_allowed_choices",
                          onChange: this.bindInput("allowed_choices"),
                          type: "text",
                          maxLength: "255",
                          value: this.state.allowed_choices
                        })
                      )
                    )
                  ),
                  _react2.default.createElement(
                    "div",
                    { className: "row" },
                    _react2.default.createElement(PollPublicSwitch, {
                      bindInput: this.bindInput,
                      disabled: this.state.isLoading,
                      isEdit: this.state.isEdit,
                      value: this.state.is_public
                    }),
                    _react2.default.createElement(
                      "div",
                      { className: "col-xs-12 col-sm-6" },
                      _react2.default.createElement(
                        _formGroup2.default,
                        {
                          label: gettext("Allow vote changes"),
                          "for": "id_allow_revotes"
                        },
                        _react2.default.createElement(_yesNoSwitch2.default, {
                          id: "id_allow_revotes",
                          disabled: this.state.isLoading,
                          iconOn: "check",
                          iconOff: "close",
                          labelOn: gettext("Allow participants to change their vote"),
                          labelOff: gettext("Don't allow participants to change their vote"),
                          onChange: this.bindInput("allow_revotes"),
                          value: this.state.allow_revotes
                        })
                      )
                    )
                  )
                )
              ),
              _react2.default.createElement(
                "div",
                { className: "panel-footer text-right" },
                _react2.default.createElement(
                  "button",
                  {
                    className: "btn btn-default",
                    disabled: this.state.isLoading,
                    onClick: this.onCancel,
                    type: "button"
                  },
                  gettext("Cancel")
                ),
                " ",
                _react2.default.createElement(
                  _button2.default,
                  { className: "btn-primary", loading: this.state.isLoading },
                  this.state.isEdit ? gettext("Save changes") : gettext("Post poll")
                )
              )
            )
          )
        )
      );
    }
  }]);

  return _class;
}(_form2.default);

exports.default = _class;
function PollPublicSwitch(props) {
  if (props.isEdit) return null;

  return _react2.default.createElement(
    "div",
    { className: "col-xs-12 col-sm-6" },
    _react2.default.createElement(
      _formGroup2.default,
      {
        label: gettext("Make voting public"),
        helpText: gettext("Making voting public will allow everyone to access detailed list of votes, showing which users voted for which choices and at which times. This option can't be changed after poll's creation. Moderators may see voting details for all polls."),
        "for": "id_is_public"
      },
      _react2.default.createElement(_yesNoSwitch2.default, {
        id: "id_is_public",
        disabled: props.disabled,
        iconOn: "visibility",
        iconOff: "visibility_off",
        labelOn: gettext("Votes are public"),
        labelOff: gettext("Votes are hidden"),
        onChange: props.bindInput("is_public"),
        value: props.value
      })
    )
  );
}

},{"../../../reducers/poll":352,"../../../services/ajax":365,"../../../services/posting":375,"../../../services/snackbar":376,"../../../services/store":377,"../../button":8,"../../form":56,"../../form-group":55,"../../yes-no-switch":300,"./choices-control":104,"react":"react"}],106:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PollForm = exports.Poll = undefined;

var _poll = require("./poll");

var _poll2 = _interopRequireDefault(_poll);

var _form = require("./form");

var _form2 = _interopRequireDefault(_form);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.Poll = _poll2.default;
exports.PollForm = _form2.default;

},{"./form":105,"./poll":108}],107:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (props) {
  return _react2.default.createElement(
    "ul",
    { className: "list-unstyled list-inline poll-details" },
    _react2.default.createElement(PollVotes, { votes: props.poll.votes }),
    _react2.default.createElement(PollLength, { poll: props.poll }),
    _react2.default.createElement(PollIsPublic, { poll: props.poll }),
    _react2.default.createElement(PollCreation, { poll: props.poll })
  );
};

exports.PollCreation = PollCreation;
exports.getPoster = getPoster;
exports.getPostedOn = getPostedOn;
exports.PollLength = PollLength;
exports.getEndsOn = getEndsOn;
exports.PollVotes = PollVotes;
exports.PollIsPublic = PollIsPublic;

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _escapeHtml = require("../../utils/escape-html");

var _escapeHtml2 = _interopRequireDefault(_escapeHtml);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var DATE_ABBR = '<abbr title="%(absolute)s">%(relative)s</abbr>';
var USER_SPAN = '<span class="item-title">%(user)s</span>';
var USER_URL = '<a href="%(url)s" class="item-title">%(user)s</a>';

function PollCreation(props) {
  var message = interpolate((0, _escapeHtml2.default)(gettext("Posted by %(poster)s %(posted_on)s.")), {
    poster: getPoster(props.poll),
    posted_on: getPostedOn(props.poll)
  }, true);

  return _react2.default.createElement("li", {
    className: "poll-info-creation",
    dangerouslySetInnerHTML: { __html: message }
  });
}

function getPoster(poll) {
  if (poll.url.poster) {
    return interpolate(USER_URL, {
      url: (0, _escapeHtml2.default)(poll.url.poster),
      user: (0, _escapeHtml2.default)(poll.poster_name)
    }, true);
  }

  return interpolate(USER_SPAN, {
    user: (0, _escapeHtml2.default)(poll.poster_name)
  }, true);
}

function getPostedOn(poll) {
  return interpolate(DATE_ABBR, {
    absolute: (0, _escapeHtml2.default)(poll.posted_on.format("LLL")),
    relative: (0, _escapeHtml2.default)(poll.posted_on.fromNow())
  }, true);
}

function PollLength(props) {
  if (!props.poll.length) {
    return null;
  }

  var message = interpolate((0, _escapeHtml2.default)(gettext("Voting ends %(ends_on)s.")), {
    ends_on: getEndsOn(props.poll)
  }, true);

  return _react2.default.createElement("li", {
    className: "poll-info-ends-on",
    dangerouslySetInnerHTML: { __html: message }
  });
}

function getEndsOn(poll) {
  return interpolate(DATE_ABBR, {
    absolute: (0, _escapeHtml2.default)(poll.endsOn.format("LLL")),
    relative: (0, _escapeHtml2.default)(poll.endsOn.fromNow())
  }, true);
}

function PollVotes(props) {
  var message = ngettext("%(votes)s vote.", "%(votes)s votes.", props.votes);
  var label = interpolate(message, {
    votes: props.votes
  }, true);

  return _react2.default.createElement(
    "li",
    { className: "poll-info-votes" },
    label
  );
}

function PollIsPublic(props) {
  if (!props.poll.is_public) {
    return null;
  }

  return _react2.default.createElement(
    "li",
    { className: "poll-info-public" },
    gettext("Votes are public.")
  );
}

},{"../../utils/escape-html":383,"react":"react"}],108:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

exports.getIsPollOver = getIsPollOver;

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _moment = require("moment");

var _moment2 = _interopRequireDefault(_moment);

var _results = require("./results");

var _results2 = _interopRequireDefault(_results);

var _voting = require("./voting");

var _voting2 = _interopRequireDefault(_voting);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_React$Component) {
  _inherits(_class, _React$Component);

  function _class(props) {
    _classCallCheck(this, _class);

    var _this = _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).call(this, props));

    _this.showResults = function () {
      _this.setState({
        showResults: true
      });
    };

    _this.showVoting = function () {
      _this.setState({
        showResults: false
      });
    };

    var showResults = true;
    if (props.user.id && !props.poll.hasSelectedChoices) {
      showResults = false;
    }

    _this.state = {
      showResults: showResults
    };
    return _this;
  }

  _createClass(_class, [{
    key: "render",
    value: function render() {
      if (!this.props.thread.poll) return null;

      var isPollOver = getIsPollOver(this.props.poll);

      if (!isPollOver && this.props.poll.acl.can_vote && !this.state.showResults) {
        return _react2.default.createElement(_voting2.default, _extends({ showResults: this.showResults }, this.props));
      } else {
        return _react2.default.createElement(_results2.default, _extends({
          isPollOver: isPollOver,
          showVoting: this.showVoting
        }, this.props));
      }
    }
  }]);

  return _class;
}(_react2.default.Component);

exports.default = _class;
function getIsPollOver(poll) {
  if (poll.length) {
    return (0, _moment2.default)().isAfter(poll.endsOn);
  }
  return false;
}

},{"./results":110,"./voting":114,"moment":"moment","react":"react"}],109:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (props) {
  return _react2.default.createElement(
    "div",
    { className: "poll-choices-bars" },
    props.poll.choices.map(function (choice) {
      return _react2.default.createElement(PollChoice, { choice: choice, key: choice.hash, poll: props.poll });
    })
  );
};

exports.PollChoice = PollChoice;
exports.ChoiceVotes = ChoiceVotes;
exports.getVotesLabel = getVotesLabel;
exports.UserChoice = UserChoice;

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function PollChoice(props) {
  var proc = 0;
  if (props.choice.votes && props.poll.votes) {
    proc = Math.ceil(props.choice.votes * 100 / props.poll.votes);
  }

  return _react2.default.createElement(
    "dl",
    { className: "dl-horizontal" },
    _react2.default.createElement(
      "dt",
      null,
      props.choice.label
    ),
    _react2.default.createElement(
      "dd",
      null,
      _react2.default.createElement(
        "div",
        { className: "progress" },
        _react2.default.createElement(
          "div",
          {
            className: "progress-bar",
            role: "progressbar",
            "aria-valuenow": proc,
            "aria-valuemin": "0",
            "aria-valuemax": "100",
            style: { width: proc + "%" }
          },
          _react2.default.createElement(
            "span",
            { className: "sr-only" },
            getVotesLabel(props.votes, props.proc)
          )
        )
      ),
      _react2.default.createElement(
        "ul",
        { className: "list-unstyled list-inline poll-chart" },
        _react2.default.createElement(ChoiceVotes, { proc: proc, votes: props.choice.votes }),
        _react2.default.createElement(UserChoice, { selected: props.choice.selected })
      )
    )
  );
}

function ChoiceVotes(props) {
  return _react2.default.createElement(
    "li",
    { className: "poll-chart-votes" },
    getVotesLabel(props.votes, props.proc)
  );
}

function getVotesLabel(votes, proc) {
  var message = ngettext("%(votes)s vote, %(proc)s% of total.", "%(votes)s votes, %(proc)s% of total.", votes);

  return interpolate(message, {
    votes: votes,
    proc: proc
  }, true);
}

function UserChoice(props) {
  if (!props.selected) return null;

  return _react2.default.createElement(
    "li",
    { className: "poll-chart-selected" },
    _react2.default.createElement(
      "span",
      { className: "material-icon" },
      "check_box"
    ),
    gettext("Your choice.")
  );
}

},{"react":"react"}],110:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (props) {
  return _react2.default.createElement(
    "div",
    { className: "panel panel-default panel-poll" },
    _react2.default.createElement(
      "div",
      { className: "panel-body" },
      _react2.default.createElement(
        "h2",
        null,
        props.poll.question
      ),
      _react2.default.createElement(_info2.default, { poll: props.poll }),
      _react2.default.createElement(_chart2.default, { poll: props.poll }),
      _react2.default.createElement(_options2.default, {
        isPollOver: props.isPollOver,
        poll: props.poll,
        showVoting: props.showVoting,
        thread: props.thread
      })
    )
  );
};

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _chart = require("./chart");

var _chart2 = _interopRequireDefault(_chart);

var _options = require("./options");

var _options2 = _interopRequireDefault(_options);

var _info = require("../info");

var _info2 = _interopRequireDefault(_info);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

},{"../info":107,"./chart":109,"./options":112,"react":"react"}],111:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

exports.ModalBody = ModalBody;
exports.ChoicesList = ChoicesList;
exports.ChoiceDetails = ChoiceDetails;
exports.VotesCount = VotesCount;
exports.VotesList = VotesList;
exports.Voter = Voter;
exports.VoteDate = VoteDate;

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _moment = require("moment");

var _moment2 = _interopRequireDefault(_moment);

var _modalMessage = require("../../modal-message");

var _modalMessage2 = _interopRequireDefault(_modalMessage);

var _modalLoader = require("../../modal-loader");

var _modalLoader2 = _interopRequireDefault(_modalLoader);

var _ajax = require("../../../services/ajax");

var _ajax2 = _interopRequireDefault(_ajax);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_React$Component) {
  _inherits(_class, _React$Component);

  function _class(props) {
    _classCallCheck(this, _class);

    var _this = _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).call(this, props));

    _this.state = {
      isLoading: true,
      error: null,
      data: []
    };
    return _this;
  }

  _createClass(_class, [{
    key: "componentDidMount",
    value: function componentDidMount() {
      var _this2 = this;

      _ajax2.default.get(this.props.poll.api.votes).then(function (data) {
        var hydratedData = data.map(function (choice) {
          return Object.assign({}, choice, {
            voters: choice.voters.map(function (voter) {
              return Object.assign({}, voter, {
                voted_on: (0, _moment2.default)(voter.voted_on)
              });
            })
          });
        });

        _this2.setState({
          isLoading: false,
          data: hydratedData
        });
      }, function (rejection) {
        _this2.setState({
          isLoading: false,
          error: rejection.detail
        });
      });
    }
  }, {
    key: "render",
    value: function render() {
      return _react2.default.createElement(
        "div",
        {
          className: "modal-dialog" + (this.state.error ? " modal-message" : " modal-sm"),
          role: "document"
        },
        _react2.default.createElement(
          "div",
          { className: "modal-content" },
          _react2.default.createElement(
            "div",
            { className: "modal-header" },
            _react2.default.createElement(
              "button",
              {
                type: "button",
                className: "close",
                "data-dismiss": "modal",
                "aria-label": gettext("Close")
              },
              _react2.default.createElement(
                "span",
                { "aria-hidden": "true" },
                "\xD7"
              )
            ),
            _react2.default.createElement(
              "h4",
              { className: "modal-title" },
              gettext("Poll votes")
            )
          ),
          _react2.default.createElement(ModalBody, {
            data: this.state.data,
            error: this.state.error,
            isLoading: this.state.isLoading
          })
        )
      );
    }
  }]);

  return _class;
}(_react2.default.Component);

exports.default = _class;
function ModalBody(props) {
  if (props.isLoading) {
    return _react2.default.createElement(_modalLoader2.default, null);
  } else if (props.error) {
    return _react2.default.createElement(_modalMessage2.default, { icon: "error_outline", message: props.error });
  }

  return _react2.default.createElement(ChoicesList, { data: props.data });
}

function ChoicesList(props) {
  return _react2.default.createElement(
    "div",
    { className: "modal-body modal-poll-votes" },
    _react2.default.createElement(
      "ul",
      { className: "list-unstyled votes-details" },
      props.data.map(function (choice) {
        return _react2.default.createElement(ChoiceDetails, _extends({ key: choice.hash }, choice));
      })
    )
  );
}

function ChoiceDetails(props) {
  return _react2.default.createElement(
    "li",
    null,
    _react2.default.createElement(
      "h4",
      null,
      props.label
    ),
    _react2.default.createElement(VotesCount, { votes: props.votes }),
    _react2.default.createElement(VotesList, { voters: props.voters }),
    _react2.default.createElement("hr", null)
  );
}

function VotesCount(props) {
  var message = ngettext("%(votes)s user has voted for this choice.", "%(votes)s users have voted for this choice.", props.votes);

  var label = interpolate(message, {
    votes: props.votes
  }, true);

  return _react2.default.createElement(
    "p",
    null,
    label
  );
}

function VotesList(props) {
  if (!props.voters.length) return null;

  return _react2.default.createElement(
    "ul",
    { className: "list-unstyled" },
    props.voters.map(function (user) {
      return _react2.default.createElement(Voter, _extends({ key: user.username }, user));
    })
  );
}

function Voter(props) {
  if (props.url) {
    return _react2.default.createElement(
      "li",
      null,
      _react2.default.createElement(
        "a",
        { className: "item-title", href: props.url },
        props.username
      ),
      " ",
      _react2.default.createElement(VoteDate, { voted_on: props.voted_on })
    );
  }

  return _react2.default.createElement(
    "li",
    null,
    _react2.default.createElement(
      "strong",
      null,
      props.username
    ),
    " ",
    _react2.default.createElement(VoteDate, { voted_on: props.voted_on })
  );
}

function VoteDate(props) {
  return _react2.default.createElement(
    "abbr",
    { className: "text-muted", title: props.voted_on.format("LLL") },
    props.voted_on.fromNow()
  );
}

},{"../../../services/ajax":365,"../../modal-loader":61,"../../modal-message":62,"moment":"moment","react":"react"}],112:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Delete = exports.Edit = exports.SeeVotes = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

exports.default = function (props) {
  var isPollOver = props.isPollOver,
      poll = props.poll,
      showVoting = props.showVoting,
      thread = props.thread;


  if (!isVisible(isPollOver, poll.acl, poll)) return null;

  var controls = [];

  var canVote = poll.acl.can_vote;
  var canChangeVote = !poll.hasSelectedChoices || poll.allow_revotes;

  if (canVote && canChangeVote) controls.push(0);
  if (poll.is_public || poll.acl.can_see_votes) controls.push(1);
  if (poll.acl.can_edit) controls.push(2);
  if (poll.acl.can_delete) controls.push(3);

  return _react2.default.createElement(
    "div",
    { className: "row poll-options" },
    _react2.default.createElement(ChangeVote, {
      controls: controls,
      isPollOver: isPollOver,
      poll: poll,
      showVoting: showVoting
    }),
    _react2.default.createElement(SeeVotes, { controls: controls, poll: poll }),
    _react2.default.createElement(Edit, { controls: controls, poll: poll, thread: thread }),
    _react2.default.createElement(Delete, { controls: controls, poll: poll })
  );
};

exports.isVisible = isVisible;
exports.getClassName = getClassName;
exports.ChangeVote = ChangeVote;

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _modal = require("./modal");

var _modal2 = _interopRequireDefault(_modal);

var _poll = require("../../../reducers/poll");

var poll = _interopRequireWildcard(_poll);

var _thread = require("../../../reducers/thread");

var thread = _interopRequireWildcard(_thread);

var _ajax = require("../../../services/ajax");

var _ajax2 = _interopRequireDefault(_ajax);

var _modal3 = require("../../../services/modal");

var _modal4 = _interopRequireDefault(_modal3);

var _posting = require("../../../services/posting");

var _posting2 = _interopRequireDefault(_posting);

var _snackbar = require("../../../services/snackbar");

var _snackbar2 = _interopRequireDefault(_snackbar);

var _store = require("../../../services/store");

var _store2 = _interopRequireDefault(_store);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

function isVisible(isPollOver, acl, poll) {
  return poll.is_public || acl.can_delete || acl.can_edit || acl.can_see_votes || acl.can_vote && !isPollOver && (!poll.hasSelectedChoices || poll.allow_revotes);
}

function getClassName(controls, control) {
  var className = "col-xs-6";

  if (controls.length === 1) {
    className = "col-xs-12";
  }

  if (controls.length === 3 && controls[0] === control) {
    className = "col-xs-12";
  }

  return className + " col-sm-3 col-md-2";
}

function ChangeVote(props) {
  var canVote = props.poll.acl.can_vote;
  var canChangeVote = !props.poll.hasSelectedChoices || props.poll.allow_revotes;

  if (!(canVote && canChangeVote)) return null;

  return _react2.default.createElement(
    "div",
    { className: getClassName(props.controls, 0) },
    _react2.default.createElement(
      "button",
      {
        className: "btn btn-default btn-block btn-sm",
        disabled: props.poll.isBusy,
        onClick: props.showVoting,
        type: "button"
      },
      gettext("Vote")
    )
  );
}

var SeeVotes = exports.SeeVotes = function (_React$Component) {
  _inherits(SeeVotes, _React$Component);

  function SeeVotes() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, SeeVotes);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = SeeVotes.__proto__ || Object.getPrototypeOf(SeeVotes)).call.apply(_ref, [this].concat(args))), _this), _this.onClick = function () {
      _modal4.default.show(_react2.default.createElement(_modal2.default, { poll: _this.props.poll }));
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(SeeVotes, [{
    key: "render",
    value: function render() {
      var seeVotes = this.props.poll.is_public || this.props.poll.acl.can_see_votes;
      if (!seeVotes) return null;

      return _react2.default.createElement(
        "div",
        { className: getClassName(this.props.controls, 1) },
        _react2.default.createElement(
          "button",
          {
            className: "btn btn-default btn-block btn-sm",
            disabled: this.props.poll.isBusy,
            onClick: this.onClick,
            type: "button"
          },
          gettext("See votes")
        )
      );
    }
  }]);

  return SeeVotes;
}(_react2.default.Component);

var Edit = exports.Edit = function (_React$Component2) {
  _inherits(Edit, _React$Component2);

  function Edit() {
    var _ref2;

    var _temp2, _this2, _ret2;

    _classCallCheck(this, Edit);

    for (var _len2 = arguments.length, args = Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
      args[_key2] = arguments[_key2];
    }

    return _ret2 = (_temp2 = (_this2 = _possibleConstructorReturn(this, (_ref2 = Edit.__proto__ || Object.getPrototypeOf(Edit)).call.apply(_ref2, [this].concat(args))), _this2), _this2.onClick = function () {
      _posting2.default.open({
        submit: _this2.props.poll.api.index,

        thread: _this2.props.thread,
        poll: _this2.props.poll,

        mode: "POLL"
      });
    }, _temp2), _possibleConstructorReturn(_this2, _ret2);
  }

  _createClass(Edit, [{
    key: "render",
    value: function render() {
      if (!this.props.poll.acl.can_edit) return null;

      return _react2.default.createElement(
        "div",
        { className: getClassName(this.props.controls, 2) },
        _react2.default.createElement(
          "button",
          {
            className: "btn btn-default btn-block btn-sm",
            disabled: this.props.poll.isBusy,
            onClick: this.onClick,
            type: "button"
          },
          gettext("Edit")
        )
      );
    }
  }]);

  return Edit;
}(_react2.default.Component);

var Delete = exports.Delete = function (_React$Component3) {
  _inherits(Delete, _React$Component3);

  function Delete() {
    var _ref3;

    var _temp3, _this3, _ret3;

    _classCallCheck(this, Delete);

    for (var _len3 = arguments.length, args = Array(_len3), _key3 = 0; _key3 < _len3; _key3++) {
      args[_key3] = arguments[_key3];
    }

    return _ret3 = (_temp3 = (_this3 = _possibleConstructorReturn(this, (_ref3 = Delete.__proto__ || Object.getPrototypeOf(Delete)).call.apply(_ref3, [this].concat(args))), _this3), _this3.onClick = function () {
      var deletePoll = confirm(gettext("Are you sure you want to delete this poll? This action is not reversible."));
      if (!deletePoll) return false;

      _store2.default.dispatch(poll.busy());

      _ajax2.default.delete(_this3.props.poll.api.index).then(_this3.handleSuccess, _this3.handleError);
    }, _this3.handleSuccess = function (newThreadAcl) {
      _snackbar2.default.success("Poll has been deleted");
      _store2.default.dispatch(poll.remove());
      _store2.default.dispatch(thread.updateAcl(newThreadAcl));
    }, _this3.handleError = function (rejection) {
      _snackbar2.default.apiError(rejection);
      _store2.default.dispatch(poll.release());
    }, _temp3), _possibleConstructorReturn(_this3, _ret3);
  }

  _createClass(Delete, [{
    key: "render",
    value: function render() {
      if (!this.props.poll.acl.can_delete) return null;

      return _react2.default.createElement(
        "div",
        { className: getClassName(this.props.controls, 3) },
        _react2.default.createElement(
          "button",
          {
            className: "btn btn-default btn-block btn-sm",
            disabled: this.props.poll.isBusy,
            onClick: this.onClick,
            type: "button"
          },
          gettext("Delete")
        )
      );
    }
  }]);

  return Delete;
}(_react2.default.Component);

},{"../../../reducers/poll":352,"../../../reducers/thread":360,"../../../services/ajax":365,"../../../services/modal":371,"../../../services/posting":375,"../../../services/snackbar":376,"../../../services/store":377,"./modal":111,"react":"react"}],113:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (props) {
  return _react2.default.createElement(
    "ul",
    { className: "list-unstyled list-inline poll-help" },
    _react2.default.createElement(PollChoicesLeft, { choicesLeft: props.choicesLeft }),
    _react2.default.createElement(PollAllowRevote, { poll: props.poll })
  );
};

exports.PollChoicesLeft = PollChoicesLeft;
exports.PollAllowRevote = PollAllowRevote;

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _escapeHtml = require("../../../utils/escape-html");

var _escapeHtml2 = _interopRequireDefault(_escapeHtml);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var DATE_ABBR = '<abbr title="%(absolute)s">%(relative)s</abbr>';
var USER_SPAN = '<span class="item-title">%(user)s</span>';
var USER_URL = '<a href="%(url)s" class="item-title">%(user)s</a>';

function PollChoicesLeft(_ref) {
  var choicesLeft = _ref.choicesLeft;

  if (choicesLeft === 0) {
    return _react2.default.createElement(
      "li",
      { className: "poll-help-choices-left" },
      gettext("You can't select any more choices.")
    );
  }

  var message = ngettext("You can select %(choices)s more choice.", "You can select %(choices)s more choices.", choicesLeft);

  var label = interpolate(message, {
    choices: choicesLeft
  }, true);

  return _react2.default.createElement(
    "li",
    { className: "poll-help-choices-left" },
    label
  );
}

function PollAllowRevote(props) {
  if (props.poll.allow_revotes) {
    return _react2.default.createElement(
      "li",
      { className: "poll-help-allow-revotes" },
      gettext("You can change your vote later.")
    );
  }

  return _react2.default.createElement(
    "li",
    { className: "poll-help-no-revotes" },
    gettext("Votes are final.")
  );
}

},{"../../../utils/escape-html":383,"react":"react"}],114:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _help = require("./help");

var _help2 = _interopRequireDefault(_help);

var _select = require("./select");

var _select2 = _interopRequireDefault(_select);

var _utils = require("./utils");

var _info = require("../info");

var _info2 = _interopRequireDefault(_info);

var _options = require("../results/options");

var _button = require("../../button");

var _button2 = _interopRequireDefault(_button);

var _form = require("../../form");

var _form2 = _interopRequireDefault(_form);

var _poll = require("../../../reducers/poll");

var poll = _interopRequireWildcard(_poll);

var _ajax = require("../../../services/ajax");

var _ajax2 = _interopRequireDefault(_ajax);

var _snackbar = require("../../../services/snackbar");

var _snackbar2 = _interopRequireDefault(_snackbar);

var _store = require("../../../services/store");

var _store2 = _interopRequireDefault(_store);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_Form) {
  _inherits(_class, _Form);

  function _class(props) {
    _classCallCheck(this, _class);

    var _this = _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).call(this, props));

    _this.toggleChoice = function (hash) {
      var choice = (0, _utils.getChoiceFromHash)(_this.state.choices, hash);

      var choices = null;
      if (!choice.selected) {
        choices = _this.selectChoice(choice, hash);
      } else {
        choices = _this.deselectChoice(choice, hash);
      }

      _this.setState({
        choices: choices,
        choicesLeft: (0, _utils.getChoicesLeft)(_this.props.poll, choices)
      });
    };

    _this.selectChoice = function (choice, hash) {
      var choicesLeft = (0, _utils.getChoicesLeft)(_this.props.poll, _this.state.choices);

      if (!choicesLeft) {
        for (var i in _this.state.choices.slice()) {
          var item = _this.state.choices[i];
          if (item.selected && item.hash != hash) {
            item.selected = false;
            break;
          }
        }
      }

      return _this.state.choices.map(function (choice) {
        return Object.assign({}, choice, {
          selected: choice.hash == hash ? true : choice.selected
        });
      });
    };

    _this.deselectChoice = function (choice, hash) {
      return _this.state.choices.map(function (choice) {
        return Object.assign({}, choice, {
          selected: choice.hash == hash ? false : choice.selected
        });
      });
    };

    _this.state = {
      isLoading: false,

      choices: props.poll.choices,
      choicesLeft: (0, _utils.getChoicesLeft)(props.poll, props.poll.choices)
    };
    return _this;
  }

  _createClass(_class, [{
    key: "clean",
    value: function clean() {
      if (this.state.choicesLeft === this.props.poll.allowed_choices) {
        _snackbar2.default.error(gettext("You need to select at least one choice"));
        return false;
      }

      return true;
    }
  }, {
    key: "send",
    value: function send() {
      var data = [];
      for (var i in this.state.choices.slice()) {
        var item = this.state.choices[i];
        if (item.selected) {
          data.push(item.hash);
        }
      }

      return _ajax2.default.post(this.props.poll.api.votes, data);
    }
  }, {
    key: "handleSuccess",
    value: function handleSuccess(data) {
      _store2.default.dispatch(poll.replace(data));
      _snackbar2.default.success(gettext("Your vote has been saved."));

      this.props.showResults();
    }
  }, {
    key: "handleError",
    value: function handleError(rejection) {
      if (rejection.status === 400) {
        _snackbar2.default.error(rejection.detail);
      } else {
        _snackbar2.default.apiError(rejection);
      }
    }
  }, {
    key: "render",
    value: function render() {
      var controls = [];

      if (this.props.poll.acl.can_vote) controls.push(0);
      if (this.props.poll.is_public || this.props.poll.acl.can_see_votes) controls.push(1);
      if (this.props.poll.acl.can_edit) controls.push(2);
      if (this.props.poll.acl.can_delete) controls.push(3);

      return _react2.default.createElement(
        "div",
        { className: "panel panel-default panel-poll" },
        _react2.default.createElement(
          "form",
          { onSubmit: this.handleSubmit },
          _react2.default.createElement(
            "div",
            { className: "panel-body" },
            _react2.default.createElement(
              "h2",
              null,
              this.props.poll.question
            ),
            _react2.default.createElement(_info2.default, { poll: this.props.poll }),
            _react2.default.createElement(_select2.default, {
              choices: this.state.choices,
              toggleChoice: this.toggleChoice
            }),
            _react2.default.createElement(_help2.default, {
              choicesLeft: this.state.choicesLeft,
              poll: this.props.poll
            })
          ),
          _react2.default.createElement(
            "div",
            { className: "panel-footer" },
            _react2.default.createElement(
              "div",
              { className: "row" },
              _react2.default.createElement(
                "div",
                { className: (0, _options.getClassName)(controls, 0) },
                _react2.default.createElement(
                  _button2.default,
                  {
                    className: "btn-primary btn-block btn-sm",
                    loading: this.state.isLoading
                  },
                  gettext("Save your vote")
                )
              ),
              _react2.default.createElement(
                "div",
                { className: (0, _options.getClassName)(controls, 1) },
                _react2.default.createElement(
                  "button",
                  {
                    className: "btn btn-default btn-block btn-sm",
                    disabled: this.state.isLoading,
                    onClick: this.props.showResults,
                    type: "button"
                  },
                  gettext("See results")
                )
              ),
              _react2.default.createElement(_options.Edit, {
                controls: controls,
                poll: this.props.poll,
                thread: this.props.thread
              }),
              _react2.default.createElement(_options.Delete, { controls: controls, poll: this.props.poll })
            )
          )
        )
      );
    }
  }]);

  return _class;
}(_form2.default);

exports.default = _class;

},{"../../../reducers/poll":352,"../../../services/ajax":365,"../../../services/snackbar":376,"../../../services/store":377,"../../button":8,"../../form":56,"../info":107,"../results/options":112,"./help":113,"./select":115,"./utils":116,"react":"react"}],115:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ChoiceSelect = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

exports.default = function (props) {
  return _react2.default.createElement(
    "ul",
    { className: "list-unstyled poll-select-choices" },
    props.choices.map(function (choice) {
      return _react2.default.createElement(ChoiceSelect, {
        choice: choice,
        key: choice.hash,
        toggleChoice: props.toggleChoice
      });
    })
  );
};

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var ChoiceSelect = exports.ChoiceSelect = function (_React$Component) {
  _inherits(ChoiceSelect, _React$Component);

  function ChoiceSelect() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, ChoiceSelect);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = ChoiceSelect.__proto__ || Object.getPrototypeOf(ChoiceSelect)).call.apply(_ref, [this].concat(args))), _this), _this.onClick = function () {
      _this.props.toggleChoice(_this.props.choice.hash);
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(ChoiceSelect, [{
    key: "render",
    value: function render() {
      return _react2.default.createElement(
        "li",
        { className: "poll-select-choice" },
        _react2.default.createElement(
          "button",
          {
            className: this.props.choice.selected ? "btn btn-selected" : "btn",
            onClick: this.onClick,
            type: "button"
          },
          _react2.default.createElement(
            "span",
            { className: "material-icon" },
            this.props.choice.selected ? "check_box" : "check_box_outline_blank"
          ),
          _react2.default.createElement(
            "strong",
            null,
            this.props.choice.label
          )
        )
      );
    }
  }]);

  return ChoiceSelect;
}(_react2.default.Component);

},{"react":"react"}],116:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getChoiceFromHash = getChoiceFromHash;
exports.getChoicesLeft = getChoicesLeft;
function getChoiceFromHash(choices, hash) {
  for (var i in choices) {
    var choice = choices[i];
    if (choice.hash === hash) {
      return choice;
    }
  }

  return null;
}

function getChoicesLeft(poll, choices) {
  var selection = [];
  for (var i in choices) {
    var choice = choices[i];
    if (choice.selected) {
      selection.push(choice);
    }
  }

  return poll.allowed_choices - selection.length;
}

},{}],117:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (props) {
  return _react2.default.createElement(
    "div",
    { className: "modal-body post-changelog-diff" },
    _react2.default.createElement(
      "ul",
      { className: "list-unstyled" },
      props.diff.map(function (item, i) {
        return _react2.default.createElement(DiffItem, { item: item, key: i });
      })
    )
  );
};

exports.DiffItem = DiffItem;
exports.getItemClassName = getItemClassName;
exports.cleanItem = cleanItem;

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function DiffItem(props) {
  if (props.item[0] === "?") return null;

  return _react2.default.createElement(
    "li",
    { className: getItemClassName(props.item) },
    cleanItem(props.item)
  );
}

function getItemClassName(item) {
  var className = "diff-item";
  if (item[0] === "-") {
    className += " diff-item-sub";
  } else if (item[0] === "+") {
    className += " diff-item-add";
  }
  return className;
}

function cleanItem(item) {
  return item.substr(2);
}

},{"react":"react"}],118:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _button = require("../button");

var _button2 = _interopRequireDefault(_button);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_React$Component) {
  _inherits(_class, _React$Component);

  function _class() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, _class);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = _class.__proto__ || Object.getPrototypeOf(_class)).call.apply(_ref, [this].concat(args))), _this), _this.onClick = function () {
      _this.props.revertEdit(_this.props.edit.id);
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(_class, [{
    key: "render",
    value: function render() {
      if (!this.props.canRevert) return null;

      return _react2.default.createElement(
        "div",
        { className: "modal-footer visible-xs-block" },
        _react2.default.createElement(
          _button2.default,
          {
            className: "btn-default btn-sm btn-block",
            disabled: this.props.disabled,
            onClick: this.onClick,
            title: gettext("Revert post to state from before this edit.")
          },
          gettext("Revert")
        )
      );
    }
  }]);

  return _class;
}(_react2.default.Component);

exports.default = _class;

},{"../button":8,"react":"react"}],119:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

exports.ModalDialog = ModalDialog;

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _diff = require("./diff");

var _diff2 = _interopRequireDefault(_diff);

var _footer = require("./footer");

var _footer2 = _interopRequireDefault(_footer);

var _toolbar = require("./toolbar");

var _toolbar2 = _interopRequireDefault(_toolbar);

var _utils = require("./utils");

var _modalMessage = require("../modal-message");

var _modalMessage2 = _interopRequireDefault(_modalMessage);

var _modalLoader = require("../modal-loader");

var _modalLoader2 = _interopRequireDefault(_modalLoader);

var _post = require("../../reducers/post");

var post = _interopRequireWildcard(_post);

var _ajax = require("../../services/ajax");

var _ajax2 = _interopRequireDefault(_ajax);

var _modal = require("../../services/modal");

var _modal2 = _interopRequireDefault(_modal);

var _snackbar = require("../../services/snackbar");

var _snackbar2 = _interopRequireDefault(_snackbar);

var _store = require("../../services/store");

var _store2 = _interopRequireDefault(_store);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_React$Component) {
  _inherits(_class, _React$Component);

  function _class(props) {
    _classCallCheck(this, _class);

    var _this = _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).call(this, props));

    _this.goToEdit = function () {
      var edit = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;

      _this.setState({
        isBusy: true
      });

      var url = _this.props.post.api.edits;
      if (edit !== null) {
        url += "?edit=" + edit;
      }

      _ajax2.default.get(url).then(function (data) {
        _this.setState({
          isReady: true,
          isBusy: false,
          edit: (0, _utils.hydrateEdit)(data)
        });
      }, function (rejection) {
        _this.setState({
          isReady: true,
          isBusy: false,
          error: rejection.detail
        });
      });
    };

    _this.revertEdit = function (edit) {
      if (_this.state.isBusy) return;

      var confirmation = confirm(gettext("Are you sure you with to revert this post to the state from before this edit?"));
      if (!confirmation) return;

      _this.setState({
        isBusy: true
      });

      var url = _this.props.post.api.edits + "?edit=" + edit;
      _ajax2.default.post(url).then(function (data) {
        var hydratedPost = post.hydrate(data);
        _store2.default.dispatch(post.patch(data, hydratedPost));

        _snackbar2.default.success(gettext("Post has been reverted to previous state."));
        _modal2.default.hide();
      }, function (rejection) {
        _snackbar2.default.apiError(rejection);

        _this.setState({
          isBusy: false
        });
      });
    };

    _this.state = {
      isReady: false,
      isBusy: true,

      canRevert: props.post.acl.can_edit,

      error: null,
      edit: null
    };
    return _this;
  }

  _createClass(_class, [{
    key: "componentDidMount",
    value: function componentDidMount() {
      this.goToEdit();
    }
  }, {
    key: "render",
    value: function render() {
      if (this.state.error) {
        return _react2.default.createElement(
          ModalDialog,
          { className: "modal-dialog modal-message" },
          _react2.default.createElement(_modalMessage2.default, { message: this.state.error })
        );
      } else if (this.state.isReady) {
        return _react2.default.createElement(
          ModalDialog,
          null,
          _react2.default.createElement(_toolbar2.default, {
            canRevert: this.state.canRevert,
            disabled: this.state.isBusy,
            edit: this.state.edit,
            goToEdit: this.goToEdit,
            revertEdit: this.revertEdit
          }),
          _react2.default.createElement(_diff2.default, { diff: this.state.edit.diff }),
          _react2.default.createElement(_footer2.default, {
            canRevert: this.state.canRevert,
            disabled: this.state.isBusy,
            edit: this.state.edit,
            revertEdit: this.revertEdit
          })
        );
      }

      return _react2.default.createElement(
        ModalDialog,
        null,
        _react2.default.createElement(_modalLoader2.default, null)
      );
    }
  }]);

  return _class;
}(_react2.default.Component);

exports.default = _class;
function ModalDialog(props) {
  return _react2.default.createElement(
    "div",
    { className: props.className || "modal-dialog", role: "document" },
    _react2.default.createElement(
      "div",
      { className: "modal-content" },
      _react2.default.createElement(
        "div",
        { className: "modal-header" },
        _react2.default.createElement(
          "button",
          {
            "aria-label": gettext("Close"),
            className: "close",
            "data-dismiss": "modal",
            type: "button"
          },
          _react2.default.createElement(
            "span",
            { "aria-hidden": "true" },
            "\xD7"
          )
        ),
        _react2.default.createElement(
          "h4",
          { className: "modal-title" },
          gettext("Post edits history")
        )
      ),
      props.children
    )
  );
}

},{"../../reducers/post":353,"../../services/ajax":365,"../../services/modal":371,"../../services/snackbar":376,"../../services/store":377,"../modal-loader":61,"../modal-message":62,"./diff":117,"./footer":118,"./toolbar":120,"./utils":121,"react":"react"}],120:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

exports.GoBackBtn = GoBackBtn;
exports.GoForwardBtn = GoForwardBtn;
exports.GoLastBtn = GoLastBtn;
exports.RevertBtn = RevertBtn;
exports.Label = Label;

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _button = require("../button");

var _button2 = _interopRequireDefault(_button);

var _escapeHtml = require("../../utils/escape-html");

var _escapeHtml2 = _interopRequireDefault(_escapeHtml);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var DATE_ABBR = '<abbr title="%(absolute)s">%(relative)s</abbr>';
var USER_SPAN = '<span class="item-title">%(user)s</span>';
var USER_URL = '<a href="%(url)s" class="item-title">%(user)s</a>';

var _class = function (_React$Component) {
  _inherits(_class, _React$Component);

  function _class() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, _class);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = _class.__proto__ || Object.getPrototypeOf(_class)).call.apply(_ref, [this].concat(args))), _this), _this.goLast = function () {
      _this.props.goToEdit();
    }, _this.goForward = function () {
      _this.props.goToEdit(_this.props.edit.next);
    }, _this.goBack = function () {
      _this.props.goToEdit(_this.props.edit.previous);
    }, _this.revertEdit = function () {
      _this.props.revertEdit(_this.props.edit.id);
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(_class, [{
    key: "render",
    value: function render() {
      return _react2.default.createElement(
        "div",
        { className: "modal-toolbar post-changelog-toolbar" },
        _react2.default.createElement(
          "div",
          { className: "row" },
          _react2.default.createElement(
            "div",
            { className: "col-xs-12 col-sm-4" },
            _react2.default.createElement(
              "div",
              { className: "row" },
              _react2.default.createElement(
                "div",
                { className: "col-xs-4" },
                _react2.default.createElement(GoBackBtn, {
                  disabled: this.props.disabled,
                  edit: this.props.edit,
                  onClick: this.goBack
                })
              ),
              _react2.default.createElement(
                "div",
                { className: "col-xs-4" },
                _react2.default.createElement(GoForwardBtn, {
                  disabled: this.props.disabled,
                  edit: this.props.edit,
                  onClick: this.goForward
                })
              ),
              _react2.default.createElement(
                "div",
                { className: "col-xs-4" },
                _react2.default.createElement(GoLastBtn, {
                  disabled: this.props.disabled,
                  edit: this.props.edit,
                  onClick: this.goLast
                })
              )
            )
          ),
          _react2.default.createElement(
            "div",
            { className: "col-xs-12 col-sm-5 xs-margin-top-half post-change-label" },
            _react2.default.createElement(Label, { edit: this.props.edit })
          ),
          _react2.default.createElement(RevertBtn, {
            canRevert: this.props.canRevert,
            disabled: this.props.disabled,
            onClick: this.revertEdit
          })
        )
      );
    }
  }]);

  return _class;
}(_react2.default.Component);

exports.default = _class;
function GoBackBtn(props) {
  return _react2.default.createElement(
    _button2.default,
    {
      className: "btn-default btn-block btn-icon btn-sm",
      disabled: props.disabled || !props.edit.previous,
      onClick: props.onClick,
      title: gettext("See previous change")
    },
    _react2.default.createElement(
      "span",
      { className: "material-icon" },
      "chevron_left"
    )
  );
}

function GoForwardBtn(props) {
  return _react2.default.createElement(
    _button2.default,
    {
      className: "btn-default btn-block btn-icon btn-sm",
      disabled: props.disabled || !props.edit.next,
      onClick: props.onClick,
      title: gettext("See next change")
    },
    _react2.default.createElement(
      "span",
      { className: "material-icon" },
      "chevron_right"
    )
  );
}

function GoLastBtn(props) {
  return _react2.default.createElement(
    _button2.default,
    {
      className: "btn-default btn-block btn-icon btn-sm",
      disabled: props.disabled || !props.edit.next,
      onClick: props.onClick,
      title: gettext("See previous change")
    },
    _react2.default.createElement(
      "span",
      { className: "material-icon" },
      "last_page"
    )
  );
}

function RevertBtn(props) {
  if (!props.canRevert) return null;

  return _react2.default.createElement(
    "div",
    { className: "col-sm-3 hidden-xs" },
    _react2.default.createElement(
      _button2.default,
      {
        className: "btn-default btn-sm btn-block",
        disabled: props.disabled,
        onClick: props.onClick,
        title: gettext("Revert post to state from before this edit.")
      },
      gettext("Revert")
    )
  );
}

function Label(props) {
  var user = null;
  if (props.edit.url.editor) {
    user = interpolate(USER_URL, {
      url: (0, _escapeHtml2.default)(props.edit.url.editor),
      user: (0, _escapeHtml2.default)(props.edit.editor_name)
    }, true);
  } else {
    user = interpolate(USER_SPAN, {
      user: (0, _escapeHtml2.default)(props.edit.editor_name)
    }, true);
  }

  var date = interpolate(DATE_ABBR, {
    absolute: (0, _escapeHtml2.default)(props.edit.edited_on.format("LLL")),
    relative: (0, _escapeHtml2.default)(props.edit.edited_on.fromNow())
  }, true);

  var message = interpolate((0, _escapeHtml2.default)(gettext("By %(edited_by)s %(edited_on)s.")), {
    edited_by: user,
    edited_on: date
  }, true);

  return _react2.default.createElement("p", { dangerouslySetInnerHTML: { __html: message } });
}

},{"../../utils/escape-html":383,"../button":8,"react":"react"}],121:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.hydrateEdit = hydrateEdit;

var _moment = require("moment");

var _moment2 = _interopRequireDefault(_moment);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function hydrateEdit(json) {
  return Object.assign({}, json, {
    edited_on: (0, _moment2.default)(json.edited_on)
  });
}

},{"moment":"moment"}],122:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (_ref) {
  var isReady = _ref.isReady,
      posts = _ref.posts,
      poster = _ref.poster;

  if (!isReady) {
    return _react2.default.createElement(_preview2.default, null);
  }

  return _react2.default.createElement(
    "ul",
    { className: "posts-list post-feed ui-ready" },
    posts.map(function (post) {
      return _react2.default.createElement(_post2.default, { key: post.id, post: post, poster: poster });
    })
  );
};

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _post = require("./post");

var _post2 = _interopRequireDefault(_post);

var _preview = require("./preview");

var _preview2 = _interopRequireDefault(_preview);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

},{"./post":125,"./preview":131,"react":"react"}],123:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (props) {
  if (props.post.content) {
    return _react2.default.createElement(Default, props);
  } else {
    return _react2.default.createElement(Invalid, props);
  }
};

exports.Default = Default;
exports.Invalid = Invalid;

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _misagoMarkup = require("../../misago-markup");

var _misagoMarkup2 = _interopRequireDefault(_misagoMarkup);

var _escapeHtml = require("../../../utils/escape-html");

var _escapeHtml2 = _interopRequireDefault(_escapeHtml);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function Default(props) {
  return _react2.default.createElement(
    "div",
    { className: "post-body" },
    _react2.default.createElement(_misagoMarkup2.default, { markup: props.post.content })
  );
}

function Invalid(props) {
  return _react2.default.createElement(
    "div",
    { className: "post-body post-body-invalid" },
    _react2.default.createElement(
      "p",
      { className: "lead" },
      gettext("This post's contents cannot be displayed.")
    ),
    _react2.default.createElement(
      "p",
      { className: "text-muted" },
      gettext("This error is caused by invalid post content manipulation.")
    )
  );
}

},{"../../../utils/escape-html":383,"../../misago-markup":60,"react":"react"}],124:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (_ref) {
  var post = _ref.post;
  var category = post.category,
      thread = post.thread;


  var tooltip = interpolate(gettext("posted %(posted_on)s"), {
    posted_on: post.posted_on.format("LL, LT")
  }, true);

  return _react2.default.createElement(
    "div",
    { className: "post-heading" },
    _react2.default.createElement(
      "a",
      { className: "btn btn-link item-title", href: thread.url },
      thread.title
    ),
    _react2.default.createElement(
      "a",
      { className: "btn btn-link post-category", href: category.url.index },
      category.name
    ),
    _react2.default.createElement(
      "a",
      {
        href: post.url.index,
        className: "btn btn-link posted-on",
        title: tooltip
      },
      post.posted_on.fromNow()
    )
  );
};

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

},{"react":"react"}],125:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (_ref) {
  var post = _ref.post,
      poster = _ref.poster;

  var user = poster || post.poster;

  var className = "post";
  if (user && user.rank.css_class) {
    className += " post-" + user.rank.css_class;
  }

  return _react2.default.createElement(
    "li",
    { className: className, id: "post-" + post.id },
    _react2.default.createElement(
      "div",
      { className: "panel panel-default panel-post" },
      _react2.default.createElement(
        "div",
        { className: "panel-body" },
        _react2.default.createElement(_postSide2.default, { post: post, poster: user }),
        _react2.default.createElement(_header2.default, { post: post }),
        _react2.default.createElement(_body2.default, { post: post })
      )
    )
  );
};

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _body = require("./body");

var _body2 = _interopRequireDefault(_body);

var _header = require("./header");

var _header2 = _interopRequireDefault(_header);

var _postSide = require("./post-side");

var _postSide2 = _interopRequireDefault(_postSide);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

},{"./body":123,"./header":124,"./post-side":128,"react":"react"}],126:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (_ref) {
  var post = _ref.post;

  return _react2.default.createElement(
    "div",
    { className: "post-side post-side-anonymous" },
    _react2.default.createElement(_button2.default, { post: post }),
    _react2.default.createElement(
      "div",
      { className: "media" },
      _react2.default.createElement(
        "div",
        { className: "media-left" },
        _react2.default.createElement(
          "span",
          null,
          _react2.default.createElement(_avatar2.default, { className: "poster-avatar", size: 50 })
        )
      ),
      _react2.default.createElement(
        "div",
        { className: "media-body" },
        _react2.default.createElement(
          "div",
          { className: "media-heading" },
          _react2.default.createElement(
            "span",
            { className: "item-title" },
            post.poster_name
          )
        ),
        _react2.default.createElement(
          "span",
          { className: "user-title user-title-anonymous" },
          gettext("Removed user")
        )
      )
    )
  );
};

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _avatar = require("../../../avatar");

var _avatar2 = _interopRequireDefault(_avatar);

var _button = require("./button");

var _button2 = _interopRequireDefault(_button);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

},{"../../../avatar":6,"./button":127,"react":"react"}],127:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (_ref) {
  var post = _ref.post;

  return _react2.default.createElement(
    "a",
    { className: "btn btn-default btn-icon pull-right", href: post.url.index },
    _react2.default.createElement(
      "span",
      { className: "btn-text-left hidden-xs" },
      gettext("See post")
    ),
    _react2.default.createElement(
      "span",
      { className: "material-icon" },
      "chevron_right"
    )
  );
};

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

},{"react":"react"}],128:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (_ref) {
  var post = _ref.post,
      poster = _ref.poster;

  if (poster.id) {
    return _react2.default.createElement(_registered2.default, { post: post, poster: poster });
  }

  return _react2.default.createElement(_anonymous2.default, { post: post });
};

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _anonymous = require("./anonymous");

var _anonymous2 = _interopRequireDefault(_anonymous);

var _registered = require("./registered");

var _registered2 = _interopRequireDefault(_registered);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

},{"./anonymous":126,"./registered":129,"react":"react"}],129:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (_ref) {
  var post = _ref.post,
      poster = _ref.poster;

  return _react2.default.createElement(
    "div",
    { className: "post-side post-side-registered" },
    _react2.default.createElement(_button2.default, { post: post }),
    _react2.default.createElement(
      "div",
      { className: "media" },
      _react2.default.createElement(
        "div",
        { className: "media-left" },
        _react2.default.createElement(
          "a",
          { href: poster.url },
          _react2.default.createElement(_avatar2.default, { className: "poster-avatar", size: 50, user: poster })
        )
      ),
      _react2.default.createElement(
        "div",
        { className: "media-body" },
        _react2.default.createElement(
          "div",
          { className: "media-heading" },
          _react2.default.createElement(
            "a",
            { className: "item-title", href: poster.url },
            poster.username
          )
        ),
        _react2.default.createElement(_userTitle2.default, { title: poster.title, rank: poster.rank })
      )
    )
  );
};

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _avatar = require("../../../avatar");

var _avatar2 = _interopRequireDefault(_avatar);

var _button = require("./button");

var _button2 = _interopRequireDefault(_button);

var _userTitle = require("./user-title");

var _userTitle2 = _interopRequireDefault(_userTitle);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

},{"../../../avatar":6,"./button":127,"./user-title":130,"react":"react"}],130:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (_ref) {
  var rank = _ref.rank,
      title = _ref.title;

  var userTitle = title || rank.title || rank.name;

  var className = "user-title";
  if (rank.css_class) {
    className += " user-title-" + rank.css_class;
  }

  if (rank.is_tab) {
    return _react2.default.createElement(
      "a",
      { className: className, href: rank.url },
      userTitle
    );
  }

  return _react2.default.createElement(
    "span",
    { className: className },
    userTitle
  );
};

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

},{"react":"react"}],131:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function () {
  return _react2.default.createElement(
    "ul",
    { className: "posts-list post-feed ui-preview" },
    _react2.default.createElement(
      "li",
      { className: "post" },
      _react2.default.createElement(
        "div",
        { className: "panel panel-default panel-post" },
        _react2.default.createElement(
          "div",
          { className: "panel-body" },
          _react2.default.createElement(
            "div",
            { className: "post-side post-side-anonymous" },
            _react2.default.createElement(
              "div",
              { className: "media" },
              _react2.default.createElement(
                "div",
                { className: "media-left" },
                _react2.default.createElement(
                  "span",
                  null,
                  _react2.default.createElement(_avatar2.default, { className: "poster-avatar", size: 50 })
                )
              ),
              _react2.default.createElement(
                "div",
                { className: "media-body" },
                _react2.default.createElement(
                  "div",
                  { className: "media-heading" },
                  _react2.default.createElement(
                    "span",
                    { className: "item-title" },
                    _react2.default.createElement(
                      "span",
                      {
                        className: "ui-preview-text",
                        style: { width: random.int(30, 200) + "px" }
                      },
                      "\xA0"
                    )
                  )
                ),
                _react2.default.createElement(
                  "span",
                  { className: "user-title user-title-anonymous" },
                  _react2.default.createElement(
                    "span",
                    {
                      className: "ui-preview-text",
                      style: { width: random.int(30, 200) + "px" }
                    },
                    "\xA0"
                  )
                )
              )
            )
          ),
          _react2.default.createElement(
            "div",
            { className: "post-heading" },
            _react2.default.createElement(
              "span",
              {
                className: "ui-preview-text",
                style: { width: random.int(30, 200) + "px" }
              },
              "\xA0"
            )
          ),
          _react2.default.createElement(
            "div",
            { className: "post-body" },
            _react2.default.createElement(
              "article",
              { className: "misago-markup" },
              _react2.default.createElement(
                "p",
                null,
                _react2.default.createElement(
                  "span",
                  {
                    className: "ui-preview-text",
                    style: { width: random.int(30, 200) + "px" }
                  },
                  "\xA0"
                ),
                "\xA0",
                _react2.default.createElement(
                  "span",
                  {
                    className: "ui-preview-text",
                    style: { width: random.int(30, 200) + "px" }
                  },
                  "\xA0"
                ),
                "\xA0",
                _react2.default.createElement(
                  "span",
                  {
                    className: "ui-preview-text",
                    style: { width: random.int(30, 200) + "px" }
                  },
                  "\xA0"
                )
              )
            )
          )
        )
      )
    )
  );
};

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _avatar = require("../avatar");

var _avatar2 = _interopRequireDefault(_avatar);

var _random = require("../../utils/random");

var random = _interopRequireWildcard(_random);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

},{"../../utils/random":388,"../avatar":6,"react":"react"}],132:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

exports.hydrateLike = hydrateLike;
exports.ModalDialog = ModalDialog;
exports.LikesList = LikesList;
exports.LikeDetails = LikeDetails;
exports.LikeDate = LikeDate;

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _moment = require("moment");

var _moment2 = _interopRequireDefault(_moment);

var _avatar = require("./avatar");

var _avatar2 = _interopRequireDefault(_avatar);

var _modalMessage = require("./modal-message");

var _modalMessage2 = _interopRequireDefault(_modalMessage);

var _modalLoader = require("./modal-loader");

var _modalLoader2 = _interopRequireDefault(_modalLoader);

var _ajax = require("../services/ajax");

var _ajax2 = _interopRequireDefault(_ajax);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_React$Component) {
  _inherits(_class, _React$Component);

  function _class(props) {
    _classCallCheck(this, _class);

    var _this = _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).call(this, props));

    _this.state = {
      isReady: false,

      error: null,
      likes: []
    };
    return _this;
  }

  _createClass(_class, [{
    key: "componentDidMount",
    value: function componentDidMount() {
      var _this2 = this;

      _ajax2.default.get(this.props.post.api.likes).then(function (data) {
        _this2.setState({
          isReady: true,
          likes: data.map(hydrateLike)
        });
      }, function (rejection) {
        _this2.setState({
          isReady: true,
          error: rejection.detail
        });
      });
    }
  }, {
    key: "render",
    value: function render() {
      if (this.state.error) {
        return _react2.default.createElement(
          ModalDialog,
          { className: "modal-message" },
          _react2.default.createElement(_modalMessage2.default, { message: this.state.error })
        );
      } else if (this.state.isReady) {
        if (this.state.likes.length) {
          return _react2.default.createElement(
            ModalDialog,
            { className: "modal-sm", likes: this.state.likes },
            _react2.default.createElement(LikesList, { likes: this.state.likes })
          );
        }

        return _react2.default.createElement(
          ModalDialog,
          { className: "modal-message" },
          _react2.default.createElement(_modalMessage2.default, { message: gettext("No users have liked this post.") })
        );
      }

      return _react2.default.createElement(
        ModalDialog,
        { className: "modal-sm" },
        _react2.default.createElement(_modalLoader2.default, null)
      );
    }
  }]);

  return _class;
}(_react2.default.Component);

exports.default = _class;
function hydrateLike(data) {
  return Object.assign({}, data, {
    liked_on: (0, _moment2.default)(data.liked_on)
  });
}

function ModalDialog(_ref) {
  var className = _ref.className,
      children = _ref.children,
      likes = _ref.likes;

  var title = gettext("Post Likes");
  if (likes) {
    var likesCount = likes.length;
    var message = ngettext("%(likes)s like", "%(likes)s likes", likesCount);

    title = interpolate(message, { likes: likesCount }, true);
  }

  return _react2.default.createElement(
    "div",
    { className: "modal-dialog " + (className || ""), role: "document" },
    _react2.default.createElement(
      "div",
      { className: "modal-content" },
      _react2.default.createElement(
        "div",
        { className: "modal-header" },
        _react2.default.createElement(
          "button",
          {
            "aria-label": gettext("Close"),
            className: "close",
            "data-dismiss": "modal",
            type: "button"
          },
          _react2.default.createElement(
            "span",
            { "aria-hidden": "true" },
            "\xD7"
          )
        ),
        _react2.default.createElement(
          "h4",
          { className: "modal-title" },
          title
        )
      ),
      children
    )
  );
}

function LikesList(props) {
  return _react2.default.createElement(
    "div",
    { className: "modal-body modal-post-likers" },
    _react2.default.createElement(
      "ul",
      { className: "media-list" },
      props.likes.map(function (like) {
        return _react2.default.createElement(LikeDetails, _extends({ key: like.id }, like));
      })
    )
  );
}

function LikeDetails(props) {
  if (props.url) {
    var user = {
      id: props.liker_id,
      avatars: props.avatars
    };

    return _react2.default.createElement(
      "li",
      { className: "media" },
      _react2.default.createElement(
        "div",
        { className: "media-left" },
        _react2.default.createElement(
          "a",
          { className: "user-avatar", href: props.url },
          _react2.default.createElement(_avatar2.default, { size: "50", user: user })
        )
      ),
      _react2.default.createElement(
        "div",
        { className: "media-body" },
        _react2.default.createElement(
          "a",
          { className: "item-title", href: props.url },
          props.username
        ),
        " ",
        _react2.default.createElement(LikeDate, { likedOn: props.liked_on })
      )
    );
  }

  return _react2.default.createElement(
    "li",
    { className: "media" },
    _react2.default.createElement(
      "div",
      { className: "media-left" },
      _react2.default.createElement(
        "span",
        { className: "user-avatar" },
        _react2.default.createElement(_avatar2.default, { size: "50" })
      )
    ),
    _react2.default.createElement(
      "div",
      { className: "media-body" },
      _react2.default.createElement(
        "strong",
        null,
        props.username
      ),
      " ",
      _react2.default.createElement(LikeDate, { likedOn: props.liked_on })
    )
  );
}

function LikeDate(props) {
  return _react2.default.createElement(
    "span",
    { className: "text-muted", title: props.likedOn.format("LLL") },
    props.likedOn.fromNow()
  );
}

},{"../services/ajax":365,"./avatar":6,"./modal-loader":61,"./modal-message":62,"moment":"moment","react":"react"}],133:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _editor = require("../editor");

var _editor2 = _interopRequireDefault(_editor);

var _form = require("../form");

var _form2 = _interopRequireDefault(_form);

var _container = require("./utils/container");

var _container2 = _interopRequireDefault(_container);

var _loader = require("./utils/loader");

var _loader2 = _interopRequireDefault(_loader);

var _message = require("./utils/message");

var _message2 = _interopRequireDefault(_message);

var _attachments = require("./utils/attachments");

var attachments = _interopRequireWildcard(_attachments);

var _validators = require("./utils/validators");

var _ajax = require("../../services/ajax");

var _ajax2 = _interopRequireDefault(_ajax);

var _posting = require("../../services/posting");

var _posting2 = _interopRequireDefault(_posting);

var _snackbar = require("../../services/snackbar");

var _snackbar2 = _interopRequireDefault(_snackbar);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_Form) {
  _inherits(_class, _Form);

  function _class(props) {
    _classCallCheck(this, _class);

    var _this = _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).call(this, props));

    _this.loadSuccess = function (data) {
      _this.setState({
        isReady: true,

        post: data.post,
        attachments: attachments.hydrate(data.attachments),
        protect: data.is_protected,

        canProtect: data.can_protect
      });
    };

    _this.loadError = function (rejection) {
      _this.setState({
        isErrored: rejection.detail
      });
    };

    _this.onCancel = function () {
      var cancel = confirm(gettext("Are you sure you want to discard changes?"));
      if (cancel) {
        _posting2.default.close();
      }
    };

    _this.onProtect = function () {
      _this.setState({
        protect: true
      });
    };

    _this.onUnprotect = function () {
      _this.setState({
        protect: false
      });
    };

    _this.onPostChange = function (event) {
      _this.changeValue("post", event.target.value);
    };

    _this.onAttachmentsChange = function (attachments) {
      _this.setState({
        attachments: attachments
      });
    };

    _this.state = {
      isReady: false,
      isLoading: false,
      isErrored: false,

      post: "",
      attachments: [],
      protect: false,

      canProtect: false,

      validators: {
        post: (0, _validators.getPostValidators)()
      },
      errors: {}
    };
    return _this;
  }

  _createClass(_class, [{
    key: "componentDidMount",
    value: function componentDidMount() {
      _ajax2.default.get(this.props.config).then(this.loadSuccess, this.loadError);
    }
  }, {
    key: "clean",
    value: function clean() {
      if (!this.state.post.trim().length) {
        _snackbar2.default.error(gettext("You have to enter a message."));
        return false;
      }

      var errors = this.validate();

      if (errors.post) {
        _snackbar2.default.error(errors.post[0]);
        return false;
      }

      return true;
    }
  }, {
    key: "send",
    value: function send() {
      return _ajax2.default.put(this.props.submit, {
        post: this.state.post,
        attachments: attachments.clean(this.state.attachments),
        protect: this.state.protect
      });
    }
  }, {
    key: "handleSuccess",
    value: function handleSuccess(success) {
      _snackbar2.default.success(gettext("Reply has been edited."));
      window.location = success.url.index;

      // keep form loading
      this.setState({
        isLoading: true
      });
    }
  }, {
    key: "handleError",
    value: function handleError(rejection) {
      if (rejection.status === 400) {
        var errors = [].concat(rejection.non_field_errors || [], rejection.category || [], rejection.title || [], rejection.post || [], rejection.attachments || []);

        _snackbar2.default.error(errors[0]);
      } else {
        _snackbar2.default.apiError(rejection);
      }
    }
  }, {
    key: "render",
    value: function render() {
      if (this.state.isReady) {
        return _react2.default.createElement(
          _container2.default,
          { className: "posting-form" },
          _react2.default.createElement(
            "form",
            { onSubmit: this.handleSubmit, method: "POST" },
            _react2.default.createElement(
              "div",
              { className: "row" },
              _react2.default.createElement(
                "div",
                { className: "col-md-12" },
                _react2.default.createElement(_editor2.default, {
                  attachments: this.state.attachments,
                  canProtect: this.state.canProtect,
                  loading: this.state.isLoading,
                  onAttachmentsChange: this.onAttachmentsChange,
                  onCancel: this.onCancel,
                  onChange: this.onPostChange,
                  onProtect: this.onProtect,
                  onUnprotect: this.onUnprotect,
                  protect: this.state.protect,
                  submitLabel: gettext("Edit reply"),
                  value: this.state.post
                })
              )
            )
          )
        );
      } else if (this.state.isErrored) {
        return _react2.default.createElement(_message2.default, { message: this.state.isErrored });
      } else {
        return _react2.default.createElement(_loader2.default, null);
      }
    }
  }]);

  return _class;
}(_form2.default);

exports.default = _class;

},{"../../services/ajax":365,"../../services/posting":375,"../../services/snackbar":376,"../editor":52,"../form":56,"./utils/attachments":138,"./utils/container":139,"./utils/loader":140,"./utils/message":141,"./utils/validators":144,"react":"react"}],134:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (props) {
  if (props.mode === "START") {
    return _react2.default.createElement(_start2.default, props);
  } else if (props.mode === "START_PRIVATE") {
    return _react2.default.createElement(_startPrivate2.default, props);
  } else if (props.mode === "REPLY") {
    return _react2.default.createElement(_reply2.default, props);
  } else if (props.mode === "EDIT") {
    return _react2.default.createElement(_edit2.default, props);
  } else {
    return null;
  }
};

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _start = require("./start");

var _start2 = _interopRequireDefault(_start);

var _startPrivate = require("./start-private");

var _startPrivate2 = _interopRequireDefault(_startPrivate);

var _reply = require("./reply");

var _reply2 = _interopRequireDefault(_reply);

var _edit = require("./edit");

var _edit2 = _interopRequireDefault(_edit);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

},{"./edit":133,"./reply":135,"./start":137,"./start-private":136,"react":"react"}],135:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _editor = require("../editor");

var _editor2 = _interopRequireDefault(_editor);

var _form = require("../form");

var _form2 = _interopRequireDefault(_form);

var _container = require("./utils/container");

var _container2 = _interopRequireDefault(_container);

var _loader = require("./utils/loader");

var _loader2 = _interopRequireDefault(_loader);

var _message = require("./utils/message");

var _message2 = _interopRequireDefault(_message);

var _attachments = require("./utils/attachments");

var attachments = _interopRequireWildcard(_attachments);

var _validators = require("./utils/validators");

var _ajax = require("../../services/ajax");

var _ajax2 = _interopRequireDefault(_ajax);

var _posting = require("../../services/posting");

var _posting2 = _interopRequireDefault(_posting);

var _snackbar = require("../../services/snackbar");

var _snackbar2 = _interopRequireDefault(_snackbar);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_Form) {
  _inherits(_class, _Form);

  function _class(props) {
    _classCallCheck(this, _class);

    var _this = _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).call(this, props));

    _this.loadSuccess = function (data) {
      _this.setState({
        isReady: true,

        post: data.post ? '[quote="@' + data.poster + '"]\n' + data.post + "\n[/quote]" : ""
      });
    };

    _this.loadError = function (rejection) {
      _this.setState({
        isErrored: rejection.detail
      });
    };

    _this.appendData = function (data) {
      var newPost = data.post ? '[quote="@' + data.poster + '"]\n' + data.post + "\n[/quote]\n\n" : "";

      _this.setState(function (prevState, props) {
        if (prevState.post.length > 0) {
          return {
            post: prevState.post + "\n\n" + newPost
          };
        }

        return {
          post: newPost
        };
      });
    };

    _this.onCancel = function () {
      var cancel = confirm(gettext("Are you sure you want to discard your reply?"));
      if (cancel) {
        _posting2.default.close();
      }
    };

    _this.onPostChange = function (event) {
      _this.changeValue("post", event.target.value);
    };

    _this.onAttachmentsChange = function (attachments) {
      _this.setState({
        attachments: attachments
      });
    };

    _this.state = {
      isReady: false,
      isLoading: false,
      isErrored: false,

      post: "",
      attachments: [],

      validators: {
        post: (0, _validators.getPostValidators)()
      },
      errors: {}
    };
    return _this;
  }

  _createClass(_class, [{
    key: "componentDidMount",
    value: function componentDidMount() {
      _ajax2.default.get(this.props.config, this.props.context || null).then(this.loadSuccess, this.loadError);
    }
  }, {
    key: "componentWillReceiveProps",
    value: function componentWillReceiveProps(nextProps) {
      var context = this.props.context;
      var newContext = nextProps.context;

      if (context && newContext && context.reply === newContext.reply) return;

      _ajax2.default.get(nextProps.config, nextProps.context || null).then(this.appendData, _snackbar2.default.apiError);
    }
  }, {
    key: "clean",
    value: function clean() {
      if (!this.state.post.trim().length) {
        _snackbar2.default.error(gettext("You have to enter a message."));
        return false;
      }

      var errors = this.validate();

      if (errors.post) {
        _snackbar2.default.error(errors.post[0]);
        return false;
      }

      return true;
    }
  }, {
    key: "send",
    value: function send() {
      return _ajax2.default.post(this.props.submit, {
        post: this.state.post,
        attachments: attachments.clean(this.state.attachments)
      });
    }
  }, {
    key: "handleSuccess",
    value: function handleSuccess(success) {
      _snackbar2.default.success(gettext("Your reply has been posted."));
      window.location = success.url.index;

      // keep form loading
      this.setState({
        isLoading: true
      });
    }
  }, {
    key: "handleError",
    value: function handleError(rejection) {
      if (rejection.status === 400) {
        var errors = [].concat(rejection.non_field_errors || [], rejection.post || [], rejection.attachments || []);

        _snackbar2.default.error(errors[0]);
      } else {
        _snackbar2.default.apiError(rejection);
      }
    }
  }, {
    key: "render",
    value: function render() {
      if (this.state.isReady) {
        return _react2.default.createElement(
          _container2.default,
          { className: "posting-form" },
          _react2.default.createElement(
            "form",
            { onSubmit: this.handleSubmit, method: "POST" },
            _react2.default.createElement(
              "div",
              { className: "row" },
              _react2.default.createElement(
                "div",
                { className: "col-md-12" },
                _react2.default.createElement(_editor2.default, {
                  attachments: this.state.attachments,
                  loading: this.state.isLoading,
                  onAttachmentsChange: this.onAttachmentsChange,
                  onCancel: this.onCancel,
                  onChange: this.onPostChange,
                  submitLabel: gettext("Post reply"),
                  value: this.state.post
                })
              )
            )
          )
        );
      } else if (this.state.isErrored) {
        return _react2.default.createElement(_message2.default, { message: this.state.isErrored });
      } else {
        return _react2.default.createElement(_loader2.default, null);
      }
    }
  }]);

  return _class;
}(_form2.default);

exports.default = _class;

},{"../../services/ajax":365,"../../services/posting":375,"../../services/snackbar":376,"../editor":52,"../form":56,"./utils/attachments":138,"./utils/container":139,"./utils/loader":140,"./utils/message":141,"./utils/validators":144,"react":"react"}],136:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _editor = require("../editor");

var _editor2 = _interopRequireDefault(_editor);

var _form = require("../form");

var _form2 = _interopRequireDefault(_form);

var _container = require("./utils/container");

var _container2 = _interopRequireDefault(_container);

var _message = require("./utils/message");

var _message2 = _interopRequireDefault(_message);

var _attachments = require("./utils/attachments");

var attachments = _interopRequireWildcard(_attachments);

var _usernames = require("./utils/usernames");

var _usernames2 = _interopRequireDefault(_usernames);

var _validators = require("./utils/validators");

var _ajax = require("../../services/ajax");

var _ajax2 = _interopRequireDefault(_ajax);

var _posting = require("../../services/posting");

var _posting2 = _interopRequireDefault(_posting);

var _snackbar = require("../../services/snackbar");

var _snackbar2 = _interopRequireDefault(_snackbar);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_Form) {
  _inherits(_class, _Form);

  function _class(props) {
    _classCallCheck(this, _class);

    var _this = _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).call(this, props));

    _this.onCancel = function () {
      var cancel = confirm(gettext("Are you sure you want to discard private thread?"));
      if (cancel) {
        _posting2.default.close();
      }
    };

    _this.onToChange = function (event) {
      _this.changeValue("to", event.target.value);
    };

    _this.onTitleChange = function (event) {
      _this.changeValue("title", event.target.value);
    };

    _this.onPostChange = function (event) {
      _this.changeValue("post", event.target.value);
    };

    _this.onAttachmentsChange = function (attachments) {
      _this.setState({
        attachments: attachments
      });
    };

    var to = (props.to || []).map(function (user) {
      return user.username;
    }).join(", ");

    _this.state = {
      isLoading: false,

      to: to,
      title: "",
      post: "",
      attachments: [],

      validators: {
        title: (0, _validators.getTitleValidators)(),
        post: (0, _validators.getPostValidators)()
      },
      errors: {}
    };
    return _this;
  }

  _createClass(_class, [{
    key: "clean",
    value: function clean() {
      if (!(0, _usernames2.default)(this.state.to).length) {
        _snackbar2.default.error(gettext("You have to enter at least one recipient."));
        return false;
      }

      if (!this.state.title.trim().length) {
        _snackbar2.default.error(gettext("You have to enter thread title."));
        return false;
      }

      if (!this.state.post.trim().length) {
        _snackbar2.default.error(gettext("You have to enter a message."));
        return false;
      }

      var errors = this.validate();

      if (errors.title) {
        _snackbar2.default.error(errors.title[0]);
        return false;
      }

      if (errors.post) {
        _snackbar2.default.error(errors.post[0]);
        return false;
      }

      return true;
    }
  }, {
    key: "send",
    value: function send() {
      return _ajax2.default.post(this.props.submit, {
        to: (0, _usernames2.default)(this.state.to),
        title: this.state.title,
        post: this.state.post,
        attachments: attachments.clean(this.state.attachments)
      });
    }
  }, {
    key: "handleSuccess",
    value: function handleSuccess(success) {
      _snackbar2.default.success(gettext("Your thread has been posted."));
      window.location = success.url;

      // keep form loading
      this.setState({
        isLoading: true
      });
    }
  }, {
    key: "handleError",
    value: function handleError(rejection) {
      if (rejection.status === 400) {
        var errors = [].concat(rejection.non_field_errors || [], rejection.to || [], rejection.title || [], rejection.post || [], rejection.attachments || []);

        _snackbar2.default.error(errors[0]);
      } else {
        _snackbar2.default.apiError(rejection);
      }
    }
  }, {
    key: "render",
    value: function render() {
      return _react2.default.createElement(
        _container2.default,
        { className: "posting-form", withFirstRow: true },
        _react2.default.createElement(
          "form",
          { onSubmit: this.handleSubmit },
          _react2.default.createElement(
            "div",
            { className: "row first-row" },
            _react2.default.createElement(
              "div",
              { className: "col-xs-12" },
              _react2.default.createElement("input", {
                className: "form-control",
                disabled: this.state.isLoading,
                onChange: this.onToChange,
                placeholder: gettext("Comma separated list of user names, eg.: Danny, Lisa"),
                type: "text",
                value: this.state.to
              })
            )
          ),
          _react2.default.createElement(
            "div",
            { className: "row first-row" },
            _react2.default.createElement(
              "div",
              { className: "col-xs-12" },
              _react2.default.createElement("input", {
                className: "form-control",
                disabled: this.state.isLoading,
                onChange: this.onTitleChange,
                placeholder: gettext("Thread title"),
                type: "text",
                value: this.state.title
              })
            )
          ),
          _react2.default.createElement(
            "div",
            { className: "row" },
            _react2.default.createElement(
              "div",
              { className: "col-xs-12" },
              _react2.default.createElement(_editor2.default, {
                attachments: this.state.attachments,
                loading: this.state.isLoading,
                onAttachmentsChange: this.onAttachmentsChange,
                onCancel: this.onCancel,
                onChange: this.onPostChange,
                submitLabel: gettext("Post thread"),
                value: this.state.post
              })
            )
          )
        )
      );
    }
  }]);

  return _class;
}(_form2.default);

exports.default = _class;

},{"../../services/ajax":365,"../../services/posting":375,"../../services/snackbar":376,"../editor":52,"../form":56,"./utils/attachments":138,"./utils/container":139,"./utils/message":141,"./utils/usernames":143,"./utils/validators":144,"react":"react"}],137:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _categorySelect = require("../category-select");

var _categorySelect2 = _interopRequireDefault(_categorySelect);

var _editor = require("../editor");

var _editor2 = _interopRequireDefault(_editor);

var _form = require("../form");

var _form2 = _interopRequireDefault(_form);

var _container = require("./utils/container");

var _container2 = _interopRequireDefault(_container);

var _loader = require("./utils/loader");

var _loader2 = _interopRequireDefault(_loader);

var _message = require("./utils/message");

var _message2 = _interopRequireDefault(_message);

var _options = require("./utils/options");

var _options2 = _interopRequireDefault(_options);

var _attachments = require("./utils/attachments");

var attachments = _interopRequireWildcard(_attachments);

var _validators = require("./utils/validators");

var _ajax = require("../../services/ajax");

var _ajax2 = _interopRequireDefault(_ajax);

var _posting = require("../../services/posting");

var _posting2 = _interopRequireDefault(_posting);

var _snackbar = require("../../services/snackbar");

var _snackbar2 = _interopRequireDefault(_snackbar);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_Form) {
  _inherits(_class, _Form);

  function _class(props) {
    _classCallCheck(this, _class);

    var _this = _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).call(this, props));

    _this.loadSuccess = function (data) {
      var category = null;
      var showOptions = false;
      var categoryOptions = null;

      // hydrate categories, extract posting options
      var categories = data.map(function (item) {
        // pick first category that allows posting and if it may, override it with initial one
        if (item.post !== false && (!category || item.id == _this.state.category)) {
          category = item.id;
          categoryOptions = item.post;
        }

        if (item.post && (item.post.close || item.post.hide || item.post.pin)) {
          showOptions = true;
        }

        return Object.assign(item, {
          disabled: item.post === false,
          label: item.name,
          value: item.id
        });
      });

      _this.setState({
        isReady: true,
        showOptions: showOptions,

        categories: categories,
        category: category,
        categoryOptions: categoryOptions
      });
    };

    _this.loadError = function (rejection) {
      _this.setState({
        isErrored: rejection.detail
      });
    };

    _this.onCancel = function () {
      var cancel = confirm(gettext("Are you sure you want to discard thread?"));
      if (cancel) {
        _posting2.default.close();
      }
    };

    _this.onTitleChange = function (event) {
      _this.changeValue("title", event.target.value);
    };

    _this.onCategoryChange = function (event) {
      var category = _this.state.categories.find(function (item) {
        return event.target.value == item.value;
      });

      // if selected pin is greater than allowed, reduce it
      var pin = _this.state.pin;
      if (category.post.pin && category.post.pin < pin) {
        pin = category.post.pin;
      }

      _this.setState({
        category: category.id,
        categoryOptions: category.post,

        pin: pin
      });
    };

    _this.onPostChange = function (event) {
      _this.changeValue("post", event.target.value);
    };

    _this.onAttachmentsChange = function (attachments) {
      _this.setState({
        attachments: attachments
      });
    };

    _this.onClose = function () {
      _this.changeValue("close", true);
    };

    _this.onOpen = function () {
      _this.changeValue("close", false);
    };

    _this.onPinGlobally = function () {
      _this.changeValue("pin", 2);
    };

    _this.onPinLocally = function () {
      _this.changeValue("pin", 1);
    };

    _this.onUnpin = function () {
      _this.changeValue("pin", 0);
    };

    _this.onHide = function () {
      _this.changeValue("hide", true);
    };

    _this.onUnhide = function () {
      _this.changeValue("hide", false);
    };

    _this.state = {
      isReady: false,
      isLoading: false,
      isErrored: false,

      showOptions: false,
      categoryOptions: null,

      title: "",
      category: props.category || null,
      categories: [],
      post: "",
      attachments: [],
      close: false,
      hide: false,
      pin: 0,

      validators: {
        title: (0, _validators.getTitleValidators)(),
        post: (0, _validators.getPostValidators)()
      },
      errors: {}
    };
    return _this;
  }

  _createClass(_class, [{
    key: "componentDidMount",
    value: function componentDidMount() {
      _ajax2.default.get(this.props.config).then(this.loadSuccess, this.loadError);
    }
  }, {
    key: "clean",
    value: function clean() {
      if (!this.state.title.trim().length) {
        _snackbar2.default.error(gettext("You have to enter thread title."));
        return false;
      }

      if (!this.state.post.trim().length) {
        _snackbar2.default.error(gettext("You have to enter a message."));
        return false;
      }

      var errors = this.validate();

      if (errors.title) {
        _snackbar2.default.error(errors.title[0]);
        return false;
      }

      if (errors.post) {
        _snackbar2.default.error(errors.post[0]);
        return false;
      }

      return true;
    }
  }, {
    key: "send",
    value: function send() {
      return _ajax2.default.post(this.props.submit, {
        title: this.state.title,
        category: this.state.category,
        post: this.state.post,
        attachments: attachments.clean(this.state.attachments),
        close: this.state.close,
        hide: this.state.hide,
        pin: this.state.pin
      });
    }
  }, {
    key: "handleSuccess",
    value: function handleSuccess(success) {
      _snackbar2.default.success(gettext("Your thread has been posted."));
      window.location = success.url;

      // keep form loading
      this.setState({
        isLoading: true
      });
    }
  }, {
    key: "handleError",
    value: function handleError(rejection) {
      if (rejection.status === 400) {
        var errors = [].concat(rejection.non_field_errors || [], rejection.category || [], rejection.title || [], rejection.post || [], rejection.attachments || []);

        _snackbar2.default.error(errors[0]);
      } else {
        _snackbar2.default.apiError(rejection);
      }
    }
  }, {
    key: "render",
    value: function render() {
      if (this.state.isErrored) {
        return _react2.default.createElement(_message2.default, { message: this.state.isErrored });
      }

      if (!this.state.isReady) {
        return _react2.default.createElement(_loader2.default, null);
      }

      var columns = 0;
      if (this.state.categoryOptions.close) columns += 1;
      if (this.state.categoryOptions.hide) columns += 1;
      if (this.state.categoryOptions.pin) columns += 1;

      var titleStyle = null;

      if (columns === 1) {
        titleStyle = "col-sm-6";
      } else {
        titleStyle = "col-sm-8";
      }

      if (columns === 3) {
        titleStyle += " col-md-6";
      } else if (columns) {
        titleStyle += " col-md-7";
      } else {
        titleStyle += " col-md-9";
      }

      return _react2.default.createElement(
        _container2.default,
        { className: "posting-form", withFirstRow: true },
        _react2.default.createElement(
          "form",
          { onSubmit: this.handleSubmit },
          _react2.default.createElement(
            "div",
            { className: "row first-row" },
            _react2.default.createElement(
              "div",
              { className: titleStyle },
              _react2.default.createElement("input", {
                className: "form-control",
                disabled: this.state.isLoading,
                onChange: this.onTitleChange,
                placeholder: gettext("Thread title"),
                type: "text",
                value: this.state.title
              })
            ),
            _react2.default.createElement(
              "div",
              { className: "col-xs-12 col-sm-4 col-md-3 xs-margin-top" },
              _react2.default.createElement(_categorySelect2.default, {
                choices: this.state.categories,
                disabled: this.state.isLoading,
                onChange: this.onCategoryChange,
                value: this.state.category
              })
            ),
            _react2.default.createElement(_options2.default, {
              close: this.state.close,
              columns: columns,
              disabled: this.state.isLoading,
              hide: this.state.hide,
              onClose: this.onClose,
              onHide: this.onHide,
              onOpen: this.onOpen,
              onPinGlobally: this.onPinGlobally,
              onPinLocally: this.onPinLocally,
              onUnhide: this.onUnhide,
              onUnpin: this.onUnpin,
              options: this.state.categoryOptions,
              pin: this.state.pin,
              showOptions: this.state.showOptions
            })
          ),
          _react2.default.createElement(
            "div",
            { className: "row" },
            _react2.default.createElement(
              "div",
              { className: "col-md-12" },
              _react2.default.createElement(_editor2.default, {
                attachments: this.state.attachments,
                loading: this.state.isLoading,
                onAttachmentsChange: this.onAttachmentsChange,
                onCancel: this.onCancel,
                onChange: this.onPostChange,
                submitLabel: gettext("Post thread"),
                value: this.state.post
              })
            )
          )
        )
      );
    }
  }]);

  return _class;
}(_form2.default);

exports.default = _class;

},{"../../services/ajax":365,"../../services/posting":375,"../../services/snackbar":376,"../category-select":21,"../editor":52,"../form":56,"./utils/attachments":138,"./utils/container":139,"./utils/loader":140,"./utils/message":141,"./utils/options":142,"./utils/validators":144,"react":"react"}],138:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.clean = clean;
exports.hydrate = hydrate;

var _moment = require("moment");

var _moment2 = _interopRequireDefault(_moment);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function clean(attachments) {
  var completedAttachments = attachments.filter(function (attachment) {
    return attachment.id && !attachment.isRemoved;
  });

  return completedAttachments.map(function (a) {
    return a.id;
  });
}

function hydrate(attachments) {
  return attachments.map(function (attachment) {
    return Object.assign({}, attachment, {
      uploaded_on: (0, _moment2.default)(attachment.uploaded_on)
    });
  });
}

},{"moment":"moment"}],139:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (props) {
  return _react2.default.createElement(
    "div",
    { className: props.className },
    _react2.default.createElement(
      "div",
      { className: "container" },
      props.children
    )
  );
};

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

},{"react":"react"}],140:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (props) {
  return _react2.default.createElement(
    _container2.default,
    { className: "posting-loader" },
    _react2.default.createElement(_loader2.default, null)
  );
};

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _container = require("./container");

var _container2 = _interopRequireDefault(_container);

var _loader = require("../../loader");

var _loader2 = _interopRequireDefault(_loader);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

},{"../../loader":58,"./container":139,"react":"react"}],141:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (props) {
  return _react2.default.createElement(
    _container2.default,
    { className: "posting-message" },
    _react2.default.createElement(
      "div",
      { className: "message-body" },
      _react2.default.createElement(
        "p",
        null,
        _react2.default.createElement(
          "span",
          { className: "material-icon" },
          "error_outline"
        ),
        props.message
      ),
      _react2.default.createElement(
        "button",
        {
          type: "button",
          className: "btn btn-default",
          onClick: _posting2.default.close
        },
        gettext("Dismiss")
      )
    )
  );
};

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _container = require("./container");

var _container2 = _interopRequireDefault(_container);

var _posting = require("../../../services/posting");

var _posting2 = _interopRequireDefault(_posting);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

},{"../../../services/posting":375,"./container":139,"react":"react"}],142:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (props) {
  if (!props.showOptions) return null;

  var columns = props.columns;


  var className = "col-xs-12 xs-margin-top";

  if (columns === 1) {
    className += " col-sm-2";
  } else {
    className += " sm-margin-top";
  }

  if (columns === 3) {
    className += " col-md-3";
  } else {
    className += " col-md-2";
  }
  className += " posting-options";

  var columnClassName = "col-xs-" + 12 / columns;

  var textClassName = "btn-text";
  if (columns === 3) {
    textClassName += " visible-sm-inline-block";
  } else if (columns === 2) {
    textClassName += " hidden-md hidden-lg";
  } else {
    textClassName += " hidden-sm";
  }

  return _react2.default.createElement(
    "div",
    { className: className },
    _react2.default.createElement(
      "div",
      { className: "row" },
      _react2.default.createElement(PinOptions, {
        className: columnClassName,
        disabled: props.disabled,
        onPinGlobally: props.onPinGlobally,
        onPinLocally: props.onPinLocally,
        onUnpin: props.onUnpin,
        pin: props.pin,
        show: props.options.pin,
        textClassName: textClassName
      }),
      _react2.default.createElement(HideOptions, {
        className: columnClassName,
        disabled: props.disabled,
        hide: props.hide,
        onHide: props.onHide,
        onUnhide: props.onUnhide,
        show: props.options.hide,
        textClassName: textClassName
      }),
      _react2.default.createElement(CloseOptions, {
        className: columnClassName,
        close: props.close,
        disabled: props.disabled,
        onClose: props.onClose,
        onOpen: props.onOpen,
        show: props.options.close,
        textClassName: textClassName
      })
    )
  );
};

exports.CloseOptions = CloseOptions;
exports.HideOptions = HideOptions;
exports.PinOptions = PinOptions;

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function CloseOptions(props) {
  if (!props.show) return null;

  var label = props.close ? gettext("Closed") : gettext("Open");

  return _react2.default.createElement(
    "div",
    { className: props.className },
    _react2.default.createElement(
      "button",
      {
        className: "btn btn-default btn-block",
        disabled: props.disabled,
        onClick: props.close ? props.onOpen : props.onClose,
        title: label,
        type: "button"
      },
      _react2.default.createElement(
        "span",
        { className: "material-icon" },
        props.close ? "lock" : "lock_outline"
      ),
      _react2.default.createElement(
        "span",
        { className: props.textClassName },
        label
      )
    )
  );
}

function HideOptions(props) {
  if (!props.show) return null;

  var label = props.hide ? gettext("Hidden") : gettext("Not hidden");

  return _react2.default.createElement(
    "div",
    { className: props.className },
    _react2.default.createElement(
      "button",
      {
        className: "btn btn-default btn-block",
        disabled: props.disabled,
        onClick: props.hide ? props.onUnhide : props.onHide,
        title: label,
        type: "button"
      },
      _react2.default.createElement(
        "span",
        { className: "material-icon" },
        props.hide ? "visibility_off" : "visibility"
      ),
      _react2.default.createElement(
        "span",
        { className: props.textClassName },
        label
      )
    )
  );
}

function PinOptions(props) {
  if (!props.show) return null;

  var icon = null;
  var onClick = null;
  var label = null;

  switch (props.pin) {
    case 0:
      icon = "radio_button_unchecked";
      onClick = props.onPinLocally;
      label = gettext("Unpinned");
      break;

    case 1:
      icon = "bookmark_outline";
      onClick = props.onPinGlobally;
      label = gettext("Pinned locally");

      if (props.show == 2) {
        onClick = props.onPinGlobally;
      } else {
        onClick = props.onUnpin;
      }

      break;

    case 2:
      icon = "bookmark";
      onClick = props.onUnpin;
      label = gettext("Pinned globally");
      break;
  }

  return _react2.default.createElement(
    "div",
    { className: props.className },
    _react2.default.createElement(
      "button",
      {
        className: "btn btn-default btn-block",
        disabled: props.disabled,
        onClick: onClick,
        title: label,
        type: "button"
      },
      _react2.default.createElement(
        "span",
        { className: "material-icon" },
        icon
      ),
      _react2.default.createElement(
        "span",
        { className: props.textClassName },
        label
      )
    )
  );
}

},{"react":"react"}],143:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (usernames) {
  var normalisedNames = usernames.split(",").map(function (i) {
    return i.trim().toLowerCase();
  });
  var removedBlanks = normalisedNames.filter(function (i) {
    return i.length > 0;
  });
  var removedDuplicates = removedBlanks.filter(function (name, pos) {
    return removedBlanks.indexOf(name) == pos;
  });

  return removedDuplicates;
};

},{}],144:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getTitleValidators = getTitleValidators;
exports.getPostValidators = getPostValidators;
exports.getTitleLengthMin = getTitleLengthMin;
exports.getTitleLengthMax = getTitleLengthMax;
exports.validatePostLengthMin = validatePostLengthMin;
exports.validatePostLengthMax = validatePostLengthMax;

var _validators = require("../../../utils/validators");

var _ = require("../../..");

var _2 = _interopRequireDefault(_);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function getTitleValidators() {
  return [getTitleLengthMin(), getTitleLengthMax()];
}

function getPostValidators() {
  if (_2.default.get("SETTINGS").post_length_max) {
    return [validatePostLengthMin(), validatePostLengthMax()];
  } else {
    return [validatePostLengthMin()];
  }
}

function getTitleLengthMin() {
  return (0, _validators.minLength)(_2.default.get("SETTINGS").thread_title_length_min, function (limitValue, length) {
    var message = ngettext("Thread title should be at least %(limit_value)s character long (it has %(show_value)s).", "Thread title should be at least %(limit_value)s characters long (it has %(show_value)s).", limitValue);

    return interpolate(message, {
      limit_value: limitValue,
      show_value: length
    }, true);
  });
}

function getTitleLengthMax() {
  return (0, _validators.maxLength)(_2.default.get("SETTINGS").thread_title_length_max, function (limitValue, length) {
    var message = ngettext("Thread title cannot be longer than %(limit_value)s character (it has %(show_value)s).", "Thread title cannot be longer than %(limit_value)s characters (it has %(show_value)s).", limitValue);

    return interpolate(message, {
      limit_value: limitValue,
      show_value: length
    }, true);
  });
}

function validatePostLengthMin() {
  return (0, _validators.minLength)(_2.default.get("SETTINGS").post_length_min, function (limitValue, length) {
    var message = ngettext("Posted message should be at least %(limit_value)s character long (it has %(show_value)s).", "Posted message should be at least %(limit_value)s characters long (it has %(show_value)s).", limitValue);

    return interpolate(message, {
      limit_value: limitValue,
      show_value: length
    }, true);
  });
}

function validatePostLengthMax() {
  return (0, _validators.maxLength)(_2.default.get("SETTINGS").post_length_max || 1000000, function (limitValue, length) {
    var message = ngettext("Posted message cannot be longer than %(limit_value)s character (it has %(show_value)s).", "Posted message cannot be longer than %(limit_value)s characters (it has %(show_value)s).", limitValue);

    return interpolate(message, {
      limit_value: limitValue,
      show_value: length
    }, true);
  });
}

},{"../../..":302,"../../../utils/validators":393}],145:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Delete = exports.Unhide = exports.Hide = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

exports.default = function (props) {
  if (isVisible(props.post.acl)) {
    return _react2.default.createElement(
      "li",
      { className: "event-controls" },
      _react2.default.createElement(Hide, props),
      _react2.default.createElement(Unhide, props),
      _react2.default.createElement(Delete, props)
    );
  } else {
    return null;
  }
};

exports.isVisible = isVisible;

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _moment = require("moment");

var _moment2 = _interopRequireDefault(_moment);

var _post = require("../../../reducers/post");

var post = _interopRequireWildcard(_post);

var _ajax = require("../../../services/ajax");

var _ajax2 = _interopRequireDefault(_ajax);

var _snackbar = require("../../../services/snackbar");

var _snackbar2 = _interopRequireDefault(_snackbar);

var _store = require("../../../services/store");

var _store2 = _interopRequireDefault(_store);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

function isVisible(acl) {
  return acl.can_hide;
}

var Hide = exports.Hide = function (_React$Component) {
  _inherits(Hide, _React$Component);

  function Hide() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, Hide);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = Hide.__proto__ || Object.getPrototypeOf(Hide)).call.apply(_ref, [this].concat(args))), _this), _this.onClick = function () {
      _store2.default.dispatch(post.patch(_this.props.post, {
        is_hidden: true,
        hidden_on: (0, _moment2.default)(),
        hidden_by_name: _this.props.user.username,
        url: Object.assign(_this.props.post.url, {
          hidden_by: _this.props.user.url
        })
      }));

      var op = { op: "replace", path: "is-hidden", value: true };

      _ajax2.default.patch(_this.props.post.api.index, [op]).then(function (patch) {
        _store2.default.dispatch(post.patch(_this.props.post, patch));
      }, function (rejection) {
        if (rejection.status === 400) {
          _snackbar2.default.error(rejection.detail[0]);
        } else {
          _snackbar2.default.apiError(rejection);
        }

        _store2.default.dispatch(post.patch(_this.props.post, {
          is_hidden: false
        }));
      });
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(Hide, [{
    key: "render",
    value: function render() {
      if (!this.props.post.is_hidden) {
        return _react2.default.createElement(
          "button",
          { type: "button", className: "btn btn-link", onClick: this.onClick },
          gettext("Hide")
        );
      } else {
        return null;
      }
    }
  }]);

  return Hide;
}(_react2.default.Component);

var Unhide = exports.Unhide = function (_React$Component2) {
  _inherits(Unhide, _React$Component2);

  function Unhide() {
    var _ref2;

    var _temp2, _this2, _ret2;

    _classCallCheck(this, Unhide);

    for (var _len2 = arguments.length, args = Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
      args[_key2] = arguments[_key2];
    }

    return _ret2 = (_temp2 = (_this2 = _possibleConstructorReturn(this, (_ref2 = Unhide.__proto__ || Object.getPrototypeOf(Unhide)).call.apply(_ref2, [this].concat(args))), _this2), _this2.onClick = function () {
      _store2.default.dispatch(post.patch(_this2.props.post, {
        is_hidden: false
      }));

      var op = { op: "replace", path: "is-hidden", value: false };

      _ajax2.default.patch(_this2.props.post.api.index, [op]).then(function (patch) {
        _store2.default.dispatch(post.patch(_this2.props.post, patch));
      }, function (rejection) {
        if (rejection.status === 400) {
          _snackbar2.default.error(rejection.detail[0]);
        } else {
          _snackbar2.default.apiError(rejection);
        }

        _store2.default.dispatch(post.patch(_this2.props.post, {
          is_hidden: true
        }));
      });
    }, _temp2), _possibleConstructorReturn(_this2, _ret2);
  }

  _createClass(Unhide, [{
    key: "render",
    value: function render() {
      if (this.props.post.is_hidden) {
        return _react2.default.createElement(
          "button",
          { type: "button", className: "btn btn-link", onClick: this.onClick },
          gettext("Unhide")
        );
      } else {
        return null;
      }
    }
  }]);

  return Unhide;
}(_react2.default.Component);

var Delete = exports.Delete = function (_React$Component3) {
  _inherits(Delete, _React$Component3);

  function Delete() {
    var _ref3;

    var _temp3, _this3, _ret3;

    _classCallCheck(this, Delete);

    for (var _len3 = arguments.length, args = Array(_len3), _key3 = 0; _key3 < _len3; _key3++) {
      args[_key3] = arguments[_key3];
    }

    return _ret3 = (_temp3 = (_this3 = _possibleConstructorReturn(this, (_ref3 = Delete.__proto__ || Object.getPrototypeOf(Delete)).call.apply(_ref3, [this].concat(args))), _this3), _this3.onClick = function () {
      var decision = confirm(gettext("Are you sure you wish to delete this event? This action is not reversible!"));
      if (decision) {
        _this3.delete();
      }
    }, _this3.delete = function () {
      _store2.default.dispatch(post.patch(_this3.props.post, {
        isDeleted: true
      }));

      _ajax2.default.delete(_this3.props.post.api.index).then(function () {
        _snackbar2.default.success(gettext("Event has been deleted."));
      }, function (rejection) {
        if (rejection.status === 400) {
          _snackbar2.default.error(rejection.detail[0]);
        } else {
          _snackbar2.default.apiError(rejection);
        }

        _store2.default.dispatch(post.patch(_this3.props.post, {
          isDeleted: false
        }));
      });
    }, _temp3), _possibleConstructorReturn(_this3, _ret3);
  }

  _createClass(Delete, [{
    key: "render",
    value: function render() {
      return _react2.default.createElement(
        "button",
        { type: "button", className: "btn btn-link", onClick: this.onClick },
        gettext("Delete")
      );
    }
  }]);

  return Delete;
}(_react2.default.Component);

},{"../../../reducers/post":353,"../../../services/ajax":365,"../../../services/snackbar":376,"../../../services/store":377,"moment":"moment","react":"react"}],146:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (props) {
  return _react2.default.createElement(
    "div",
    { className: "post-avatar" },
    _react2.default.createElement(
      "span",
      { className: "material-icon" },
      ICON[props.post.event_type]
    )
  );
};

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var ICON = {
  changed_title: "edit",

  pinned_globally: "bookmark",
  pinned_locally: "bookmark_border",
  unpinned: "panorama_fish_eye",

  moved: "arrow_forward",
  merged: "call_merge",

  approved: "done",

  opened: "lock_open",
  closed: "lock_outline",

  unhid: "visibility",
  hid: "visibility_off",

  changed_owner: "grade",
  tookover: "grade",

  added_participant: "person_add",

  owner_left: "person_outline",
  participant_left: "person_outline",
  removed_participant: "remove_circle_outline"
};

},{"react":"react"}],147:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (props) {
  var className = "event";
  if (props.post.isDeleted) {
    className = "hide";
  } else if (props.post.is_hidden) {
    className = "event post-hidden";
  }

  return _react2.default.createElement(
    "li",
    { id: "post-" + props.post.id, className: className },
    _react2.default.createElement(_unreadLabel2.default, { post: props.post }),
    _react2.default.createElement(
      "div",
      { className: "row" },
      _react2.default.createElement(
        "div",
        { className: "col-xs-2 col-sm-3 text-right" },
        _react2.default.createElement(_icon2.default, props)
      ),
      _react2.default.createElement(
        "div",
        { className: "col-xs-10 col-sm-9 text-left" },
        _react2.default.createElement(
          _waypoint2.default,
          { post: props.post },
          _react2.default.createElement(_message2.default, props),
          _react2.default.createElement(_info2.default, props)
        )
      )
    )
  );
};

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _icon = require("./icon");

var _icon2 = _interopRequireDefault(_icon);

var _info = require("./info");

var _info2 = _interopRequireDefault(_info);

var _message = require("./message");

var _message2 = _interopRequireDefault(_message);

var _unreadLabel = require("./unread-label");

var _unreadLabel2 = _interopRequireDefault(_unreadLabel);

var _waypoint = require("../waypoint");

var _waypoint2 = _interopRequireDefault(_waypoint);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

},{"../waypoint":173,"./icon":146,"./info":148,"./message":149,"./unread-label":150,"react":"react"}],148:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (props) {
  return _react2.default.createElement(
    "ul",
    { className: "list-inline event-info" },
    _react2.default.createElement(Hidden, props),
    _react2.default.createElement(Poster, props),
    _react2.default.createElement(_controls2.default, props)
  );
};

exports.Hidden = Hidden;
exports.Poster = Poster;

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _escapeHtml = require("../../../utils/escape-html");

var _escapeHtml2 = _interopRequireDefault(_escapeHtml);

var _controls = require("./controls");

var _controls2 = _interopRequireDefault(_controls);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var DATE_ABBR = '<abbr title="%(absolute)s">%(relative)s</abbr>';
var DATE_URL = '<a href="%(url)s" title="%(absolute)s">%(relative)s</a>';
var USER_SPAN = '<span class="item-title">%(user)s</span>';
var USER_URL = '<a href="%(url)s" class="item-title">%(user)s</a>';

function Hidden(props) {
  if (props.post.is_hidden) {
    var user = null;
    if (props.post.url.hidden_by) {
      user = interpolate(USER_URL, {
        url: (0, _escapeHtml2.default)(props.post.url.hidden_by),
        user: (0, _escapeHtml2.default)(props.post.hidden_by_name)
      }, true);
    } else {
      user = interpolate(USER_SPAN, {
        user: (0, _escapeHtml2.default)(props.post.hidden_by_name)
      }, true);
    }

    var date = interpolate(DATE_ABBR, {
      absolute: (0, _escapeHtml2.default)(props.post.hidden_on.format("LLL")),
      relative: (0, _escapeHtml2.default)(props.post.hidden_on.fromNow())
    }, true);

    var message = interpolate((0, _escapeHtml2.default)(gettext("Hidden by %(event_by)s %(event_on)s.")), {
      event_by: user,
      event_on: date
    }, true);

    return _react2.default.createElement("li", {
      className: "event-hidden-message",
      dangerouslySetInnerHTML: { __html: message }
    });
  } else {
    return null;
  }
}

function Poster(props) {
  var user = null;
  if (props.post.poster) {
    user = interpolate(USER_URL, {
      url: (0, _escapeHtml2.default)(props.post.poster.url),
      user: (0, _escapeHtml2.default)(props.post.poster_name)
    }, true);
  } else {
    user = interpolate(USER_SPAN, {
      user: (0, _escapeHtml2.default)(props.post.poster_name)
    }, true);
  }

  var date = interpolate(DATE_URL, {
    url: (0, _escapeHtml2.default)(props.post.url.index),
    absolute: (0, _escapeHtml2.default)(props.post.posted_on.format("LLL")),
    relative: (0, _escapeHtml2.default)(props.post.posted_on.fromNow())
  }, true);

  var message = interpolate((0, _escapeHtml2.default)(gettext("By %(event_by)s %(event_on)s.")), {
    event_by: user,
    event_on: date
  }, true);

  return _react2.default.createElement("li", {
    className: "event-posters",
    dangerouslySetInnerHTML: { __html: message }
  });
}

},{"../../../utils/escape-html":383,"./controls":145,"react":"react"}],149:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (props) {
  if (MESSAGE[props.post.event_type]) {
    return _react2.default.createElement(
      "p",
      { className: "event-message" },
      MESSAGE[props.post.event_type]
    );
  } else if (props.post.event_type === "changed_title") {
    return _react2.default.createElement(ChangedTitle, props);
  } else if (props.post.event_type === "moved") {
    return _react2.default.createElement(Moved, props);
  } else if (props.post.event_type === "merged") {
    return _react2.default.createElement(Merged, props);
  } else if (props.post.event_type === "changed_owner") {
    return _react2.default.createElement(ChangedOwner, props);
  } else if (props.post.event_type === "added_participant") {
    return _react2.default.createElement(AddedParticipant, props);
  } else if (props.post.event_type === "removed_participant") {
    return _react2.default.createElement(RemovedParticipant, props);
  } else {
    return null;
  }
};

exports.ChangedTitle = ChangedTitle;
exports.Moved = Moved;
exports.Merged = Merged;
exports.ChangedOwner = ChangedOwner;
exports.AddedParticipant = AddedParticipant;
exports.RemovedParticipant = RemovedParticipant;

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _escapeHtml = require("../../../utils/escape-html");

var _escapeHtml2 = _interopRequireDefault(_escapeHtml);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var MESSAGE = {
  pinned_globally: gettext("Thread has been pinned globally."),
  pinned_locally: gettext("Thread has been pinned locally."),
  unpinned: gettext("Thread has been unpinned."),

  approved: gettext("Thread has been approved."),

  opened: gettext("Thread has been opened."),
  closed: gettext("Thread has been closed."),

  unhid: gettext("Thread has been revealed."),
  hid: gettext("Thread has been made hidden."),

  tookover: gettext("Took thread over."),

  owner_left: gettext("Owner has left thread. This thread is now closed."),
  participant_left: gettext("Participant has left thread.")
};

var ITEM_LINK = '<a href="%(url)s" class="item-title">%(name)s</a>';
var ITEM_SPAN = '<span class="item-title">%(name)s</span>';

function ChangedTitle(props) {
  var msgstring = (0, _escapeHtml2.default)(gettext("Thread title has been changed from %(old_title)s."));
  var oldTitle = interpolate(ITEM_SPAN, {
    name: (0, _escapeHtml2.default)(props.post.event_context.old_title)
  }, true);
  var message = interpolate(msgstring, {
    old_title: oldTitle
  }, true);

  return _react2.default.createElement("p", {
    className: "event-message",
    dangerouslySetInnerHTML: { __html: message }
  });
}

function Moved(props) {
  var msgstring = (0, _escapeHtml2.default)(gettext("Thread has been moved from %(from_category)s."));
  var fromCategory = interpolate(ITEM_LINK, {
    url: (0, _escapeHtml2.default)(props.post.event_context.from_category.url),
    name: (0, _escapeHtml2.default)(props.post.event_context.from_category.name)
  }, true);

  var message = interpolate(msgstring, {
    from_category: fromCategory
  }, true);

  return _react2.default.createElement("p", {
    className: "event-message",
    dangerouslySetInnerHTML: { __html: message }
  });
}

function Merged(props) {
  var msgstring = (0, _escapeHtml2.default)(gettext("The %(merged_thread)s thread has been merged into this thread."));
  var mergedThread = interpolate(ITEM_SPAN, {
    name: (0, _escapeHtml2.default)(props.post.event_context.merged_thread)
  }, true);

  var message = interpolate(msgstring, {
    merged_thread: mergedThread
  }, true);

  return _react2.default.createElement("p", {
    className: "event-message",
    dangerouslySetInnerHTML: { __html: message }
  });
}

function ChangedOwner(props) {
  var msgstring = (0, _escapeHtml2.default)(gettext("Changed thread owner to %(user)s."));
  var newOwner = interpolate(ITEM_LINK, {
    url: (0, _escapeHtml2.default)(props.post.event_context.user.url),
    name: (0, _escapeHtml2.default)(props.post.event_context.user.username)
  }, true);

  var message = interpolate(msgstring, {
    user: newOwner
  }, true);

  return _react2.default.createElement("p", {
    className: "event-message",
    dangerouslySetInnerHTML: { __html: message }
  });
}

function AddedParticipant(props) {
  var msgstring = (0, _escapeHtml2.default)(gettext("Added %(user)s to thread."));
  var newOwner = interpolate(ITEM_LINK, {
    url: (0, _escapeHtml2.default)(props.post.event_context.user.url),
    name: (0, _escapeHtml2.default)(props.post.event_context.user.username)
  }, true);

  var message = interpolate(msgstring, {
    user: newOwner
  }, true);

  return _react2.default.createElement("p", {
    className: "event-message",
    dangerouslySetInnerHTML: { __html: message }
  });
}

function RemovedParticipant(props) {
  var msgstring = (0, _escapeHtml2.default)(gettext("Removed %(user)s from thread."));
  var newOwner = interpolate(ITEM_LINK, {
    url: (0, _escapeHtml2.default)(props.post.event_context.user.url),
    name: (0, _escapeHtml2.default)(props.post.event_context.user.username)
  }, true);

  var message = interpolate(msgstring, {
    user: newOwner
  }, true);

  return _react2.default.createElement("p", {
    className: "event-message",
    dangerouslySetInnerHTML: { __html: message }
  });
}

},{"../../../utils/escape-html":383,"react":"react"}],150:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (_ref) {
  var post = _ref.post;

  if (post.is_read) return null;

  return _react2.default.createElement(
    "div",
    { className: "row" },
    _react2.default.createElement(
      "div",
      { className: "col-xs-10 col-xs-offset-2 col-sm-9 col-sm-offset-3 text-left" },
      _react2.default.createElement(
        "div",
        { className: "event-label" },
        _react2.default.createElement(
          "span",
          { className: "label label-unread" },
          gettext("New event")
        )
      )
    )
  );
};

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

},{"react":"react"}],151:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

exports.default = function (props) {
  if (!props.posts.isLoaded) {
    return _react2.default.createElement(
      "ul",
      { className: "posts-list ui-preview" },
      _react2.default.createElement(_preview2.default, null)
    );
  }

  return _react2.default.createElement(
    "ul",
    { className: "posts-list ui-ready" },
    props.posts.results.map(function (post) {
      return _react2.default.createElement(ListItem, _extends({ key: post.id, post: post }, props));
    })
  );
};

exports.ListItem = ListItem;

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _event = require("./event");

var _event2 = _interopRequireDefault(_event);

var _post = require("./post");

var _post2 = _interopRequireDefault(_post);

var _preview = require("./post/preview");

var _preview2 = _interopRequireDefault(_preview);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function ListItem(props) {
  if (props.post.is_event) {
    return _react2.default.createElement(_event2.default, props);
  }

  return _react2.default.createElement(_post2.default, props);
}

},{"./event":147,"./post":163,"./post/preview":171,"react":"react"}],152:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (props) {
  return _react2.default.createElement(
    "div",
    { className: "col-xs-12 col-md-6" },
    _react2.default.createElement(AttachmentPreview, props),
    _react2.default.createElement(
      "div",
      { className: "post-attachment" },
      _react2.default.createElement(
        "a",
        {
          href: props.attachment.url.index,
          className: "attachment-name item-title"
        },
        props.attachment.filename
      ),
      _react2.default.createElement(AttachmentDetails, props)
    )
  );
};

exports.AttachmentPreview = AttachmentPreview;
exports.AttachmentIcon = AttachmentIcon;
exports.AttachmentThumbnail = AttachmentThumbnail;
exports.AttachmentDetails = AttachmentDetails;

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _ = require("../../../..");

var _2 = _interopRequireDefault(_);

var _escapeHtml = require("../../../../utils/escape-html");

var _escapeHtml2 = _interopRequireDefault(_escapeHtml);

var _fileSize = require("../../../../utils/file-size");

var _fileSize2 = _interopRequireDefault(_fileSize);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var DATE_ABBR = '<abbr title="%(absolute)s">%(relative)s</abbr>';
var USER_SPAN = '<span class="item-title">%(user)s</span>';
var USER_URL = '<a href="%(url)s" class="item-title">%(user)s</a>';

function AttachmentPreview(props) {
  if (props.attachment.is_image) {
    return _react2.default.createElement(
      "div",
      { className: "post-attachment-preview" },
      _react2.default.createElement(AttachmentThumbnail, props)
    );
  } else {
    return _react2.default.createElement(
      "div",
      { className: "post-attachment-preview" },
      _react2.default.createElement(AttachmentIcon, props)
    );
  }
}

function AttachmentIcon(props) {
  return _react2.default.createElement(
    "a",
    { href: props.attachment.url.index, className: "material-icon" },
    "insert_drive_file"
  );
}

function AttachmentThumbnail(props) {
  var url = props.attachment.url.thumb || props.attachment.url.index;
  return _react2.default.createElement("a", {
    className: "post-thumbnail",
    href: props.attachment.url.index,
    style: { backgroundImage: 'url("' + (0, _escapeHtml2.default)(url) + '")' }
  });
}

function AttachmentDetails(props) {
  var user = null;
  if (props.attachment.url.uploader) {
    user = interpolate(USER_URL, {
      url: (0, _escapeHtml2.default)(props.attachment.url.uploader),
      user: (0, _escapeHtml2.default)(props.attachment.uploader_name)
    }, true);
  } else {
    user = interpolate(USER_SPAN, {
      user: (0, _escapeHtml2.default)(props.attachment.uploader_name)
    }, true);
  }

  var date = interpolate(DATE_ABBR, {
    absolute: (0, _escapeHtml2.default)(props.attachment.uploaded_on.format("LLL")),
    relative: (0, _escapeHtml2.default)(props.attachment.uploaded_on.fromNow())
  }, true);

  var message = interpolate((0, _escapeHtml2.default)(gettext("%(filetype)s, %(size)s, uploaded by %(uploader)s %(uploaded_on)s.")), {
    filetype: props.attachment.filetype,
    size: (0, _fileSize2.default)(props.attachment.size),
    uploader: user,
    uploaded_on: date
  }, true);

  return _react2.default.createElement("p", {
    className: "post-attachment-description",
    dangerouslySetInnerHTML: { __html: message }
  });
}

},{"../../../..":302,"../../../../utils/escape-html":383,"../../../../utils/file-size":384,"react":"react"}],153:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (props) {
  if (!isVisible(props.post)) {
    return null;
  }

  return _react2.default.createElement(
    "div",
    { className: "post-attachments" },
    (0, _batch2.default)(props.post.attachments, 2).map(function (row) {
      var key = row.map(function (a) {
        return a ? a.id : 0;
      }).join("_");
      return _react2.default.createElement(Row, { key: key, row: row });
    })
  );
};

exports.isVisible = isVisible;
exports.Row = Row;

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _batch = require("../../../../utils/batch");

var _batch2 = _interopRequireDefault(_batch);

var _attachment = require("./attachment");

var _attachment2 = _interopRequireDefault(_attachment);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function isVisible(post) {
  return (!post.is_hidden || post.acl.can_see_hidden) && post.attachments;
}

function Row(props) {
  return _react2.default.createElement(
    "div",
    { className: "row" },
    props.row.map(function (attachment) {
      return _react2.default.createElement(_attachment2.default, {
        attachment: attachment,
        key: attachment ? attachment.id : 0
      });
    })
  );
}

},{"../../../../utils/batch":380,"./attachment":152,"react":"react"}],154:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (props) {
  if (props.post.is_hidden && !props.post.acl.can_see_hidden) {
    return _react2.default.createElement(Hidden, props);
  } else if (props.post.content) {
    return _react2.default.createElement(Default, props);
  } else {
    return _react2.default.createElement(Invalid, props);
  }
};

exports.Default = Default;
exports.Hidden = Hidden;
exports.Invalid = Invalid;

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _waypoint = require("../waypoint");

var _waypoint2 = _interopRequireDefault(_waypoint);

var _misagoMarkup = require("../../misago-markup");

var _misagoMarkup2 = _interopRequireDefault(_misagoMarkup);

var _escapeHtml = require("../../../utils/escape-html");

var _escapeHtml2 = _interopRequireDefault(_escapeHtml);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var HIDDEN_BY_URL = '<a href="%(url)s" class="item-title">%(user)s</a>';
var HIDDEN_BY_SPAN = '<span class="item-title">%(user)s</span>';
var HIDDEN_ON = '<abbr class="last-title" title="%(absolute)s">%(relative)s</abbr>';

function Default(props) {
  return _react2.default.createElement(
    _waypoint2.default,
    { className: "post-body", post: props.post },
    _react2.default.createElement(_misagoMarkup2.default, { markup: props.post.content })
  );
}

function Hidden(props) {
  var user = null;
  if (props.post.hidden_by) {
    user = interpolate(HIDDEN_BY_URL, {
      url: (0, _escapeHtml2.default)(props.post.url.hidden_by),
      user: (0, _escapeHtml2.default)(props.post.hidden_by_name)
    }, true);
  } else {
    user = interpolate(HIDDEN_BY_SPAN, {
      user: (0, _escapeHtml2.default)(props.post.hidden_by_name)
    }, true);
  }

  var date = interpolate(HIDDEN_ON, {
    absolute: (0, _escapeHtml2.default)(props.post.hidden_on.format("LLL")),
    relative: (0, _escapeHtml2.default)(props.post.hidden_on.fromNow())
  }, true);

  var message = interpolate((0, _escapeHtml2.default)(gettext("Hidden by %(hidden_by)s %(hidden_on)s.")), {
    hidden_by: user,
    hidden_on: date
  }, true);

  return _react2.default.createElement(
    _waypoint2.default,
    { className: "post-body post-body-hidden", post: props.post },
    _react2.default.createElement(
      "p",
      { className: "lead" },
      gettext("This post is hidden. You cannot see its contents.")
    ),
    _react2.default.createElement("p", { className: "text-muted", dangerouslySetInnerHTML: { __html: message } })
  );
}

function Invalid(props) {
  return _react2.default.createElement(
    _waypoint2.default,
    { className: "post-body post-body-invalid", post: props.post },
    _react2.default.createElement(
      "p",
      { className: "lead" },
      gettext("This post's contents cannot be displayed.")
    ),
    _react2.default.createElement(
      "p",
      { className: "text-muted" },
      gettext("This error is caused by invalid post content manipulation.")
    )
  );
}

},{"../../../utils/escape-html":383,"../../misago-markup":60,"../waypoint":173,"react":"react"}],155:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.approve = approve;
exports.protect = protect;
exports.unprotect = unprotect;
exports.hide = hide;
exports.unhide = unhide;
exports.like = like;
exports.unlike = unlike;
exports.patch = patch;
exports.remove = remove;
exports.markAsBestAnswer = markAsBestAnswer;
exports.unmarkBestAnswer = unmarkBestAnswer;
exports.patchThread = patchThread;

var _moment = require("moment");

var _moment2 = _interopRequireDefault(_moment);

var _thread = require("../../../../reducers/thread");

var thread = _interopRequireWildcard(_thread);

var _post = require("../../../../reducers/post");

var post = _interopRequireWildcard(_post);

var _ajax = require("../../../../services/ajax");

var _ajax2 = _interopRequireDefault(_ajax);

var _snackbar = require("../../../../services/snackbar");

var _snackbar2 = _interopRequireDefault(_snackbar);

var _store = require("../../../../services/store");

var _store2 = _interopRequireDefault(_store);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function approve(props) {
  _store2.default.dispatch(post.patch(props.post, {
    is_unapproved: false
  }));

  var ops = [{ op: "replace", path: "is-unapproved", value: false }];

  var previousState = {
    is_unapproved: props.post.is_unapproved
  };

  patch(props, ops, previousState);
}

function protect(props) {
  _store2.default.dispatch(post.patch(props.post, {
    is_protected: true
  }));

  var ops = [{ op: "replace", path: "is-protected", value: true }];

  var previousState = {
    is_protected: props.post.is_protected
  };

  patch(props, ops, previousState);
}

function unprotect(props) {
  _store2.default.dispatch(post.patch(props.post, {
    is_protected: false
  }));

  var ops = [{ op: "replace", path: "is-protected", value: false }];

  var previousState = {
    is_protected: props.post.is_protected
  };

  patch(props, ops, previousState);
}

function hide(props) {
  _store2.default.dispatch(post.patch(props.post, {
    is_hidden: true,
    hidden_on: (0, _moment2.default)(),
    hidden_by_name: props.user.username,
    url: Object.assign(props.post.url, {
      hidden_by: props.user.url
    })
  }));

  var ops = [{ op: "replace", path: "is-hidden", value: true }];

  var previousState = {
    is_hidden: props.post.is_hidden,
    hidden_on: props.post.hidden_on,
    hidden_by_name: props.post.hidden_by_name,
    url: props.post.url
  };

  patch(props, ops, previousState);
}

function unhide(props) {
  _store2.default.dispatch(post.patch(props.post, {
    is_hidden: false
  }));

  var ops = [{ op: "replace", path: "is-hidden", value: false }];

  var previousState = {
    is_hidden: props.post.is_hidden
  };

  patch(props, ops, previousState);
}

function like(props) {
  var lastLikes = props.post.last_likes || [];
  var concatedLikes = [props.user].concat(lastLikes);
  var finalLikes = concatedLikes.length > 3 ? concatedLikes.slice(0, -1) : concatedLikes;

  _store2.default.dispatch(post.patch(props.post, {
    is_liked: true,
    likes: props.post.likes + 1,
    last_likes: finalLikes
  }));

  var ops = [{ op: "replace", path: "is-liked", value: true }];

  var previousState = {
    is_liked: props.post.is_liked,
    likes: props.post.likes,
    last_likes: props.post.last_likes
  };

  patch(props, ops, previousState);
}

function unlike(props) {
  _store2.default.dispatch(post.patch(props.post, {
    is_liked: false,
    likes: props.post.likes - 1,
    last_likes: props.post.last_likes.filter(function (user) {
      return !user.id || user.id !== props.user.id;
    })
  }));

  var ops = [{ op: "replace", path: "is-liked", value: false }];

  var previousState = {
    is_liked: props.post.is_liked,
    likes: props.post.likes,
    last_likes: props.post.last_likes
  };

  patch(props, ops, previousState);
}

function patch(props, ops, previousState) {
  _ajax2.default.patch(props.post.api.index, ops).then(function (newState) {
    _store2.default.dispatch(post.patch(props.post, newState));
  }, function (rejection) {
    if (rejection.status === 400) {
      _snackbar2.default.error(rejection.detail[0]);
    } else {
      _snackbar2.default.apiError(rejection);
    }

    _store2.default.dispatch(post.patch(props.post, previousState));
  });
}

function remove(props) {
  var confirmed = confirm(gettext("Are you sure you want to delete this post? This action is not reversible!"));
  if (!confirmed) {
    return;
  }

  _store2.default.dispatch(post.patch(props.post, {
    isDeleted: true
  }));

  _ajax2.default.delete(props.post.api.index).then(function () {
    _snackbar2.default.success(gettext("Post has been deleted."));
  }, function (rejection) {
    if (rejection.status === 400) {
      _snackbar2.default.error(rejection.detail);
    } else {
      _snackbar2.default.apiError(rejection);
    }

    _store2.default.dispatch(post.patch(props.post, {
      isDeleted: false
    }));
  });
}

function markAsBestAnswer(props) {
  var post = props.post,
      user = props.user;


  _store2.default.dispatch(thread.update({
    best_answer: post.id,
    best_answer_is_protected: post.is_protected,
    best_answer_marked_on: (0, _moment2.default)(),
    best_answer_marked_by: user.id,
    best_answer_marked_by_name: user.username,
    best_answer_marked_by_slug: user.slug
  }));

  var ops = [{ op: "replace", path: "best-answer", value: post.id }, { op: "add", path: "acl", value: true }];

  var previousState = {
    best_answer: props.thread.best_answer,
    best_answer_is_protected: props.thread.best_answer_is_protected,
    best_answer_marked_on: props.thread.best_answer_marked_on,
    best_answer_marked_by: props.thread.best_answer_marked_by,
    best_answer_marked_by_name: props.thread.best_answer_marked_by_name,
    best_answer_marked_by_slug: props.thread.best_answer_marked_by_slug
  };

  patchThread(props, ops, previousState);
}

function unmarkBestAnswer(props) {
  var post = props.post;


  _store2.default.dispatch(thread.update({
    best_answer: null,
    best_answer_is_protected: false,
    best_answer_marked_on: null,
    best_answer_marked_by: null,
    best_answer_marked_by_name: null,
    best_answer_marked_by_slug: null
  }));

  var ops = [{ op: "remove", path: "best-answer", value: post.id }, { op: "add", path: "acl", value: true }];

  var previousState = {
    best_answer: props.thread.best_answer,
    best_answer_is_protected: props.thread.best_answer_is_protected,
    best_answer_marked_on: props.thread.best_answer_marked_on,
    best_answer_marked_by: props.thread.best_answer_marked_by,
    best_answer_marked_by_name: props.thread.best_answer_marked_by_name,
    best_answer_marked_by_slug: props.thread.best_answer_marked_by_slug
  };

  patchThread(props, ops, previousState);
}

function patchThread(props, ops, previousState) {
  _ajax2.default.patch(props.thread.api.index, ops).then(function (newState) {
    if (newState.best_answer_marked_on) {
      newState.best_answer_marked_on = (0, _moment2.default)(newState.best_answer_marked_on);
    }
    _store2.default.dispatch(thread.update(newState));
  }, function (rejection) {
    if (rejection.status === 400) {
      _snackbar2.default.error(rejection.detail[0]);
    } else {
      _snackbar2.default.apiError(rejection);
    }

    _store2.default.dispatch(thread.update(previousState));
  });
}

},{"../../../../reducers/post":353,"../../../../reducers/thread":360,"../../../../services/ajax":365,"../../../../services/snackbar":376,"../../../../services/store":377,"moment":"moment"}],156:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Delete = exports.Unhide = exports.Hide = exports.Unprotect = exports.Protect = exports.Split = exports.Move = exports.Approve = exports.PostEdits = exports.UnmarkMarkBestAnswer = exports.MarkAsBestAnswer = exports.Edit = exports.Permalink = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

exports.default = function (props) {
  return _react2.default.createElement(
    "ul",
    { className: "dropdown-menu dropdown-menu-right stick-to-bottom" },
    _react2.default.createElement(Permalink, props),
    _react2.default.createElement(Edit, props),
    _react2.default.createElement(MarkAsBestAnswer, props),
    _react2.default.createElement(UnmarkMarkBestAnswer, props),
    _react2.default.createElement(PostEdits, props),
    _react2.default.createElement(Approve, props),
    _react2.default.createElement(Move, props),
    _react2.default.createElement(Split, props),
    _react2.default.createElement(Protect, props),
    _react2.default.createElement(Unprotect, props),
    _react2.default.createElement(Hide, props),
    _react2.default.createElement(Unhide, props),
    _react2.default.createElement(Delete, props)
  );
};

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _modal = require("../../../../services/modal");

var _modal2 = _interopRequireDefault(_modal);

var _posting = require("../../../../services/posting");

var _posting2 = _interopRequireDefault(_posting);

var _actions = require("./actions");

var moderation = _interopRequireWildcard(_actions);

var _move = require("./move");

var _move2 = _interopRequireDefault(_move);

var _postChangelog = require("../../../post-changelog");

var _postChangelog2 = _interopRequireDefault(_postChangelog);

var _split = require("./split");

var _split2 = _interopRequireDefault(_split);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var Permalink = exports.Permalink = function (_React$Component) {
  _inherits(Permalink, _React$Component);

  function Permalink() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, Permalink);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = Permalink.__proto__ || Object.getPrototypeOf(Permalink)).call.apply(_ref, [this].concat(args))), _this), _this.onClick = function () {
      var permaUrl = window.location.protocol + "//";
      permaUrl += window.location.host;
      permaUrl += _this.props.post.url.index;

      prompt(gettext("Permament link to this post:"), permaUrl);
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(Permalink, [{
    key: "render",
    value: function render() {
      return _react2.default.createElement(
        "li",
        null,
        _react2.default.createElement(
          "button",
          { className: "btn btn-link", onClick: this.onClick, type: "button" },
          _react2.default.createElement(
            "span",
            { className: "material-icon" },
            "link"
          ),
          gettext("Permament link")
        )
      );
    }
  }]);

  return Permalink;
}(_react2.default.Component);

var Edit = exports.Edit = function (_React$Component2) {
  _inherits(Edit, _React$Component2);

  function Edit() {
    var _ref2;

    var _temp2, _this2, _ret2;

    _classCallCheck(this, Edit);

    for (var _len2 = arguments.length, args = Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
      args[_key2] = arguments[_key2];
    }

    return _ret2 = (_temp2 = (_this2 = _possibleConstructorReturn(this, (_ref2 = Edit.__proto__ || Object.getPrototypeOf(Edit)).call.apply(_ref2, [this].concat(args))), _this2), _this2.onClick = function () {
      _posting2.default.open({
        mode: "EDIT",

        config: _this2.props.post.api.editor,
        submit: _this2.props.post.api.index
      });
    }, _temp2), _possibleConstructorReturn(_this2, _ret2);
  }

  _createClass(Edit, [{
    key: "render",
    value: function render() {
      if (!this.props.post.acl.can_edit) return null;

      return _react2.default.createElement(
        "li",
        null,
        _react2.default.createElement(
          "button",
          { className: "btn btn-link", onClick: this.onClick, type: "button" },
          _react2.default.createElement(
            "span",
            { className: "material-icon" },
            "edit"
          ),
          gettext("Edit")
        )
      );
    }
  }]);

  return Edit;
}(_react2.default.Component);

var MarkAsBestAnswer = exports.MarkAsBestAnswer = function (_React$Component3) {
  _inherits(MarkAsBestAnswer, _React$Component3);

  function MarkAsBestAnswer() {
    var _ref3;

    var _temp3, _this3, _ret3;

    _classCallCheck(this, MarkAsBestAnswer);

    for (var _len3 = arguments.length, args = Array(_len3), _key3 = 0; _key3 < _len3; _key3++) {
      args[_key3] = arguments[_key3];
    }

    return _ret3 = (_temp3 = (_this3 = _possibleConstructorReturn(this, (_ref3 = MarkAsBestAnswer.__proto__ || Object.getPrototypeOf(MarkAsBestAnswer)).call.apply(_ref3, [this].concat(args))), _this3), _this3.onClick = function () {
      moderation.markAsBestAnswer(_this3.props);
    }, _temp3), _possibleConstructorReturn(_this3, _ret3);
  }

  _createClass(MarkAsBestAnswer, [{
    key: "render",
    value: function render() {
      var _props = this.props,
          post = _props.post,
          thread = _props.thread;


      if (!thread.acl.can_mark_best_answer) return null;
      if (!post.acl.can_mark_as_best_answer) return null;
      if (post.id === thread.best_answer) return null;
      if (thread.best_answer && !thread.acl.can_change_best_answer) return null;

      return _react2.default.createElement(
        "li",
        null,
        _react2.default.createElement(
          "button",
          { className: "btn btn-link", onClick: this.onClick, type: "button" },
          _react2.default.createElement(
            "span",
            { className: "material-icon" },
            "check_box"
          ),
          gettext("Mark as best answer")
        )
      );
    }
  }]);

  return MarkAsBestAnswer;
}(_react2.default.Component);

var UnmarkMarkBestAnswer = exports.UnmarkMarkBestAnswer = function (_React$Component4) {
  _inherits(UnmarkMarkBestAnswer, _React$Component4);

  function UnmarkMarkBestAnswer() {
    var _ref4;

    var _temp4, _this4, _ret4;

    _classCallCheck(this, UnmarkMarkBestAnswer);

    for (var _len4 = arguments.length, args = Array(_len4), _key4 = 0; _key4 < _len4; _key4++) {
      args[_key4] = arguments[_key4];
    }

    return _ret4 = (_temp4 = (_this4 = _possibleConstructorReturn(this, (_ref4 = UnmarkMarkBestAnswer.__proto__ || Object.getPrototypeOf(UnmarkMarkBestAnswer)).call.apply(_ref4, [this].concat(args))), _this4), _this4.onClick = function () {
      moderation.unmarkBestAnswer(_this4.props);
    }, _temp4), _possibleConstructorReturn(_this4, _ret4);
  }

  _createClass(UnmarkMarkBestAnswer, [{
    key: "render",
    value: function render() {
      var _props2 = this.props,
          post = _props2.post,
          thread = _props2.thread;


      if (post.id !== thread.best_answer) return null;
      if (!thread.acl.can_unmark_best_answer) return null;

      return _react2.default.createElement(
        "li",
        null,
        _react2.default.createElement(
          "button",
          { className: "btn btn-link", onClick: this.onClick, type: "button" },
          _react2.default.createElement(
            "span",
            { className: "material-icon" },
            "check_box_outline_blank"
          ),
          gettext("Unmark best answer")
        )
      );
    }
  }]);

  return UnmarkMarkBestAnswer;
}(_react2.default.Component);

var PostEdits = exports.PostEdits = function (_React$Component5) {
  _inherits(PostEdits, _React$Component5);

  function PostEdits() {
    var _ref5;

    var _temp5, _this5, _ret5;

    _classCallCheck(this, PostEdits);

    for (var _len5 = arguments.length, args = Array(_len5), _key5 = 0; _key5 < _len5; _key5++) {
      args[_key5] = arguments[_key5];
    }

    return _ret5 = (_temp5 = (_this5 = _possibleConstructorReturn(this, (_ref5 = PostEdits.__proto__ || Object.getPrototypeOf(PostEdits)).call.apply(_ref5, [this].concat(args))), _this5), _this5.onClick = function () {
      _modal2.default.show(_react2.default.createElement(_postChangelog2.default, { post: _this5.props.post }));
    }, _temp5), _possibleConstructorReturn(_this5, _ret5);
  }

  _createClass(PostEdits, [{
    key: "render",
    value: function render() {
      var isHidden = this.props.post.is_hidden && !this.props.post.acl.can_see_hidden;
      var isUnedited = this.props.post.edits === 0;
      if (isHidden || isUnedited) return null;

      var message = ngettext("This post was edited %(edits)s time.", "This post was edited %(edits)s times.", this.props.post.edits);

      var title = interpolate(message, {
        edits: this.props.post.edits
      }, true);

      return _react2.default.createElement(
        "li",
        null,
        _react2.default.createElement(
          "button",
          { className: "btn btn-link", onClick: this.onClick, type: "button" },
          _react2.default.createElement(
            "span",
            { className: "material-icon" },
            "edit"
          ),
          gettext("Changes history")
        )
      );
    }
  }]);

  return PostEdits;
}(_react2.default.Component);

var Approve = exports.Approve = function (_React$Component6) {
  _inherits(Approve, _React$Component6);

  function Approve() {
    var _ref6;

    var _temp6, _this6, _ret6;

    _classCallCheck(this, Approve);

    for (var _len6 = arguments.length, args = Array(_len6), _key6 = 0; _key6 < _len6; _key6++) {
      args[_key6] = arguments[_key6];
    }

    return _ret6 = (_temp6 = (_this6 = _possibleConstructorReturn(this, (_ref6 = Approve.__proto__ || Object.getPrototypeOf(Approve)).call.apply(_ref6, [this].concat(args))), _this6), _this6.onClick = function () {
      moderation.approve(_this6.props);
    }, _temp6), _possibleConstructorReturn(_this6, _ret6);
  }

  _createClass(Approve, [{
    key: "render",
    value: function render() {
      if (!this.props.post.acl.can_approve) return null;
      if (!this.props.post.is_unapproved) return null;

      return _react2.default.createElement(
        "li",
        null,
        _react2.default.createElement(
          "button",
          { className: "btn btn-link", onClick: this.onClick, type: "button" },
          _react2.default.createElement(
            "span",
            { className: "material-icon" },
            "done"
          ),
          gettext("Approve")
        )
      );
    }
  }]);

  return Approve;
}(_react2.default.Component);

var Move = exports.Move = function (_React$Component7) {
  _inherits(Move, _React$Component7);

  function Move() {
    var _ref7;

    var _temp7, _this7, _ret7;

    _classCallCheck(this, Move);

    for (var _len7 = arguments.length, args = Array(_len7), _key7 = 0; _key7 < _len7; _key7++) {
      args[_key7] = arguments[_key7];
    }

    return _ret7 = (_temp7 = (_this7 = _possibleConstructorReturn(this, (_ref7 = Move.__proto__ || Object.getPrototypeOf(Move)).call.apply(_ref7, [this].concat(args))), _this7), _this7.onClick = function () {
      _modal2.default.show(_react2.default.createElement(_move2.default, _this7.props));
    }, _temp7), _possibleConstructorReturn(_this7, _ret7);
  }

  _createClass(Move, [{
    key: "render",
    value: function render() {
      if (!this.props.post.acl.can_move) return null;

      return _react2.default.createElement(
        "li",
        null,
        _react2.default.createElement(
          "button",
          { className: "btn btn-link", onClick: this.onClick, type: "button" },
          _react2.default.createElement(
            "span",
            { className: "material-icon" },
            "arrow_forward"
          ),
          gettext("Move")
        )
      );
    }
  }]);

  return Move;
}(_react2.default.Component);

var Split = exports.Split = function (_React$Component8) {
  _inherits(Split, _React$Component8);

  function Split() {
    var _ref8;

    var _temp8, _this8, _ret8;

    _classCallCheck(this, Split);

    for (var _len8 = arguments.length, args = Array(_len8), _key8 = 0; _key8 < _len8; _key8++) {
      args[_key8] = arguments[_key8];
    }

    return _ret8 = (_temp8 = (_this8 = _possibleConstructorReturn(this, (_ref8 = Split.__proto__ || Object.getPrototypeOf(Split)).call.apply(_ref8, [this].concat(args))), _this8), _this8.onClick = function () {
      _modal2.default.show(_react2.default.createElement(_split2.default, _this8.props));
    }, _temp8), _possibleConstructorReturn(_this8, _ret8);
  }

  _createClass(Split, [{
    key: "render",
    value: function render() {
      if (!this.props.post.acl.can_move) return null;

      return _react2.default.createElement(
        "li",
        null,
        _react2.default.createElement(
          "button",
          { className: "btn btn-link", onClick: this.onClick, type: "button" },
          _react2.default.createElement(
            "span",
            { className: "material-icon" },
            "call_split"
          ),
          gettext("Split")
        )
      );
    }
  }]);

  return Split;
}(_react2.default.Component);

var Protect = exports.Protect = function (_React$Component9) {
  _inherits(Protect, _React$Component9);

  function Protect() {
    var _ref9;

    var _temp9, _this9, _ret9;

    _classCallCheck(this, Protect);

    for (var _len9 = arguments.length, args = Array(_len9), _key9 = 0; _key9 < _len9; _key9++) {
      args[_key9] = arguments[_key9];
    }

    return _ret9 = (_temp9 = (_this9 = _possibleConstructorReturn(this, (_ref9 = Protect.__proto__ || Object.getPrototypeOf(Protect)).call.apply(_ref9, [this].concat(args))), _this9), _this9.onClick = function () {
      moderation.protect(_this9.props);
    }, _temp9), _possibleConstructorReturn(_this9, _ret9);
  }

  _createClass(Protect, [{
    key: "render",
    value: function render() {
      if (!this.props.post.acl.can_protect) return null;
      if (this.props.post.is_protected) return null;

      return _react2.default.createElement(
        "li",
        null,
        _react2.default.createElement(
          "button",
          { className: "btn btn-link", onClick: this.onClick, type: "button" },
          _react2.default.createElement(
            "span",
            { className: "material-icon" },
            "lock_outline"
          ),
          gettext("Protect")
        )
      );
    }
  }]);

  return Protect;
}(_react2.default.Component);

var Unprotect = exports.Unprotect = function (_React$Component10) {
  _inherits(Unprotect, _React$Component10);

  function Unprotect() {
    var _ref10;

    var _temp10, _this10, _ret10;

    _classCallCheck(this, Unprotect);

    for (var _len10 = arguments.length, args = Array(_len10), _key10 = 0; _key10 < _len10; _key10++) {
      args[_key10] = arguments[_key10];
    }

    return _ret10 = (_temp10 = (_this10 = _possibleConstructorReturn(this, (_ref10 = Unprotect.__proto__ || Object.getPrototypeOf(Unprotect)).call.apply(_ref10, [this].concat(args))), _this10), _this10.onClick = function () {
      moderation.unprotect(_this10.props);
    }, _temp10), _possibleConstructorReturn(_this10, _ret10);
  }

  _createClass(Unprotect, [{
    key: "render",
    value: function render() {
      if (!this.props.post.acl.can_protect) return null;
      if (!this.props.post.is_protected) return null;

      return _react2.default.createElement(
        "li",
        null,
        _react2.default.createElement(
          "button",
          { className: "btn btn-link", onClick: this.onClick, type: "button" },
          _react2.default.createElement(
            "span",
            { className: "material-icon" },
            "lock_open"
          ),
          gettext("Remove protection")
        )
      );
    }
  }]);

  return Unprotect;
}(_react2.default.Component);

var Hide = exports.Hide = function (_React$Component11) {
  _inherits(Hide, _React$Component11);

  function Hide() {
    var _ref11;

    var _temp11, _this11, _ret11;

    _classCallCheck(this, Hide);

    for (var _len11 = arguments.length, args = Array(_len11), _key11 = 0; _key11 < _len11; _key11++) {
      args[_key11] = arguments[_key11];
    }

    return _ret11 = (_temp11 = (_this11 = _possibleConstructorReturn(this, (_ref11 = Hide.__proto__ || Object.getPrototypeOf(Hide)).call.apply(_ref11, [this].concat(args))), _this11), _this11.onClick = function () {
      moderation.hide(_this11.props);
    }, _temp11), _possibleConstructorReturn(_this11, _ret11);
  }

  _createClass(Hide, [{
    key: "render",
    value: function render() {
      var _props3 = this.props,
          post = _props3.post,
          thread = _props3.thread;


      if (post.id === thread.best_answer) return null;
      if (!post.acl.can_hide) return null;
      if (post.is_hidden) return null;

      return _react2.default.createElement(
        "li",
        null,
        _react2.default.createElement(
          "button",
          { className: "btn btn-link", onClick: this.onClick, type: "button" },
          _react2.default.createElement(
            "span",
            { className: "material-icon" },
            "visibility_off"
          ),
          gettext("Hide")
        )
      );
    }
  }]);

  return Hide;
}(_react2.default.Component);

var Unhide = exports.Unhide = function (_React$Component12) {
  _inherits(Unhide, _React$Component12);

  function Unhide() {
    var _ref12;

    var _temp12, _this12, _ret12;

    _classCallCheck(this, Unhide);

    for (var _len12 = arguments.length, args = Array(_len12), _key12 = 0; _key12 < _len12; _key12++) {
      args[_key12] = arguments[_key12];
    }

    return _ret12 = (_temp12 = (_this12 = _possibleConstructorReturn(this, (_ref12 = Unhide.__proto__ || Object.getPrototypeOf(Unhide)).call.apply(_ref12, [this].concat(args))), _this12), _this12.onClick = function () {
      moderation.unhide(_this12.props);
    }, _temp12), _possibleConstructorReturn(_this12, _ret12);
  }

  _createClass(Unhide, [{
    key: "render",
    value: function render() {
      if (!this.props.post.acl.can_unhide) return null;
      if (!this.props.post.is_hidden) return null;

      return _react2.default.createElement(
        "li",
        null,
        _react2.default.createElement(
          "button",
          { className: "btn btn-link", onClick: this.onClick, type: "button" },
          _react2.default.createElement(
            "span",
            { className: "material-icon" },
            "visibility"
          ),
          gettext("Unhide")
        )
      );
    }
  }]);

  return Unhide;
}(_react2.default.Component);

var Delete = exports.Delete = function (_React$Component13) {
  _inherits(Delete, _React$Component13);

  function Delete() {
    var _ref13;

    var _temp13, _this13, _ret13;

    _classCallCheck(this, Delete);

    for (var _len13 = arguments.length, args = Array(_len13), _key13 = 0; _key13 < _len13; _key13++) {
      args[_key13] = arguments[_key13];
    }

    return _ret13 = (_temp13 = (_this13 = _possibleConstructorReturn(this, (_ref13 = Delete.__proto__ || Object.getPrototypeOf(Delete)).call.apply(_ref13, [this].concat(args))), _this13), _this13.onClick = function () {
      moderation.remove(_this13.props);
    }, _temp13), _possibleConstructorReturn(_this13, _ret13);
  }

  _createClass(Delete, [{
    key: "render",
    value: function render() {
      var _props4 = this.props,
          post = _props4.post,
          thread = _props4.thread;


      if (post.id === thread.best_answer) return null;
      if (!post.acl.can_delete) return null;

      return _react2.default.createElement(
        "li",
        null,
        _react2.default.createElement(
          "button",
          { className: "btn btn-link", onClick: this.onClick, type: "button" },
          _react2.default.createElement(
            "span",
            { className: "material-icon" },
            "clear"
          ),
          gettext("Delete")
        )
      );
    }
  }]);

  return Delete;
}(_react2.default.Component);

},{"../../../../services/modal":371,"../../../../services/posting":375,"../../../post-changelog":119,"./actions":155,"./move":158,"./split":159,"react":"react"}],157:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (props) {
  return _react2.default.createElement(
    "div",
    { className: "pull-right dropdown" },
    _react2.default.createElement(
      "button",
      {
        "aria-expanded": "true",
        "aria-haspopup": "true",
        className: "btn btn-default btn-icon dropdown-toggle",
        "data-toggle": "dropdown",
        type: "button"
      },
      _react2.default.createElement(
        "span",
        { className: "material-icon" },
        "expand_more"
      )
    ),
    _react2.default.createElement(_dropdown2.default, props)
  );
};

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _dropdown = require("./dropdown");

var _dropdown2 = _interopRequireDefault(_dropdown);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

},{"./dropdown":156,"react":"react"}],158:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

exports.ModalHeader = ModalHeader;

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _button = require("../../../button");

var _button2 = _interopRequireDefault(_button);

var _form = require("../../../form");

var _form2 = _interopRequireDefault(_form);

var _formGroup = require("../../../form-group");

var _formGroup2 = _interopRequireDefault(_formGroup);

var _post = require("../../../../reducers/post");

var post = _interopRequireWildcard(_post);

var _ajax = require("../../../../services/ajax");

var _ajax2 = _interopRequireDefault(_ajax);

var _modal = require("../../../../services/modal");

var _modal2 = _interopRequireDefault(_modal);

var _snackbar = require("../../../../services/snackbar");

var _snackbar2 = _interopRequireDefault(_snackbar);

var _store = require("../../../../services/store");

var _store2 = _interopRequireDefault(_store);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_Form) {
  _inherits(_class, _Form);

  function _class(props) {
    _classCallCheck(this, _class);

    var _this = _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).call(this, props));

    _this.onUrlChange = function (event) {
      _this.changeValue("url", event.target.value);
    };

    _this.state = {
      isLoading: false,

      url: "",

      validators: {
        url: []
      },
      errors: {}
    };
    return _this;
  }

  _createClass(_class, [{
    key: "clean",
    value: function clean() {
      if (!this.state.url.trim().length) {
        _snackbar2.default.error(gettext("You have to enter link to the other thread."));
        return false;
      }

      return true;
    }
  }, {
    key: "send",
    value: function send() {
      return _ajax2.default.post(this.props.thread.api.posts.move, {
        new_thread: this.state.url,
        posts: [this.props.post.id]
      });
    }
  }, {
    key: "handleSuccess",
    value: function handleSuccess(success) {
      _store2.default.dispatch(post.patch(this.props.post, {
        isDeleted: true
      }));

      _modal2.default.hide();

      _snackbar2.default.success(gettext("Selected post was moved to the other thread."));
    }
  }, {
    key: "handleError",
    value: function handleError(rejection) {
      if (rejection.status === 400) {
        _snackbar2.default.error(rejection.detail);
      } else {
        _snackbar2.default.apiError(rejection);
      }
    }
  }, {
    key: "render",
    value: function render() {
      return _react2.default.createElement(
        "div",
        { className: "modal-dialog", role: "document" },
        _react2.default.createElement(
          "form",
          { onSubmit: this.handleSubmit },
          _react2.default.createElement(
            "div",
            { className: "modal-content" },
            _react2.default.createElement(ModalHeader, null),
            _react2.default.createElement(
              "div",
              { className: "modal-body" },
              _react2.default.createElement(
                _formGroup2.default,
                {
                  "for": "id_url",
                  label: gettext("Link to thread you want to move post to")
                },
                _react2.default.createElement("input", {
                  className: "form-control",
                  disabled: this.state.isLoading,
                  id: "id_url",
                  onChange: this.onUrlChange,
                  value: this.state.url
                })
              )
            ),
            _react2.default.createElement(
              "div",
              { className: "modal-footer" },
              _react2.default.createElement(
                "button",
                {
                  className: "btn btn-primary",
                  loading: this.state.isLoading
                },
                gettext("Move post")
              )
            )
          )
        )
      );
    }
  }]);

  return _class;
}(_form2.default);

exports.default = _class;
function ModalHeader(props) {
  return _react2.default.createElement(
    "div",
    { className: "modal-header" },
    _react2.default.createElement(
      "button",
      {
        "aria-label": gettext("Close"),
        className: "close",
        "data-dismiss": "modal",
        type: "button"
      },
      _react2.default.createElement(
        "span",
        { "aria-hidden": "true" },
        "\xD7"
      )
    ),
    _react2.default.createElement(
      "h4",
      { className: "modal-title" },
      gettext("Move post")
    )
  );
}

},{"../../../../reducers/post":353,"../../../../services/ajax":365,"../../../../services/modal":371,"../../../../services/snackbar":376,"../../../../services/store":377,"../../../button":8,"../../../form":56,"../../../form-group":55,"react":"react"}],159:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ModerationForm = exports.PostingConfig = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

exports.default = function (props) {
  return _react2.default.createElement(PostingConfig, _extends({}, props, { Form: ModerationForm }));
};

exports.Loader = Loader;
exports.Error = Error;
exports.Modal = Modal;

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _button = require("../../../button");

var _button2 = _interopRequireDefault(_button);

var _form = require("../../../form");

var _form2 = _interopRequireDefault(_form);

var _formGroup = require("../../../form-group");

var _formGroup2 = _interopRequireDefault(_formGroup);

var _categorySelect = require("../../../category-select");

var _categorySelect2 = _interopRequireDefault(_categorySelect);

var _modalLoader = require("../../../modal-loader");

var _modalLoader2 = _interopRequireDefault(_modalLoader);

var _select = require("../../../select");

var _select2 = _interopRequireDefault(_select);

var _post = require("../../../../reducers/post");

var post = _interopRequireWildcard(_post);

var _ajax = require("../../../../services/ajax");

var _ajax2 = _interopRequireDefault(_ajax);

var _modal = require("../../../../services/modal");

var _modal2 = _interopRequireDefault(_modal);

var _snackbar = require("../../../../services/snackbar");

var _snackbar2 = _interopRequireDefault(_snackbar);

var _store = require("../../../../services/store");

var _store2 = _interopRequireDefault(_store);

var _validators = require("../../../../utils/validators");

var validators = _interopRequireWildcard(_validators);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var PostingConfig = exports.PostingConfig = function (_React$Component) {
  _inherits(PostingConfig, _React$Component);

  function PostingConfig(props) {
    _classCallCheck(this, PostingConfig);

    var _this = _possibleConstructorReturn(this, (PostingConfig.__proto__ || Object.getPrototypeOf(PostingConfig)).call(this, props));

    _this.state = {
      isLoaded: false,
      isError: false,

      categories: []
    };
    return _this;
  }

  _createClass(PostingConfig, [{
    key: "componentDidMount",
    value: function componentDidMount() {
      var _this2 = this;

      _ajax2.default.get(misago.get("THREAD_EDITOR_API")).then(function (data) {
        // hydrate categories, extract posting options
        var categories = data.map(function (item) {
          return Object.assign(item, {
            disabled: item.post === false,
            label: item.name,
            value: item.id,
            post: item.post
          });
        });

        _this2.setState({
          isLoaded: true,
          categories: categories
        });
      }, function (rejection) {
        _this2.setState({
          isError: rejection.detail
        });
      });
    }
  }, {
    key: "render",
    value: function render() {
      if (this.state.isError) {
        return _react2.default.createElement(Error, { message: this.state.isError });
      } else if (this.state.isLoaded) {
        return _react2.default.createElement(ModerationForm, _extends({}, this.props, { categories: this.state.categories }));
      } else {
        return _react2.default.createElement(Loader, null);
      }
    }
  }]);

  return PostingConfig;
}(_react2.default.Component);

var ModerationForm = exports.ModerationForm = function (_Form) {
  _inherits(ModerationForm, _Form);

  function ModerationForm(props) {
    _classCallCheck(this, ModerationForm);

    var _this3 = _possibleConstructorReturn(this, (ModerationForm.__proto__ || Object.getPrototypeOf(ModerationForm)).call(this, props));

    _this3.onCategoryChange = function (ev) {
      var categoryId = ev.target.value;
      var newState = {
        category: categoryId
      };

      if (_this3.acl[categoryId].can_pin_threads < newState.weight) {
        newState.weight = 0;
      }

      if (!_this3.acl[categoryId].can_hide_threads) {
        newState.is_hidden = 0;
      }

      if (!_this3.acl[categoryId].can_close_threads) {
        newState.is_closed = false;
      }

      _this3.setState(newState);
    };

    _this3.state = {
      isLoading: false,

      title: "",
      category: null,
      categories: props.categories,
      weight: 0,
      is_hidden: 0,
      is_closed: false,

      validators: {
        title: [validators.required()]
      },

      errors: {}
    };

    _this3.isHiddenChoices = [{
      value: 0,
      icon: "visibility",
      label: gettext("No")
    }, {
      value: 1,
      icon: "visibility_off",
      label: gettext("Yes")
    }];

    _this3.isClosedChoices = [{
      value: false,
      icon: "lock_outline",
      label: gettext("No")
    }, {
      value: true,
      icon: "lock",
      label: gettext("Yes")
    }];

    _this3.acl = {};
    _this3.props.categories.forEach(function (category) {
      if (category.post) {
        if (!_this3.state.category) {
          _this3.state.category = category.id;
        }

        _this3.acl[category.id] = {
          can_pin_threads: category.post.pin,
          can_close_threads: category.post.close,
          can_hide_threads: category.post.hide
        };
      }
    });
    return _this3;
  }

  _createClass(ModerationForm, [{
    key: "clean",
    value: function clean() {
      if (this.isValid()) {
        return true;
      } else {
        _snackbar2.default.error(gettext("Form contains errors."));
        this.setState({
          errors: this.validate()
        });
        return false;
      }
    }
  }, {
    key: "send",
    value: function send() {
      return _ajax2.default.post(this.props.thread.api.posts.split, {
        title: this.state.title,
        category: this.state.category,
        weight: this.state.weight,
        is_hidden: this.state.is_hidden,
        is_closed: this.state.is_closed,
        posts: [this.props.post.id]
      });
    }
  }, {
    key: "handleSuccess",
    value: function handleSuccess(apiResponse) {
      _store2.default.dispatch(post.patch(this.props.post, {
        isDeleted: true
      }));

      _modal2.default.hide();

      _snackbar2.default.success(gettext("Selected post was split into new thread."));
    }
  }, {
    key: "handleError",
    value: function handleError(rejection) {
      if (rejection.status === 400) {
        this.setState({
          errors: Object.assign({}, this.state.errors, rejection)
        });
        _snackbar2.default.error(gettext("Form contains errors."));
      } else if (rejection.status === 403 && Array.isArray(rejection)) {
        _modal2.default.show(_react2.default.createElement(ErrorsModal, { errors: rejection }));
      } else {
        _snackbar2.default.apiError(rejection);
      }
    }
  }, {
    key: "getWeightChoices",
    value: function getWeightChoices() {
      var choices = [{
        value: 0,
        icon: "remove",
        label: gettext("Not pinned")
      }, {
        value: 1,
        icon: "bookmark_border",
        label: gettext("Pinned locally")
      }];

      if (this.acl[this.state.category].can_pin_threads == 2) {
        choices.push({
          value: 2,
          icon: "bookmark",
          label: gettext("Pinned globally")
        });
      }

      return choices;
    }
  }, {
    key: "renderWeightField",
    value: function renderWeightField() {
      if (this.acl[this.state.category].can_pin_threads) {
        return _react2.default.createElement(
          _formGroup2.default,
          {
            label: gettext("Thread weight"),
            "for": "id_weight",
            labelClass: "col-sm-4",
            controlClass: "col-sm-8"
          },
          _react2.default.createElement(_select2.default, {
            id: "id_weight",
            onChange: this.bindInput("weight"),
            value: this.state.weight,
            choices: this.getWeightChoices()
          })
        );
      } else {
        return null;
      }
    }
  }, {
    key: "renderHiddenField",
    value: function renderHiddenField() {
      if (this.acl[this.state.category].can_hide_threads) {
        return _react2.default.createElement(
          _formGroup2.default,
          {
            label: gettext("Hide thread"),
            "for": "id_is_hidden",
            labelClass: "col-sm-4",
            controlClass: "col-sm-8"
          },
          _react2.default.createElement(_select2.default, {
            id: "id_is_closed",
            onChange: this.bindInput("is_hidden"),
            value: this.state.is_hidden,
            choices: this.isHiddenChoices
          })
        );
      } else {
        return null;
      }
    }
  }, {
    key: "renderClosedField",
    value: function renderClosedField() {
      if (this.acl[this.state.category].can_close_threads) {
        return _react2.default.createElement(
          _formGroup2.default,
          {
            label: gettext("Close thread"),
            "for": "id_is_closed",
            labelClass: "col-sm-4",
            controlClass: "col-sm-8"
          },
          _react2.default.createElement(_select2.default, {
            id: "id_is_closed",
            onChange: this.bindInput("is_closed"),
            value: this.state.is_closed,
            choices: this.isClosedChoices
          })
        );
      } else {
        return null;
      }
    }
  }, {
    key: "render",
    value: function render() {
      return _react2.default.createElement(
        Modal,
        { className: "modal-dialog" },
        _react2.default.createElement(
          "form",
          { onSubmit: this.handleSubmit },
          _react2.default.createElement(
            "div",
            { className: "modal-body" },
            _react2.default.createElement(
              _formGroup2.default,
              {
                label: gettext("Thread title"),
                "for": "id_title",
                labelClass: "col-sm-4",
                controlClass: "col-sm-8",
                validation: this.state.errors.title
              },
              _react2.default.createElement("input", {
                id: "id_title",
                className: "form-control",
                type: "text",
                onChange: this.bindInput("title"),
                value: this.state.title
              })
            ),
            _react2.default.createElement("div", { className: "clearfix" }),
            _react2.default.createElement(
              _formGroup2.default,
              {
                label: gettext("Category"),
                "for": "id_category",
                labelClass: "col-sm-4",
                controlClass: "col-sm-8",
                validation: this.state.errors.category
              },
              _react2.default.createElement(_categorySelect2.default, {
                id: "id_category",
                onChange: this.onCategoryChange,
                value: this.state.category,
                choices: this.state.categories
              })
            ),
            _react2.default.createElement("div", { className: "clearfix" }),
            this.renderWeightField(),
            this.renderHiddenField(),
            this.renderClosedField()
          ),
          _react2.default.createElement(
            "div",
            { className: "modal-footer" },
            _react2.default.createElement(
              _button2.default,
              { className: "btn-primary", loading: this.state.isLoading },
              gettext("Split post")
            )
          )
        )
      );
    }
  }]);

  return ModerationForm;
}(_form2.default);

function Loader() {
  return _react2.default.createElement(
    Modal,
    { className: "modal-dialog" },
    _react2.default.createElement(_modalLoader2.default, null)
  );
}

function Error(props) {
  return _react2.default.createElement(
    Modal,
    { className: "modal-dialog modal-message" },
    _react2.default.createElement(
      "div",
      { className: "message-icon" },
      _react2.default.createElement(
        "span",
        { className: "material-icon" },
        "info_outline"
      )
    ),
    _react2.default.createElement(
      "div",
      { className: "message-body" },
      _react2.default.createElement(
        "p",
        { className: "lead" },
        gettext("You can't move this post at the moment.")
      ),
      _react2.default.createElement(
        "p",
        null,
        props.message
      )
    )
  );
}

function Modal(props) {
  return _react2.default.createElement(
    "div",
    { className: props.className, role: "document" },
    _react2.default.createElement(
      "div",
      { className: "modal-content" },
      _react2.default.createElement(
        "div",
        { className: "modal-header" },
        _react2.default.createElement(
          "button",
          {
            "aria-label": gettext("Close"),
            className: "close",
            "data-dismiss": "modal",
            type: "button"
          },
          _react2.default.createElement(
            "span",
            { "aria-hidden": "true" },
            "\xD7"
          )
        ),
        _react2.default.createElement(
          "h4",
          { className: "modal-title" },
          gettext("Split post into new thread")
        )
      ),
      props.children
    )
  );
}

},{"../../../../reducers/post":353,"../../../../services/ajax":365,"../../../../services/modal":371,"../../../../services/snackbar":376,"../../../../services/store":377,"../../../../utils/validators":393,"../../../button":8,"../../../category-select":21,"../../../form":56,"../../../form-group":55,"../../../modal-loader":61,"../../../select":210,"react":"react"}],160:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FlagBestAnswer = FlagBestAnswer;
exports.FlagHidden = FlagHidden;
exports.FlagUnapproved = FlagUnapproved;
exports.FlagProtected = FlagProtected;
exports.isVisible = isVisible;

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function FlagBestAnswer(_ref) {
  var post = _ref.post,
      thread = _ref.thread,
      user = _ref.user;

  if (!(isVisible(post) && post.id === thread.best_answer)) {
    return null;
  }

  var message = null;
  if (user.id && thread.best_answer_marked_by === user.id) {
    message = interpolate(gettext("Marked as best answer by you %(marked_on)s."), {
      marked_on: thread.best_answer_marked_on.fromNow()
    }, true);
  } else {
    message = interpolate(gettext("Marked as best answer by %(marked_by)s %(marked_on)s."), {
      marked_by: thread.best_answer_marked_by_name,
      marked_on: thread.best_answer_marked_on.fromNow()
    }, true);
  }

  return _react2.default.createElement(
    "div",
    { className: "post-status-message post-status-best-answer" },
    _react2.default.createElement(
      "span",
      { className: "material-icon" },
      "check_box"
    ),
    _react2.default.createElement(
      "p",
      null,
      message
    )
  );
}

function FlagHidden(props) {
  if (!(isVisible(props.post) && props.post.is_hidden)) {
    return null;
  }

  return _react2.default.createElement(
    "div",
    { className: "post-status-message post-status-hidden" },
    _react2.default.createElement(
      "span",
      { className: "material-icon" },
      "visibility_off"
    ),
    _react2.default.createElement(
      "p",
      null,
      gettext("This post is hidden. Only users with permission may see its contents.")
    )
  );
}

function FlagUnapproved(props) {
  if (!(isVisible(props.post) && props.post.is_unapproved)) {
    return null;
  }

  return _react2.default.createElement(
    "div",
    { className: "post-status-message post-status-unapproved" },
    _react2.default.createElement(
      "span",
      { className: "material-icon" },
      "remove_circle_outline"
    ),
    _react2.default.createElement(
      "p",
      null,
      gettext("This post is unapproved. Only users with permission to approve posts and its author may see its contents.")
    )
  );
}

function FlagProtected(props) {
  if (!(isVisible(props.post) && props.post.is_protected)) {
    return null;
  }

  return _react2.default.createElement(
    "div",
    { className: "post-status-message post-status-protected visible-xs-block" },
    _react2.default.createElement(
      "span",
      { className: "material-icon" },
      "lock_outline"
    ),
    _react2.default.createElement(
      "p",
      null,
      gettext("This post is protected. Only moderators may change it.")
    )
  );
}

function isVisible(post) {
  return !post.is_hidden || post.acl.can_see_hidden;
}

},{"react":"react"}],161:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Edit = exports.Reply = exports.LikesCompact = exports.Likes = exports.Like = exports.MarkAsBestAnswerCompact = exports.MarkAsBestAnswer = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

exports.default = function (props) {
  if (!isVisible(props.post)) return null;

  return _react2.default.createElement(
    "div",
    { className: "post-footer" },
    _react2.default.createElement(MarkAsBestAnswer, props),
    _react2.default.createElement(MarkAsBestAnswerCompact, props),
    _react2.default.createElement(Like, props),
    _react2.default.createElement(Likes, _extends({
      lastLikes: props.post.last_likes,
      likes: props.post.likes
    }, props)),
    _react2.default.createElement(LikesCompact, _extends({ likes: props.post.likes }, props)),
    _react2.default.createElement(Reply, props),
    _react2.default.createElement(Edit, props)
  );
};

exports.isVisible = isVisible;
exports.getLikesMessage = getLikesMessage;

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _actions = require("./controls/actions");

var actions = _interopRequireWildcard(_actions);

var _postLikes = require("../../post-likes");

var _postLikes2 = _interopRequireDefault(_postLikes);

var _modal = require("../../../services/modal");

var _modal2 = _interopRequireDefault(_modal);

var _posting = require("../../../services/posting");

var _posting2 = _interopRequireDefault(_posting);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

function isVisible(post) {
  return (!post.is_hidden || post.acl.can_see_hidden) && (post.acl.can_reply || post.acl.can_edit || post.acl.can_see_likes && (post.last_likes || []).length || post.acl.can_like);
}

var MarkAsBestAnswer = exports.MarkAsBestAnswer = function (_React$Component) {
  _inherits(MarkAsBestAnswer, _React$Component);

  function MarkAsBestAnswer() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, MarkAsBestAnswer);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = MarkAsBestAnswer.__proto__ || Object.getPrototypeOf(MarkAsBestAnswer)).call.apply(_ref, [this].concat(args))), _this), _this.onClick = function () {
      actions.markAsBestAnswer(_this.props);
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(MarkAsBestAnswer, [{
    key: "render",
    value: function render() {
      var _props = this.props,
          post = _props.post,
          thread = _props.thread;


      if (!thread.acl.can_mark_best_answer) return null;
      if (!post.acl.can_mark_as_best_answer) return null;
      if (thread.best_answer && !thread.acl.can_change_best_answer) return null;

      return _react2.default.createElement(
        "button",
        {
          className: "hidden-xs btn btn-default btn-sm pull-left",
          disabled: this.props.post.isBusy || post.id === thread.best_answer,
          onClick: this.onClick,
          type: "button"
        },
        _react2.default.createElement(
          "span",
          { className: "material-icon" },
          "check_box"
        ),
        gettext("Best answer")
      );
    }
  }]);

  return MarkAsBestAnswer;
}(_react2.default.Component);

var MarkAsBestAnswerCompact = exports.MarkAsBestAnswerCompact = function (_React$Component2) {
  _inherits(MarkAsBestAnswerCompact, _React$Component2);

  function MarkAsBestAnswerCompact() {
    var _ref2;

    var _temp2, _this2, _ret2;

    _classCallCheck(this, MarkAsBestAnswerCompact);

    for (var _len2 = arguments.length, args = Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
      args[_key2] = arguments[_key2];
    }

    return _ret2 = (_temp2 = (_this2 = _possibleConstructorReturn(this, (_ref2 = MarkAsBestAnswerCompact.__proto__ || Object.getPrototypeOf(MarkAsBestAnswerCompact)).call.apply(_ref2, [this].concat(args))), _this2), _this2.onClick = function () {
      actions.markAsBestAnswer(_this2.props);
    }, _temp2), _possibleConstructorReturn(_this2, _ret2);
  }

  _createClass(MarkAsBestAnswerCompact, [{
    key: "render",
    value: function render() {
      var _props2 = this.props,
          post = _props2.post,
          thread = _props2.thread;


      if (!thread.acl.can_mark_best_answer) return null;
      if (!post.acl.can_mark_as_best_answer) return null;
      if (thread.best_answer && !thread.acl.can_change_best_answer) return null;

      return _react2.default.createElement(
        "button",
        {
          className: "visible-xs-inline-block btn btn-default btn-sm pull-left",
          disabled: this.props.post.isBusy || post.id === thread.best_answer,
          onClick: this.onClick,
          type: "button"
        },
        _react2.default.createElement(
          "span",
          { className: "material-icon" },
          "check_box"
        )
      );
    }
  }]);

  return MarkAsBestAnswerCompact;
}(_react2.default.Component);

var Like = exports.Like = function (_React$Component3) {
  _inherits(Like, _React$Component3);

  function Like() {
    var _ref3;

    var _temp3, _this3, _ret3;

    _classCallCheck(this, Like);

    for (var _len3 = arguments.length, args = Array(_len3), _key3 = 0; _key3 < _len3; _key3++) {
      args[_key3] = arguments[_key3];
    }

    return _ret3 = (_temp3 = (_this3 = _possibleConstructorReturn(this, (_ref3 = Like.__proto__ || Object.getPrototypeOf(Like)).call.apply(_ref3, [this].concat(args))), _this3), _this3.onClick = function () {
      if (_this3.props.post.is_liked) {
        actions.unlike(_this3.props);
      } else {
        actions.like(_this3.props);
      }
    }, _temp3), _possibleConstructorReturn(_this3, _ret3);
  }

  _createClass(Like, [{
    key: "render",
    value: function render() {
      if (!this.props.post.acl.can_like) return null;

      var className = "btn btn-default btn-sm pull-left";
      if (this.props.post.is_liked) {
        className = "btn btn-success btn-sm pull-left";
      }

      return _react2.default.createElement(
        "button",
        {
          className: className,
          disabled: this.props.post.isBusy,
          onClick: this.onClick,
          type: "button"
        },
        this.props.post.is_liked ? gettext("Liked") : gettext("Like")
      );
    }
  }]);

  return Like;
}(_react2.default.Component);

var Likes = exports.Likes = function (_React$Component4) {
  _inherits(Likes, _React$Component4);

  function Likes() {
    var _ref4;

    var _temp4, _this4, _ret4;

    _classCallCheck(this, Likes);

    for (var _len4 = arguments.length, args = Array(_len4), _key4 = 0; _key4 < _len4; _key4++) {
      args[_key4] = arguments[_key4];
    }

    return _ret4 = (_temp4 = (_this4 = _possibleConstructorReturn(this, (_ref4 = Likes.__proto__ || Object.getPrototypeOf(Likes)).call.apply(_ref4, [this].concat(args))), _this4), _this4.onClick = function () {
      _modal2.default.show(_react2.default.createElement(_postLikes2.default, { post: _this4.props.post }));
    }, _temp4), _possibleConstructorReturn(_this4, _ret4);
  }

  _createClass(Likes, [{
    key: "render",
    value: function render() {
      var hasLikes = (this.props.post.last_likes || []).length > 0;
      if (!this.props.post.acl.can_see_likes || !hasLikes) return null;

      if (this.props.post.acl.can_see_likes === 2) {
        return _react2.default.createElement(
          "button",
          {
            className: "btn btn-link btn-sm pull-left hidden-xs",
            onClick: this.onClick,
            type: "button"
          },
          getLikesMessage(this.props.likes, this.props.lastLikes)
        );
      }

      return _react2.default.createElement(
        "p",
        { className: "pull-left hidden-xs" },
        getLikesMessage(this.props.likes, this.props.lastLikes)
      );
    }
  }]);

  return Likes;
}(_react2.default.Component);

var LikesCompact = exports.LikesCompact = function (_Likes) {
  _inherits(LikesCompact, _Likes);

  function LikesCompact() {
    _classCallCheck(this, LikesCompact);

    return _possibleConstructorReturn(this, (LikesCompact.__proto__ || Object.getPrototypeOf(LikesCompact)).apply(this, arguments));
  }

  _createClass(LikesCompact, [{
    key: "render",
    value: function render() {
      var hasLikes = (this.props.post.last_likes || []).length > 0;
      if (!this.props.post.acl.can_see_likes || !hasLikes) return null;

      if (this.props.post.acl.can_see_likes === 2) {
        return _react2.default.createElement(
          "button",
          {
            className: "btn btn-link btn-sm likes-compact pull-left visible-xs-block",
            onClick: this.onClick,
            type: "button"
          },
          _react2.default.createElement(
            "span",
            { className: "material-icon" },
            "favorite"
          ),
          this.props.likes
        );
      }

      return _react2.default.createElement(
        "p",
        { className: "likes-compact pull-left visible-xs-block" },
        _react2.default.createElement(
          "span",
          { className: "material-icon" },
          "favorite"
        ),
        this.props.likes
      );
    }
  }]);

  return LikesCompact;
}(Likes);

function getLikesMessage(likes, users) {
  var usernames = users.slice(0, 3).map(function (u) {
    return u.username;
  });

  if (usernames.length == 1) {
    return interpolate(gettext("%(user)s likes this."), {
      user: usernames[0]
    }, true);
  }

  var hiddenLikes = likes - usernames.length;

  var otherUsers = usernames.slice(0, -1).join(", ");
  var lastUser = usernames.slice(-1)[0];

  var usernamesList = interpolate(gettext("%(users)s and %(last_user)s"), {
    users: otherUsers,
    last_user: lastUser
  }, true);

  if (hiddenLikes === 0) {
    return interpolate(gettext("%(users)s like this."), {
      users: usernamesList
    }, true);
  }

  var message = ngettext("%(users)s and %(likes)s other user like this.", "%(users)s and %(likes)s other users like this.", hiddenLikes);

  return interpolate(message, {
    users: usernames.join(", "),
    likes: hiddenLikes
  }, true);
}

var Reply = exports.Reply = function (_React$Component5) {
  _inherits(Reply, _React$Component5);

  function Reply() {
    var _ref5;

    var _temp5, _this6, _ret5;

    _classCallCheck(this, Reply);

    for (var _len5 = arguments.length, args = Array(_len5), _key5 = 0; _key5 < _len5; _key5++) {
      args[_key5] = arguments[_key5];
    }

    return _ret5 = (_temp5 = (_this6 = _possibleConstructorReturn(this, (_ref5 = Reply.__proto__ || Object.getPrototypeOf(Reply)).call.apply(_ref5, [this].concat(args))), _this6), _this6.onClick = function () {
      _posting2.default.open({
        mode: "REPLY",

        config: _this6.props.thread.api.editor,
        submit: _this6.props.thread.api.posts.index,

        context: {
          reply: _this6.props.post.id
        }
      });
    }, _temp5), _possibleConstructorReturn(_this6, _ret5);
  }

  _createClass(Reply, [{
    key: "render",
    value: function render() {
      if (this.props.post.acl.can_reply) {
        return _react2.default.createElement(
          "button",
          {
            className: "btn btn-primary btn-sm pull-right",
            type: "button",
            onClick: this.onClick
          },
          gettext("Reply")
        );
      } else {
        return null;
      }
    }
  }]);

  return Reply;
}(_react2.default.Component);

var Edit = exports.Edit = function (_React$Component6) {
  _inherits(Edit, _React$Component6);

  function Edit() {
    var _ref6;

    var _temp6, _this7, _ret6;

    _classCallCheck(this, Edit);

    for (var _len6 = arguments.length, args = Array(_len6), _key6 = 0; _key6 < _len6; _key6++) {
      args[_key6] = arguments[_key6];
    }

    return _ret6 = (_temp6 = (_this7 = _possibleConstructorReturn(this, (_ref6 = Edit.__proto__ || Object.getPrototypeOf(Edit)).call.apply(_ref6, [this].concat(args))), _this7), _this7.onClick = function () {
      _posting2.default.open({
        mode: "EDIT",

        config: _this7.props.post.api.editor,
        submit: _this7.props.post.api.index
      });
    }, _temp6), _possibleConstructorReturn(_this7, _ret6);
  }

  _createClass(Edit, [{
    key: "render",
    value: function render() {
      if (this.props.post.acl.can_edit) {
        return _react2.default.createElement(
          "button",
          {
            className: "hidden-xs btn btn-default btn-sm pull-right",
            type: "button",
            onClick: this.onClick
          },
          gettext("Edit")
        );
      } else {
        return null;
      }
    }
  }]);

  return Edit;
}(_react2.default.Component);

},{"../../../services/modal":371,"../../../services/posting":375,"../../post-likes":132,"./controls/actions":155,"react":"react"}],162:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PostEditsCompacts = exports.PostEdits = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

exports.default = function (props) {
  return _react2.default.createElement(
    "div",
    { className: "post-heading" },
    _react2.default.createElement(UnreadLabel, props),
    _react2.default.createElement(UnreadCompact, props),
    _react2.default.createElement(PostedOn, props),
    _react2.default.createElement(PostedOnCompact, props),
    _react2.default.createElement(PostEdits, props),
    _react2.default.createElement(PostEditsCompacts, props),
    _react2.default.createElement(ProtectedLabel, props),
    _react2.default.createElement(_select2.default, props),
    _react2.default.createElement(_controls2.default, props)
  );
};

exports.UnreadLabel = UnreadLabel;
exports.UnreadCompact = UnreadCompact;
exports.PostedOn = PostedOn;
exports.PostedOnCompact = PostedOnCompact;
exports.ProtectedLabel = ProtectedLabel;

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _controls = require("./controls");

var _controls2 = _interopRequireDefault(_controls);

var _select = require("./select");

var _select2 = _interopRequireDefault(_select);

var _userStatus = require("../../user-status");

var _postChangelog = require("../../post-changelog");

var _postChangelog2 = _interopRequireDefault(_postChangelog);

var _modal = require("../../../services/modal");

var _modal2 = _interopRequireDefault(_modal);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

function UnreadLabel(props) {
  if (props.post.is_read) return null;

  return _react2.default.createElement(
    "span",
    { className: "label label-unread hidden-xs" },
    gettext("New post")
  );
}

function UnreadCompact(props) {
  if (props.post.is_read) return null;

  return _react2.default.createElement(
    "span",
    { className: "label label-unread visible-xs-inline-block" },
    gettext("New")
  );
}

function PostedOn(props) {
  var tooltip = interpolate(gettext("posted %(posted_on)s"), {
    posted_on: props.post.posted_on.format("LL, LT")
  }, true);

  return _react2.default.createElement(
    "a",
    {
      href: props.post.url.index,
      className: "btn btn-link posted-on hidden-xs",
      title: tooltip
    },
    props.post.posted_on.fromNow()
  );
}

function PostedOnCompact(props) {
  return _react2.default.createElement(
    "a",
    {
      href: props.post.url.index,
      className: "btn btn-link posted-on visible-xs-inline-block"
    },
    props.post.posted_on.fromNow(true)
  );
}

var PostEdits = exports.PostEdits = function (_React$Component) {
  _inherits(PostEdits, _React$Component);

  function PostEdits() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, PostEdits);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = PostEdits.__proto__ || Object.getPrototypeOf(PostEdits)).call.apply(_ref, [this].concat(args))), _this), _this.onClick = function () {
      _modal2.default.show(_react2.default.createElement(_postChangelog2.default, { post: _this.props.post }));
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(PostEdits, [{
    key: "render",
    value: function render() {
      var isHidden = this.props.post.is_hidden && !this.props.post.acl.can_see_hidden;
      var isUnedited = this.props.post.edits === 0;
      if (isHidden || isUnedited) return null;

      var tooltip = ngettext("This post was edited %(edits)s time.", "This post was edited %(edits)s times.", this.props.post.edits);

      var title = interpolate(tooltip, {
        edits: this.props.post.edits
      }, true);

      var label = ngettext("edited %(edits)s time", "edited %(edits)s times", this.props.post.edits);

      return _react2.default.createElement(
        "button",
        {
          className: "btn btn-link btn-see-edits hidden-xs",
          onClick: this.onClick,
          title: title,
          type: "button"
        },
        interpolate(label, {
          edits: this.props.post.edits
        }, true)
      );
    }
  }]);

  return PostEdits;
}(_react2.default.Component);

var PostEditsCompacts = exports.PostEditsCompacts = function (_PostEdits) {
  _inherits(PostEditsCompacts, _PostEdits);

  function PostEditsCompacts() {
    _classCallCheck(this, PostEditsCompacts);

    return _possibleConstructorReturn(this, (PostEditsCompacts.__proto__ || Object.getPrototypeOf(PostEditsCompacts)).apply(this, arguments));
  }

  _createClass(PostEditsCompacts, [{
    key: "render",
    value: function render() {
      var isHidden = this.props.post.is_hidden && !this.props.post.acl.can_see_hidden;
      var isUnedited = this.props.post.edits === 0;
      if (isHidden || isUnedited) return null;

      var label = ngettext("%(edits)s edit", "%(edits)s edits", this.props.post.edits);

      return _react2.default.createElement(
        "button",
        {
          className: "btn btn-link btn-see-edits visible-xs-inline-block",
          onClick: this.onClick,
          type: "button"
        },
        interpolate(label, {
          edits: this.props.post.edits
        }, true)
      );
    }
  }]);

  return PostEditsCompacts;
}(PostEdits);

function ProtectedLabel(props) {
  var postAuthor = props.post.poster && props.post.poster.id === props.user.id;
  var hasAcl = props.post.acl.can_protect;
  var isVisible = props.user.id && props.post.is_protected && (postAuthor || hasAcl);

  if (!isVisible) {
    return null;
  }

  return _react2.default.createElement(
    "span",
    {
      className: "label label-protected hidden-xs",
      title: gettext("This post is protected and may not be edited.")
    },
    _react2.default.createElement(
      "span",
      { className: "material-icon" },
      "lock_outline"
    ),
    gettext("protected")
  );
}

},{"../../../services/modal":371,"../../post-changelog":119,"../../user-status":274,"./controls":157,"./select":172,"react":"react"}],163:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (props) {
  var className = "post";
  if (props.post.isDeleted) {
    className = "hide";
  } else if (props.post.is_hidden && !props.post.acl.can_see_hidden) {
    className = "post post-hidden";
  }

  if (props.post.poster && props.post.poster.rank.css_class) {
    className += " post-" + props.post.poster.rank.css_class;
  }

  if (!props.post.is_read) {
    className += " post-new";
  }

  return _react2.default.createElement(
    "li",
    { id: "post-" + props.post.id, className: className },
    _react2.default.createElement(
      "div",
      { className: "panel panel-default panel-post" },
      _react2.default.createElement(
        "div",
        { className: "panel-body" },
        _react2.default.createElement(
          "div",
          { className: "row" },
          _react2.default.createElement(_postSide2.default, props),
          _react2.default.createElement(
            "div",
            { className: "col-xs-12 col-md-9" },
            _react2.default.createElement(_header2.default, props),
            _react2.default.createElement(_flags.FlagBestAnswer, props),
            _react2.default.createElement(_flags.FlagUnapproved, props),
            _react2.default.createElement(_flags.FlagProtected, props),
            _react2.default.createElement(_flags.FlagHidden, props),
            _react2.default.createElement(_body2.default, props),
            _react2.default.createElement(_attachments2.default, props),
            _react2.default.createElement(_footer2.default, props)
          )
        )
      )
    )
  );
};

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _attachments = require("./attachments");

var _attachments2 = _interopRequireDefault(_attachments);

var _body = require("./body");

var _body2 = _interopRequireDefault(_body);

var _flags = require("./flags");

var _footer = require("./footer");

var _footer2 = _interopRequireDefault(_footer);

var _header = require("./header");

var _header2 = _interopRequireDefault(_header);

var _postSide = require("./post-side");

var _postSide2 = _interopRequireDefault(_postSide);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

},{"./attachments":153,"./body":154,"./flags":160,"./footer":161,"./header":162,"./post-side":166,"react":"react"}],164:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (_ref) {
  var post = _ref.post,
      thread = _ref.thread;

  return _react2.default.createElement(
    "div",
    { className: "col-xs-12 col-md-3 post-side post-side-anonymous" },
    _react2.default.createElement(_select2.default, { post: post, thread: thread }),
    _react2.default.createElement(_controls2.default, { post: post, thread: thread }),
    _react2.default.createElement(
      "div",
      { className: "media" },
      _react2.default.createElement(
        "div",
        { className: "media-left" },
        _react2.default.createElement(
          "span",
          null,
          _react2.default.createElement(_avatar2.default, { className: "poster-avatar", size: 100 })
        )
      ),
      _react2.default.createElement(
        "div",
        { className: "media-body" },
        _react2.default.createElement(
          "span",
          { className: "media-heading item-title" },
          post.poster_name
        ),
        _react2.default.createElement(
          "span",
          { className: "user-title user-title-anonymous" },
          gettext("Removed user")
        )
      )
    )
  );
};

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _avatar = require("../../../avatar");

var _avatar2 = _interopRequireDefault(_avatar);

var _controls = require("../controls");

var _controls2 = _interopRequireDefault(_controls);

var _select = require("../select");

var _select2 = _interopRequireDefault(_select);

var _userStatus = require("../../../user-status");

var _userStatus2 = _interopRequireDefault(_userStatus);

var _userPostcount = require("./user-postcount");

var _userPostcount2 = _interopRequireDefault(_userPostcount);

var _userTitle = require("./user-title");

var _userTitle2 = _interopRequireDefault(_userTitle);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

},{"../../../avatar":6,"../../../user-status":274,"../controls":157,"../select":172,"./user-postcount":168,"./user-title":170,"react":"react"}],165:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (_ref) {
  var title = _ref.title,
      rank = _ref.rank;

  return rank.is_tab || !!title || !!rank.title;
};

},{}],166:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (props) {
  if (props.post.poster) {
    return _react2.default.createElement(_registered2.default, props);
  }

  return _react2.default.createElement(_anonymous2.default, props);
};

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _anonymous = require("./anonymous");

var _anonymous2 = _interopRequireDefault(_anonymous);

var _registered = require("./registered");

var _registered2 = _interopRequireDefault(_registered);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

},{"./anonymous":164,"./registered":167,"react":"react"}],167:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (_ref) {
  var post = _ref.post,
      thread = _ref.thread;
  var poster = post.poster;


  return _react2.default.createElement(
    "div",
    { className: "col-xs-12 col-md-3 post-side post-side-registered" },
    _react2.default.createElement(_select2.default, { post: post, thread: thread }),
    _react2.default.createElement(_controls2.default, { post: post, thread: thread }),
    _react2.default.createElement(
      "div",
      { className: "media" },
      _react2.default.createElement(
        "div",
        { className: "media-left" },
        _react2.default.createElement(
          "a",
          { href: poster.url },
          _react2.default.createElement(_avatar2.default, { className: "poster-avatar", size: 100, user: poster })
        )
      ),
      _react2.default.createElement(
        "div",
        { className: "media-body" },
        _react2.default.createElement(
          "div",
          { className: "media-heading" },
          _react2.default.createElement(
            "a",
            { className: "item-title", href: poster.url },
            poster.username
          ),
          _react2.default.createElement(
            _userStatus2.default,
            { status: poster.status },
            _react2.default.createElement(_userStatus.StatusIcon, { status: poster.status })
          )
        ),
        _react2.default.createElement(_userTitle2.default, { rank: poster.rank, title: poster.title }),
        _react2.default.createElement(_userStatus4.default, { poster: poster }),
        _react2.default.createElement(_userPostcount2.default, { poster: poster })
      )
    )
  );
};

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _avatar = require("../../../avatar");

var _avatar2 = _interopRequireDefault(_avatar);

var _controls = require("../controls");

var _controls2 = _interopRequireDefault(_controls);

var _select = require("../select");

var _select2 = _interopRequireDefault(_select);

var _userStatus = require("../../../user-status");

var _userStatus2 = _interopRequireDefault(_userStatus);

var _userPostcount = require("./user-postcount");

var _userPostcount2 = _interopRequireDefault(_userPostcount);

var _userStatus3 = require("./user-status");

var _userStatus4 = _interopRequireDefault(_userStatus3);

var _userTitle = require("./user-title");

var _userTitle2 = _interopRequireDefault(_userTitle);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

},{"../../../avatar":6,"../../../user-status":274,"../controls":157,"../select":172,"./user-postcount":168,"./user-status":169,"./user-title":170,"react":"react"}],168:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (_ref) {
  var poster = _ref.poster;

  var message = ngettext("%(posts)s post", "%(posts)s posts", poster.posts);

  var className = "user-postcount";
  if ((0, _hasVisibleTitle2.default)(poster)) {
    className += " hidden-xs hidden-sm";
  }

  return _react2.default.createElement(
    "span",
    { className: className },
    interpolate(message, {
      posts: poster.posts
    }, true)
  );
};

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _hasVisibleTitle = require("./has-visible-title");

var _hasVisibleTitle2 = _interopRequireDefault(_hasVisibleTitle);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

},{"./has-visible-title":165,"react":"react"}],169:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (_ref) {
  var poster = _ref.poster;

  var className = "hidden-xs";
  if ((0, _hasVisibleTitle2.default)(poster)) {
    className += " hidden-sm";
  }

  return _react2.default.createElement(
    "span",
    { className: className },
    _react2.default.createElement(
      _userStatus2.default,
      { status: poster.status },
      _react2.default.createElement(_userStatus.StatusLabel, { status: poster.status, user: poster })
    )
  );
};

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _userStatus = require("../../../user-status");

var _userStatus2 = _interopRequireDefault(_userStatus);

var _hasVisibleTitle = require("./has-visible-title");

var _hasVisibleTitle2 = _interopRequireDefault(_hasVisibleTitle);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

},{"../../../user-status":274,"./has-visible-title":165,"react":"react"}],170:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (_ref) {
  var rank = _ref.rank,
      title = _ref.title;

  var userTitle = title || rank.title;
  if (!userTitle && rank.is_tab) {
    userTitle = rank.name;
  }

  if (!userTitle) return null;

  var className = "user-title";
  if (rank.css_class) {
    className += " user-title-" + rank.css_class;
  }

  if (rank.is_tab) {
    return _react2.default.createElement(
      "div",
      { className: className },
      _react2.default.createElement(
        "a",
        { href: rank.url },
        userTitle
      )
    );
  }

  return _react2.default.createElement(
    "div",
    { className: className },
    userTitle
  );
};

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

},{"react":"react"}],171:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (props) {
  return _react2.default.createElement(
    "li",
    { className: "post" },
    _react2.default.createElement(
      "div",
      { className: "post-border" },
      _react2.default.createElement(
        "div",
        { className: "post-avatar" },
        _react2.default.createElement(_avatar2.default, { size: "100" })
      ),
      _react2.default.createElement(
        "div",
        { className: "post-body" },
        _react2.default.createElement(
          "div",
          { className: "panel panel-default panel-post" },
          _react2.default.createElement(
            "div",
            { className: "panel-heading post-heading" },
            _react2.default.createElement(
              "span",
              {
                className: "ui-preview-text",
                style: { width: random.int(30, 100) + "px" }
              },
              "\xA0"
            ),
            _react2.default.createElement(
              "span",
              {
                className: "ui-preview-text",
                style: { width: random.int(30, 100) + "px" }
              },
              "\xA0"
            )
          ),
          _react2.default.createElement(
            "div",
            { className: "panel-body" },
            _react2.default.createElement(
              "article",
              { className: "misago-markup" },
              _react2.default.createElement(
                "p",
                {
                  className: "ui-preview-text",
                  style: { width: random.int(50, 100) + "%" }
                },
                "\xA0"
              ),
              _react2.default.createElement(
                "p",
                {
                  className: "ui-preview-text",
                  style: { width: random.int(50, 100) + "%" }
                },
                "\xA0"
              ),
              _react2.default.createElement(
                "p",
                {
                  className: "ui-preview-text",
                  style: { width: random.int(50, 100) + "%" }
                },
                "\xA0"
              )
            )
          )
        )
      )
    )
  );
};

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _avatar = require("../../avatar");

var _avatar2 = _interopRequireDefault(_avatar);

var _random = require("../../../utils/random");

var random = _interopRequireWildcard(_random);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

},{"../../../utils/random":388,"../../avatar":6,"react":"react"}],172:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

exports.isVisible = isVisible;

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _posts = require("../../../reducers/posts");

var posts = _interopRequireWildcard(_posts);

var _store = require("../../../services/store");

var _store2 = _interopRequireDefault(_store);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_React$Component) {
  _inherits(_class, _React$Component);

  function _class() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, _class);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = _class.__proto__ || Object.getPrototypeOf(_class)).call.apply(_ref, [this].concat(args))), _this), _this.onClick = function () {
      if (_this.props.post.isSelected) {
        _store2.default.dispatch(posts.deselect(_this.props.post));
      } else {
        _store2.default.dispatch(posts.select(_this.props.post));
      }
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(_class, [{
    key: "render",
    value: function render() {
      if (!(this.props.thread.acl.can_merge_posts || isVisible(this.props.post.acl))) {
        return null;
      }

      return _react2.default.createElement(
        "div",
        { className: "pull-right hidden-xs" },
        _react2.default.createElement(
          "button",
          {
            className: "btn btn-default btn-icon",
            onClick: this.onClick,
            type: "button"
          },
          _react2.default.createElement(
            "span",
            { className: "material-icon" },
            this.props.post.isSelected ? "check_box" : "check_box_outline_blank"
          )
        )
      );
    }
  }]);

  return _class;
}(_react2.default.Component);

exports.default = _class;
function isVisible(acl) {
  return acl.can_approve || acl.can_hide || acl.can_protect || acl.can_unhide || acl.can_delete || acl.can_move;
}

},{"../../../reducers/posts":354,"../../../services/store":377,"react":"react"}],173:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _post = require("../../reducers/post");

var post = _interopRequireWildcard(_post);

var _thread = require("../../reducers/thread");

var thread = _interopRequireWildcard(_thread);

var _ajax = require("../../services/ajax");

var _ajax2 = _interopRequireDefault(_ajax);

var _snackbar = require("../../services/snackbar");

var _snackbar2 = _interopRequireDefault(_snackbar);

var _store = require("../../services/store");

var _store2 = _interopRequireDefault(_store);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_React$Component) {
  _inherits(_class, _React$Component);

  function _class() {
    _classCallCheck(this, _class);

    return _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).apply(this, arguments));
  }

  _createClass(_class, [{
    key: "componentDidMount",

    /*
    Super naive and de-facto placeholder implementation for reading posts on scroll
    */
    value: function componentDidMount() {
      var _this2 = this;

      if (this.props.post.is_read) return; // don't register read tracker

      $(this.documentNode).waypoint({
        handler: function handler(direction) {
          if (direction !== "down" || _this2.props.post.is_read) return;

          // after 1500ms run flag post as read logic
          window.setTimeout(function () {
            // check if post's bottom edge is still in viewport
            var boundingClientRect = _this2.documentNode.getBoundingClientRect();
            var offsetBottom = boundingClientRect.height + boundingClientRect.top;
            var clientHeight = document.documentElement.clientHeight;

            if (offsetBottom < 5) return; // scrolled past the post
            if (offsetBottom > clientHeight) return; // scrolled back up

            // mark post as read
            _store2.default.dispatch(post.patch(_this2.props.post, {
              is_read: true
            }));

            // call API to let it know we have unread post
            _ajax2.default.post(_this2.props.post.api.read).then(function (data) {
              _store2.default.dispatch(thread.update(_this2.props.thread, {
                is_read: data.thread_is_read
              }));
            }, function (rejection) {
              _snackbar2.default.apiError(rejection);
            });
          }, 1000);
        },
        offset: "bottom-in-view"
      });
    }
  }, {
    key: "render",
    value: function render() {
      var _this3 = this;

      return _react2.default.createElement(
        "div",
        {
          className: this.props.className,
          ref: function ref(node) {
            _this3.documentNode = node;
          }
        },
        this.props.children
      );
    }
  }]);

  return _class;
}(_react2.default.Component);

exports.default = _class;

},{"../../reducers/post":353,"../../reducers/thread":360,"../../services/ajax":365,"../../services/snackbar":376,"../../services/store":377,"react":"react"}],174:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _moment = require("moment");

var _moment2 = _interopRequireDefault(_moment);

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _panelLoader = require("../panel-loader");

var _panelLoader2 = _interopRequireDefault(_panelLoader);

var _panelMessage = require("../panel-message");

var _panelMessage2 = _interopRequireDefault(_panelMessage);

var _index = require("../../index");

var _index2 = _interopRequireDefault(_index);

var _polls = require("../../services/polls");

var _polls2 = _interopRequireDefault(_polls);

var _pageTitle = require("../../services/page-title");

var _pageTitle2 = _interopRequireDefault(_pageTitle);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_React$Component) {
  _inherits(_class, _React$Component);

  function _class(props) {
    _classCallCheck(this, _class);

    var _this = _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).call(this, props));

    _this.update = function (ban) {
      if (ban.expires_on) {
        ban.expires_on = (0, _moment2.default)(ban.expires_on);
      }

      _this.setState({
        isLoaded: true,
        error: null,

        ban: ban
      });
    };

    _this.error = function (error) {
      _this.setState({
        isLoaded: true,
        error: error.detail,
        ban: null
      });
    };

    if (_index2.default.has("PROFILE_BAN")) {
      _this.initWithPreloadedData(_index2.default.pop("PROFILE_BAN"));
    } else {
      _this.initWithoutPreloadedData();
    }

    _this.startPolling(props.profile.api.ban);
    return _this;
  }

  _createClass(_class, [{
    key: "initWithPreloadedData",
    value: function initWithPreloadedData(ban) {
      if (ban.expires_on) {
        ban.expires_on = (0, _moment2.default)(ban.expires_on);
      }

      this.state = {
        isLoaded: true,
        ban: ban
      };
    }
  }, {
    key: "initWithoutPreloadedData",
    value: function initWithoutPreloadedData() {
      this.state = {
        isLoaded: false
      };
    }
  }, {
    key: "startPolling",
    value: function startPolling(api) {
      _polls2.default.start({
        poll: "ban-details",
        url: api,
        frequency: 90 * 1000,
        update: this.update,
        error: this.error
      });
    }
  }, {
    key: "componentDidMount",
    value: function componentDidMount() {
      _pageTitle2.default.set({
        title: gettext("Ban details"),
        parent: this.props.profile.username
      });
    }
  }, {
    key: "componentWillUnmount",
    value: function componentWillUnmount() {
      _polls2.default.stop("ban-details");
    }
  }, {
    key: "getUserMessage",
    value: function getUserMessage() {
      if (this.state.ban.user_message) {
        return _react2.default.createElement(
          "div",
          { className: "panel-body ban-message ban-user-message" },
          _react2.default.createElement(
            "h4",
            null,
            gettext("User-shown ban message")
          ),
          _react2.default.createElement("div", {
            className: "lead",
            dangerouslySetInnerHTML: {
              __html: this.state.ban.user_message.html
            }
          })
        );
      } else {
        return null;
      }
    }
  }, {
    key: "getStaffMessage",
    value: function getStaffMessage() {
      if (this.state.ban.staff_message) {
        return _react2.default.createElement(
          "div",
          { className: "panel-body ban-message ban-staff-message" },
          _react2.default.createElement(
            "h4",
            null,
            gettext("Team-shown ban message")
          ),
          _react2.default.createElement("div", {
            className: "lead",
            dangerouslySetInnerHTML: {
              __html: this.state.ban.staff_message.html
            }
          })
        );
      } else {
        return null;
      }
    }
  }, {
    key: "getExpirationMessage",
    value: function getExpirationMessage() {
      if (this.state.ban.expires_on) {
        if (this.state.ban.expires_on.isAfter((0, _moment2.default)())) {
          var _title = interpolate(gettext("This ban expires on %(expires_on)s."), {
            expires_on: this.state.ban.expires_on.format("LL, LT")
          }, true);

          var message = interpolate(gettext("This ban expires %(expires_on)s."), {
            expires_on: this.state.ban.expires_on.fromNow()
          }, true);

          return _react2.default.createElement(
            "abbr",
            { title: _title },
            message
          );
        } else {
          return gettext("This ban has expired.");
        }
      } else {
        return interpolate(gettext("%(username)s's ban is permanent."), {
          username: this.props.profile.username
        }, true);
      }
    }
  }, {
    key: "getPanelBody",
    value: function getPanelBody() {
      if (this.state.ban) {
        if (Object.keys(this.state.ban).length) {
          return _react2.default.createElement(
            "div",
            null,
            this.getUserMessage(),
            this.getStaffMessage(),
            _react2.default.createElement(
              "div",
              { className: "panel-body ban-expires" },
              _react2.default.createElement(
                "h4",
                null,
                gettext("Ban expiration")
              ),
              _react2.default.createElement(
                "p",
                { className: "lead" },
                this.getExpirationMessage()
              )
            )
          );
        } else {
          return _react2.default.createElement(
            "div",
            null,
            _react2.default.createElement(_panelMessage2.default, {
              message: gettext("No ban is active at the moment.")
            })
          );
        }
      } else if (this.state.error) {
        return _react2.default.createElement(
          "div",
          null,
          _react2.default.createElement(_panelMessage2.default, { icon: "error_outline", message: this.state.error })
        );
      } else {
        return _react2.default.createElement(
          "div",
          null,
          _react2.default.createElement(_panelLoader2.default, null)
        );
      }
    }
  }, {
    key: "render",
    value: function render() {
      return _react2.default.createElement(
        "div",
        { className: "profile-ban-details" },
        _react2.default.createElement(
          "div",
          { className: "panel panel-default" },
          _react2.default.createElement(
            "div",
            { className: "panel-heading" },
            _react2.default.createElement(
              "h3",
              { className: "panel-title" },
              gettext("Ban details")
            )
          ),
          this.getPanelBody()
        )
      );
    }
  }]);

  return _class;
}(_react2.default.Component);

exports.default = _class;

},{"../../index":302,"../../services/page-title":373,"../../services/polls":374,"../panel-loader":93,"../panel-message":94,"moment":"moment","react":"react"}],175:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (_ref) {
  var isAuthenticated = _ref.isAuthenticated,
      profile = _ref.profile;

  var message = null;
  if (isAuthenticated) {
    message = gettext("You are not sharing any details with others.");
  } else {
    message = interpolate(gettext("%(username)s is not sharing any details with others."), {
      username: profile.username
    }, true);
  }

  return _react2.default.createElement(
    "div",
    { className: "panel panel-default" },
    _react2.default.createElement(
      "div",
      { className: "panel-body text-center lead" },
      message
    )
  );
};

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

},{"react":"react"}],176:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (_ref) {
  var html = _ref.html,
      text = _ref.text,
      url = _ref.url;

  if (html) {
    return _react2.default.createElement("div", {
      className: "form-control-static col-md-9",
      dangerouslySetInnerHTML: { __html: html }
    });
  }

  return _react2.default.createElement(
    "div",
    { className: "form-control-static col-md-9" },
    _react2.default.createElement(SafeValue, { text: text, url: url })
  );
};

exports.SafeValue = SafeValue;

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function SafeValue(_ref2) {
  var text = _ref2.text,
      url = _ref2.url;

  if (url) {
    return _react2.default.createElement(
      "p",
      null,
      _react2.default.createElement(
        "a",
        { href: url, target: "_blank", rel: "nofollow" },
        text || url
      )
    );
  }

  if (text) {
    return _react2.default.createElement(
      "p",
      null,
      text
    );
  }

  return null;
}

},{"react":"react"}],177:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (props) {
  return _react2.default.createElement(
    "div",
    { className: "form-group" },
    _react2.default.createElement(
      "strong",
      { className: "control-label col-md-3" },
      props.name,
      ":"
    ),
    _react2.default.createElement(_fieldValue2.default, props)
  );
};

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _fieldValue = require("./field-value");

var _fieldValue2 = _interopRequireDefault(_fieldValue);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

},{"./field-value":176,"react":"react"}],178:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (_ref) {
  var api = _ref.api,
      display = _ref.display,
      onCancel = _ref.onCancel,
      onSuccess = _ref.onSuccess;

  if (!display) return null;

  return _react2.default.createElement(_editDetails2.default, { api: api, onCancel: onCancel, onSuccess: onSuccess });
};

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _editDetails = require("../../edit-details");

var _editDetails2 = _interopRequireDefault(_editDetails);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

},{"../../edit-details":32,"react":"react"}],179:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (_ref) {
  var fields = _ref.fields,
      name = _ref.name;

  return _react2.default.createElement(
    "div",
    { className: "panel panel-default panel-profile-details-group" },
    _react2.default.createElement(
      "div",
      { className: "panel-heading" },
      _react2.default.createElement(
        "h3",
        { className: "panel-title" },
        name
      )
    ),
    _react2.default.createElement(
      "div",
      { className: "panel-body" },
      _react2.default.createElement(
        "div",
        { className: "form-horizontal" },
        fields.map(function (_ref2) {
          var fieldname = _ref2.fieldname,
              html = _ref2.html,
              name = _ref2.name,
              text = _ref2.text,
              url = _ref2.url;

          return _react2.default.createElement(_field2.default, {
            key: fieldname,
            name: name,
            html: html,
            text: text,
            url: url
          });
        })
      )
    )
  );
};

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _field = require("./field");

var _field2 = _interopRequireDefault(_field);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

},{"./field":177,"react":"react"}],180:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (_ref) {
  var display = _ref.display,
      groups = _ref.groups,
      isAuthenticated = _ref.isAuthenticated,
      loading = _ref.loading,
      profile = _ref.profile;

  if (!display) return null;

  if (loading) {
    return _react2.default.createElement(_loader2.default, null);
  }

  if (!groups.length) {
    return _react2.default.createElement(_emptyMessage2.default, { isAuthenticated: isAuthenticated, profile: profile });
  }

  return _react2.default.createElement(
    "div",
    null,
    groups.map(function (group, i) {
      return _react2.default.createElement(_group2.default, { fields: group.fields, key: i, name: group.name });
    })
  );
};

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _emptyMessage = require("./empty-message");

var _emptyMessage2 = _interopRequireDefault(_emptyMessage);

var _group = require("./group");

var _group2 = _interopRequireDefault(_group);

var _loader = require("../../loader");

var _loader2 = _interopRequireDefault(_loader);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

},{"../../loader":58,"./empty-message":175,"./group":179,"react":"react"}],181:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (_ref) {
  var onEdit = _ref.onEdit,
      showEditButton = _ref.showEditButton;

  return _react2.default.createElement(
    "div",
    null,
    _react2.default.createElement(
      "nav",
      { className: "toolbar" },
      _react2.default.createElement(
        "div",
        { className: "row" },
        _react2.default.createElement(
          "div",
          { className: "col-sm-8 col-md-10" },
          _react2.default.createElement(
            "h3",
            { className: "md-margin-top-no" },
            gettext("Details")
          )
        ),
        _react2.default.createElement(EditButton, { onEdit: onEdit, showEditButton: showEditButton })
      )
    )
  );
};

exports.EditButton = EditButton;

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function EditButton(_ref2) {
  var onEdit = _ref2.onEdit,
      showEditButton = _ref2.showEditButton;

  if (!showEditButton) return null;

  return _react2.default.createElement(
    "div",
    { className: "col-sm-4 col-md-2" },
    _react2.default.createElement(
      "button",
      {
        className: "btn btn-default btn-outline btn-block",
        onClick: onEdit,
        type: "button"
      },
      gettext("Edit")
    )
  );
}

},{"react":"react"}],182:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _form = require("./form");

var _form2 = _interopRequireDefault(_form);

var _groupsList = require("./groups-list");

var _groupsList2 = _interopRequireDefault(_groupsList);

var _header = require("./header");

var _header2 = _interopRequireDefault(_header);

var _profileDetails = require("../../../data/profile-details");

var _profileDetails2 = _interopRequireDefault(_profileDetails);

var _profileDetails3 = require("../../../reducers/profile-details");

var _pageTitle = require("../../../services/page-title");

var _pageTitle2 = _interopRequireDefault(_pageTitle);

var _snackbar = require("../../../services/snackbar");

var _snackbar2 = _interopRequireDefault(_snackbar);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_React$Component) {
  _inherits(_class, _React$Component);

  function _class(props) {
    _classCallCheck(this, _class);

    var _this = _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).call(this, props));

    _this.onCancel = function () {
      _this.setState({ editing: false });
    };

    _this.onEdit = function () {
      _this.setState({ editing: true });
    };

    _this.onSuccess = function (newDetails) {
      var _this$props = _this.props,
          dispatch = _this$props.dispatch,
          isAuthenticated = _this$props.isAuthenticated,
          profile = _this$props.profile;


      var message = null;
      if (isAuthenticated) {
        message = gettext("Your details have been updated.");
      } else {
        message = interpolate(gettext("%(username)s's details have been updated."), {
          username: profile.username
        }, true);
      }

      _snackbar2.default.info(message);
      dispatch((0, _profileDetails3.load)(newDetails));
      _this.setState({ editing: false });
    };

    _this.state = {
      editing: false
    };
    return _this;
  }

  _createClass(_class, [{
    key: "componentDidMount",
    value: function componentDidMount() {
      _pageTitle2.default.set({
        title: gettext("Details"),
        parent: this.props.profile.username
      });
    }
  }, {
    key: "render",
    value: function render() {
      var _props = this.props,
          dispatch = _props.dispatch,
          isAuthenticated = _props.isAuthenticated,
          profile = _props.profile,
          profileDetails = _props.profileDetails;

      var loading = profileDetails.id !== profile.id;

      return _react2.default.createElement(
        _profileDetails2.default,
        {
          data: profileDetails,
          dispatch: dispatch,
          user: profile
        },
        _react2.default.createElement(
          "div",
          { className: "profile-details" },
          _react2.default.createElement(_header2.default, {
            onEdit: this.onEdit,
            showEditButton: !!profileDetails.edit && !this.state.editing
          }),
          _react2.default.createElement(_groupsList2.default, {
            display: !this.state.editing,
            groups: profileDetails.groups,
            isAuthenticated: isAuthenticated,
            loading: loading,
            profile: profile
          }),
          _react2.default.createElement(_form2.default, {
            api: profile.api.edit_details,
            dispatch: dispatch,
            display: this.state.editing,
            onCancel: this.onCancel,
            onSuccess: this.onSuccess
          })
        )
      );
    }
  }]);

  return _class;
}(_react2.default.Component);

exports.default = _class;

},{"../../../data/profile-details":301,"../../../reducers/profile-details":355,"../../../services/page-title":373,"../../../services/snackbar":376,"./form":178,"./groups-list":180,"./header":181,"react":"react"}],183:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

exports.Threads = Threads;
exports.Posts = Posts;

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _route = require("./route");

var _route2 = _interopRequireDefault(_route);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function Threads(props) {
  var emptyMessage = null;
  if (props.user.id === props.profile.id) {
    emptyMessage = gettext("You have no started threads.");
  } else {
    emptyMessage = interpolate(gettext("%(username)s started no threads."), {
      username: props.profile.username
    }, true);
  }

  var header = null;
  if (!props.posts.isLoaded) {
    header = gettext("Loading...");
  } else if (props.profile.id === props.user.id) {
    var message = ngettext("You have started %(threads)s thread.", "You have started %(threads)s threads.", props.profile.threads);

    header = interpolate(message, {
      threads: props.profile.threads
    }, true);
  } else {
    var _message = ngettext("%(username)s has started %(threads)s thread.", "%(username)s has started %(threads)s threads.", props.profile.threads);

    header = interpolate(_message, {
      username: props.profile.username,
      threads: props.profile.threads
    }, true);
  }

  return _react2.default.createElement(_route2.default, _extends({
    api: props.profile.api.threads,
    emptyMessage: emptyMessage,
    header: header,
    title: gettext("Threads")
  }, props));
}

function Posts(props) {
  var emptyMessage = null;
  if (props.user.id === props.profile.id) {
    emptyMessage = gettext("You have posted no messages.");
  } else {
    emptyMessage = interpolate(gettext("%(username)s posted no messages."), {
      username: props.profile.username
    }, true);
  }

  var header = null;
  if (!props.posts.isLoaded) {
    header = gettext("Loading...");
  } else if (props.profile.id === props.user.id) {
    var message = ngettext("You have posted %(posts)s message.", "You have posted %(posts)s messages.", props.profile.posts);

    header = interpolate(message, {
      posts: props.profile.posts
    }, true);
  } else {
    var _message2 = ngettext("%(username)s has posted %(posts)s message.", "%(username)s has posted %(posts)s messages.", props.profile.posts);

    header = interpolate(_message2, {
      username: props.profile.username,
      posts: props.profile.posts
    }, true);
  }

  return _react2.default.createElement(_route2.default, _extends({
    api: props.profile.api.posts,
    emptyMessage: emptyMessage,
    header: header,
    title: gettext("Posts")
  }, props));
}

},{"./route":184,"react":"react"}],184:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

exports.Feed = Feed;
exports.LoadMoreButton = LoadMoreButton;

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _postFeed = require("../../post-feed");

var _postFeed2 = _interopRequireDefault(_postFeed);

var _button = require("../../button");

var _button2 = _interopRequireDefault(_button);

var _posts = require("../../../reducers/posts");

var posts = _interopRequireWildcard(_posts);

var _pageTitle = require("../../../services/page-title");

var _pageTitle2 = _interopRequireDefault(_pageTitle);

var _ajax = require("../../../services/ajax");

var _ajax2 = _interopRequireDefault(_ajax);

var _snackbar = require("../../../services/snackbar");

var _snackbar2 = _interopRequireDefault(_snackbar);

var _store = require("../../../services/store");

var _store2 = _interopRequireDefault(_store);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_React$Component) {
  _inherits(_class, _React$Component);

  function _class(props) {
    _classCallCheck(this, _class);

    var _this = _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).call(this, props));

    _this.loadMore = function () {
      _this.setState({
        isLoading: true
      });

      _this.loadItems(_this.props.posts.next);
    };

    _this.state = {
      isLoading: false
    };
    return _this;
  }

  _createClass(_class, [{
    key: "loadItems",
    value: function loadItems() {
      var _this2 = this;

      var start = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;

      _ajax2.default.get(this.props.api, {
        start: start || 0
      }).then(function (data) {
        if (start === 0) {
          _store2.default.dispatch(posts.load(data));
        } else {
          _store2.default.dispatch(posts.append(data));
        }

        _this2.setState({
          isLoading: false
        });
      }, function (rejection) {
        _this2.setState({
          isLoading: false
        });

        _snackbar2.default.apiError(rejection);
      });
    }
  }, {
    key: "componentDidMount",
    value: function componentDidMount() {
      _pageTitle2.default.set({
        title: this.props.title,
        parent: this.props.profile.username
      });

      this.loadItems();
    }
  }, {
    key: "render",
    value: function render() {
      return _react2.default.createElement(
        "div",
        { className: "profile-feed" },
        _react2.default.createElement(
          "nav",
          { className: "toolbar" },
          _react2.default.createElement(
            "h3",
            { className: "toolbar-left" },
            this.props.header
          )
        ),
        _react2.default.createElement(Feed, _extends({
          isLoading: this.state.isLoading,
          loadMore: this.loadMore
        }, this.props))
      );
    }
  }]);

  return _class;
}(_react2.default.Component);

exports.default = _class;
function Feed(props) {
  if (!props.posts.results.length) {
    return _react2.default.createElement(
      "p",
      { className: "lead" },
      props.emptyMessage
    );
  }

  return _react2.default.createElement(
    "div",
    null,
    _react2.default.createElement(_postFeed2.default, {
      isReady: props.posts.isLoaded,
      posts: props.posts.results,
      poster: props.profile
    }),
    _react2.default.createElement(LoadMoreButton, {
      isLoading: props.isLoading,
      loadMore: props.loadMore,
      next: props.posts.next
    })
  );
}

function LoadMoreButton(props) {
  if (!props.next) return null;

  return _react2.default.createElement(
    "div",
    { className: "pager-more" },
    _react2.default.createElement(
      _button2.default,
      {
        className: "btn btn-default btn-outline",
        loading: props.isLoading,
        onClick: props.loadMore
      },
      gettext("Show older activity")
    )
  );
}

},{"../../../reducers/posts":354,"../../../services/ajax":365,"../../../services/page-title":373,"../../../services/snackbar":376,"../../../services/store":377,"../../button":8,"../../post-feed":122,"react":"react"}],185:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _button = require("../button");

var _button2 = _interopRequireDefault(_button);

var _profile = require("../../reducers/profile");

var _ajax = require("../../services/ajax");

var _ajax2 = _interopRequireDefault(_ajax);

var _snackbar = require("../../services/snackbar");

var _snackbar2 = _interopRequireDefault(_snackbar);

var _store = require("../../services/store");

var _store2 = _interopRequireDefault(_store);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_React$Component) {
  _inherits(_class, _React$Component);

  function _class(props) {
    _classCallCheck(this, _class);

    var _this = _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).call(this, props));

    _this.action = function () {
      _this.setState({
        isLoading: true
      });

      if (_this.props.profile.is_followed) {
        _store2.default.dispatch((0, _profile.patch)({
          is_followed: false,
          followers: _this.props.profile.followers - 1
        }));
      } else {
        _store2.default.dispatch((0, _profile.patch)({
          is_followed: true,
          followers: _this.props.profile.followers + 1
        }));
      }

      _ajax2.default.post(_this.props.profile.api.follow).then(function (data) {
        _this.setState({
          isLoading: false
        });

        _store2.default.dispatch((0, _profile.patch)(data));
      }, function (rejection) {
        _this.setState({
          isLoading: false
        });
        _snackbar2.default.apiError(rejection);
      });
    };

    _this.state = {
      isLoading: false
    };
    return _this;
  }

  _createClass(_class, [{
    key: "getClassName",
    value: function getClassName() {
      if (this.props.profile.is_followed) {
        return this.props.className + " btn-default btn-following";
      } else {
        return this.props.className + " btn-default btn-follow";
      }
    }
  }, {
    key: "getIcon",
    value: function getIcon() {
      if (this.props.profile.is_followed) {
        return "favorite";
      } else {
        return "favorite_border";
      }
    }
  }, {
    key: "getLabel",
    value: function getLabel() {
      if (this.props.profile.is_followed) {
        return gettext("Following");
      } else {
        return gettext("Follow");
      }
    }
  }, {
    key: "render",
    value: function render() {
      return _react2.default.createElement(
        _button2.default,
        {
          className: this.getClassName(),
          disabled: this.state.isLoading,
          onClick: this.action
        },
        _react2.default.createElement(
          "span",
          { className: "material-icon" },
          this.getIcon()
        ),
        this.getLabel()
      );
    }
  }]);

  return _class;
}(_react2.default.Component);

exports.default = _class;

},{"../../reducers/profile":356,"../../services/ajax":365,"../../services/snackbar":376,"../../services/store":377,"../button":8,"react":"react"}],186:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _button = require("../button");

var _button2 = _interopRequireDefault(_button);

var _quickSearch = require("../quick-search");

var _quickSearch2 = _interopRequireDefault(_quickSearch);

var _usersList = require("../users-list");

var _usersList2 = _interopRequireDefault(_usersList);

var _index = require("../../index");

var _index2 = _interopRequireDefault(_index);

var _users = require("../../reducers/users");

var _ajax = require("../../services/ajax");

var _ajax2 = _interopRequireDefault(_ajax);

var _snackbar = require("../../services/snackbar");

var _snackbar2 = _interopRequireDefault(_snackbar);

var _store = require("../../services/store");

var _store2 = _interopRequireDefault(_store);

var _pageTitle = require("../../services/page-title");

var _pageTitle2 = _interopRequireDefault(_pageTitle);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_React$Component) {
  _inherits(_class, _React$Component);

  function _class(props) {
    _classCallCheck(this, _class);

    var _this = _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).call(this, props));

    _this.loadMore = function () {
      _this.setState({
        isBusy: true
      });

      _this.loadUsers(_this.state.page + 1, _this.state.search);
    };

    _this.search = function (ev) {
      _this.setState({
        isLoaded: false,
        isBusy: true,

        search: ev.target.value,

        count: 0,
        more: 0,

        page: 1,
        pages: 1
      });

      _this.loadUsers(1, ev.target.value);
    };

    _this.setSpecialProps();

    if (_index2.default.has(_this.PRELOADED_DATA_KEY)) {
      _this.initWithPreloadedData(_index2.default.pop(_this.PRELOADED_DATA_KEY));
    } else {
      _this.initWithoutPreloadedData();
    }
    return _this;
  }

  _createClass(_class, [{
    key: "setSpecialProps",
    value: function setSpecialProps() {
      this.PRELOADED_DATA_KEY = "PROFILE_FOLLOWERS";
      this.TITLE = gettext("Followers");
      this.API_FILTER = "followers";
    }
  }, {
    key: "initWithPreloadedData",
    value: function initWithPreloadedData(data) {
      this.state = {
        isLoaded: true,
        isBusy: false,

        search: "",

        count: data.count,
        more: data.more,

        page: data.page,
        pages: data.pages
      };

      _store2.default.dispatch((0, _users.hydrate)(data.results));
    }
  }, {
    key: "initWithoutPreloadedData",
    value: function initWithoutPreloadedData() {
      this.state = {
        isLoaded: false,
        isBusy: false,

        search: "",

        count: 0,
        more: 0,

        page: 1,
        pages: 1
      };

      this.loadUsers();
    }
  }, {
    key: "loadUsers",
    value: function loadUsers() {
      var _this2 = this;

      var page = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;
      var search = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

      var apiUrl = this.props.profile.api[this.API_FILTER];

      _ajax2.default.get(apiUrl, {
        search: search,
        page: page || 1
      }, "user-" + this.API_FILTER).then(function (data) {
        if (page === 1) {
          _store2.default.dispatch((0, _users.hydrate)(data.results));
        } else {
          _store2.default.dispatch((0, _users.append)(data.results));
        }

        _this2.setState({
          isLoaded: true,
          isBusy: false,

          count: data.count,
          more: data.more,

          page: data.page,
          pages: data.pages
        });
      }, function (rejection) {
        _snackbar2.default.apiError(rejection);
      });
    }
  }, {
    key: "componentDidMount",
    value: function componentDidMount() {
      _pageTitle2.default.set({
        title: this.TITLE,
        parent: this.props.profile.username
      });
    }
  }, {
    key: "getLabel",
    value: function getLabel() {
      if (!this.state.isLoaded) {
        return gettext("Loading...");
      } else if (this.state.search) {
        var message = ngettext("Found %(users)s user.", "Found %(users)s users.", this.state.count);

        return interpolate(message, {
          users: this.state.count
        }, true);
      } else if (this.props.profile.id === this.props.user.id) {
        var _message = ngettext("You have %(users)s follower.", "You have %(users)s followers.", this.state.count);

        return interpolate(_message, {
          users: this.state.count
        }, true);
      } else {
        var _message2 = ngettext("%(username)s has %(users)s follower.", "%(username)s has %(users)s followers.", this.state.count);

        return interpolate(_message2, {
          username: this.props.profile.username,
          users: this.state.count
        }, true);
      }
    }
  }, {
    key: "getEmptyMessage",
    value: function getEmptyMessage() {
      if (this.state.search) {
        return gettext("Search returned no users matching specified criteria.");
      } else if (this.props.user.id === this.props.profile.id) {
        return gettext("You have no followers.");
      } else {
        return interpolate(gettext("%(username)s has no followers."), {
          username: this.props.profile.username
        }, true);
      }
    }
  }, {
    key: "getMoreButton",
    value: function getMoreButton() {
      if (!this.state.more) return null;

      return _react2.default.createElement(
        "div",
        { className: "pager-more" },
        _react2.default.createElement(
          _button2.default,
          {
            className: "btn btn-default btn-outline",
            loading: this.state.isBusy,
            onClick: this.loadMore
          },
          interpolate(gettext("Show more (%(more)s)"), {
            more: this.state.more
          }, true)
        )
      );
    }
  }, {
    key: "getListBody",
    value: function getListBody() {
      if (this.state.isLoaded && this.state.count === 0) {
        return _react2.default.createElement(
          "p",
          { className: "lead" },
          this.getEmptyMessage()
        );
      }

      return _react2.default.createElement(
        "div",
        null,
        _react2.default.createElement(_usersList2.default, {
          cols: 3,
          isReady: this.state.isLoaded,
          users: this.props.users
        }),
        this.getMoreButton()
      );
    }
  }, {
    key: "getClassName",
    value: function getClassName() {
      return "profile-" + this.API_FILTER;
    }
  }, {
    key: "render",
    value: function render() {
      return _react2.default.createElement(
        "div",
        { className: this.getClassName() },
        _react2.default.createElement(
          "nav",
          { className: "toolbar" },
          _react2.default.createElement(
            "h3",
            { className: "toolbar-left" },
            this.getLabel()
          ),
          _react2.default.createElement(_quickSearch2.default, {
            className: "toolbar-right",
            value: this.state.search,
            onChange: this.search,
            placeholder: gettext("Search users...")
          })
        ),
        this.getListBody()
      );
    }
  }]);

  return _class;
}(_react2.default.Component);

exports.default = _class;

},{"../../index":302,"../../reducers/users":364,"../../services/ajax":365,"../../services/page-title":373,"../../services/snackbar":376,"../../services/store":377,"../button":8,"../quick-search":197,"../users-list":284,"react":"react"}],187:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _followers = require("./followers");

var _followers2 = _interopRequireDefault(_followers);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_Followers) {
  _inherits(_class, _Followers);

  function _class() {
    _classCallCheck(this, _class);

    return _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).apply(this, arguments));
  }

  _createClass(_class, [{
    key: "setSpecialProps",
    value: function setSpecialProps() {
      this.PRELOADED_DATA_KEY = "PROFILE_FOLLOWS";
      this.TITLE = gettext("Follows");
      this.API_FILTER = "follows";
    }
  }, {
    key: "getLabel",
    value: function getLabel() {
      if (!this.state.isLoaded) {
        return gettext("Loading...");
      } else if (this.state.search) {
        var message = ngettext("Found %(users)s user.", "Found %(users)s users.", this.state.count);

        return interpolate(message, {
          users: this.state.count
        }, true);
      } else if (this.props.profile.id === this.props.user.id) {
        var _message = ngettext("You are following %(users)s user.", "You are following %(users)s users.", this.state.count);

        return interpolate(_message, {
          users: this.state.count
        }, true);
      } else {
        var _message2 = ngettext("%(username)s is following %(users)s user.", "%(username)s is following %(users)s users.", this.state.count);

        return interpolate(_message2, {
          username: this.props.profile.username,
          users: this.state.count
        }, true);
      }
    }
  }, {
    key: "getEmptyMessage",
    value: function getEmptyMessage() {
      if (this.state.search) {
        return gettext("Search returned no users matching specified criteria.");
      } else if (this.props.user.id === this.props.profile.id) {
        return gettext("You are not following any users.");
      } else {
        return interpolate(gettext("%(username)s is not following any users."), {
          username: this.props.profile.username
        }, true);
      }
    }
  }]);

  return _class;
}(_followers2.default);

exports.default = _class;

},{"./followers":186,"react":"react"}],188:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

exports.IsDisabledMessage = IsDisabledMessage;
exports.getColStyle = getColStyle;

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _avatar = require("../avatar");

var _avatar2 = _interopRequireDefault(_avatar);

var _dropdownToggle = require("../dropdown-toggle");

var _dropdownToggle2 = _interopRequireDefault(_dropdownToggle);

var _followButton = require("./follow-button");

var _followButton2 = _interopRequireDefault(_followButton);

var _messageButton = require("./message-button");

var _messageButton2 = _interopRequireDefault(_messageButton);

var _nav = require("./moderation/nav");

var _nav2 = _interopRequireDefault(_nav);

var _navs = require("./navs");

var _userStatus = require("../user-status");

var _userStatus2 = _interopRequireDefault(_userStatus);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_React$Component) {
  _inherits(_class, _React$Component);

  function _class() {
    _classCallCheck(this, _class);

    return _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).apply(this, arguments));
  }

  _createClass(_class, [{
    key: "getUserStatus",
    value: function getUserStatus() {
      return _react2.default.createElement(
        "li",
        { className: "user-status-display" },
        _react2.default.createElement(
          _userStatus2.default,
          { user: this.props.profile, status: this.props.profile.status },
          _react2.default.createElement(_userStatus.StatusIcon, {
            user: this.props.profile,
            status: this.props.profile.status
          }),
          _react2.default.createElement(_userStatus.StatusLabel, {
            user: this.props.profile,
            status: this.props.profile.status,
            className: "status-label"
          })
        )
      );
    }
  }, {
    key: "getUserRank",
    value: function getUserRank() {
      if (this.props.profile.rank.is_tab) {
        return _react2.default.createElement(
          "li",
          { className: "user-rank" },
          _react2.default.createElement(
            "a",
            { href: this.props.profile.rank.url, className: "item-title" },
            this.props.profile.rank.name
          )
        );
      } else {
        return _react2.default.createElement(
          "li",
          { className: "user-rank" },
          _react2.default.createElement(
            "span",
            { className: "item-title" },
            this.props.profile.rank.name
          )
        );
      }
    }
  }, {
    key: "getUserTitle",
    value: function getUserTitle() {
      if (this.props.profile.title) {
        return _react2.default.createElement(
          "li",
          { className: "user-title" },
          this.props.profile.title
        );
      } else if (this.props.profile.rank.title) {
        return _react2.default.createElement(
          "li",
          { className: "user-title" },
          this.props.profile.rank.title
        );
      } else {
        return null;
      }
    }
  }, {
    key: "getJoinedOn",
    value: function getJoinedOn() {
      var title = interpolate(gettext("Joined on %(joined_on)s"), {
        joined_on: this.props.profile.joined_on.format("LL, LT")
      }, true);

      var age = interpolate(gettext("Joined %(joined_on)s"), {
        joined_on: this.props.profile.joined_on.fromNow()
      }, true);

      return _react2.default.createElement(
        "li",
        { className: "user-joined-on" },
        _react2.default.createElement(
          "abbr",
          { title: title },
          age
        )
      );
    }
  }, {
    key: "getEmail",
    value: function getEmail() {
      if (this.props.profile.email) {
        return _react2.default.createElement(
          "li",
          { className: "user-email" },
          _react2.default.createElement(
            "a",
            { href: "mailto:" + this.props.profile.email, className: "item-title" },
            this.props.profile.email
          )
        );
      } else {
        return null;
      }
    }
  }, {
    key: "getFollowButton",
    value: function getFollowButton() {
      if (this.props.profile.acl.can_follow) {
        return _react2.default.createElement(_followButton2.default, {
          className: "btn btn-block btn-outline",
          profile: this.props.profile
        });
      } else {
        return null;
      }
    }
  }, {
    key: "getModerationButton",
    value: function getModerationButton() {
      if (this.props.profile.acl.can_moderate) {
        return _react2.default.createElement(
          "div",
          { className: "btn-group btn-group-justified" },
          _react2.default.createElement(
            "div",
            { className: "btn-group" },
            _react2.default.createElement(
              "button",
              {
                className: "btn btn-default btn-moderate btn-outline dropdown-toggle",
                type: "button",
                "data-toggle": "dropdown",
                "aria-haspopup": "true",
                "aria-expanded": "false"
              },
              _react2.default.createElement(
                "span",
                { className: "material-icon" },
                "tonality"
              ),
              gettext("Moderation")
            ),
            _react2.default.createElement(_nav2.default, { profile: this.props.profile })
          )
        );
      } else {
        return null;
      }
    }
  }, {
    key: "render",
    value: function render() {
      var canFollow = this.props.profile.acl.can_follow;
      var canModerate = this.props.profile.acl.can_moderate;

      var isProfileOwner = this.props.user.id === this.props.profile.id;
      var canMessage = !isProfileOwner && this.props.user.acl.can_start_private_threads;

      var cols = 0;
      if (canFollow) cols += 1;
      if (canModerate) cols += 1;
      if (canMessage) cols += 1;

      var colsWidth = cols ? 2 * cols + 1 : 0;

      var headerClassName = "page-header";
      if (this.props.profile.rank.css_class) {
        headerClassName += " page-header-rank-" + this.props.profile.rank.css_class;
      }

      return _react2.default.createElement(
        "div",
        { className: "page-header-bg" },
        _react2.default.createElement(
          "div",
          { className: headerClassName },
          _react2.default.createElement(
            "div",
            { className: "container" },
            _react2.default.createElement(IsDisabledMessage, {
              isActive: this.props.profile.is_active,
              isDeletingAccount: this.props.profile.is_deleting_account
            }),
            _react2.default.createElement(
              "div",
              { className: "row" },
              _react2.default.createElement(
                "div",
                { className: "col-md-9 col-md-offset-3" },
                _react2.default.createElement(
                  "div",
                  { className: "row" },
                  _react2.default.createElement(
                    "div",
                    { className: "col-sm-" + (12 - colsWidth) },
                    _react2.default.createElement(_avatar2.default, {
                      className: "user-avatar user-avatar-sm",
                      user: this.props.profile,
                      size: "100",
                      size2x: "200"
                    }),
                    _react2.default.createElement(
                      "h1",
                      null,
                      this.props.profile.username
                    )
                  ),
                  !!cols && _react2.default.createElement(
                    "div",
                    { className: "col-sm-" + colsWidth },
                    _react2.default.createElement(
                      "div",
                      { className: "row xs-margin-top sm-margin-top" },
                      !!canMessage && _react2.default.createElement(
                        "div",
                        { className: getColStyle(cols, 0) },
                        _react2.default.createElement(_messageButton2.default, {
                          className: "btn btn-default btn-block btn-outline",
                          profile: this.props.profile,
                          user: this.props.user
                        })
                      ),
                      !!canFollow && _react2.default.createElement(
                        "div",
                        { className: getColStyle(cols, 1) },
                        this.getFollowButton()
                      ),
                      !!canModerate && _react2.default.createElement(
                        "div",
                        { className: getColStyle(cols, 2) },
                        this.getModerationButton()
                      )
                    )
                  )
                )
              )
            )
          ),
          _react2.default.createElement(
            "div",
            { className: "header-stats" },
            _react2.default.createElement(
              "div",
              { className: "container" },
              _react2.default.createElement(
                "div",
                { className: "row" },
                _react2.default.createElement(
                  "div",
                  { className: "col-md-9 col-md-offset-3" },
                  _react2.default.createElement(
                    "ul",
                    { className: "list-inline" },
                    this.getUserStatus(),
                    this.getUserRank(),
                    this.getUserTitle(),
                    this.getJoinedOn(),
                    this.getEmail()
                  )
                )
              )
            )
          ),
          _react2.default.createElement(_navs.CompactNav, {
            baseUrl: this.props.baseUrl,
            pages: this.props.pages,
            profile: this.props.profile
          })
        )
      );
    }
  }]);

  return _class;
}(_react2.default.Component);

exports.default = _class;
function IsDisabledMessage(_ref) {
  var isActive = _ref.isActive,
      isDeletingAccount = _ref.isDeletingAccount;

  if (isActive !== false && isDeletingAccount !== true) return null;

  var message = null;
  if (isDeletingAccount) {
    message = gettext("This user is deleting their account.");
  } else {
    message = gettext("This user's account has been disabled by administrator.");
  }

  return _react2.default.createElement(
    "div",
    { className: "alert alert-danger" },
    _react2.default.createElement(
      "p",
      null,
      message
    )
  );
}

function getColStyle(cols, col) {
  var colStyle = "";

  if (cols == 1) {
    colStyle = "col-xs-12";
  }

  if (cols == 2) {
    colStyle = "col-xs-6 col-sm-6";
  }

  if (cols == 3) {
    if (col == 2) {
      colStyle = "col-xs-12 col-sm-4 xs-margin-top";
    } else {
      colStyle += "col-xs-6 col-sm-4";
    }
  }

  return colStyle;
}

},{"../avatar":6,"../dropdown-toggle":27,"../user-status":274,"./follow-button":185,"./message-button":189,"./moderation/nav":193,"./navs":194,"react":"react"}],189:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _posting = require("../../services/posting");

var _posting2 = _interopRequireDefault(_posting);

var _ = require("../..");

var _2 = _interopRequireDefault(_);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_React$Component) {
  _inherits(_class, _React$Component);

  function _class() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, _class);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = _class.__proto__ || Object.getPrototypeOf(_class)).call.apply(_ref, [this].concat(args))), _this), _this.onClick = function () {
      _posting2.default.open({
        mode: "START_PRIVATE",
        submit: _2.default.get("PRIVATE_THREADS_API"),

        to: [_this.props.profile]
      });
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(_class, [{
    key: "render",
    value: function render() {
      var canMessage = this.props.user.acl.can_start_private_threads;
      var isProfileOwner = this.props.user.id === this.props.profile.id;

      if (!canMessage || isProfileOwner) return null;

      return _react2.default.createElement(
        "button",
        {
          className: this.props.className,
          onClick: this.onClick,
          type: "button"
        },
        _react2.default.createElement(
          "span",
          { className: "material-icon" },
          "comment"
        ),
        gettext("Message")
      );
    }
  }]);

  return _class;
}(_react2.default.Component);

exports.default = _class;

},{"../..":302,"../../services/posting":375,"react":"react"}],190:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _button = require("../../button");

var _button2 = _interopRequireDefault(_button);

var _form = require("../../form");

var _form2 = _interopRequireDefault(_form);

var _formGroup = require("../../form-group");

var _formGroup2 = _interopRequireDefault(_formGroup);

var _modalLoader = require("../../modal-loader");

var _modalLoader2 = _interopRequireDefault(_modalLoader);

var _yesNoSwitch = require("../../yes-no-switch");

var _yesNoSwitch2 = _interopRequireDefault(_yesNoSwitch);

var _modalMessage = require("../../modal-message");

var _modalMessage2 = _interopRequireDefault(_modalMessage);

var _users = require("../../../reducers/users");

var _ajax = require("../../../services/ajax");

var _ajax2 = _interopRequireDefault(_ajax);

var _snackbar = require("../../../services/snackbar");

var _snackbar2 = _interopRequireDefault(_snackbar);

var _store = require("../../../services/store");

var _store2 = _interopRequireDefault(_store);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_Form) {
  _inherits(_class, _Form);

  function _class(props) {
    _classCallCheck(this, _class);

    var _this = _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).call(this, props));

    _this.state = {
      isLoaded: false,
      isLoading: false,
      error: null,

      is_avatar_locked: "",
      avatar_lock_user_message: "",
      avatar_lock_staff_message: ""
    };
    return _this;
  }

  _createClass(_class, [{
    key: "componentDidMount",
    value: function componentDidMount() {
      var _this2 = this;

      _ajax2.default.get(this.props.profile.api.moderate_avatar).then(function (options) {
        _this2.setState({
          isLoaded: true,

          is_avatar_locked: options.is_avatar_locked,
          avatar_lock_user_message: options.avatar_lock_user_message || "",
          avatar_lock_staff_message: options.avatar_lock_staff_message || ""
        });
      }, function (rejection) {
        _this2.setState({
          isLoaded: true,
          error: rejection.detail
        });
      });
    }
  }, {
    key: "clean",
    value: function clean() {
      if (this.isValid()) {
        return true;
      } else {
        _snackbar2.default.error(this.validate().username[0]);
        return false;
      }
    }
  }, {
    key: "send",
    value: function send() {
      return _ajax2.default.post(this.props.profile.api.moderate_avatar, {
        is_avatar_locked: this.state.is_avatar_locked,
        avatar_lock_user_message: this.state.avatar_lock_user_message,
        avatar_lock_staff_message: this.state.avatar_lock_staff_message
      });
    }
  }, {
    key: "handleSuccess",
    value: function handleSuccess(apiResponse) {
      _store2.default.dispatch((0, _users.updateAvatar)(this.props.profile, apiResponse.avatar_hash));
      _snackbar2.default.success(gettext("Avatar controls have been changed."));
    }
  }, {
    key: "getFormBody",
    value: function getFormBody() {
      return _react2.default.createElement(
        "form",
        { onSubmit: this.handleSubmit },
        _react2.default.createElement(
          "div",
          { className: "modal-body" },
          _react2.default.createElement(
            _formGroup2.default,
            {
              label: gettext("Lock avatar"),
              helpText: gettext("Locking user avatar will prohibit user from changing his avatar and will reset his/her avatar to default one."),
              "for": "id_is_avatar_locked"
            },
            _react2.default.createElement(_yesNoSwitch2.default, {
              id: "id_is_avatar_locked",
              disabled: this.state.isLoading,
              iconOn: "lock_outline",
              iconOff: "lock_open",
              labelOn: gettext("Disallow user from changing avatar"),
              labelOff: gettext("Allow user to change avatar"),
              onChange: this.bindInput("is_avatar_locked"),
              value: this.state.is_avatar_locked
            })
          ),
          _react2.default.createElement(
            _formGroup2.default,
            {
              label: gettext("User message"),
              helpText: gettext("Optional message for user explaining why he/she is prohibited form changing avatar."),
              "for": "id_avatar_lock_user_message"
            },
            _react2.default.createElement("textarea", {
              id: "id_avatar_lock_user_message",
              className: "form-control",
              rows: "4",
              disabled: this.state.isLoading,
              onChange: this.bindInput("avatar_lock_user_message"),
              value: this.state.avatar_lock_user_message
            })
          ),
          _react2.default.createElement(
            _formGroup2.default,
            {
              label: gettext("Staff message"),
              helpText: gettext("Optional message for forum team members explaining why user is prohibited form changing avatar."),
              "for": "id_avatar_lock_staff_message"
            },
            _react2.default.createElement("textarea", {
              id: "id_avatar_lock_staff_message",
              className: "form-control",
              rows: "4",
              disabled: this.state.isLoading,
              onChange: this.bindInput("avatar_lock_staff_message"),
              value: this.state.avatar_lock_staff_message
            })
          )
        ),
        _react2.default.createElement(
          "div",
          { className: "modal-footer" },
          _react2.default.createElement(
            "button",
            {
              type: "button",
              className: "btn btn-default",
              "data-dismiss": "modal"
            },
            gettext("Close")
          ),
          _react2.default.createElement(
            _button2.default,
            { className: "btn-primary", loading: this.state.isLoading },
            gettext("Save changes")
          )
        )
      );
    }
  }, {
    key: "getModalBody",
    value: function getModalBody() {
      if (this.state.error) {
        return _react2.default.createElement(_modalMessage2.default, { icon: "remove_circle_outline", message: this.state.error });
      } else if (this.state.isLoaded) {
        return this.getFormBody();
      } else {
        return _react2.default.createElement(_modalLoader2.default, null);
      }
    }
  }, {
    key: "getClassName",
    value: function getClassName() {
      if (this.state.error) {
        return "modal-dialog modal-message modal-avatar-controls";
      } else {
        return "modal-dialog modal-avatar-controls";
      }
    }
  }, {
    key: "render",
    value: function render() {
      return _react2.default.createElement(
        "div",
        { className: this.getClassName(), role: "document" },
        _react2.default.createElement(
          "div",
          { className: "modal-content" },
          _react2.default.createElement(
            "div",
            { className: "modal-header" },
            _react2.default.createElement(
              "button",
              {
                type: "button",
                className: "close",
                "data-dismiss": "modal",
                "aria-label": gettext("Close")
              },
              _react2.default.createElement(
                "span",
                { "aria-hidden": "true" },
                "\xD7"
              )
            ),
            _react2.default.createElement(
              "h4",
              { className: "modal-title" },
              gettext("Avatar controls")
            )
          ),
          this.getModalBody()
        )
      );
    }
  }]);

  return _class;
}(_form2.default);

exports.default = _class;

},{"../../../reducers/users":364,"../../../services/ajax":365,"../../../services/snackbar":376,"../../../services/store":377,"../../button":8,"../../form":56,"../../form-group":55,"../../modal-loader":61,"../../modal-message":62,"../../yes-no-switch":300,"react":"react"}],191:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _button = require("../../button");

var _button2 = _interopRequireDefault(_button);

var _form = require("../../form");

var _form2 = _interopRequireDefault(_form);

var _formGroup = require("../../form-group");

var _formGroup2 = _interopRequireDefault(_formGroup);

var _modalLoader = require("../../modal-loader");

var _modalLoader2 = _interopRequireDefault(_modalLoader);

var _modalMessage = require("../../modal-message");

var _modalMessage2 = _interopRequireDefault(_modalMessage);

var _usernameHistory = require("../../../reducers/username-history");

var _users = require("../../../reducers/users");

var _ajax = require("../../../services/ajax");

var _ajax2 = _interopRequireDefault(_ajax);

var _snackbar = require("../../../services/snackbar");

var _snackbar2 = _interopRequireDefault(_snackbar);

var _store = require("../../../services/store");

var _store2 = _interopRequireDefault(_store);

var _validators = require("../../../utils/validators");

var validators = _interopRequireWildcard(_validators);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_Form) {
  _inherits(_class, _Form);

  function _class(props) {
    _classCallCheck(this, _class);

    var _this = _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).call(this, props));

    _this.state = {
      isLoaded: false,
      isLoading: false,
      error: null,

      username: "",
      validators: {
        username: [validators.usernameContent()]
      }
    };
    return _this;
  }

  _createClass(_class, [{
    key: "componentDidMount",
    value: function componentDidMount() {
      var _this2 = this;

      _ajax2.default.get(this.props.profile.api.moderate_username).then(function () {
        _this2.setState({
          isLoaded: true
        });
      }, function (rejection) {
        _this2.setState({
          isLoaded: true,
          error: rejection.detail
        });
      });
    }
  }, {
    key: "clean",
    value: function clean() {
      if (this.isValid()) {
        return true;
      } else {
        _snackbar2.default.error(this.validate().username[0]);
        return false;
      }
    }
  }, {
    key: "send",
    value: function send() {
      return _ajax2.default.post(this.props.profile.api.moderate_username, {
        username: this.state.username
      });
    }
  }, {
    key: "handleSuccess",
    value: function handleSuccess(apiResponse) {
      this.setState({
        username: ""
      });

      _store2.default.dispatch((0, _usernameHistory.addNameChange)(apiResponse, this.props.profile, this.props.user));
      _store2.default.dispatch((0, _users.updateUsername)(this.props.profile, apiResponse.username, apiResponse.slug));

      _snackbar2.default.success(gettext("Username has been changed."));
    }
  }, {
    key: "getFormBody",
    value: function getFormBody() {
      return _react2.default.createElement(
        "form",
        { onSubmit: this.handleSubmit },
        _react2.default.createElement(
          "div",
          { className: "modal-body" },
          _react2.default.createElement(
            _formGroup2.default,
            { label: gettext("New username"), "for": "id_username" },
            _react2.default.createElement("input", {
              type: "text",
              id: "id_username",
              className: "form-control",
              disabled: this.state.isLoading,
              onChange: this.bindInput("username"),
              value: this.state.username
            })
          )
        ),
        _react2.default.createElement(
          "div",
          { className: "modal-footer" },
          _react2.default.createElement(
            "button",
            {
              className: "btn btn-default",
              "data-dismiss": "modal",
              disabled: this.state.isLoading,
              type: "button"
            },
            gettext("Cancel")
          ),
          _react2.default.createElement(
            _button2.default,
            { className: "btn-primary", loading: this.state.isLoading },
            gettext("Change username")
          )
        )
      );
    }
  }, {
    key: "getModalBody",
    value: function getModalBody() {
      if (this.state.error) {
        return _react2.default.createElement(_modalMessage2.default, { icon: "remove_circle_outline", message: this.state.error });
      } else if (this.state.isLoaded) {
        return this.getFormBody();
      } else {
        return _react2.default.createElement(_modalLoader2.default, null);
      }
    }
  }, {
    key: "getClassName",
    value: function getClassName() {
      if (this.state.error) {
        return "modal-dialog modal-message modal-rename-user";
      } else {
        return "modal-dialog modal-rename-user";
      }
    }
  }, {
    key: "render",
    value: function render() {
      return _react2.default.createElement(
        "div",
        { className: this.getClassName(), role: "document" },
        _react2.default.createElement(
          "div",
          { className: "modal-content" },
          _react2.default.createElement(
            "div",
            { className: "modal-header" },
            _react2.default.createElement(
              "button",
              {
                type: "button",
                className: "close",
                "data-dismiss": "modal",
                "aria-label": gettext("Close")
              },
              _react2.default.createElement(
                "span",
                { "aria-hidden": "true" },
                "\xD7"
              )
            ),
            _react2.default.createElement(
              "h4",
              { className: "modal-title" },
              gettext("Change username")
            )
          ),
          this.getModalBody()
        )
      );
    }
  }]);

  return _class;
}(_form2.default);

exports.default = _class;

},{"../../../reducers/username-history":363,"../../../reducers/users":364,"../../../services/ajax":365,"../../../services/snackbar":376,"../../../services/store":377,"../../../utils/validators":393,"../../button":8,"../../form":56,"../../form-group":55,"../../modal-loader":61,"../../modal-message":62,"react":"react"}],192:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _button = require("../../button");

var _button2 = _interopRequireDefault(_button);

var _form = require("../../form");

var _form2 = _interopRequireDefault(_form);

var _formGroup = require("../../form-group");

var _formGroup2 = _interopRequireDefault(_formGroup);

var _modalLoader = require("../../modal-loader");

var _modalLoader2 = _interopRequireDefault(_modalLoader);

var _modalMessage = require("../../modal-message");

var _modalMessage2 = _interopRequireDefault(_modalMessage);

var _yesNoSwitch = require("../../yes-no-switch");

var _yesNoSwitch2 = _interopRequireDefault(_yesNoSwitch);

var _index = require("../../../index");

var _index2 = _interopRequireDefault(_index);

var _ajax = require("../../../services/ajax");

var _ajax2 = _interopRequireDefault(_ajax);

var _polls = require("../../../services/polls");

var _polls2 = _interopRequireDefault(_polls);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_Form) {
  _inherits(_class, _Form);

  function _class(props) {
    _classCallCheck(this, _class);

    var _this = _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).call(this, props));

    _this.countdown = function () {
      window.setTimeout(function () {
        if (_this.state.countdown > 1) {
          _this.setState({
            countdown: _this.state.countdown - 1
          });
          _this.countdown();
        } else if (!_this.state.confirm) {
          _this.setState({
            confirm: true
          });
        }
      }, 1000);
    };

    _this.state = {
      isLoaded: false,
      isLoading: false,
      isDeleted: false,
      error: null,

      countdown: 5,
      confirm: false,

      with_content: false
    };
    return _this;
  }

  _createClass(_class, [{
    key: "componentDidMount",
    value: function componentDidMount() {
      var _this2 = this;

      _ajax2.default.get(this.props.profile.api.delete).then(function () {
        _this2.setState({
          isLoaded: true
        });

        _this2.countdown();
      }, function (rejection) {
        _this2.setState({
          isLoaded: true,
          error: rejection.detail
        });
      });
    }
  }, {
    key: "send",
    value: function send() {
      return _ajax2.default.post(this.props.profile.api.delete, {
        with_content: this.state.with_content
      });
    }
  }, {
    key: "handleSuccess",
    value: function handleSuccess() {
      _polls2.default.stop("user-profile");

      if (this.state.with_content) {
        this.setState({
          isDeleted: interpolate(gettext("%(username)s's account, threads, posts and other content has been deleted."), {
            username: this.props.profile.username
          }, true)
        });
      } else {
        this.setState({
          isDeleted: interpolate(gettext("%(username)s's account has been deleted and other content has been hidden."), {
            username: this.props.profile.username
          }, true)
        });
      }
    }
  }, {
    key: "getButtonLabel",
    value: function getButtonLabel() {
      if (this.state.confirm) {
        return interpolate(gettext("Delete %(username)s"), {
          username: this.props.profile.username
        }, true);
      } else {
        return interpolate(gettext("Please wait... (%(countdown)ss)"), {
          countdown: this.state.countdown
        }, true);
      }
    }
  }, {
    key: "getForm",
    value: function getForm() {
      return _react2.default.createElement(
        "form",
        { onSubmit: this.handleSubmit },
        _react2.default.createElement(
          "div",
          { className: "modal-body" },
          _react2.default.createElement(
            _formGroup2.default,
            { label: gettext("User content"), "for": "id_with_content" },
            _react2.default.createElement(_yesNoSwitch2.default, {
              id: "id_with_content",
              disabled: this.state.isLoading,
              labelOn: gettext("Delete together with user's account"),
              labelOff: gettext("Hide after deleting user's account"),
              onChange: this.bindInput("with_content"),
              value: this.state.with_content
            })
          )
        ),
        _react2.default.createElement(
          "div",
          { className: "modal-footer" },
          _react2.default.createElement(
            "button",
            {
              type: "button",
              className: "btn btn-default",
              "data-dismiss": "modal"
            },
            gettext("Cancel")
          ),
          _react2.default.createElement(
            _button2.default,
            {
              className: "btn-danger",
              loading: this.state.isLoading,
              disabled: !this.state.confirm
            },
            this.getButtonLabel()
          )
        )
      );
    }
  }, {
    key: "getDeletedBody",
    value: function getDeletedBody() {
      return _react2.default.createElement(
        "div",
        { className: "modal-body" },
        _react2.default.createElement(
          "div",
          { className: "message-icon" },
          _react2.default.createElement(
            "span",
            { className: "material-icon" },
            "info_outline"
          )
        ),
        _react2.default.createElement(
          "div",
          { className: "message-body" },
          _react2.default.createElement(
            "p",
            { className: "lead" },
            this.state.isDeleted
          ),
          _react2.default.createElement(
            "p",
            null,
            _react2.default.createElement(
              "a",
              { href: _index2.default.get("USERS_LIST_URL") },
              gettext("Return to users list")
            )
          )
        )
      );
    }
  }, {
    key: "getModalBody",
    value: function getModalBody() {
      if (this.state.error) {
        return _react2.default.createElement(_modalMessage2.default, { icon: "remove_circle_outline", message: this.state.error });
      } else if (this.state.isLoaded) {
        if (this.state.isDeleted) {
          return this.getDeletedBody();
        } else {
          return this.getForm();
        }
      } else {
        return _react2.default.createElement(_modalLoader2.default, null);
      }
    }
  }, {
    key: "getClassName",
    value: function getClassName() {
      if (this.state.error || this.state.isDeleted) {
        return "modal-dialog modal-message modal-delete-account";
      } else {
        return "modal-dialog modal-delete-account";
      }
    }
  }, {
    key: "render",
    value: function render() {
      return _react2.default.createElement(
        "div",
        { className: this.getClassName(), role: "document" },
        _react2.default.createElement(
          "div",
          { className: "modal-content" },
          _react2.default.createElement(
            "div",
            { className: "modal-header" },
            _react2.default.createElement(
              "button",
              {
                type: "button",
                className: "close",
                "data-dismiss": "modal",
                "aria-label": gettext("Close")
              },
              _react2.default.createElement(
                "span",
                { "aria-hidden": "true" },
                "\xD7"
              )
            ),
            _react2.default.createElement(
              "h4",
              { className: "modal-title" },
              gettext("Delete user account")
            )
          ),
          this.getModalBody()
        )
      );
    }
  }]);

  return _class;
}(_form2.default);

exports.default = _class;

},{"../../../index":302,"../../../services/ajax":365,"../../../services/polls":374,"../../button":8,"../../form":56,"../../form-group":55,"../../modal-loader":61,"../../modal-message":62,"../../yes-no-switch":300,"react":"react"}],193:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _reactRedux = require("react-redux");

var _avatarControls = require("./avatar-controls");

var _avatarControls2 = _interopRequireDefault(_avatarControls);

var _changeUsername = require("./change-username");

var _changeUsername2 = _interopRequireDefault(_changeUsername);

var _deleteAccount = require("./delete-account");

var _deleteAccount2 = _interopRequireDefault(_deleteAccount);

var _modal = require("../../../services/modal");

var _modal2 = _interopRequireDefault(_modal);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var select = function select(store) {
  return {
    tick: store.tick,
    user: store.auth,
    profile: store.profile
  };
};

var _class = function (_React$Component) {
  _inherits(_class, _React$Component);

  function _class() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, _class);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = _class.__proto__ || Object.getPrototypeOf(_class)).call.apply(_ref, [this].concat(args))), _this), _this.showAvatarDialog = function () {
      _modal2.default.show((0, _reactRedux.connect)(select)(_avatarControls2.default));
    }, _this.showRenameDialog = function () {
      _modal2.default.show((0, _reactRedux.connect)(select)(_changeUsername2.default));
    }, _this.showDeleteDialog = function () {
      _modal2.default.show((0, _reactRedux.connect)(select)(_deleteAccount2.default));
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(_class, [{
    key: "getAvatarButton",
    value: function getAvatarButton() {
      if (this.props.profile.acl.can_moderate_avatar) {
        return _react2.default.createElement(
          "li",
          null,
          _react2.default.createElement(
            "button",
            {
              type: "button",
              className: "btn btn-link",
              onClick: this.showAvatarDialog
            },
            _react2.default.createElement(
              "span",
              { className: "material-icon" },
              "portrait"
            ),
            gettext("Avatar controls")
          )
        );
      } else {
        return null;
      }
    }
  }, {
    key: "getRenameButton",
    value: function getRenameButton() {
      if (this.props.profile.acl.can_rename) {
        return _react2.default.createElement(
          "li",
          null,
          _react2.default.createElement(
            "button",
            {
              type: "button",
              className: "btn btn-link",
              onClick: this.showRenameDialog
            },
            _react2.default.createElement(
              "span",
              { className: "material-icon" },
              "credit_card"
            ),
            gettext("Change username")
          )
        );
      } else {
        return null;
      }
    }
  }, {
    key: "getDeleteButton",
    value: function getDeleteButton() {
      if (this.props.profile.acl.can_delete) {
        return _react2.default.createElement(
          "li",
          null,
          _react2.default.createElement(
            "button",
            {
              type: "button",
              className: "btn btn-link",
              onClick: this.showDeleteDialog
            },
            _react2.default.createElement(
              "span",
              { className: "material-icon" },
              "clear"
            ),
            gettext("Delete account")
          )
        );
      } else {
        return null;
      }
    }
  }, {
    key: "render",
    value: function render() {
      return _react2.default.createElement(
        "ul",
        {
          className: "dropdown-menu dropdown-menu-right stick-to-bottom",
          role: "menu"
        },
        this.getAvatarButton(),
        this.getRenameButton(),
        this.getDeleteButton()
      );
    }
  }]);

  return _class;
}(_react2.default.Component);

exports.default = _class;

},{"../../../services/modal":371,"./avatar-controls":190,"./change-username":191,"./delete-account":192,"react":"react","react-redux":"react-redux"}],194:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SideNav = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

exports.CompactNav = CompactNav;

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _reactRouter = require("react-router");

var _li = require("../li");

var _li2 = _interopRequireDefault(_li);

var _followButton = require("./follow-button");

var _followButton2 = _interopRequireDefault(_followButton);

var _index = require("../../index");

var _index2 = _interopRequireDefault(_index);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var SideNav = exports.SideNav = function (_React$Component) {
  _inherits(SideNav, _React$Component);

  function SideNav() {
    _classCallCheck(this, SideNav);

    return _possibleConstructorReturn(this, (SideNav.__proto__ || Object.getPrototypeOf(SideNav)).apply(this, arguments));
  }

  _createClass(SideNav, [{
    key: "render",
    value: function render() {
      var _this2 = this;

      return _react2.default.createElement(
        "div",
        { className: "list-group nav-side" },
        this.props.pages.map(function (page) {
          return _react2.default.createElement(
            _reactRouter.Link,
            {
              to: _this2.props.baseUrl + page.component + "/",
              className: "list-group-item",
              activeClassName: "active",
              key: page.component
            },
            _react2.default.createElement(
              "span",
              { className: "material-icon" },
              page.icon
            ),
            page.name
          );
        })
      );
    }
  }]);

  return SideNav;
}(_react2.default.Component);

function CompactNav(props) {
  return _react2.default.createElement(
    "div",
    { className: "page-tabs hidden-md hidden-lg" },
    _react2.default.createElement(
      "div",
      { className: "container" },
      _react2.default.createElement(
        "ul",
        { className: "nav nav-pills", role: "menu" },
        props.pages.map(function (page) {
          return _react2.default.createElement(
            _li2.default,
            {
              path: props.baseUrl + page.component + "/",
              key: page.component
            },
            _react2.default.createElement(
              _reactRouter.Link,
              {
                to: props.baseUrl + page.component + "/",
                onClick: props.hideNav
              },
              _react2.default.createElement(
                "span",
                { className: "material-icon" },
                page.icon
              ),
              page.name
            )
          );
        })
      )
    )
  );
}

},{"../../index":302,"../li":57,"./follow-button":185,"react":"react","react-router":"react-router"}],195:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

exports.select = select;
exports.paths = paths;

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _reactRedux = require("react-redux");

var _banDetails = require("./ban-details");

var _banDetails2 = _interopRequireDefault(_banDetails);

var _details = require("./details");

var _details2 = _interopRequireDefault(_details);

var _feed = require("./feed");

var _followers = require("./followers");

var _followers2 = _interopRequireDefault(_followers);

var _follows = require("./follows");

var _follows2 = _interopRequireDefault(_follows);

var _usernameHistory = require("./username-history");

var _usernameHistory2 = _interopRequireDefault(_usernameHistory);

var _header = require("./header");

var _header2 = _interopRequireDefault(_header);

var _nav = require("./moderation/nav");

var _nav2 = _interopRequireDefault(_nav);

var _navs = require("./navs");

var _avatar = require("../avatar");

var _avatar2 = _interopRequireDefault(_avatar);

var _withDropdown = require("../with-dropdown");

var _withDropdown2 = _interopRequireDefault(_withDropdown);

var _ = require("../..");

var _2 = _interopRequireDefault(_);

var _profile = require("../../reducers/profile");

var _polls = require("../../services/polls");

var _polls2 = _interopRequireDefault(_polls);

var _store = require("../../services/store");

var _store2 = _interopRequireDefault(_store);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_WithDropdown) {
  _inherits(_class, _WithDropdown);

  function _class(props) {
    _classCallCheck(this, _class);

    var _this = _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).call(this, props));

    _this.update = function (data) {
      _store2.default.dispatch((0, _profile.hydrate)(data));
    };

    _this.startPolling(props.profile.api.index);
    return _this;
  }

  _createClass(_class, [{
    key: "startPolling",
    value: function startPolling(api) {
      _polls2.default.start({
        poll: "user-profile",
        url: api,
        frequency: 90 * 1000,
        update: this.update
      });
    }
  }, {
    key: "render",
    value: function render() {
      var baseUrl = _2.default.get("PROFILE").url;
      var pages = _2.default.get("PROFILE_PAGES");

      return _react2.default.createElement(
        "div",
        { className: "page page-user-profile" },
        _react2.default.createElement(_header2.default, {
          baseUrl: baseUrl,
          pages: pages,
          profile: this.props.profile,
          toggleNav: this.toggleNav,
          toggleModeration: this.toggleModeration,
          user: this.props.user
        }),
        _react2.default.createElement(
          "div",
          { className: "container" },
          _react2.default.createElement(
            "div",
            { className: "row" },
            _react2.default.createElement(
              "div",
              { className: "col-md-3 hidden-xs hidden-sm" },
              _react2.default.createElement(
                "div",
                { className: "profile-side-avatar" },
                _react2.default.createElement(_avatar2.default, { user: this.props.profile, size: "400" })
              ),
              _react2.default.createElement(_navs.SideNav, {
                baseUrl: baseUrl,
                pages: pages,
                profile: this.props.profile
              })
            ),
            _react2.default.createElement(
              "div",
              { className: "col-md-9" },
              this.props.children
            )
          )
        )
      );
    }
  }]);

  return _class;
}(_withDropdown2.default);

exports.default = _class;
function select(store) {
  return {
    isAuthenticated: store.auth.user.id === store.profile.id,

    tick: store.tick.tick,
    user: store.auth.user,
    users: store.users,
    posts: store.posts,
    profile: store.profile,
    profileDetails: store["profile-details"],
    "username-history": store["username-history"]
  };
}

var COMPONENTS = {
  posts: _feed.Posts,
  threads: _feed.Threads,
  followers: _followers2.default,
  follows: _follows2.default,
  details: _details2.default,
  "username-history": _usernameHistory2.default,
  "ban-details": _banDetails2.default
};

function paths() {
  var paths = [];
  _2.default.get("PROFILE_PAGES").forEach(function (item) {
    paths.push(Object.assign({}, item, {
      path: _2.default.get("PROFILE").url + item.component + "/",
      component: (0, _reactRedux.connect)(select)(COMPONENTS[item.component])
    }));
  });

  return paths;
}

},{"../..":302,"../../reducers/profile":356,"../../services/polls":374,"../../services/store":377,"../avatar":6,"../with-dropdown":299,"./ban-details":174,"./details":182,"./feed":183,"./followers":186,"./follows":187,"./header":188,"./moderation/nav":193,"./navs":194,"./username-history":196,"react":"react","react-redux":"react-redux"}],196:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _button = require("../button");

var _button2 = _interopRequireDefault(_button);

var _quickSearch = require("../quick-search");

var _quickSearch2 = _interopRequireDefault(_quickSearch);

var _root = require("../username-history/root");

var _root2 = _interopRequireDefault(_root);

var _index = require("../../index");

var _index2 = _interopRequireDefault(_index);

var _usernameHistory = require("../../reducers/username-history");

var _ajax = require("../../services/ajax");

var _ajax2 = _interopRequireDefault(_ajax);

var _snackbar = require("../../services/snackbar");

var _snackbar2 = _interopRequireDefault(_snackbar);

var _store = require("../../services/store");

var _store2 = _interopRequireDefault(_store);

var _pageTitle = require("../../services/page-title");

var _pageTitle2 = _interopRequireDefault(_pageTitle);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_React$Component) {
  _inherits(_class, _React$Component);

  function _class(props) {
    _classCallCheck(this, _class);

    var _this = _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).call(this, props));

    _this.loadMore = function () {
      _this.setState({
        isBusy: true
      });

      _this.loadChanges(_this.state.page + 1, _this.state.search);
    };

    _this.search = function (ev) {
      _this.setState({
        isLoaded: false,
        isBusy: true,

        search: ev.target.value,

        count: 0,
        more: 0,

        page: 1,
        pages: 1
      });

      _this.loadChanges(1, ev.target.value);
    };

    if (_index2.default.has("PROFILE_NAME_HISTORY")) {
      _this.initWithPreloadedData(_index2.default.pop("PROFILE_NAME_HISTORY"));
    } else {
      _this.initWithoutPreloadedData();
    }
    return _this;
  }

  _createClass(_class, [{
    key: "initWithPreloadedData",
    value: function initWithPreloadedData(data) {
      this.state = {
        isLoaded: true,
        isBusy: false,

        search: "",

        count: data.count,
        more: data.more,

        page: data.page,
        pages: data.pages
      };

      _store2.default.dispatch((0, _usernameHistory.hydrate)(data.results));
    }
  }, {
    key: "initWithoutPreloadedData",
    value: function initWithoutPreloadedData() {
      this.state = {
        isLoaded: false,
        isBusy: false,

        search: "",

        count: 0,
        more: 0,

        page: 1,
        pages: 1
      };

      this.loadChanges();
    }
  }, {
    key: "loadChanges",
    value: function loadChanges() {
      var _this2 = this;

      var page = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;
      var search = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

      _ajax2.default.get(_index2.default.get("USERNAME_CHANGES_API"), {
        user: this.props.profile.id,
        search: search,
        page: page || 1
      }, "search-username-history").then(function (data) {
        if (page === 1) {
          _store2.default.dispatch((0, _usernameHistory.hydrate)(data.results));
        } else {
          _store2.default.dispatch((0, _usernameHistory.append)(data.results));
        }

        _this2.setState({
          isLoaded: true,
          isBusy: false,

          count: data.count,
          more: data.more,

          page: data.page,
          pages: data.pages
        });
      }, function (rejection) {
        _snackbar2.default.apiError(rejection);
      });
    }
  }, {
    key: "componentDidMount",
    value: function componentDidMount() {
      _pageTitle2.default.set({
        title: gettext("Username history"),
        parent: this.props.profile.username
      });
    }
  }, {
    key: "getLabel",
    value: function getLabel() {
      if (!this.state.isLoaded) {
        return gettext("Loading...");
      } else if (this.state.search) {
        var message = ngettext("Found %(changes)s username change.", "Found %(changes)s username changes.", this.state.count);

        return interpolate(message, {
          changes: this.state.count
        }, true);
      } else if (this.props.profile.id === this.props.user.id) {
        var _message = ngettext("Your username was changed %(changes)s time.", "Your username was changed %(changes)s times.", this.state.count);

        return interpolate(_message, {
          changes: this.state.count
        }, true);
      } else {
        var _message2 = ngettext("%(username)s's username was changed %(changes)s time.", "%(username)s's username was changed %(changes)s times.", this.state.count);

        return interpolate(_message2, {
          username: this.props.profile.username,
          changes: this.state.count
        }, true);
      }
    }
  }, {
    key: "getEmptyMessage",
    value: function getEmptyMessage() {
      if (this.state.search) {
        return gettext("Search returned no username changes matching specified criteria.");
      } else if (this.props.user.id === this.props.profile.id) {
        return gettext("No name changes have been recorded for your account.");
      } else {
        return interpolate(gettext("%(username)s's username was never changed."), {
          username: this.props.profile.username
        }, true);
      }
    }
  }, {
    key: "getMoreButton",
    value: function getMoreButton() {
      if (!this.state.more) return null;

      return _react2.default.createElement(
        "div",
        { className: "pager-more" },
        _react2.default.createElement(
          _button2.default,
          {
            className: "btn btn-default btn-outline",
            loading: this.state.isBusy,
            onClick: this.loadMore
          },
          interpolate(gettext("Show older (%(more)s)"), {
            more: this.state.more
          }, true)
        )
      );
    }
  }, {
    key: "render",
    value: function render() {
      return _react2.default.createElement(
        "div",
        { className: "profile-username-history" },
        _react2.default.createElement(
          "nav",
          { className: "toolbar" },
          _react2.default.createElement(
            "h3",
            { className: "toolbar-left" },
            this.getLabel()
          ),
          _react2.default.createElement(_quickSearch2.default, {
            className: "toolbar-right",
            value: this.state.search,
            onChange: this.search,
            placeholder: gettext("Search history...")
          })
        ),
        _react2.default.createElement(_root2.default, {
          isLoaded: this.state.isLoaded,
          emptyMessage: this.getEmptyMessage(),
          changes: this.props["username-history"]
        }),
        this.getMoreButton()
      );
    }
  }]);

  return _class;
}(_react2.default.Component);

exports.default = _class;

},{"../../index":302,"../../reducers/username-history":363,"../../services/ajax":365,"../../services/page-title":373,"../../services/snackbar":376,"../../services/store":377,"../button":8,"../quick-search":197,"../username-history/root":280,"react":"react"}],197:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_React$Component) {
  _inherits(_class, _React$Component);

  function _class() {
    _classCallCheck(this, _class);

    return _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).apply(this, arguments));
  }

  _createClass(_class, [{
    key: "getClassName",
    value: function getClassName() {
      if (this.props.className) {
        return "form-search " + this.props.className;
      } else {
        return "form-search";
      }
    }
  }, {
    key: "render",
    value: function render() {
      return _react2.default.createElement(
        "div",
        { className: this.getClassName() },
        _react2.default.createElement("input", {
          type: "text",
          className: "form-control",
          value: this.props.value,
          onChange: this.props.onChange,
          placeholder: this.props.placeholder || gettext("Search...")
        }),
        _react2.default.createElement(
          "span",
          { className: "material-icon" },
          "search"
        )
      );
    }
  }]);

  return _class;
}(_react2.default.Component);

exports.default = _class;

},{"react":"react"}],198:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _loader = require("./loader");

var _loader2 = _interopRequireDefault(_loader);

var _register = require("./register.js");

var _register2 = _interopRequireDefault(_register);

var _ajax = require("../services/ajax");

var _ajax2 = _interopRequireDefault(_ajax);

var _captcha = require("../services/captcha");

var _captcha2 = _interopRequireDefault(_captcha);

var _modal = require("../services/modal");

var _modal2 = _interopRequireDefault(_modal);

var _snackbar = require("../services/snackbar");

var _snackbar2 = _interopRequireDefault(_snackbar);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_React$Component) {
  _inherits(_class, _React$Component);

  function _class(props) {
    _classCallCheck(this, _class);

    var _this = _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).call(this, props));

    _this.showRegisterForm = function () {
      if (misago.get("SETTINGS").account_activation === "closed") {
        _snackbar2.default.info(gettext("New registrations are currently disabled."));
      } else if (_this.state.isLoaded) {
        _modal2.default.show(_react2.default.createElement(_register2.default, { criteria: _this.state.criteria }));
      } else {
        _this.setState({ isLoading: true });

        Promise.all([_captcha2.default.load(), _ajax2.default.get(misago.get("AUTH_CRITERIA_API"))]).then(function (result) {
          _this.setState({
            isLoading: false,
            isLoaded: true,
            criteria: result[1]
          });

          _modal2.default.show(_react2.default.createElement(_register2.default, { criteria: result[1] }));
        }, function () {
          _this.setState({ isLoading: false });

          _snackbar2.default.error(gettext("Registration is currently unavailable due to an error."));
        });
      }
    };

    _this.state = {
      isLoading: false,
      isLoaded: false,

      criteria: null
    };
    return _this;
  }

  _createClass(_class, [{
    key: "getClassName",
    value: function getClassName() {
      return this.props.className + (this.state.isLoading ? " btn-loading" : "");
    }
  }, {
    key: "render",
    value: function render() {
      return _react2.default.createElement(
        "button",
        {
          className: "btn " + this.getClassName(),
          disabled: this.state.isLoading,
          onClick: this.showRegisterForm,
          type: "button"
        },
        gettext("Register"),
        this.state.isLoading ? _react2.default.createElement(_loader2.default, null) : null
      );
    }
  }]);

  return _class;
}(_react2.default.Component);

exports.default = _class;

},{"../services/ajax":365,"../services/captcha":367,"../services/modal":371,"../services/snackbar":376,"./loader":58,"./register.js":199,"react":"react"}],199:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RegisterComplete = exports.RegisterForm = undefined;

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _button = require("./button");

var _button2 = _interopRequireDefault(_button);

var _form = require("./form");

var _form2 = _interopRequireDefault(_form);

var _formGroup = require("./form-group");

var _formGroup2 = _interopRequireDefault(_formGroup);

var _passwordStrength = require("./password-strength");

var _passwordStrength2 = _interopRequireDefault(_passwordStrength);

var _RegisterLegalFootnote = require("./RegisterLegalFootnote");

var _RegisterLegalFootnote2 = _interopRequireDefault(_RegisterLegalFootnote);

var _StartSocialAuth = require("./StartSocialAuth");

var _StartSocialAuth2 = _interopRequireDefault(_StartSocialAuth);

var _ = require("..");

var _2 = _interopRequireDefault(_);

var _ajax = require("../services/ajax");

var _ajax2 = _interopRequireDefault(_ajax);

var _auth = require("../services/auth");

var _auth2 = _interopRequireDefault(_auth);

var _captcha = require("../services/captcha");

var _captcha2 = _interopRequireDefault(_captcha);

var _modal = require("../services/modal");

var _modal2 = _interopRequireDefault(_modal);

var _snackbar = require("../services/snackbar");

var _snackbar2 = _interopRequireDefault(_snackbar);

var _bannedPage = require("../utils/banned-page");

var _bannedPage2 = _interopRequireDefault(_bannedPage);

var _validators = require("../utils/validators");

var validators = _interopRequireWildcard(_validators);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var RegisterForm = exports.RegisterForm = function (_Form) {
  _inherits(RegisterForm, _Form);

  function RegisterForm(props) {
    _classCallCheck(this, RegisterForm);

    var _this = _possibleConstructorReturn(this, (RegisterForm.__proto__ || Object.getPrototypeOf(RegisterForm)).call(this, props));

    _this.handlePrivacyPolicyChange = function (event) {
      var value = event.target.value;
      _this.handleToggleAgreement("privacyPolicy", value);
    };

    _this.handleTermsOfServiceChange = function (event) {
      var value = event.target.value;
      _this.handleToggleAgreement("termsOfService", value);
    };

    _this.handleToggleAgreement = function (agreement, value) {
      _this.setState(function (prevState, props) {
        if (prevState[agreement] === null) {
          var _errors = _extends({}, prevState.errors, _defineProperty({}, agreement, null));
          return _defineProperty({ errors: _errors }, agreement, value);
        }

        var validator = _this.state.validators[agreement][0];
        var errors = _extends({}, prevState.errors, _defineProperty({}, agreement, [validator(null)]));
        return _defineProperty({ errors: errors }, agreement, null);
      });
    };

    var _this$props$criteria = _this.props.criteria,
        username = _this$props$criteria.username,
        password = _this$props$criteria.password;


    var passwordMinLength = 0;
    password.forEach(function (item) {
      if (item.name === "MinimumLengthValidator") {
        passwordMinLength = item.min_length;
      }
    });

    var formValidators = {
      username: [validators.usernameContent(), validators.usernameMinLength(username.min_length), validators.usernameMaxLength(username.max_length)],
      email: [validators.email()],
      password: [validators.passwordMinLength(passwordMinLength)],
      captcha: _captcha2.default.validator()
    };

    if (!!_2.default.get("TERMS_OF_SERVICE_ID")) {
      formValidators.termsOfService = [validators.requiredTermsOfService()];
    }

    if (!!_2.default.get("PRIVACY_POLICY_ID")) {
      formValidators.privacyPolicy = [validators.requiredPrivacyPolicy()];
    }

    _this.state = {
      isLoading: false,

      username: "",
      email: "",
      password: "",
      captcha: "",

      termsOfService: null,
      privacyPolicy: null,

      validators: formValidators,
      errors: {}
    };
    return _this;
  }

  _createClass(RegisterForm, [{
    key: "clean",
    value: function clean() {
      if (this.isValid()) {
        return true;
      } else {
        _snackbar2.default.error(gettext("Form contains errors."));
        this.setState({
          errors: this.validate()
        });
        return false;
      }
    }
  }, {
    key: "send",
    value: function send() {
      return _ajax2.default.post(_2.default.get("USERS_API"), {
        username: this.state.username,
        email: this.state.email,
        password: this.state.password,
        captcha: this.state.captcha,
        terms_of_service: this.state.termsOfService,
        privacy_policy: this.state.privacyPolicy
      });
    }
  }, {
    key: "handleSuccess",
    value: function handleSuccess(apiResponse) {
      this.props.callback(apiResponse);
    }
  }, {
    key: "handleError",
    value: function handleError(rejection) {
      if (rejection.status === 400) {
        this.setState({
          errors: Object.assign({}, this.state.errors, rejection)
        });

        if (rejection.__all__ && rejection.__all__.length > 0) {
          _snackbar2.default.error(rejection.__all__[0]);
        } else {
          _snackbar2.default.error(gettext("Form contains errors."));
        }
      } else if (rejection.status === 403 && rejection.ban) {
        (0, _bannedPage2.default)(rejection.ban);
        _modal2.default.hide();
      } else {
        _snackbar2.default.apiError(rejection);
      }
    }
  }, {
    key: "render",
    value: function render() {
      return _react2.default.createElement(
        "div",
        { className: "modal-dialog modal-register", role: "document" },
        _react2.default.createElement(
          "div",
          { className: "modal-content" },
          _react2.default.createElement(
            "div",
            { className: "modal-header" },
            _react2.default.createElement(
              "button",
              {
                type: "button",
                className: "close",
                "data-dismiss": "modal",
                "aria-label": gettext("Close")
              },
              _react2.default.createElement(
                "span",
                { "aria-hidden": "true" },
                "\xD7"
              )
            ),
            _react2.default.createElement(
              "h4",
              { className: "modal-title" },
              gettext("Register")
            )
          ),
          _react2.default.createElement(
            "form",
            { onSubmit: this.handleSubmit },
            _react2.default.createElement("input", { type: "type", style: { display: "none" } }),
            _react2.default.createElement("input", { type: "password", style: { display: "none" } }),
            _react2.default.createElement(
              "div",
              { className: "modal-body" },
              _react2.default.createElement(_StartSocialAuth2.default, {
                buttonClassName: "col-xs-12 col-sm-6",
                buttonLabel: gettext("Join with %(site)s"),
                formLabel: gettext("Or create forum account:")
              }),
              _react2.default.createElement(
                _formGroup2.default,
                {
                  label: gettext("Username"),
                  "for": "id_username",
                  validation: this.state.errors.username
                },
                _react2.default.createElement("input", {
                  type: "text",
                  id: "id_username",
                  className: "form-control",
                  "aria-describedby": "id_username_status",
                  disabled: this.state.isLoading,
                  onChange: this.bindInput("username"),
                  value: this.state.username
                })
              ),
              _react2.default.createElement(
                _formGroup2.default,
                {
                  label: gettext("E-mail"),
                  "for": "id_email",
                  validation: this.state.errors.email
                },
                _react2.default.createElement("input", {
                  type: "text",
                  id: "id_email",
                  className: "form-control",
                  "aria-describedby": "id_email_status",
                  disabled: this.state.isLoading,
                  onChange: this.bindInput("email"),
                  value: this.state.email
                })
              ),
              _react2.default.createElement(
                _formGroup2.default,
                {
                  label: gettext("Password"),
                  "for": "id_password",
                  validation: this.state.errors.password,
                  extra: _react2.default.createElement(_passwordStrength2.default, {
                    password: this.state.password,
                    inputs: [this.state.username, this.state.email]
                  })
                },
                _react2.default.createElement("input", {
                  type: "password",
                  id: "id_password",
                  className: "form-control",
                  "aria-describedby": "id_password_status",
                  disabled: this.state.isLoading,
                  onChange: this.bindInput("password"),
                  value: this.state.password
                })
              ),
              _captcha2.default.component({
                form: this
              }),
              _react2.default.createElement(_RegisterLegalFootnote2.default, {
                errors: this.state.errors,
                privacyPolicy: this.state.privacyPolicy,
                termsOfService: this.state.termsOfService,
                onPrivacyPolicyChange: this.handlePrivacyPolicyChange,
                onTermsOfServiceChange: this.handleTermsOfServiceChange
              })
            ),
            _react2.default.createElement(
              "div",
              { className: "modal-footer" },
              _react2.default.createElement(
                "button",
                {
                  className: "btn btn-default",
                  "data-dismiss": "modal",
                  disabled: this.state.isLoading,
                  type: "button"
                },
                gettext("Cancel")
              ),
              _react2.default.createElement(
                _button2.default,
                { className: "btn-primary", loading: this.state.isLoading },
                gettext("Register account")
              )
            )
          )
        )
      );
    }
  }]);

  return RegisterForm;
}(_form2.default);

var RegisterComplete = exports.RegisterComplete = function (_React$Component) {
  _inherits(RegisterComplete, _React$Component);

  function RegisterComplete() {
    _classCallCheck(this, RegisterComplete);

    return _possibleConstructorReturn(this, (RegisterComplete.__proto__ || Object.getPrototypeOf(RegisterComplete)).apply(this, arguments));
  }

  _createClass(RegisterComplete, [{
    key: "getLead",
    value: function getLead() {
      if (this.props.activation === "user") {
        return gettext("%(username)s, your account has been created but you need to activate it before you will be able to sign in.");
      } else if (this.props.activation === "admin") {
        return gettext("%(username)s, your account has been created but board administrator will have to activate it before you will be able to sign in.");
      }
    }
  }, {
    key: "getSubscript",
    value: function getSubscript() {
      if (this.props.activation === "user") {
        return gettext("We have sent an e-mail to %(email)s with link that you have to click to activate your account.");
      } else if (this.props.activation === "admin") {
        return gettext("We will send an e-mail to %(email)s when this takes place.");
      }
    }
  }, {
    key: "render",
    value: function render() {
      return _react2.default.createElement(
        "div",
        {
          className: "modal-dialog modal-message modal-register",
          role: "document"
        },
        _react2.default.createElement(
          "div",
          { className: "modal-content" },
          _react2.default.createElement(
            "div",
            { className: "modal-header" },
            _react2.default.createElement(
              "button",
              {
                type: "button",
                className: "close",
                "data-dismiss": "modal",
                "aria-label": gettext("Close")
              },
              _react2.default.createElement(
                "span",
                { "aria-hidden": "true" },
                "\xD7"
              )
            ),
            _react2.default.createElement(
              "h4",
              { className: "modal-title" },
              gettext("Registration complete")
            )
          ),
          _react2.default.createElement(
            "div",
            { className: "modal-body" },
            _react2.default.createElement(
              "div",
              { className: "message-icon" },
              _react2.default.createElement(
                "span",
                { className: "material-icon" },
                "info_outline"
              )
            ),
            _react2.default.createElement(
              "div",
              { className: "message-body" },
              _react2.default.createElement(
                "p",
                { className: "lead" },
                interpolate(this.getLead(), { username: this.props.username }, true)
              ),
              _react2.default.createElement(
                "p",
                null,
                interpolate(this.getSubscript(), { email: this.props.email }, true)
              ),
              _react2.default.createElement(
                "button",
                {
                  className: "btn btn-default",
                  "data-dismiss": "modal",
                  type: "button"
                },
                gettext("Ok")
              )
            )
          )
        )
      );
    }
  }]);

  return RegisterComplete;
}(_react2.default.Component);

var _class = function (_React$Component2) {
  _inherits(_class, _React$Component2);

  function _class(props) {
    _classCallCheck(this, _class);

    var _this3 = _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).call(this, props));

    _this3.completeRegistration = function (apiResponse) {
      if (apiResponse.activation === "active") {
        _modal2.default.hide();
        _auth2.default.signIn(apiResponse);
      } else {
        _this3.setState({
          complete: apiResponse
        });
      }
    };

    _this3.state = {
      complete: false
    };
    return _this3;
  }

  _createClass(_class, [{
    key: "render",
    value: function render() {
      if (this.state.complete) {
        return _react2.default.createElement(RegisterComplete, {
          activation: this.state.complete.activation,
          email: this.state.complete.email,
          username: this.state.complete.username
        });
      }

      return _react2.default.createElement(RegisterForm, _extends({ callback: this.completeRegistration }, this.props));
    }
  }]);

  return _class;
}(_react2.default.Component);

exports.default = _class;

},{"..":302,"../services/ajax":365,"../services/auth":366,"../services/captcha":367,"../services/modal":371,"../services/snackbar":376,"../utils/banned-page":379,"../utils/validators":393,"./RegisterLegalFootnote":1,"./StartSocialAuth":2,"./button":8,"./form":56,"./form-group":55,"./password-strength":103,"react":"react"}],200:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LinkSent = exports.RequestLinkForm = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _index = require("../index");

var _index2 = _interopRequireDefault(_index);

var _button = require("./button");

var _button2 = _interopRequireDefault(_button);

var _form = require("./form");

var _form2 = _interopRequireDefault(_form);

var _ajax = require("../services/ajax");

var _ajax2 = _interopRequireDefault(_ajax);

var _snackbar = require("../services/snackbar");

var _snackbar2 = _interopRequireDefault(_snackbar);

var _validators = require("../utils/validators");

var validators = _interopRequireWildcard(_validators);

var _bannedPage = require("../utils/banned-page");

var _bannedPage2 = _interopRequireDefault(_bannedPage);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var RequestLinkForm = exports.RequestLinkForm = function (_Form) {
  _inherits(RequestLinkForm, _Form);

  function RequestLinkForm(props) {
    _classCallCheck(this, RequestLinkForm);

    var _this = _possibleConstructorReturn(this, (RequestLinkForm.__proto__ || Object.getPrototypeOf(RequestLinkForm)).call(this, props));

    _this.state = {
      isLoading: false,

      email: "",

      validators: {
        email: [validators.email()]
      }
    };
    return _this;
  }

  _createClass(RequestLinkForm, [{
    key: "clean",
    value: function clean() {
      if (this.isValid()) {
        return true;
      } else {
        _snackbar2.default.error(gettext("Enter a valid email address."));
        return false;
      }
    }
  }, {
    key: "send",
    value: function send() {
      return _ajax2.default.post(_index2.default.get("SEND_ACTIVATION_API"), {
        email: this.state.email
      });
    }
  }, {
    key: "handleSuccess",
    value: function handleSuccess(apiResponse) {
      this.props.callback(apiResponse);
    }
  }, {
    key: "handleError",
    value: function handleError(rejection) {
      if (["already_active", "inactive_admin"].indexOf(rejection.code) > -1) {
        _snackbar2.default.info(rejection.detail);
      } else if (rejection.status === 403 && rejection.ban) {
        (0, _bannedPage2.default)(rejection.ban);
      } else {
        _snackbar2.default.apiError(rejection);
      }
    }
  }, {
    key: "render",
    value: function render() {
      return _react2.default.createElement(
        "div",
        { className: "well well-form well-form-request-activation-link" },
        _react2.default.createElement(
          "form",
          { onSubmit: this.handleSubmit },
          _react2.default.createElement(
            "div",
            { className: "form-group" },
            _react2.default.createElement(
              "div",
              { className: "control-input" },
              _react2.default.createElement("input", {
                type: "text",
                className: "form-control",
                placeholder: gettext("Your e-mail address"),
                disabled: this.state.isLoading,
                onChange: this.bindInput("email"),
                value: this.state.email
              })
            )
          ),
          _react2.default.createElement(
            _button2.default,
            {
              className: "btn-primary btn-block",
              loading: this.state.isLoading
            },
            gettext("Send link")
          )
        )
      );
    }
  }]);

  return RequestLinkForm;
}(_form2.default);

var LinkSent = exports.LinkSent = function (_React$Component) {
  _inherits(LinkSent, _React$Component);

  function LinkSent() {
    _classCallCheck(this, LinkSent);

    return _possibleConstructorReturn(this, (LinkSent.__proto__ || Object.getPrototypeOf(LinkSent)).apply(this, arguments));
  }

  _createClass(LinkSent, [{
    key: "getMessage",
    value: function getMessage() {
      return interpolate(gettext("Activation link was sent to %(email)s"), {
        email: this.props.user.email
      }, true);
    }
  }, {
    key: "render",
    value: function render() {
      return _react2.default.createElement(
        "div",
        { className: "well well-form well-form-request-activation-link well-done" },
        _react2.default.createElement(
          "div",
          { className: "done-message" },
          _react2.default.createElement(
            "div",
            { className: "message-icon" },
            _react2.default.createElement(
              "span",
              { className: "material-icon" },
              "check"
            )
          ),
          _react2.default.createElement(
            "div",
            { className: "message-body" },
            _react2.default.createElement(
              "p",
              null,
              this.getMessage()
            )
          ),
          _react2.default.createElement(
            "button",
            {
              className: "btn btn-primary btn-block",
              type: "button",
              onClick: this.props.callback
            },
            gettext("Request another link")
          )
        )
      );
    }
  }]);

  return LinkSent;
}(_react2.default.Component);

var _class = function (_React$Component2) {
  _inherits(_class, _React$Component2);

  function _class(props) {
    _classCallCheck(this, _class);

    var _this3 = _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).call(this, props));

    _this3.complete = function (apiResponse) {
      _this3.setState({
        complete: apiResponse
      });
    };

    _this3.reset = function () {
      _this3.setState({
        complete: false
      });
    };

    _this3.state = {
      complete: false
    };
    return _this3;
  }

  _createClass(_class, [{
    key: "render",
    value: function render() {
      if (this.state.complete) {
        return _react2.default.createElement(LinkSent, { user: this.state.complete, callback: this.reset });
      } else {
        return _react2.default.createElement(RequestLinkForm, { callback: this.complete });
      }
    }
  }]);

  return _class;
}(_react2.default.Component);

exports.default = _class;

},{"../index":302,"../services/ajax":365,"../services/snackbar":376,"../utils/banned-page":379,"../utils/validators":393,"./button":8,"./form":56,"react":"react"}],201:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AccountInactivePage = exports.LinkSent = exports.RequestResetForm = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _reactDom = require("react-dom");

var _reactDom2 = _interopRequireDefault(_reactDom);

var _index = require("../index");

var _index2 = _interopRequireDefault(_index);

var _button = require("./button");

var _button2 = _interopRequireDefault(_button);

var _form = require("./form");

var _form2 = _interopRequireDefault(_form);

var _ajax = require("../services/ajax");

var _ajax2 = _interopRequireDefault(_ajax);

var _snackbar = require("../services/snackbar");

var _snackbar2 = _interopRequireDefault(_snackbar);

var _validators = require("../utils/validators");

var validators = _interopRequireWildcard(_validators);

var _bannedPage = require("../utils/banned-page");

var _bannedPage2 = _interopRequireDefault(_bannedPage);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var RequestResetForm = exports.RequestResetForm = function (_Form) {
  _inherits(RequestResetForm, _Form);

  function RequestResetForm(props) {
    _classCallCheck(this, RequestResetForm);

    var _this = _possibleConstructorReturn(this, (RequestResetForm.__proto__ || Object.getPrototypeOf(RequestResetForm)).call(this, props));

    _this.state = {
      isLoading: false,

      email: "",

      validators: {
        email: [validators.email()]
      }
    };
    return _this;
  }

  _createClass(RequestResetForm, [{
    key: "clean",
    value: function clean() {
      if (this.isValid()) {
        return true;
      } else {
        _snackbar2.default.error(gettext("Enter a valid email address."));
        return false;
      }
    }
  }, {
    key: "send",
    value: function send() {
      return _ajax2.default.post(_index2.default.get("SEND_PASSWORD_RESET_API"), {
        email: this.state.email
      });
    }
  }, {
    key: "handleSuccess",
    value: function handleSuccess(apiResponse) {
      this.props.callback(apiResponse);
    }
  }, {
    key: "handleError",
    value: function handleError(rejection) {
      if (["inactive_user", "inactive_admin"].indexOf(rejection.code) > -1) {
        this.props.showInactivePage(rejection);
      } else if (rejection.status === 403 && rejection.ban) {
        (0, _bannedPage2.default)(rejection.ban);
      } else {
        _snackbar2.default.apiError(rejection);
      }
    }
  }, {
    key: "render",
    value: function render() {
      return _react2.default.createElement(
        "div",
        { className: "well well-form well-form-request-password-reset" },
        _react2.default.createElement(
          "form",
          { onSubmit: this.handleSubmit },
          _react2.default.createElement(
            "div",
            { className: "form-group" },
            _react2.default.createElement(
              "div",
              { className: "control-input" },
              _react2.default.createElement("input", {
                type: "text",
                className: "form-control",
                placeholder: gettext("Your e-mail address"),
                disabled: this.state.isLoading,
                onChange: this.bindInput("email"),
                value: this.state.email
              })
            )
          ),
          _react2.default.createElement(
            _button2.default,
            {
              className: "btn-primary btn-block",
              loading: this.state.isLoading
            },
            gettext("Send link")
          )
        )
      );
    }
  }]);

  return RequestResetForm;
}(_form2.default);

var LinkSent = exports.LinkSent = function (_React$Component) {
  _inherits(LinkSent, _React$Component);

  function LinkSent() {
    _classCallCheck(this, LinkSent);

    return _possibleConstructorReturn(this, (LinkSent.__proto__ || Object.getPrototypeOf(LinkSent)).apply(this, arguments));
  }

  _createClass(LinkSent, [{
    key: "getMessage",
    value: function getMessage() {
      return interpolate(gettext("Reset password link was sent to %(email)s"), {
        email: this.props.user.email
      }, true);
    }
  }, {
    key: "render",
    value: function render() {
      return _react2.default.createElement(
        "div",
        { className: "well well-form well-form-request-password-reset well-done" },
        _react2.default.createElement(
          "div",
          { className: "done-message" },
          _react2.default.createElement(
            "div",
            { className: "message-icon" },
            _react2.default.createElement(
              "span",
              { className: "material-icon" },
              "check"
            )
          ),
          _react2.default.createElement(
            "div",
            { className: "message-body" },
            _react2.default.createElement(
              "p",
              null,
              this.getMessage()
            )
          ),
          _react2.default.createElement(
            "button",
            {
              type: "button",
              className: "btn btn-primary btn-block",
              onClick: this.props.callback
            },
            gettext("Request another link")
          )
        )
      );
    }
  }]);

  return LinkSent;
}(_react2.default.Component);

var AccountInactivePage = exports.AccountInactivePage = function (_React$Component2) {
  _inherits(AccountInactivePage, _React$Component2);

  function AccountInactivePage() {
    _classCallCheck(this, AccountInactivePage);

    return _possibleConstructorReturn(this, (AccountInactivePage.__proto__ || Object.getPrototypeOf(AccountInactivePage)).apply(this, arguments));
  }

  _createClass(AccountInactivePage, [{
    key: "getActivateButton",
    value: function getActivateButton() {
      if (this.props.activation === "inactive_user") {
        return _react2.default.createElement(
          "p",
          null,
          _react2.default.createElement(
            "a",
            { href: _index2.default.get("REQUEST_ACTIVATION_URL") },
            gettext("Activate your account.")
          )
        );
      } else {
        return null;
      }
    }
  }, {
    key: "render",
    value: function render() {
      return _react2.default.createElement(
        "div",
        { className: "page page-message page-message-info page-forgotten-password-inactive" },
        _react2.default.createElement(
          "div",
          { className: "container" },
          _react2.default.createElement(
            "div",
            { className: "message-panel" },
            _react2.default.createElement(
              "div",
              { className: "message-icon" },
              _react2.default.createElement(
                "span",
                { className: "material-icon" },
                "info_outline"
              )
            ),
            _react2.default.createElement(
              "div",
              { className: "message-body" },
              _react2.default.createElement(
                "p",
                { className: "lead" },
                gettext("Your account is inactive.")
              ),
              _react2.default.createElement(
                "p",
                null,
                this.props.message
              ),
              this.getActivateButton()
            )
          )
        )
      );
    }
  }]);

  return AccountInactivePage;
}(_react2.default.Component);

var _class = function (_React$Component3) {
  _inherits(_class, _React$Component3);

  function _class(props) {
    _classCallCheck(this, _class);

    var _this4 = _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).call(this, props));

    _this4.complete = function (apiResponse) {
      _this4.setState({
        complete: apiResponse
      });
    };

    _this4.reset = function () {
      _this4.setState({
        complete: false
      });
    };

    _this4.state = {
      complete: false
    };
    return _this4;
  }

  _createClass(_class, [{
    key: "showInactivePage",
    value: function showInactivePage(apiResponse) {
      _reactDom2.default.render(_react2.default.createElement(AccountInactivePage, {
        activation: apiResponse.code,
        message: apiResponse.detail
      }), document.getElementById("page-mount"));
    }
  }, {
    key: "render",
    value: function render() {
      if (this.state.complete) {
        return _react2.default.createElement(LinkSent, { callback: this.reset, user: this.state.complete });
      }

      return _react2.default.createElement(RequestResetForm, {
        callback: this.complete,
        showInactivePage: this.showInactivePage
      });
    }
  }]);

  return _class;
}(_react2.default.Component);

exports.default = _class;

},{"../index":302,"../services/ajax":365,"../services/snackbar":376,"../utils/banned-page":379,"../utils/validators":393,"./button":8,"./form":56,"react":"react","react-dom":"react-dom"}],202:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PasswordChangedPage = exports.ResetPasswordForm = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _reactDom = require("react-dom");

var _reactDom2 = _interopRequireDefault(_reactDom);

var _index = require("../index");

var _index2 = _interopRequireDefault(_index);

var _button = require("./button");

var _button2 = _interopRequireDefault(_button);

var _form = require("./form");

var _form2 = _interopRequireDefault(_form);

var _signIn = require("./sign-in.js");

var _signIn2 = _interopRequireDefault(_signIn);

var _ajax = require("../services/ajax");

var _ajax2 = _interopRequireDefault(_ajax);

var _auth = require("../services/auth");

var _auth2 = _interopRequireDefault(_auth);

var _modal = require("../services/modal");

var _modal2 = _interopRequireDefault(_modal);

var _snackbar = require("../services/snackbar");

var _snackbar2 = _interopRequireDefault(_snackbar);

var _bannedPage = require("../utils/banned-page");

var _bannedPage2 = _interopRequireDefault(_bannedPage);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var ResetPasswordForm = exports.ResetPasswordForm = function (_Form) {
  _inherits(ResetPasswordForm, _Form);

  function ResetPasswordForm(props) {
    _classCallCheck(this, ResetPasswordForm);

    var _this = _possibleConstructorReturn(this, (ResetPasswordForm.__proto__ || Object.getPrototypeOf(ResetPasswordForm)).call(this, props));

    _this.state = {
      isLoading: false,

      password: ""
    };
    return _this;
  }

  _createClass(ResetPasswordForm, [{
    key: "clean",
    value: function clean() {
      if (this.state.password.trim().length) {
        return true;
      } else {
        _snackbar2.default.error(gettext("Enter new password."));
        return false;
      }
    }
  }, {
    key: "send",
    value: function send() {
      return _ajax2.default.post(_index2.default.get("CHANGE_PASSWORD_API"), {
        password: this.state.password
      });
    }
  }, {
    key: "handleSuccess",
    value: function handleSuccess(apiResponse) {
      this.props.callback(apiResponse);
    }
  }, {
    key: "handleError",
    value: function handleError(rejection) {
      if (rejection.status === 403 && rejection.ban) {
        (0, _bannedPage2.default)(rejection.ban);
      } else {
        _snackbar2.default.apiError(rejection);
      }
    }
  }, {
    key: "render",
    value: function render() {
      return _react2.default.createElement(
        "div",
        { className: "well well-form well-form-reset-password" },
        _react2.default.createElement(
          "form",
          { onSubmit: this.handleSubmit },
          _react2.default.createElement(
            "div",
            { className: "form-group" },
            _react2.default.createElement(
              "div",
              { className: "control-input" },
              _react2.default.createElement("input", {
                type: "password",
                className: "form-control",
                placeholder: gettext("Enter new password"),
                disabled: this.state.isLoading,
                onChange: this.bindInput("password"),
                value: this.state.password
              })
            )
          ),
          _react2.default.createElement(
            _button2.default,
            {
              className: "btn-primary btn-block",
              loading: this.state.isLoading
            },
            gettext("Change password")
          )
        )
      );
    }
  }]);

  return ResetPasswordForm;
}(_form2.default);

var PasswordChangedPage = exports.PasswordChangedPage = function (_React$Component) {
  _inherits(PasswordChangedPage, _React$Component);

  function PasswordChangedPage() {
    _classCallCheck(this, PasswordChangedPage);

    return _possibleConstructorReturn(this, (PasswordChangedPage.__proto__ || Object.getPrototypeOf(PasswordChangedPage)).apply(this, arguments));
  }

  _createClass(PasswordChangedPage, [{
    key: "getMessage",
    value: function getMessage() {
      return interpolate(gettext("%(username)s, your password has been changed successfully."), {
        username: this.props.user.username
      }, true);
    }
  }, {
    key: "showSignIn",
    value: function showSignIn() {
      _modal2.default.show(_signIn2.default);
    }
  }, {
    key: "render",
    value: function render() {
      return _react2.default.createElement(
        "div",
        { className: "page page-message page-message-success page-forgotten-password-changed" },
        _react2.default.createElement(
          "div",
          { className: "container" },
          _react2.default.createElement(
            "div",
            { className: "message-panel" },
            _react2.default.createElement(
              "div",
              { className: "message-icon" },
              _react2.default.createElement(
                "span",
                { className: "material-icon" },
                "check"
              )
            ),
            _react2.default.createElement(
              "div",
              { className: "message-body" },
              _react2.default.createElement(
                "p",
                { className: "lead" },
                this.getMessage()
              ),
              _react2.default.createElement(
                "p",
                null,
                gettext("You will have to sign in using new password before continuing.")
              ),
              _react2.default.createElement(
                "p",
                null,
                _react2.default.createElement(
                  "button",
                  {
                    type: "button",
                    className: "btn btn-primary",
                    onClick: this.showSignIn
                  },
                  gettext("Sign in")
                )
              )
            )
          )
        )
      );
    }
  }]);

  return PasswordChangedPage;
}(_react2.default.Component);

var _class = function (_React$Component2) {
  _inherits(_class, _React$Component2);

  function _class() {
    var _ref;

    var _temp, _this3, _ret;

    _classCallCheck(this, _class);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this3 = _possibleConstructorReturn(this, (_ref = _class.__proto__ || Object.getPrototypeOf(_class)).call.apply(_ref, [this].concat(args))), _this3), _this3.complete = function (apiResponse) {
      _auth2.default.softSignOut();

      // nuke "redirect_to" field so we don't end
      // coming back to error page after sign in
      $('#hidden-login-form input[name="redirect_to"]').remove();

      _reactDom2.default.render(_react2.default.createElement(PasswordChangedPage, { user: apiResponse }), document.getElementById("page-mount"));
    }, _temp), _possibleConstructorReturn(_this3, _ret);
  }

  _createClass(_class, [{
    key: "render",
    value: function render() {
      return _react2.default.createElement(ResetPasswordForm, { callback: this.complete });
    }
  }]);

  return _class;
}(_react2.default.Component);

exports.default = _class;

},{"../index":302,"../services/ajax":365,"../services/auth":366,"../services/modal":371,"../services/snackbar":376,"../utils/banned-page":379,"./button":8,"./form":56,"./sign-in.js":211,"react":"react","react-dom":"react-dom"}],203:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _ = require("../..");

var _2 = _interopRequireDefault(_);

var _form = require("../form");

var _form2 = _interopRequireDefault(_form);

var _posts = require("../../reducers/posts");

var _search = require("../../reducers/search");

var _users = require("../../reducers/users");

var _ajax = require("../../services/ajax");

var _ajax2 = _interopRequireDefault(_ajax);

var _snackbar = require("../../services/snackbar");

var _snackbar2 = _interopRequireDefault(_snackbar);

var _store = require("../../services/store");

var _store2 = _interopRequireDefault(_store);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_Form) {
  _inherits(_class, _Form);

  function _class(props) {
    _classCallCheck(this, _class);

    var _this = _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).call(this, props));

    _this.onQueryChange = function (event) {
      _this.changeValue("query", event.target.value);
    };

    _this.state = {
      isLoading: false,

      query: props.search.query
    };
    return _this;
  }

  _createClass(_class, [{
    key: "componentDidMount",
    value: function componentDidMount() {
      if (this.state.query.length) {
        this.handleSubmit();
      }
    }
  }, {
    key: "clean",
    value: function clean() {
      if (!this.state.query.trim().length) {
        _snackbar2.default.error(gettext("You have to enter search query."));
        return false;
      }

      return true;
    }
  }, {
    key: "send",
    value: function send() {
      _store2.default.dispatch((0, _search.update)({
        isLoading: true
      }));

      return _ajax2.default.get(_2.default.get("SEARCH_API"), {
        q: this.state.query.trim()
      });
    }
  }, {
    key: "handleSuccess",
    value: function handleSuccess(providers) {
      _store2.default.dispatch((0, _search.update)({
        query: this.state.query.trim(),
        isLoading: false,
        providers: providers
      }));

      providers.forEach(function (provider) {
        if (provider.id === "users") {
          _store2.default.dispatch((0, _users.hydrate)(provider.results.results));
        } else if (provider.id === "threads") {
          _store2.default.dispatch((0, _posts.load)(provider.results));
        }
      });
    }
  }, {
    key: "handleError",
    value: function handleError(rejection) {
      _snackbar2.default.apiError(rejection);

      _store2.default.dispatch((0, _search.update)({
        isLoading: false
      }));
    }
  }, {
    key: "render",
    value: function render() {
      return _react2.default.createElement(
        "div",
        { className: "page-header-bg" },
        _react2.default.createElement(
          "div",
          { className: "page-header page-search-form" },
          _react2.default.createElement(
            "form",
            { onSubmit: this.handleSubmit },
            _react2.default.createElement(
              "div",
              { className: "container" },
              _react2.default.createElement(
                "div",
                { className: "row" },
                _react2.default.createElement(
                  "div",
                  { className: "col-xs-12 col-md-3" },
                  _react2.default.createElement(
                    "h1",
                    null,
                    gettext("Search")
                  )
                ),
                _react2.default.createElement(
                  "div",
                  { className: "col-xs-12 col-md-9" },
                  _react2.default.createElement(
                    "div",
                    { className: "row xs-margin-top sm-margin-top" },
                    _react2.default.createElement(
                      "div",
                      { className: "col-xs-12 col-sm-8 col-md-9" },
                      _react2.default.createElement(
                        "div",
                        { className: "form-group" },
                        _react2.default.createElement("input", {
                          className: "form-control",
                          disabled: this.props.search.isLoading || this.state.isLoading,
                          onChange: this.onQueryChange,
                          type: "text",
                          value: this.state.query
                        })
                      )
                    ),
                    _react2.default.createElement(
                      "div",
                      { className: "col-xs-12 col-sm-4 col-md-3" },
                      _react2.default.createElement(
                        "button",
                        {
                          className: "btn btn-primary btn-block btn-outline",
                          disabled: this.props.search.isLoading || this.state.isLoading
                        },
                        gettext("Search")
                      )
                    )
                  )
                )
              )
            )
          )
        )
      );
    }
  }]);

  return _class;
}(_form2.default);

exports.default = _class;

},{"../..":302,"../../reducers/posts":354,"../../reducers/search":357,"../../reducers/users":364,"../../services/ajax":365,"../../services/snackbar":376,"../../services/store":377,"../form":56,"react":"react"}],204:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.select = select;

exports.default = function (providers) {
  return providers.map(function (provider) {
    return {
      path: provider.url,
      component: (0, _reactRedux.connect)(select)(components[provider.id]),
      provider: provider
    };
  });
};

var _reactRedux = require("react-redux");

var _threads = require("./threads");

var _threads2 = _interopRequireDefault(_threads);

var _users = require("./users");

var _users2 = _interopRequireDefault(_users);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var components = {
  threads: _threads2.default,
  users: _users2.default
};

function select(store) {
  return {
    posts: store.posts,
    search: store.search,
    tick: store.tick.tick,
    user: store.auth.user,
    users: store.users
  };
}

},{"./threads":207,"./users":209,"react-redux":"react-redux"}],205:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (props) {
  return _react2.default.createElement(
    "div",
    { className: "page page-search" },
    _react2.default.createElement(_form2.default, { provider: props.provider, search: props.search }),
    _react2.default.createElement(
      "div",
      { className: "container" },
      _react2.default.createElement(
        "div",
        { className: "row" },
        _react2.default.createElement(
          "div",
          { className: "col-md-3" },
          _react2.default.createElement(_sidenav2.default, { providers: props.search.providers })
        ),
        _react2.default.createElement(
          "div",
          { className: "col-md-9" },
          props.children,
          _react2.default.createElement(SearchTime, { provider: props.provider, search: props.search })
        )
      )
    )
  );
};

exports.SearchTime = SearchTime;

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _form = require("./form");

var _form2 = _interopRequireDefault(_form);

var _sidenav = require("./sidenav");

var _sidenav2 = _interopRequireDefault(_sidenav);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function SearchTime(props) {
  var time = null;
  props.search.providers.forEach(function (p) {
    if (p.id === props.provider.id) {
      time = p.time;
    }
  });

  if (time === null) return null;

  var copy = gettext("Search took %(time)s s to complete");

  return _react2.default.createElement(
    "footer",
    { className: "search-footer" },
    _react2.default.createElement(
      "p",
      null,
      interpolate(copy, { time: time }, true)
    )
  );
}

},{"./form":203,"./sidenav":206,"react":"react"}],206:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (props) {
  return _react2.default.createElement(
    "div",
    { className: "list-group nav-side" },
    props.providers.map(function (provider) {
      return _react2.default.createElement(
        _reactRouter.Link,
        {
          activeClassName: "active",
          className: "list-group-item",
          key: provider.id,
          to: provider.url
        },
        _react2.default.createElement(
          "span",
          { className: "material-icon" },
          provider.icon
        ),
        provider.name,
        _react2.default.createElement(Badge, { results: provider.results })
      );
    })
  );
};

exports.Badge = Badge;

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _reactRouter = require("react-router");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function Badge(props) {
  if (!props.results) return null;

  var count = props.results.count;
  if (count > 1000000) {
    count = Math.ceil(count / 1000000) + "KK";
  } else if (count > 1000) {
    count = Math.ceil(count / 1000) + "K";
  }

  return _react2.default.createElement(
    "span",
    { className: "badge" },
    count
  );
}

},{"react":"react","react-router":"react-router"}],207:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

exports.default = function (props) {
  return _react2.default.createElement(
    _page2.default,
    { provider: props.route.provider, search: props.search },
    _react2.default.createElement(
      Blankslate,
      { query: props.search.query, posts: props.posts },
      _react2.default.createElement(_results2.default, _extends({
        provider: props.route.provider,
        query: props.search.query
      }, props.posts))
    )
  );
};

exports.Blankslate = Blankslate;

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _page = require("../page");

var _page2 = _interopRequireDefault(_page);

var _results = require("./results");

var _results2 = _interopRequireDefault(_results);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function Blankslate(props) {
  if (props.posts && props.posts.count) return props.children;

  if (props.query.length) {
    return _react2.default.createElement(
      "p",
      { className: "lead" },
      gettext("No threads matching search query have been found.")
    );
  }

  return _react2.default.createElement(
    "p",
    { className: "lead" },
    gettext("Enter at least two characters to search threads.")
  );
}

},{"../page":205,"./results":208,"react":"react"}],208:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LoadMore = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

exports.default = function (props) {
  return _react2.default.createElement(
    "div",
    null,
    _react2.default.createElement(_postFeed2.default, { isReady: true, posts: props.results }),
    _react2.default.createElement(LoadMore, props)
  );
};

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _postFeed = require("../../post-feed");

var _postFeed2 = _interopRequireDefault(_postFeed);

var _button = require("../../button");

var _button2 = _interopRequireDefault(_button);

var _misagoMarkup = require("../../misago-markup");

var _misagoMarkup2 = _interopRequireDefault(_misagoMarkup);

var _posts = require("../../../reducers/posts");

var _search = require("../../../reducers/search");

var _ajax = require("../../../services/ajax");

var _ajax2 = _interopRequireDefault(_ajax);

var _snackbar = require("../../../services/snackbar");

var _snackbar2 = _interopRequireDefault(_snackbar);

var _store = require("../../../services/store");

var _store2 = _interopRequireDefault(_store);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var LoadMore = exports.LoadMore = function (_React$Component) {
  _inherits(LoadMore, _React$Component);

  function LoadMore() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, LoadMore);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = LoadMore.__proto__ || Object.getPrototypeOf(LoadMore)).call.apply(_ref, [this].concat(args))), _this), _this.onClick = function () {
      _store2.default.dispatch((0, _posts.update)({
        isBusy: true
      }));

      _ajax2.default.get(_this.props.provider.api, {
        q: _this.props.query,
        page: _this.props.next
      }).then(function (providers) {
        providers.forEach(function (provider) {
          if (provider.id !== "threads") return;
          _store2.default.dispatch((0, _posts.append)(provider.results));
          _store2.default.dispatch((0, _search.updateProvider)(provider));
        });

        _store2.default.dispatch((0, _posts.update)({
          isBusy: false
        }));
      }, function (rejection) {
        _snackbar2.default.apiError(rejection);

        _store2.default.dispatch((0, _posts.update)({
          isBusy: false
        }));
      });
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(LoadMore, [{
    key: "render",
    value: function render() {
      if (!this.props.more) return null;

      return _react2.default.createElement(
        "div",
        { className: "pager-more" },
        _react2.default.createElement(
          _button2.default,
          {
            className: "btn btn-default btn-outline",
            loading: this.props.isBusy,
            onClick: this.onClick
          },
          gettext("Show more")
        )
      );
    }
  }]);

  return LoadMore;
}(_react2.default.Component);

},{"../../../reducers/posts":354,"../../../reducers/search":357,"../../../services/ajax":365,"../../../services/snackbar":376,"../../../services/store":377,"../../button":8,"../../misago-markup":60,"../../post-feed":122,"react":"react"}],209:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (props) {
  return _react2.default.createElement(
    _page2.default,
    { provider: props.route.provider, search: props.search },
    _react2.default.createElement(
      Blankslate,
      { query: props.search.query, users: props.users },
      _react2.default.createElement(_usersList2.default, { cols: 3, isReady: true, users: props.users })
    )
  );
};

exports.Blankslate = Blankslate;

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _page = require("../page");

var _page2 = _interopRequireDefault(_page);

var _usersList = require("../../users-list");

var _usersList2 = _interopRequireDefault(_usersList);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function Blankslate(props) {
  if (props.users.length) return props.children;

  if (props.query.length) {
    return _react2.default.createElement(
      "p",
      { className: "lead" },
      gettext("No users matching search query have been found.")
    );
  }

  return _react2.default.createElement(
    "p",
    { className: "lead" },
    gettext("Enter at least two characters to search users.")
  );
}

},{"../../users-list":284,"../page":205,"react":"react"}],210:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

exports.Icon = Icon;

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_React$Component) {
  _inherits(_class, _React$Component);

  function _class() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, _class);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = _class.__proto__ || Object.getPrototypeOf(_class)).call.apply(_ref, [this].concat(args))), _this), _this.change = function (value) {
      return function () {
        _this.props.onChange({
          target: {
            value: value
          }
        });
      };
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(_class, [{
    key: "getChoice",
    value: function getChoice() {
      var _this2 = this;

      var choice = null;
      this.props.choices.map(function (item) {
        if (item.value === _this2.props.value) {
          choice = item;
        }
      });
      return choice;
    }
  }, {
    key: "getIcon",
    value: function getIcon() {
      return this.getChoice().icon;
    }
  }, {
    key: "getLabel",
    value: function getLabel() {
      return this.getChoice().label;
    }
  }, {
    key: "render",
    value: function render() {
      var _this3 = this;

      return _react2.default.createElement(
        "div",
        { className: "btn-group btn-select-group" },
        _react2.default.createElement(
          "button",
          {
            type: "button",
            className: "btn btn-select dropdown-toggle",
            id: this.props.id || null,
            "data-toggle": "dropdown",
            "aria-haspopup": "true",
            "aria-expanded": "false",
            "aria-describedby": this.props["aria-describedby"] || null,
            disabled: this.props.disabled || false
          },
          _react2.default.createElement(Icon, { icon: this.getIcon() }),
          this.getLabel()
        ),
        _react2.default.createElement(
          "ul",
          { className: "dropdown-menu" },
          this.props.choices.map(function (item, i) {
            return _react2.default.createElement(
              "li",
              { key: i },
              _react2.default.createElement(
                "button",
                {
                  type: "button",
                  className: "btn-link",
                  onClick: _this3.change(item.value)
                },
                _react2.default.createElement(Icon, { icon: item.icon }),
                item.label
              )
            );
          })
        )
      );
    }
  }]);

  return _class;
}(_react2.default.Component);

exports.default = _class;
function Icon(_ref2) {
  var icon = _ref2.icon;

  if (!icon) return null;

  return _react2.default.createElement(
    "span",
    { className: "material-icon" },
    icon
  );
}

},{"react":"react"}],211:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _index = require("../index");

var _index2 = _interopRequireDefault(_index);

var _button = require("./button");

var _button2 = _interopRequireDefault(_button);

var _form = require("./form");

var _form2 = _interopRequireDefault(_form);

var _StartSocialAuth = require("./StartSocialAuth");

var _StartSocialAuth2 = _interopRequireDefault(_StartSocialAuth);

var _ajax = require("../services/ajax");

var _ajax2 = _interopRequireDefault(_ajax);

var _modal = require("../services/modal");

var _modal2 = _interopRequireDefault(_modal);

var _snackbar = require("../services/snackbar");

var _snackbar2 = _interopRequireDefault(_snackbar);

var _bannedPage = require("../utils/banned-page");

var _bannedPage2 = _interopRequireDefault(_bannedPage);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_Form) {
  _inherits(_class, _Form);

  function _class(props) {
    _classCallCheck(this, _class);

    var _this = _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).call(this, props));

    _this.state = {
      isLoading: false,
      showActivation: false,

      username: "",
      password: "",

      validators: {
        username: [],
        password: []
      }
    };
    return _this;
  }

  _createClass(_class, [{
    key: "clean",
    value: function clean() {
      if (!this.isValid()) {
        _snackbar2.default.error(gettext("Fill out both fields."));
        return false;
      } else {
        return true;
      }
    }
  }, {
    key: "send",
    value: function send() {
      return _ajax2.default.post(_index2.default.get("AUTH_API"), {
        username: this.state.username,
        password: this.state.password
      });
    }
  }, {
    key: "handleSuccess",
    value: function handleSuccess() {
      var form = $("#hidden-login-form");

      form.append('<input type="text" name="username" />');
      form.append('<input type="password" name="password" />');

      // fill out form with user credentials and submit it, this will tell
      // Misago to redirect user back to right page, and will trigger browser's
      // key ring feature
      form.find('input[type="hidden"]').val(_ajax2.default.getCsrfToken());
      form.find('input[name="redirect_to"]').val(window.location.pathname);
      form.find('input[name="username"]').val(this.state.username);
      form.find('input[name="password"]').val(this.state.password);
      form.submit();

      // keep form loading
      this.setState({
        isLoading: true
      });
    }
  }, {
    key: "handleError",
    value: function handleError(rejection) {
      if (rejection.status === 400) {
        if (rejection.code === "inactive_admin") {
          _snackbar2.default.info(rejection.detail);
        } else if (rejection.code === "inactive_user") {
          _snackbar2.default.info(rejection.detail);
          this.setState({
            showActivation: true
          });
        } else if (rejection.code === "banned") {
          (0, _bannedPage2.default)(rejection.detail);
          _modal2.default.hide();
        } else {
          _snackbar2.default.error(rejection.detail);
        }
      } else if (rejection.status === 403 && rejection.ban) {
        (0, _bannedPage2.default)(rejection.ban);
        _modal2.default.hide();
      } else {
        _snackbar2.default.apiError(rejection);
      }
    }
  }, {
    key: "getActivationButton",
    value: function getActivationButton() {
      if (!this.state.showActivation) return null;

      return _react2.default.createElement(
        "a",
        {
          className: "btn btn-success btn-block",
          href: _index2.default.get("REQUEST_ACTIVATION_URL")
        },
        gettext("Activate account")
      );
    }
  }, {
    key: "render",
    value: function render() {
      return _react2.default.createElement(
        "div",
        { className: "modal-dialog modal-sm modal-sign-in", role: "document" },
        _react2.default.createElement(
          "div",
          { className: "modal-content" },
          _react2.default.createElement(
            "div",
            { className: "modal-header" },
            _react2.default.createElement(
              "button",
              {
                "aria-label": gettext("Close"),
                className: "close",
                "data-dismiss": "modal",
                type: "button"
              },
              _react2.default.createElement(
                "span",
                { "aria-hidden": "true" },
                "\xD7"
              )
            ),
            _react2.default.createElement(
              "h4",
              { className: "modal-title" },
              gettext("Sign in")
            )
          ),
          _react2.default.createElement(
            "form",
            { onSubmit: this.handleSubmit },
            _react2.default.createElement(
              "div",
              { className: "modal-body" },
              _react2.default.createElement(_StartSocialAuth2.default, {
                buttonLabel: gettext("Sign in with %(site)s"),
                formLabel: gettext("Or use your forum account:"),
                labelClassName: "text-center"
              }),
              _react2.default.createElement(
                "div",
                { className: "form-group" },
                _react2.default.createElement(
                  "div",
                  { className: "control-input" },
                  _react2.default.createElement("input", {
                    className: "form-control input-lg",
                    disabled: this.state.isLoading,
                    id: "id_username",
                    onChange: this.bindInput("username"),
                    placeholder: gettext("Username or e-mail"),
                    type: "text",
                    value: this.state.username
                  })
                )
              ),
              _react2.default.createElement(
                "div",
                { className: "form-group" },
                _react2.default.createElement(
                  "div",
                  { className: "control-input" },
                  _react2.default.createElement("input", {
                    className: "form-control input-lg",
                    disabled: this.state.isLoading,
                    id: "id_password",
                    onChange: this.bindInput("password"),
                    placeholder: gettext("Password"),
                    type: "password",
                    value: this.state.password
                  })
                )
              )
            ),
            _react2.default.createElement(
              "div",
              { className: "modal-footer" },
              this.getActivationButton(),
              _react2.default.createElement(
                _button2.default,
                {
                  className: "btn-primary btn-block",
                  loading: this.state.isLoading
                },
                gettext("Sign in")
              ),
              _react2.default.createElement(
                "a",
                {
                  className: "btn btn-default btn-block",
                  href: _index2.default.get("FORGOTTEN_PASSWORD_URL")
                },
                gettext("Forgot password?")
              )
            )
          )
        )
      );
    }
  }]);

  return _class;
}(_form2.default);

exports.default = _class;

},{"../index":302,"../services/ajax":365,"../services/modal":371,"../services/snackbar":376,"../utils/banned-page":379,"./StartSocialAuth":2,"./button":8,"./form":56,"react":"react"}],212:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Snackbar = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

exports.select = select;

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var TYPES_CLASSES = {
  info: "alert-info",
  success: "alert-success",
  warning: "alert-warning",
  error: "alert-danger"
};

var Snackbar = exports.Snackbar = function (_React$Component) {
  _inherits(Snackbar, _React$Component);

  function Snackbar() {
    _classCallCheck(this, Snackbar);

    return _possibleConstructorReturn(this, (Snackbar.__proto__ || Object.getPrototypeOf(Snackbar)).apply(this, arguments));
  }

  _createClass(Snackbar, [{
    key: "getSnackbarClass",
    value: function getSnackbarClass() {
      var snackbarClass = "alerts-snackbar";
      if (this.props.isVisible) {
        snackbarClass += " in";
      } else {
        snackbarClass += " out";
      }
      return snackbarClass;
    }
  }, {
    key: "render",
    value: function render() {
      return _react2.default.createElement(
        "div",
        { className: this.getSnackbarClass() },
        _react2.default.createElement(
          "p",
          { className: "alert " + TYPES_CLASSES[this.props.type] },
          this.props.message
        )
      );
    }
  }]);

  return Snackbar;
}(_react2.default.Component);

function select(state) {
  return state.snackbar;
}

},{"react":"react"}],213:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _header = require("./header");

var _header2 = _interopRequireDefault(_header);

var _ = require("../..");

var _2 = _interopRequireDefault(_);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Complete = function Complete(_ref) {
  var activation = _ref.activation,
      backend_name = _ref.backend_name,
      username = _ref.username;

  var icon = "";
  var message = "";
  if (activation === "user") {
    message = gettext("%(username)s, your account has been created but you need to activate it before you will be able to sign in.");
  } else if (activation === "admin") {
    message = gettext("%(username)s, your account has been created but board administrator will have to activate it before you will be able to sign in.");
  } else {
    message = gettext("%(username)s, your account has been created and you have been signed in to it.");
  }

  if (activation === "active") {
    icon = "check";
  } else {
    icon = "info_outline";
  }

  return _react2.default.createElement(
    "div",
    { className: "page page-social-auth page-social-sauth-register" },
    _react2.default.createElement(_header2.default, { backendName: backend_name }),
    _react2.default.createElement(
      "div",
      { className: "container" },
      _react2.default.createElement(
        "div",
        { className: "row" },
        _react2.default.createElement(
          "div",
          { className: "col-md-6 col-md-offset-3" },
          _react2.default.createElement(
            "div",
            { className: "panel panel-default panel-form" },
            _react2.default.createElement(
              "div",
              { className: "panel-heading" },
              _react2.default.createElement(
                "h3",
                { className: "panel-title" },
                gettext("Registration completed!")
              )
            ),
            _react2.default.createElement(
              "div",
              { className: "panel-body panel-message-body" },
              _react2.default.createElement(
                "div",
                { className: "message-icon" },
                _react2.default.createElement(
                  "span",
                  { className: "material-icon" },
                  icon
                )
              ),
              _react2.default.createElement(
                "div",
                { className: "message-body" },
                _react2.default.createElement(
                  "p",
                  { className: "lead" },
                  interpolate(message, { username: username }, true)
                ),
                _react2.default.createElement(
                  "p",
                  { className: "help-block" },
                  _react2.default.createElement(
                    "a",
                    {
                      className: "btn btn-default",
                      href: _2.default.get("MISAGO_PATH")
                    },
                    gettext("Return to forum index")
                  )
                )
              )
            )
          )
        )
      )
    )
  );
};

exports.default = Complete;

},{"../..":302,"./header":214,"react":"react"}],214:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Header = function Header(_ref) {
  var backendName = _ref.backendName;

  var pageTitleTpl = gettext("Sign in with %(backend)s");
  var pageTitle = interpolate(pageTitleTpl, { backend: backendName }, true);

  return _react2.default.createElement(
    "div",
    { className: "page-header-bg" },
    _react2.default.createElement(
      "div",
      { className: "page-header" },
      _react2.default.createElement(
        "div",
        { className: "container" },
        _react2.default.createElement(
          "h1",
          null,
          pageTitle
        )
      )
    )
  );
};

exports.default = Header;

},{"react":"react"}],215:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _register = require("./register");

var _register2 = _interopRequireDefault(_register);

var _complete = require("./complete");

var _complete2 = _interopRequireDefault(_complete);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var SocialAuth = function (_React$Component) {
  _inherits(SocialAuth, _React$Component);

  function SocialAuth(props) {
    _classCallCheck(this, SocialAuth);

    var _this = _possibleConstructorReturn(this, (SocialAuth.__proto__ || Object.getPrototypeOf(SocialAuth)).call(this, props));

    _this.handleRegistrationComplete = function (_ref) {
      var activation = _ref.activation,
          email = _ref.email,
          step = _ref.step,
          username = _ref.username;

      _this.setState({ activation: activation, email: email, step: step, username: username });
    };

    _this.state = {
      step: props.step,

      activation: props.activation || "",
      email: props.email || "",
      username: props.username || ""
    };
    return _this;
  }

  _createClass(SocialAuth, [{
    key: "render",
    value: function render() {
      var _props = this.props,
          backend_name = _props.backend_name,
          url = _props.url;
      var _state = this.state,
          activation = _state.activation,
          email = _state.email,
          step = _state.step,
          username = _state.username;


      if (step === "register") {
        return _react2.default.createElement(_register2.default, {
          backend_name: backend_name,
          email: email,
          url: url,
          username: username,
          onRegistrationComplete: this.handleRegistrationComplete
        });
      }

      return _react2.default.createElement(_complete2.default, {
        activation: activation,
        backend_name: backend_name,
        email: email,
        url: url,
        username: username
      });
    }
  }]);

  return SocialAuth;
}(_react2.default.Component);

exports.default = SocialAuth;

},{"./complete":213,"./register":216,"react":"react"}],216:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _ = require("../..");

var _2 = _interopRequireDefault(_);

var _RegisterLegalFootnote = require("../RegisterLegalFootnote");

var _RegisterLegalFootnote2 = _interopRequireDefault(_RegisterLegalFootnote);

var _button = require("../button");

var _button2 = _interopRequireDefault(_button);

var _form = require("../form");

var _form2 = _interopRequireDefault(_form);

var _formGroup = require("../form-group");

var _formGroup2 = _interopRequireDefault(_formGroup);

var _ajax = require("../../services/ajax");

var _ajax2 = _interopRequireDefault(_ajax);

var _snackbar = require("../../services/snackbar");

var _snackbar2 = _interopRequireDefault(_snackbar);

var _validators = require("../../utils/validators");

var validators = _interopRequireWildcard(_validators);

var _header = require("./header");

var _header2 = _interopRequireDefault(_header);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var Register = function (_Form) {
  _inherits(Register, _Form);

  function Register(props) {
    _classCallCheck(this, Register);

    var _this = _possibleConstructorReturn(this, (Register.__proto__ || Object.getPrototypeOf(Register)).call(this, props));

    _this.handlePrivacyPolicyChange = function (event) {
      var value = event.target.value;
      _this.handleToggleAgreement("privacyPolicy", value);
    };

    _this.handleTermsOfServiceChange = function (event) {
      var value = event.target.value;
      _this.handleToggleAgreement("termsOfService", value);
    };

    _this.handleToggleAgreement = function (agreement, value) {
      _this.setState(function (prevState, props) {
        if (prevState[agreement] === null) {
          var _errors = _extends({}, prevState.errors, _defineProperty({}, agreement, null));
          return _defineProperty({ errors: _errors }, agreement, value);
        }

        var validator = _this.state.validators[agreement][0];
        var errors = _extends({}, prevState.errors, _defineProperty({}, agreement, [validator(null)]));
        return _defineProperty({ errors: errors }, agreement, null);
      });
    };

    var formValidators = {
      email: [validators.email()],
      username: [validators.usernameContent()]
    };

    if (!!_2.default.get("TERMS_OF_SERVICE_ID")) {
      formValidators.termsOfService = [validators.requiredTermsOfService()];
    }

    if (!!_2.default.get("PRIVACY_POLICY_ID")) {
      formValidators.privacyPolicy = [validators.requiredPrivacyPolicy()];
    }

    _this.state = {
      email: props.email || "",
      emailProtected: !!props.email,
      username: props.username || "",

      termsOfService: null,
      privacyPolicy: null,

      validators: formValidators,
      errors: {},

      isLoading: false
    };
    return _this;
  }

  _createClass(Register, [{
    key: "clean",
    value: function clean() {
      var errors = this.validate();
      var lengths = [this.state.email.trim().length, this.state.username.trim().length];

      if (lengths.indexOf(0) !== -1) {
        _snackbar2.default.error(gettext("Fill out all fields."));
        return false;
      }

      var validators = this.state.validators;


      var checkTermsOfService = !!_2.default.get("TERMS_OF_SERVICE_ID");
      if (checkTermsOfService && this.state.termsOfService === null) {
        _snackbar2.default.error(validators.termsOfService[0](null));
        return false;
      }

      var checkPrivacyPolicy = !!_2.default.get("PRIVACY_POLICY_ID");
      if (checkPrivacyPolicy && this.state.privacyPolicy === null) {
        _snackbar2.default.error(validators.privacyPolicy[0](null));
        _snackbar2.default.error(gettext("You need to accept the privacy policy."));
        return false;
      }

      return true;
    }
  }, {
    key: "send",
    value: function send() {
      return _ajax2.default.post(this.props.url, {
        email: this.state.email,
        username: this.state.username,
        terms_of_service: this.state.termsOfService,
        privacy_policy: this.state.privacyPolicy
      });
    }
  }, {
    key: "handleSuccess",
    value: function handleSuccess(response) {
      var onRegistrationComplete = this.props.onRegistrationComplete;

      onRegistrationComplete(response);
    }
  }, {
    key: "handleError",
    value: function handleError(rejection) {
      if (rejection.status === 200) {
        // We've entered "errored" state because response is HTML instead of exptected JSON
        var onRegistrationComplete = this.props.onRegistrationComplete;
        var username = this.state.username;

        onRegistrationComplete({ activation: "active", step: "done", username: username });
      } else if (rejection.status === 400) {
        var stateUpdate = { errors: rejection };
        if (rejection.email) {
          stateUpdate.emailProtected = false;
        }
        this.setState(stateUpdate);
      } else {
        _snackbar2.default.apiError(rejection);
      }
    }
  }, {
    key: "render",
    value: function render() {
      var backend_name = this.props.backend_name;
      var _state = this.state,
          email = _state.email,
          emailProtected = _state.emailProtected,
          username = _state.username,
          isLoading = _state.isLoading;


      var emailHelpText = null;
      if (emailProtected) {
        var emailHelpTextTpl = gettext("Your e-mail address has been verified by %(backend)s.");
        emailHelpText = interpolate(emailHelpTextTpl, { backend: backend_name }, true);
      }

      return _react2.default.createElement(
        "div",
        { className: "page page-social-auth page-social-sauth-register" },
        _react2.default.createElement(_header2.default, { backendName: backend_name }),
        _react2.default.createElement(
          "div",
          { className: "container" },
          _react2.default.createElement(
            "div",
            { className: "row" },
            _react2.default.createElement(
              "div",
              { className: "col-md-6 col-md-offset-3" },
              _react2.default.createElement(
                "form",
                { onSubmit: this.handleSubmit },
                _react2.default.createElement(
                  "div",
                  { className: "panel panel-default panel-form" },
                  _react2.default.createElement(
                    "div",
                    { className: "panel-heading" },
                    _react2.default.createElement(
                      "h3",
                      { className: "panel-title" },
                      gettext("Complete your details")
                    )
                  ),
                  _react2.default.createElement(
                    "div",
                    { className: "panel-body" },
                    _react2.default.createElement(
                      _formGroup2.default,
                      {
                        "for": "id_username",
                        label: gettext("Username"),
                        validation: this.state.errors.username
                      },
                      _react2.default.createElement("input", {
                        type: "text",
                        id: "id_username",
                        className: "form-control",
                        disabled: isLoading,
                        onChange: this.bindInput("username"),
                        value: username
                      })
                    ),
                    _react2.default.createElement(
                      _formGroup2.default,
                      {
                        "for": "id_email",
                        label: gettext("E-mail address"),
                        helpText: emailHelpText,
                        validation: emailProtected ? null : this.state.errors.email
                      },
                      _react2.default.createElement("input", {
                        type: "email",
                        id: "id_email",
                        className: "form-control",
                        disabled: isLoading || emailProtected,
                        onChange: this.bindInput("email"),
                        value: email
                      })
                    ),
                    _react2.default.createElement(_RegisterLegalFootnote2.default, {
                      errors: this.state.errors,
                      privacyPolicy: this.state.privacyPolicy,
                      termsOfService: this.state.termsOfService,
                      onPrivacyPolicyChange: this.handlePrivacyPolicyChange,
                      onTermsOfServiceChange: this.handleTermsOfServiceChange
                    })
                  ),
                  _react2.default.createElement(
                    "div",
                    { className: "panel-footer" },
                    _react2.default.createElement(
                      _button2.default,
                      {
                        className: "btn-primary",
                        loading: this.state.isLoading
                      },
                      gettext("Sign in")
                    )
                  )
                )
              )
            )
          )
        )
      );
    }
  }]);

  return Register;
}(_form2.default);

exports.default = Register;

},{"../..":302,"../../services/ajax":365,"../../services/snackbar":376,"../../utils/validators":393,"../RegisterLegalFootnote":1,"../button":8,"../form":56,"../form-group":55,"./header":214,"react":"react"}],217:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (props) {
  return _react2.default.createElement(
    "div",
    { className: "page-breadcrumbs" },
    _react2.default.createElement(
      "div",
      { className: "container" },
      _react2.default.createElement(
        "ol",
        { className: "breadcrumb hidden-xs" },
        props.path.map(function (item) {
          return _react2.default.createElement(Breadcrumb, { key: item.id, node: item });
        })
      ),
      _react2.default.createElement(GoBack, props)
    )
  );
};

exports.Breadcrumb = Breadcrumb;
exports.GoBack = GoBack;

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function Breadcrumb(props) {
  return _react2.default.createElement(
    "li",
    null,
    _react2.default.createElement(
      "a",
      { href: props.node.url.index },
      props.node.name
    )
  );
}

function GoBack(props) {
  var lastItem = props.path[props.path.length - 1];

  return _react2.default.createElement(
    "a",
    { href: lastItem.url.index, className: "go-back-sm visible-xs-block" },
    _react2.default.createElement(
      "span",
      { className: "material-icon" },
      "chevron_left"
    ),
    lastItem.name
  );
}

},{"react":"react"}],218:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

exports.Moderation = Moderation;

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _breadcrumbs = require("./breadcrumbs");

var _breadcrumbs2 = _interopRequireDefault(_breadcrumbs);

var _thread = require("../moderation/thread");

var _stats = require("./stats");

var _stats2 = _interopRequireDefault(_stats);

var _form = require("../../form");

var _form2 = _interopRequireDefault(_form);

var _validators = require("../../posting/utils/validators");

var _ajax = require("../../../services/ajax");

var _ajax2 = _interopRequireDefault(_ajax);

var _snackbar = require("../../../services/snackbar");

var _snackbar2 = _interopRequireDefault(_snackbar);

var _store = require("../../../services/store");

var _store2 = _interopRequireDefault(_store);

var _thread2 = require("../../../reducers/thread");

var thread = _interopRequireWildcard(_thread2);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_Form) {
  _inherits(_class, _Form);

  function _class(props) {
    _classCallCheck(this, _class);

    var _this = _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).call(this, props));

    _this.onChange = function (event) {
      _this.changeValue("title", event.target.value);
    };

    _this.onEdit = function () {
      _this.setState({
        isEditing: true
      });
    };

    _this.onCancel = function () {
      _this.setState({
        title: _this.props.thread.title,

        isEditing: false
      });
    };

    _this.state = {
      isEditing: false,
      isLoading: false,

      title: props.thread.title,

      validators: {
        title: (0, _validators.getTitleValidators)()
      },
      errors: {}
    };
    return _this;
  }

  _createClass(_class, [{
    key: "clean",
    value: function clean() {
      if (!this.state.title.trim().length) {
        _snackbar2.default.error(gettext("You have to enter thread title."));
        return false;
      }

      var errors = this.validate();

      if (errors.title) {
        _snackbar2.default.error(errors.title[0]);
        return false;
      }

      return true;
    }
  }, {
    key: "send",
    value: function send() {
      return _ajax2.default.patch(this.props.thread.api.index, [{ op: "replace", path: "title", value: this.state.title }]);
    }
  }, {
    key: "handleSuccess",
    value: function handleSuccess(data) {
      _store2.default.dispatch(thread.update(data));

      this.setState({
        isEditing: false
      });
    }
  }, {
    key: "handleError",
    value: function handleError(rejection) {
      if (rejection.status === 400) {
        _snackbar2.default.error(rejection.detail[0]);
      } else {
        _snackbar2.default.apiError(rejection);
      }
    }
  }, {
    key: "render",
    value: function render() {
      var _props = this.props,
          thread = _props.thread,
          user = _props.user;

      var showModeration = !!user.id && (0, _thread.isModerationVisible)(thread);

      if (this.state.isEditing) {
        return _react2.default.createElement(
          "div",
          { className: "page-header" },
          _react2.default.createElement(_breadcrumbs2.default, { path: thread.path }),
          _react2.default.createElement(
            "div",
            { className: "container" },
            _react2.default.createElement(
              "div",
              { className: "row xs-margin-top title-edit-form" },
              _react2.default.createElement(
                "form",
                { onSubmit: this.handleSubmit },
                _react2.default.createElement(
                  "div",
                  { className: "col-sm-6 col-md-6" },
                  _react2.default.createElement("input", {
                    className: "form-control",
                    type: "text",
                    value: this.state.title,
                    onChange: this.onChange
                  })
                ),
                _react2.default.createElement(
                  "div",
                  { className: "col-sm-6 col-md-4" },
                  _react2.default.createElement(
                    "div",
                    { className: "row xs-margin-top-half sm-margin-top-no md-margin-top-no" },
                    _react2.default.createElement(
                      "div",
                      { className: "col-xs-6" },
                      _react2.default.createElement(
                        "button",
                        {
                          className: "btn btn-primary btn-block btn-outline",
                          disabled: this.state.isLoading,
                          title: gettext("Change title")
                        },
                        gettext("Save changes")
                      )
                    ),
                    _react2.default.createElement(
                      "div",
                      { className: "col-xs-6" },
                      _react2.default.createElement(
                        "button",
                        {
                          className: "btn btn-default btn-block btn-outline",
                          disabled: this.state.isLoading,
                          onClick: this.onCancel,
                          title: gettext("Cancel"),
                          type: "button"
                        },
                        gettext("Cancel")
                      )
                    )
                  )
                )
              )
            )
          ),
          _react2.default.createElement(_stats2.default, { thread: thread })
        );
      } else if (user.id && thread.acl.can_edit) {
        return _react2.default.createElement(
          "div",
          { className: "page-header" },
          _react2.default.createElement(_breadcrumbs2.default, { path: thread.path }),
          _react2.default.createElement(
            "div",
            { className: "container" },
            _react2.default.createElement(
              "div",
              { className: "row" },
              _react2.default.createElement(
                "div",
                {
                  className: showModeration ? "col-sm-9 col-md-8" : "col-sm-10 col-md-10"
                },
                _react2.default.createElement(
                  "h1",
                  null,
                  thread.title
                )
              ),
              _react2.default.createElement(
                "div",
                {
                  className: showModeration ? "col-sm-3 col-md-4" : "col-sm-3 col-md-2"
                },
                _react2.default.createElement(
                  "div",
                  { className: "row xs-margin-top md-margin-top-no" },
                  _react2.default.createElement(
                    "div",
                    { className: showModeration ? "col-xs-6" : "col-xs-12" },
                    _react2.default.createElement(
                      "button",
                      {
                        className: "btn btn-default btn-block btn-outline",
                        onClick: this.onEdit,
                        title: gettext("Edit title"),
                        type: "button"
                      },
                      _react2.default.createElement(
                        "span",
                        { className: "material-icon" },
                        "edit"
                      ),
                      _react2.default.createElement(
                        "span",
                        { className: "hidden-sm" },
                        gettext("Edit")
                      )
                    )
                  ),
                  showModeration && _react2.default.createElement(Moderation, this.props)
                )
              )
            )
          ),
          _react2.default.createElement(_stats2.default, { thread: thread })
        );
      } else if (showModeration) {
        return _react2.default.createElement(
          "div",
          { className: "page-header" },
          _react2.default.createElement(_breadcrumbs2.default, { path: thread.path }),
          _react2.default.createElement(
            "div",
            { className: "container" },
            _react2.default.createElement(
              "div",
              { className: "row" },
              _react2.default.createElement(
                "div",
                { className: "col-sm-9 col-md-10" },
                _react2.default.createElement(
                  "h1",
                  null,
                  thread.title
                )
              ),
              _react2.default.createElement(
                "div",
                { className: "col-sm-3 col-md-2" },
                _react2.default.createElement(
                  "div",
                  { className: "row xs-margin-top md-margin-top-no" },
                  _react2.default.createElement(Moderation, _extends({ isSingle: true }, this.props))
                )
              )
            )
          ),
          _react2.default.createElement(_stats2.default, { thread: thread })
        );
      }

      return _react2.default.createElement(
        "div",
        { className: "page-header" },
        _react2.default.createElement(_breadcrumbs2.default, { path: thread.path }),
        _react2.default.createElement(
          "div",
          { className: "container" },
          _react2.default.createElement(
            "h1",
            null,
            thread.title
          )
        ),
        _react2.default.createElement(_stats2.default, { thread: thread })
      );
    }
  }]);

  return _class;
}(_form2.default);

exports.default = _class;
function Moderation(props) {
  return _react2.default.createElement(
    "div",
    { className: props.isSingle ? "col-xs-12" : "col-xs-6" },
    _react2.default.createElement(
      "div",
      { className: "btn-group btn-group-justified" },
      _react2.default.createElement(
        "div",
        { className: "btn-group" },
        _react2.default.createElement(
          "button",
          {
            "aria-expanded": "false",
            "aria-haspopup": "true",
            className: "btn btn-default btn-outline dropdown-toggle",
            "data-toggle": "dropdown",
            disabled: props.thread.isBusy,
            type: "button"
          },
          _react2.default.createElement(
            "span",
            { className: "material-icon" },
            "settings"
          ),
          _react2.default.createElement(
            "span",
            { className: props.isSingle ? "" : "hidden-sm" },
            gettext("Moderation")
          )
        ),
        _react2.default.createElement(_thread.ModerationControls, {
          posts: props.posts,
          thread: props.thread,
          user: props.user
        })
      )
    )
  );
}

},{"../../../reducers/thread":360,"../../../services/ajax":365,"../../../services/snackbar":376,"../../../services/store":377,"../../form":56,"../../posting/utils/validators":144,"../moderation/thread":227,"./breadcrumbs":217,"./stats":219,"react":"react"}],219:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Weight = Weight;
exports.Unapproved = Unapproved;
exports.IsHidden = IsHidden;
exports.IsClosed = IsClosed;
exports.Replies = Replies;
exports.LastReply = LastReply;

exports.default = function (props) {
  return _react2.default.createElement(
    "div",
    { className: "header-stats" },
    _react2.default.createElement(
      "div",
      { className: "container" },
      _react2.default.createElement(
        "ul",
        { className: "list-inline" },
        _react2.default.createElement(Weight, { thread: props.thread }),
        _react2.default.createElement(Unapproved, { thread: props.thread }),
        _react2.default.createElement(IsHidden, { thread: props.thread }),
        _react2.default.createElement(IsClosed, { thread: props.thread }),
        _react2.default.createElement(Replies, { thread: props.thread }),
        _react2.default.createElement(LastReply, { thread: props.thread })
      )
    )
  );
};

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _escapeHtml = require("../../../utils/escape-html");

var _escapeHtml2 = _interopRequireDefault(_escapeHtml);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var LAST_POSTER_URL = '<a href="%(url)s" class="poster-title">%(user)s</a>';
var LAST_POSTER_SPAN = '<span class="poster-title">%(user)s</span>';
var LAST_REPLY = '<abbr class="last-title" title="%(absolute)s">%(relative)s</abbr>';

function Weight(props) {
  if (props.thread.weight == 2) {
    return _react2.default.createElement(
      "li",
      { className: "thread-pinned-globally" },
      _react2.default.createElement(
        "span",
        { className: "material-icon" },
        "bookmark"
      ),
      _react2.default.createElement(
        "span",
        { className: "icon-legend" },
        gettext("Pinned globally")
      )
    );
  } else if (props.thread.weight == 1) {
    return _react2.default.createElement(
      "li",
      { className: "thread-pinned-locally" },
      _react2.default.createElement(
        "span",
        { className: "material-icon" },
        "bookmark_border"
      ),
      _react2.default.createElement(
        "span",
        { className: "icon-legend" },
        gettext("Pinned locally")
      )
    );
  } else {
    return null;
  }
}

function Unapproved(props) {
  if (props.thread.is_unapproved) {
    return _react2.default.createElement(
      "li",
      { className: "thread-unapproved" },
      _react2.default.createElement(
        "span",
        { className: "material-icon" },
        "remove_circle"
      ),
      _react2.default.createElement(
        "span",
        { className: "icon-legend" },
        gettext("Unapproved")
      )
    );
  } else if (props.thread.has_unapproved_posts) {
    return _react2.default.createElement(
      "li",
      { className: "thread-unapproved-posts" },
      _react2.default.createElement(
        "span",
        { className: "material-icon" },
        "remove_circle_outline"
      ),
      _react2.default.createElement(
        "span",
        { className: "icon-legend" },
        gettext("Unapproved posts")
      )
    );
  } else {
    return null;
  }
}

function IsHidden(props) {
  if (props.thread.is_hidden) {
    return _react2.default.createElement(
      "li",
      { className: "thread-hidden" },
      _react2.default.createElement(
        "span",
        { className: "material-icon" },
        "visibility_off"
      ),
      _react2.default.createElement(
        "span",
        { className: "icon-legend" },
        gettext("Hidden")
      )
    );
  } else {
    return null;
  }
}

function IsClosed(props) {
  if (props.thread.is_closed) {
    return _react2.default.createElement(
      "li",
      { className: "thread-closed" },
      _react2.default.createElement(
        "span",
        { className: "material-icon" },
        "lock_outline"
      ),
      _react2.default.createElement(
        "span",
        { className: "icon-legend" },
        gettext("Closed")
      )
    );
  } else {
    return null;
  }
}

function Replies(props) {
  var message = ngettext("%(replies)s reply", "%(replies)s replies", props.thread.replies);
  var legend = interpolate(message, { replies: props.thread.replies }, true);

  return _react2.default.createElement(
    "li",
    { className: "thread-replies" },
    _react2.default.createElement(
      "span",
      { className: "material-icon" },
      "forum"
    ),
    _react2.default.createElement(
      "span",
      { className: "icon-legend" },
      legend
    )
  );
}

function LastReply(props) {
  var user = null;
  if (props.thread.url.last_poster) {
    user = interpolate(LAST_POSTER_URL, {
      url: (0, _escapeHtml2.default)(props.thread.url.last_poster),
      user: (0, _escapeHtml2.default)(props.thread.last_poster_name)
    }, true);
  } else {
    user = interpolate(LAST_POSTER_SPAN, {
      user: (0, _escapeHtml2.default)(props.thread.last_poster_name)
    }, true);
  }

  var date = interpolate(LAST_REPLY, {
    absolute: (0, _escapeHtml2.default)(props.thread.last_post_on.format("LLL")),
    relative: (0, _escapeHtml2.default)(props.thread.last_post_on.fromNow())
  }, true);

  var message = interpolate((0, _escapeHtml2.default)(gettext("last reply by %(user)s %(date)s")), {
    date: date,
    user: user
  }, true);

  return _react2.default.createElement("li", {
    className: "thread-last-reply",
    dangerouslySetInnerHTML: { __html: message }
  });
}

},{"../../../utils/escape-html":383,"react":"react"}],220:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.approve = approve;
exports.protect = protect;
exports.unprotect = unprotect;
exports.hide = hide;
exports.unhide = unhide;
exports.patch = patch;
exports.merge = merge;
exports.remove = remove;

var _moment = require("moment");

var _moment2 = _interopRequireDefault(_moment);

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _post = require("../../../../reducers/post");

var post = _interopRequireWildcard(_post);

var _posts = require("../../../../reducers/posts");

var posts = _interopRequireWildcard(_posts);

var _ajax = require("../../../../services/ajax");

var _ajax2 = _interopRequireDefault(_ajax);

var _modal = require("../../../../services/modal");

var _modal2 = _interopRequireDefault(_modal);

var _snackbar = require("../../../../services/snackbar");

var _snackbar2 = _interopRequireDefault(_snackbar);

var _store = require("../../../../services/store");

var _store2 = _interopRequireDefault(_store);

var _errorsList = require("./errors-list");

var _errorsList2 = _interopRequireDefault(_errorsList);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function approve(props) {
  var selection = props.selection;


  var ops = [{ op: "replace", path: "is-unapproved", value: false }];

  var newState = selection.map(function (post) {
    return {
      id: post.id,
      is_unapproved: false
    };
  });

  var previousState = selection.map(function (post) {
    return {
      id: post.id,
      is_unapproved: post.is_unapproved
    };
  });

  patch(props, ops, newState, previousState);
}

function protect(props) {
  var selection = props.selection;


  var ops = [{ op: "replace", path: "is-protected", value: true }];

  var newState = selection.map(function (post) {
    return {
      id: post.id,
      is_protected: true
    };
  });

  var previousState = selection.map(function (post) {
    return {
      id: post.id,
      is_protected: post.is_protected
    };
  });

  patch(props, ops, newState, previousState);
}

function unprotect(props) {
  var selection = props.selection;


  var ops = [{ op: "replace", path: "is-protected", value: false }];

  var newState = selection.map(function (post) {
    return {
      id: post.id,
      is_protected: false
    };
  });

  var previousState = selection.map(function (post) {
    return {
      id: post.id,
      is_protected: post.is_protected
    };
  });

  patch(props, ops, newState, previousState);
}

function hide(props) {
  var selection = props.selection;


  var ops = [{ op: "replace", path: "is-hidden", value: true }];

  var newState = selection.map(function (post) {
    return {
      id: post.id,
      is_hidden: true,
      hidden_on: (0, _moment2.default)(),
      hidden_by_name: props.user.username,
      url: Object.assign(post.url, {
        hidden_by: props.user.url
      })
    };
  });

  var previousState = selection.map(function (post) {
    return {
      id: post.id,
      is_hidden: post.is_hidden,
      hidden_on: post.hidden_on,
      hidden_by_name: post.hidden_by_name,
      url: post.url
    };
  });

  patch(props, ops, newState, previousState);
}

function unhide(props) {
  var selection = props.selection;


  var ops = [{ op: "replace", path: "is-hidden", value: false }];

  var newState = selection.map(function (post) {
    return {
      id: post.id,
      is_hidden: false,
      hidden_on: (0, _moment2.default)(),
      hidden_by_name: props.user.username,
      url: Object.assign(post.url, {
        hidden_by: props.user.url
      })
    };
  });

  var previousState = selection.map(function (post) {
    return {
      id: post.id,
      is_hidden: post.is_hidden,
      hidden_on: post.hidden_on,
      hidden_by_name: post.hidden_by_name,
      url: post.url
    };
  });

  patch(props, ops, newState, previousState);
}

function patch(props, ops, newState, previousState) {
  var selection = props.selection,
      thread = props.thread;

  // patch selected items

  newState.forEach(function (item) {
    post.patch(item, item);
  });

  // deselect all the things
  _store2.default.dispatch(posts.deselectAll());

  // call ajax
  var data = {
    ops: ops,

    ids: selection.map(function (post) {
      return post.id;
    })
  };

  _ajax2.default.patch(thread.api.posts.index, data).then(function (data) {
    data.forEach(function (item) {
      _store2.default.dispatch(post.patch(item, item));
    });
  }, function (rejection) {
    if (rejection.status !== 400) {
      // rollback all
      previousState.forEach(function (item) {
        _store2.default.dispatch(post.patch(item, item));
      });
      return _snackbar2.default.apiError(rejection);
    }

    var errors = [];
    var rollback = [];

    rejection.forEach(function (item) {
      if (item.detail) {
        errors.push(item);
        rollback.push(item.id);
      } else {
        _store2.default.dispatch(post.patch(item, item));
      }

      previousState.forEach(function (item) {
        if (rollback.indexOf(item) !== -1) {
          _store2.default.dispatch(post.patch(item, item));
        }
      });
    });

    var posts = {};
    selection.forEach(function (item) {
      posts[item.id] = item;
    });

    _modal2.default.show(_react2.default.createElement(_errorsList2.default, { errors: errors, posts: posts }));
  });
}

function merge(props) {
  var confirmed = confirm(gettext("Are you sure you want to merge selected posts? This action is not reversible!"));
  if (!confirmed) {
    return;
  }

  props.selection.slice(1).map(function (selection) {
    _store2.default.dispatch(post.patch(selection, {
      isDeleted: true
    }));
  });

  _ajax2.default.post(props.thread.api.posts.merge, {
    posts: props.selection.map(function (post) {
      return post.id;
    })
  }).then(function (data) {
    _store2.default.dispatch(post.patch(data, post.hydrate(data)));
  }, function (rejection) {
    if (rejection.status === 400) {
      _snackbar2.default.error(rejection.detail);
    } else {
      _snackbar2.default.apiError(rejection);
    }

    props.selection.slice(1).map(function (selection) {
      _store2.default.dispatch(post.patch(selection, {
        isDeleted: false
      }));
    });
  });

  _store2.default.dispatch(posts.deselectAll());
}

function remove(props) {
  var confirmed = confirm(gettext("Are you sure you want to delete selected posts? This action is not reversible!"));
  if (!confirmed) {
    return;
  }

  props.selection.map(function (selection) {
    _store2.default.dispatch(post.patch(selection, {
      isDeleted: true
    }));
  });

  var ids = props.selection.map(function (post) {
    return post.id;
  });

  _ajax2.default.delete(props.thread.api.posts.index, ids).then(function () {
    return;
  }, function (rejection) {
    if (rejection.status === 400) {
      _snackbar2.default.error(rejection.detail);
    } else {
      _snackbar2.default.apiError(rejection);
    }

    props.selection.map(function (selection) {
      _store2.default.dispatch(post.patch(selection, {
        isDeleted: false
      }));
    });
  });

  _store2.default.dispatch(posts.deselectAll());
}

},{"../../../../reducers/post":353,"../../../../reducers/posts":354,"../../../../services/ajax":365,"../../../../services/modal":371,"../../../../services/snackbar":376,"../../../../services/store":377,"./errors-list":222,"moment":"moment","react":"react"}],221:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Delete = exports.Unhide = exports.Hide = exports.Unprotect = exports.Protect = exports.Split = exports.Move = exports.Merge = exports.Approve = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

exports.default = function (props) {
  return _react2.default.createElement(
    "ul",
    { className: "dropdown-menu" },
    _react2.default.createElement(Approve, props),
    _react2.default.createElement(Merge, props),
    _react2.default.createElement(Move, props),
    _react2.default.createElement(Split, props),
    _react2.default.createElement(Protect, props),
    _react2.default.createElement(Unprotect, props),
    _react2.default.createElement(Unhide, props),
    _react2.default.createElement(Hide, props),
    _react2.default.createElement(Delete, props)
  );
};

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _modal = require("../../../../services/modal");

var _modal2 = _interopRequireDefault(_modal);

var _actions = require("./actions");

var moderation = _interopRequireWildcard(_actions);

var _move = require("./move");

var _move2 = _interopRequireDefault(_move);

var _split = require("./split");

var _split2 = _interopRequireDefault(_split);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var Approve = exports.Approve = function (_React$Component) {
  _inherits(Approve, _React$Component);

  function Approve() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, Approve);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = Approve.__proto__ || Object.getPrototypeOf(Approve)).call.apply(_ref, [this].concat(args))), _this), _this.onClick = function () {
      moderation.approve(_this.props);
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(Approve, [{
    key: "render",
    value: function render() {
      var isVisible = this.props.selection.find(function (post) {
        return post.acl.can_approve && post.is_unapproved;
      });

      if (!isVisible) return null;

      return _react2.default.createElement(
        "li",
        null,
        _react2.default.createElement(
          "button",
          { type: "button", className: "btn btn-link", onClick: this.onClick },
          _react2.default.createElement(
            "span",
            { className: "material-icon" },
            "done"
          ),
          gettext("Approve")
        )
      );
    }
  }]);

  return Approve;
}(_react2.default.Component);

var Merge = exports.Merge = function (_React$Component2) {
  _inherits(Merge, _React$Component2);

  function Merge() {
    var _ref2;

    var _temp2, _this2, _ret2;

    _classCallCheck(this, Merge);

    for (var _len2 = arguments.length, args = Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
      args[_key2] = arguments[_key2];
    }

    return _ret2 = (_temp2 = (_this2 = _possibleConstructorReturn(this, (_ref2 = Merge.__proto__ || Object.getPrototypeOf(Merge)).call.apply(_ref2, [this].concat(args))), _this2), _this2.onClick = function () {
      moderation.merge(_this2.props);
    }, _temp2), _possibleConstructorReturn(_this2, _ret2);
  }

  _createClass(Merge, [{
    key: "render",
    value: function render() {
      var isVisible = this.props.selection.length > 1 && this.props.selection.find(function (post) {
        return post.acl.can_merge;
      });

      if (!isVisible) return null;

      return _react2.default.createElement(
        "li",
        null,
        _react2.default.createElement(
          "button",
          { type: "button", className: "btn btn-link", onClick: this.onClick },
          _react2.default.createElement(
            "span",
            { className: "material-icon" },
            "call_merge"
          ),
          gettext("Merge")
        )
      );
    }
  }]);

  return Merge;
}(_react2.default.Component);

var Move = exports.Move = function (_React$Component3) {
  _inherits(Move, _React$Component3);

  function Move() {
    var _ref3;

    var _temp3, _this3, _ret3;

    _classCallCheck(this, Move);

    for (var _len3 = arguments.length, args = Array(_len3), _key3 = 0; _key3 < _len3; _key3++) {
      args[_key3] = arguments[_key3];
    }

    return _ret3 = (_temp3 = (_this3 = _possibleConstructorReturn(this, (_ref3 = Move.__proto__ || Object.getPrototypeOf(Move)).call.apply(_ref3, [this].concat(args))), _this3), _this3.onClick = function () {
      _modal2.default.show(_react2.default.createElement(_move2.default, _this3.props));
    }, _temp3), _possibleConstructorReturn(_this3, _ret3);
  }

  _createClass(Move, [{
    key: "render",
    value: function render() {
      var isVisible = this.props.selection.find(function (post) {
        return post.acl.can_move;
      });

      if (!isVisible) return null;

      return _react2.default.createElement(
        "li",
        null,
        _react2.default.createElement(
          "button",
          { type: "button", className: "btn btn-link", onClick: this.onClick },
          _react2.default.createElement(
            "span",
            { className: "material-icon" },
            "arrow_forward"
          ),
          gettext("Move")
        )
      );
    }
  }]);

  return Move;
}(_react2.default.Component);

var Split = exports.Split = function (_React$Component4) {
  _inherits(Split, _React$Component4);

  function Split() {
    var _ref4;

    var _temp4, _this4, _ret4;

    _classCallCheck(this, Split);

    for (var _len4 = arguments.length, args = Array(_len4), _key4 = 0; _key4 < _len4; _key4++) {
      args[_key4] = arguments[_key4];
    }

    return _ret4 = (_temp4 = (_this4 = _possibleConstructorReturn(this, (_ref4 = Split.__proto__ || Object.getPrototypeOf(Split)).call.apply(_ref4, [this].concat(args))), _this4), _this4.onClick = function () {
      _modal2.default.show(_react2.default.createElement(_split2.default, _this4.props));
    }, _temp4), _possibleConstructorReturn(_this4, _ret4);
  }

  _createClass(Split, [{
    key: "render",
    value: function render() {
      var isVisible = this.props.selection.find(function (post) {
        return post.acl.can_move;
      });

      if (!isVisible) return null;

      return _react2.default.createElement(
        "li",
        null,
        _react2.default.createElement(
          "button",
          { type: "button", className: "btn btn-link", onClick: this.onClick },
          _react2.default.createElement(
            "span",
            { className: "material-icon" },
            "call_split"
          ),
          gettext("Split")
        )
      );
    }
  }]);

  return Split;
}(_react2.default.Component);

var Protect = exports.Protect = function (_React$Component5) {
  _inherits(Protect, _React$Component5);

  function Protect() {
    var _ref5;

    var _temp5, _this5, _ret5;

    _classCallCheck(this, Protect);

    for (var _len5 = arguments.length, args = Array(_len5), _key5 = 0; _key5 < _len5; _key5++) {
      args[_key5] = arguments[_key5];
    }

    return _ret5 = (_temp5 = (_this5 = _possibleConstructorReturn(this, (_ref5 = Protect.__proto__ || Object.getPrototypeOf(Protect)).call.apply(_ref5, [this].concat(args))), _this5), _this5.onClick = function () {
      moderation.protect(_this5.props);
    }, _temp5), _possibleConstructorReturn(_this5, _ret5);
  }

  _createClass(Protect, [{
    key: "render",
    value: function render() {
      var isVisible = this.props.selection.find(function (post) {
        return !post.is_protected && post.acl.can_protect;
      });

      if (!isVisible) return null;

      return _react2.default.createElement(
        "li",
        null,
        _react2.default.createElement(
          "button",
          { type: "button", className: "btn btn-link", onClick: this.onClick },
          _react2.default.createElement(
            "span",
            { className: "material-icon" },
            "lock_outline"
          ),
          gettext("Protect")
        )
      );
    }
  }]);

  return Protect;
}(_react2.default.Component);

var Unprotect = exports.Unprotect = function (_React$Component6) {
  _inherits(Unprotect, _React$Component6);

  function Unprotect() {
    var _ref6;

    var _temp6, _this6, _ret6;

    _classCallCheck(this, Unprotect);

    for (var _len6 = arguments.length, args = Array(_len6), _key6 = 0; _key6 < _len6; _key6++) {
      args[_key6] = arguments[_key6];
    }

    return _ret6 = (_temp6 = (_this6 = _possibleConstructorReturn(this, (_ref6 = Unprotect.__proto__ || Object.getPrototypeOf(Unprotect)).call.apply(_ref6, [this].concat(args))), _this6), _this6.onClick = function () {
      moderation.unprotect(_this6.props);
    }, _temp6), _possibleConstructorReturn(_this6, _ret6);
  }

  _createClass(Unprotect, [{
    key: "render",
    value: function render() {
      var isVisible = this.props.selection.find(function (post) {
        return post.is_protected && post.acl.can_protect;
      });

      if (!isVisible) return null;

      return _react2.default.createElement(
        "li",
        null,
        _react2.default.createElement(
          "button",
          { type: "button", className: "btn btn-link", onClick: this.onClick },
          _react2.default.createElement(
            "span",
            { className: "material-icon" },
            "lock_open"
          ),
          gettext("Unprotect")
        )
      );
    }
  }]);

  return Unprotect;
}(_react2.default.Component);

var Hide = exports.Hide = function (_React$Component7) {
  _inherits(Hide, _React$Component7);

  function Hide() {
    var _ref7;

    var _temp7, _this7, _ret7;

    _classCallCheck(this, Hide);

    for (var _len7 = arguments.length, args = Array(_len7), _key7 = 0; _key7 < _len7; _key7++) {
      args[_key7] = arguments[_key7];
    }

    return _ret7 = (_temp7 = (_this7 = _possibleConstructorReturn(this, (_ref7 = Hide.__proto__ || Object.getPrototypeOf(Hide)).call.apply(_ref7, [this].concat(args))), _this7), _this7.onClick = function () {
      moderation.hide(_this7.props);
    }, _temp7), _possibleConstructorReturn(_this7, _ret7);
  }

  _createClass(Hide, [{
    key: "render",
    value: function render() {
      var isVisible = this.props.selection.find(function (post) {
        return post.acl.can_hide && !post.is_hidden;
      });

      if (!isVisible) return null;

      return _react2.default.createElement(
        "li",
        null,
        _react2.default.createElement(
          "button",
          { type: "button", className: "btn btn-link", onClick: this.onClick },
          _react2.default.createElement(
            "span",
            { className: "material-icon" },
            "visibility_off"
          ),
          gettext("Hide")
        )
      );
    }
  }]);

  return Hide;
}(_react2.default.Component);

var Unhide = exports.Unhide = function (_React$Component8) {
  _inherits(Unhide, _React$Component8);

  function Unhide() {
    var _ref8;

    var _temp8, _this8, _ret8;

    _classCallCheck(this, Unhide);

    for (var _len8 = arguments.length, args = Array(_len8), _key8 = 0; _key8 < _len8; _key8++) {
      args[_key8] = arguments[_key8];
    }

    return _ret8 = (_temp8 = (_this8 = _possibleConstructorReturn(this, (_ref8 = Unhide.__proto__ || Object.getPrototypeOf(Unhide)).call.apply(_ref8, [this].concat(args))), _this8), _this8.onClick = function () {
      moderation.unhide(_this8.props);
    }, _temp8), _possibleConstructorReturn(_this8, _ret8);
  }

  _createClass(Unhide, [{
    key: "render",
    value: function render() {
      var isVisible = this.props.selection.find(function (post) {
        return post.acl.can_unhide && post.is_hidden;
      });

      if (!isVisible) return null;

      return _react2.default.createElement(
        "li",
        null,
        _react2.default.createElement(
          "button",
          { type: "button", className: "btn btn-link", onClick: this.onClick },
          _react2.default.createElement(
            "span",
            { className: "material-icon" },
            "visibility"
          ),
          gettext("Unhide")
        )
      );
    }
  }]);

  return Unhide;
}(_react2.default.Component);

var Delete = exports.Delete = function (_React$Component9) {
  _inherits(Delete, _React$Component9);

  function Delete() {
    var _ref9;

    var _temp9, _this9, _ret9;

    _classCallCheck(this, Delete);

    for (var _len9 = arguments.length, args = Array(_len9), _key9 = 0; _key9 < _len9; _key9++) {
      args[_key9] = arguments[_key9];
    }

    return _ret9 = (_temp9 = (_this9 = _possibleConstructorReturn(this, (_ref9 = Delete.__proto__ || Object.getPrototypeOf(Delete)).call.apply(_ref9, [this].concat(args))), _this9), _this9.onClick = function () {
      moderation.remove(_this9.props);
    }, _temp9), _possibleConstructorReturn(_this9, _ret9);
  }

  _createClass(Delete, [{
    key: "render",
    value: function render() {
      var isVisible = this.props.selection.find(function (post) {
        return post.acl.can_delete;
      });

      if (!isVisible) return null;

      return _react2.default.createElement(
        "li",
        null,
        _react2.default.createElement(
          "button",
          { type: "button", className: "btn btn-link", onClick: this.onClick },
          _react2.default.createElement(
            "span",
            { className: "material-icon" },
            "clear"
          ),
          gettext("Delete")
        )
      );
    }
  }]);

  return Delete;
}(_react2.default.Component);

},{"../../../../services/modal":371,"./actions":220,"./move":224,"./split":225,"react":"react"}],222:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (_ref) {
  var errors = _ref.errors,
      posts = _ref.posts;

  return _react2.default.createElement(
    "div",
    { className: "modal-dialog", role: "document" },
    _react2.default.createElement(
      "div",
      { className: "modal-content" },
      _react2.default.createElement(
        "div",
        { className: "modal-header" },
        _react2.default.createElement(
          "button",
          {
            "aria-label": gettext("Close"),
            className: "close",
            "data-dismiss": "modal",
            type: "button"
          },
          _react2.default.createElement(
            "span",
            { "aria-hidden": "true" },
            "\xD7"
          )
        ),
        _react2.default.createElement(
          "h4",
          { className: "modal-title" },
          gettext("Moderation")
        )
      ),
      _react2.default.createElement(
        "div",
        { className: "modal-body" },
        _react2.default.createElement(
          "p",
          { className: "lead" },
          gettext("One or more posts could not be changed:")
        ),
        _react2.default.createElement(
          "ul",
          { className: "list-unstyled list-errored-items" },
          errors.map(function (post) {
            return _react2.default.createElement(PostErrors, {
              errors: post.detail,
              key: post.id,
              post: posts[post.id]
            });
          })
        )
      )
    )
  );
};

exports.PostErrors = PostErrors;

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function PostErrors(_ref2) {
  var errors = _ref2.errors,
      post = _ref2.post;

  var heading = interpolate(gettext("%(username)s on %(posted_on)s"), {
    posted_on: post.posted_on.format("LL, LT"),
    username: post.poster_name
  }, true);

  return _react2.default.createElement(
    "li",
    null,
    _react2.default.createElement(
      "h5",
      null,
      heading,
      ":"
    ),
    errors.map(function (error, i) {
      return _react2.default.createElement(
        "p",
        { key: i },
        error
      );
    })
  );
}

},{"react":"react"}],223:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

exports.default = function (props) {
  if (!props.user.id || !isVisible(props.thread, props.posts.results)) {
    return null;
  }

  var selection = props.posts.results.filter(function (post) {
    return post.isSelected;
  });

  return _react2.default.createElement(
    "div",
    { className: "dropup" },
    _react2.default.createElement(
      "button",
      {
        "aria-expanded": "true",
        "aria-haspopup": "true",
        className: "btn btn-default dropdown-toggle btn-block btn-outline",
        "data-toggle": "dropdown",
        disabled: !selection.length,
        type: "button"
      },
      gettext("Posts options")
    ),
    _react2.default.createElement(_dropdown2.default, _extends({ selection: selection }, props))
  );
};

exports.isVisible = isVisible;

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _dropdown = require("./dropdown");

var _dropdown2 = _interopRequireDefault(_dropdown);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function isVisible(thread, posts) {
  if (thread.acl.can_merge_posts && posts.length > 1) {
    // fast test: show moderation menu if we can merge posts
    return true;
  }

  // slow test: show moderation if any of posts has moderation options
  var visible = false;
  posts.forEach(function (post) {
    if (!post.is_event) {
      var showModeration = post.acl.can_approve && post.is_unapproved || post.acl.can_delete || !post.is_hidden && post.acl.can_hide || post.acl.can_move || post.acl.can_merge || post.acl.can_protect || post.is_hidden && post.acl.can_unhide || post.acl.can_unprotect;

      if (showModeration) {
        visible = true;
      }
    }
  });
  return visible;
}

},{"./dropdown":221,"react":"react"}],224:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

exports.ModalHeader = ModalHeader;

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _button = require("../../../button");

var _button2 = _interopRequireDefault(_button);

var _form = require("../../../form");

var _form2 = _interopRequireDefault(_form);

var _formGroup = require("../../../form-group");

var _formGroup2 = _interopRequireDefault(_formGroup);

var _post = require("../../../../reducers/post");

var post = _interopRequireWildcard(_post);

var _ajax = require("../../../../services/ajax");

var _ajax2 = _interopRequireDefault(_ajax);

var _modal = require("../../../../services/modal");

var _modal2 = _interopRequireDefault(_modal);

var _snackbar = require("../../../../services/snackbar");

var _snackbar2 = _interopRequireDefault(_snackbar);

var _store = require("../../../../services/store");

var _store2 = _interopRequireDefault(_store);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_Form) {
  _inherits(_class, _Form);

  function _class(props) {
    _classCallCheck(this, _class);

    var _this = _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).call(this, props));

    _this.onUrlChange = function (event) {
      _this.changeValue("url", event.target.value);
    };

    _this.state = {
      isLoading: false,

      url: "",

      validators: {
        url: []
      },
      errors: {}
    };
    return _this;
  }

  _createClass(_class, [{
    key: "clean",
    value: function clean() {
      if (!this.state.url.trim().length) {
        _snackbar2.default.error(gettext("You have to enter link to the other thread."));
        return false;
      }

      return true;
    }
  }, {
    key: "send",
    value: function send() {
      return _ajax2.default.post(this.props.thread.api.posts.move, {
        new_thread: this.state.url,
        posts: this.props.selection.map(function (post) {
          return post.id;
        })
      });
    }
  }, {
    key: "handleSuccess",
    value: function handleSuccess(success) {
      this.props.selection.forEach(function (selection) {
        _store2.default.dispatch(post.patch(selection, {
          isDeleted: true
        }));
      });

      _modal2.default.hide();

      _snackbar2.default.success(gettext("Selected posts were moved to the other thread."));
    }
  }, {
    key: "handleError",
    value: function handleError(rejection) {
      if (rejection.status === 400) {
        _snackbar2.default.error(rejection.detail);
      } else {
        _snackbar2.default.apiError(rejection);
      }
    }
  }, {
    key: "render",
    value: function render() {
      return _react2.default.createElement(
        "div",
        { className: "modal-dialog", role: "document" },
        _react2.default.createElement(
          "form",
          { onSubmit: this.handleSubmit },
          _react2.default.createElement(
            "div",
            { className: "modal-content" },
            _react2.default.createElement(ModalHeader, null),
            _react2.default.createElement(
              "div",
              { className: "modal-body" },
              _react2.default.createElement(
                _formGroup2.default,
                {
                  "for": "id_url",
                  label: gettext("Link to thread you want to move posts to")
                },
                _react2.default.createElement("input", {
                  className: "form-control",
                  disabled: this.state.isLoading,
                  id: "id_url",
                  onChange: this.onUrlChange,
                  value: this.state.url
                })
              )
            ),
            _react2.default.createElement(
              "div",
              { className: "modal-footer" },
              _react2.default.createElement(
                "button",
                {
                  className: "btn btn-default",
                  "data-dismiss": "modal",
                  disabled: this.state.isLoading,
                  type: "button"
                },
                gettext("Cancel")
              ),
              _react2.default.createElement(
                "button",
                {
                  className: "btn btn-primary",
                  loading: this.state.isLoading
                },
                gettext("Move posts")
              )
            )
          )
        )
      );
    }
  }]);

  return _class;
}(_form2.default);

exports.default = _class;
function ModalHeader(props) {
  return _react2.default.createElement(
    "div",
    { className: "modal-header" },
    _react2.default.createElement(
      "button",
      {
        "aria-label": gettext("Close"),
        className: "close",
        "data-dismiss": "modal",
        type: "button"
      },
      _react2.default.createElement(
        "span",
        { "aria-hidden": "true" },
        "\xD7"
      )
    ),
    _react2.default.createElement(
      "h4",
      { className: "modal-title" },
      gettext("Move posts")
    )
  );
}

},{"../../../../reducers/post":353,"../../../../services/ajax":365,"../../../../services/modal":371,"../../../../services/snackbar":376,"../../../../services/store":377,"../../../button":8,"../../../form":56,"../../../form-group":55,"react":"react"}],225:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ModerationForm = exports.PostingConfig = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

exports.default = function (props) {
  return _react2.default.createElement(PostingConfig, _extends({}, props, { Form: ModerationForm }));
};

exports.Loader = Loader;
exports.Error = Error;
exports.Modal = Modal;

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _button = require("../../../button");

var _button2 = _interopRequireDefault(_button);

var _form = require("../../../form");

var _form2 = _interopRequireDefault(_form);

var _formGroup = require("../../../form-group");

var _formGroup2 = _interopRequireDefault(_formGroup);

var _categorySelect = require("../../../category-select");

var _categorySelect2 = _interopRequireDefault(_categorySelect);

var _modalLoader = require("../../../modal-loader");

var _modalLoader2 = _interopRequireDefault(_modalLoader);

var _select = require("../../../select");

var _select2 = _interopRequireDefault(_select);

var _post = require("../../../../reducers/post");

var post = _interopRequireWildcard(_post);

var _ajax = require("../../../../services/ajax");

var _ajax2 = _interopRequireDefault(_ajax);

var _modal = require("../../../../services/modal");

var _modal2 = _interopRequireDefault(_modal);

var _snackbar = require("../../../../services/snackbar");

var _snackbar2 = _interopRequireDefault(_snackbar);

var _store = require("../../../../services/store");

var _store2 = _interopRequireDefault(_store);

var _validators = require("../../../../utils/validators");

var validators = _interopRequireWildcard(_validators);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var PostingConfig = exports.PostingConfig = function (_React$Component) {
  _inherits(PostingConfig, _React$Component);

  function PostingConfig(props) {
    _classCallCheck(this, PostingConfig);

    var _this = _possibleConstructorReturn(this, (PostingConfig.__proto__ || Object.getPrototypeOf(PostingConfig)).call(this, props));

    _this.state = {
      isLoaded: false,
      isError: false,

      categories: []
    };
    return _this;
  }

  _createClass(PostingConfig, [{
    key: "componentDidMount",
    value: function componentDidMount() {
      var _this2 = this;

      _ajax2.default.get(misago.get("THREAD_EDITOR_API")).then(function (data) {
        // hydrate categories, extract posting options
        var categories = data.map(function (item) {
          return Object.assign(item, {
            disabled: item.post === false,
            label: item.name,
            value: item.id,
            post: item.post
          });
        });

        _this2.setState({
          isLoaded: true,
          categories: categories
        });
      }, function (rejection) {
        _this2.setState({
          isError: rejection.detail
        });
      });
    }
  }, {
    key: "render",
    value: function render() {
      if (this.state.isError) {
        return _react2.default.createElement(Error, { message: this.state.isError });
      } else if (this.state.isLoaded) {
        return _react2.default.createElement(ModerationForm, _extends({}, this.props, { categories: this.state.categories }));
      } else {
        return _react2.default.createElement(Loader, null);
      }
    }
  }]);

  return PostingConfig;
}(_react2.default.Component);

var ModerationForm = exports.ModerationForm = function (_Form) {
  _inherits(ModerationForm, _Form);

  function ModerationForm(props) {
    _classCallCheck(this, ModerationForm);

    var _this3 = _possibleConstructorReturn(this, (ModerationForm.__proto__ || Object.getPrototypeOf(ModerationForm)).call(this, props));

    _this3.onCategoryChange = function (ev) {
      var categoryId = ev.target.value;
      var newState = {
        category: categoryId
      };

      if (_this3.acl[categoryId].can_pin_threads < newState.weight) {
        newState.weight = 0;
      }

      if (!_this3.acl[categoryId].can_hide_threads) {
        newState.is_hidden = 0;
      }

      if (!_this3.acl[categoryId].can_close_threads) {
        newState.is_closed = false;
      }

      _this3.setState(newState);
    };

    _this3.state = {
      isLoading: false,

      title: "",
      category: null,
      categories: props.categories,
      weight: 0,
      is_hidden: 0,
      is_closed: false,

      validators: {
        title: [validators.required()]
      },

      errors: {}
    };

    _this3.isHiddenChoices = [{
      value: 0,
      icon: "visibility",
      label: gettext("No")
    }, {
      value: 1,
      icon: "visibility_off",
      label: gettext("Yes")
    }];

    _this3.isClosedChoices = [{
      value: false,
      icon: "lock_outline",
      label: gettext("No")
    }, {
      value: true,
      icon: "lock",
      label: gettext("Yes")
    }];

    _this3.acl = {};
    _this3.props.categories.forEach(function (category) {
      if (category.post) {
        if (!_this3.state.category) {
          _this3.state.category = category.id;
        }

        _this3.acl[category.id] = {
          can_pin_threads: category.post.pin,
          can_close_threads: category.post.close,
          can_hide_threads: category.post.hide
        };
      }
    });
    return _this3;
  }

  _createClass(ModerationForm, [{
    key: "clean",
    value: function clean() {
      if (this.isValid()) {
        return true;
      } else {
        _snackbar2.default.error(gettext("Form contains errors."));
        this.setState({
          errors: this.validate()
        });
        return false;
      }
    }
  }, {
    key: "send",
    value: function send() {
      return _ajax2.default.post(this.props.thread.api.posts.split, {
        title: this.state.title,
        category: this.state.category,
        weight: this.state.weight,
        is_hidden: this.state.is_hidden,
        is_closed: this.state.is_closed,
        posts: this.props.selection.map(function (post) {
          return post.id;
        })
      });
    }
  }, {
    key: "handleSuccess",
    value: function handleSuccess(apiResponse) {
      this.props.selection.forEach(function (selection) {
        _store2.default.dispatch(post.patch(selection, {
          isDeleted: true
        }));
      });

      _modal2.default.hide();

      _snackbar2.default.success(gettext("Selected posts were split into new thread."));
    }
  }, {
    key: "handleError",
    value: function handleError(rejection) {
      if (rejection.status === 400) {
        this.setState({
          errors: Object.assign({}, this.state.errors, rejection)
        });
        _snackbar2.default.error(gettext("Form contains errors."));
      } else if (rejection.status === 403 && Array.isArray(rejection)) {
        _modal2.default.show(_react2.default.createElement(ErrorsModal, { errors: rejection }));
      } else {
        _snackbar2.default.apiError(rejection);
      }
    }
  }, {
    key: "getWeightChoices",
    value: function getWeightChoices() {
      var choices = [{
        value: 0,
        icon: "remove",
        label: gettext("Not pinned")
      }, {
        value: 1,
        icon: "bookmark_border",
        label: gettext("Pinned locally")
      }];

      if (this.acl[this.state.category].can_pin_threads == 2) {
        choices.push({
          value: 2,
          icon: "bookmark",
          label: gettext("Pinned globally")
        });
      }

      return choices;
    }
  }, {
    key: "renderWeightField",
    value: function renderWeightField() {
      if (this.acl[this.state.category].can_pin_threads) {
        return _react2.default.createElement(
          _formGroup2.default,
          {
            label: gettext("Thread weight"),
            "for": "id_weight",
            labelClass: "col-sm-4",
            controlClass: "col-sm-8"
          },
          _react2.default.createElement(_select2.default, {
            id: "id_weight",
            onChange: this.bindInput("weight"),
            value: this.state.weight,
            choices: this.getWeightChoices()
          })
        );
      } else {
        return null;
      }
    }
  }, {
    key: "renderHiddenField",
    value: function renderHiddenField() {
      if (this.acl[this.state.category].can_hide_threads) {
        return _react2.default.createElement(
          _formGroup2.default,
          {
            label: gettext("Hide thread"),
            "for": "id_is_hidden",
            labelClass: "col-sm-4",
            controlClass: "col-sm-8"
          },
          _react2.default.createElement(_select2.default, {
            id: "id_is_closed",
            onChange: this.bindInput("is_hidden"),
            value: this.state.is_hidden,
            choices: this.isHiddenChoices
          })
        );
      } else {
        return null;
      }
    }
  }, {
    key: "renderClosedField",
    value: function renderClosedField() {
      if (this.acl[this.state.category].can_close_threads) {
        return _react2.default.createElement(
          _formGroup2.default,
          {
            label: gettext("Close thread"),
            "for": "id_is_closed",
            labelClass: "col-sm-4",
            controlClass: "col-sm-8"
          },
          _react2.default.createElement(_select2.default, {
            id: "id_is_closed",
            onChange: this.bindInput("is_closed"),
            value: this.state.is_closed,
            choices: this.isClosedChoices
          })
        );
      } else {
        return null;
      }
    }
  }, {
    key: "render",
    value: function render() {
      return _react2.default.createElement(
        Modal,
        { className: "modal-dialog" },
        _react2.default.createElement(
          "form",
          { onSubmit: this.handleSubmit },
          _react2.default.createElement(
            "div",
            { className: "modal-body" },
            _react2.default.createElement(
              _formGroup2.default,
              {
                label: gettext("Thread title"),
                "for": "id_title",
                labelClass: "col-sm-4",
                controlClass: "col-sm-8",
                validation: this.state.errors.title
              },
              _react2.default.createElement("input", {
                id: "id_title",
                className: "form-control",
                type: "text",
                onChange: this.bindInput("title"),
                value: this.state.title
              })
            ),
            _react2.default.createElement("div", { className: "clearfix" }),
            _react2.default.createElement(
              _formGroup2.default,
              {
                label: gettext("Category"),
                "for": "id_category",
                labelClass: "col-sm-4",
                controlClass: "col-sm-8",
                validation: this.state.errors.category
              },
              _react2.default.createElement(_categorySelect2.default, {
                id: "id_category",
                onChange: this.onCategoryChange,
                value: this.state.category,
                choices: this.state.categories
              })
            ),
            _react2.default.createElement("div", { className: "clearfix" }),
            this.renderWeightField(),
            this.renderHiddenField(),
            this.renderClosedField()
          ),
          _react2.default.createElement(
            "div",
            { className: "modal-footer" },
            _react2.default.createElement(
              "button",
              {
                className: "btn btn-default",
                "data-dismiss": "modal",
                disabled: this.state.isLoading,
                type: "button"
              },
              gettext("Cancel")
            ),
            _react2.default.createElement(
              _button2.default,
              { className: "btn-primary", loading: this.state.isLoading },
              gettext("Split posts")
            )
          )
        )
      );
    }
  }]);

  return ModerationForm;
}(_form2.default);

function Loader() {
  return _react2.default.createElement(
    Modal,
    { className: "modal-dialog" },
    _react2.default.createElement(_modalLoader2.default, null)
  );
}

function Error(props) {
  return _react2.default.createElement(
    Modal,
    { className: "modal-dialog modal-message" },
    _react2.default.createElement(
      "div",
      { className: "message-icon" },
      _react2.default.createElement(
        "span",
        { className: "material-icon" },
        "info_outline"
      )
    ),
    _react2.default.createElement(
      "div",
      { className: "message-body" },
      _react2.default.createElement(
        "p",
        { className: "lead" },
        gettext("You can't move selected posts at the moment.")
      ),
      _react2.default.createElement(
        "p",
        null,
        props.message
      ),
      _react2.default.createElement(
        "button",
        { className: "btn btn-default", "data-dismiss": "modal", type: "button" },
        gettext("Ok")
      )
    )
  );
}

function Modal(props) {
  return _react2.default.createElement(
    "div",
    { className: props.className, role: "document" },
    _react2.default.createElement(
      "div",
      { className: "modal-content" },
      _react2.default.createElement(
        "div",
        { className: "modal-header" },
        _react2.default.createElement(
          "button",
          {
            "aria-label": gettext("Close"),
            className: "close",
            "data-dismiss": "modal",
            type: "button"
          },
          _react2.default.createElement(
            "span",
            { "aria-hidden": "true" },
            "\xD7"
          )
        ),
        _react2.default.createElement(
          "h4",
          { className: "modal-title" },
          gettext("Split posts into new thread")
        )
      ),
      props.children
    )
  );
}

},{"../../../../reducers/post":353,"../../../../services/ajax":365,"../../../../services/modal":371,"../../../../services/snackbar":376,"../../../../services/store":377,"../../../../utils/validators":393,"../../../button":8,"../../../category-select":21,"../../../form":56,"../../../form-group":55,"../../../modal-loader":61,"../../../select":210,"react":"react"}],226:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _merge = require("./merge");

var _merge2 = _interopRequireDefault(_merge);

var _move = require("./move");

var _move2 = _interopRequireDefault(_move);

var _thread = require("../../../../reducers/thread");

var thread = _interopRequireWildcard(_thread);

var _ajax = require("../../../../services/ajax");

var _ajax2 = _interopRequireDefault(_ajax);

var _modal = require("../../../../services/modal");

var _modal2 = _interopRequireDefault(_modal);

var _snackbar = require("../../../../services/snackbar");

var _snackbar2 = _interopRequireDefault(_snackbar);

var _store = require("../../../../services/store");

var _store2 = _interopRequireDefault(_store);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_React$Component) {
  _inherits(_class, _React$Component);

  function _class() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, _class);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = _class.__proto__ || Object.getPrototypeOf(_class)).call.apply(_ref, [this].concat(args))), _this), _this.callApi = function (ops, successMessage) {
      _store2.default.dispatch(thread.busy());

      // by the chance update thread acl too
      ops.push({ op: "add", path: "acl", value: true });

      _ajax2.default.patch(_this.props.thread.api.index, ops).then(function (data) {
        _store2.default.dispatch(thread.update(data));
        _store2.default.dispatch(thread.release());
        _snackbar2.default.success(successMessage);
      }, function (rejection) {
        _store2.default.dispatch(thread.release());
        if (rejection.status === 400) {
          _snackbar2.default.error(rejection.detail[0]);
        } else {
          _snackbar2.default.apiError(rejection);
        }
      });
    }, _this.pinGlobally = function () {
      _this.callApi([{
        op: "replace",
        path: "weight",
        value: 2
      }], gettext("Thread has been pinned globally."));
    }, _this.pinLocally = function () {
      _this.callApi([{
        op: "replace",
        path: "weight",
        value: 1
      }], gettext("Thread has been pinned locally."));
    }, _this.unpin = function () {
      _this.callApi([{
        op: "replace",
        path: "weight",
        value: 0
      }], gettext("Thread has been unpinned."));
    }, _this.approve = function () {
      _this.callApi([{
        op: "replace",
        path: "is-unapproved",
        value: false
      }], gettext("Thread has been approved."));
    }, _this.open = function () {
      _this.callApi([{
        op: "replace",
        path: "is-closed",
        value: false
      }], gettext("Thread has been opened."));
    }, _this.close = function () {
      _this.callApi([{
        op: "replace",
        path: "is-closed",
        value: true
      }], gettext("Thread has been closed."));
    }, _this.unhide = function () {
      _this.callApi([{
        op: "replace",
        path: "is-hidden",
        value: false
      }], gettext("Thread has been made visible."));
    }, _this.hide = function () {
      _this.callApi([{
        op: "replace",
        path: "is-hidden",
        value: true
      }], gettext("Thread has been made hidden."));
    }, _this.move = function () {
      _modal2.default.show(_react2.default.createElement(_move2.default, { posts: _this.props.posts, thread: _this.props.thread }));
    }, _this.merge = function () {
      _modal2.default.show(_react2.default.createElement(_merge2.default, { thread: _this.props.thread }));
    }, _this.delete = function () {
      if (!confirm(gettext("Are you sure you want to delete this thread?"))) {
        return;
      }

      _store2.default.dispatch(thread.busy());

      _ajax2.default.delete(_this.props.thread.api.index).then(function (data) {
        _snackbar2.default.success(gettext("Thread has been deleted."));
        window.location = _this.props.thread.category.url.index;
      }, function (rejection) {
        _store2.default.dispatch(thread.release());
        _snackbar2.default.apiError(rejection);
      });
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(_class, [{
    key: "getPinGloballyButton",
    value: function getPinGloballyButton() {
      if (this.props.thread.weight === 2) return null;
      if (!this.props.thread.acl.can_pin_globally) return null;

      return _react2.default.createElement(
        "li",
        null,
        _react2.default.createElement(
          "button",
          {
            className: "btn btn-link",
            onClick: this.pinGlobally,
            type: "button"
          },
          _react2.default.createElement(
            "span",
            { className: "material-icon" },
            "bookmark"
          ),
          gettext("Pin globally")
        )
      );
    }
  }, {
    key: "getPinLocallyButton",
    value: function getPinLocallyButton() {
      if (this.props.thread.weight === 1) return null;
      if (!this.props.thread.acl.can_pin) return null;

      return _react2.default.createElement(
        "li",
        null,
        _react2.default.createElement(
          "button",
          {
            className: "btn btn-link",
            onClick: this.pinLocally,
            type: "button"
          },
          _react2.default.createElement(
            "span",
            { className: "material-icon" },
            "bookmark_border"
          ),
          gettext("Pin locally")
        )
      );
    }
  }, {
    key: "getUnpinButton",
    value: function getUnpinButton() {
      if (this.props.thread.weight === 0) return null;
      if (!this.props.thread.acl.can_pin) return null;

      return _react2.default.createElement(
        "li",
        null,
        _react2.default.createElement(
          "button",
          { className: "btn btn-link", onClick: this.unpin, type: "button" },
          _react2.default.createElement(
            "span",
            { className: "material-icon" },
            "panorama_fish_eye"
          ),
          gettext("Unpin")
        )
      );
    }
  }, {
    key: "getMoveButton",
    value: function getMoveButton() {
      if (!this.props.thread.acl.can_move) return null;

      return _react2.default.createElement(
        "li",
        null,
        _react2.default.createElement(
          "button",
          { className: "btn btn-link", onClick: this.move, type: "button" },
          _react2.default.createElement(
            "span",
            { className: "material-icon" },
            "arrow_forward"
          ),
          gettext("Move")
        )
      );
    }
  }, {
    key: "getMergeButton",
    value: function getMergeButton() {
      if (!this.props.thread.acl.can_merge) return null;

      return _react2.default.createElement(
        "li",
        null,
        _react2.default.createElement(
          "button",
          { className: "btn btn-link", onClick: this.merge, type: "button" },
          _react2.default.createElement(
            "span",
            { className: "material-icon" },
            "call_merge"
          ),
          gettext("Merge")
        )
      );
    }
  }, {
    key: "getApproveButton",
    value: function getApproveButton() {
      if (!this.props.thread.is_unapproved) return null;
      if (!this.props.thread.acl.can_approve) return null;

      return _react2.default.createElement(
        "li",
        null,
        _react2.default.createElement(
          "button",
          { className: "btn btn-link", onClick: this.approve, type: "button" },
          _react2.default.createElement(
            "span",
            { className: "material-icon" },
            "done"
          ),
          gettext("Approve")
        )
      );
    }
  }, {
    key: "getOpenButton",
    value: function getOpenButton() {
      if (!this.props.thread.is_closed) return null;
      if (!this.props.thread.acl.can_close) return null;

      return _react2.default.createElement(
        "li",
        null,
        _react2.default.createElement(
          "button",
          { className: "btn btn-link", onClick: this.open, type: "button" },
          _react2.default.createElement(
            "span",
            { className: "material-icon" },
            "lock_open"
          ),
          gettext("Open")
        )
      );
    }
  }, {
    key: "getCloseButton",
    value: function getCloseButton() {
      if (this.props.thread.is_closed) return null;
      if (!this.props.thread.acl.can_close) return null;

      return _react2.default.createElement(
        "li",
        null,
        _react2.default.createElement(
          "button",
          { className: "btn btn-link", onClick: this.close, type: "button" },
          _react2.default.createElement(
            "span",
            { className: "material-icon" },
            "lock_outline"
          ),
          gettext("Close")
        )
      );
    }
  }, {
    key: "getUnhideButton",
    value: function getUnhideButton() {
      if (!this.props.thread.is_hidden) return null;
      if (!this.props.thread.acl.can_unhide) return null;

      return _react2.default.createElement(
        "li",
        null,
        _react2.default.createElement(
          "button",
          { className: "btn btn-link", onClick: this.unhide, type: "button" },
          _react2.default.createElement(
            "span",
            { className: "material-icon" },
            "visibility"
          ),
          gettext("Unhide")
        )
      );
    }
  }, {
    key: "getHideButton",
    value: function getHideButton() {
      if (this.props.thread.is_hidden) return null;
      if (!this.props.thread.acl.can_hide) return null;

      return _react2.default.createElement(
        "li",
        null,
        _react2.default.createElement(
          "button",
          { className: "btn btn-link", onClick: this.hide, type: "button" },
          _react2.default.createElement(
            "span",
            { className: "material-icon" },
            "visibility_off"
          ),
          gettext("Hide")
        )
      );
    }
  }, {
    key: "getDeleteButton",
    value: function getDeleteButton() {
      if (!this.props.thread.acl.can_delete) return null;

      return _react2.default.createElement(
        "li",
        null,
        _react2.default.createElement(
          "button",
          { className: "btn btn-link", onClick: this.delete, type: "button" },
          _react2.default.createElement(
            "span",
            { className: "material-icon" },
            "clear"
          ),
          gettext("Delete")
        )
      );
    }
  }, {
    key: "render",
    value: function render() {
      return _react2.default.createElement(
        "ul",
        { className: "dropdown-menu dropdown-menu-right stick-to-bottom" },
        this.getPinGloballyButton(),
        this.getPinLocallyButton(),
        this.getUnpinButton(),
        this.getMoveButton(),
        this.getMergeButton(),
        this.getApproveButton(),
        this.getOpenButton(),
        this.getCloseButton(),
        this.getUnhideButton(),
        this.getHideButton(),
        this.getDeleteButton()
      );
    }
  }]);

  return _class;
}(_react2.default.Component);

exports.default = _class;

},{"../../../../reducers/thread":360,"../../../../services/ajax":365,"../../../../services/modal":371,"../../../../services/snackbar":376,"../../../../services/store":377,"./merge":229,"./move":230,"react":"react"}],227:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isModerationVisible = exports.ModerationControls = undefined;

var _controls = require("./controls");

var _controls2 = _interopRequireDefault(_controls);

var _isVisible = require("./is-visible");

var _isVisible2 = _interopRequireDefault(_isVisible);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.ModerationControls = _controls2.default;
exports.isModerationVisible = _isVisible2.default;

},{"./controls":226,"./is-visible":228}],228:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (thread) {
  return !!(thread.acl.can_approve && thread.is_unapproved || thread.acl.can_close || thread.acl.can_delete || thread.acl.can_hide || thread.acl.can_move || thread.acl.can_merge || thread.acl.can_pin || thread.acl.can_pin_globally && thread.weight !== 2 || thread.acl.can_unhide && thread.is_hidden);
};

},{}],229:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

exports.ModalHeader = ModalHeader;

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _form = require("../../../form");

var _form2 = _interopRequireDefault(_form);

var _formGroup = require("../../../form-group");

var _formGroup2 = _interopRequireDefault(_formGroup);

var _mergeConflict = require("../../../merge-conflict");

var _mergeConflict2 = _interopRequireDefault(_mergeConflict);

var _thread = require("../../../../reducers/thread");

var thread = _interopRequireWildcard(_thread);

var _ajax = require("../../../../services/ajax");

var _ajax2 = _interopRequireDefault(_ajax);

var _modal = require("../../../../services/modal");

var _modal2 = _interopRequireDefault(_modal);

var _snackbar = require("../../../../services/snackbar");

var _snackbar2 = _interopRequireDefault(_snackbar);

var _store = require("../../../../services/store");

var _store2 = _interopRequireDefault(_store);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_Form) {
  _inherits(_class, _Form);

  function _class(props) {
    _classCallCheck(this, _class);

    var _this = _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).call(this, props));

    _this.handleSuccess = function (success) {
      _this.handleSuccessUnmounted(success);

      // keep form loading
      _this.setState({
        isLoading: true
      });
    };

    _this.handleSuccessUnmounted = function (success) {
      _snackbar2.default.success(gettext("Thread has been merged with other one."));
      window.location = success.url;
    };

    _this.handleError = function (rejection) {
      _store2.default.dispatch(thread.release());

      if (rejection.status === 400) {
        if (rejection.best_answers || rejection.polls) {
          _modal2.default.show(_react2.default.createElement(_mergeConflict2.default, {
            api: _this.props.thread.api.merge,
            bestAnswers: rejection.best_answers,
            data: { other_thread: _this.state.url },
            polls: rejection.polls,
            onError: _this.handleError,
            onSuccess: _this.handleSuccessUnmounted
          }));
        } else if (rejection.best_answer) {
          _snackbar2.default.error(rejection.best_answer[0]);
        } else if (rejection.poll) {
          _snackbar2.default.error(rejection.poll[0]);
        } else {
          _snackbar2.default.error(rejection.detail);
        }
      } else {
        _snackbar2.default.apiError(rejection);
      }
    };

    _this.onUrlChange = function (event) {
      _this.changeValue("url", event.target.value);
    };

    _this.state = {
      isLoading: false,

      url: "",

      validators: {
        url: []
      },
      errors: {}
    };
    return _this;
  }

  _createClass(_class, [{
    key: "clean",
    value: function clean() {
      if (!this.state.url.trim().length) {
        _snackbar2.default.error(gettext("You have to enter link to the other thread."));
        return false;
      }

      return true;
    }
  }, {
    key: "send",
    value: function send() {
      // freeze thread
      _store2.default.dispatch(thread.busy());

      return _ajax2.default.post(this.props.thread.api.merge, {
        other_thread: this.state.url
      });
    }
  }, {
    key: "render",
    value: function render() {
      return _react2.default.createElement(
        "div",
        { className: "modal-dialog", role: "document" },
        _react2.default.createElement(
          "form",
          { onSubmit: this.handleSubmit },
          _react2.default.createElement(
            "div",
            { className: "modal-content" },
            _react2.default.createElement(ModalHeader, null),
            _react2.default.createElement(
              "div",
              { className: "modal-body" },
              _react2.default.createElement(
                _formGroup2.default,
                {
                  "for": "id_url",
                  label: gettext("Link to thread you want to merge with"),
                  help_text: gettext("Merge will delete current thread and move its contents to the thread specified here.")
                },
                _react2.default.createElement("input", {
                  className: "form-control",
                  disabled: this.state.isLoading || this.props.thread.isBusy,
                  id: "id_url",
                  onChange: this.onUrlChange,
                  value: this.state.url
                })
              )
            ),
            _react2.default.createElement(
              "div",
              { className: "modal-footer" },
              _react2.default.createElement(
                "button",
                {
                  className: "btn btn-default",
                  "data-dismiss": "modal",
                  disabled: this.state.isLoading,
                  type: "button"
                },
                gettext("Cancel")
              ),
              _react2.default.createElement(
                "button",
                {
                  className: "btn btn-primary",
                  loading: this.state.isLoading || this.props.thread.isBusy
                },
                gettext("Merge thread")
              )
            )
          )
        )
      );
    }
  }]);

  return _class;
}(_form2.default);

exports.default = _class;
function ModalHeader(props) {
  return _react2.default.createElement(
    "div",
    { className: "modal-header" },
    _react2.default.createElement(
      "button",
      {
        "aria-label": gettext("Close"),
        className: "close",
        "data-dismiss": "modal",
        type: "button"
      },
      _react2.default.createElement(
        "span",
        { "aria-hidden": "true" },
        "\xD7"
      )
    ),
    _react2.default.createElement(
      "h4",
      { className: "modal-title" },
      gettext("Merge thread")
    )
  );
}

},{"../../../../reducers/thread":360,"../../../../services/ajax":365,"../../../../services/modal":371,"../../../../services/snackbar":376,"../../../../services/store":377,"../../../form":56,"../../../form-group":55,"../../../merge-conflict":59,"react":"react"}],230:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

exports.ModalHeader = ModalHeader;
exports.ModalLoading = ModalLoading;
exports.ModalMessage = ModalMessage;

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _form = require("../../../form");

var _form2 = _interopRequireDefault(_form);

var _formGroup = require("../../../form-group");

var _formGroup2 = _interopRequireDefault(_formGroup);

var _categorySelect = require("../../../category-select");

var _categorySelect2 = _interopRequireDefault(_categorySelect);

var _modalLoader = require("../../../modal-loader");

var _modalLoader2 = _interopRequireDefault(_modalLoader);

var _posts = require("../../../../reducers/posts");

var posts = _interopRequireWildcard(_posts);

var _thread = require("../../../../reducers/thread");

var thread = _interopRequireWildcard(_thread);

var _ = require("../../../..");

var _2 = _interopRequireDefault(_);

var _ajax = require("../../../../services/ajax");

var _ajax2 = _interopRequireDefault(_ajax);

var _modal = require("../../../../services/modal");

var _modal2 = _interopRequireDefault(_modal);

var _snackbar = require("../../../../services/snackbar");

var _snackbar2 = _interopRequireDefault(_snackbar);

var _store = require("../../../../services/store");

var _store2 = _interopRequireDefault(_store);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_Form) {
  _inherits(_class, _Form);

  function _class(props) {
    _classCallCheck(this, _class);

    var _this = _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).call(this, props));

    _this.onCategoryChange = function (event) {
      _this.changeValue("category", event.target.value);
    };

    _this.state = {
      isReady: false,
      isLoading: false,
      isError: false,

      category: null,
      categories: []
    };
    return _this;
  }

  _createClass(_class, [{
    key: "componentDidMount",
    value: function componentDidMount() {
      var _this2 = this;

      _ajax2.default.get(_2.default.get("THREAD_EDITOR_API")).then(function (data) {
        var category = null;

        // hydrate categories, extract posting options
        var categories = data.map(function (item) {
          // pick first category that allows posting and if it may, override it with initial one
          if (item.post !== false && !category) {
            category = item.id;
          }

          return Object.assign(item, {
            disabled: item.post === false,
            label: item.name,
            value: item.id
          });
        });

        _this2.setState({
          isReady: true,

          category: category,
          categories: categories
        });
      }, function (rejection) {
        _this2.setState({
          isError: rejection.detail
        });
      });
    }
  }, {
    key: "send",
    value: function send() {
      // freeze thread
      _store2.default.dispatch(thread.busy());

      return _ajax2.default.patch(this.props.thread.api.index, [{ op: "replace", path: "category", value: this.state.category }]);
    }
  }, {
    key: "handleSuccess",
    value: function handleSuccess() {
      // refresh thread and displayed posts
      _ajax2.default.get(this.props.thread.api.posts.index, { page: this.props.posts.page }).then(function (data) {
        _store2.default.dispatch(thread.replace(data));
        _store2.default.dispatch(posts.load(data.post_set));
        _store2.default.dispatch(thread.release());

        _snackbar2.default.success(gettext("Thread has been moved."));
        _modal2.default.hide();
      }, function (rejection) {
        _store2.default.dispatch(thread.release());
        _snackbar2.default.apiError(rejection);
      });
    }
  }, {
    key: "handleError",
    value: function handleError(rejection) {
      if (rejection.status === 400) {
        _snackbar2.default.error(rejection.detail[0]);
      } else {
        _snackbar2.default.apiError(rejection);
      }
    }
  }, {
    key: "render",
    value: function render() {
      if (this.state.isReady) {
        return _react2.default.createElement(
          "div",
          { className: "modal-dialog", role: "document" },
          _react2.default.createElement(
            "form",
            { onSubmit: this.handleSubmit },
            _react2.default.createElement(
              "div",
              { className: "modal-content" },
              _react2.default.createElement(ModalHeader, null),
              _react2.default.createElement(
                "div",
                { className: "modal-body" },
                _react2.default.createElement(
                  _formGroup2.default,
                  { "for": "id_category", label: gettext("New category") },
                  _react2.default.createElement(_categorySelect2.default, {
                    choices: this.state.categories,
                    disabled: this.state.isLoading || this.props.thread.isBusy,
                    id: "id_category",
                    onChange: this.onCategoryChange,
                    value: this.state.category
                  })
                )
              ),
              _react2.default.createElement(
                "div",
                { className: "modal-footer" },
                _react2.default.createElement(
                  "button",
                  {
                    className: "btn btn-default",
                    "data-dismiss": "modal",
                    disabled: this.state.isLoading,
                    type: "button"
                  },
                  gettext("Cancel")
                ),
                _react2.default.createElement(
                  "button",
                  {
                    className: "btn btn-primary",
                    loading: this.state.isLoading || this.props.thread.isBusy
                  },
                  gettext("Move thread")
                )
              )
            )
          )
        );
      } else if (this.state.isError) {
        return _react2.default.createElement(ModalMessage, { message: this.state.isError });
      } else {
        return _react2.default.createElement(ModalLoading, null);
      }
    }
  }]);

  return _class;
}(_form2.default);

exports.default = _class;
function ModalHeader(props) {
  return _react2.default.createElement(
    "div",
    { className: "modal-header" },
    _react2.default.createElement(
      "button",
      {
        "aria-label": gettext("Close"),
        className: "close",
        "data-dismiss": "modal",
        type: "button"
      },
      _react2.default.createElement(
        "span",
        { "aria-hidden": "true" },
        "\xD7"
      )
    ),
    _react2.default.createElement(
      "h4",
      { className: "modal-title" },
      gettext("Move thread")
    )
  );
}

function ModalLoading(props) {
  return _react2.default.createElement(
    "div",
    { className: "modal-dialog", role: "document" },
    _react2.default.createElement(
      "div",
      { className: "modal-content" },
      _react2.default.createElement(ModalHeader, null),
      _react2.default.createElement(_modalLoader2.default, null)
    )
  );
}

function ModalMessage(props) {
  return _react2.default.createElement(
    "div",
    { className: "modal-dialog modal-message", role: "document" },
    _react2.default.createElement(
      "div",
      { className: "modal-content" },
      _react2.default.createElement(ModalHeader, null),
      _react2.default.createElement(
        "div",
        { className: "message-icon" },
        _react2.default.createElement(
          "span",
          { className: "material-icon" },
          "info_outline"
        )
      ),
      _react2.default.createElement(
        "div",
        { className: "message-body" },
        _react2.default.createElement(
          "p",
          { className: "lead" },
          gettext("You can't move this thread at the moment.")
        ),
        _react2.default.createElement(
          "p",
          null,
          props.message
        ),
        _react2.default.createElement(
          "button",
          {
            className: "btn btn-default",
            "data-dismiss": "modal",
            type: "button"
          },
          gettext("Ok")
        )
      )
    )
  );
}

},{"../../../..":302,"../../../../reducers/posts":354,"../../../../reducers/thread":360,"../../../../services/ajax":365,"../../../../services/modal":371,"../../../../services/snackbar":376,"../../../../services/store":377,"../../../category-select":21,"../../../form":56,"../../../form-group":55,"../../../modal-loader":61,"react":"react"}],231:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (props) {
  return _react2.default.createElement(
    "nav",
    { className: "misago-pagination pull-left" },
    _react2.default.createElement(Pager, props),
    _react2.default.createElement(More, { more: props.posts.more })
  );
};

exports.Pager = Pager;
exports.FirstPage = FirstPage;
exports.PreviousPage = PreviousPage;
exports.NextPage = NextPage;
exports.LastPage = LastPage;
exports.More = More;

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _reactRouter = require("react-router");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function Pager(props) {
  return _react2.default.createElement(
    "div",
    { className: "row row-paginator" },
    _react2.default.createElement(
      "div",
      { className: "col-xs-3" },
      _react2.default.createElement(FirstPage, props)
    ),
    _react2.default.createElement(
      "div",
      { className: "col-xs-3" },
      _react2.default.createElement(PreviousPage, props)
    ),
    _react2.default.createElement(
      "div",
      { className: "col-xs-3" },
      _react2.default.createElement(NextPage, props)
    ),
    _react2.default.createElement(
      "div",
      { className: "col-xs-3" },
      _react2.default.createElement(LastPage, props)
    )
  );
}

function FirstPage(props) {
  if (props.posts.isLoaded && props.posts.first) {
    return _react2.default.createElement(
      _reactRouter.Link,
      {
        className: "btn btn-default btn-block btn-outline btn-icon",
        to: props.thread.url.index,
        title: gettext("Go to first page")
      },
      _react2.default.createElement(
        "span",
        { className: "material-icon" },
        "first_page"
      )
    );
  } else {
    return _react2.default.createElement(
      "span",
      {
        className: "btn btn-default btn-block btn-outline btn-icon disabled",
        title: gettext("Go to first page")
      },
      _react2.default.createElement(
        "span",
        { className: "material-icon" },
        "first_page"
      )
    );
  }
}

function PreviousPage(props) {
  if (props.posts.isLoaded && props.posts.page > 1) {
    var previousUrl = "";
    if (props.posts.previous) {
      previousUrl = props.posts.previous + "/";
    }

    return _react2.default.createElement(
      _reactRouter.Link,
      {
        className: "btn btn-default btn-block btn-outline btn-icon",
        to: props.thread.url.index + previousUrl,
        title: gettext("Go to previous page")
      },
      _react2.default.createElement(
        "span",
        { className: "material-icon" },
        "chevron_left"
      )
    );
  } else {
    return _react2.default.createElement(
      "span",
      {
        className: "btn btn-default btn-block btn-outline btn-icon disabled",
        title: gettext("Go to previous page")
      },
      _react2.default.createElement(
        "span",
        { className: "material-icon" },
        "chevron_left"
      )
    );
  }
}

function NextPage(props) {
  if (props.posts.isLoaded && props.posts.more) {
    var nextUrl = "";
    if (props.posts.next) {
      nextUrl = props.posts.next + "/";
    }

    return _react2.default.createElement(
      _reactRouter.Link,
      {
        className: "btn btn-default btn-block btn-outline btn-icon",
        to: props.thread.url.index + nextUrl,
        title: gettext("Go to next page")
      },
      _react2.default.createElement(
        "span",
        { className: "material-icon" },
        "chevron_right"
      )
    );
  } else {
    return _react2.default.createElement(
      "span",
      {
        className: "btn btn-default btn-block btn-outline btn-icon disabled",
        title: gettext("Go to next page")
      },
      _react2.default.createElement(
        "span",
        { className: "material-icon" },
        "chevron_right"
      )
    );
  }
}

function LastPage(props) {
  if (props.posts.isLoaded && props.posts.last) {
    return _react2.default.createElement(
      _reactRouter.Link,
      {
        className: "btn btn-default btn-block btn-outline btn-icon",
        to: props.thread.url.index + props.posts.last + "/",
        title: gettext("Go to last page")
      },
      _react2.default.createElement(
        "span",
        { className: "material-icon" },
        "last_page"
      )
    );
  } else {
    return _react2.default.createElement(
      "span",
      {
        className: "btn btn-default btn-block btn-outline btn-icon disabled",
        title: gettext("Go to last page")
      },
      _react2.default.createElement(
        "span",
        { className: "material-icon" },
        "last_page"
      )
    );
  }
}

function More(props) {
  var message = null;
  if (props.more) {
    message = ngettext("There is %(more)s more post in this thread.", "There are %(more)s more posts in this thread.", props.more);
    message = interpolate(message, { more: props.more }, true);
  } else {
    message = gettext("There are no more posts in this thread.");
  }

  return _react2.default.createElement(
    "p",
    null,
    message
  );
}

},{"react":"react","react-router":"react-router"}],232:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (props) {
  return _react2.default.createElement(
    "button",
    {
      className: props.className || "btn btn-primary btn-outline",
      onClick: props.onClick,
      type: "button"
    },
    _react2.default.createElement(
      "span",
      { className: "material-icon" },
      "chat"
    ),
    gettext("Reply")
  );
};

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

},{"react":"react"}],233:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.select = select;
exports.paths = paths;

var _reactRedux = require("react-redux");

var _route = require("./route");

var _route2 = _interopRequireDefault(_route);

var _index = require("../../index");

var _index2 = _interopRequireDefault(_index);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function select(store) {
  return {
    participants: store.participants,
    poll: store.poll,
    posts: store.posts,
    thread: store.thread,
    tick: store.tick.tick,
    user: store.auth.user
  };
}

function paths() {
  var thread = _index2.default.get("THREAD");
  var basePath = thread.url.index.replace(thread.slug + "-" + thread.pk, ":slug");

  return [{
    path: basePath,
    component: (0, _reactRedux.connect)(select)(_route2.default)
  }, {
    path: basePath + ":page/",
    component: (0, _reactRedux.connect)(select)(_route2.default)
  }];
}

},{"../../index":302,"./route":234,"react-redux":"react-redux"}],234:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _participants = require("../participants");

var _participants2 = _interopRequireDefault(_participants);

var _poll = require("../poll");

var _postsList = require("../posts-list");

var _postsList2 = _interopRequireDefault(_postsList);

var _header = require("./header");

var _header2 = _interopRequireDefault(_header);

var _toolbarTop = require("./toolbar-top");

var _toolbarTop2 = _interopRequireDefault(_toolbarTop);

var _toolbarBottom = require("./toolbar-bottom");

var _toolbarBottom2 = _interopRequireDefault(_toolbarBottom);

var _participants3 = require("../../reducers/participants");

var participants = _interopRequireWildcard(_participants3);

var _poll2 = require("../../reducers/poll");

var poll = _interopRequireWildcard(_poll2);

var _posts = require("../../reducers/posts");

var posts = _interopRequireWildcard(_posts);

var _thread = require("../../reducers/thread");

var thread = _interopRequireWildcard(_thread);

var _ajax = require("../../services/ajax");

var _ajax2 = _interopRequireDefault(_ajax);

var _polls = require("../../services/polls");

var _polls2 = _interopRequireDefault(_polls);

var _snackbar = require("../../services/snackbar");

var _snackbar2 = _interopRequireDefault(_snackbar);

var _posting = require("../../services/posting");

var _posting2 = _interopRequireDefault(_posting);

var _store = require("../../services/store");

var _store2 = _interopRequireDefault(_store);

var _pageTitle = require("../../services/page-title");

var _pageTitle2 = _interopRequireDefault(_pageTitle);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_React$Component) {
  _inherits(_class, _React$Component);

  function _class() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, _class);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = _class.__proto__ || Object.getPrototypeOf(_class)).call.apply(_ref, [this].concat(args))), _this), _this.update = function (data) {
      _store2.default.dispatch(thread.replace(data));
      _store2.default.dispatch(posts.load(data.post_set));

      if (data.participants) {
        _store2.default.dispatch(participants.replace(data.participants));
      }

      if (data.poll) {
        _store2.default.dispatch(poll.replace(data.poll));
      }

      _this.setPageTitle();
    }, _this.openReplyForm = function () {
      _posting2.default.open({
        mode: "REPLY",

        config: _this.props.thread.api.editor,
        submit: _this.props.thread.api.posts.index
      });
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(_class, [{
    key: "componentDidMount",
    value: function componentDidMount() {
      if (this.shouldFetchData()) {
        this.fetchData();
        this.setPageTitle();
      }

      this.startPollingApi();
    }
  }, {
    key: "componentDidUpdate",
    value: function componentDidUpdate() {
      if (this.shouldFetchData()) {
        this.fetchData();
        this.startPollingApi();
        this.setPageTitle();
      }
    }
  }, {
    key: "componentWillUnmount",
    value: function componentWillUnmount() {
      this.stopPollingApi();
    }
  }, {
    key: "shouldFetchData",
    value: function shouldFetchData() {
      if (this.props.posts.isLoaded) {
        var page = (this.props.params.page || 1) * 1;
        return page != this.props.posts.page;
      } else {
        return false;
      }
    }
  }, {
    key: "fetchData",
    value: function fetchData() {
      var _this2 = this;

      _store2.default.dispatch(posts.unload());

      _ajax2.default.get(this.props.thread.api.posts.index, {
        page: this.props.params.page || 1
      }, "posts").then(function (data) {
        _this2.update(data);
      }, function (rejection) {
        _snackbar2.default.apiError(rejection);
      });
    }
  }, {
    key: "startPollingApi",
    value: function startPollingApi() {
      _polls2.default.start({
        poll: "thread-posts",

        url: this.props.thread.api.posts.index,
        data: {
          page: this.props.params.page || 1
        },
        update: this.update,

        frequency: 120 * 1000,
        delayed: true
      });
    }
  }, {
    key: "stopPollingApi",
    value: function stopPollingApi() {
      _polls2.default.stop("thread-posts");
    }
  }, {
    key: "setPageTitle",
    value: function setPageTitle() {
      _pageTitle2.default.set({
        title: this.props.thread.title,
        parent: this.props.thread.category.name,
        page: (this.props.params.page || 1) * 1
      });
    }
  }, {
    key: "render",
    value: function render() {
      var className = "page page-thread";
      if (this.props.thread.category.css_class) {
        className += " page-thread-" + this.props.thread.category.css_class;
      }

      return _react2.default.createElement(
        "div",
        { className: className },
        _react2.default.createElement(
          "div",
          { className: "page-header-bg" },
          _react2.default.createElement(_header2.default, this.props)
        ),
        _react2.default.createElement(
          "div",
          { className: "container" },
          _react2.default.createElement(_toolbarTop2.default, _extends({ openReplyForm: this.openReplyForm }, this.props)),
          _react2.default.createElement(_poll.Poll, {
            poll: this.props.poll,
            thread: this.props.thread,
            user: this.props.user
          }),
          _react2.default.createElement(_participants2.default, {
            participants: this.props.participants,
            thread: this.props.thread,
            user: this.props.user
          }),
          _react2.default.createElement(_postsList2.default, this.props),
          _react2.default.createElement(_toolbarBottom2.default, _extends({ openReplyForm: this.openReplyForm }, this.props))
        )
      );
    }
  }]);

  return _class;
}(_react2.default.Component);

exports.default = _class;

},{"../../reducers/participants":351,"../../reducers/poll":352,"../../reducers/posts":354,"../../reducers/thread":360,"../../services/ajax":365,"../../services/page-title":373,"../../services/polls":374,"../../services/posting":375,"../../services/snackbar":376,"../../services/store":377,"../participants":101,"../poll":106,"../posts-list":151,"./header":218,"./toolbar-bottom":236,"./toolbar-top":237,"react":"react"}],235:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Email = exports.Enable = exports.Disable = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

exports.default = function (props) {
  if (!props.user.id) return null;

  return _react2.default.createElement(
    "div",
    { className: props.className },
    _react2.default.createElement(
      "button",
      {
        "aria-expanded": "true",
        "aria-haspopup": "true",
        className: "btn btn-default dropdown-toggle btn-block btn-outline",
        "data-toggle": "dropdown",
        type: "button"
      },
      _react2.default.createElement(
        "span",
        { className: "material-icon" },
        getIcon(props.thread.subscription)
      ),
      getLabel(props.thread.subscription)
    ),
    _react2.default.createElement(Dropdown, props)
  );
};

exports.getIcon = getIcon;
exports.getLabel = getLabel;
exports.Dropdown = Dropdown;
exports.update = update;

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _thread = require("../../reducers/thread");

var actions = _interopRequireWildcard(_thread);

var _ajax = require("../../services/ajax");

var _ajax2 = _interopRequireDefault(_ajax);

var _snackbar = require("../../services/snackbar");

var _snackbar2 = _interopRequireDefault(_snackbar);

var _store = require("../../services/store");

var _store2 = _interopRequireDefault(_store);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

function getIcon(subscription) {
  if (subscription === true) {
    return "star";
  } else if (subscription === false) {
    return "star_half";
  } else {
    return "star_border";
  }
}

function getLabel(subscription) {
  if (subscription === true) {
    return gettext("E-mail");
  } else if (subscription === false) {
    return gettext("Enabled");
  } else {
    return gettext("Disabled");
  }
}

function Dropdown(props) {
  return _react2.default.createElement(
    "ul",
    { className: props.dropdownClassName || "dropdown-menu stick-to-bottom" },
    _react2.default.createElement(Disable, props),
    _react2.default.createElement(Enable, props),
    _react2.default.createElement(Email, props)
  );
}

var Disable = exports.Disable = function (_React$Component) {
  _inherits(Disable, _React$Component);

  function Disable() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, Disable);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = Disable.__proto__ || Object.getPrototypeOf(Disable)).call.apply(_ref, [this].concat(args))), _this), _this.onClick = function () {
      if (_this.props.thread.subscription === null) {
        return;
      }

      update(_this.props.thread, null, "unsubscribe");
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(Disable, [{
    key: "render",
    value: function render() {
      return _react2.default.createElement(
        "li",
        null,
        _react2.default.createElement(
          "button",
          { className: "btn btn-link", onClick: this.onClick },
          _react2.default.createElement(
            "span",
            { className: "material-icon" },
            "star_border"
          ),
          gettext("Unsubscribe")
        )
      );
    }
  }]);

  return Disable;
}(_react2.default.Component);

var Enable = exports.Enable = function (_React$Component2) {
  _inherits(Enable, _React$Component2);

  function Enable() {
    var _ref2;

    var _temp2, _this2, _ret2;

    _classCallCheck(this, Enable);

    for (var _len2 = arguments.length, args = Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
      args[_key2] = arguments[_key2];
    }

    return _ret2 = (_temp2 = (_this2 = _possibleConstructorReturn(this, (_ref2 = Enable.__proto__ || Object.getPrototypeOf(Enable)).call.apply(_ref2, [this].concat(args))), _this2), _this2.onClick = function () {
      if (_this2.props.thread.subscription === false) {
        return;
      }

      update(_this2.props.thread, false, "notify");
    }, _temp2), _possibleConstructorReturn(_this2, _ret2);
  }

  _createClass(Enable, [{
    key: "render",
    value: function render() {
      return _react2.default.createElement(
        "li",
        null,
        _react2.default.createElement(
          "button",
          { className: "btn btn-link", onClick: this.onClick },
          _react2.default.createElement(
            "span",
            { className: "material-icon" },
            "star_half"
          ),
          gettext("Subscribe")
        )
      );
    }
  }]);

  return Enable;
}(_react2.default.Component);

var Email = exports.Email = function (_React$Component3) {
  _inherits(Email, _React$Component3);

  function Email() {
    var _ref3;

    var _temp3, _this3, _ret3;

    _classCallCheck(this, Email);

    for (var _len3 = arguments.length, args = Array(_len3), _key3 = 0; _key3 < _len3; _key3++) {
      args[_key3] = arguments[_key3];
    }

    return _ret3 = (_temp3 = (_this3 = _possibleConstructorReturn(this, (_ref3 = Email.__proto__ || Object.getPrototypeOf(Email)).call.apply(_ref3, [this].concat(args))), _this3), _this3.onClick = function () {
      if (_this3.props.thread.subscription === true) {
        return;
      }

      update(_this3.props.thread, true, "email");
    }, _temp3), _possibleConstructorReturn(_this3, _ret3);
  }

  _createClass(Email, [{
    key: "render",
    value: function render() {
      return _react2.default.createElement(
        "li",
        null,
        _react2.default.createElement(
          "button",
          { className: "btn btn-link", onClick: this.onClick },
          _react2.default.createElement(
            "span",
            { className: "material-icon" },
            "star"
          ),
          gettext("Subscribe with e-mail")
        )
      );
    }
  }]);

  return Email;
}(_react2.default.Component);

function update(thread, newState, value) {
  var oldState = {
    subscription: thread.subscription
  };

  _store2.default.dispatch(actions.update({
    subscription: newState
  }));

  _ajax2.default.patch(thread.api.index, [{ op: "replace", path: "subscription", value: value }]).then(function (finalState) {
    _store2.default.dispatch(actions.update(finalState));
  }, function (rejection) {
    if (rejection.status === 400) {
      _snackbar2.default.error(rejection.detail[0]);
    } else {
      _snackbar2.default.apiError(rejection);
    }

    _store2.default.dispatch(actions.update(oldState));
  });
}

},{"../../reducers/thread":360,"../../services/ajax":365,"../../services/snackbar":376,"../../services/store":377,"react":"react"}],236:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

exports.default = function (props) {
  return _react2.default.createElement(
    "div",
    { className: "row row-toolbar" },
    _react2.default.createElement(
      "div",
      { className: "col-xs-12 text-center visible-xs-block" },
      _react2.default.createElement(_paginator.More, { more: props.posts.more }),
      _react2.default.createElement("div", { className: "toolbar-vertical-spacer" })
    ),
    _react2.default.createElement(
      "div",
      { className: "col-md-7" },
      _react2.default.createElement(
        "div",
        { className: "row" },
        _react2.default.createElement(
          "div",
          { className: "col-sm-4 col-md-5" },
          _react2.default.createElement(_paginator.Pager, props)
        ),
        _react2.default.createElement(
          "div",
          { className: "col-sm-8 col-md-7 hidden-xs" },
          _react2.default.createElement(_paginator.More, { more: props.posts.more })
        )
      )
    ),
    _react2.default.createElement(
      Options,
      { visible: !!props.user.id },
      _react2.default.createElement("div", { className: "toolbar-vertical-spacer hidden-md hidden-lg" }),
      _react2.default.createElement(
        "div",
        { className: "row" },
        _react2.default.createElement(Spacer, props),
        _react2.default.createElement(Moderation, props),
        _react2.default.createElement(Subscription, props),
        _react2.default.createElement(Reply, { thread: props.thread, onClick: props.openReplyForm })
      )
    )
  );
};

exports.Options = Options;
exports.Moderation = Moderation;
exports.Subscription = Subscription;
exports.Reply = Reply;
exports.Spacer = Spacer;

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _paginator = require("./paginator");

var _posts = require("./moderation/posts");

var _posts2 = _interopRequireDefault(_posts);

var _replyButton = require("./reply-button");

var _replyButton2 = _interopRequireDefault(_replyButton);

var _subscription = require("./subscription");

var _subscription2 = _interopRequireDefault(_subscription);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function Options(props) {
  if (!props.visible) return null;

  return _react2.default.createElement(
    "div",
    { className: "col-md-5" },
    props.children
  );
}

function Moderation(props) {
  if (!props.user.id) return null;

  return _react2.default.createElement(
    "div",
    { className: "col-sm-4 hidden-xs" },
    _react2.default.createElement(_posts2.default, props)
  );
}

function Subscription(props) {
  var xsClass = "col-xs-6";
  if (!props.thread.acl.can_reply) {
    xsClass = "col-xs-12";
  }

  return _react2.default.createElement(
    "div",
    { className: xsClass + " col-sm-4" },
    _react2.default.createElement(_subscription2.default, _extends({
      btnClassName: "btn-block",
      className: "dropup"
    }, props))
  );
}

function Reply(props) {
  if (!props.thread.acl.can_reply) return null;

  return _react2.default.createElement(
    "div",
    { className: "col-xs-6 col-sm-4" },
    _react2.default.createElement(_replyButton2.default, {
      className: "btn btn-primary btn-block btn-outline",
      onClick: props.onClick
    })
  );
}

function Spacer(props) {
  if (props.thread.acl.can_reply) return null;

  return _react2.default.createElement("div", { className: "hidden-xs hidden-sm col-sm-4" });
}

},{"./moderation/posts":223,"./paginator":231,"./reply-button":232,"./subscription":235,"react":"react"}],237:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StartPollCompact = exports.StartPoll = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

exports.default = function (props) {
  var hiddenSpecialOption = !props.thread.acl.can_start_poll || props.thread.poll;

  return _react2.default.createElement(
    "div",
    { className: "row row-toolbar row-toolbar-bottom-margin" },
    _react2.default.createElement(GotoMenu, props),
    _react2.default.createElement(
      "div",
      { className: "col-xs-9 col-md-5 col-md-offset-2" },
      _react2.default.createElement(
        "div",
        { className: "row" },
        _react2.default.createElement(Spacer, { visible: !props.user.id }),
        _react2.default.createElement(Spacer, { visible: hiddenSpecialOption }),
        _react2.default.createElement(SubscriptionMenu, props),
        _react2.default.createElement(StartPoll, props),
        _react2.default.createElement(Reply, props)
      )
    )
  );
};

exports.GotoMenu = GotoMenu;
exports.GotoNew = GotoNew;
exports.GotoBestAnswer = GotoBestAnswer;
exports.GotoUnapproved = GotoUnapproved;
exports.GotoLast = GotoLast;
exports.CompactOptions = CompactOptions;
exports.GotoNewCompact = GotoNewCompact;
exports.GotoUnapprovedCompact = GotoUnapprovedCompact;
exports.GotoLastCompact = GotoLastCompact;
exports.Reply = Reply;
exports.SubscriptionMenu = SubscriptionMenu;
exports.Spacer = Spacer;

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _replyButton = require("./reply-button");

var _replyButton2 = _interopRequireDefault(_replyButton);

var _subscription = require("./subscription");

var _subscription2 = _interopRequireDefault(_subscription);

var _posting = require("../../services/posting");

var _posting2 = _interopRequireDefault(_posting);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

function GotoMenu(props) {
  var user = props.user;


  var className = "col-xs-3 col-sm-3 col-md-5";
  if (user.is_anonymous) {
    className = "col-xs-12 col-sm-3 col-md-5";
  }

  return _react2.default.createElement(
    "div",
    { className: className },
    _react2.default.createElement(
      "div",
      { className: "row hidden-xs hidden-sm" },
      _react2.default.createElement(GotoLast, { thread: props.thread }),
      _react2.default.createElement(GotoNew, { thread: props.thread }),
      _react2.default.createElement(GotoBestAnswer, { thread: props.thread }),
      _react2.default.createElement(GotoUnapproved, { thread: props.thread })
    ),
    _react2.default.createElement(CompactOptions, props)
  );
}

function GotoNew(props) {
  if (!props.thread.is_new) return null;

  return _react2.default.createElement(
    "div",
    { className: "col-sm-4" },
    _react2.default.createElement(
      "a",
      {
        href: props.thread.url.new_post,
        className: "btn btn-default btn-block btn-outline",
        title: gettext("Go to first new post")
      },
      gettext("New")
    )
  );
}

function GotoBestAnswer(props) {
  if (!props.thread.best_answer) {
    return null;
  }

  return _react2.default.createElement(
    "div",
    { className: "col-sm-4" },
    _react2.default.createElement(
      "a",
      {
        href: props.thread.url.best_answer,
        className: "btn btn-default btn-block btn-outline",
        title: gettext("Go to best answer")
      },
      gettext("Best answer")
    )
  );
}

function GotoUnapproved(props) {
  if (!props.thread.has_unapproved_posts || !props.thread.acl.can_approve) {
    return null;
  }

  return _react2.default.createElement(
    "div",
    { className: "col-sm-4" },
    _react2.default.createElement(
      "a",
      {
        href: props.thread.url.unapproved_post,
        className: "btn btn-default btn-block btn-outline",
        title: gettext("Go to first unapproved post")
      },
      gettext("Unapproved")
    )
  );
}

function GotoLast(props) {
  return _react2.default.createElement(
    "div",
    { className: "col-sm-4" },
    _react2.default.createElement(
      "a",
      {
        href: props.thread.url.last_post,
        className: "btn btn-default btn-block btn-outline",
        title: gettext("Go to last post")
      },
      gettext("Last")
    )
  );
}

function CompactOptions(props) {
  var user = props.user;

  if (user.is_anonymous) {
    return _react2.default.createElement(
      "div",
      { className: "visible-xs-block visible-sm-block" },
      _react2.default.createElement(
        "a",
        {
          href: props.thread.url.last_post,
          className: "btn btn-default btn-block btn-outline"
        },
        gettext("Last post")
      )
    );
  }

  return _react2.default.createElement(
    "div",
    { className: "dropdown visible-xs-block visible-sm-block" },
    _react2.default.createElement(
      "button",
      {
        "aria-expanded": "true",
        "aria-haspopup": "true",
        className: "btn btn-default dropdown-toggle btn-block btn-outline",
        "data-toggle": "dropdown",
        type: "button"
      },
      _react2.default.createElement(
        "span",
        { className: "material-icon" },
        "expand_more"
      ),
      _react2.default.createElement(
        "span",
        { className: "btn-text hidden-xs" },
        gettext("Options")
      )
    ),
    _react2.default.createElement(
      "ul",
      { className: "dropdown-menu" },
      _react2.default.createElement(StartPollCompact, props),
      _react2.default.createElement(GotoNewCompact, props),
      _react2.default.createElement(GotoUnapprovedCompact, props),
      _react2.default.createElement(GotoLastCompact, props)
    )
  );
}

function GotoNewCompact(props) {
  if (!props.thread.is_new) return null;

  return _react2.default.createElement(
    "li",
    null,
    _react2.default.createElement(
      "a",
      { href: props.thread.url.new_post, className: "btn btn-link" },
      gettext("Go to first new post")
    )
  );
}

function GotoUnapprovedCompact(props) {
  if (!props.thread.has_unapproved_posts || !props.thread.acl.can_approve) {
    return null;
  }

  return _react2.default.createElement(
    "li",
    null,
    _react2.default.createElement(
      "a",
      { href: props.thread.url.unapproved_post, className: "btn btn-link" },
      gettext("Go to first unapproved post")
    )
  );
}

function GotoLastCompact(props) {
  return _react2.default.createElement(
    "li",
    null,
    _react2.default.createElement(
      "a",
      { href: props.thread.url.last_post, className: "btn btn-link" },
      gettext("Go to last post")
    )
  );
}

function Reply(props) {
  if (!props.thread.acl.can_reply) return null;

  return _react2.default.createElement(
    "div",
    { className: "col-sm-4 hidden-xs" },
    _react2.default.createElement(_replyButton2.default, {
      className: "btn btn-primary btn-block btn-outline",
      onClick: props.openReplyForm
    })
  );
}

function SubscriptionMenu(props) {
  if (!props.user.id) return null;

  return _react2.default.createElement(
    "div",
    { className: "col-xs-12 col-sm-4" },
    _react2.default.createElement(_subscription2.default, _extends({
      className: "dropdown",
      dropdownClassName: "dropdown-menu dropdown-menu-right stick-to-bottom"
    }, props))
  );
}

var StartPoll = exports.StartPoll = function (_React$Component) {
  _inherits(StartPoll, _React$Component);

  function StartPoll() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, StartPoll);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = StartPoll.__proto__ || Object.getPrototypeOf(StartPoll)).call.apply(_ref, [this].concat(args))), _this), _this.onClick = function () {
      _posting2.default.open({
        mode: "POLL",
        submit: _this.props.thread.api.poll,

        thread: _this.props.thread,
        poll: null
      });
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(StartPoll, [{
    key: "render",
    value: function render() {
      if (!this.props.thread.acl.can_start_poll || this.props.thread.poll) {
        return null;
      }

      return _react2.default.createElement(
        "div",
        { className: "col-sm-4 hidden-xs" },
        _react2.default.createElement(
          "button",
          {
            className: "btn btn-default btn-block btn-outline",
            onClick: this.onClick,
            type: "button"
          },
          _react2.default.createElement(
            "span",
            { className: "material-icon" },
            "poll"
          ),
          gettext("Add poll")
        )
      );
    }
  }]);

  return StartPoll;
}(_react2.default.Component);

var StartPollCompact = exports.StartPollCompact = function (_StartPoll) {
  _inherits(StartPollCompact, _StartPoll);

  function StartPollCompact() {
    _classCallCheck(this, StartPollCompact);

    return _possibleConstructorReturn(this, (StartPollCompact.__proto__ || Object.getPrototypeOf(StartPollCompact)).apply(this, arguments));
  }

  _createClass(StartPollCompact, [{
    key: "render",
    value: function render() {
      if (!this.props.thread.acl.can_start_poll || this.props.thread.poll) {
        return null;
      }

      return _react2.default.createElement(
        "li",
        null,
        _react2.default.createElement(
          "button",
          { className: "btn btn-link", onClick: this.onClick, type: "button" },
          gettext("Add poll")
        )
      );
    }
  }]);

  return StartPollCompact;
}(StartPoll);

function Spacer(props) {
  if (!props.visible) return null;

  return _react2.default.createElement("div", { className: "col-sm-4 hidden-xs" });
}

},{"../../services/posting":375,"./reply-button":232,"./subscription":235,"react":"react"}],238:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (props) {
  if (!props.isLoaded) {
    return _react2.default.createElement(_preview2.default, null);
  }

  if (props.threads.length === 0) {
    return _react2.default.createElement(
      _empty2.default,
      { diffSize: props.diffSize, applyDiff: props.applyDiff },
      props.children
    );
  }

  return _react2.default.createElement(_ready2.default, {
    activeCategory: props.category,
    categories: props.categories,
    list: props.list,
    threads: props.threads,
    diffSize: props.diffSize,
    applyDiff: props.applyDiff,
    showOptions: props.showOptions,
    selection: props.selection,
    busyThreads: props.busyThreads
  });
};

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _empty = require("./list/empty");

var _empty2 = _interopRequireDefault(_empty);

var _ready = require("./list/ready");

var _ready2 = _interopRequireDefault(_ready);

var _preview = require("./list/preview");

var _preview2 = _interopRequireDefault(_preview);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

},{"./list/empty":240,"./list/preview":241,"./list/ready":242,"react":"react"}],239:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (props) {
  var applyDiff = props.applyDiff,
      diffSize = props.diffSize;

  if (diffSize === 0) return null;

  return _react2.default.createElement(
    "li",
    { className: "list-group-item threads-diff-message" },
    _react2.default.createElement(
      "button",
      {
        type: "button",
        className: "btn btn-block btn-default",
        onClick: applyDiff
      },
      _react2.default.createElement(
        "span",
        { className: "material-icon" },
        "cached"
      ),
      _react2.default.createElement(
        "span",
        { className: "diff-message" },
        getMessage(diffSize)
      )
    )
  );
};

exports.getMessage = getMessage;

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function getMessage(diffSize) {
  var message = ngettext("There is %(threads)s new or updated thread. Click this message to show it.", "There are %(threads)s new or updated threads. Click this message to show them.", diffSize);

  return interpolate(message, {
    threads: diffSize
  }, true);
}

},{"react":"react"}],240:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _diffMessage = require("./diff-message");

var _diffMessage2 = _interopRequireDefault(_diffMessage);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_React$Component) {
  _inherits(_class, _React$Component);

  function _class() {
    _classCallCheck(this, _class);

    return _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).apply(this, arguments));
  }

  _createClass(_class, [{
    key: "getDiffMessage",
    value: function getDiffMessage() {
      if (this.props.diffSize === 0) return null;

      return _react2.default.createElement(_diffMessage2.default, {
        applyDiff: this.props.applyDiff,
        diffSize: this.props.diffSize
      });
    }
  }, {
    key: "render",
    value: function render() {
      return _react2.default.createElement(
        "div",
        { className: "threads-list ui-ready" },
        _react2.default.createElement(
          "ul",
          { className: "list-group" },
          this.getDiffMessage(),
          this.props.children
        )
      );
    }
  }]);

  return _class;
}(_react2.default.Component);

exports.default = _class;

},{"./diff-message":239,"react":"react"}],241:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _preview = require("../thread/preview");

var _preview2 = _interopRequireDefault(_preview);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_React$Component) {
  _inherits(_class, _React$Component);

  function _class() {
    _classCallCheck(this, _class);

    return _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).apply(this, arguments));
  }

  _createClass(_class, [{
    key: "shouldComponentUpdate",
    value: function shouldComponentUpdate() {
      return false;
    }
  }, {
    key: "render",
    value: function render() {
      return _react2.default.createElement(
        "div",
        { className: "threads-list ui-preview" },
        _react2.default.createElement(
          "ul",
          { className: "list-group" },
          _react2.default.createElement(_preview2.default, null)
        )
      );
    }
  }]);

  return _class;
}(_react2.default.Component);

exports.default = _class;

},{"../thread/preview":249,"react":"react"}],242:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (props) {
  return _react2.default.createElement(
    "div",
    { className: "threads-list ui-ready" },
    _react2.default.createElement(
      "ul",
      { className: "list-group" },
      _react2.default.createElement(_diffMessage2.default, { diffSize: props.diffSize, applyDiff: props.applyDiff }),
      props.threads.map(function (thread) {
        return _react2.default.createElement(_ready2.default, {
          activeCategory: props.activeCategory,
          categories: props.categories,
          list: props.list,
          thread: thread,
          showOptions: props.showOptions,
          isSelected: props.selection.indexOf(thread.id) >= 0,
          isBusy: props.busyThreads.indexOf(thread.id) >= 0,
          key: thread.id
        });
      })
    )
  );
};

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _diffMessage = require("./diff-message");

var _diffMessage2 = _interopRequireDefault(_diffMessage);

var _ready = require("../thread/ready");

var _ready2 = _interopRequireDefault(_ready);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

},{"../thread/ready":250,"./diff-message":239,"react":"react"}],243:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (_ref) {
  var category = _ref.category,
      isBusy = _ref.isBusy,
      showOptions = _ref.showOptions,
      isSelected = _ref.isSelected,
      thread = _ref.thread;

  var className = "col-xs-12 col-sm-12";
  if (showOptions) {
    if (thread.moderation.length) {
      className = "col-xs-6 col-sm-12";
    } else {
      className = "col-xs-9 col-sm-12";
    }
  }

  var statusFlags = 0;
  if (thread.is_hidden) statusFlags += 1;
  if (thread.is_closed) statusFlags += 1;
  if (thread.has_poll) statusFlags += 1;

  var allFlagsVisible = showOptions && statusFlags === 3;

  var textClassName = "detail-text hidden-xs";
  if (allFlagsVisible) {
    textClassName += " hidden-sm";
  }

  return _react2.default.createElement(
    "div",
    { className: "row thread-details-bottom" },
    _react2.default.createElement(
      "div",
      { className: className },
      _react2.default.createElement(_category2.default, {
        className: "item-title thread-detail-category hidden-xs",
        category: category
      }),
      _react2.default.createElement(HiddenLabel, { textClassName: textClassName, display: thread.is_hidden }),
      _react2.default.createElement(ClosedLabel, { textClassName: textClassName, display: thread.is_closed }),
      _react2.default.createElement(PollLabel, { textClassName: textClassName, display: thread.has_poll }),
      _react2.default.createElement(BestAnswerLabel, { thread: thread }),
      _react2.default.createElement(RepliesLabel, {
        forceFullText: !showOptions || statusFlags < 2,
        replies: thread.replies
      }),
      _react2.default.createElement(LastReplyLabel, {
        datetime: thread.last_post_on,
        url: thread.url.last_post
      }),
      _react2.default.createElement(LastPoster, {
        posterName: thread.last_poster_name,
        url: thread.url.last_poster
      })
    ),
    _react2.default.createElement(_options.OptionsXs, {
      disabled: isBusy,
      display: showOptions,
      isSelected: isSelected,
      thread: thread
    })
  );
};

exports.HiddenLabel = HiddenLabel;
exports.ClosedLabel = ClosedLabel;
exports.PollLabel = PollLabel;
exports.BestAnswerLabel = BestAnswerLabel;
exports.RepliesLabel = RepliesLabel;
exports.LastReplyLabel = LastReplyLabel;
exports.LastPoster = LastPoster;

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _category = require("./category");

var _category2 = _interopRequireDefault(_category);

var _options = require("../options");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function HiddenLabel(_ref2) {
  var display = _ref2.display,
      textClassName = _ref2.textClassName;

  if (!display) return null;

  return _react2.default.createElement(
    "span",
    { className: "thread-detail-hidden" },
    _react2.default.createElement(
      "span",
      { className: "material-icon" },
      "visibility_off"
    ),
    _react2.default.createElement(
      "span",
      { className: textClassName },
      gettext("Hidden")
    )
  );
}

function ClosedLabel(_ref3) {
  var display = _ref3.display,
      textClassName = _ref3.textClassName;

  if (!display) return null;

  return _react2.default.createElement(
    "span",
    { className: "thread-detail-closed" },
    _react2.default.createElement(
      "span",
      { className: "material-icon" },
      "lock_outline"
    ),
    _react2.default.createElement(
      "span",
      { className: textClassName },
      gettext("Closed")
    )
  );
}

function PollLabel(_ref4) {
  var display = _ref4.display,
      textClassName = _ref4.textClassName;

  if (!display) return null;

  return _react2.default.createElement(
    "span",
    { className: "thread-detail-poll" },
    _react2.default.createElement(
      "span",
      { className: "material-icon" },
      "assessment"
    ),
    _react2.default.createElement(
      "span",
      { className: textClassName },
      gettext("Poll")
    )
  );
}

function BestAnswerLabel(_ref5) {
  var thread = _ref5.thread;

  if (!thread.best_answer) return null;

  return _react2.default.createElement(
    "a",
    {
      className: "visible-xs-inline-block thread-detail-answered",
      href: thread.url.best_answer
    },
    _react2.default.createElement(
      "span",
      { className: "material-icon" },
      "check_box"
    )
  );
}

function RepliesLabel(_ref6) {
  var replies = _ref6.replies,
      forceFullText = _ref6.forceFullText;

  var text = ngettext("%(replies)s reply", "%(replies)s replies", replies);

  var compactClassName = "";
  var fullClassName = "";

  if (forceFullText) {
    compactClassName = "detail-text hide";
    fullClassName = "detail-text";
  } else {
    compactClassName = "detail-text visible-xs-inline-block";
    fullClassName = "detail-text hidden-xs";
  }

  return _react2.default.createElement(
    "span",
    { className: "thread-detail-replies" },
    _react2.default.createElement(
      "span",
      { className: "material-icon" },
      "forum"
    ),
    _react2.default.createElement(
      "span",
      { className: compactClassName },
      replies
    ),
    _react2.default.createElement(
      "span",
      { className: fullClassName },
      interpolate(text, { replies: replies }, true)
    )
  );
}

function LastReplyLabel(_ref7) {
  var datetime = _ref7.datetime,
      url = _ref7.url;

  return _react2.default.createElement(
    "a",
    {
      className: "visible-sm-inline-block thread-detail-last-reply",
      href: url,
      title: datetime.format("LLL")
    },
    datetime.fromNow(true)
  );
}

function LastPoster(props) {
  var posterName = props.posterName,
      url = props.url;

  var className = "visible-sm-inline-block item-title thread-last-poster";

  if (url) {
    return _react2.default.createElement(
      "a",
      { className: className, href: url },
      posterName
    );
  }

  return _react2.default.createElement(
    "span",
    { className: className },
    posterName
  );
}

},{"../options":248,"./category":244,"react":"react"}],244:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (_ref) {
  var category = _ref.category,
      className = _ref.className;

  if (!category) return null;

  if (category.css_class) {
    className += " thread-detail-category-" + category.css_class;
  }

  return _react2.default.createElement(
    "a",
    { className: className, href: category.url.index },
    category.name
  );
};

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

},{"react":"react"}],245:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TopDetails = exports.BottomDetails = undefined;

var _bottom = require("./bottom");

var _bottom2 = _interopRequireDefault(_bottom);

var _top = require("./top");

var _top2 = _interopRequireDefault(_top);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.BottomDetails = _bottom2.default;
exports.TopDetails = _top2.default;

},{"./bottom":243,"./top":246}],246:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (_ref) {
  var category = _ref.category,
      thread = _ref.thread;

  return _react2.default.createElement(
    "div",
    { className: "thread-details-top" },
    _react2.default.createElement(NewLabel, { isRead: thread.is_read, url: thread.url.new_post }),
    _react2.default.createElement(PinnedLabel, { weight: thread.weight }),
    _react2.default.createElement(UnapprovedLabel, {
      thread: thread.is_unapproved,
      posts: thread.has_unapproved_posts
    }),
    _react2.default.createElement(BestAnswerLabel, { thread: thread }),
    _react2.default.createElement(_category2.default, {
      className: "item-title thread-detail-category visible-xs-inline-block",
      category: category
    }),
    _react2.default.createElement(LastReplyLabel, {
      datetime: thread.last_post_on,
      url: thread.url.last_post
    }),
    _react2.default.createElement(LastPoster, {
      posterName: thread.last_poster_name,
      url: thread.url.last_poster
    })
  );
};

exports.NewLabel = NewLabel;
exports.PinnedLabel = PinnedLabel;
exports.UnapprovedLabel = UnapprovedLabel;
exports.BestAnswerLabel = BestAnswerLabel;
exports.LastReplyLabel = LastReplyLabel;
exports.LastPoster = LastPoster;

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _category = require("./category");

var _category2 = _interopRequireDefault(_category);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function NewLabel(_ref2) {
  var isRead = _ref2.isRead,
      url = _ref2.url;

  if (isRead) return null;

  return _react2.default.createElement(
    "a",
    { className: "thread-detail-new", href: url },
    _react2.default.createElement(
      "span",
      { className: "material-icon" },
      "comment"
    ),
    _react2.default.createElement(
      "span",
      { className: "detail-text" },
      gettext("New posts")
    )
  );
}

function PinnedLabel(_ref3) {
  var weight = _ref3.weight;

  if (weight === 0) return null;

  var className = "thread-detail-pinned-globally";
  var icon = "bookmark";
  var text = gettext("Pinned globally");

  if (weight === 1) {
    className = "thread-detail-pinned-locally";
    icon = "bookmark_border";
    text = gettext("Pinned locally");
  }

  return _react2.default.createElement(
    "span",
    { className: className },
    _react2.default.createElement(
      "span",
      { className: "material-icon" },
      icon
    ),
    _react2.default.createElement(
      "span",
      { className: "detail-text" },
      text
    )
  );
}

function UnapprovedLabel(_ref4) {
  var posts = _ref4.posts,
      thread = _ref4.thread;

  if (!posts && !thread) return null;

  var className = "thread-detail-unapproved-posts";
  var icon = "remove_circle_outline";
  var text = gettext("Unapproved posts");

  if (thread) {
    className = "thread-detail-unapproved";
    icon = "remove_circle";
    text = gettext("Unapproved");
  }

  return _react2.default.createElement(
    "span",
    { className: className },
    _react2.default.createElement(
      "span",
      { className: "material-icon" },
      icon
    ),
    _react2.default.createElement(
      "span",
      { className: "detail-text" },
      text
    )
  );
}

function BestAnswerLabel(_ref5) {
  var thread = _ref5.thread;

  if (!thread.best_answer) return null;

  return _react2.default.createElement(
    "a",
    {
      className: "hidden-xs thread-detail-answered",
      href: thread.url.best_answer
    },
    _react2.default.createElement(
      "span",
      { className: "material-icon" },
      "check_box"
    ),
    _react2.default.createElement(
      "span",
      { className: "detail-text" },
      gettext("Answered")
    )
  );
}

function LastReplyLabel(_ref6) {
  var datetime = _ref6.datetime,
      url = _ref6.url;

  return _react2.default.createElement(
    "a",
    {
      className: "visible-xs-inline-block thread-detail-last-reply",
      href: url,
      title: datetime.format("LLL")
    },
    datetime.fromNow(true)
  );
}

function LastPoster(props) {
  var posterName = props.posterName,
      url = props.url;


  if (url) {
    return _react2.default.createElement(
      "a",
      {
        className: "visible-xs-inline-block item-title thread-last-poster",
        href: url
      },
      posterName
    );
  }

  return _react2.default.createElement(
    "span",
    { className: "visible-xs-inline-block item-title thread-last-poster" },
    posterName
  );
}

},{"./category":244,"react":"react"}],247:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (_ref) {
  var thread = _ref.thread;

  return _react2.default.createElement(
    "div",
    { className: "media" },
    _react2.default.createElement(
      "div",
      { className: "media-left" },
      _react2.default.createElement(
        _userUrl2.default,
        {
          className: "thread-last-poster-avatar",
          title: thread.last_poster_name,
          url: thread.url.last_poster
        },
        _react2.default.createElement(_avatar2.default, {
          className: "media-object",
          size: 40,
          user: thread.last_poster
        })
      )
    ),
    _react2.default.createElement(
      "div",
      { className: "media-body" },
      _react2.default.createElement(
        _userUrl2.default,
        {
          className: "item-title thread-last-poster",
          url: thread.url.last_poster
        },
        thread.last_poster_name
      ),
      _react2.default.createElement(Timestamp, { datetime: thread.last_post_on, url: thread.url.last_post })
    )
  );
};

exports.Timestamp = Timestamp;

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _avatar = require("../../avatar");

var _avatar2 = _interopRequireDefault(_avatar);

var _userUrl = require("./user-url");

var _userUrl2 = _interopRequireDefault(_userUrl);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function Timestamp(_ref2) {
  var datetime = _ref2.datetime,
      url = _ref2.url;

  return _react2.default.createElement(
    "a",
    { className: "thread-last-reply", href: url, title: datetime.format("LLL") },
    datetime.fromNow(true)
  );
}

},{"../../avatar":6,"./user-url":255,"react":"react"}],248:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Checkbox = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

exports.Options = Options;
exports.OptionsXs = OptionsXs;

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _compact = require("./subscription/compact");

var _compact2 = _interopRequireDefault(_compact);

var _full = require("./subscription/full");

var _full2 = _interopRequireDefault(_full);

var _selection = require("../../../reducers/selection");

var select = _interopRequireWildcard(_selection);

var _store = require("../../../services/store");

var _store2 = _interopRequireDefault(_store);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

function Options(_ref) {
  var display = _ref.display,
      disabled = _ref.disabled,
      isSelected = _ref.isSelected,
      thread = _ref.thread;

  if (!display) return null;

  var className = "col-sm-2 col-md-2 hidden-xs";
  if (thread.moderation.length) {
    className = "col-sm-3 col-md-2 hidden-xs";
  }

  return _react2.default.createElement(
    "div",
    { className: className },
    _react2.default.createElement(
      "div",
      { className: "row thread-options" },
      _react2.default.createElement(_full2.default, { thread: thread, disabled: disabled }),
      _react2.default.createElement(_compact2.default, { thread: thread, disabled: disabled }),
      _react2.default.createElement(Checkbox, { thread: thread, disabled: disabled, isSelected: isSelected })
    )
  );
}

function OptionsXs(_ref2) {
  var display = _ref2.display,
      disabled = _ref2.disabled,
      isSelected = _ref2.isSelected,
      thread = _ref2.thread;

  if (!display) return null;

  var className = "";
  if (thread.moderation.length) {
    className += "col-xs-6";
  } else {
    className += "col-xs-3";
  }
  className += " visible-xs-block thread-options-xs";

  return _react2.default.createElement(
    "div",
    { className: className },
    _react2.default.createElement(
      "div",
      { className: "row thread-options" },
      _react2.default.createElement(_full2.default, { thread: thread, disabled: disabled }),
      _react2.default.createElement(_compact2.default, { thread: thread, disabled: disabled }),
      _react2.default.createElement(Checkbox, { thread: thread, disabled: disabled, isSelected: isSelected })
    )
  );
}

var Checkbox = exports.Checkbox = function (_React$Component) {
  _inherits(Checkbox, _React$Component);

  function Checkbox() {
    var _ref3;

    var _temp, _this, _ret;

    _classCallCheck(this, Checkbox);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref3 = Checkbox.__proto__ || Object.getPrototypeOf(Checkbox)).call.apply(_ref3, [this].concat(args))), _this), _this.toggleSelection = function () {
      _store2.default.dispatch(select.item(_this.props.thread.id));
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(Checkbox, [{
    key: "render",
    value: function render() {
      var _props = this.props,
          disabled = _props.disabled,
          isSelected = _props.isSelected,
          thread = _props.thread;


      if (!thread.moderation.length) return null;

      return _react2.default.createElement(
        "div",
        { className: "col-xs-6" },
        _react2.default.createElement(
          "button",
          {
            className: "btn btn-default btn-icon btn-block",
            onClick: this.toggleSelection,
            disabled: disabled
          },
          _react2.default.createElement(
            "span",
            { className: "material-icon" },
            isSelected ? "check_box" : "check_box_outline_blank"
          )
        )
      );
    }
  }]);

  return Checkbox;
}(_react2.default.Component);

},{"../../../reducers/selection":358,"../../../services/store":377,"./subscription/compact":251,"./subscription/full":252,"react":"react"}],249:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _random = require("../../../utils/random");

var random = _interopRequireWildcard(_random);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_React$Component) {
  _inherits(_class, _React$Component);

  function _class() {
    _classCallCheck(this, _class);

    return _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).apply(this, arguments));
  }

  _createClass(_class, [{
    key: "shouldComponentUpdate",
    value: function shouldComponentUpdate() {
      return false;
    }
  }, {
    key: "render",
    value: function render() {
      return _react2.default.createElement(
        "li",
        { className: "list-group-item thread-preview" },
        _react2.default.createElement(
          "div",
          { className: "thread-details-top visible-xs-block" },
          _react2.default.createElement(
            "span",
            {
              className: "ui-preview-text",
              style: { width: random.int(30, 80) + "px" }
            },
            "\xA0"
          ),
          _react2.default.createElement(
            "span",
            {
              className: "ui-preview-text",
              style: { width: random.int(30, 80) + "px" }
            },
            "\xA0"
          ),
          _react2.default.createElement(
            "span",
            {
              className: "ui-preview-text",
              style: { width: random.int(30, 80) + "px" }
            },
            "\xA0"
          )
        ),
        _react2.default.createElement(
          "span",
          { className: "item-title thread-title" },
          _react2.default.createElement(
            "span",
            {
              className: "ui-preview-text",
              style: { width: random.int(60, 200) + "px" }
            },
            "\xA0"
          ),
          _react2.default.createElement(
            "span",
            {
              className: "ui-preview-text hidden-xs",
              style: { width: random.int(60, 200) + "px" }
            },
            "\xA0"
          ),
          _react2.default.createElement(
            "span",
            {
              className: "ui-preview-text hidden-xs",
              style: { width: random.int(60, 200) + "px" }
            },
            "\xA0"
          )
        ),
        _react2.default.createElement(
          "div",
          { className: "thread-details-bottom" },
          _react2.default.createElement(
            "div",
            null,
            _react2.default.createElement(
              "span",
              {
                className: "ui-preview-text",
                style: { width: random.int(30, 80) + "px" }
              },
              "\xA0"
            ),
            _react2.default.createElement(
              "span",
              {
                className: "ui-preview-text",
                style: { width: random.int(30, 80) + "px" }
              },
              "\xA0"
            ),
            _react2.default.createElement(
              "span",
              {
                className: "ui-preview-text",
                style: { width: random.int(30, 80) + "px" }
              },
              "\xA0"
            )
          )
        )
      );
    }
  }]);

  return _class;
}(_react2.default.Component);

exports.default = _class;

},{"../../../utils/random":388,"react":"react"}],250:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (props) {
  var activeCategory = props.activeCategory,
      categories = props.categories,
      list = props.list,
      thread = props.thread,
      isBusy = props.isBusy,
      isSelected = props.isSelected,
      showOptions = props.showOptions;


  var category = null;
  if (activeCategory.id !== thread.category) {
    category = categories[thread.category];
  }

  var flavor = category || activeCategory;

  var className = "thread-main col-xs-12";
  if (showOptions) {
    if (thread.moderation.length) {
      className += " col-sm-9 col-md-7";
    } else {
      className += " col-sm-10 col-md-7";
    }
  } else {
    className += " col-sm-12 col-md-9";
  }

  return _react2.default.createElement(
    "li",
    { className: getClassName(thread.is_read, isBusy, isSelected, flavor) },
    _react2.default.createElement(_details.TopDetails, { category: category, thread: thread }),
    _react2.default.createElement(
      "div",
      { className: "row thread-row" },
      _react2.default.createElement(
        "div",
        { className: className },
        _react2.default.createElement(
          "div",
          { className: "media" },
          _react2.default.createElement(
            "div",
            { className: "media-left hidden-xs" },
            _react2.default.createElement(
              _userUrl2.default,
              {
                className: "thread-starter-avatar",
                title: thread.starter_name,
                url: thread.url.starter
              },
              _react2.default.createElement(_avatar2.default, { size: 40, user: thread.starter })
            )
          ),
          _react2.default.createElement(
            "div",
            { className: "media-body" },
            _react2.default.createElement(
              "a",
              { href: thread.url.index, className: "item-title thread-title" },
              thread.title
            ),
            _react2.default.createElement(_details.BottomDetails, {
              category: category,
              disabled: isBusy,
              isSelected: isSelected,
              showOptions: showOptions,
              thread: thread
            })
          )
        )
      ),
      _react2.default.createElement(
        "div",
        { className: "col-md-3 hidden-xs hidden-sm thread-last-action" },
        _react2.default.createElement(_lastAction2.default, { thread: thread })
      ),
      _react2.default.createElement(_options.Options, {
        disabled: isBusy,
        display: showOptions,
        isSelected: isSelected,
        thread: thread
      })
    )
  );
};

exports.getClassName = getClassName;

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _avatar = require("../../avatar");

var _avatar2 = _interopRequireDefault(_avatar);

var _details = require("./details");

var _lastAction = require("./last-action");

var _lastAction2 = _interopRequireDefault(_lastAction);

var _options = require("./options");

var _userUrl = require("./user-url");

var _userUrl2 = _interopRequireDefault(_userUrl);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function getClassName(isRead, isBusy, isSelected, flavor) {
  var styles = ["list-group-item"];

  if (flavor && flavor.css_class) {
    styles.push("list-group-category-has-flavor");
    styles.push("list-group-item-category-" + flavor.css_class);
  }

  if (isRead) {
    styles.push("thread-read");
  } else {
    styles.push("thread-new");
  }

  if (isBusy) {
    styles.push("thread-busy");
  } else if (isSelected) {
    styles.push("thread-selected");
  }

  return styles.join(" ");
}

},{"../../avatar":6,"./details":245,"./last-action":247,"./options":248,"./user-url":255,"react":"react"}],251:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _full = require("./full");

var _full2 = _interopRequireDefault(_full);

var _modal = require("./modal");

var _modal2 = _interopRequireDefault(_modal);

var _modal3 = require("../../../../services/modal");

var _modal4 = _interopRequireDefault(_modal3);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_SubscriptionFull) {
  _inherits(_class, _SubscriptionFull);

  function _class() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, _class);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = _class.__proto__ || Object.getPrototypeOf(_class)).call.apply(_ref, [this].concat(args))), _this), _this.showOptions = function () {
      _modal4.default.show(_react2.default.createElement(_modal2.default, { thread: _this.props.thread }));
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(_class, [{
    key: "render",
    value: function render() {
      var moderation = this.props.thread.moderation;


      var className = "";
      if (moderation.length) {
        className += "col-xs-6";
      } else {
        className += "col-xs-12";
      }
      className += " hidden-md hidden-lg";

      return _react2.default.createElement(
        "div",
        { className: className },
        _react2.default.createElement(
          "button",
          {
            type: "button",
            className: this.getClassName(),
            disabled: this.props.disabled,
            onClick: this.showOptions
          },
          _react2.default.createElement(
            "span",
            { className: "material-icon" },
            this.getIcon()
          )
        )
      );
    }
  }]);

  return _class;
}(_full2.default);

exports.default = _class;

},{"../../../../services/modal":371,"./full":252,"./modal":253,"react":"react"}],252:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

exports.Label = Label;

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _options = require("./options");

var _options2 = _interopRequireDefault(_options);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_React$Component) {
  _inherits(_class, _React$Component);

  function _class() {
    _classCallCheck(this, _class);

    return _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).apply(this, arguments));
  }

  _createClass(_class, [{
    key: "getIcon",
    value: function getIcon() {
      if (this.props.thread.subscription === true) {
        return "star";
      } else if (this.props.thread.subscription === false) {
        return "star_half";
      }

      return "star_border";
    }
  }, {
    key: "getClassName",
    value: function getClassName() {
      if (this.props.thread.subscription === true) {
        return "btn btn-default btn-icon btn-block btn-subscribe btn-subscribe-full dropdown-toggle";
      } else if (this.props.thread.subscription === false) {
        return "btn btn-default btn-icon btn-block btn-subscribe btn-subscribe-half dropdown-toggle";
      }

      return "btn btn-default btn-icon btn-block btn-subscribe dropdown-toggle";
    }
  }, {
    key: "render",
    value: function render() {
      var _props$thread = this.props.thread,
          moderation = _props$thread.moderation,
          subscription = _props$thread.subscription;

      var fullwidth = !moderation.length;

      var className = fullwidth ? "col-xs-12" : "col-xs-6";
      className += " hidden-xs hidden-sm";

      return _react2.default.createElement(
        "div",
        { className: className },
        _react2.default.createElement(
          "div",
          { className: "btn-group btn-group-justified" },
          _react2.default.createElement(
            "div",
            { className: "btn-group" },
            _react2.default.createElement(
              "button",
              {
                type: "button",
                className: this.getClassName(),
                disabled: this.props.disabled,
                "data-toggle": "dropdown",
                "aria-haspopup": "true",
                "aria-expanded": "false"
              },
              _react2.default.createElement(
                "span",
                { className: "material-icon" },
                this.getIcon()
              ),
              _react2.default.createElement(Label, { moderation: moderation, subscription: subscription })
            ),
            _react2.default.createElement(_options2.default, {
              className: "dropdown-menu dropdown-menu-right",
              thread: this.props.thread
            })
          )
        )
      );
    }
  }]);

  return _class;
}(_react2.default.Component);

exports.default = _class;
function Label(_ref) {
  var moderation = _ref.moderation,
      subscription = _ref.subscription;

  if (moderation.length) return null;

  var text = gettext("Disabled");
  if (subscription === true) {
    text = gettext("E-mail");
  } else if (subscription === false) {
    text = gettext("Enabled");
  }

  return _react2.default.createElement(
    "span",
    { className: "btn-text" },
    text
  );
}

},{"./options":254,"react":"react"}],253:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _options = require("./options");

var _options2 = _interopRequireDefault(_options);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_React$Component) {
  _inherits(_class, _React$Component);

  function _class() {
    _classCallCheck(this, _class);

    return _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).apply(this, arguments));
  }

  _createClass(_class, [{
    key: "render",
    value: function render() {
      return _react2.default.createElement(
        "div",
        { className: "modal-dialog modal-sm", role: "document" },
        _react2.default.createElement(
          "div",
          { className: "modal-content" },
          _react2.default.createElement(
            "div",
            { className: "modal-header" },
            _react2.default.createElement(
              "button",
              {
                type: "button",
                className: "close",
                "data-dismiss": "modal",
                "aria-label": gettext("Close")
              },
              _react2.default.createElement(
                "span",
                { "aria-hidden": "true" },
                "\xD7"
              )
            ),
            _react2.default.createElement(
              "h4",
              { className: "modal-title" },
              gettext("Change subscription")
            )
          ),
          _react2.default.createElement(_options2.default, { className: "modal-menu", thread: this.props.thread })
        )
      );
    }
  }]);

  return _class;
}(_react2.default.Component);

exports.default = _class;

},{"./options":254,"react":"react"}],254:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _button = require("../../../button");

var _button2 = _interopRequireDefault(_button);

var _threads = require("../../../../reducers/threads");

var _ajax = require("../../../../services/ajax");

var _ajax2 = _interopRequireDefault(_ajax);

var _modal = require("../../../../services/modal");

var _modal2 = _interopRequireDefault(_modal);

var _snackbar = require("../../../../services/snackbar");

var _snackbar2 = _interopRequireDefault(_snackbar);

var _store = require("../../../../services/store");

var _store2 = _interopRequireDefault(_store);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var STATE_UPDATES = {
  unsubscribe: null,
  notify: false,
  email: true
};

var _class = function (_React$Component) {
  _inherits(_class, _React$Component);

  function _class(props) {
    _classCallCheck(this, _class);

    var _this = _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).call(this, props));

    _this.setSubscription = function (newState) {
      _modal2.default.hide();

      _this.setState({
        isLoading: true
      });

      var oldState = _this.props.thread.subscription;

      _store2.default.dispatch((0, _threads.patch)(_this.props.thread, {
        subscription: STATE_UPDATES[newState]
      }));

      _ajax2.default.patch(_this.props.thread.api.index, [{ op: "replace", path: "subscription", value: newState }]).then(function () {
        _this.setState({
          isLoading: false
        });
      }, function (rejection) {
        _this.setState({
          isLoading: false
        });
        _store2.default.dispatch((0, _threads.patch)(_this.props.thread, {
          subscription: STATE_UPDATES[oldState]
        }));
        _snackbar2.default.apiError(rejection);
      });
    };

    _this.unsubscribe = function () {
      _this.setSubscription("unsubscribe");
    };

    _this.notify = function () {
      _this.setSubscription("notify");
    };

    _this.email = function () {
      _this.setSubscription("email");
    };

    _this.state = {
      isLoading: false
    };
    return _this;
  }

  _createClass(_class, [{
    key: "render",
    value: function render() {
      return _react2.default.createElement(
        "ul",
        { className: this.props.className },
        _react2.default.createElement(
          "li",
          null,
          _react2.default.createElement(
            "button",
            { className: "btn-link", onClick: this.unsubscribe },
            _react2.default.createElement(
              "span",
              { className: "material-icon" },
              "star_border"
            ),
            gettext("Unsubscribe")
          )
        ),
        _react2.default.createElement(
          "li",
          null,
          _react2.default.createElement(
            "button",
            { className: "btn-link", onClick: this.notify },
            _react2.default.createElement(
              "span",
              { className: "material-icon" },
              "star_half"
            ),
            gettext("Subscribe")
          )
        ),
        _react2.default.createElement(
          "li",
          null,
          _react2.default.createElement(
            "button",
            { className: "btn-link", onClick: this.email },
            _react2.default.createElement(
              "span",
              { className: "material-icon" },
              "star"
            ),
            gettext("Subscribe with e-mail")
          )
        )
      );
    }
  }]);

  return _class;
}(_react2.default.Component);

exports.default = _class;

},{"../../../../reducers/threads":361,"../../../../services/ajax":365,"../../../../services/modal":371,"../../../../services/snackbar":376,"../../../../services/store":377,"../../../button":8,"react":"react"}],255:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (_ref) {
  var children = _ref.children,
      className = _ref.className,
      title = _ref.title,
      url = _ref.url;

  if (url) {
    return _react2.default.createElement(
      "a",
      { className: className, href: url, title: title },
      children
    );
  }

  return _react2.default.createElement(
    "span",
    { className: className, title: title },
    children
  );
};

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

},{"react":"react"}],256:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Subcategory = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _reactRouter = require("react-router");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var Subcategory = exports.Subcategory = function (_React$Component) {
  _inherits(Subcategory, _React$Component);

  function Subcategory() {
    _classCallCheck(this, Subcategory);

    return _possibleConstructorReturn(this, (Subcategory.__proto__ || Object.getPrototypeOf(Subcategory)).apply(this, arguments));
  }

  _createClass(Subcategory, [{
    key: "getUrl",
    value: function getUrl() {
      if (this.props.listPath) {
        return this.props.category.url.index + this.props.listPath;
      } else {
        return this.props.category.url.index;
      }
    }
  }, {
    key: "render",
    value: function render() {
      return _react2.default.createElement(
        "li",
        null,
        _react2.default.createElement(
          _reactRouter.Link,
          { to: this.getUrl(), className: "btn btn-link" },
          this.props.category.name
        )
      );
    }
  }]);

  return Subcategory;
}(_react2.default.Component);

var _class = function (_React$Component2) {
  _inherits(_class, _React$Component2);

  function _class() {
    _classCallCheck(this, _class);

    return _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).apply(this, arguments));
  }

  _createClass(_class, [{
    key: "render",
    value: function render() {
      var _this3 = this;

      return _react2.default.createElement(
        "div",
        { className: "dropdown category-picker" },
        _react2.default.createElement(
          "button",
          {
            type: "button",
            className: "btn btn-default btn-outline dropdown-toggle btn-block",
            "data-toggle": "dropdown",
            "aria-haspopup": "true",
            "aria-expanded": "false"
          },
          _react2.default.createElement(
            "span",
            { className: "material-icon" },
            "label_outline"
          ),
          _react2.default.createElement(
            "span",
            { className: "hidden-xs" },
            gettext("Category")
          )
        ),
        _react2.default.createElement(
          "ul",
          { className: "dropdown-menu stick-to-bottom categories-menu" },
          this.props.choices.map(function (id) {
            if (_this3.props.categories[id]) {
              return _react2.default.createElement(Subcategory, {
                category: _this3.props.categories[id],
                listPath: _this3.props.list.path,
                key: id
              });
            } else {
              return null;
            }
          })
        )
      );
    }
  }]);

  return _class;
}(_react2.default.Component);

exports.default = _class;

},{"react":"react","react-router":"react-router"}],257:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.compareLastPostAge = compareLastPostAge;
exports.compareGlobalWeight = compareGlobalWeight;
exports.compareWeight = compareWeight;
function compareLastPostAge(a, b) {
  if (a.last_post > b.last_post) {
    return -1;
  } else if (a.last_post < b.last_post) {
    return 1;
  } else {
    return 0;
  }
}

function compareGlobalWeight(a, b) {
  if (a.weight === 2 && a.weight > b.weight) {
    return -1;
  } else if (b.weight === 2 && a.weight < b.weight) {
    return 1;
  } else {
    return compareLastPostAge(a, b);
  }
}

function compareWeight(a, b) {
  if (a.weight > b.weight) {
    return -1;
  } else if (a.weight < b.weight) {
    return 1;
  } else {
    return compareLastPostAge(a, b);
  }
}

},{}],258:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _pageLead = require("../page-lead");

var _pageLead2 = _interopRequireDefault(_pageLead);

var _toolbar = require("./toolbar");

var _toolbar2 = _interopRequireDefault(_toolbar);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_React$Component) {
  _inherits(_class, _React$Component);

  function _class() {
    _classCallCheck(this, _class);

    return _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).apply(this, arguments));
  }

  _createClass(_class, [{
    key: "getCategoryDescription",
    value: function getCategoryDescription() {
      if (this.props.pageLead) {
        return _react2.default.createElement(
          "div",
          { className: "category-description" },
          _react2.default.createElement(
            "div",
            { className: "page-lead" },
            _react2.default.createElement(
              "p",
              null,
              this.props.pageLead
            )
          )
        );
      } else if (this.props.route.category.description) {
        return _react2.default.createElement(
          "div",
          { className: "category-description" },
          _react2.default.createElement(_pageLead2.default, { copy: this.props.route.category.description.html })
        );
      } else {
        return null;
      }
    }
  }, {
    key: "getDisableToolbar",
    value: function getDisableToolbar() {
      return !this.props.isLoaded || this.props.isBusy || this.props.busyThreads.length;
    }
  }, {
    key: "getToolbar",
    value: function getToolbar() {
      var isVisible = this.props.subcategories.length || this.props.user.id;

      if (!isVisible) return null;

      return _react2.default.createElement(_toolbar2.default, {
        subcategories: this.props.subcategories,
        categories: this.props.route.categories,
        categoriesMap: this.props.route.categoriesMap,
        list: this.props.route.list,
        threads: this.props.threads,
        moderation: this.props.moderation,
        selection: this.props.selection,
        selectAllThreads: this.props.selectAllThreads,
        selectNoneThreads: this.props.selectNoneThreads,
        addThreads: this.props.addThreads,
        freezeThread: this.props.freezeThread,
        deleteThread: this.props.deleteThread,
        updateThread: this.props.updateThread,
        api: this.props.api,
        route: this.props.route,
        disabled: this.getDisableToolbar(),
        user: this.props.user
      });
    }
  }, {
    key: "render",
    value: function render() {
      return _react2.default.createElement(
        "div",
        { className: "container" },
        this.getCategoryDescription(),
        this.getToolbar(),
        this.props.children
      );
    }
  }]);

  return _class;
}(_react2.default.Component);

exports.default = _class;

},{"../page-lead":92,"./toolbar":269,"react":"react"}],259:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

exports.ParentCategory = ParentCategory;

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _reactRouter = require("react-router");

var _button = require("../button");

var _button2 = _interopRequireDefault(_button);

var _dropdownToggle = require("../dropdown-toggle");

var _dropdownToggle2 = _interopRequireDefault(_dropdownToggle);

var _nav = require("./nav");

var _nav2 = _interopRequireDefault(_nav);

var _ajax = require("../../services/ajax");

var _ajax2 = _interopRequireDefault(_ajax);

var _posting = require("../../services/posting");

var _posting2 = _interopRequireDefault(_posting);

var _snackbar = require("../../services/snackbar");

var _snackbar2 = _interopRequireDefault(_snackbar);

var _store = require("../../services/store");

var _store2 = _interopRequireDefault(_store);

var _ = require("../..");

var _2 = _interopRequireDefault(_);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_React$Component) {
  _inherits(_class, _React$Component);

  function _class(props) {
    _classCallCheck(this, _class);

    var _this = _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).call(this, props));

    _this.startThread = function () {
      _posting2.default.open(_this.props.startThread || {
        mode: "START",

        config: _2.default.get("THREAD_EDITOR_API"),
        submit: _2.default.get("THREADS_API"),

        category: _this.props.route.category.id
      });
    };

    _this.state = {
      isBusy: false
    };
    return _this;
  }

  _createClass(_class, [{
    key: "hasGoBackButton",
    value: function hasGoBackButton() {
      return !!this.props.route.category.parent;
    }
  }, {
    key: "getGoBackButton",
    value: function getGoBackButton() {
      if (!this.props.route.category.parent) return null;

      var parent = this.props.categories[this.props.route.category.parent];

      return _react2.default.createElement(
        "div",
        { className: "hidden-xs col-sm-2 col-lg-1" },
        _react2.default.createElement(
          _reactRouter.Link,
          {
            className: "btn btn-default btn-icon btn-aligned btn-go-back btn-block btn-outline",
            to: parent.url.index + this.props.route.list.path
          },
          _react2.default.createElement(
            "span",
            { className: "material-icon" },
            "keyboard_arrow_left"
          )
        )
      );
    }
  }, {
    key: "getStartThreadButton",
    value: function getStartThreadButton() {
      if (!this.props.user.id) return null;

      return _react2.default.createElement(
        _button2.default,
        {
          className: "btn-primary btn-block btn-outline",
          onClick: this.startThread,
          disabled: this.props.disabled
        },
        _react2.default.createElement(
          "span",
          { className: "material-icon" },
          "chat"
        ),
        gettext("Start thread")
      );
    }
  }, {
    key: "render",
    value: function render() {
      var headerClassName = "col-xs-12";
      if (this.hasGoBackButton()) {
        headerClassName += " col-sm-10 col-lg-11 sm-align-row-buttons";
      }

      var isAuthenticated = !!this.props.user.id;

      return _react2.default.createElement(
        "div",
        { className: "page-header-bg" },
        _react2.default.createElement(
          "div",
          { className: "page-header" },
          _react2.default.createElement(
            "div",
            { className: "container" },
            _react2.default.createElement(
              "div",
              { className: "row" },
              _react2.default.createElement(
                "div",
                {
                  className: isAuthenticated ? "col-sm-9 col-md-10" : "col-xs-12"
                },
                _react2.default.createElement(
                  "div",
                  { className: "row" },
                  this.getGoBackButton(),
                  _react2.default.createElement(
                    "div",
                    { className: headerClassName },
                    _react2.default.createElement(ParentCategory, {
                      categories: this.props.categories,
                      category: this.props.route.category.parent
                    }),
                    _react2.default.createElement(
                      "h1",
                      null,
                      this.props.title
                    )
                  )
                )
              ),
              isAuthenticated && _react2.default.createElement(
                "div",
                { className: "col-sm-3 col-md-2 xs-margin-top" },
                this.getStartThreadButton()
              )
            )
          ),
          _react2.default.createElement(_nav2.default, {
            baseUrl: this.props.route.category.url.index,
            list: this.props.route.list,
            lists: this.props.route.lists
          })
        )
      );
    }
  }]);

  return _class;
}(_react2.default.Component);

exports.default = _class;
function ParentCategory(_ref) {
  var categories = _ref.categories,
      category = _ref.category;

  if (!category) return null;

  var parent = categories[category];

  return _react2.default.createElement(
    _reactRouter.Link,
    { className: "go-back-sm visible-xs-block", to: parent.url.index },
    _react2.default.createElement(
      "span",
      { className: "material-icon" },
      "chevron_left"
    ),
    parent.parent ? parent.name : gettext("Threads")
  );
}

},{"../..":302,"../../services/ajax":365,"../../services/posting":375,"../../services/snackbar":376,"../../services/store":377,"../button":8,"../dropdown-toggle":27,"./nav":266,"react":"react","react-router":"react-router"}],260:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_React$Component) {
  _inherits(_class, _React$Component);

  function _class() {
    _classCallCheck(this, _class);

    return _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).apply(this, arguments));
  }

  _createClass(_class, [{
    key: "render",
    value: function render() {
      if (this.props.list.type === "all") {
        if (this.props.emptyMessage) {
          return _react2.default.createElement(
            "li",
            { className: "list-group-item empty-message" },
            _react2.default.createElement(
              "p",
              { className: "lead" },
              this.props.emptyMessage
            ),
            _react2.default.createElement(
              "p",
              null,
              gettext("Why not start one yourself?")
            )
          );
        } else {
          return _react2.default.createElement(
            "li",
            { className: "list-group-item empty-message" },
            _react2.default.createElement(
              "p",
              { className: "lead" },
              this.props.category.special_role ? gettext("There are no threads on this forum... yet!") : gettext("There are no threads in this category.")
            ),
            _react2.default.createElement(
              "p",
              null,
              gettext("Why not start one yourself?")
            )
          );
        }
      } else {
        return _react2.default.createElement(
          "li",
          { className: "list-group-item empty-message" },
          gettext("No threads matching specified criteria were found.")
        );
      }
    }
  }]);

  return _class;
}(_react2.default.Component);

exports.default = _class;

},{"react":"react"}],261:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _errorsList = require("./errors-list");

var _errorsList2 = _interopRequireDefault(_errorsList);

var _merge = require("./merge");

var _merge2 = _interopRequireDefault(_merge);

var _move = require("./move");

var _move2 = _interopRequireDefault(_move);

var _selection = require("../../../reducers/selection");

var select = _interopRequireWildcard(_selection);

var _ajax = require("../../../services/ajax");

var _ajax2 = _interopRequireDefault(_ajax);

var _modal = require("../../../services/modal");

var _modal2 = _interopRequireDefault(_modal);

var _snackbar = require("../../../services/snackbar");

var _snackbar2 = _interopRequireDefault(_snackbar);

var _store = require("../../../services/store");

var _store2 = _interopRequireDefault(_store);

var _countdown = require("../../../utils/countdown");

var _countdown2 = _interopRequireDefault(_countdown);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_React$Component) {
  _inherits(_class, _React$Component);

  function _class() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, _class);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = _class.__proto__ || Object.getPrototypeOf(_class)).call.apply(_ref, [this].concat(args))), _this), _this.callApi = function (ops, successMessage) {
      var onSuccess = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;

      // freeze threads
      _this.props.threads.forEach(function (thread) {
        _this.props.freezeThread(thread.id);
      });

      // list ids
      var ids = _this.props.threads.map(function (thread) {
        return thread.id;
      });

      // always return current acl
      ops.push({ op: "add", path: "acl", value: true });

      _ajax2.default.patch(_this.props.api, { ids: ids, ops: ops }).then(function (data) {
        // unfreeze
        _this.props.threads.forEach(function (thread) {
          _this.props.freezeThread(thread.id);
        });

        // update threads
        data.forEach(function (thread) {
          _this.props.updateThread(thread);
        });

        // show success message and call callback
        _snackbar2.default.success(successMessage);
        if (onSuccess) {
          onSuccess();
        }
      }, function (rejection) {
        // unfreeze
        _this.props.threads.forEach(function (thread) {
          _this.props.freezeThread(thread.id);
        });

        // escape on non-400 error
        if (rejection.status !== 400) {
          return _snackbar2.default.apiError(rejection);
        }

        // build errors list
        var errors = [];
        var threadsMap = {};

        _this.props.threads.forEach(function (thread) {
          threadsMap[thread.id] = thread;
        });

        rejection.forEach(function (_ref2) {
          var id = _ref2.id,
              detail = _ref2.detail;

          if (typeof threadsMap[id] !== "undefined") {
            errors.push({
              errors: detail,
              thread: threadsMap[id]
            });
          }
        });

        _modal2.default.show(_react2.default.createElement(_errorsList2.default, { errors: errors }));
      });
    }, _this.pinGlobally = function () {
      _this.callApi([{
        op: "replace",
        path: "weight",
        value: 2
      }], gettext("Selected threads were pinned globally."));
    }, _this.pinLocally = function () {
      _this.callApi([{
        op: "replace",
        path: "weight",
        value: 1
      }], gettext("Selected threads were pinned locally."));
    }, _this.unpin = function () {
      _this.callApi([{
        op: "replace",
        path: "weight",
        value: 0
      }], gettext("Selected threads were unpinned."));
    }, _this.approve = function () {
      _this.callApi([{
        op: "replace",
        path: "is-unapproved",
        value: false
      }], gettext("Selected threads were approved."));
    }, _this.open = function () {
      _this.callApi([{
        op: "replace",
        path: "is-closed",
        value: false
      }], gettext("Selected threads were opened."));
    }, _this.close = function () {
      _this.callApi([{
        op: "replace",
        path: "is-closed",
        value: true
      }], gettext("Selected threads were closed."));
    }, _this.unhide = function () {
      _this.callApi([{
        op: "replace",
        path: "is-hidden",
        value: false
      }], gettext("Selected threads were unhidden."));
    }, _this.hide = function () {
      _this.callApi([{
        op: "replace",
        path: "is-hidden",
        value: true
      }], gettext("Selected threads were hidden."));
    }, _this.move = function () {
      _modal2.default.show(_react2.default.createElement(_move2.default, {
        callApi: _this.callApi,
        categories: _this.props.categories,
        categoriesMap: _this.props.categoriesMap,
        route: _this.props.route,
        user: _this.props.user
      }));
    }, _this.merge = function () {
      var errors = [];
      _this.props.threads.forEach(function (thread) {
        if (!thread.acl.can_merge) {
          errors.append({
            id: thread.id,
            title: thread.title,
            errors: [gettext("You don't have permission to merge this thread with others.")]
          });
        }
      });

      if (_this.props.threads.length < 2) {
        _snackbar2.default.info(gettext("You have to select at least two threads to merge."));
      } else if (errors.length) {
        _modal2.default.show(_react2.default.createElement(_errorsList2.default, { errors: errors }));
        return;
      } else {
        _modal2.default.show(_react2.default.createElement(_merge2.default, _this.props));
      }
    }, _this.delete = function () {
      if (!confirm(gettext("Are you sure you want to delete selected threads?"))) {
        return;
      }

      _this.props.threads.map(function (thread) {
        _this.props.freezeThread(thread.id);
      });

      var ids = _this.props.threads.map(function (thread) {
        return thread.id;
      });

      _ajax2.default.delete(_this.props.api, ids).then(function () {
        _this.props.threads.map(function (thread) {
          _this.props.freezeThread(thread.id);
          _this.props.deleteThread(thread);
        });

        _snackbar2.default.success(gettext("Selected threads were deleted."));
      }, function (rejection) {
        if (rejection.status === 400) {
          var failedThreads = rejection.map(function (thread) {
            return thread.id;
          });

          _this.props.threads.map(function (thread) {
            _this.props.freezeThread(thread.id);
            if (failedThreads.indexOf(thread.id) === -1) {
              _this.props.deleteThread(thread);
            }
          });

          _modal2.default.show(_react2.default.createElement(_errorsList2.default, { errors: rejection }));
        } else {
          _snackbar2.default.apiError(rejection);
        }
      });
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(_class, [{
    key: "getPinGloballyButton",
    value: function getPinGloballyButton() {
      if (!this.props.moderation.can_pin_globally) return null;

      return _react2.default.createElement(
        "li",
        null,
        _react2.default.createElement(
          "button",
          {
            className: "btn btn-link",
            onClick: this.pinGlobally,
            type: "button"
          },
          _react2.default.createElement(
            "span",
            { className: "material-icon" },
            "bookmark"
          ),
          gettext("Pin threads globally")
        )
      );
    }
  }, {
    key: "getPinLocallyButton",
    value: function getPinLocallyButton() {
      if (!this.props.moderation.can_pin) return null;

      return _react2.default.createElement(
        "li",
        null,
        _react2.default.createElement(
          "button",
          {
            className: "btn btn-link",
            onClick: this.pinLocally,
            type: "button"
          },
          _react2.default.createElement(
            "span",
            { className: "material-icon" },
            "bookmark_border"
          ),
          gettext("Pin threads locally")
        )
      );
    }
  }, {
    key: "getUnpinButton",
    value: function getUnpinButton() {
      if (!this.props.moderation.can_pin) return null;

      return _react2.default.createElement(
        "li",
        null,
        _react2.default.createElement(
          "button",
          { className: "btn btn-link", onClick: this.unpin, type: "button" },
          _react2.default.createElement(
            "span",
            { className: "material-icon" },
            "panorama_fish_eye"
          ),
          gettext("Unpin threads")
        )
      );
    }
  }, {
    key: "getMoveButton",
    value: function getMoveButton() {
      if (!this.props.moderation.can_move) return null;

      return _react2.default.createElement(
        "li",
        null,
        _react2.default.createElement(
          "button",
          { className: "btn btn-link", onClick: this.move, type: "button" },
          _react2.default.createElement(
            "span",
            { className: "material-icon" },
            "arrow_forward"
          ),
          gettext("Move threads")
        )
      );
    }
  }, {
    key: "getMergeButton",
    value: function getMergeButton() {
      if (!this.props.moderation.can_merge) return null;

      return _react2.default.createElement(
        "li",
        null,
        _react2.default.createElement(
          "button",
          { className: "btn btn-link", onClick: this.merge, type: "button" },
          _react2.default.createElement(
            "span",
            { className: "material-icon" },
            "call_merge"
          ),
          gettext("Merge threads")
        )
      );
    }
  }, {
    key: "getApproveButton",
    value: function getApproveButton() {
      if (!this.props.moderation.can_approve) return null;

      return _react2.default.createElement(
        "li",
        null,
        _react2.default.createElement(
          "button",
          { className: "btn btn-link", onClick: this.approve, type: "button" },
          _react2.default.createElement(
            "span",
            { className: "material-icon" },
            "done"
          ),
          gettext("Approve threads")
        )
      );
    }
  }, {
    key: "getOpenButton",
    value: function getOpenButton() {
      if (!this.props.moderation.can_close) return null;

      return _react2.default.createElement(
        "li",
        null,
        _react2.default.createElement(
          "button",
          { className: "btn btn-link", onClick: this.open, type: "button" },
          _react2.default.createElement(
            "span",
            { className: "material-icon" },
            "lock_open"
          ),
          gettext("Open threads")
        )
      );
    }
  }, {
    key: "getCloseButton",
    value: function getCloseButton() {
      if (!this.props.moderation.can_close) return null;

      return _react2.default.createElement(
        "li",
        null,
        _react2.default.createElement(
          "button",
          { className: "btn btn-link", onClick: this.close, type: "button" },
          _react2.default.createElement(
            "span",
            { className: "material-icon" },
            "lock_outline"
          ),
          gettext("Close threads")
        )
      );
    }
  }, {
    key: "getUnhideButton",
    value: function getUnhideButton() {
      if (!this.props.moderation.can_unhide) return null;

      return _react2.default.createElement(
        "li",
        null,
        _react2.default.createElement(
          "button",
          { className: "btn btn-link", onClick: this.unhide, type: "button" },
          _react2.default.createElement(
            "span",
            { className: "material-icon" },
            "visibility"
          ),
          gettext("Unhide threads")
        )
      );
    }
  }, {
    key: "getHideButton",
    value: function getHideButton() {
      if (!this.props.moderation.can_hide) return null;

      return _react2.default.createElement(
        "li",
        null,
        _react2.default.createElement(
          "button",
          { onClick: this.hide, type: "button", className: "btn btn-link" },
          _react2.default.createElement(
            "span",
            { className: "material-icon" },
            "visibility_off"
          ),
          gettext("Hide threads")
        )
      );
    }
  }, {
    key: "getDeleteButton",
    value: function getDeleteButton() {
      if (!this.props.moderation.can_delete) return null;

      return _react2.default.createElement(
        "li",
        null,
        _react2.default.createElement(
          "button",
          { className: "btn btn-link", onClick: this.delete, type: "button" },
          _react2.default.createElement(
            "span",
            { className: "material-icon" },
            "clear"
          ),
          gettext("Delete threads")
        )
      );
    }
  }, {
    key: "render",
    value: function render() {
      return _react2.default.createElement(
        "ul",
        { className: this.props.className },
        this.getPinGloballyButton(),
        this.getPinLocallyButton(),
        this.getUnpinButton(),
        this.getMoveButton(),
        this.getMergeButton(),
        this.getApproveButton(),
        this.getOpenButton(),
        this.getCloseButton(),
        this.getUnhideButton(),
        this.getHideButton(),
        this.getDeleteButton()
      );
    }
  }]);

  return _class;
}(_react2.default.Component);

exports.default = _class;

},{"../../../reducers/selection":358,"../../../services/ajax":365,"../../../services/modal":371,"../../../services/snackbar":376,"../../../services/store":377,"../../../utils/countdown":382,"./errors-list":262,"./merge":263,"./move":264,"react":"react"}],262:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

exports.ThreadErrors = ThreadErrors;

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_React$Component) {
  _inherits(_class, _React$Component);

  function _class() {
    _classCallCheck(this, _class);

    return _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).apply(this, arguments));
  }

  _createClass(_class, [{
    key: "render",
    value: function render() {
      return _react2.default.createElement(
        "div",
        { className: "modal-dialog", role: "document" },
        _react2.default.createElement(
          "div",
          { className: "modal-content" },
          _react2.default.createElement(
            "div",
            { className: "modal-header" },
            _react2.default.createElement(
              "button",
              {
                "aria-label": gettext("Close"),
                className: "close",
                "data-dismiss": "modal",
                type: "button"
              },
              _react2.default.createElement(
                "span",
                { "aria-hidden": "true" },
                "\xD7"
              )
            ),
            _react2.default.createElement(
              "h4",
              { className: "modal-title" },
              gettext("Threads moderation")
            )
          ),
          _react2.default.createElement(
            "div",
            { className: "modal-body" },
            _react2.default.createElement(
              "p",
              { className: "lead" },
              gettext("One or more threads could not be deleted:")
            ),
            _react2.default.createElement(
              "ul",
              { className: "list-unstyled list-errored-items" },
              this.props.errors.map(function (item) {
                return _react2.default.createElement(ThreadErrors, {
                  errors: item.errors,
                  key: item.thread.id,
                  thread: item.thread
                });
              })
            )
          )
        )
      );
    }
  }]);

  return _class;
}(_react2.default.Component);

exports.default = _class;
function ThreadErrors(_ref) {
  var errors = _ref.errors,
      thread = _ref.thread;

  return _react2.default.createElement(
    "li",
    null,
    _react2.default.createElement(
      "h5",
      null,
      thread.title
    ),
    errors.map(function (message, i) {
      return _react2.default.createElement(
        "p",
        null,
        message
      );
    })
  );
}

},{"react":"react"}],263:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _button = require("../../button");

var _button2 = _interopRequireDefault(_button);

var _form = require("../../form");

var _form2 = _interopRequireDefault(_form);

var _formGroup = require("../../form-group");

var _formGroup2 = _interopRequireDefault(_formGroup);

var _categorySelect = require("../../category-select");

var _categorySelect2 = _interopRequireDefault(_categorySelect);

var _select = require("../../select");

var _select2 = _interopRequireDefault(_select);

var _index = require("../../../index");

var _index2 = _interopRequireDefault(_index);

var _threads = require("../../../reducers/threads");

var _selection = require("../../../reducers/selection");

var select = _interopRequireWildcard(_selection);

var _errorsList = require("./errors-list");

var _errorsList2 = _interopRequireDefault(_errorsList);

var _mergeConflict = require("../../merge-conflict");

var _mergeConflict2 = _interopRequireDefault(_mergeConflict);

var _ajax = require("../../../services/ajax");

var _ajax2 = _interopRequireDefault(_ajax);

var _modal = require("../../../services/modal");

var _modal2 = _interopRequireDefault(_modal);

var _snackbar = require("../../../services/snackbar");

var _snackbar2 = _interopRequireDefault(_snackbar);

var _store = require("../../../services/store");

var _store2 = _interopRequireDefault(_store);

var _validators = require("../../../utils/validators");

var validators = _interopRequireWildcard(_validators);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_Form) {
  _inherits(_class, _Form);

  function _class(props) {
    _classCallCheck(this, _class);

    var _this = _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).call(this, props));

    _this.getFormdata = function () {
      return {
        threads: _this.props.threads.map(function (thread) {
          return thread.id;
        }),
        title: _this.state.title,
        category: _this.state.category,
        weight: _this.state.weight,
        is_hidden: _this.state.is_hidden,
        is_closed: _this.state.is_closed
      };
    };

    _this.handleSuccess = function (apiResponse) {
      // unfreeze and remove merged threads
      _this.props.threads.forEach(function (thread) {
        _this.props.freezeThread(thread.id);
        _this.props.deleteThread(thread);
      });

      // deselect all threads
      _store2.default.dispatch(select.none());

      // append merged thread, filter threads
      _this.props.addThreads([apiResponse]);
      _store2.default.dispatch((0, _threads.filterThreads)(_this.props.route.category, _this.props.categoriesMap));

      // hide modal
      _modal2.default.hide();
    };

    _this.handleError = function (rejection) {
      if (rejection.status === 400) {
        if (rejection.best_answers || rejection.polls) {
          _modal2.default.show(_react2.default.createElement(_mergeConflict2.default, {
            api: _index2.default.get("MERGE_THREADS_API"),
            bestAnswers: rejection.best_answers,
            data: _this.getFormdata(),
            polls: rejection.polls,
            onError: _this.handleError,
            onSuccess: _this.handleSuccess
          }));
        } else {
          _this.setState({
            errors: Object.assign({}, _this.state.errors, rejection)
          });
          _snackbar2.default.error(gettext("Form contains errors."));
        }
      } else if (rejection.status === 403 && Array.isArray(rejection)) {
        _modal2.default.show(_react2.default.createElement(_errorsList2.default, { errors: rejection }));
      } else if (rejection.best_answer) {
        _snackbar2.default.error(rejection.best_answer[0]);
      } else if (rejection.poll) {
        _snackbar2.default.error(rejection.poll[0]);
      } else {
        _snackbar2.default.apiError(rejection);
      }
    };

    _this.onCategoryChange = function (ev) {
      var categoryId = ev.target.value;
      var newState = {
        category: categoryId
      };

      if (_this.acl[categoryId].can_pin_threads < newState.weight) {
        newState.weight = 0;
      }

      if (!_this.acl[categoryId].can_hide_threads) {
        newState.is_hidden = 0;
      }

      if (!_this.acl[categoryId].can_close_threads) {
        newState.is_closed = false;
      }

      _this.setState(newState);
    };

    _this.state = {
      isLoading: false,

      title: "",
      category: null,
      weight: 0,
      is_hidden: 0,
      is_closed: false,

      validators: {
        title: [validators.required()]
      },

      errors: {}
    };

    _this.acl = {};
    for (var i in props.user.acl.categories) {
      if (!props.user.acl.categories.hasOwnProperty(i)) {
        continue;
      }

      var acl = props.user.acl.categories[i];
      _this.acl[acl.id] = acl;
    }

    _this.categoryChoices = [];
    props.categories.forEach(function (category) {
      if (category.level > 0) {
        var _acl = _this.acl[category.id];
        var disabled = !_acl.can_start_threads || category.is_closed && !_acl.can_close_threads;

        _this.categoryChoices.push({
          value: category.id,
          disabled: disabled,
          level: category.level - 1,
          label: category.name
        });

        if (!disabled && !_this.state.category) {
          _this.state.category = category.id;
        }
      }
    });

    _this.isHiddenChoices = [{
      value: 0,
      icon: "visibility",
      label: gettext("No")
    }, {
      value: 1,
      icon: "visibility_off",
      label: gettext("Yes")
    }];

    _this.isClosedChoices = [{
      value: false,
      icon: "lock_outline",
      label: gettext("No")
    }, {
      value: true,
      icon: "lock",
      label: gettext("Yes")
    }];
    return _this;
  }

  _createClass(_class, [{
    key: "clean",
    value: function clean() {
      if (this.isValid()) {
        return true;
      } else {
        _snackbar2.default.error(gettext("Form contains errors."));
        this.setState({
          errors: this.validate()
        });
        return false;
      }
    }
  }, {
    key: "send",
    value: function send() {
      return _ajax2.default.post(_index2.default.get("MERGE_THREADS_API"), this.getFormdata());
    }
  }, {
    key: "getWeightChoices",
    value: function getWeightChoices() {
      var choices = [{
        value: 0,
        icon: "remove",
        label: gettext("Not pinned")
      }, {
        value: 1,
        icon: "bookmark_border",
        label: gettext("Pinned locally")
      }];

      if (this.acl[this.state.category].can_pin_threads == 2) {
        choices.push({
          value: 2,
          icon: "bookmark",
          label: gettext("Pinned globally")
        });
      }

      return choices;
    }
  }, {
    key: "renderWeightField",
    value: function renderWeightField() {
      if (this.acl[this.state.category].can_pin_threads) {
        return _react2.default.createElement(
          _formGroup2.default,
          { label: gettext("Thread weight"), "for": "id_weight" },
          _react2.default.createElement(_select2.default, {
            id: "id_weight",
            onChange: this.bindInput("weight"),
            value: this.state.weight,
            choices: this.getWeightChoices()
          })
        );
      } else {
        return null;
      }
    }
  }, {
    key: "renderHiddenField",
    value: function renderHiddenField() {
      if (this.acl[this.state.category].can_hide_threads) {
        return _react2.default.createElement(
          _formGroup2.default,
          { label: gettext("Hide thread"), "for": "id_is_hidden" },
          _react2.default.createElement(_select2.default, {
            id: "id_is_closed",
            onChange: this.bindInput("is_hidden"),
            value: this.state.is_hidden,
            choices: this.isHiddenChoices
          })
        );
      } else {
        return null;
      }
    }
  }, {
    key: "renderClosedField",
    value: function renderClosedField() {
      if (this.acl[this.state.category].can_close_threads) {
        return _react2.default.createElement(
          _formGroup2.default,
          { label: gettext("Close thread"), "for": "id_is_closed" },
          _react2.default.createElement(_select2.default, {
            id: "id_is_closed",
            onChange: this.bindInput("is_closed"),
            value: this.state.is_closed,
            choices: this.isClosedChoices
          })
        );
      } else {
        return null;
      }
    }
  }, {
    key: "renderForm",
    value: function renderForm() {
      return _react2.default.createElement(
        "form",
        { onSubmit: this.handleSubmit },
        _react2.default.createElement(
          "div",
          { className: "modal-body" },
          _react2.default.createElement(
            _formGroup2.default,
            {
              label: gettext("Thread title"),
              "for": "id_title",
              validation: this.state.errors.title
            },
            _react2.default.createElement("input", {
              id: "id_title",
              className: "form-control",
              type: "text",
              onChange: this.bindInput("title"),
              value: this.state.title
            })
          ),
          _react2.default.createElement("div", { className: "clearfix" }),
          _react2.default.createElement(
            _formGroup2.default,
            {
              label: gettext("Category"),
              "for": "id_category",
              validation: this.state.errors.category
            },
            _react2.default.createElement(_categorySelect2.default, {
              id: "id_category",
              onChange: this.onCategoryChange,
              value: this.state.category,
              choices: this.categoryChoices
            })
          ),
          _react2.default.createElement("div", { className: "clearfix" }),
          this.renderWeightField(),
          this.renderHiddenField(),
          this.renderClosedField()
        ),
        _react2.default.createElement(
          "div",
          { className: "modal-footer" },
          _react2.default.createElement(
            "button",
            {
              className: "btn btn-default",
              "data-dismiss": "modal",
              disabled: this.state.isLoading,
              type: "button"
            },
            gettext("Cancel")
          ),
          _react2.default.createElement(
            _button2.default,
            { className: "btn-primary", loading: this.state.isLoading },
            gettext("Merge threads")
          )
        )
      );
    }
  }, {
    key: "renderCantMergeMessage",
    value: function renderCantMergeMessage() {
      return _react2.default.createElement(
        "div",
        { className: "modal-body" },
        _react2.default.createElement(
          "div",
          { className: "message-icon" },
          _react2.default.createElement(
            "span",
            { className: "material-icon" },
            "info_outline"
          )
        ),
        _react2.default.createElement(
          "div",
          { className: "message-body" },
          _react2.default.createElement(
            "p",
            { className: "lead" },
            gettext("You can't move threads because there are no categories you are allowed to move them to.")
          ),
          _react2.default.createElement(
            "p",
            null,
            gettext("You need permission to start threads in category to be able to merge threads to it.")
          ),
          _react2.default.createElement(
            "button",
            {
              className: "btn btn-default",
              "data-dismiss": "modal",
              type: "button"
            },
            gettext("Ok")
          )
        )
      );
    }
  }, {
    key: "getClassName",
    value: function getClassName() {
      if (!this.state.category) {
        return "modal-dialog modal-message";
      } else {
        return "modal-dialog";
      }
    }
  }, {
    key: "render",
    value: function render() {
      return _react2.default.createElement(
        "div",
        { className: this.getClassName(), role: "document" },
        _react2.default.createElement(
          "div",
          { className: "modal-content" },
          _react2.default.createElement(
            "div",
            { className: "modal-header" },
            _react2.default.createElement(
              "button",
              {
                type: "button",
                className: "close",
                "data-dismiss": "modal",
                "aria-label": gettext("Close")
              },
              _react2.default.createElement(
                "span",
                { "aria-hidden": "true" },
                "\xD7"
              )
            ),
            _react2.default.createElement(
              "h4",
              { className: "modal-title" },
              gettext("Merge threads")
            )
          ),
          this.state.category ? this.renderForm() : this.renderCantMergeMessage()
        )
      );
    }
  }]);

  return _class;
}(_form2.default);

exports.default = _class;

},{"../../../index":302,"../../../reducers/selection":358,"../../../reducers/threads":361,"../../../services/ajax":365,"../../../services/modal":371,"../../../services/snackbar":376,"../../../services/store":377,"../../../utils/validators":393,"../../button":8,"../../category-select":21,"../../form":56,"../../form-group":55,"../../merge-conflict":59,"../../select":210,"./errors-list":262,"react":"react"}],264:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _form = require("../../form");

var _form2 = _interopRequireDefault(_form);

var _formGroup = require("../../form-group");

var _formGroup2 = _interopRequireDefault(_formGroup);

var _categorySelect = require("../../category-select");

var _categorySelect2 = _interopRequireDefault(_categorySelect);

var _selection = require("../../../reducers/selection");

var select = _interopRequireWildcard(_selection);

var _threads = require("../../../reducers/threads");

var _modal = require("../../../services/modal");

var _modal2 = _interopRequireDefault(_modal);

var _store = require("../../../services/store");

var _store2 = _interopRequireDefault(_store);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_Form) {
  _inherits(_class, _Form);

  function _class(props) {
    _classCallCheck(this, _class);

    var _this = _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).call(this, props));

    _this.handleSubmit = function (event) {
      // we don't reload page on submissions
      event.preventDefault();

      _modal2.default.hide();

      var onSuccess = function onSuccess() {
        _store2.default.dispatch((0, _threads.filterThreads)(_this.props.route.category, _this.props.categoriesMap));

        // deselect threads moved outside of visible scope
        var storeState = _store2.default.getState();
        var leftThreads = storeState.threads.map(function (thread) {
          return thread.id;
        });
        _store2.default.dispatch(select.all(storeState.selection.filter(function (thread) {
          return leftThreads.indexOf(thread) !== -1;
        })));
      };

      _this.props.callApi([{ op: "replace", path: "category", value: _this.state.category }, { op: "replace", path: "flatten-categories", value: null }, { op: "add", path: "acl", value: true }], gettext("Selected threads were moved."), onSuccess);
    };

    _this.state = {
      category: null
    };

    var acls = {};
    for (var i in props.user.acl.categories) {
      if (!props.user.acl.categories.hasOwnProperty(i)) {
        continue;
      }

      var acl = props.user.acl.categories[i];
      acls[acl.id] = acl;
    }

    _this.categoryChoices = [];
    props.categories.forEach(function (category) {
      if (category.level > 0) {
        var _acl = acls[category.id];
        var disabled = !_acl.can_start_threads || category.is_closed && !_acl.can_close_threads;

        _this.categoryChoices.push({
          value: category.id,
          disabled: disabled,
          level: category.level - 1,
          label: category.name
        });

        if (!disabled && !_this.state.category) {
          _this.state.category = category.id;
        }
      }
    });
    return _this;
  }

  _createClass(_class, [{
    key: "getClassName",
    value: function getClassName() {
      if (!this.state.category) {
        return "modal-dialog modal-message";
      } else {
        return "modal-dialog";
      }
    }
  }, {
    key: "renderForm",
    value: function renderForm() {
      return _react2.default.createElement(
        "form",
        { onSubmit: this.handleSubmit },
        _react2.default.createElement(
          "div",
          { className: "modal-body" },
          _react2.default.createElement(
            _formGroup2.default,
            { label: gettext("New category"), "for": "id_new_category" },
            _react2.default.createElement(_categorySelect2.default, {
              id: "id_new_category",
              onChange: this.bindInput("category"),
              value: this.state.category,
              choices: this.categoryChoices
            })
          )
        ),
        _react2.default.createElement(
          "div",
          { className: "modal-footer" },
          _react2.default.createElement(
            "button",
            {
              className: "btn btn-default",
              "data-dismiss": "modal",
              disabled: this.state.isLoading,
              type: "button"
            },
            gettext("Cancel")
          ),
          _react2.default.createElement(
            "button",
            { className: "btn btn-primary" },
            gettext("Move threads")
          )
        )
      );
    }
  }, {
    key: "renderCantMoveMessage",
    value: function renderCantMoveMessage() {
      return _react2.default.createElement(
        "div",
        { className: "modal-body" },
        _react2.default.createElement(
          "div",
          { className: "message-icon" },
          _react2.default.createElement(
            "span",
            { className: "material-icon" },
            "info_outline"
          )
        ),
        _react2.default.createElement(
          "div",
          { className: "message-body" },
          _react2.default.createElement(
            "p",
            { className: "lead" },
            gettext("You can't move threads because there are no categories you are allowed to move them to.")
          ),
          _react2.default.createElement(
            "p",
            null,
            gettext("You need permission to start threads in category to be able to move threads to it.")
          ),
          _react2.default.createElement(
            "button",
            {
              className: "btn btn-default",
              "data-dismiss": "modal",
              type: "button"
            },
            gettext("Ok")
          )
        )
      );
    }
  }, {
    key: "render",
    value: function render() {
      return _react2.default.createElement(
        "div",
        { className: this.getClassName(), role: "document" },
        _react2.default.createElement(
          "div",
          { className: "modal-content" },
          _react2.default.createElement(
            "div",
            { className: "modal-header" },
            _react2.default.createElement(
              "button",
              {
                type: "button",
                className: "close",
                "data-dismiss": "modal",
                "aria-label": gettext("Close")
              },
              _react2.default.createElement(
                "span",
                { "aria-hidden": "true" },
                "\xD7"
              )
            ),
            _react2.default.createElement(
              "h4",
              { className: "modal-title" },
              gettext("Move threads")
            )
          ),
          this.state.category ? this.renderForm() : this.renderCantMoveMessage()
        )
      );
    }
  }]);

  return _class;
}(_form2.default);

exports.default = _class;

},{"../../../reducers/selection":358,"../../../reducers/threads":361,"../../../services/modal":371,"../../../services/store":377,"../../category-select":21,"../../form":56,"../../form-group":55,"react":"react"}],265:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _selection = require("../../../reducers/selection");

var select = _interopRequireWildcard(_selection);

var _store = require("../../../services/store");

var _store2 = _interopRequireDefault(_store);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_React$Component) {
  _inherits(_class, _React$Component);

  function _class() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, _class);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = _class.__proto__ || Object.getPrototypeOf(_class)).call.apply(_ref, [this].concat(args))), _this), _this.selectAll = function () {
      _store2.default.dispatch(select.all(_this.props.threads.map(function (thread) {
        return thread.id;
      })));
    }, _this.selectNone = function () {
      _store2.default.dispatch(select.none());
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(_class, [{
    key: "render",
    value: function render() {
      return _react2.default.createElement(
        "ul",
        { className: this.props.className },
        _react2.default.createElement(
          "li",
          null,
          _react2.default.createElement(
            "button",
            {
              className: "btn btn-link",
              type: "button",
              onClick: this.selectAll
            },
            _react2.default.createElement(
              "span",
              { className: "material-icon" },
              "check_box"
            ),
            gettext("Select all")
          )
        ),
        _react2.default.createElement(
          "li",
          null,
          _react2.default.createElement(
            "button",
            {
              className: "btn btn-link",
              type: "button",
              onClick: this.selectNone
            },
            _react2.default.createElement(
              "span",
              { className: "material-icon" },
              "check_box_outline_blank"
            ),
            gettext("Select none")
          )
        )
      );
    }
  }]);

  return _class;
}(_react2.default.Component);

exports.default = _class;

},{"../../../reducers/selection":358,"../../../services/store":377,"react":"react"}],266:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (_ref) {
  var baseUrl = _ref.baseUrl,
      list = _ref.list,
      lists = _ref.lists;

  if (lists.length < 2) return null;

  return _react2.default.createElement(
    "div",
    { className: "page-tabs" },
    _react2.default.createElement(
      "div",
      { className: "container" },
      _react2.default.createElement(
        "ul",
        { className: "nav nav-pills" },
        lists.map(function (item) {
          return _react2.default.createElement(
            _li2.default,
            {
              isControlled: true,
              isActive: item.path === list.path,
              key: baseUrl + item.path
            },
            _react2.default.createElement(
              _reactRouter.Link,
              { to: baseUrl + item.path },
              item.name
            )
          );
        })
      )
    )
  );
};

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _reactRouter = require("react-router");

var _li = require("../li");

var _li2 = _interopRequireDefault(_li);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

},{"../li":57,"react":"react","react-router":"react-router"}],267:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getSelect = getSelect;
exports.getLists = getLists;
exports.paths = paths;

var _reactRedux = require("react-redux");

var _route = require("./route");

var _route2 = _interopRequireDefault(_route);

var _index = require("../../index");

var _index2 = _interopRequireDefault(_index);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function getSelect(options) {
  return function (store) {
    return {
      options: options,
      selection: store.selection,
      threads: store.threads,
      tick: store.tick.tick,
      user: store.auth.user
    };
  };
}

function getLists(user) {
  var lists = [{
    type: "all",
    path: "",
    name: gettext("All"),
    longName: gettext("All threads")
  }];

  if (user.id) {
    lists.push({
      type: "my",
      path: "my/",
      name: gettext("My"),
      longName: gettext("My threads")
    });
    lists.push({
      type: "new",
      path: "new/",
      name: gettext("New"),
      longName: gettext("New threads")
    });
    lists.push({
      type: "unread",
      path: "unread/",
      name: gettext("Unread"),
      longName: gettext("Unread threads")
    });
    lists.push({
      type: "subscribed",
      path: "subscribed/",
      name: gettext("Subscribed"),
      longName: gettext("Subscribed threads")
    });

    if (user.acl.can_see_unapproved_content_lists) {
      lists.push({
        type: "unapproved",
        path: "unapproved/",
        name: gettext("Unapproved"),
        longName: gettext("Unapproved content")
      });
    }
  }

  return lists;
}

function paths(user, mode) {
  var lists = getLists(user);
  var routes = [];
  var categoriesMap = {};

  _index2.default.get("CATEGORIES").forEach(function (category) {
    lists.forEach(function (list) {
      categoriesMap[category.id] = category;

      routes.push({
        path: category.url.index + list.path,
        component: (0, _reactRedux.connect)(getSelect(mode))(_route2.default),

        categories: _index2.default.get("CATEGORIES"),
        categoriesMap: categoriesMap,
        category: category,

        lists: lists,
        list: list
      });
    });
  });

  return routes;
}

},{"../../index":302,"./route":268,"react-redux":"react-redux"}],268:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _button = require("../button");

var _button2 = _interopRequireDefault(_button);

var _compare = require("./compare");

var _container = require("./container");

var _container2 = _interopRequireDefault(_container);

var _header = require("./header");

var _header2 = _interopRequireDefault(_header);

var _utils = require("./utils");

var _threadsList = require("../threads-list");

var _threadsList2 = _interopRequireDefault(_threadsList);

var _listEmpty = require("./list-empty");

var _listEmpty2 = _interopRequireDefault(_listEmpty);

var _withDropdown = require("../with-dropdown");

var _withDropdown2 = _interopRequireDefault(_withDropdown);

var _index = require("../../index");

var _index2 = _interopRequireDefault(_index);

var _selection = require("../../reducers/selection");

var select = _interopRequireWildcard(_selection);

var _threads = require("../../reducers/threads");

var _ajax = require("../../services/ajax");

var _ajax2 = _interopRequireDefault(_ajax);

var _polls = require("../../services/polls");

var _polls2 = _interopRequireDefault(_polls);

var _snackbar = require("../../services/snackbar");

var _snackbar2 = _interopRequireDefault(_snackbar);

var _store = require("../../services/store");

var _store2 = _interopRequireDefault(_store);

var _pageTitle = require("../../services/page-title");

var _pageTitle2 = _interopRequireDefault(_pageTitle);

var _sets = require("../../utils/sets");

var sets = _interopRequireWildcard(_sets);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_WithDropdown) {
  _inherits(_class, _WithDropdown);

  function _class(props) {
    _classCallCheck(this, _class);

    var _this = _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).call(this, props));

    _this.loadMore = function () {
      _this.setState({
        isBusy: true
      });

      _this.loadThreads(_this.getCategory(), _this.state.next);
    };

    _this.pollResponse = function (data) {
      _this.setState({
        diff: Object.assign({}, data, {
          results: (0, _utils.diffThreads)(_this.props.threads, data.results)
        })
      });
    };

    _this.addThreads = function (threads) {
      _store2.default.dispatch((0, _threads.append)(threads, _this.getSorting()));
    };

    _this.applyDiff = function () {
      _this.addThreads(_this.state.diff.results);

      _this.setState(Object.assign({}, _this.state.diff, {
        moderation: (0, _utils.getModerationActions)(_store2.default.getState().threads),

        diff: {
          results: []
        }
      }));
    };

    _this.freezeThread = function (thread) {
      _this.setState(function (currentState) {
        return {
          busyThreads: sets.toggle(currentState.busyThreads, thread)
        };
      });
    };

    _this.updateThread = function (thread) {
      _store2.default.dispatch((0, _threads.patch)(thread, thread, _this.getSorting()));
    };

    _this.deleteThread = function (thread) {
      _store2.default.dispatch((0, _threads.deleteThread)(thread));
    };

    _this.state = {
      isMounted: true,

      isLoaded: false,
      isBusy: false,

      diff: {
        results: []
      },

      moderation: [],
      busyThreads: [],

      dropdown: false,
      subcategories: [],

      next: 0
    };

    var category = _this.getCategory();

    if (_index2.default.has("THREADS")) {
      _this.initWithPreloadedData(category, _index2.default.get("THREADS"));
    } else {
      _this.initWithoutPreloadedData(category);
    }
    return _this;
  }

  _createClass(_class, [{
    key: "getCategory",
    value: function getCategory() {
      if (!this.props.route.category.special_role) {
        return this.props.route.category.id;
      } else {
        return null;
      }
    }
  }, {
    key: "initWithPreloadedData",
    value: function initWithPreloadedData(category, data) {
      this.state = Object.assign(this.state, {
        moderation: (0, _utils.getModerationActions)(data.results),
        subcategories: data.subcategories,
        next: data.next
      });

      this.startPolling(category);
    }
  }, {
    key: "initWithoutPreloadedData",
    value: function initWithoutPreloadedData(category) {
      this.loadThreads(category);
    }
  }, {
    key: "loadThreads",
    value: function loadThreads(category) {
      var _this2 = this;

      var next = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;

      _ajax2.default.get(this.props.options.api, {
        category: category,
        list: this.props.route.list.type,
        start: next || 0
      }, "threads").then(function (data) {
        if (!_this2.state.isMounted) {
          // user changed route before loading completion
          return;
        }

        if (next === 0) {
          _store2.default.dispatch((0, _threads.hydrate)(data.results));
        } else {
          _store2.default.dispatch((0, _threads.append)(data.results, _this2.getSorting()));
        }

        _this2.setState({
          isLoaded: true,
          isBusy: false,

          moderation: (0, _utils.getModerationActions)(_store2.default.getState().threads),

          subcategories: data.subcategories,

          next: data.next
        });

        _this2.startPolling(category);
      }, function (rejection) {
        _snackbar2.default.apiError(rejection);
      });
    }
  }, {
    key: "startPolling",
    value: function startPolling(category) {
      _polls2.default.start({
        poll: "threads",
        url: this.props.options.api,
        data: {
          category: category,
          list: this.props.route.list.type
        },
        frequency: 120 * 1000,
        update: this.pollResponse
      });
    }
  }, {
    key: "componentDidMount",
    value: function componentDidMount() {
      this.setPageTitle();

      if (_index2.default.has("THREADS")) {
        // unlike in other components, routes are root components for threads
        // so we can't dispatch store action from constructor
        _store2.default.dispatch((0, _threads.hydrate)(_index2.default.pop("THREADS").results));

        this.setState({
          isLoaded: true
        });
      }

      _store2.default.dispatch(select.none());
    }
  }, {
    key: "componentWillUnmount",
    value: function componentWillUnmount() {
      this.state.isMounted = false;
      _polls2.default.stop("threads");
    }
  }, {
    key: "getTitle",
    value: function getTitle() {
      if (this.props.options.title) {
        return this.props.options.title;
      }

      return (0, _utils.getTitle)(this.props.route);
    }
  }, {
    key: "setPageTitle",
    value: function setPageTitle() {
      if (this.props.route.category.level || !_index2.default.get("THREADS_ON_INDEX")) {
        _pageTitle2.default.set((0, _utils.getPageTitle)(this.props.route));
      } else if (this.props.options.title) {
        _pageTitle2.default.set(this.props.options.title);
      } else {
        if (_index2.default.get("SETTINGS").index_title) {
          document.title = _index2.default.get("SETTINGS").index_title;
        } else {
          document.title = _index2.default.get("SETTINGS").forum_name;
        }
      }
    }
  }, {
    key: "getSorting",
    value: function getSorting() {
      if (this.props.route.category.level) {
        return _compare.compareWeight;
      } else {
        return _compare.compareGlobalWeight;
      }
    }

    // AJAX

    // Thread state utils

  }, {
    key: "getMoreButton",
    value: function getMoreButton() {
      if (!this.state.next) return null;

      return _react2.default.createElement(
        "div",
        { className: "pager-more" },
        _react2.default.createElement(
          _button2.default,
          {
            className: "btn btn-default btn-outline",
            loading: this.state.isBusy || this.state.busyThreads.length,
            onClick: this.loadMore
          },
          gettext("Show more")
        )
      );
    }
  }, {
    key: "getClassName",
    value: function getClassName() {
      var className = "page page-threads";
      className += " page-threads-" + this.props.route.list.type;
      if (isIndex(this.props)) {
        className += " page-threads-index";
      }
      if (this.props.route.category.css_class) {
        className += " page-threads-" + this.props.route.category.css_class;
      }
      return className;
    }
  }, {
    key: "render",
    value: function render() {
      return _react2.default.createElement(
        "div",
        { className: this.getClassName() },
        _react2.default.createElement(_header2.default, {
          categories: this.props.route.categoriesMap,
          disabled: !this.state.isLoaded,
          startThread: this.props.options.startThread,
          threads: this.props.threads,
          title: this.getTitle(),
          toggleNav: this.toggleNav,
          route: this.props.route,
          user: this.props.user
        }),
        _react2.default.createElement(
          _container2.default,
          {
            api: this.props.options.api,
            route: this.props.route,
            subcategories: this.state.subcategories,
            user: this.props.user,
            pageLead: this.props.options.pageLead,
            threads: this.props.threads,
            threadsCount: this.state.count,
            moderation: this.state.moderation,
            selection: this.props.selection,
            busyThreads: this.state.busyThreads,
            addThreads: this.addThreads,
            freezeThread: this.freezeThread,
            deleteThread: this.deleteThread,
            updateThread: this.updateThread,
            isLoaded: this.state.isLoaded,
            isBusy: this.state.isBusy
          },
          _react2.default.createElement(
            _threadsList2.default,
            {
              category: this.props.route.category,
              categories: this.props.route.categoriesMap,
              list: this.props.route.list,
              selection: this.props.selection,
              threads: this.props.threads,
              diffSize: this.state.diff.results.length,
              applyDiff: this.applyDiff,
              showOptions: !!this.props.user.id,
              isLoaded: this.state.isLoaded,
              busyThreads: this.state.busyThreads
            },
            _react2.default.createElement(_listEmpty2.default, {
              category: this.props.route.category,
              emptyMessage: this.props.options.emptyMessage,
              list: this.props.route.list
            })
          ),
          this.getMoreButton()
        )
      );
    }
  }]);

  return _class;
}(_withDropdown2.default);

exports.default = _class;


function isIndex(props) {
  if (props.route.category.level || !_index2.default.get("THREADS_ON_INDEX")) return false;
  if (props.options.title) return false;

  return true;
}

},{"../../index":302,"../../reducers/selection":358,"../../reducers/threads":361,"../../services/ajax":365,"../../services/page-title":373,"../../services/polls":374,"../../services/snackbar":376,"../../services/store":377,"../../utils/sets":391,"../button":8,"../threads-list":238,"../with-dropdown":299,"./compare":257,"./container":258,"./header":259,"./list-empty":260,"./utils":270,"react":"react"}],269:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _categoryPicker = require("./category-picker");

var _categoryPicker2 = _interopRequireDefault(_categoryPicker);

var _controls = require("./moderation/controls");

var _controls2 = _interopRequireDefault(_controls);

var _selection = require("./moderation/selection");

var _selection2 = _interopRequireDefault(_selection);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_React$Component) {
  _inherits(_class, _React$Component);

  function _class() {
    _classCallCheck(this, _class);

    return _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).apply(this, arguments));
  }

  _createClass(_class, [{
    key: "getCategoryPicker",
    value: function getCategoryPicker() {
      if (!this.props.subcategories.length) return null;

      return _react2.default.createElement(_categoryPicker2.default, {
        categories: this.props.categoriesMap,
        choices: this.props.subcategories,
        list: this.props.list
      });
    }
  }, {
    key: "showModerationOptions",
    value: function showModerationOptions() {
      return this.props.user.id && this.props.moderation.allow;
    }
  }, {
    key: "getSelectedThreads",
    value: function getSelectedThreads() {
      var _this2 = this;

      return this.props.threads.filter(function (thread) {
        return _this2.props.selection.indexOf(thread.id) >= 0;
      });
    }
  }, {
    key: "getModerationButton",
    value: function getModerationButton() {
      if (!this.showModerationOptions()) return null;

      return _react2.default.createElement(
        "div",
        { className: "col-xs-6 col-sm-3 col-md-2" },
        _react2.default.createElement(
          "div",
          { className: "btn-group btn-group-justified" },
          _react2.default.createElement(
            "div",
            { className: "btn-group dropdown" },
            _react2.default.createElement(
              "button",
              {
                type: "button",
                className: "btn btn-default btn-outline dropdown-toggle",
                "data-toggle": "dropdown",
                "aria-haspopup": "true",
                "aria-expanded": "false",
                disabled: this.props.disabled || !this.props.selection.length
              },
              _react2.default.createElement(
                "span",
                { className: "material-icon" },
                "settings"
              ),
              gettext("Options")
            ),
            _react2.default.createElement(_controls2.default, {
              addThreads: this.props.addThreads,
              api: this.props.api,
              categories: this.props.categories,
              categoriesMap: this.props.categoriesMap,
              className: "dropdown-menu dropdown-menu-right stick-to-bottom",
              deleteThread: this.props.deleteThread,
              freezeThread: this.props.freezeThread,
              moderation: this.props.moderation,
              route: this.props.route,
              threads: this.getSelectedThreads(),
              updateThread: this.props.updateThread,
              user: this.props.user
            })
          )
        )
      );
    }
  }, {
    key: "getSelectionButton",
    value: function getSelectionButton() {
      if (!this.showModerationOptions()) return null;

      return _react2.default.createElement(
        "div",
        { className: "col-xs-3 col-sm-2 col-md-1" },
        _react2.default.createElement(
          "div",
          { className: "btn-group btn-group-justified" },
          _react2.default.createElement(
            "div",
            { className: "btn-group dropdown" },
            _react2.default.createElement(
              "button",
              {
                type: "button",
                className: "btn btn-default btn-outline btn-icon dropdown-toggle",
                "data-toggle": "dropdown",
                "aria-haspopup": "true",
                "aria-expanded": "false",
                disabled: this.props.disabled
              },
              _react2.default.createElement(
                "span",
                { className: "material-icon" },
                "select_all"
              )
            ),
            _react2.default.createElement(_selection2.default, {
              className: "dropdown-menu dropdown-menu-right stick-to-bottom",
              threads: this.props.threads
            })
          )
        )
      );
    }
  }, {
    key: "render",
    value: function render() {
      return _react2.default.createElement(
        "div",
        { className: "row row-toolbar row-toolbar-bottom-margin" },
        _react2.default.createElement(
          "div",
          { className: "col-xs-3 col-sm-3 col-md-2 dropdown" },
          this.getCategoryPicker()
        ),
        _react2.default.createElement("div", { className: "hidden-xs col-sm-4 col-md-7" }),
        this.getModerationButton(),
        this.getSelectionButton()
      );
    }
  }]);

  return _class;
}(_react2.default.Component);

exports.default = _class;

},{"./category-picker":256,"./moderation/controls":261,"./moderation/selection":265,"react":"react"}],270:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getPageTitle = getPageTitle;
exports.getTitle = getTitle;
exports.isThreadChanged = isThreadChanged;
exports.diffThreads = diffThreads;
exports.getModerationActions = getModerationActions;

var _index = require("../../index");

var _index2 = _interopRequireDefault(_index);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function getPageTitle(route) {
  if (route.category.level) {
    if (route.list.path) {
      return {
        title: route.list.longName,
        parent: route.category.name
      };
    } else {
      return {
        title: route.category.name
      };
    }
  } else if (_index2.default.get("THREADS_ON_INDEX")) {
    if (route.list.path) {
      return {
        title: route.list.longName
      };
    } else {
      return null;
    }
  } else {
    if (route.list.path) {
      return {
        title: route.list.longName,
        parent: gettext("Threads")
      };
    } else {
      return {
        title: gettext("Threads")
      };
    }
  }
}

function getTitle(route) {
  if (route.category.level) {
    return route.category.name;
  } else if (_index2.default.get("THREADS_ON_INDEX")) {
    if (_index2.default.get("SETTINGS").index_header) {
      return _index2.default.get("SETTINGS").index_header;
    } else {
      return _index2.default.get("SETTINGS").forum_name;
    }
  } else {
    return gettext("Threads");
  }
}

function isThreadChanged(current, fromDb) {
  return [current.title === fromDb.title, current.weight === fromDb.weight, current.category === fromDb.category, current.last_post === fromDb.last_post, current.last_poster_name === fromDb.last_poster_name].indexOf(false) >= 0;
}

function diffThreads(current, fromDb) {
  var currentMap = {};
  current.forEach(function (thread) {
    currentMap[thread.id] = thread;
  });

  return fromDb.filter(function (thread) {
    if (currentMap[thread.id]) {
      return isThreadChanged(currentMap[thread.id], thread);
    } else {
      return true;
    }
  });
}

function getModerationActions(threads) {
  var moderation = {
    allow: false,

    can_approve: 0,
    can_close: 0,
    can_delete: 0,
    can_hide: 0,
    can_merge: 0,
    can_move: 0,
    can_pin: 0,
    can_pin_globally: 0,
    can_unhide: 0
  };

  threads.forEach(function (thread) {
    if (thread.is_unapproved && thread.acl.can_approve > moderation.can_approve) {
      moderation.can_approve = thread.acl.can_approve;
    }

    if (thread.acl.can_close > moderation.can_close) {
      moderation.can_close = thread.acl.can_close;
    }

    if (thread.acl.can_delete > moderation.can_delete) {
      moderation.can_delete = thread.acl.can_delete;
    }

    if (thread.acl.can_hide > moderation.can_hide) {
      moderation.can_hide = thread.acl.can_hide;
    }

    if (thread.acl.can_merge > moderation.can_merge) {
      moderation.can_merge = thread.acl.can_merge;
    }

    if (thread.acl.can_move > moderation.can_move) {
      moderation.can_move = thread.acl.can_move;
    }

    if (thread.acl.can_pin > moderation.can_pin) {
      moderation.can_pin = thread.acl.can_pin;
    }

    if (thread.acl.can_pin_globally > moderation.can_pin_globally) {
      moderation.can_pin_globally = thread.acl.can_pin_globally;
    }

    if (thread.is_hidden && thread.acl.can_unhide > moderation.can_unhide) {
      moderation.can_unhide = thread.acl.can_unhide;
    }

    moderation.allow = moderation.can_approve || moderation.can_close || moderation.can_delete || moderation.can_hide || moderation.can_merge || moderation.can_move || moderation.can_pin || moderation.can_pin_globally || moderation.can_unhide;
  });

  return moderation;
}

},{"../../index":302}],271:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CompactGuestNav = exports.GuestNav = exports.GuestMenu = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _avatar = require("../avatar");

var _avatar2 = _interopRequireDefault(_avatar);

var _navbarSearch = require("../navbar-search");

var _navbarSearch2 = _interopRequireDefault(_navbarSearch);

var _registerButton = require("../register-button");

var _registerButton2 = _interopRequireDefault(_registerButton);

var _signIn = require("../sign-in.js");

var _signIn2 = _interopRequireDefault(_signIn);

var _ = require("../..");

var _2 = _interopRequireDefault(_);

var _mobileNavbarDropdown = require("../../services/mobile-navbar-dropdown");

var _mobileNavbarDropdown2 = _interopRequireDefault(_mobileNavbarDropdown);

var _modal = require("../../services/modal");

var _modal2 = _interopRequireDefault(_modal);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var GuestMenu = exports.GuestMenu = function (_React$Component) {
  _inherits(GuestMenu, _React$Component);

  function GuestMenu() {
    _classCallCheck(this, GuestMenu);

    return _possibleConstructorReturn(this, (GuestMenu.__proto__ || Object.getPrototypeOf(GuestMenu)).apply(this, arguments));
  }

  _createClass(GuestMenu, [{
    key: "showSignInModal",
    value: function showSignInModal() {
      _modal2.default.show(_signIn2.default);
    }
  }, {
    key: "render",
    value: function render() {
      return _react2.default.createElement(
        "ul",
        {
          className: "dropdown-menu user-dropdown dropdown-menu-right",
          role: "menu"
        },
        _react2.default.createElement(
          "li",
          { className: "guest-preview" },
          _react2.default.createElement(
            "h4",
            null,
            gettext("You are browsing as guest.")
          ),
          _react2.default.createElement(
            "p",
            null,
            gettext("Sign in or register to start and participate in discussions.")
          ),
          _react2.default.createElement(
            "div",
            { className: "row" },
            _2.default.get("SETTINGS").enable_sso ? _react2.default.createElement(
              "div",
              { className: "col-xs-12" },
              _react2.default.createElement(
                "a",
                {
                  className: "btn btn-primary btn-register btn-block",
                  href: _2.default.get("SETTINGS").SSO_LOGIN_URL
                },
                gettext("Sign in")
              )
            ) : _react2.default.createElement(
              "div",
              { className: "col-xs-6" },
              _react2.default.createElement(
                "button",
                {
                  className: "btn btn-default btn-sign-in btn-block",
                  onClick: this.showSignInModal,
                  type: "button"
                },
                gettext("Sign in")
              )
            ),
            !_2.default.get("SETTINGS").enable_sso && _react2.default.createElement(
              "div",
              { className: "col-xs-6" },
              _react2.default.createElement(
                _registerButton2.default,
                { className: "btn-primary btn-register btn-block" },
                gettext("Register")
              )
            )
          )
        )
      );
    }
  }]);

  return GuestMenu;
}(_react2.default.Component);

var GuestNav = exports.GuestNav = function (_GuestMenu) {
  _inherits(GuestNav, _GuestMenu);

  function GuestNav() {
    _classCallCheck(this, GuestNav);

    return _possibleConstructorReturn(this, (GuestNav.__proto__ || Object.getPrototypeOf(GuestNav)).apply(this, arguments));
  }

  _createClass(GuestNav, [{
    key: "render",
    value: function render() {
      return _react2.default.createElement(
        "div",
        { className: "nav nav-guest" },
        _2.default.get("SETTINGS").enable_sso ? _react2.default.createElement(
          "a",
          {
            className: "btn navbar-btn btn-primary btn-register",
            href: _2.default.get("SETTINGS").SSO_LOGIN_URL
          },
          gettext("Sign in")
        ) : _react2.default.createElement(
          "button",
          {
            className: "btn navbar-btn btn-default btn-sign-in",
            onClick: this.showSignInModal,
            type: "button"
          },
          gettext("Sign in")
        ),
        !_2.default.get("SETTINGS").enable_sso && _react2.default.createElement(
          _registerButton2.default,
          { className: "navbar-btn btn-primary btn-register" },
          gettext("Register")
        ),
        _react2.default.createElement(
          "div",
          { className: "navbar-left" },
          _react2.default.createElement(_navbarSearch2.default, null)
        )
      );
    }
  }]);

  return GuestNav;
}(GuestMenu);

var CompactGuestNav = exports.CompactGuestNav = function (_React$Component2) {
  _inherits(CompactGuestNav, _React$Component2);

  function CompactGuestNav() {
    _classCallCheck(this, CompactGuestNav);

    return _possibleConstructorReturn(this, (CompactGuestNav.__proto__ || Object.getPrototypeOf(CompactGuestNav)).apply(this, arguments));
  }

  _createClass(CompactGuestNav, [{
    key: "showGuestMenu",
    value: function showGuestMenu() {
      _mobileNavbarDropdown2.default.show(GuestMenu);
    }
  }, {
    key: "render",
    value: function render() {
      return _react2.default.createElement(
        "button",
        { type: "button", onClick: this.showGuestMenu },
        _react2.default.createElement(_avatar2.default, { size: "64" })
      );
    }
  }]);

  return CompactGuestNav;
}(_react2.default.Component);

},{"../..":302,"../../services/mobile-navbar-dropdown":370,"../../services/modal":371,"../avatar":6,"../navbar-search":77,"../register-button":198,"../sign-in.js":211,"react":"react"}],272:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CompactUserMenu = exports.UserMenu = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

exports.select = select;

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _guestNav = require("./guest-nav");

var _userNav = require("./user-nav");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var UserMenu = exports.UserMenu = function (_React$Component) {
  _inherits(UserMenu, _React$Component);

  function UserMenu() {
    _classCallCheck(this, UserMenu);

    return _possibleConstructorReturn(this, (UserMenu.__proto__ || Object.getPrototypeOf(UserMenu)).apply(this, arguments));
  }

  _createClass(UserMenu, [{
    key: "render",
    value: function render() {
      if (this.props.isAuthenticated) {
        return _react2.default.createElement(_userNav.UserNav, { user: this.props.user });
      } else {
        return _react2.default.createElement(_guestNav.GuestNav, null);
      }
    }
  }]);

  return UserMenu;
}(_react2.default.Component);

var CompactUserMenu = exports.CompactUserMenu = function (_React$Component2) {
  _inherits(CompactUserMenu, _React$Component2);

  function CompactUserMenu() {
    _classCallCheck(this, CompactUserMenu);

    return _possibleConstructorReturn(this, (CompactUserMenu.__proto__ || Object.getPrototypeOf(CompactUserMenu)).apply(this, arguments));
  }

  _createClass(CompactUserMenu, [{
    key: "render",
    value: function render() {
      if (this.props.isAuthenticated) {
        return _react2.default.createElement(_userNav.CompactUserNav, { user: this.props.user });
      } else {
        return _react2.default.createElement(_guestNav.CompactGuestNav, null);
      }
    }
  }]);

  return CompactUserMenu;
}(_react2.default.Component);

function select(state) {
  return state.auth;
}

},{"./guest-nav":271,"./user-nav":273,"react":"react"}],273:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CompactUserNav = exports.UserMenu = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

exports.PrivateThreadsBadge = PrivateThreadsBadge;
exports.UserNav = UserNav;
exports.UserPrivateThreadsLink = UserPrivateThreadsLink;
exports.selectUserMenu = selectUserMenu;

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _reactRedux = require("react-redux");

var _avatar = require("../avatar");

var _avatar2 = _interopRequireDefault(_avatar);

var _root = require("../change-avatar/root");

var _root2 = _interopRequireDefault(_root);

var _navbarSearch = require("../navbar-search");

var _navbarSearch2 = _interopRequireDefault(_navbarSearch);

var _ = require("../..");

var _2 = _interopRequireDefault(_);

var _mobileNavbarDropdown = require("../../services/mobile-navbar-dropdown");

var _mobileNavbarDropdown2 = _interopRequireDefault(_mobileNavbarDropdown);

var _modal = require("../../services/modal");

var _modal2 = _interopRequireDefault(_modal);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var UserMenu = exports.UserMenu = function (_React$Component) {
  _inherits(UserMenu, _React$Component);

  function UserMenu() {
    _classCallCheck(this, UserMenu);

    return _possibleConstructorReturn(this, (UserMenu.__proto__ || Object.getPrototypeOf(UserMenu)).apply(this, arguments));
  }

  _createClass(UserMenu, [{
    key: "logout",
    value: function logout() {
      var decision = confirm(gettext("Are you sure you want to sign out?"));
      if (decision) {
        $("#hidden-logout-form").submit();
      }
    }
  }, {
    key: "changeAvatar",
    value: function changeAvatar() {
      _modal2.default.show((0, _reactRedux.connect)(_root.select)(_root2.default));
    }
  }, {
    key: "render",
    value: function render() {
      var user = this.props.user;


      return _react2.default.createElement(
        "ul",
        {
          className: "dropdown-menu user-dropdown dropdown-menu-right",
          role: "menu"
        },
        _react2.default.createElement(
          "li",
          { className: "dropdown-header" },
          _react2.default.createElement(
            "strong",
            null,
            user.username
          ),
          _react2.default.createElement(
            "div",
            { className: "row user-stats" },
            _react2.default.createElement(
              "div",
              { className: "col-sm-3" },
              _react2.default.createElement(
                "span",
                { className: "material-icon" },
                "message"
              ),
              user.posts
            ),
            _react2.default.createElement(
              "div",
              { className: "col-sm-3" },
              _react2.default.createElement(
                "span",
                { className: "material-icon" },
                "forum"
              ),
              user.threads
            ),
            _react2.default.createElement(
              "div",
              { className: "col-sm-3" },
              _react2.default.createElement(
                "span",
                { className: "material-icon" },
                "favorite"
              ),
              user.followers
            ),
            _react2.default.createElement(
              "div",
              { className: "col-sm-3" },
              _react2.default.createElement(
                "span",
                { className: "material-icon" },
                "favorite_outline"
              ),
              user.following
            )
          )
        ),
        _react2.default.createElement("li", { className: "divider" }),
        _react2.default.createElement(
          "li",
          null,
          _react2.default.createElement(
            "a",
            { href: user.url },
            _react2.default.createElement(
              "span",
              { className: "material-icon" },
              "account_circle"
            ),
            gettext("See your profile")
          )
        ),
        _react2.default.createElement(
          "li",
          null,
          _react2.default.createElement(
            "a",
            { href: _2.default.get("USERCP_URL") },
            _react2.default.createElement(
              "span",
              { className: "material-icon" },
              "done_all"
            ),
            gettext("Change options")
          )
        ),
        _react2.default.createElement(
          "li",
          null,
          _react2.default.createElement(
            "button",
            {
              className: "btn-link",
              onClick: this.changeAvatar,
              type: "button"
            },
            _react2.default.createElement(
              "span",
              { className: "material-icon" },
              "portrait"
            ),
            gettext("Change avatar")
          )
        ),
        !!user.acl.can_use_private_threads && _react2.default.createElement(
          "li",
          null,
          _react2.default.createElement(
            "a",
            { href: _2.default.get("PRIVATE_THREADS_URL") },
            _react2.default.createElement(
              "span",
              { className: "material-icon" },
              "message"
            ),
            gettext("Private threads"),
            _react2.default.createElement(PrivateThreadsBadge, { user: user })
          )
        ),
        _react2.default.createElement("li", { className: "divider" }),
        _react2.default.createElement(
          "li",
          { className: "dropdown-buttons" },
          _react2.default.createElement(
            "button",
            {
              className: "btn btn-default btn-block",
              onClick: this.logout,
              type: "button"
            },
            gettext("Log out")
          )
        )
      );
    }
  }]);

  return UserMenu;
}(_react2.default.Component);

function PrivateThreadsBadge(_ref) {
  var user = _ref.user;

  if (!user.unread_private_threads) return null;

  return _react2.default.createElement(
    "span",
    { className: "badge" },
    user.unread_private_threads
  );
}

function UserNav(_ref2) {
  var user = _ref2.user;

  return _react2.default.createElement(
    "ul",
    { className: "ul nav navbar-nav nav-user" },
    _react2.default.createElement(
      "li",
      null,
      _react2.default.createElement(_navbarSearch2.default, null)
    ),
    _react2.default.createElement(UserPrivateThreadsLink, { user: user }),
    _react2.default.createElement(
      "li",
      { className: "dropdown" },
      _react2.default.createElement(
        "a",
        {
          "aria-haspopup": "true",
          "aria-expanded": "false",
          className: "dropdown-toggle",
          "data-toggle": "dropdown",
          href: user.url,
          role: "button"
        },
        _react2.default.createElement(_avatar2.default, { user: user, size: "64" })
      ),
      _react2.default.createElement(UserMenu, { user: user })
    )
  );
}

function UserPrivateThreadsLink(_ref3) {
  var user = _ref3.user;

  if (!user.acl.can_use_private_threads) return null;

  var title = null;
  if (user.unread_private_threads) {
    title = gettext("You have unread private threads!");
  } else {
    title = gettext("Private threads");
  }

  return _react2.default.createElement(
    "li",
    null,
    _react2.default.createElement(
      "a",
      {
        className: "navbar-icon",
        href: _2.default.get("PRIVATE_THREADS_URL"),
        title: title
      },
      _react2.default.createElement(
        "span",
        { className: "material-icon" },
        "message"
      ),
      user.unread_private_threads > 0 && _react2.default.createElement(
        "span",
        { className: "badge" },
        user.unread_private_threads
      )
    )
  );
}

function selectUserMenu(state) {
  return {
    user: state.auth.user
  };
}

var CompactUserNav = exports.CompactUserNav = function (_React$Component2) {
  _inherits(CompactUserNav, _React$Component2);

  function CompactUserNav() {
    _classCallCheck(this, CompactUserNav);

    return _possibleConstructorReturn(this, (CompactUserNav.__proto__ || Object.getPrototypeOf(CompactUserNav)).apply(this, arguments));
  }

  _createClass(CompactUserNav, [{
    key: "showUserMenu",
    value: function showUserMenu() {
      _mobileNavbarDropdown2.default.showConnected("user-menu", (0, _reactRedux.connect)(selectUserMenu)(UserMenu));
    }
  }, {
    key: "render",
    value: function render() {
      return _react2.default.createElement(
        "button",
        { type: "button", onClick: this.showUserMenu },
        _react2.default.createElement(_avatar2.default, { user: this.props.user, size: "50" })
      );
    }
  }]);

  return CompactUserNav;
}(_react2.default.Component);

},{"../..":302,"../../services/mobile-navbar-dropdown":370,"../../services/modal":371,"../avatar":6,"../change-avatar/root":25,"../navbar-search":77,"react":"react","react-redux":"react-redux"}],274:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StatusLabel = exports.StatusIcon = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

exports.getStatusClassName = getStatusClassName;
exports.getStatusDescription = getStatusDescription;

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_React$Component) {
  _inherits(_class, _React$Component);

  function _class() {
    _classCallCheck(this, _class);

    return _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).apply(this, arguments));
  }

  _createClass(_class, [{
    key: "getClass",
    value: function getClass() {
      return getStatusClassName(this.props.status);
    }
  }, {
    key: "render",
    value: function render() {
      return _react2.default.createElement(
        "span",
        { className: this.getClass() },
        this.props.children
      );
    }
  }]);

  return _class;
}(_react2.default.Component);

exports.default = _class;

var StatusIcon = exports.StatusIcon = function (_React$Component2) {
  _inherits(StatusIcon, _React$Component2);

  function StatusIcon() {
    _classCallCheck(this, StatusIcon);

    return _possibleConstructorReturn(this, (StatusIcon.__proto__ || Object.getPrototypeOf(StatusIcon)).apply(this, arguments));
  }

  _createClass(StatusIcon, [{
    key: "getIcon",
    value: function getIcon() {
      if (this.props.status.is_banned) {
        return "remove_circle_outline";
      } else if (this.props.status.is_hidden) {
        return "help_outline";
      } else if (this.props.status.is_online_hidden) {
        return "label";
      } else if (this.props.status.is_offline_hidden) {
        return "label_outline";
      } else if (this.props.status.is_online) {
        return "lens";
      } else if (this.props.status.is_offline) {
        return "panorama_fish_eye";
      }
    }
  }, {
    key: "render",
    value: function render() {
      return _react2.default.createElement(
        "span",
        { className: "material-icon status-icon" },
        this.getIcon()
      );
    }
  }]);

  return StatusIcon;
}(_react2.default.Component);

var StatusLabel = exports.StatusLabel = function (_React$Component3) {
  _inherits(StatusLabel, _React$Component3);

  function StatusLabel() {
    _classCallCheck(this, StatusLabel);

    return _possibleConstructorReturn(this, (StatusLabel.__proto__ || Object.getPrototypeOf(StatusLabel)).apply(this, arguments));
  }

  _createClass(StatusLabel, [{
    key: "getHelp",
    value: function getHelp() {
      return getStatusDescription(this.props.user, this.props.status);
    }
  }, {
    key: "getLabel",
    value: function getLabel() {
      if (this.props.status.is_banned) {
        return gettext("Banned");
      } else if (this.props.status.is_hidden) {
        return gettext("Hidden");
      } else if (this.props.status.is_online_hidden) {
        return gettext("Online (hidden)");
      } else if (this.props.status.is_offline_hidden) {
        return gettext("Offline (hidden)");
      } else if (this.props.status.is_online) {
        return gettext("Online");
      } else if (this.props.status.is_offline) {
        return gettext("Offline");
      }
    }
  }, {
    key: "render",
    value: function render() {
      return _react2.default.createElement(
        "span",
        {
          className: this.props.className || "status-label",
          title: this.getHelp()
        },
        this.getLabel()
      );
    }
  }]);

  return StatusLabel;
}(_react2.default.Component);

function getStatusClassName(status) {
  var className = "";
  if (status.is_banned) {
    className = "banned";
  } else if (status.is_hidden) {
    className = "offline";
  } else if (status.is_online_hidden) {
    className = "online";
  } else if (status.is_offline_hidden) {
    className = "offline";
  } else if (status.is_online) {
    className = "online";
  } else if (status.is_offline) {
    className = "offline";
  }

  return "user-status user-" + className;
}

function getStatusDescription(user, status) {
  if (status.is_banned) {
    if (status.banned_until) {
      return interpolate(gettext("%(username)s is banned until %(ban_expires)s"), {
        username: user.username,
        ban_expires: status.banned_until.format("LL, LT")
      }, true);
    } else {
      return interpolate(gettext("%(username)s is banned"), {
        username: user.username
      }, true);
    }
  } else if (status.is_hidden) {
    return interpolate(gettext("%(username)s is hiding presence"), {
      username: user.username
    }, true);
  } else if (status.is_online_hidden) {
    return interpolate(gettext("%(username)s is online (hidden)"), {
      username: user.username
    }, true);
  } else if (status.is_offline_hidden) {
    return interpolate(gettext("%(username)s was last seen %(last_click)s (hidden)"), {
      username: user.username,
      last_click: status.last_click.fromNow()
    }, true);
  } else if (status.is_online) {
    return interpolate(gettext("%(username)s is online"), {
      username: user.username
    }, true);
  } else if (status.is_offline) {
    return interpolate(gettext("%(username)s was last seen %(last_click)s"), {
      username: user.username,
      last_click: status.last_click.fromNow()
    }, true);
  }
}

},{"react":"react"}],275:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _avatar = require("../avatar");

var _avatar2 = _interopRequireDefault(_avatar);

var _random = require("../../utils/random");

var random = _interopRequireWildcard(_random);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_React$Component) {
  _inherits(_class, _React$Component);

  function _class() {
    _classCallCheck(this, _class);

    return _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).apply(this, arguments));
  }

  _createClass(_class, [{
    key: "shouldComponentUpdate",
    value: function shouldComponentUpdate() {
      return false;
    }
  }, {
    key: "getClassName",
    value: function getClassName() {
      if (this.props.hiddenOnMobile) {
        return "list-group-item hidden-xs hidden-sm";
      } else {
        return "list-group-item";
      }
    }
  }, {
    key: "render",
    value: function render() {
      return _react2.default.createElement(
        "li",
        { className: this.getClassName() },
        _react2.default.createElement(
          "div",
          { className: "change-avatar" },
          _react2.default.createElement(
            "span",
            { className: "user-avatar" },
            _react2.default.createElement(_avatar2.default, { size: "100" })
          )
        ),
        _react2.default.createElement(
          "div",
          { className: "change-author" },
          _react2.default.createElement(
            "span",
            {
              className: "ui-preview-text",
              style: { width: random.int(30, 100) + "px" }
            },
            "\xA0"
          )
        ),
        _react2.default.createElement(
          "div",
          { className: "change" },
          _react2.default.createElement(
            "span",
            {
              className: "ui-preview-text",
              style: { width: random.int(30, 70) + "px" }
            },
            "\xA0"
          ),
          _react2.default.createElement(
            "span",
            { className: "material-icon" },
            "arrow_forward"
          ),
          _react2.default.createElement(
            "span",
            {
              className: "ui-preview-text",
              style: { width: random.int(30, 70) + "px" }
            },
            "\xA0"
          )
        ),
        _react2.default.createElement(
          "div",
          { className: "change-date" },
          _react2.default.createElement(
            "span",
            {
              className: "ui-preview-text",
              style: { width: random.int(80, 140) + "px" }
            },
            "\xA0"
          )
        )
      );
    }
  }]);

  return _class;
}(_react2.default.Component);

exports.default = _class;

},{"../../utils/random":388,"../avatar":6,"react":"react"}],276:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _avatar = require("../avatar");

var _avatar2 = _interopRequireDefault(_avatar);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_React$Component) {
  _inherits(_class, _React$Component);

  function _class() {
    _classCallCheck(this, _class);

    return _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).apply(this, arguments));
  }

  _createClass(_class, [{
    key: "renderUserAvatar",
    value: function renderUserAvatar() {
      if (this.props.change.changed_by) {
        return _react2.default.createElement(
          "a",
          {
            href: this.props.change.changed_by.url,
            className: "user-avatar-wrapper"
          },
          _react2.default.createElement(_avatar2.default, { user: this.props.change.changed_by, size: "100" })
        );
      } else {
        return _react2.default.createElement(
          "span",
          { className: "user-avatar-wrapper" },
          _react2.default.createElement(_avatar2.default, { size: "100" })
        );
      }
    }
  }, {
    key: "renderUsername",
    value: function renderUsername() {
      if (this.props.change.changed_by) {
        return _react2.default.createElement(
          "a",
          { href: this.props.change.changed_by.url, className: "item-title" },
          this.props.change.changed_by.username
        );
      } else {
        return _react2.default.createElement(
          "span",
          { className: "item-title" },
          this.props.change.changed_by_username
        );
      }
    }
  }, {
    key: "render",
    value: function render() {
      return _react2.default.createElement(
        "li",
        { className: "list-group-item", key: this.props.change.id },
        _react2.default.createElement(
          "div",
          { className: "change-avatar" },
          this.renderUserAvatar()
        ),
        _react2.default.createElement(
          "div",
          { className: "change-author" },
          this.renderUsername()
        ),
        _react2.default.createElement(
          "div",
          { className: "change" },
          _react2.default.createElement(
            "span",
            { className: "old-username" },
            this.props.change.old_username
          ),
          _react2.default.createElement(
            "span",
            { className: "material-icon" },
            "arrow_forward"
          ),
          _react2.default.createElement(
            "span",
            { className: "new-username" },
            this.props.change.new_username
          )
        ),
        _react2.default.createElement(
          "div",
          { className: "change-date" },
          _react2.default.createElement(
            "abbr",
            { title: this.props.change.changed_on.format("LLL") },
            this.props.change.changed_on.fromNow()
          )
        )
      );
    }
  }]);

  return _class;
}(_react2.default.Component);

exports.default = _class;

},{"../avatar":6,"react":"react"}],277:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_React$Component) {
  _inherits(_class, _React$Component);

  function _class() {
    _classCallCheck(this, _class);

    return _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).apply(this, arguments));
  }

  _createClass(_class, [{
    key: "getEmptyMessage",
    value: function getEmptyMessage() {
      if (this.props.emptyMessage) {
        return this.props.emptyMessage;
      } else {
        return gettext("No name changes have been recorded for your account.");
      }
    }
  }, {
    key: "render",
    value: function render() {
      return _react2.default.createElement(
        "div",
        { className: "username-history ui-ready" },
        _react2.default.createElement(
          "ul",
          { className: "list-group" },
          _react2.default.createElement(
            "li",
            { className: "list-group-item empty-message" },
            this.getEmptyMessage()
          )
        )
      );
    }
  }]);

  return _class;
}(_react2.default.Component);

exports.default = _class;

},{"react":"react"}],278:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _changePreview = require("./change-preview");

var _changePreview2 = _interopRequireDefault(_changePreview);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_React$Component) {
  _inherits(_class, _React$Component);

  function _class() {
    _classCallCheck(this, _class);

    return _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).apply(this, arguments));
  }

  _createClass(_class, [{
    key: "shouldComponentUpdate",
    value: function shouldComponentUpdate() {
      return false;
    }
  }, {
    key: "render",
    value: function render() {
      return _react2.default.createElement(
        "div",
        { className: "username-history ui-preview" },
        _react2.default.createElement(
          "ul",
          { className: "list-group" },
          [0, 1, 2].map(function (i) {
            return _react2.default.createElement(_changePreview2.default, { hiddenOnMobile: i > 0, key: i });
          })
        )
      );
    }
  }]);

  return _class;
}(_react2.default.Component);

exports.default = _class;

},{"./change-preview":275,"react":"react"}],279:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _change = require("./change");

var _change2 = _interopRequireDefault(_change);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_React$Component) {
  _inherits(_class, _React$Component);

  function _class() {
    _classCallCheck(this, _class);

    return _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).apply(this, arguments));
  }

  _createClass(_class, [{
    key: "render",
    value: function render() {
      return _react2.default.createElement(
        "div",
        { className: "username-history ui-ready" },
        _react2.default.createElement(
          "ul",
          { className: "list-group" },
          this.props.changes.map(function (change) {
            return _react2.default.createElement(_change2.default, { change: change, key: change.id });
          })
        )
      );
    }
  }]);

  return _class;
}(_react2.default.Component);

exports.default = _class;

},{"./change":276,"react":"react"}],280:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _listEmpty = require("./list-empty");

var _listEmpty2 = _interopRequireDefault(_listEmpty);

var _listReady = require("./list-ready");

var _listReady2 = _interopRequireDefault(_listReady);

var _listPreview = require("./list-preview");

var _listPreview2 = _interopRequireDefault(_listPreview);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_React$Component) {
  _inherits(_class, _React$Component);

  function _class() {
    _classCallCheck(this, _class);

    return _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).apply(this, arguments));
  }

  _createClass(_class, [{
    key: "render",
    value: function render() {
      if (this.props.isLoaded) {
        if (this.props.changes.length) {
          return _react2.default.createElement(_listReady2.default, { changes: this.props.changes });
        } else {
          return _react2.default.createElement(_listEmpty2.default, { emptyMessage: this.props.emptyMessage });
        }
      } else {
        return _react2.default.createElement(_listPreview2.default, null);
      }
    }
  }]);

  return _class;
}(_react2.default.Component);

exports.default = _class;

},{"./list-empty":277,"./list-preview":278,"./list-ready":279,"react":"react"}],281:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (_ref) {
  var showStatus = _ref.showStatus,
      user = _ref.user;
  var rank = user.rank;


  var className = "panel user-card";
  if (rank.css_class) {
    className += " user-card-" + rank.css_class;
  }

  return _react2.default.createElement(
    "div",
    { className: className },
    _react2.default.createElement(
      "div",
      { className: "panel-body" },
      _react2.default.createElement(
        "div",
        { className: "row" },
        _react2.default.createElement(
          "div",
          { className: "col-xs-3 user-card-left" },
          _react2.default.createElement(
            "div",
            { className: "user-card-small-avatar" },
            _react2.default.createElement(
              "a",
              { href: user.url },
              _react2.default.createElement(_avatar2.default, { size: "50", size2x: "80", user: user })
            )
          )
        ),
        _react2.default.createElement(
          "div",
          { className: "col-xs-9 col-sm-12 user-card-body" },
          _react2.default.createElement(
            "div",
            { className: "user-card-avatar" },
            _react2.default.createElement(
              "a",
              { href: user.url },
              _react2.default.createElement(_avatar2.default, { size: "150", size2x: "200", user: user })
            )
          ),
          _react2.default.createElement(
            "div",
            { className: "user-card-username" },
            _react2.default.createElement(
              "a",
              { href: user.url },
              user.username
            )
          ),
          _react2.default.createElement(
            "div",
            { className: "user-card-title" },
            _react2.default.createElement(_userTitle2.default, { rank: rank, title: user.title })
          ),
          _react2.default.createElement(
            "div",
            { className: "user-card-stats" },
            _react2.default.createElement(_stats2.default, { showStatus: showStatus, user: user })
          )
        )
      )
    )
  );
};

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _avatar = require("../../avatar");

var _avatar2 = _interopRequireDefault(_avatar);

var _stats = require("./stats");

var _stats2 = _interopRequireDefault(_stats);

var _userTitle = require("./user-title");

var _userTitle2 = _interopRequireDefault(_userTitle);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

},{"../../avatar":6,"./stats":282,"./user-title":283,"react":"react"}],282:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (_ref) {
  var showStatus = _ref.showStatus,
      user = _ref.user;

  return _react2.default.createElement(
    "ul",
    { className: "list-unstyled" },
    _react2.default.createElement(Status, { showStatus: showStatus, user: user }),
    _react2.default.createElement(JoinDate, { user: user }),
    _react2.default.createElement("li", { className: "user-stat-divider" }),
    _react2.default.createElement(Posts, { user: user }),
    _react2.default.createElement(Threads, { user: user }),
    _react2.default.createElement(Followers, { user: user })
  );
};

exports.Status = Status;
exports.JoinDate = JoinDate;
exports.Posts = Posts;
exports.Threads = Threads;
exports.Followers = Followers;
exports.getStatClassName = getStatClassName;

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _userStatus = require("../../user-status");

var _userStatus2 = _interopRequireDefault(_userStatus);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function Status(_ref2) {
  var showStatus = _ref2.showStatus,
      user = _ref2.user;

  if (!showStatus) return null;

  return _react2.default.createElement(
    "li",
    { className: "user-stat-status" },
    _react2.default.createElement(
      _userStatus2.default,
      { status: user.status },
      _react2.default.createElement(_userStatus.StatusLabel, { status: user.status, user: user })
    )
  );
}

function JoinDate(_ref3) {
  var user = _ref3.user;
  var joined_on = user.joined_on;


  var title = interpolate(gettext("Joined on %(joined_on)s"), {
    joined_on: joined_on.format("LL, LT")
  }, true);

  var message = interpolate(gettext("Joined %(joined_on)s"), {
    joined_on: joined_on.fromNow()
  }, true);

  return _react2.default.createElement(
    "li",
    { className: "user-stat-join-date" },
    _react2.default.createElement(
      "abbr",
      { title: title },
      message
    )
  );
}

function Posts(_ref4) {
  var user = _ref4.user;

  var className = getStatClassName("user-stat-posts", user.posts);
  var message = ngettext("%(posts)s post", "%(posts)s posts", user.posts);

  return _react2.default.createElement(
    "li",
    { className: className },
    interpolate(message, {
      posts: user.posts
    }, true)
  );
}

function Threads(_ref5) {
  var user = _ref5.user;

  var className = getStatClassName("user-stat-threads", user.threads);
  var message = ngettext("%(threads)s thread", "%(threads)s threads", user.threads);

  return _react2.default.createElement(
    "li",
    { className: className },
    interpolate(message, {
      threads: user.threads
    }, true)
  );
}

function Followers(_ref6) {
  var user = _ref6.user;

  var className = getStatClassName("user-stat-followers", user.followers);
  var message = ngettext("%(followers)s follower", "%(followers)s followers", user.followers);

  return _react2.default.createElement(
    "li",
    { className: className },
    interpolate(message, {
      followers: user.followers
    }, true)
  );
}

function getStatClassName(className, stat) {
  if (stat === 0) {
    return className + " user-stat-empty";
  }
  return className;
}

},{"../../user-status":274,"react":"react"}],283:[function(require,module,exports){
arguments[4][130][0].apply(exports,arguments)
},{"dup":130,"react":"react"}],284:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (_ref) {
  var cols = _ref.cols,
      isReady = _ref.isReady,
      showStatus = _ref.showStatus,
      users = _ref.users;

  var colClassName = "col-xs-12 col-sm-4";
  if (cols === 4) {
    colClassName += " col-md-3";
  }

  if (!isReady) {
    return _react2.default.createElement(_preview2.default, { colClassName: colClassName, cols: cols });
  }

  return _react2.default.createElement(
    "div",
    { className: "users-cards-list ui-ready" },
    _react2.default.createElement(
      "div",
      { className: "row" },
      users.map(function (user) {
        return _react2.default.createElement(
          "div",
          { className: colClassName, key: user.id },
          _react2.default.createElement(_card2.default, { showStatus: showStatus, user: user })
        );
      })
    )
  );
};

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _card = require("./card");

var _card2 = _interopRequireDefault(_card);

var _preview = require("./preview");

var _preview2 = _interopRequireDefault(_preview);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

},{"./card":281,"./preview":286,"react":"react"}],285:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _avatar = require("../../avatar");

var _avatar2 = _interopRequireDefault(_avatar);

var _random = require("../../../utils/random");

var random = _interopRequireWildcard(_random);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_React$Component) {
  _inherits(_class, _React$Component);

  function _class() {
    _classCallCheck(this, _class);

    return _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).apply(this, arguments));
  }

  _createClass(_class, [{
    key: "shouldComponentUpdate",
    value: function shouldComponentUpdate() {
      return false;
    }
  }, {
    key: "render",
    value: function render() {
      return _react2.default.createElement(
        "div",
        { className: "panel user-card user-card-preview" },
        _react2.default.createElement(
          "div",
          { className: "panel-body" },
          _react2.default.createElement(
            "div",
            { className: "row" },
            _react2.default.createElement(
              "div",
              { className: "col-xs-3 user-card-left" },
              _react2.default.createElement(
                "div",
                { className: "user-card-small-avatar" },
                _react2.default.createElement(
                  "span",
                  null,
                  _react2.default.createElement(_avatar2.default, { size: "50", size2x: "80" })
                )
              )
            ),
            _react2.default.createElement(
              "div",
              { className: "col-xs-9 col-sm-12 user-card-body" },
              _react2.default.createElement(
                "div",
                { className: "user-card-avatar" },
                _react2.default.createElement(
                  "span",
                  null,
                  _react2.default.createElement(_avatar2.default, { size: "150", size2x: "200" })
                )
              ),
              _react2.default.createElement(
                "div",
                { className: "user-card-username" },
                _react2.default.createElement(
                  "span",
                  {
                    className: "ui-preview-text",
                    style: { width: random.int(60, 150) + "px" }
                  },
                  "\xA0"
                )
              ),
              _react2.default.createElement(
                "div",
                { className: "user-card-title" },
                _react2.default.createElement(
                  "span",
                  {
                    className: "ui-preview-text",
                    style: { width: random.int(60, 150) + "px" }
                  },
                  "\xA0"
                )
              ),
              _react2.default.createElement(
                "div",
                { className: "user-card-stats" },
                _react2.default.createElement(
                  "ul",
                  { className: "list-unstyled" },
                  _react2.default.createElement(
                    "li",
                    null,
                    _react2.default.createElement(
                      "span",
                      {
                        className: "ui-preview-text",
                        style: { width: random.int(30, 70) + "px" }
                      },
                      "\xA0"
                    )
                  ),
                  _react2.default.createElement(
                    "li",
                    null,
                    _react2.default.createElement(
                      "span",
                      {
                        className: "ui-preview-text",
                        style: { width: random.int(30, 70) + "px" }
                      },
                      "\xA0"
                    )
                  ),
                  _react2.default.createElement("li", { className: "user-stat-divider" }),
                  _react2.default.createElement(
                    "li",
                    null,
                    _react2.default.createElement(
                      "span",
                      {
                        className: "ui-preview-text",
                        style: { width: random.int(30, 70) + "px" }
                      },
                      "\xA0"
                    )
                  ),
                  _react2.default.createElement(
                    "li",
                    null,
                    _react2.default.createElement(
                      "span",
                      {
                        className: "ui-preview-text",
                        style: { width: random.int(30, 70) + "px" }
                      },
                      "\xA0"
                    )
                  )
                )
              )
            )
          )
        )
      );
    }
  }]);

  return _class;
}(_react2.default.Component);

exports.default = _class;

},{"../../../utils/random":388,"../../avatar":6,"react":"react"}],286:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (_ref) {
  var colClassName = _ref.colClassName,
      cols = _ref.cols;

  var list = Array.apply(null, { length: cols }).map(Number.call, Number);

  return _react2.default.createElement(
    "div",
    { className: "users-cards-list ui-preview" },
    _react2.default.createElement(
      "div",
      { className: "row" },
      list.map(function (i) {
        var className = colClassName;
        if (i !== 0) className += " hidden-xs";
        if (i === 3) className += " hidden-sm";

        return _react2.default.createElement(
          "div",
          { className: className, key: i },
          _react2.default.createElement(_card2.default, null)
        );
      })
    )
  );
};

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _card = require("./card");

var _card2 = _interopRequireDefault(_card);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

},{"./card":285,"react":"react"}],287:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_React$Component) {
  _inherits(_class, _React$Component);

  function _class() {
    _classCallCheck(this, _class);

    return _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).apply(this, arguments));
  }

  _createClass(_class, [{
    key: "getEmptyMessage",
    value: function getEmptyMessage() {
      return interpolate(gettext("No users have posted any new messages during last %(days)s days."), { days: this.props.trackedPeriod }, true);
    }
  }, {
    key: "render",
    value: function render() {
      return _react2.default.createElement(
        "div",
        { className: "active-posters-list" },
        _react2.default.createElement(
          "div",
          { className: "container" },
          _react2.default.createElement(
            "p",
            { className: "lead" },
            this.getEmptyMessage()
          )
        )
      );
    }
  }]);

  return _class;
}(_react2.default.Component);

exports.default = _class;

},{"react":"react"}],288:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _avatar = require("../../avatar");

var _avatar2 = _interopRequireDefault(_avatar);

var _random = require("../../../utils/random");

var random = _interopRequireWildcard(_random);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_React$Component) {
  _inherits(_class, _React$Component);

  function _class() {
    _classCallCheck(this, _class);

    return _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).apply(this, arguments));
  }

  _createClass(_class, [{
    key: "shouldComponentUpdate",
    value: function shouldComponentUpdate() {
      return false;
    }
  }, {
    key: "getClassName",
    value: function getClassName() {
      if (this.props.hiddenOnMobile) {
        return "list-group-item hidden-xs hidden-sm";
      } else {
        return "list-group-item";
      }
    }
  }, {
    key: "render",
    value: function render() {
      return _react2.default.createElement(
        "li",
        { className: this.getClassName() },
        _react2.default.createElement(
          "div",
          { className: "rank-user-avatar" },
          _react2.default.createElement(
            "span",
            null,
            _react2.default.createElement(_avatar2.default, { size: "50" })
          )
        ),
        _react2.default.createElement(
          "div",
          { className: "rank-user" },
          _react2.default.createElement(
            "div",
            { className: "user-name" },
            _react2.default.createElement(
              "span",
              { className: "item-title" },
              _react2.default.createElement(
                "span",
                {
                  className: "ui-preview-text",
                  style: { width: random.int(30, 80) + "px" }
                },
                "\xA0"
              )
            )
          ),
          _react2.default.createElement(
            "div",
            { className: "user-details" },
            _react2.default.createElement(
              "span",
              { className: "user-status" },
              _react2.default.createElement(
                "span",
                { className: "status-icon ui-preview-text" },
                "\xA0"
              ),
              _react2.default.createElement(
                "span",
                {
                  className: "status-label ui-preview-text hidden-xs hidden-sm",
                  style: { width: random.int(30, 50) + "px" }
                },
                "\xA0"
              )
            ),
            _react2.default.createElement(
              "span",
              { className: "rank-name" },
              _react2.default.createElement(
                "span",
                {
                  className: "ui-preview-text",
                  style: { width: random.int(30, 50) + "px" }
                },
                "\xA0"
              )
            ),
            _react2.default.createElement(
              "span",
              { className: "user-title hidden-xs hidden-sm" },
              _react2.default.createElement(
                "span",
                {
                  className: "ui-preview-text",
                  style: { width: random.int(30, 50) + "px" }
                },
                "\xA0"
              )
            )
          ),
          _react2.default.createElement(
            "div",
            { className: "user-compact-stats visible-xs-block" },
            _react2.default.createElement(
              "span",
              { className: "rank-position" },
              _react2.default.createElement(
                "strong",
                null,
                _react2.default.createElement(
                  "span",
                  {
                    className: "ui-preview-text",
                    style: { width: random.int(20, 30) + "px" }
                  },
                  "\xA0"
                )
              ),
              _react2.default.createElement(
                "small",
                null,
                gettext("Rank")
              )
            ),
            _react2.default.createElement(
              "span",
              { className: "rank-posts-counted" },
              _react2.default.createElement(
                "strong",
                null,
                _react2.default.createElement(
                  "span",
                  {
                    className: "ui-preview-text",
                    style: { width: random.int(20, 30) + "px" }
                  },
                  "\xA0"
                )
              ),
              _react2.default.createElement(
                "small",
                null,
                gettext("Ranked posts")
              )
            )
          )
        ),
        _react2.default.createElement(
          "div",
          { className: "rank-position hidden-xs" },
          _react2.default.createElement(
            "strong",
            null,
            _react2.default.createElement(
              "span",
              {
                className: "ui-preview-text",
                style: { width: random.int(20, 30) + "px" }
              },
              "\xA0"
            )
          ),
          _react2.default.createElement(
            "small",
            null,
            gettext("Rank")
          )
        ),
        _react2.default.createElement(
          "div",
          { className: "rank-posts-counted hidden-xs" },
          _react2.default.createElement(
            "strong",
            null,
            _react2.default.createElement(
              "span",
              {
                className: "ui-preview-text",
                style: { width: random.int(20, 30) + "px" }
              },
              "\xA0"
            )
          ),
          _react2.default.createElement(
            "small",
            null,
            gettext("Ranked posts")
          )
        ),
        _react2.default.createElement(
          "div",
          { className: "rank-posts-total hidden-xs" },
          _react2.default.createElement(
            "strong",
            null,
            _react2.default.createElement(
              "span",
              {
                className: "ui-preview-text",
                style: { width: random.int(20, 30) + "px" }
              },
              "\xA0"
            )
          ),
          _react2.default.createElement(
            "small",
            null,
            gettext("Total posts")
          )
        )
      );
    }
  }]);

  return _class;
}(_react2.default.Component);

exports.default = _class;

},{"../../../utils/random":388,"../../avatar":6,"react":"react"}],289:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _reactRouter = require("react-router");

var _avatar = require("../../avatar");

var _avatar2 = _interopRequireDefault(_avatar);

var _userStatus = require("../../user-status");

var _userStatus2 = _interopRequireDefault(_userStatus);

var _index = require("../../../index");

var _index2 = _interopRequireDefault(_index);

var _random = require("../../../utils/random");

var random = _interopRequireWildcard(_random);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_React$Component) {
  _inherits(_class, _React$Component);

  function _class() {
    _classCallCheck(this, _class);

    return _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).apply(this, arguments));
  }

  _createClass(_class, [{
    key: "getClassName",
    value: function getClassName() {
      if (this.props.rank.css_class) {
        return "list-group-item list-group-rank-" + this.props.rank.css_class;
      } else {
        return "list-group-item";
      }
    }
  }, {
    key: "getUserStatus",
    value: function getUserStatus() {
      if (this.props.user.status) {
        return _react2.default.createElement(
          _userStatus2.default,
          { user: this.props.user, status: this.props.user.status },
          _react2.default.createElement(_userStatus.StatusIcon, { user: this.props.user, status: this.props.user.status }),
          _react2.default.createElement(_userStatus.StatusLabel, {
            user: this.props.user,
            status: this.props.user.status,
            className: "status-label hidden-xs hidden-sm"
          })
        );
      }

      return _react2.default.createElement(
        "span",
        { className: "user-status" },
        _react2.default.createElement(
          "span",
          { className: "status-icon ui-preview-text" },
          "\xA0"
        ),
        _react2.default.createElement(
          "span",
          {
            className: "status-label ui-preview-text hidden-xs hidden-sm",
            style: { width: random.int(30, 50) + "px" }
          },
          "\xA0"
        )
      );
    }
  }, {
    key: "getRankName",
    value: function getRankName() {
      if (!this.props.rank.is_tab) {
        return _react2.default.createElement(
          "span",
          { className: "rank-name item-title" },
          this.props.rank.name
        );
      }

      var rankUrl = _index2.default.get("USERS_LIST_URL") + this.props.rank.slug + "/";
      return _react2.default.createElement(
        _reactRouter.Link,
        { to: rankUrl, className: "rank-name item-title" },
        this.props.rank.name
      );
    }
  }, {
    key: "getUserTitle",
    value: function getUserTitle() {
      if (!this.props.user.title) return null;

      return _react2.default.createElement(
        "span",
        { className: "user-title hidden-xs hidden-sm" },
        this.props.user.title
      );
    }
  }, {
    key: "render",
    value: function render() {
      return _react2.default.createElement(
        "li",
        { className: this.getClassName() },
        _react2.default.createElement(
          "div",
          { className: "rank-user-avatar" },
          _react2.default.createElement(
            "a",
            { href: this.props.user.url },
            _react2.default.createElement(_avatar2.default, { user: this.props.user, size: 50, size2x: 64 })
          )
        ),
        _react2.default.createElement(
          "div",
          { className: "rank-user" },
          _react2.default.createElement(
            "div",
            { className: "user-name" },
            _react2.default.createElement(
              "a",
              { href: this.props.user.url, className: "item-title" },
              this.props.user.username
            )
          ),
          _react2.default.createElement(
            "div",
            { className: "user-details" },
            this.getUserStatus(),
            this.getRankName(),
            this.getUserTitle()
          ),
          _react2.default.createElement(
            "div",
            { className: "user-compact-stats visible-xs-block" },
            _react2.default.createElement(
              "span",
              { className: "rank-position" },
              _react2.default.createElement(
                "strong",
                null,
                "#",
                this.props.counter
              ),
              _react2.default.createElement(
                "small",
                null,
                gettext("Rank")
              )
            ),
            _react2.default.createElement(
              "span",
              { className: "rank-posts-counted" },
              _react2.default.createElement(
                "strong",
                null,
                this.props.user.meta.score
              ),
              _react2.default.createElement(
                "small",
                null,
                gettext("Ranked posts")
              )
            )
          )
        ),
        _react2.default.createElement(
          "div",
          { className: "rank-position hidden-xs" },
          _react2.default.createElement(
            "strong",
            null,
            "#",
            this.props.counter
          ),
          _react2.default.createElement(
            "small",
            null,
            gettext("Rank")
          )
        ),
        _react2.default.createElement(
          "div",
          { className: "rank-posts-counted hidden-xs" },
          _react2.default.createElement(
            "strong",
            null,
            this.props.user.meta.score
          ),
          _react2.default.createElement(
            "small",
            null,
            gettext("Ranked posts")
          )
        ),
        _react2.default.createElement(
          "div",
          { className: "rank-posts-total hidden-xs" },
          _react2.default.createElement(
            "strong",
            null,
            this.props.user.posts
          ),
          _react2.default.createElement(
            "small",
            null,
            gettext("Total posts")
          )
        )
      );
    }
  }]);

  return _class;
}(_react2.default.Component);

exports.default = _class;

},{"../../../index":302,"../../../utils/random":388,"../../avatar":6,"../../user-status":274,"react":"react","react-router":"react-router"}],290:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _listItemPreview = require("./list-item-preview");

var _listItemPreview2 = _interopRequireDefault(_listItemPreview);

var _random = require("../../../utils/random");

var random = _interopRequireWildcard(_random);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_React$Component) {
  _inherits(_class, _React$Component);

  function _class() {
    _classCallCheck(this, _class);

    return _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).apply(this, arguments));
  }

  _createClass(_class, [{
    key: "shouldComponentUpdate",
    value: function shouldComponentUpdate() {
      return false;
    }
  }, {
    key: "render",
    value: function render() {
      return _react2.default.createElement(
        "div",
        { className: "active-posters-list" },
        _react2.default.createElement(
          "div",
          { className: "container" },
          _react2.default.createElement(
            "p",
            { className: "lead ui-preview" },
            _react2.default.createElement(
              "span",
              {
                className: "ui-preview-text",
                style: { width: random.int(50, 220) + "px" }
              },
              "\xA0"
            )
          ),
          _react2.default.createElement(
            "div",
            { className: "active-posters ui-preview" },
            _react2.default.createElement(
              "ul",
              { className: "list-group" },
              [0, 1, 2].map(function (i) {
                return _react2.default.createElement(_listItemPreview2.default, { hiddenOnMobile: i > 0, key: i });
              })
            )
          )
        )
      );
    }
  }]);

  return _class;
}(_react2.default.Component);

exports.default = _class;

},{"../../../utils/random":388,"./list-item-preview":288,"react":"react"}],291:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _listItem = require("./list-item");

var _listItem2 = _interopRequireDefault(_listItem);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_React$Component) {
  _inherits(_class, _React$Component);

  function _class() {
    _classCallCheck(this, _class);

    return _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).apply(this, arguments));
  }

  _createClass(_class, [{
    key: "getLeadMessage",
    value: function getLeadMessage() {
      var message = ngettext("%(posters)s top poster from last %(days)s days.", "%(posters)s top posters from last %(days)s days.", this.props.count);

      return interpolate(message, {
        posters: this.props.count,
        days: this.props.trackedPeriod
      }, true);
    }
  }, {
    key: "render",
    value: function render() {
      return _react2.default.createElement(
        "div",
        { className: "active-posters-list" },
        _react2.default.createElement(
          "div",
          { className: "container" },
          _react2.default.createElement(
            "p",
            { className: "lead" },
            this.getLeadMessage()
          ),
          _react2.default.createElement(
            "div",
            { className: "active-posters ui-ready" },
            _react2.default.createElement(
              "ul",
              { className: "list-group" },
              this.props.users.map(function (user, i) {
                return _react2.default.createElement(_listItem2.default, {
                  user: user,
                  rank: user.rank,
                  counter: i + 1,
                  key: user.id
                });
              })
            )
          )
        )
      );
    }
  }]);

  return _class;
}(_react2.default.Component);

exports.default = _class;

},{"./list-item":289,"react":"react"}],292:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _listEmpty = require("./list-empty");

var _listEmpty2 = _interopRequireDefault(_listEmpty);

var _listPreview = require("./list-preview");

var _listPreview2 = _interopRequireDefault(_listPreview);

var _listReady = require("./list-ready");

var _listReady2 = _interopRequireDefault(_listReady);

var _index = require("../../../index");

var _index2 = _interopRequireDefault(_index);

var _users = require("../../../reducers/users");

var _polls = require("../../../services/polls");

var _polls2 = _interopRequireDefault(_polls);

var _store = require("../../../services/store");

var _store2 = _interopRequireDefault(_store);

var _pageTitle = require("../../../services/page-title");

var _pageTitle2 = _interopRequireDefault(_pageTitle);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_React$Component) {
  _inherits(_class, _React$Component);

  function _class(props) {
    _classCallCheck(this, _class);

    var _this = _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).call(this, props));

    _this.update = function (data) {
      _store2.default.dispatch((0, _users.hydrate)(data.results));

      _this.setState({
        isLoaded: true,

        trackedPeriod: data.tracked_period,
        count: data.count
      });
    };

    if (_index2.default.has("USERS")) {
      _this.initWithPreloadedData(_index2.default.pop("USERS"));
    } else {
      _this.initWithoutPreloadedData();
    }

    _this.startPolling();
    return _this;
  }

  _createClass(_class, [{
    key: "initWithPreloadedData",
    value: function initWithPreloadedData(data) {
      this.state = {
        isLoaded: true,

        trackedPeriod: data.tracked_period,
        count: data.count
      };

      _store2.default.dispatch((0, _users.hydrate)(data.results));
    }
  }, {
    key: "initWithoutPreloadedData",
    value: function initWithoutPreloadedData() {
      this.state = {
        isLoaded: false
      };
    }
  }, {
    key: "startPolling",
    value: function startPolling() {
      _polls2.default.start({
        poll: "active-posters",
        url: _index2.default.get("USERS_API"),
        data: {
          list: "active"
        },
        frequency: 90 * 1000,
        update: this.update
      });
    }
  }, {
    key: "componentDidMount",
    value: function componentDidMount() {
      _pageTitle2.default.set({
        title: this.props.route.extra.name,
        parent: gettext("Users")
      });
    }
  }, {
    key: "componentWillUnmount",
    value: function componentWillUnmount() {
      _polls2.default.stop("active-posters");
    }
  }, {
    key: "render",
    value: function render() {
      if (this.state.isLoaded) {
        if (this.state.count > 0) {
          return _react2.default.createElement(_listReady2.default, {
            users: this.props.users,
            trackedPeriod: this.state.trackedPeriod,
            count: this.state.count
          });
        } else {
          return _react2.default.createElement(_listEmpty2.default, { trackedPeriod: this.state.trackedPeriod });
        }
      } else {
        return _react2.default.createElement(_listPreview2.default, null);
      }
    }
  }]);

  return _class;
}(_react2.default.Component);

exports.default = _class;

},{"../../../index":302,"../../../reducers/users":364,"../../../services/page-title":373,"../../../services/polls":374,"../../../services/store":377,"./list-empty":287,"./list-preview":290,"./list-ready":291,"react":"react"}],293:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (_ref) {
  var baseUrl = _ref.baseUrl,
      lists = _ref.lists;

  return _react2.default.createElement(
    "ul",
    { className: "nav nav-pills" },
    lists.map(function (list) {
      var url = listUrl(baseUrl, list);
      return _react2.default.createElement(
        _li2.default,
        { path: url, key: url },
        _react2.default.createElement(
          _reactRouter.Link,
          { to: url },
          list.name
        )
      );
    })
  );
};

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _reactRouter = require("react-router");

var _li = require("../li");

var _li2 = _interopRequireDefault(_li);

var _index = require("../../index");

var _index2 = _interopRequireDefault(_index);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var listUrl = function listUrl(baseUrl, list) {
  var url = baseUrl;
  if (list.component === "rank") {
    url += list.slug;
  } else {
    url += list.component;
  }
  return url + "/";
};

},{"../../index":302,"../li":57,"react":"react","react-router":"react-router"}],294:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _usersList = require("../../users-list");

var _usersList2 = _interopRequireDefault(_usersList);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_React$Component) {
  _inherits(_class, _React$Component);

  function _class() {
    _classCallCheck(this, _class);

    return _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).apply(this, arguments));
  }

  _createClass(_class, [{
    key: "shouldComponentUpdate",
    value: function shouldComponentUpdate() {
      return false;
    }
  }, {
    key: "render",
    value: function render() {
      return _react2.default.createElement(
        "div",
        null,
        _react2.default.createElement(_usersList2.default, { cols: 4, isReady: false })
      );
    }
  }]);

  return _class;
}(_react2.default.Component);

exports.default = _class;

},{"../../users-list":284,"react":"react"}],295:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (props) {
  return _react2.default.createElement(
    "div",
    null,
    _react2.default.createElement(_usersList2.default, {
      cols: 4,
      isReady: true,
      showStatus: true,
      users: props.users
    }),
    _react2.default.createElement(_pager2.default, props)
  );
};

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _pager = require("./pager");

var _pager2 = _interopRequireDefault(_pager);

var _usersList = require("../../users-list");

var _usersList2 = _interopRequireDefault(_usersList);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

},{"../../users-list":284,"./pager":296,"react":"react"}],296:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (props) {
  if (props.pages === 1) return null;

  return _react2.default.createElement(
    "div",
    { className: "row row-toolbar" },
    _react2.default.createElement(
      "div",
      { className: "col-xs-12 text-center visible-xs-block" },
      _react2.default.createElement(More, { more: props.more }),
      _react2.default.createElement("div", { className: "toolbar-vertical-spacer" })
    ),
    _react2.default.createElement(
      "div",
      { className: "col-md-7" },
      _react2.default.createElement(
        "div",
        { className: "row" },
        _react2.default.createElement(
          "div",
          { className: "col-sm-4 col-md-5" },
          _react2.default.createElement(Pager, props)
        ),
        _react2.default.createElement(
          "div",
          { className: "col-sm-8 col-md-7 hidden-xs" },
          _react2.default.createElement(More, { more: props.more })
        )
      )
    )
  );
};

exports.Pager = Pager;
exports.FirstPage = FirstPage;
exports.PreviousPage = PreviousPage;
exports.NextPage = NextPage;
exports.LastPage = LastPage;
exports.More = More;

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _reactRouter = require("react-router");

var _resetScroll = require("../../../utils/reset-scroll");

var _resetScroll2 = _interopRequireDefault(_resetScroll);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function Pager(props) {
  return _react2.default.createElement(
    "div",
    { className: "row row-paginator" },
    _react2.default.createElement(
      "div",
      { className: "col-xs-3" },
      _react2.default.createElement(FirstPage, props)
    ),
    _react2.default.createElement(
      "div",
      { className: "col-xs-3" },
      _react2.default.createElement(PreviousPage, props)
    ),
    _react2.default.createElement(
      "div",
      { className: "col-xs-3" },
      _react2.default.createElement(NextPage, props)
    ),
    _react2.default.createElement(
      "div",
      { className: "col-xs-3" },
      _react2.default.createElement(LastPage, props)
    )
  );
}

function FirstPage(props) {
  if (props.isLoaded && props.first) {
    return _react2.default.createElement(
      _reactRouter.Link,
      {
        className: "btn btn-default btn-block btn-icon btn-outline",
        onClick: _resetScroll2.default,
        to: props.baseUrl,
        title: gettext("Go to first page")
      },
      _react2.default.createElement(
        "span",
        { className: "material-icon" },
        "first_page"
      )
    );
  } else {
    return _react2.default.createElement(
      "span",
      {
        className: "btn btn-default btn-block btn-icon btn-outline disabled",
        title: gettext("Go to first page")
      },
      _react2.default.createElement(
        "span",
        { className: "material-icon" },
        "first_page"
      )
    );
  }
}

function PreviousPage(props) {
  if (props.isLoaded && props.page > 1) {
    var previousUrl = "";
    if (props.previous) {
      previousUrl = props.previous + "/";
    }

    return _react2.default.createElement(
      _reactRouter.Link,
      {
        className: "btn btn-default btn-block btn-icon btn-outline",
        onClick: _resetScroll2.default,
        to: props.baseUrl + previousUrl,
        title: gettext("Go to previous page")
      },
      _react2.default.createElement(
        "span",
        { className: "material-icon" },
        "chevron_left"
      )
    );
  } else {
    return _react2.default.createElement(
      "span",
      {
        className: "btn btn-default btn-block btn-icon btn-outline disabled",
        title: gettext("Go to previous page")
      },
      _react2.default.createElement(
        "span",
        { className: "material-icon" },
        "chevron_left"
      )
    );
  }
}

function NextPage(props) {
  if (props.isLoaded && props.more) {
    var nextUrl = "";
    if (props.next) {
      nextUrl = props.next + "/";
    }

    return _react2.default.createElement(
      _reactRouter.Link,
      {
        className: "btn btn-default btn-block btn-icon btn-outline",
        onClick: _resetScroll2.default,
        to: props.baseUrl + nextUrl,
        title: gettext("Go to next page")
      },
      _react2.default.createElement(
        "span",
        { className: "material-icon" },
        "chevron_right"
      )
    );
  } else {
    return _react2.default.createElement(
      "span",
      {
        className: "btn btn-default btn-block btn-icon btn-outline disabled",
        title: gettext("Go to next page")
      },
      _react2.default.createElement(
        "span",
        { className: "material-icon" },
        "chevron_right"
      )
    );
  }
}

function LastPage(props) {
  if (props.isLoaded && props.last) {
    return _react2.default.createElement(
      _reactRouter.Link,
      {
        className: "btn btn-default btn-block btn-icon btn-outline",
        onClick: _resetScroll2.default,
        to: props.baseUrl + props.last + "/",
        title: gettext("Go to last page")
      },
      _react2.default.createElement(
        "span",
        { className: "material-icon" },
        "last_page"
      )
    );
  } else {
    return _react2.default.createElement(
      "span",
      {
        className: "btn btn-default btn-block btn-icon btn-outline disabled",
        title: gettext("Go to last page")
      },
      _react2.default.createElement(
        "span",
        { className: "material-icon" },
        "last_page"
      )
    );
  }
}

function More(props) {
  var message = null;
  if (props.more) {
    message = ngettext("There is %(more)s more member with this role.", "There are %(more)s more members with this role.", props.more);
    message = interpolate(message, { more: props.more }, true);
  } else {
    message = gettext("There are no more members with this role.");
  }

  return _react2.default.createElement(
    "p",
    null,
    message
  );
}

},{"../../../utils/reset-scroll":389,"react":"react","react-router":"react-router"}],297:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _pageLead = require("../../page-lead");

var _pageLead2 = _interopRequireDefault(_pageLead);

var _list = require("./list");

var _list2 = _interopRequireDefault(_list);

var _listLoading = require("./list-loading");

var _listLoading2 = _interopRequireDefault(_listLoading);

var _index = require("../../../index");

var _index2 = _interopRequireDefault(_index);

var _users = require("../../../reducers/users");

var _polls = require("../../../services/polls");

var _polls2 = _interopRequireDefault(_polls);

var _store = require("../../../services/store");

var _store2 = _interopRequireDefault(_store);

var _pageTitle = require("../../../services/page-title");

var _pageTitle2 = _interopRequireDefault(_pageTitle);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_React$Component) {
  _inherits(_class, _React$Component);

  function _class(props) {
    _classCallCheck(this, _class);

    var _this = _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).call(this, props));

    _this.update = function (data) {
      _store2.default.dispatch((0, _users.hydrate)(data.results));

      data.isLoaded = true;
      _this.setState(data);
    };

    if (_index2.default.has("USERS")) {
      _this.initWithPreloadedData(_index2.default.pop("USERS"));
    } else {
      _this.initWithoutPreloadedData();
    }

    _this.startPolling(props.params.page || 1);
    return _this;
  }

  _createClass(_class, [{
    key: "initWithPreloadedData",
    value: function initWithPreloadedData(data) {
      this.state = Object.assign(data, {
        isLoaded: true
      });
      _store2.default.dispatch((0, _users.hydrate)(data.results));
    }
  }, {
    key: "initWithoutPreloadedData",
    value: function initWithoutPreloadedData() {
      this.state = {
        isLoaded: false
      };
    }
  }, {
    key: "startPolling",
    value: function startPolling(page) {
      _polls2.default.start({
        poll: "rank-users",
        url: _index2.default.get("USERS_API"),
        data: {
          rank: this.props.route.rank.id,
          page: page
        },
        frequency: 90 * 1000,
        update: this.update
      });
    }
  }, {
    key: "componentDidMount",
    value: function componentDidMount() {
      _pageTitle2.default.set({
        title: this.props.route.rank.name,
        page: this.props.params.page || null,
        parent: gettext("Users")
      });
    }
  }, {
    key: "componentWillUnmount",
    value: function componentWillUnmount() {
      _polls2.default.stop("rank-users");
    }
  }, {
    key: "componentWillReceiveProps",
    value: function componentWillReceiveProps(nextProps) {
      if (this.props.params.page !== nextProps.params.page) {
        _pageTitle2.default.set({
          title: this.props.route.rank.name,
          page: nextProps.params.page || null,
          parent: gettext("Users")
        });

        this.setState({
          isLoaded: false
        });

        _polls2.default.stop("rank-users");
        this.startPolling(nextProps.params.page);
      }
    }
  }, {
    key: "getClassName",
    value: function getClassName() {
      if (this.props.route.rank.css_class) {
        return "rank-users-list rank-users-" + this.props.route.rank.css_class;
      } else {
        return "rank-users-list";
      }
    }
  }, {
    key: "getRankDescription",
    value: function getRankDescription() {
      if (this.props.route.rank.description) {
        return _react2.default.createElement(
          "div",
          { className: "rank-description" },
          _react2.default.createElement(_pageLead2.default, { copy: this.props.route.rank.description.html })
        );
      } else {
        return null;
      }
    }
  }, {
    key: "getComponent",
    value: function getComponent() {
      if (this.state.isLoaded) {
        if (this.state.count > 0) {
          var baseUrl = _index2.default.get("USERS_LIST_URL") + this.props.route.rank.slug + "/";
          return _react2.default.createElement(_list2.default, _extends({ baseUrl: baseUrl, users: this.props.users }, this.state));
        } else {
          return _react2.default.createElement(
            "p",
            { className: "lead" },
            gettext("There are no users with this rank at the moment.")
          );
        }
      } else {
        return _react2.default.createElement(_listLoading2.default, null);
      }
    }
  }, {
    key: "render",
    value: function render() {
      return _react2.default.createElement(
        "div",
        { className: this.getClassName() },
        _react2.default.createElement(
          "div",
          { className: "container" },
          this.getRankDescription(),
          this.getComponent()
        )
      );
    }
  }]);

  return _class;
}(_react2.default.Component);

exports.default = _class;

},{"../../../index":302,"../../../reducers/users":364,"../../../services/page-title":373,"../../../services/polls":374,"../../../services/store":377,"../../page-lead":92,"./list":295,"./list-loading":294,"react":"react"}],298:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

exports.select = select;
exports.paths = paths;

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _reactRedux = require("react-redux");

var _dropdownToggle = require("../dropdown-toggle");

var _dropdownToggle2 = _interopRequireDefault(_dropdownToggle);

var _nav = require("./nav");

var _nav2 = _interopRequireDefault(_nav);

var _root = require("./active-posters/root");

var _root2 = _interopRequireDefault(_root);

var _root3 = require("./rank/root");

var _root4 = _interopRequireDefault(_root3);

var _withDropdown = require("../with-dropdown");

var _withDropdown2 = _interopRequireDefault(_withDropdown);

var _index = require("../../index");

var _index2 = _interopRequireDefault(_index);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_WithDropdown) {
  _inherits(_class, _WithDropdown);

  function _class() {
    _classCallCheck(this, _class);

    return _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).apply(this, arguments));
  }

  _createClass(_class, [{
    key: "render",
    value: function render() {
      return _react2.default.createElement(
        "div",
        { className: "page page-users-lists" },
        _react2.default.createElement(
          "div",
          { className: "page-header-bg" },
          _react2.default.createElement(
            "div",
            { className: "page-header" },
            _react2.default.createElement(
              "div",
              { className: "container" },
              _react2.default.createElement(
                "h1",
                null,
                gettext("Users")
              )
            ),
            _react2.default.createElement(
              "div",
              { className: "page-tabs" },
              _react2.default.createElement(
                "div",
                { className: "container" },
                _react2.default.createElement(_nav2.default, {
                  lists: _index2.default.get("USERS_LISTS"),
                  baseUrl: _index2.default.get("USERS_LIST_URL")
                })
              )
            )
          )
        ),
        this.props.children
      );
    }
  }]);

  return _class;
}(_withDropdown2.default);

exports.default = _class;
function select(store) {
  return {
    tick: store.tick.tick,
    user: store.auth.user,
    users: store.users
  };
}

function paths() {
  var paths = [];

  _index2.default.get("USERS_LISTS").forEach(function (item) {
    if (item.component === "rank") {
      paths.push({
        path: _index2.default.get("USERS_LIST_URL") + item.slug + "/:page/",
        component: (0, _reactRedux.connect)(select)(_root4.default),
        rank: item
      });
      paths.push({
        path: _index2.default.get("USERS_LIST_URL") + item.slug + "/",
        component: (0, _reactRedux.connect)(select)(_root4.default),
        rank: item
      });
    } else if (item.component === "active-posters") {
      paths.push({
        path: _index2.default.get("USERS_LIST_URL") + item.component + "/",
        component: (0, _reactRedux.connect)(select)(_root2.default),
        extra: {
          name: item.name
        }
      });
    }
  });

  return paths;
}

},{"../../index":302,"../dropdown-toggle":27,"../with-dropdown":299,"./active-posters/root":292,"./nav":293,"./rank/root":297,"react":"react","react-redux":"react-redux"}],299:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_React$Component) {
  _inherits(_class, _React$Component);

  function _class(props) {
    _classCallCheck(this, _class);

    var _this = _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).call(this, props));

    _this.toggleNav = function () {
      _this.setState({
        dropdown: !_this.state.dropdown
      });
    };

    _this.hideNav = function () {
      _this.setState({
        dropdown: false
      });
    };

    _this.state = {
      dropdown: false
    };
    return _this;
  }

  _createClass(_class, [{
    key: "getCompactNavClassName",
    value: function getCompactNavClassName() {
      if (this.state.dropdown) {
        return "compact-nav open";
      } else {
        return "compact-nav";
      }
    }
  }]);

  return _class;
}(_react2.default.Component);

exports.default = _class;

},{"react":"react"}],300:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_React$Component) {
  _inherits(_class, _React$Component);

  function _class() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, _class);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = _class.__proto__ || Object.getPrototypeOf(_class)).call.apply(_ref, [this].concat(args))), _this), _this.toggle = function () {
      _this.props.onChange({
        target: {
          value: !_this.props.value
        }
      });
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(_class, [{
    key: "getClassName",
    value: function getClassName() {
      if (this.props.value) {
        return "btn btn-yes-no btn-yes-no-on";
      } else {
        return "btn btn-yes-no btn-yes-no-off";
      }
    }
  }, {
    key: "getIcon",
    value: function getIcon() {
      if (!!this.props.value) {
        return this.props.iconOn || "check_box";
      } else {
        return this.props.iconOff || "check_box_outline_blank";
      }
    }
  }, {
    key: "getLabel",
    value: function getLabel() {
      if (!!this.props.value) {
        return this.props.labelOn || gettext("yes");
      } else {
        return this.props.labelOff || gettext("no");
      }
    }
  }, {
    key: "render",
    value: function render() {
      return _react2.default.createElement(
        "button",
        {
          type: "button",
          onClick: this.toggle,
          className: this.getClassName(),
          id: this.props.id || null,
          "aria-describedby": this.props["aria-describedby"] || null,
          disabled: this.props.disabled || false
        },
        _react2.default.createElement(
          "span",
          { className: "material-icon" },
          this.getIcon()
        ),
        _react2.default.createElement(
          "span",
          { className: "btn-text" },
          this.getLabel()
        )
      );
    }
  }]);

  return _class;
}(_react2.default.Component);

exports.default = _class;

},{"react":"react"}],301:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _profileDetails = require("../reducers/profile-details");

var _ajax = require("../services/ajax");

var _ajax2 = _interopRequireDefault(_ajax);

var _snackbar = require("../services/snackbar");

var _snackbar2 = _interopRequireDefault(_snackbar);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_React$Component) {
  _inherits(_class, _React$Component);

  function _class() {
    _classCallCheck(this, _class);

    return _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).apply(this, arguments));
  }

  _createClass(_class, [{
    key: "componentDidMount",
    value: function componentDidMount() {
      var _props = this.props,
          data = _props.data,
          dispatch = _props.dispatch,
          user = _props.user;

      if (data && data.id === user.id) return;

      _ajax2.default.get(this.props.user.api.details).then(function (data) {
        dispatch((0, _profileDetails.load)(data));
      }, function (rejection) {
        _snackbar2.default.apiError(rejection);
      });
    }
  }, {
    key: "render",
    value: function render() {
      return this.props.children;
    }
  }]);

  return _class;
}(_react2.default.Component);

exports.default = _class;

},{"../reducers/profile-details":355,"../services/ajax":365,"../services/snackbar":376,"react":"react"}],302:[function(require,module,exports){
(function (global){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Misago = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _orderedList = require("./utils/ordered-list");

var _orderedList2 = _interopRequireDefault(_orderedList);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var Misago = exports.Misago = function () {
  function Misago() {
    _classCallCheck(this, Misago);

    this._initializers = [];
    this._context = {};
  }

  _createClass(Misago, [{
    key: "addInitializer",
    value: function addInitializer(initializer) {
      this._initializers.push({
        key: initializer.name,

        item: initializer.initializer,

        after: initializer.after,
        before: initializer.before
      });
    }
  }, {
    key: "init",
    value: function init(context) {
      var _this = this;

      this._context = context;

      var initOrder = new _orderedList2.default(this._initializers).orderedValues();
      initOrder.forEach(function (initializer) {
        initializer(_this);
      });
    }

    // context accessors

  }, {
    key: "has",
    value: function has(key) {
      return !!this._context[key];
    }
  }, {
    key: "get",
    value: function get(key, fallback) {
      if (this.has(key)) {
        return this._context[key];
      } else {
        return fallback || undefined;
      }
    }
  }, {
    key: "pop",
    value: function pop(key) {
      if (this.has(key)) {
        var value = this._context[key];
        this._context[key] = null;
        return value;
      } else {
        return undefined;
      }
    }
  }]);

  return Misago;
}();

// create  singleton


var misago = new Misago();

// expose it globally
global.misago = misago;

// and export it for tests and stuff
exports.default = misago;

}).call(this,typeof global !== "undefined" ? global : typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {})
},{"./utils/ordered-list":387}],303:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = initializer;

var _index = require("../index");

var _index2 = _interopRequireDefault(_index);

var _ajax = require("../services/ajax");

var _ajax2 = _interopRequireDefault(_ajax);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function initializer() {
  _ajax2.default.init(_index2.default.get("CSRF_COOKIE_NAME"));
}

_index2.default.addInitializer({
  name: "ajax",
  initializer: initializer
});

},{"../index":302,"../services/ajax":365}],304:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = initializer;

var _index = require("../index");

var _index2 = _interopRequireDefault(_index);

var _auth = require("../reducers/auth");

var _ajax = require("../services/ajax");

var _ajax2 = _interopRequireDefault(_ajax);

var _snackbar = require("../services/snackbar");

var _snackbar2 = _interopRequireDefault(_snackbar);

var _store = require("../services/store");

var _store2 = _interopRequireDefault(_store);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var AUTH_SYNC_RATE = 45; // sync user with backend every 45 seconds

function initializer(context) {
  if (context.get("isAuthenticated")) {
    window.setInterval(function () {
      _ajax2.default.get(context.get("AUTH_API")).then(function (data) {
        _store2.default.dispatch((0, _auth.patch)(data));
      }, function (rejection) {
        _snackbar2.default.apiError(rejection);
      });
    }, AUTH_SYNC_RATE * 1000);
  }
}

_index2.default.addInitializer({
  name: "auth-sync",
  initializer: initializer,
  after: "auth"
});

},{"../index":302,"../reducers/auth":350,"../services/ajax":365,"../services/snackbar":376,"../services/store":377}],305:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = initializer;

var _index = require("../index");

var _index2 = _interopRequireDefault(_index);

var _auth = require("../services/auth");

var _auth2 = _interopRequireDefault(_auth);

var _modal = require("../services/modal");

var _modal2 = _interopRequireDefault(_modal);

var _store = require("../services/store");

var _store2 = _interopRequireDefault(_store);

var _localStorage = require("../services/local-storage");

var _localStorage2 = _interopRequireDefault(_localStorage);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function initializer() {
  _auth2.default.init(_store2.default, _localStorage2.default, _modal2.default);
}

_index2.default.addInitializer({
  name: "auth",
  initializer: initializer,
  after: "store"
});

},{"../index":302,"../services/auth":366,"../services/local-storage":369,"../services/modal":371,"../services/store":377}],306:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = initializer;

var _index = require("../index");

var _index2 = _interopRequireDefault(_index);

var _ajax = require("../services/ajax");

var _ajax2 = _interopRequireDefault(_ajax);

var _captcha = require("../services/captcha");

var _captcha2 = _interopRequireDefault(_captcha);

var _include = require("../services/include");

var _include2 = _interopRequireDefault(_include);

var _snackbar = require("../services/snackbar");

var _snackbar2 = _interopRequireDefault(_snackbar);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function initializer(context) {
  _captcha2.default.init(context, _ajax2.default, _include2.default, _snackbar2.default);
}

_index2.default.addInitializer({
  name: "captcha",
  initializer: initializer
});

},{"../index":302,"../services/ajax":365,"../services/captcha":367,"../services/include":368,"../services/snackbar":376}],307:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = initializer;

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _index = require("../../index");

var _index2 = _interopRequireDefault(_index);

var _acceptAgreement = require("../../components/accept-agreement");

var _acceptAgreement2 = _interopRequireDefault(_acceptAgreement);

var _mountComponent = require("../../utils/mount-component");

var _mountComponent2 = _interopRequireDefault(_mountComponent);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function initializer(context) {
  if (document.getElementById("required-agreement-mount")) {
    (0, _mountComponent2.default)(_react2.default.createElement(_acceptAgreement2.default, { api: context.get("REQUIRED_AGREEMENT_API") }), "required-agreement-mount", false);
  }
}

_index2.default.addInitializer({
  name: "component:accept-agreement",
  initializer: initializer,
  after: "store"
});

},{"../../components/accept-agreement":3,"../../index":302,"../../utils/mount-component":386,"react":"react"}],308:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = initializer;

var _reactRedux = require("react-redux");

var _index = require("../../index");

var _index2 = _interopRequireDefault(_index);

var _authMessage = require("../../components/auth-message");

var _authMessage2 = _interopRequireDefault(_authMessage);

var _mountComponent = require("../../utils/mount-component");

var _mountComponent2 = _interopRequireDefault(_mountComponent);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function initializer() {
  (0, _mountComponent2.default)((0, _reactRedux.connect)(_authMessage.select)(_authMessage2.default), "auth-message-mount");
}

_index2.default.addInitializer({
  name: "component:auth-message",
  initializer: initializer,
  after: "store"
});

},{"../../components/auth-message":5,"../../index":302,"../../utils/mount-component":386,"react-redux":"react-redux"}],309:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = initializer;

var _index = require("../../index");

var _index2 = _interopRequireDefault(_index);

var _bannedPage = require("../../utils/banned-page");

var _bannedPage2 = _interopRequireDefault(_bannedPage);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function initializer(context) {
  if (context.has("BAN_MESSAGE")) {
    (0, _bannedPage2.default)(context.get("BAN_MESSAGE"), false);
  }
}

_index2.default.addInitializer({
  name: "component:banmed-page",
  initializer: initializer,
  after: "store"
});

},{"../../index":302,"../../utils/banned-page":379}],310:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = initializer;

var _reactRedux = require("react-redux");

var _categories = require("../../components/categories");

var _categories2 = _interopRequireDefault(_categories);

var _index = require("../../index");

var _index2 = _interopRequireDefault(_index);

var _mountComponent = require("../../utils/mount-component");

var _mountComponent2 = _interopRequireDefault(_mountComponent);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function initializer() {
  if (document.getElementById("categories-mount")) {
    (0, _mountComponent2.default)((0, _reactRedux.connect)(_categories.select)(_categories2.default), "categories-mount");
  }
}

_index2.default.addInitializer({
  name: "component:categories",
  initializer: initializer,
  after: "store"
});

},{"../../components/categories":20,"../../index":302,"../../utils/mount-component":386,"react-redux":"react-redux"}],311:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = initializer;

var _root = require("../../components/options/root");

var _root2 = _interopRequireDefault(_root);

var _index = require("../../index");

var _index2 = _interopRequireDefault(_index);

var _routedComponent = require("../../utils/routed-component");

var _routedComponent2 = _interopRequireDefault(_routedComponent);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function initializer(context) {
  if (context.has("USER_OPTIONS")) {
    (0, _routedComponent2.default)({
      root: _index2.default.get("USERCP_URL"),
      component: _root2.default,
      paths: (0, _root.paths)()
    });
  }
}

_index2.default.addInitializer({
  name: "component:options",
  initializer: initializer,
  after: "store"
});

},{"../../components/options/root":87,"../../index":302,"../../utils/routed-component":390}],312:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = initializer;

var _reactRedux = require("react-redux");

var _root = require("../../components/profile/root");

var _root2 = _interopRequireDefault(_root);

var _index = require("../../index");

var _index2 = _interopRequireDefault(_index);

var _routedComponent = require("../../utils/routed-component");

var _routedComponent2 = _interopRequireDefault(_routedComponent);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function initializer(context) {
  if (context.has("PROFILE") && context.has("PROFILE_PAGES")) {
    (0, _routedComponent2.default)({
      root: _index2.default.get("PROFILE").url,
      component: (0, _reactRedux.connect)(_root.select)(_root2.default),
      paths: (0, _root.paths)()
    });
  }
}

_index2.default.addInitializer({
  name: "component:profile",
  initializer: initializer,
  after: "reducer:profile-hydrate"
});

},{"../../components/profile/root":195,"../../index":302,"../../utils/routed-component":390,"react-redux":"react-redux"}],313:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = initializer;

var _index = require("../../index");

var _index2 = _interopRequireDefault(_index);

var _requestActivationLink = require("../../components/request-activation-link");

var _requestActivationLink2 = _interopRequireDefault(_requestActivationLink);

var _mountComponent = require("../../utils/mount-component");

var _mountComponent2 = _interopRequireDefault(_mountComponent);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function initializer() {
  if (document.getElementById("request-activation-link-mount")) {
    (0, _mountComponent2.default)(_requestActivationLink2.default, "request-activation-link-mount", false);
  }
}

_index2.default.addInitializer({
  name: "component:request-activation-link",
  initializer: initializer,
  after: "store"
});

},{"../../components/request-activation-link":200,"../../index":302,"../../utils/mount-component":386}],314:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = initializer;

var _index = require("../../index");

var _index2 = _interopRequireDefault(_index);

var _requestPasswordReset = require("../../components/request-password-reset");

var _requestPasswordReset2 = _interopRequireDefault(_requestPasswordReset);

var _mountComponent = require("../../utils/mount-component");

var _mountComponent2 = _interopRequireDefault(_mountComponent);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function initializer() {
  if (document.getElementById("request-password-reset-mount")) {
    (0, _mountComponent2.default)(_requestPasswordReset2.default, "request-password-reset-mount", false);
  }
}

_index2.default.addInitializer({
  name: "component:request-password-reset",
  initializer: initializer,
  after: "store"
});

},{"../../components/request-password-reset":201,"../../index":302,"../../utils/mount-component":386}],315:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = initializer;

var _ = require("../..");

var _2 = _interopRequireDefault(_);

var _resetPasswordForm = require("../../components/reset-password-form");

var _resetPasswordForm2 = _interopRequireDefault(_resetPasswordForm);

var _mountComponent = require("../../utils/mount-component");

var _mountComponent2 = _interopRequireDefault(_mountComponent);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function initializer() {
  if (document.getElementById("reset-password-form-mount")) {
    (0, _mountComponent2.default)(_resetPasswordForm2.default, "reset-password-form-mount", false);
  }
}

_2.default.addInitializer({
  name: "component:reset-password-form",
  initializer: initializer,
  after: "store"
});

},{"../..":302,"../../components/reset-password-form":202,"../../utils/mount-component":386}],316:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = initializer;

var _search = require("../../components/search");

var _search2 = _interopRequireDefault(_search);

var _ = require("../..");

var _2 = _interopRequireDefault(_);

var _routedComponent = require("../../utils/routed-component");

var _routedComponent2 = _interopRequireDefault(_routedComponent);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function initializer(context) {
  if (context.get("CURRENT_LINK") === "misago:search") {
    (0, _routedComponent2.default)({
      paths: (0, _search2.default)(_2.default.get("SEARCH_PROVIDERS"))
    });
  }
}

_2.default.addInitializer({
  name: "component:search",
  initializer: initializer,
  after: "store"
});

},{"../..":302,"../../components/search":204,"../../utils/routed-component":390}],317:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = initializer;

var _reactRedux = require("react-redux");

var _index = require("../../index");

var _index2 = _interopRequireDefault(_index);

var _snackbar = require("../../components/snackbar");

var _mountComponent = require("../../utils/mount-component");

var _mountComponent2 = _interopRequireDefault(_mountComponent);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function initializer() {
  (0, _mountComponent2.default)((0, _reactRedux.connect)(_snackbar.select)(_snackbar.Snackbar), "snackbar-mount");
}

_index2.default.addInitializer({
  name: "component:snackbar",
  initializer: initializer,
  after: "snackbar"
});

},{"../../components/snackbar":212,"../../index":302,"../../utils/mount-component":386,"react-redux":"react-redux"}],318:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = initializer;

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _socialAuth = require("../../components/social-auth");

var _socialAuth2 = _interopRequireDefault(_socialAuth);

var _ = require("../..");

var _2 = _interopRequireDefault(_);

var _mountComponent = require("../../utils/mount-component");

var _mountComponent2 = _interopRequireDefault(_mountComponent);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function initializer(context) {
  if (context.get("CURRENT_LINK") === "misago:social-complete") {
    var props = context.get("SOCIAL_AUTH_FORM");
    (0, _mountComponent2.default)(_react2.default.createElement(_socialAuth2.default, props), "page-mount");
  }
}

_2.default.addInitializer({
  name: "component:social-auth",
  initializer: initializer,
  after: "store"
});

},{"../..":302,"../../components/social-auth":215,"../../utils/mount-component":386,"react":"react"}],319:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = initializer;

var _root = require("../../components/thread/root");

var _index = require("../../index");

var _index2 = _interopRequireDefault(_index);

var _routedComponent = require("../../utils/routed-component");

var _routedComponent2 = _interopRequireDefault(_routedComponent);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function initializer(context) {
  if (context.has("THREAD") && context.has("POSTS")) {
    (0, _routedComponent2.default)({
      paths: (0, _root.paths)()
    });
  }
}

_index2.default.addInitializer({
  name: "component:thread",
  initializer: initializer,
  after: "store"
});

},{"../../components/thread/root":233,"../../index":302,"../../utils/routed-component":390}],320:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = initializer;
exports.getListOptions = getListOptions;

var _root = require("../../components/threads/root");

var _index = require("../../index");

var _index2 = _interopRequireDefault(_index);

var _routedComponent = require("../../utils/routed-component");

var _routedComponent2 = _interopRequireDefault(_routedComponent);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var PRIVATE_THREADS_LIST = "misago:private-threads";

function initializer(context) {
  if (context.has("THREADS") && context.has("CATEGORIES")) {
    (0, _routedComponent2.default)({
      paths: (0, _root.paths)(context.get("user"), getListOptions(context))
    });
  }
}

function getListOptions(context) {
  var currentLink = context.get("CURRENT_LINK");
  if (currentLink.substr(0, PRIVATE_THREADS_LIST.length) === PRIVATE_THREADS_LIST) {
    return {
      api: context.get("PRIVATE_THREADS_API"),
      startThread: {
        mode: "START_PRIVATE",
        submit: _index2.default.get("PRIVATE_THREADS_API")
      },
      title: gettext("Private threads"),
      pageLead: gettext("Private threads are threads which only those that started them and those they have invited may see and participate in."),
      emptyMessage: gettext("You aren't participating in any private threads.")
    };
  }

  return {
    api: context.get("THREADS_API")
  };
}

_index2.default.addInitializer({
  name: "component:threads",
  initializer: initializer,
  after: "store"
});

},{"../../components/threads/root":267,"../../index":302,"../../utils/routed-component":390}],321:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = initializer;

var _reactRedux = require("react-redux");

var _index = require("../../index");

var _index2 = _interopRequireDefault(_index);

var _root = require("../../components/user-menu/root");

var _mountComponent = require("../../utils/mount-component");

var _mountComponent2 = _interopRequireDefault(_mountComponent);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function initializer() {
  (0, _mountComponent2.default)((0, _reactRedux.connect)(_root.select)(_root.UserMenu), "user-menu-mount");
  (0, _mountComponent2.default)((0, _reactRedux.connect)(_root.select)(_root.CompactUserMenu), "user-menu-compact-mount");
}

_index2.default.addInitializer({
  name: "component:user-menu",
  initializer: initializer,
  after: "store"
});

},{"../../components/user-menu/root":272,"../../index":302,"../../utils/mount-component":386,"react-redux":"react-redux"}],322:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = initializer;

var _root = require("../../components/users/root");

var _root2 = _interopRequireDefault(_root);

var _index = require("../../index");

var _index2 = _interopRequireDefault(_index);

var _routedComponent = require("../../utils/routed-component");

var _routedComponent2 = _interopRequireDefault(_routedComponent);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function initializer(context) {
  if (context.has("USERS_LISTS")) {
    (0, _routedComponent2.default)({
      root: _index2.default.get("USERS_LIST_URL"),
      component: _root2.default,
      paths: (0, _root.paths)()
    });
  }
}

_index2.default.addInitializer({
  name: "component:users",
  initializer: initializer,
  after: "store"
});

},{"../../components/users/root":298,"../../index":302,"../../utils/routed-component":390}],323:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = initializer;

var _index = require("../index");

var _index2 = _interopRequireDefault(_index);

var _include = require("../services/include");

var _include2 = _interopRequireDefault(_include);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function initializer(context) {
  _include2.default.init(context.get("STATIC_URL"));
}

_index2.default.addInitializer({
  name: "include",
  initializer: initializer
});

},{"../index":302,"../services/include":368}],324:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = initializer;

var _index = require("../index");

var _index2 = _interopRequireDefault(_index);

var _localStorage = require("../services/local-storage");

var _localStorage2 = _interopRequireDefault(_localStorage);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function initializer() {
  _localStorage2.default.init("misago_");
}

_index2.default.addInitializer({
  name: "local-storage",
  initializer: initializer
});

},{"../index":302,"../services/local-storage":369}],325:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = initializer;

var _index = require("../index");

var _index2 = _interopRequireDefault(_index);

var _mobileNavbarDropdown = require("../services/mobile-navbar-dropdown");

var _mobileNavbarDropdown2 = _interopRequireDefault(_mobileNavbarDropdown);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function initializer() {
  var element = document.getElementById("mobile-navbar-dropdown-mount");
  if (element) {
    _mobileNavbarDropdown2.default.init(element);
  }
}

_index2.default.addInitializer({
  name: "dropdown",
  initializer: initializer,
  before: "store"
});

},{"../index":302,"../services/mobile-navbar-dropdown":370}],326:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = initializer;

var _index = require("../index");

var _index2 = _interopRequireDefault(_index);

var _modal = require("../services/modal");

var _modal2 = _interopRequireDefault(_modal);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function initializer() {
  var element = document.getElementById("modal-mount");
  if (element) {
    _modal2.default.init(element);
  }
}

_index2.default.addInitializer({
  name: "modal",
  initializer: initializer,
  before: "store"
});

},{"../index":302,"../services/modal":371}],327:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = initializer;

var _moment = require("moment");

var _moment2 = _interopRequireDefault(_moment);

var _index = require("../index");

var _index2 = _interopRequireDefault(_index);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function initializer() {
  _moment2.default.locale($("html").attr("lang"));
}

_index2.default.addInitializer({
  name: "moment",
  initializer: initializer
});

},{"../index":302,"moment":"moment"}],328:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = initializer;

var _index = require("../index");

var _index2 = _interopRequireDefault(_index);

var _pageTitle = require("../services/page-title");

var _pageTitle2 = _interopRequireDefault(_pageTitle);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function initializer(context) {
  _pageTitle2.default.init(context.get("SETTINGS").forum_index_title, context.get("SETTINGS").forum_name);
}

_index2.default.addInitializer({
  name: "page-title",
  initializer: initializer
});

},{"../index":302,"../services/page-title":373}],329:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = initializer;

var _index = require("../index");

var _index2 = _interopRequireDefault(_index);

var _ajax = require("../services/ajax");

var _ajax2 = _interopRequireDefault(_ajax);

var _snackbar = require("../services/snackbar");

var _snackbar2 = _interopRequireDefault(_snackbar);

var _polls = require("../services/polls");

var _polls2 = _interopRequireDefault(_polls);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function initializer() {
  _polls2.default.init(_ajax2.default, _snackbar2.default);
}

_index2.default.addInitializer({
  name: "polls",
  initializer: initializer
});

},{"../index":302,"../services/ajax":365,"../services/polls":374,"../services/snackbar":376}],330:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = initializer;

var _index = require("../index");

var _index2 = _interopRequireDefault(_index);

var _ajax = require("../services/ajax");

var _ajax2 = _interopRequireDefault(_ajax);

var _posting = require("../services/posting");

var _posting2 = _interopRequireDefault(_posting);

var _snackbar = require("../services/snackbar");

var _snackbar2 = _interopRequireDefault(_snackbar);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function initializer() {
  _posting2.default.init(_ajax2.default, _snackbar2.default, document.getElementById("posting-placeholder"));
}

_index2.default.addInitializer({
  name: "posting",
  initializer: initializer
});

},{"../index":302,"../services/ajax":365,"../services/posting":375,"../services/snackbar":376}],331:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = initializer;

var _index = require("../../index");

var _index2 = _interopRequireDefault(_index);

var _auth = require("../../reducers/auth");

var _auth2 = _interopRequireDefault(_auth);

var _store = require("../../services/store");

var _store2 = _interopRequireDefault(_store);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function initializer(context) {
  _store2.default.addReducer("auth", _auth2.default, Object.assign({
    isAuthenticated: context.get("isAuthenticated"),
    isAnonymous: !context.get("isAuthenticated"),

    user: context.get("user")
  }, _auth.initialState));
}

_index2.default.addInitializer({
  name: "reducer:auth",
  initializer: initializer,
  before: "store"
});

},{"../../index":302,"../../reducers/auth":350,"../../services/store":377}],332:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = initializer;

var _index = require("../../index");

var _index2 = _interopRequireDefault(_index);

var _participants = require("../../reducers/participants");

var _participants2 = _interopRequireDefault(_participants);

var _store = require("../../services/store");

var _store2 = _interopRequireDefault(_store);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function initializer() {
  var initialState = null;
  if (_index2.default.has("THREAD")) {
    initialState = _index2.default.get("THREAD").participants;
  }

  _store2.default.addReducer("participants", _participants2.default, initialState || []);
}

_index2.default.addInitializer({
  name: "reducer:participants",
  initializer: initializer,
  before: "store"
});

},{"../../index":302,"../../reducers/participants":351,"../../services/store":377}],333:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = initializer;

var _index = require("../../index");

var _index2 = _interopRequireDefault(_index);

var _poll = require("../../reducers/poll");

var _poll2 = _interopRequireDefault(_poll);

var _store = require("../../services/store");

var _store2 = _interopRequireDefault(_store);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function initializer() {
  var initialState = null;
  if (_index2.default.has("THREAD") && _index2.default.get("THREAD").poll) {
    initialState = (0, _poll.hydrate)(_index2.default.get("THREAD").poll);
  } else {
    initialState = {
      isBusy: false
    };
  }

  _store2.default.addReducer("poll", _poll2.default, initialState);
}

_index2.default.addInitializer({
  name: "reducer:poll",
  initializer: initializer,
  before: "store"
});

},{"../../index":302,"../../reducers/poll":352,"../../services/store":377}],334:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = initializer;

var _index = require("../../index");

var _index2 = _interopRequireDefault(_index);

var _posts = require("../../reducers/posts");

var _posts2 = _interopRequireDefault(_posts);

var _store = require("../../services/store");

var _store2 = _interopRequireDefault(_store);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function initializer() {
  var initialState = null;
  if (_index2.default.has("POSTS")) {
    initialState = (0, _posts.hydrate)(_index2.default.get("POSTS"));
  } else {
    initialState = {
      isLoaded: false,
      isBusy: false
    };
  }

  _store2.default.addReducer("posts", _posts2.default, initialState);
}

_index2.default.addInitializer({
  name: "reducer:posts",
  initializer: initializer,
  before: "store"
});

},{"../../index":302,"../../reducers/posts":354,"../../services/store":377}],335:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = initializer;

var _index = require("../../index");

var _index2 = _interopRequireDefault(_index);

var _profileDetails = require("../../reducers/profile-details");

var _profileDetails2 = _interopRequireDefault(_profileDetails);

var _store = require("../../services/store");

var _store2 = _interopRequireDefault(_store);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function initializer() {
  var initialState = null;
  if (_index2.default.has("PROFILE_DETAILS")) {
    initialState = _index2.default.get("PROFILE_DETAILS");
  }

  _store2.default.addReducer("profile-details", _profileDetails2.default, initialState || {});
}

_index2.default.addInitializer({
  name: "reducer:profile-details",
  initializer: initializer,
  before: "store"
});

},{"../../index":302,"../../reducers/profile-details":355,"../../services/store":377}],336:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = initializer;

var _index = require("../../index");

var _index2 = _interopRequireDefault(_index);

var _profile = require("../../reducers/profile");

var _store = require("../../services/store");

var _store2 = _interopRequireDefault(_store);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function initializer() {
  if (_index2.default.has("PROFILE")) {
    _store2.default.dispatch((0, _profile.hydrate)(_index2.default.get("PROFILE")));
  }
}

_index2.default.addInitializer({
  name: "reducer:profile-hydrate",
  initializer: initializer,
  after: "store"
});

},{"../../index":302,"../../reducers/profile":356,"../../services/store":377}],337:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = initializer;

var _index = require("../../index");

var _index2 = _interopRequireDefault(_index);

var _profile = require("../../reducers/profile");

var _profile2 = _interopRequireDefault(_profile);

var _store = require("../../services/store");

var _store2 = _interopRequireDefault(_store);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function initializer() {
  _store2.default.addReducer("profile", _profile2.default, {});
}

_index2.default.addInitializer({
  name: "reducer:profile",
  initializer: initializer,
  before: "store"
});

},{"../../index":302,"../../reducers/profile":356,"../../services/store":377}],338:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = initializer;

var _ = require("../..");

var _2 = _interopRequireDefault(_);

var _search = require("../../reducers/search");

var _search2 = _interopRequireDefault(_search);

var _store = require("../../services/store");

var _store2 = _interopRequireDefault(_store);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function initializer() {
  _store2.default.addReducer("search", _search2.default, Object.assign({}, _search.initialState, {
    providers: _2.default.get("SEARCH_PROVIDERS") || [],
    query: _2.default.get("SEARCH_QUERY") || ""
  }));
}

_2.default.addInitializer({
  name: "reducer:search",
  initializer: initializer,
  before: "store"
});

},{"../..":302,"../../reducers/search":357,"../../services/store":377}],339:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = initializer;

var _index = require("../../index");

var _index2 = _interopRequireDefault(_index);

var _selection = require("../../reducers/selection");

var _selection2 = _interopRequireDefault(_selection);

var _store = require("../../services/store");

var _store2 = _interopRequireDefault(_store);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function initializer() {
  _store2.default.addReducer("selection", _selection2.default, []);
}

_index2.default.addInitializer({
  name: "reducer:selection",
  initializer: initializer,
  before: "store"
});

},{"../../index":302,"../../reducers/selection":358,"../../services/store":377}],340:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = initializer;

var _index = require("../../index");

var _index2 = _interopRequireDefault(_index);

var _snackbar = require("../../reducers/snackbar");

var _snackbar2 = _interopRequireDefault(_snackbar);

var _store = require("../../services/store");

var _store2 = _interopRequireDefault(_store);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function initializer() {
  _store2.default.addReducer("snackbar", _snackbar2.default, _snackbar.initialState);
}

_index2.default.addInitializer({
  name: "reducer:snackbar",
  initializer: initializer,
  before: "store"
});

},{"../../index":302,"../../reducers/snackbar":359,"../../services/store":377}],341:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = initializer;

var _index = require("../../index");

var _index2 = _interopRequireDefault(_index);

var _thread = require("../../reducers/thread");

var _thread2 = _interopRequireDefault(_thread);

var _store = require("../../services/store");

var _store2 = _interopRequireDefault(_store);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function initializer() {
  var initialState = null;
  if (_index2.default.has("THREAD")) {
    initialState = (0, _thread.hydrate)(_index2.default.get("THREAD"));
  } else {
    initialState = {
      isBusy: false
    };
  }

  _store2.default.addReducer("thread", _thread2.default, initialState);
}

_index2.default.addInitializer({
  name: "reducer:thread",
  initializer: initializer,
  before: "store"
});

},{"../../index":302,"../../reducers/thread":360,"../../services/store":377}],342:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = initializer;

var _index = require("../../index");

var _index2 = _interopRequireDefault(_index);

var _threads = require("../../reducers/threads");

var _threads2 = _interopRequireDefault(_threads);

var _store = require("../../services/store");

var _store2 = _interopRequireDefault(_store);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function initializer() {
  _store2.default.addReducer("threads", _threads2.default, []);
}

_index2.default.addInitializer({
  name: "reducer:threads",
  initializer: initializer,
  before: "store"
});

},{"../../index":302,"../../reducers/threads":361,"../../services/store":377}],343:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = initializer;

var _index = require("../../index");

var _index2 = _interopRequireDefault(_index);

var _tick = require("../../reducers/tick");

var _tick2 = _interopRequireDefault(_tick);

var _store = require("../../services/store");

var _store2 = _interopRequireDefault(_store);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function initializer() {
  _store2.default.addReducer("tick", _tick2.default, _tick.initialState);
}

_index2.default.addInitializer({
  name: "reducer:tick",
  initializer: initializer,
  before: "store"
});

},{"../../index":302,"../../reducers/tick":362,"../../services/store":377}],344:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = initializer;

var _index = require("../../index");

var _index2 = _interopRequireDefault(_index);

var _usernameHistory = require("../../reducers/username-history");

var _usernameHistory2 = _interopRequireDefault(_usernameHistory);

var _store = require("../../services/store");

var _store2 = _interopRequireDefault(_store);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function initializer() {
  _store2.default.addReducer("username-history", _usernameHistory2.default, []);
}

_index2.default.addInitializer({
  name: "reducer:username-history",
  initializer: initializer,
  before: "store"
});

},{"../../index":302,"../../reducers/username-history":363,"../../services/store":377}],345:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = initializer;

var _index = require("../../index");

var _index2 = _interopRequireDefault(_index);

var _users = require("../../reducers/users");

var _users2 = _interopRequireDefault(_users);

var _store = require("../../services/store");

var _store2 = _interopRequireDefault(_store);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function initializer() {
  _store2.default.addReducer("users", _users2.default, []);
}

_index2.default.addInitializer({
  name: "reducer:users",
  initializer: initializer,
  before: "store"
});

},{"../../index":302,"../../reducers/users":364,"../../services/store":377}],346:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = initializer;

var _index = require("../index");

var _index2 = _interopRequireDefault(_index);

var _snackbar = require("../services/snackbar");

var _snackbar2 = _interopRequireDefault(_snackbar);

var _store = require("../services/store");

var _store2 = _interopRequireDefault(_store);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function initializer() {
  _snackbar2.default.init(_store2.default);
}

_index2.default.addInitializer({
  name: "snackbar",
  initializer: initializer,
  after: "store"
});

},{"../index":302,"../services/snackbar":376,"../services/store":377}],347:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = initializer;

var _index = require("../index");

var _index2 = _interopRequireDefault(_index);

var _store = require("../services/store");

var _store2 = _interopRequireDefault(_store);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function initializer() {
  _store2.default.init();
}

_index2.default.addInitializer({
  name: "store",
  initializer: initializer,
  before: "_end"
});

},{"../index":302,"../services/store":377}],348:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = initializer;

var _index = require("../index");

var _index2 = _interopRequireDefault(_index);

var _tick = require("../reducers/tick");

var _store = require("../services/store");

var _store2 = _interopRequireDefault(_store);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var TICK_PERIOD = 50 * 1000; //do the tick every 50s

function initializer() {
  window.setInterval(function () {
    _store2.default.dispatch((0, _tick.doTick)());
  }, TICK_PERIOD);
}

_index2.default.addInitializer({
  name: "tick-start",
  initializer: initializer,
  after: "store"
});

},{"../index":302,"../reducers/tick":362,"../services/store":377}],349:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = initializer;

var _index = require("../index");

var _index2 = _interopRequireDefault(_index);

var _include = require("../services/include");

var _include2 = _interopRequireDefault(_include);

var _zxcvbn = require("../services/zxcvbn");

var _zxcvbn2 = _interopRequireDefault(_zxcvbn);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function initializer() {
  _zxcvbn2.default.init(_include2.default);
}

_index2.default.addInitializer({
  name: "zxcvbn",
  initializer: initializer
});

},{"../index":302,"../services/include":368,"../services/zxcvbn":378}],350:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SIGN_OUT = exports.SIGN_IN = exports.PATCH_USER = exports.initialState = undefined;
exports.patch = patch;
exports.signIn = signIn;
exports.signOut = signOut;
exports.default = auth;

var _users = require("./users");

var initialState = exports.initialState = {
  signedIn: false,
  signedOut: false
};

var PATCH_USER = exports.PATCH_USER = "PATCH_USER";
var SIGN_IN = exports.SIGN_IN = "SIGN_IN";
var SIGN_OUT = exports.SIGN_OUT = "SIGN_OUT";

function patch(patch) {
  return {
    type: PATCH_USER,
    patch: patch
  };
}

function signIn(user) {
  return {
    type: SIGN_IN,
    user: user
  };
}

function signOut() {
  var soft = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

  return {
    type: SIGN_OUT,
    soft: soft
  };
}

function auth() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

  switch (action.type) {
    case PATCH_USER:
      var newState = Object.assign({}, state);
      newState.user = Object.assign({}, state.user, action.patch);
      return newState;

    case SIGN_IN:
      return Object.assign({}, state, {
        signedIn: action.user
      });

    case SIGN_OUT:
      return Object.assign({}, state, {
        isAuthenticated: false,
        isAnonymous: true,
        signedOut: !action.soft
      });

    case _users.UPDATE_AVATAR:
      if (state.isAuthenticated && state.user.id === action.userId) {
        var _newState = Object.assign({}, state);
        _newState.user = Object.assign({}, state.user, {
          avatars: action.avatars
        });
        return _newState;
      }
      return state;

    case _users.UPDATE_USERNAME:
      if (state.isAuthenticated && state.user.id === action.userId) {
        var _newState2 = Object.assign({}, state);
        _newState2.user = Object.assign({}, state.user, {
          username: action.username,
          slug: action.slug
        });
        return _newState2;
      }
      return state;

    default:
      return state;
  }
}

},{"./users":364}],351:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.replace = replace;
exports.default = participants;
var REPLACE_PARTICIPANTS = exports.REPLACE_PARTICIPANTS = "REPLACE_PARTICIPANTS";

function replace(newState) {
  return {
    type: REPLACE_PARTICIPANTS,
    state: newState
  };
}

function participants() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

  switch (action.type) {
    case REPLACE_PARTICIPANTS:
      return action.state;

    default:
      return state;
  }
}

},{}],352:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UPDATE_POLL = exports.REPLACE_POLL = exports.REMOVE_POLL = exports.RELEASE_POLL = exports.BUSY_POLL = undefined;
exports.hydrate = hydrate;
exports.busy = busy;
exports.release = release;
exports.replace = replace;
exports.update = update;
exports.remove = remove;
exports.default = poll;

var _moment = require("moment");

var _moment2 = _interopRequireDefault(_moment);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var BUSY_POLL = exports.BUSY_POLL = "BUSY_POLL";
var RELEASE_POLL = exports.RELEASE_POLL = "RELEASE_POLL";
var REMOVE_POLL = exports.REMOVE_POLL = "REMOVE_POLL";
var REPLACE_POLL = exports.REPLACE_POLL = "REPLACE_POLL";
var UPDATE_POLL = exports.UPDATE_POLL = "UPDATE_POLL";

function hydrate(json) {
  var hasSelectedChoices = false;
  for (var i in json.choices) {
    var choice = json.choices[i];
    if (choice.selected) {
      hasSelectedChoices = true;
      break;
    }
  }

  return Object.assign({}, json, {
    posted_on: (0, _moment2.default)(json.posted_on),

    hasSelectedChoices: hasSelectedChoices,
    endsOn: json.length ? (0, _moment2.default)(json.posted_on).add(json.length, "days") : null,

    isBusy: false
  });
}

function busy() {
  return {
    type: BUSY_POLL
  };
}

function release() {
  return {
    type: RELEASE_POLL
  };
}

function replace(newState) {
  var hydrated = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

  return {
    type: REPLACE_POLL,
    state: hydrated ? newState : hydrate(newState)
  };
}

function update(data) {
  return {
    type: UPDATE_POLL,
    data: data
  };
}

function remove() {
  return {
    type: REMOVE_POLL
  };
}

function poll() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

  switch (action.type) {
    case BUSY_POLL:
      return Object.assign({}, state, { isBusy: true });

    case RELEASE_POLL:
      return Object.assign({}, state, { isBusy: false });

    case REMOVE_POLL:
      return {
        isBusy: false
      };

    case REPLACE_POLL:
      return action.state;

    case UPDATE_POLL:
      return Object.assign({}, state, action.data);

    default:
      return state;
  }
}

},{"moment":"moment"}],353:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PATCH_POST = undefined;
exports.hydrate = hydrate;
exports.hydrateAttachment = hydrateAttachment;
exports.patch = patch;
exports.default = post;

var _moment = require("moment");

var _moment2 = _interopRequireDefault(_moment);

var _users = require("./users");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var PATCH_POST = exports.PATCH_POST = "PATCH_POST";

function hydrate(json) {
  return Object.assign({}, json, {
    posted_on: (0, _moment2.default)(json.posted_on),
    updated_on: (0, _moment2.default)(json.updated_on),
    hidden_on: (0, _moment2.default)(json.hidden_on),

    attachments: json.attachments ? json.attachments.map(hydrateAttachment) : null,
    poster: json.poster ? (0, _users.hydrateUser)(json.poster) : null,

    isSelected: false,
    isBusy: false,
    isDeleted: false
  });
}

function hydrateAttachment(json) {
  return Object.assign({}, json, {
    uploaded_on: (0, _moment2.default)(json.uploaded_on)
  });
}

function patch(post, patch) {
  return {
    type: PATCH_POST,
    post: post,
    patch: patch
  };
}

function post() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

  switch (action.type) {
    case PATCH_POST:
      if (state.id == action.post.id) {
        return Object.assign({}, state, action.patch);
      }
      return state;

    default:
      return state;
  }
}

},{"./users":364,"moment":"moment"}],354:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UPDATE_POSTS = exports.UNLOAD_POSTS = exports.LOAD_POSTS = exports.DESELECT_POSTS = exports.DESELECT_POST = exports.SELECT_POST = exports.APPEND_POSTS = undefined;
exports.select = select;
exports.deselect = deselect;
exports.deselectAll = deselectAll;
exports.hydrate = hydrate;
exports.load = load;
exports.append = append;
exports.unload = unload;
exports.update = update;
exports.default = posts;

var _post = require("./post");

var _post2 = _interopRequireDefault(_post);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var APPEND_POSTS = exports.APPEND_POSTS = "APPEND_POSTS";
var SELECT_POST = exports.SELECT_POST = "SELECT_POST";
var DESELECT_POST = exports.DESELECT_POST = "DESELECT_POST";
var DESELECT_POSTS = exports.DESELECT_POSTS = "DESELECT_POSTS";
var LOAD_POSTS = exports.LOAD_POSTS = "LOAD_POSTS";
var UNLOAD_POSTS = exports.UNLOAD_POSTS = "UNLOAD_POSTS";
var UPDATE_POSTS = exports.UPDATE_POSTS = "UPDATE_POSTS";

function select(post) {
  return {
    type: SELECT_POST,
    post: post
  };
}

function deselect(post) {
  return {
    type: DESELECT_POST,
    post: post
  };
}

function deselectAll() {
  return {
    type: DESELECT_POSTS
  };
}

function hydrate(json) {
  return Object.assign({}, json, {
    results: json.results.map(_post.hydrate),
    isLoaded: true,
    isBusy: false,
    isSelected: false
  });
}

function load(newState) {
  var hydrated = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

  return {
    type: LOAD_POSTS,
    state: hydrated ? newState : hydrate(newState)
  };
}

function append(newState) {
  var hydrated = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

  return {
    type: APPEND_POSTS,
    state: hydrated ? newState : hydrate(newState)
  };
}

function unload() {
  return {
    type: UNLOAD_POSTS
  };
}

function update(newState) {
  return {
    type: UPDATE_POSTS,
    update: newState
  };
}

function posts() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

  switch (action.type) {
    case SELECT_POST:
      var selectedPosts = state.results.map(function (post) {
        if (post.id == action.post.id) {
          return Object.assign({}, post, {
            isSelected: true
          });
        } else {
          return post;
        }
      });

      return Object.assign({}, state, {
        results: selectedPosts
      });

    case DESELECT_POST:
      var deseletedPosts = state.results.map(function (post) {
        if (post.id == action.post.id) {
          return Object.assign({}, post, {
            isSelected: false
          });
        } else {
          return post;
        }
      });

      return Object.assign({}, state, {
        results: deseletedPosts
      });

    case DESELECT_POSTS:
      var deseletedAllPosts = state.results.map(function (post) {
        return Object.assign({}, post, {
          isSelected: false
        });
      });

      return Object.assign({}, state, {
        results: deseletedAllPosts
      });

    case APPEND_POSTS:
      var results = state.results.slice();
      var resultsIds = state.results.map(function (post) {
        return post.id;
      });

      action.state.results.map(function (post) {
        if (resultsIds.indexOf(post.id) === -1) {
          results.push(post);
        }
      });

      return Object.assign({}, action.state, {
        results: results
      });

    case LOAD_POSTS:
      return action.state;

    case UNLOAD_POSTS:
      return Object.assign({}, state, {
        isLoaded: false
      });

    case UPDATE_POSTS:
      return Object.assign({}, state, action.update);

    case _post.PATCH_POST:
      var reducedPosts = state.results.map(function (post) {
        return (0, _post2.default)(post, action);
      });

      return Object.assign({}, state, {
        results: reducedPosts
      });

    default:
      return state;
  }
}

},{"./post":353}],355:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.load = load;
exports.default = details;
var LOAD_DETAILS = exports.LOAD_DETAILS = "LOAD_DETAILS";

function load(newState) {
  return {
    type: LOAD_DETAILS,

    newState: newState
  };
}

function details() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

  switch (action.type) {
    case LOAD_DETAILS:
      return action.newState;

    default:
      return state;
  }
}

},{}],356:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PATCH_PROFILE = exports.HYDRATE_PROFILE = undefined;
exports.hydrate = hydrate;
exports.patch = patch;
exports.default = auth;

var _moment = require("moment");

var _moment2 = _interopRequireDefault(_moment);

var _users = require("./users");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var HYDRATE_PROFILE = exports.HYDRATE_PROFILE = "HYDRATE_PROFILE";
var PATCH_PROFILE = exports.PATCH_PROFILE = "PATCH_PROFILE";

function hydrate(profile) {
  return {
    type: HYDRATE_PROFILE,
    profile: profile
  };
}

function patch(patch) {
  return {
    type: PATCH_PROFILE,
    patch: patch
  };
}

function auth() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

  switch (action.type) {
    case HYDRATE_PROFILE:
      return Object.assign({}, action.profile, {
        joined_on: (0, _moment2.default)(action.profile.joined_on),
        status: (0, _users.hydrateStatus)(action.profile.status)
      });

    case PATCH_PROFILE:
      return Object.assign({}, state, action.patch);

    case _users.UPDATE_AVATAR:
      if (state.id === action.userId) {
        return Object.assign({}, state, {
          avatars: action.avatars
        });
      }
      return state;

    case _users.UPDATE_USERNAME:
      if (state.id === action.userId) {
        return Object.assign({}, state, {
          username: action.username,
          slug: action.slug
        });
      }
      return state;

    default:
      return state;
  }
}

},{"./users":364,"moment":"moment"}],357:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.replace = replace;
exports.update = update;
exports.updateProvider = updateProvider;
exports.default = participants;
var REPLACE_SEARCH = exports.REPLACE_SEARCH = "REPLACE_SEARCH";
var UPDATE_SEARCH = exports.UPDATE_SEARCH = "UPDATE_SEARCH";
var UPDATE_SEARCH_PROVIDER = exports.UPDATE_SEARCH_PROVIDER = "UPDATE_SEARCH_PROVIDER";

var initialState = exports.initialState = {
  isLoading: false,
  query: "",
  providers: []
};

function replace(newState) {
  return {
    type: REPLACE_SEARCH,
    state: {
      isLoading: false,
      providers: newState
    }
  };
}

function update(newState) {
  return {
    type: UPDATE_SEARCH,
    update: newState
  };
}

function updateProvider(provider) {
  return {
    type: UPDATE_SEARCH_PROVIDER,
    provider: provider
  };
}

function participants() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

  switch (action.type) {
    case REPLACE_SEARCH:
      return action.state;

    case UPDATE_SEARCH:
      return Object.assign({}, state, action.update);

    case UPDATE_SEARCH_PROVIDER:
      return Object.assign({}, state, {
        providers: state.providers.map(function (provider) {
          if (provider.id === action.provider.id) {
            return action.provider;
          } else {
            return provider;
          }
        })
      });

    default:
      return state;
  }
}

},{}],358:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SELECT_ITEM = exports.SELECT_NONE = exports.SELECT_ALL = undefined;
exports.all = all;
exports.none = none;
exports.item = item;
exports.default = selection;

var _sets = require("../utils/sets");

var SELECT_ALL = exports.SELECT_ALL = "SELECT_ALL";
var SELECT_NONE = exports.SELECT_NONE = "SELECT_NONE";
var SELECT_ITEM = exports.SELECT_ITEM = "SELECT_ITEM";

function all(itemsIds) {
  return {
    type: SELECT_ALL,
    items: itemsIds
  };
}

function none() {
  return {
    type: SELECT_NONE
  };
}

function item(itemId) {
  return {
    type: SELECT_ITEM,
    item: itemId
  };
}

function selection() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

  switch (action.type) {
    case SELECT_ALL:
      return action.items;

    case SELECT_NONE:
      return [];

    case SELECT_ITEM:
      return (0, _sets.toggle)(state, action.item);

    default:
      return state;
  }
}

},{"../utils/sets":391}],359:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.showSnackbar = showSnackbar;
exports.hideSnackbar = hideSnackbar;
exports.default = snackbar;
var initialState = exports.initialState = {
  type: "info",
  message: "",
  isVisible: false
};

var SHOW_SNACKBAR = exports.SHOW_SNACKBAR = "SHOW_SNACKBAR";
var HIDE_SNACKBAR = exports.HIDE_SNACKBAR = "HIDE_SNACKBAR";

function showSnackbar(message, type) {
  return {
    type: SHOW_SNACKBAR,
    message: message,
    messageType: type
  };
}

function hideSnackbar() {
  return {
    type: HIDE_SNACKBAR
  };
}

function snackbar() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

  if (action.type === SHOW_SNACKBAR) {
    return {
      type: action.messageType,
      message: action.message,
      isVisible: true
    };
  } else if (action.type === HIDE_SNACKBAR) {
    return Object.assign({}, state, {
      isVisible: false
    });
  } else {
    return state;
  }
}

},{}],360:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UPDATE_THREAD_ACL = exports.UPDATE_THREAD = exports.REPLACE_THREAD = exports.RELEASE_THREAD = exports.BUSY_THREAD = undefined;
exports.hydrate = hydrate;
exports.busy = busy;
exports.release = release;
exports.replace = replace;
exports.update = update;
exports.updateAcl = updateAcl;
exports.default = thread;

var _moment = require("moment");

var _moment2 = _interopRequireDefault(_moment);

var _poll = require("./poll");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var BUSY_THREAD = exports.BUSY_THREAD = "BUSY_THREAD";
var RELEASE_THREAD = exports.RELEASE_THREAD = "RELEASE_THREAD";
var REPLACE_THREAD = exports.REPLACE_THREAD = "REPLACE_THREAD";
var UPDATE_THREAD = exports.UPDATE_THREAD = "UPDATE_THREAD";
var UPDATE_THREAD_ACL = exports.UPDATE_THREAD_ACL = "UPDATE_THREAD_ACL";

function hydrate(json) {
  return Object.assign({}, json, {
    started_on: (0, _moment2.default)(json.started_on),
    last_post_on: (0, _moment2.default)(json.last_post_on),
    best_answer_marked_on: json.best_answer_marked_on ? (0, _moment2.default)(json.best_answer_marked_on) : null,

    isBusy: false
  });
}

function busy() {
  return {
    type: BUSY_THREAD
  };
}

function release() {
  return {
    type: RELEASE_THREAD
  };
}

function replace(newState) {
  var hydrated = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

  return {
    type: REPLACE_THREAD,
    state: hydrated ? newState : hydrate(newState)
  };
}

function update(data) {
  return {
    type: UPDATE_THREAD,
    data: data
  };
}

function updateAcl(data) {
  return {
    type: UPDATE_THREAD_ACL,
    data: data
  };
}

function thread() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

  switch (action.type) {
    case BUSY_THREAD:
      return Object.assign({}, state, { isBusy: true });

    case RELEASE_THREAD:
      return Object.assign({}, state, { isBusy: false });

    case _poll.REMOVE_POLL:
      return Object.assign({}, state, { poll: null });

    case _poll.REPLACE_POLL:
      return Object.assign({}, state, { poll: action.state });

    case REPLACE_THREAD:
      return action.state;

    case UPDATE_THREAD:
      return Object.assign({}, state, action.data);

    case UPDATE_THREAD_ACL:
      var acl = Object.assign({}, state.acl, action.data);
      return Object.assign({}, state, { acl: acl });

    default:
      return state;
  }
}

},{"./poll":352,"moment":"moment"}],361:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MODERATION_PERMISSIONS = exports.SORT_THREADS = exports.PATCH_THREAD = exports.HYDRATE_THREADS = exports.FILTER_THREADS = exports.DELETE_THREAD = exports.APPEND_THREADS = undefined;
exports.append = append;
exports.deleteThread = deleteThread;
exports.filterThreads = filterThreads;
exports.hydrate = hydrate;
exports.patch = patch;
exports.sort = sort;
exports.getThreadModerationOptions = getThreadModerationOptions;
exports.hydrateThread = hydrateThread;
exports.default = thread;

var _moment = require("moment");

var _moment2 = _interopRequireDefault(_moment);

var _concatUnique = require("../utils/concat-unique");

var _concatUnique2 = _interopRequireDefault(_concatUnique);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var APPEND_THREADS = exports.APPEND_THREADS = "APPEND_THREADS";
var DELETE_THREAD = exports.DELETE_THREAD = "DELETE_THREAD";
var FILTER_THREADS = exports.FILTER_THREADS = "FILTER_THREADS";
var HYDRATE_THREADS = exports.HYDRATE_THREADS = "HYDRATE_THREADS";
var PATCH_THREAD = exports.PATCH_THREAD = "PATCH_THREAD";
var SORT_THREADS = exports.SORT_THREADS = "SORT_THREADS";

var MODERATION_PERMISSIONS = exports.MODERATION_PERMISSIONS = ["can_announce", "can_approve", "can_close", "can_hide", "can_move", "can_merge", "can_pin", "can_review"];

function append(items, sorting) {
  return {
    type: APPEND_THREADS,
    items: items,
    sorting: sorting
  };
}

function deleteThread(thread) {
  return {
    type: DELETE_THREAD,
    thread: thread
  };
}

function filterThreads(category, categoriesMap) {
  return {
    type: FILTER_THREADS,
    category: category,
    categoriesMap: categoriesMap
  };
}

function hydrate(items) {
  return {
    type: HYDRATE_THREADS,
    items: items
  };
}

function patch(thread, patch) {
  var sorting = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;

  return {
    type: PATCH_THREAD,
    thread: thread,
    patch: patch,
    sorting: sorting
  };
}

function sort(sorting) {
  return {
    type: SORT_THREADS,
    sorting: sorting
  };
}

function getThreadModerationOptions(thread_acl) {
  var options = [];
  MODERATION_PERMISSIONS.forEach(function (perm) {
    if (thread_acl[perm]) {
      options.push(perm);
    }
  });
  return options;
}

function hydrateThread(thread) {
  return Object.assign({}, thread, {
    started_on: (0, _moment2.default)(thread.started_on),
    last_post_on: (0, _moment2.default)(thread.last_post_on),
    moderation: getThreadModerationOptions(thread.acl)
  });
}

function thread() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

  switch (action.type) {
    case APPEND_THREADS:
      var mergedState = (0, _concatUnique2.default)(action.items.map(hydrateThread), state);
      return mergedState.sort(action.sorting);

    case DELETE_THREAD:
      return state.filter(function (item) {
        return item.id !== action.thread.id;
      });

    case FILTER_THREADS:
      return state.filter(function (item) {
        var itemCategory = action.categoriesMap[item.category];
        if (itemCategory.lft >= action.category.lft && itemCategory.rght <= action.category.rght) {
          // same or sub category
          return true;
        } else if (item.weight == 2) {
          // globally pinned
          return true;
        } else {
          // thread moved outside displayed scope, hide it
          return false;
        }
      });

    case HYDRATE_THREADS:
      return action.items.map(hydrateThread);

    case PATCH_THREAD:
      var patchedState = state.map(function (item) {
        if (item.id === action.thread.id) {
          return Object.assign({}, item, action.patch);
        } else {
          return item;
        }
      });

      if (action.sorting) {
        return patchedState.sort(action.sorting);
      }
      return patchedState;

    case SORT_THREADS:
      return state.sort(action.sorting);

    default:
      return state;
  }
}

},{"../utils/concat-unique":381,"moment":"moment"}],362:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.doTick = doTick;
exports.default = tick;
var initialState = exports.initialState = {
  tick: 0
};

var TICK = exports.TICK = "TICK";

function doTick() {
  return {
    type: TICK
  };
}

function tick() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

  if (action.type === TICK) {
    return Object.assign({}, state, {
      tick: state.tick + 1
    });
  } else {
    return state;
  }
}

},{}],363:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.HYDRATE_HISTORY = exports.APPEND_HISTORY = exports.ADD_NAME_CHANGE = undefined;
exports.addNameChange = addNameChange;
exports.append = append;
exports.hydrate = hydrate;
exports.hydrateNamechange = hydrateNamechange;
exports.default = username;

var _moment = require("moment");

var _moment2 = _interopRequireDefault(_moment);

var _users = require("./users");

var _concatUnique = require("../utils/concat-unique");

var _concatUnique2 = _interopRequireDefault(_concatUnique);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var ADD_NAME_CHANGE = exports.ADD_NAME_CHANGE = "ADD_NAME_CHANGE";
var APPEND_HISTORY = exports.APPEND_HISTORY = "APPEND_HISTORY";
var HYDRATE_HISTORY = exports.HYDRATE_HISTORY = "HYDRATE_HISTORY";

function addNameChange(change, user, changedBy) {
  return {
    type: ADD_NAME_CHANGE,
    change: change,
    user: user,
    changedBy: changedBy
  };
}

function append(items) {
  return {
    type: APPEND_HISTORY,
    items: items
  };
}

function hydrate(items) {
  return {
    type: HYDRATE_HISTORY,
    items: items
  };
}

function hydrateNamechange(namechange) {
  return Object.assign({}, namechange, {
    changed_on: (0, _moment2.default)(namechange.changed_on)
  });
}

function username() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

  switch (action.type) {
    case ADD_NAME_CHANGE:
      var newState = state.slice();
      newState.unshift({
        id: Math.floor(Date.now() / 1000), // just small hax for getting id
        changed_by: action.changedBy,
        changed_by_username: action.changedBy.username,
        changed_on: (0, _moment2.default)(),
        new_username: action.change.username,
        old_username: action.user.username
      });
      return newState;

    case APPEND_HISTORY:
      return (0, _concatUnique2.default)(state, action.items.map(hydrateNamechange));

    case HYDRATE_HISTORY:
      return action.items.map(hydrateNamechange);

    case _users.UPDATE_AVATAR:
      return state.map(function (item) {
        item = Object.assign({}, item);
        if (item.changed_by && item.changed_by.id === action.userId) {
          item.changed_by = Object.assign({}, item.changed_by, {
            avatars: action.avatars
          });
        }

        return item;
      });

    case _users.UPDATE_USERNAME:
      return state.map(function (item) {
        item = Object.assign({}, item);
        if (item.changed_by && item.changed_by.id === action.userId) {
          item.changed_by = Object.assign({}, item.changed_by, {
            username: action.username,
            slug: action.slug
          });
        }

        return Object.assign({}, item);
      });

    default:
      return state;
  }
}

},{"../utils/concat-unique":381,"./users":364,"moment":"moment"}],364:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UPDATE_USERNAME = exports.UPDATE_AVATAR = exports.HYDRATE_USERS = exports.APPEND_USERS = undefined;
exports.append = append;
exports.hydrate = hydrate;
exports.hydrateStatus = hydrateStatus;
exports.hydrateUser = hydrateUser;
exports.updateAvatar = updateAvatar;
exports.updateUsername = updateUsername;
exports.default = user;

var _moment = require("moment");

var _moment2 = _interopRequireDefault(_moment);

var _concatUnique = require("../utils/concat-unique");

var _concatUnique2 = _interopRequireDefault(_concatUnique);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var APPEND_USERS = exports.APPEND_USERS = "APPEND_USERS";
var HYDRATE_USERS = exports.HYDRATE_USERS = "HYDRATE_USERS";
var UPDATE_AVATAR = exports.UPDATE_AVATAR = "UPDATE_AVATAR";
var UPDATE_USERNAME = exports.UPDATE_USERNAME = "UPDATE_USERNAME";

function append(items) {
  return {
    type: APPEND_USERS,
    items: items
  };
}

function hydrate(items) {
  return {
    type: HYDRATE_USERS,
    items: items
  };
}

function hydrateStatus(status) {
  if (status) {
    return Object.assign({}, status, {
      last_click: status.last_click ? (0, _moment2.default)(status.last_click) : null,
      banned_until: status.banned_until ? (0, _moment2.default)(status.banned_until) : null
    });
  } else {
    return null;
  }
}

function hydrateUser(user) {
  return Object.assign({}, user, {
    joined_on: (0, _moment2.default)(user.joined_on),
    status: hydrateStatus(user.status)
  });
}

function updateAvatar(user, avatars) {
  return {
    type: UPDATE_AVATAR,
    userId: user.id,
    avatars: avatars
  };
}

function updateUsername(user, username, slug) {
  return {
    type: UPDATE_USERNAME,
    userId: user.id,
    username: username,
    slug: slug
  };
}

function user() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

  switch (action.type) {
    case APPEND_USERS:
      return (0, _concatUnique2.default)(state, action.items.map(hydrateUser));

    case HYDRATE_USERS:
      return action.items.map(hydrateUser);

    case UPDATE_AVATAR:
      return state.map(function (item) {
        item = Object.assign({}, item);
        if (item.id === action.userId) {
          item.avatars = action.avatars;
        }

        return item;
      });

    default:
      return state;
  }
}

},{"../utils/concat-unique":381,"moment":"moment"}],365:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var Ajax = exports.Ajax = function () {
  function Ajax() {
    _classCallCheck(this, Ajax);

    this._cookieName = null;
    this._csrfToken = null;
    this._locks = {};
  }

  _createClass(Ajax, [{
    key: "init",
    value: function init(cookieName) {
      this._cookieName = cookieName;
    }
  }, {
    key: "getCsrfToken",
    value: function getCsrfToken() {
      if (document.cookie.indexOf(this._cookieName) !== -1) {
        var cookieRegex = new RegExp(this._cookieName + "=([^;]*)");
        var cookie = document.cookie.match(cookieRegex)[0];
        return cookie ? cookie.split("=")[1] : null;
      } else {
        return null;
      }
    }
  }, {
    key: "request",
    value: function request(method, url, data) {
      var self = this;
      return new Promise(function (resolve, reject) {
        var xhr = {
          url: url,
          method: method,
          headers: {
            "X-CSRFToken": self.getCsrfToken()
          },

          data: data ? JSON.stringify(data) : null,
          contentType: "application/json; charset=utf-8",
          dataType: "json",

          success: function success(data) {
            resolve(data);
          },

          error: function error(jqXHR) {
            var rejection = jqXHR.responseJSON || {};

            rejection.status = jqXHR.status;

            if (rejection.status === 0) {
              rejection.detail = gettext("Lost connection with application.");
            }

            if (rejection.status === 404) {
              if (!rejection.detail || rejection.detail === "NOT FOUND") {
                rejection.detail = gettext("Action link is invalid.");
              }
            }

            if (rejection.status === 500 && !rejection.detail) {
              rejection.detail = gettext("Unknown error has occured.");
            }

            rejection.statusText = jqXHR.statusText;

            reject(rejection);
          }
        };

        $.ajax(xhr);
      });
    }
  }, {
    key: "get",
    value: function get(url, params, lock) {
      if (params) {
        url += "?" + $.param(params);
      }

      if (lock) {
        var self = this;

        // update url in existing lock?
        if (this._locks[lock]) {
          this._locks[lock].url = url;
        }

        // immediately dereference promise handlers without doing anything
        // we are already waiting for existing response to resolve
        if (this._locks[lock] && this._locks[lock].waiter) {
          return {
            then: function then() {
              return;
            }

            // return promise that will begin when original one resolves
          };
        } else if (this._locks[lock] && this._locks[lock].wait) {
          this._locks[lock].waiter = true;

          return new Promise(function (resolve, reject) {
            var wait = function wait(url) {
              // keep waiting on promise
              if (self._locks[lock].wait) {
                window.setTimeout(function () {
                  wait(url);
                }, 300);

                // poll for new url
              } else if (self._locks[lock].url !== url) {
                wait(self._locks[lock].url);

                // ajax backend for response
              } else {
                self._locks[lock].waiter = false;
                self.request("GET", self._locks[lock].url).then(function (data) {
                  if (self._locks[lock].url === url) {
                    resolve(data);
                  } else {
                    self._locks[lock].waiter = true;
                    wait(self._locks[lock].url);
                  }
                }, function (rejection) {
                  if (self._locks[lock].url === url) {
                    reject(rejection);
                  } else {
                    self._locks[lock].waiter = true;
                    wait(self._locks[lock].url);
                  }
                });
              }
            };

            window.setTimeout(function () {
              wait(url);
            }, 300);
          });

          // setup new lock without waiter
        } else {
          this._locks[lock] = {
            url: url,
            wait: true,
            waiter: false
          };

          return new Promise(function (resolve, reject) {
            self.request("GET", url).then(function (data) {
              self._locks[lock].wait = false;
              if (self._locks[lock].url === url) {
                resolve(data);
              }
            }, function (rejection) {
              self._locks[lock].wait = false;
              if (self._locks[lock].url === url) {
                reject(rejection);
              }
            });
          });
        }
      } else {
        return this.request("GET", url);
      }
    }
  }, {
    key: "post",
    value: function post(url, data) {
      return this.request("POST", url, data);
    }
  }, {
    key: "patch",
    value: function patch(url, data) {
      return this.request("PATCH", url, data);
    }
  }, {
    key: "put",
    value: function put(url, data) {
      return this.request("PUT", url, data);
    }
  }, {
    key: "delete",
    value: function _delete(url, data) {
      return this.request("DELETE", url, data);
    }
  }, {
    key: "upload",
    value: function upload(url, data, progress) {
      var self = this;
      return new Promise(function (resolve, reject) {
        var xhr = {
          url: url,
          method: "POST",
          headers: {
            "X-CSRFToken": self.getCsrfToken()
          },

          data: data,
          contentType: false,
          processData: false,

          xhr: function xhr() {
            var xhr = new window.XMLHttpRequest();
            xhr.upload.addEventListener("progress", function (evt) {
              if (evt.lengthComputable) {
                progress(Math.round(evt.loaded / evt.total * 100));
              }
            }, false);
            return xhr;
          },

          success: function success(response) {
            resolve(response);
          },

          error: function error(jqXHR) {
            var rejection = jqXHR.responseJSON || {};

            rejection.status = jqXHR.status;

            if (rejection.status === 0) {
              rejection.detail = gettext("Lost connection with application.");
            }

            if (rejection.status === 413 && !rejection.detail) {
              rejection.detail = gettext("Upload was rejected by server as too large.");
            }

            if (rejection.status === 404) {
              if (!rejection.detail || rejection.detail === "NOT FOUND") {
                rejection.detail = gettext("Action link is invalid.");
              }
            }

            if (rejection.status === 500 && !rejection.detail) {
              rejection.detail = gettext("Unknown error has occured.");
            }

            rejection.statusText = jqXHR.statusText;

            reject(rejection);
          }
        };

        $.ajax(xhr);
      });
    }
  }]);

  return Ajax;
}();

exports.default = new Ajax();

},{}],366:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Auth = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _auth = require("../reducers/auth");

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var Auth = exports.Auth = function () {
  function Auth() {
    _classCallCheck(this, Auth);
  }

  _createClass(Auth, [{
    key: "init",
    value: function init(store, local, modal) {
      this._store = store;
      this._local = local;
      this._modal = modal;

      // tell other tabs what auth state is because we are most current with it
      this.syncSession();

      // listen for other tabs to tell us that state changed
      this.watchState();
    }
  }, {
    key: "syncSession",
    value: function syncSession() {
      var state = this._store.getState().auth;
      if (state.isAuthenticated) {
        this._local.set("auth", {
          isAuthenticated: true,
          username: state.user.username
        });
      } else {
        this._local.set("auth", {
          isAuthenticated: false
        });
      }
    }
  }, {
    key: "watchState",
    value: function watchState() {
      var _this = this;

      var state = this._store.getState().auth;
      this._local.watch("auth", function (newState) {
        if (newState.isAuthenticated) {
          _this._store.dispatch((0, _auth.signIn)({
            username: newState.username
          }));
        } else if (state.isAuthenticated) {
          // check if we are authenticated in this tab
          // because some browser plugins prune local store
          // aggressively, forcing erroneous message to display here
          // tracking bug #955
          _this._store.dispatch((0, _auth.signOut)());
        }
      });
      this._modal.hide();
    }
  }, {
    key: "signIn",
    value: function signIn(user) {
      this._store.dispatch((0, _auth.signIn)(user));
      this._local.set("auth", {
        isAuthenticated: true,
        username: user.username
      });
      this._modal.hide();
    }
  }, {
    key: "signOut",
    value: function signOut() {
      this._store.dispatch((0, _auth.signOut)());
      this._local.set("auth", {
        isAuthenticated: false
      });
      this._modal.hide();
    }
  }, {
    key: "softSignOut",
    value: function softSignOut() {
      this._store.dispatch((0, _auth.signOut)(true));
      this._local.set("auth", {
        isAuthenticated: false
      });
      this._modal.hide();
    }
  }]);

  return Auth;
}();

exports.default = new Auth();

},{"../reducers/auth":350}],367:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Captcha = exports.ReCaptcha = exports.ReCaptchaComponent = exports.QACaptcha = exports.NoCaptcha = exports.BaseCaptcha = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }(); /* global grecaptcha */


var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _formGroup = require("../components/form-group");

var _formGroup2 = _interopRequireDefault(_formGroup);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var BaseCaptcha = exports.BaseCaptcha = function () {
  function BaseCaptcha() {
    _classCallCheck(this, BaseCaptcha);
  }

  _createClass(BaseCaptcha, [{
    key: "init",
    value: function init(context, ajax, include, snackbar) {
      this._context = context;
      this._ajax = ajax;
      this._include = include;
      this._snackbar = snackbar;
    }
  }]);

  return BaseCaptcha;
}();

var NoCaptcha = exports.NoCaptcha = function (_BaseCaptcha) {
  _inherits(NoCaptcha, _BaseCaptcha);

  function NoCaptcha() {
    _classCallCheck(this, NoCaptcha);

    return _possibleConstructorReturn(this, (NoCaptcha.__proto__ || Object.getPrototypeOf(NoCaptcha)).apply(this, arguments));
  }

  _createClass(NoCaptcha, [{
    key: "load",
    value: function load() {
      return new Promise(function (resolve) {
        // immediately resolve as we don't have anything to validate
        resolve();
      });
    }
  }, {
    key: "validator",
    value: function validator() {
      return null;
    }
  }, {
    key: "component",
    value: function component() {
      return null;
    }
  }]);

  return NoCaptcha;
}(BaseCaptcha);

var QACaptcha = exports.QACaptcha = function (_BaseCaptcha2) {
  _inherits(QACaptcha, _BaseCaptcha2);

  function QACaptcha() {
    _classCallCheck(this, QACaptcha);

    return _possibleConstructorReturn(this, (QACaptcha.__proto__ || Object.getPrototypeOf(QACaptcha)).apply(this, arguments));
  }

  _createClass(QACaptcha, [{
    key: "load",
    value: function load() {
      var self = this;
      return new Promise(function (resolve, reject) {
        self._ajax.get(self._context.get("CAPTCHA_API")).then(function (data) {
          self.question = data.question;
          self.helpText = data.help_text;
          resolve();
        }, function () {
          self._snackbar.error(gettext("Failed to load CAPTCHA."));
          reject();
        });
      });
    }
  }, {
    key: "validator",
    value: function validator() {
      return [];
    }
  }, {
    key: "component",
    value: function component(kwargs) {
      return _react2.default.createElement(
        _formGroup2.default,
        {
          label: this.question,
          "for": "id_captcha",
          labelClass: kwargs.labelClass || "",
          controlClass: kwargs.controlClass || "",
          validation: kwargs.form.state.errors.captcha,
          helpText: this.helpText || null
        },
        _react2.default.createElement("input", {
          "aria-describedby": "id_captcha_status",
          className: "form-control",
          disabled: kwargs.form.state.isLoading,
          id: "id_captcha",
          onChange: kwargs.form.bindInput("captcha"),
          type: "text",
          value: kwargs.form.state.captcha
        })
      );
    }
  }]);

  return QACaptcha;
}(BaseCaptcha);

var ReCaptchaComponent = exports.ReCaptchaComponent = function (_React$Component) {
  _inherits(ReCaptchaComponent, _React$Component);

  function ReCaptchaComponent() {
    _classCallCheck(this, ReCaptchaComponent);

    return _possibleConstructorReturn(this, (ReCaptchaComponent.__proto__ || Object.getPrototypeOf(ReCaptchaComponent)).apply(this, arguments));
  }

  _createClass(ReCaptchaComponent, [{
    key: "componentDidMount",
    value: function componentDidMount() {
      var _this4 = this;

      grecaptcha.render("recaptcha", {
        sitekey: this.props.siteKey,
        callback: function callback(response) {
          // fire fakey event to binding
          _this4.props.binding({
            target: {
              value: response
            }
          });
        }
      });
    }
  }, {
    key: "render",
    value: function render() {
      return _react2.default.createElement("div", { id: "recaptcha" });
    }
  }]);

  return ReCaptchaComponent;
}(_react2.default.Component);

var ReCaptcha = exports.ReCaptcha = function (_BaseCaptcha3) {
  _inherits(ReCaptcha, _BaseCaptcha3);

  function ReCaptcha() {
    _classCallCheck(this, ReCaptcha);

    return _possibleConstructorReturn(this, (ReCaptcha.__proto__ || Object.getPrototypeOf(ReCaptcha)).apply(this, arguments));
  }

  _createClass(ReCaptcha, [{
    key: "load",
    value: function load() {
      this._include.include("https://www.google.com/recaptcha/api.js", true);

      return new Promise(function (resolve) {
        var wait = function wait() {
          if (typeof grecaptcha === "undefined") {
            window.setTimeout(function () {
              wait();
            }, 200);
          } else {
            resolve();
          }
        };
        wait();
      });
    }
  }, {
    key: "validator",
    value: function validator() {
      return [];
    }
  }, {
    key: "component",
    value: function component(kwargs) {
      return _react2.default.createElement(
        _formGroup2.default,
        {
          label: gettext("Please solve the quick test"),
          "for": "id_captcha",
          labelClass: kwargs.labelClass || "",
          controlClass: kwargs.controlClass || "",
          validation: kwargs.form.state.errors.captcha,
          helpText: gettext("This test helps us prevent automated spam registrations on our site.")
        },
        _react2.default.createElement(ReCaptchaComponent, {
          binding: kwargs.form.bindInput("captcha"),
          siteKey: this._context.get("SETTINGS").recaptcha_site_key
        })
      );
    }
  }]);

  return ReCaptcha;
}(BaseCaptcha);

var Captcha = exports.Captcha = function () {
  function Captcha() {
    _classCallCheck(this, Captcha);
  }

  _createClass(Captcha, [{
    key: "init",
    value: function init(context, ajax, include, snackbar) {
      switch (context.get("SETTINGS").captcha_type) {
        case "no":
          this._captcha = new NoCaptcha();
          break;

        case "qa":
          this._captcha = new QACaptcha();
          break;

        case "re":
          this._captcha = new ReCaptcha();
          break;
      }

      this._captcha.init(context, ajax, include, snackbar);
    }

    // accessors for underlying strategy

  }, {
    key: "load",
    value: function load() {
      return this._captcha.load();
    }
  }, {
    key: "validator",
    value: function validator() {
      return this._captcha.validator();
    }
  }, {
    key: "component",
    value: function component(kwargs) {
      return this._captcha.component(kwargs);
    }
  }]);

  return Captcha;
}();

exports.default = new Captcha();

},{"../components/form-group":55,"react":"react"}],368:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var Include = exports.Include = function () {
  function Include() {
    _classCallCheck(this, Include);
  }

  _createClass(Include, [{
    key: "init",
    value: function init(staticUrl) {
      this._staticUrl = staticUrl;
      this._included = [];
    }
  }, {
    key: "include",
    value: function include(script) {
      var remote = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

      if (this._included.indexOf(script) === -1) {
        this._included.push(script);
        this._include(script, remote);
      }
    }
  }, {
    key: "_include",
    value: function _include(script, remote) {
      $.ajax({
        url: (!remote ? this._staticUrl : "") + script,
        cache: true,
        dataType: "script"
      });
    }
  }]);

  return Include;
}();

exports.default = new Include();

},{}],369:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var storage = window.localStorage;

var LocalStorage = exports.LocalStorage = function () {
  function LocalStorage() {
    _classCallCheck(this, LocalStorage);
  }

  _createClass(LocalStorage, [{
    key: "init",
    value: function init(prefix) {
      var _this = this;

      this._prefix = prefix;
      this._watchers = [];

      window.addEventListener("storage", function (e) {
        var newValueJson = JSON.parse(e.newValue);
        _this._watchers.forEach(function (watcher) {
          if (watcher.key === e.key && e.oldValue !== e.newValue) {
            watcher.callback(newValueJson);
          }
        });
      });
    }
  }, {
    key: "set",
    value: function set(key, value) {
      storage.setItem(this._prefix + key, JSON.stringify(value));
    }
  }, {
    key: "get",
    value: function get(key) {
      var itemString = storage.getItem(this._prefix + key);
      if (itemString) {
        return JSON.parse(itemString);
      } else {
        return null;
      }
    }
  }, {
    key: "watch",
    value: function watch(key, callback) {
      this._watchers.push({
        key: this._prefix + key,
        callback: callback
      });
    }
  }]);

  return LocalStorage;
}();

exports.default = new LocalStorage();

},{}],370:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MobileNavbarDropdown = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _mountComponent = require("../utils/mount-component");

var _mountComponent2 = _interopRequireDefault(_mountComponent);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var MobileNavbarDropdown = exports.MobileNavbarDropdown = function () {
  function MobileNavbarDropdown() {
    _classCallCheck(this, MobileNavbarDropdown);
  }

  _createClass(MobileNavbarDropdown, [{
    key: "init",
    value: function init(element) {
      this._element = element;
      this._component = null;
    }
  }, {
    key: "show",
    value: function show(component) {
      if (this._component === component) {
        this.hide();
      } else {
        this._component = component;
        (0, _mountComponent2.default)(component, this._element.id);
        $(this._element).addClass("open");
      }
    }
  }, {
    key: "showConnected",
    value: function showConnected(name, component) {
      if (this._component === name) {
        this.hide();
      } else {
        this._component = name;
        (0, _mountComponent2.default)(component, this._element.id, true);
        $(this._element).addClass("open");
      }
    }
  }, {
    key: "hide",
    value: function hide() {
      $(this._element).removeClass("open");
      this._component = null;
    }
  }]);

  return MobileNavbarDropdown;
}();

exports.default = new MobileNavbarDropdown();

},{"../utils/mount-component":386}],371:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Modal = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _reactDom = require("react-dom");

var _reactDom2 = _interopRequireDefault(_reactDom);

var _mountComponent = require("../utils/mount-component");

var _mountComponent2 = _interopRequireDefault(_mountComponent);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var Modal = exports.Modal = function () {
  function Modal() {
    _classCallCheck(this, Modal);
  }

  _createClass(Modal, [{
    key: "init",
    value: function init(element) {
      var _this = this;

      this._element = element;

      this._modal = $(element).modal({ show: false });

      this._modal.on("hidden.bs.modal", function () {
        _reactDom2.default.unmountComponentAtNode(_this._element);
      });
    }
  }, {
    key: "show",
    value: function show(component) {
      (0, _mountComponent2.default)(component, this._element.id);
      this._modal.modal("show");
    }
  }, {
    key: "hide",
    value: function hide() {
      this._modal.modal("hide");
    }
  }]);

  return Modal;
}();

exports.default = new Modal();

},{"../utils/mount-component":386,"react-dom":"react-dom"}],372:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

exports.parseYoutubeUrl = parseYoutubeUrl;
exports.cleanUrl = cleanUrl;
exports.getVideoIdFromUrl = getVideoIdFromUrl;

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var ytRegExp = new RegExp("^.*(?:(?:youtu.be/|v/|vi/|u/w/|embed/)|(?:(?:watch)??v(?:i)?=|&v(?:i)?=))([^#&?]*).*");

var OneBox = exports.OneBox = function () {
  function OneBox() {
    var _this = this;

    _classCallCheck(this, OneBox);

    this.render = function (domnode) {
      if (!domnode) return;
      _this.highlightCode(domnode);
      _this.embedYoutubePlayers(domnode);
    };

    this._youtube = {};
  }

  _createClass(OneBox, [{
    key: "highlightCode",
    value: function highlightCode(domnode) {
      var codeblocks = domnode.querySelectorAll("pre>code");
      for (var i = 0; i < codeblocks.length; i++) {
        var code = codeblocks[i];
        hljs.highlightBlock(code);
      }
    }
  }, {
    key: "embedYoutubePlayers",
    value: function embedYoutubePlayers(domnode) {
      var anchors = domnode.querySelectorAll("p>a");
      for (var i = 0; i < anchors.length; i++) {
        var a = anchors[i];
        var p = a.parentNode;
        var onlyChild = p.childNodes.length === 1;

        if (!this._youtube[a.href]) {
          this._youtube[a.href] = parseYoutubeUrl(a.href);
        }

        var youtubeMovie = this._youtube[a.href];
        if (onlyChild && !!youtubeMovie && youtubeMovie.data !== false) {
          this.swapYoutubePlayer(a, youtubeMovie);
        }
      }
    }
  }, {
    key: "swapYoutubePlayer",
    value: function swapYoutubePlayer(element, youtube) {
      var url = "https://www.youtube.com/embed/";
      url += youtube.video;
      url += "?rel=0";
      if (youtube.start) {
        url += "&start=" + youtube.start;
      }

      var player = $('<iframe class="embed-responsive-item" src="' + url + '" allowfullscreen></iframe>');
      $(element).replaceWith(player);
      player.wrap('<div class="embed-responsive embed-responsive-16by9"></div>');
    }
  }]);

  return OneBox;
}();

exports.default = new OneBox();
function parseYoutubeUrl(url) {
  var cleanedUrl = cleanUrl(url);
  var video = getVideoIdFromUrl(cleanedUrl);

  if (!video) return null;

  var start = 0;
  if (cleanedUrl.indexOf("?") > 0) {
    var query = cleanedUrl.substr(cleanedUrl.indexOf("?") + 1);
    var timebit = query.split("&").filter(function (i) {
      return i.substr(0, 2) === "t=";
    })[0];

    if (timebit) {
      var bits = timebit.substr(2).split("m");
      if (bits[0].substr(-1) === "s") {
        start += parseInt(bits[0].substr(0, bits[0].length - 1));
      } else {
        start += parseInt(bits[0]) * 60;
        if (!!bits[1] && bits[1].substr(-1) === "s") {
          start += parseInt(bits[1].substr(0, bits[1].length - 1));
        }
      }
    }
  }

  return {
    start: start,
    video: video
  };
}

function cleanUrl(url) {
  var clean = url;

  if (url.substr(0, 8) === "https://") {
    clean = clean.substr(8);
  } else if (url.substr(0, 7) === "http://") {
    clean = clean.substr(7);
  }

  if (clean.substr(0, 4) === "www.") {
    clean = clean.substr(4);
  }

  return clean;
}

function getVideoIdFromUrl(url) {
  if (url.indexOf("youtu") === -1) return null;

  var video = url.match(ytRegExp);
  if (video) {
    return video[1];
  }
  return null;
}

},{}],373:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var PageTitle = exports.PageTitle = function () {
  function PageTitle() {
    _classCallCheck(this, PageTitle);
  }

  _createClass(PageTitle, [{
    key: "init",
    value: function init(indexTitle, forumName) {
      this._indexTitle = indexTitle;
      this._forumName = forumName;
    }
  }, {
    key: "set",
    value: function set(title) {
      if (!title) {
        document.title = this._indexTitle || this._forumName;
        return;
      }

      if (typeof title === "string") {
        title = { title: title };
      }

      var finalTitle = title.title;

      if (title.page > 1) {
        var pageLabel = interpolate(gettext("page: %(page)s"), {
          page: title.page
        }, true);

        finalTitle += " (" + pageLabel + ")";
      }

      if (title.parent) {
        finalTitle += " | " + title.parent;
      }

      document.title = finalTitle + " | " + this._forumName;
    }
  }]);

  return PageTitle;
}();

exports.default = new PageTitle();

},{}],374:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var Polls = exports.Polls = function () {
  function Polls() {
    _classCallCheck(this, Polls);
  }

  _createClass(Polls, [{
    key: "init",
    value: function init(ajax, snackbar) {
      this._ajax = ajax;
      this._snackbar = snackbar;

      this._polls = {};
    }
  }, {
    key: "start",
    value: function start(kwargs) {
      var _this = this;

      this.stop(kwargs.poll);

      var poolServer = function poolServer() {
        _this._polls[kwargs.poll] = kwargs;

        _this._ajax.get(kwargs.url, kwargs.data || null).then(function (data) {
          if (!_this._polls[kwargs.poll]._stopped) {
            kwargs.update(data);

            _this._polls[kwargs.poll].timeout = window.setTimeout(poolServer, kwargs.frequency);
          }
        }, function (rejection) {
          if (!_this._polls[kwargs.poll]._stopped) {
            if (kwargs.error) {
              kwargs.error(rejection);
            } else {
              _this._snackbar.apiError(rejection);
            }
          }
        });
      };

      if (kwargs.delayed) {
        this._polls[kwargs.poll] = {
          timeout: window.setTimeout(poolServer, kwargs.frequency)
        };
      } else {
        poolServer();
      }
    }
  }, {
    key: "stop",
    value: function stop(pollId) {
      if (this._polls[pollId]) {
        window.clearTimeout(this._polls[pollId].timeout);
        this._polls[pollId]._stopped = true;
      }
    }
  }]);

  return Polls;
}();

exports.default = new Polls();

},{}],375:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Posting = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _reactDom = require("react-dom");

var _reactDom2 = _interopRequireDefault(_reactDom);

var _poll = require("../components/poll");

var _posting = require("../components/posting");

var _posting2 = _interopRequireDefault(_posting);

var _mountComponent = require("../utils/mount-component");

var _mountComponent2 = _interopRequireDefault(_mountComponent);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var Posting = exports.Posting = function () {
  function Posting() {
    var _this = this;

    _classCallCheck(this, Posting);

    this.close = function () {
      if (_this._isOpen && !_this._isClosing) {
        _this._isClosing = true;
        _this._placeholder.removeClass("slide-in");

        window.setTimeout(function () {
          _reactDom2.default.unmountComponentAtNode(document.getElementById("posting-mount"));
          _this._isClosing = false;
          _this._isOpen = false;
        }, 300);
      }
    };
  }

  _createClass(Posting, [{
    key: "init",
    value: function init(ajax, snackbar, placeholder) {
      this._ajax = ajax;
      this._snackbar = snackbar;
      this._placeholder = $(placeholder);

      this._mode = null;

      this._isOpen = false;
      this._isClosing = false;
    }
  }, {
    key: "open",
    value: function open(props) {
      if (this._isOpen === false) {
        this._mode = props.mode;
        this._isOpen = props.submit;
        this._realOpen(props);
      } else if (this._isOpen !== props.submit) {
        var message = gettext("You are already working on other message. Do you want to discard it?");
        if (this._mode == "POLL") {
          message = gettext("You are already working on a poll. Do you want to discard it?");
        }

        var changeForm = confirm(message);
        if (changeForm) {
          this._mode = props.mode;
          this._isOpen = props.submit;
          this._realOpen(props);
        }
      } else if (this._mode == "REPLY" && props.mode == "REPLY") {
        this._realOpen(props);
      }
    }
  }, {
    key: "_realOpen",
    value: function _realOpen(props) {
      if (props.mode == "POLL") {
        (0, _mountComponent2.default)(_react2.default.createElement(_poll.PollForm, props), "posting-mount");
      } else {
        (0, _mountComponent2.default)(_react2.default.createElement(_posting2.default, props), "posting-mount");
      }

      this._placeholder.addClass("slide-in");

      $("html, body").animate({
        scrollTop: this._placeholder.offset().top
      }, 1000);
    }
  }]);

  return Posting;
}();

exports.default = new Posting();

},{"../components/poll":106,"../components/posting":134,"../utils/mount-component":386,"react":"react","react-dom":"react-dom"}],376:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Snackbar = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _snackbar = require("../reducers/snackbar");

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var HIDE_ANIMATION_LENGTH = 300;
var MESSAGE_SHOW_LENGTH = 5000;

var Snackbar = exports.Snackbar = function () {
  function Snackbar() {
    _classCallCheck(this, Snackbar);
  }

  _createClass(Snackbar, [{
    key: "init",
    value: function init(store) {
      this._store = store;
      this._timeout = null;
    }
  }, {
    key: "alert",
    value: function alert(message, type) {
      var _this = this;

      if (this._timeout) {
        window.clearTimeout(this._timeout);
        this._store.dispatch((0, _snackbar.hideSnackbar)());

        this._timeout = window.setTimeout(function () {
          _this._timeout = null;
          _this.alert(message, type);
        }, HIDE_ANIMATION_LENGTH);
      } else {
        this._store.dispatch((0, _snackbar.showSnackbar)(message, type));
        this._timeout = window.setTimeout(function () {
          _this._store.dispatch((0, _snackbar.hideSnackbar)());
          _this._timeout = null;
        }, MESSAGE_SHOW_LENGTH);
      }
    }

    // shorthands for message types

  }, {
    key: "info",
    value: function info(message) {
      this.alert(message, "info");
    }
  }, {
    key: "success",
    value: function success(message) {
      this.alert(message, "success");
    }
  }, {
    key: "warning",
    value: function warning(message) {
      this.alert(message, "warning");
    }
  }, {
    key: "error",
    value: function error(message) {
      this.alert(message, "error");
    }

    // shorthand for api errors

  }, {
    key: "apiError",
    value: function apiError(rejection) {
      var message = rejection.detail;

      if (!message) {
        if (rejection.status === 404) {
          message = gettext("Action link is invalid.");
        } else {
          message = gettext("Unknown error has occured.");
        }
      }

      if (rejection.status === 403 && message === "Permission denied") {
        message = gettext("You don't have permission to perform this action.");
      }

      this.error(message);
    }
  }]);

  return Snackbar;
}();

exports.default = new Snackbar();

},{"../reducers/snackbar":359}],377:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StoreWrapper = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _redux = require("redux");

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var StoreWrapper = exports.StoreWrapper = function () {
  function StoreWrapper() {
    _classCallCheck(this, StoreWrapper);

    this._store = null;
    this._reducers = {};
    this._initialState = {};
  }

  _createClass(StoreWrapper, [{
    key: "addReducer",
    value: function addReducer(name, reducer, initialState) {
      this._reducers[name] = reducer;
      this._initialState[name] = initialState;
    }
  }, {
    key: "init",
    value: function init() {
      this._store = (0, _redux.createStore)((0, _redux.combineReducers)(this._reducers), this._initialState);
    }
  }, {
    key: "getStore",
    value: function getStore() {
      return this._store;
    }

    // Store API

  }, {
    key: "getState",
    value: function getState() {
      return this._store.getState();
    }
  }, {
    key: "dispatch",
    value: function dispatch(action) {
      return this._store.dispatch(action);
    }
  }]);

  return StoreWrapper;
}();

exports.default = new StoreWrapper();

},{"redux":"redux"}],378:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

/* global zxcvbn */
var Zxcvbn = exports.Zxcvbn = function () {
  function Zxcvbn() {
    _classCallCheck(this, Zxcvbn);
  }

  _createClass(Zxcvbn, [{
    key: "init",
    value: function init(include) {
      this._include = include;
      this._isLoaded = false;
    }
  }, {
    key: "scorePassword",
    value: function scorePassword(password, inputs) {
      // 0-4 score, the more the stronger password
      if (this._isLoaded) {
        return zxcvbn(password, inputs).score;
      }

      return 0;
    }
  }, {
    key: "load",
    value: function load() {
      if (!this._isLoaded) {
        this._include.include("misago/js/zxcvbn.js");
        return this._loadingPromise();
      } else {
        return this._loadedPromise();
      }
    }
  }, {
    key: "_loadingPromise",
    value: function _loadingPromise() {
      var self = this;

      return new Promise(function (resolve, reject) {
        var wait = function wait() {
          var tries = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;

          tries += 1;
          if (tries > 200) {
            reject();
          } else if (typeof zxcvbn === "undefined") {
            window.setTimeout(function () {
              wait(tries);
            }, 200);
          } else {
            self._isLoaded = true;
            resolve();
          }
        };
        wait();
      });
    }
  }, {
    key: "_loadedPromise",
    value: function _loadedPromise() {
      // we have already loaded zxcvbn.js, resolve away!
      return new Promise(function (resolve) {
        resolve();
      });
    }
  }]);

  return Zxcvbn;
}();

exports.default = new Zxcvbn();

},{}],379:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (ban, changeState) {
  _reactDom2.default.render(_react2.default.createElement(
    _reactRedux.Provider,
    { store: _store2.default.getStore() },
    _react2.default.createElement(RedrawedBannedPage, {
      message: ban.message,
      expires: ban.expires_on ? (0, _moment2.default)(ban.expires_on) : null
    })
  ), document.getElementById("page-mount"));

  if (typeof changeState === "undefined" || changeState) {
    var forumName = _index2.default.get("SETTINGS").forum_name;
    document.title = gettext("You are banned") + " | " + forumName;
    window.history.pushState({}, "", _index2.default.get("BANNED_URL"));
  }
};

var _moment = require("moment");

var _moment2 = _interopRequireDefault(_moment);

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _reactDom = require("react-dom");

var _reactDom2 = _interopRequireDefault(_reactDom);

var _reactRedux = require("react-redux");

var _bannedPage = require("../components/banned-page");

var _bannedPage2 = _interopRequireDefault(_bannedPage);

var _index = require("../index");

var _index2 = _interopRequireDefault(_index);

var _store = require("../services/store");

var _store2 = _interopRequireDefault(_store);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var select = function select(state) {
  return state.tick;
};

var RedrawedBannedPage = (0, _reactRedux.connect)(select)(_bannedPage2.default);

},{"../components/banned-page":7,"../index":302,"../services/store":377,"moment":"moment","react":"react","react-dom":"react-dom","react-redux":"react-redux"}],380:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (list, rowWidth) {
  var padding = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

  var rows = [];
  var row = [];

  list.forEach(function (element) {
    row.push(element);
    if (row.length === rowWidth) {
      rows.push(row);
      row = [];
    }
  });

  // pad row to required length?
  if (padding !== false && row.length > 0 && row.length < rowWidth) {
    for (var i = row.length; i < rowWidth; i++) {
      row.push(padding);
    }
  }

  if (row.length) {
    rows.push(row);
  }

  return rows;
};

},{}],381:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (a, b) {
  var ids = [];
  return a.concat(b).filter(function (item) {
    if (ids.indexOf(item.id) === -1) {
      ids.push(item.id);
      return true;
    } else {
      return false;
    }
  });
};

},{}],382:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var _class = function () {
  function _class(callback, count) {
    _classCallCheck(this, _class);

    this._callback = callback;
    this._count = count;
  }

  _createClass(_class, [{
    key: "count",
    value: function count() {
      this._count -= 1;
      if (this._count === 0) {
        this._callback();
      }
    }
  }]);

  return _class;
}();

exports.default = _class;

},{}],383:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (text) {
  return text.replace(/[&<>"']/g, function (m) {
    return map[m];
  });
};

var map = {
  "&": "&amp;",
  "<": "&lt;",
  ">": "&gt;",
  '"': "&quot;",
  "'": "&#039;"
};

},{}],384:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (bytes) {
  if (bytes > 1024 * 1024 * 1024) {
    return roundSize(bytes / (1024 * 1024 * 1024)) + " GB";
  } else if (bytes > 1024 * 1024) {
    return roundSize(bytes / (1024 * 1024)) + " MB";
  } else if (bytes > 1024) {
    return roundSize(bytes / 1024) + " KB";
  } else {
    return roundSize(bytes) + " B";
  }
};

exports.roundSize = roundSize;
function roundSize(value) {
  return value.toFixed(1);
}

},{}],385:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (str) {
  return URL_PATTERN.test($.trim(str));
};

var URL_PATTERN = new RegExp("^(https?:\\/\\/)?((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|((\\d{1,3}\\.){3}\\d{1,3}))(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*(\\?[;&a-z\\d%_.~+=-]*)?(\\#[-a-z\\d_]*)?$", "i");

},{}],386:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (Component, rootElementId) {
  var connected = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;

  var rootElement = document.getElementById(rootElementId);

  var finalComponent = Component.props ? Component : _react2.default.createElement(Component, null);

  if (rootElement) {
    if (connected) {
      _reactDom2.default.render(_react2.default.createElement(
        _reactRedux.Provider,
        { store: _store2.default.getStore() },
        finalComponent
      ), rootElement);
    } else {
      _reactDom2.default.render(finalComponent, rootElement);
    }
  }
};

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _reactDom = require("react-dom");

var _reactDom2 = _interopRequireDefault(_reactDom);

var _reactRedux = require("react-redux");

var _store = require("../services/store");

var _store2 = _interopRequireDefault(_store);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

},{"../services/store":377,"react":"react","react-dom":"react-dom","react-redux":"react-redux"}],387:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var OrderedList = function () {
  function OrderedList(items) {
    _classCallCheck(this, OrderedList);

    this.isOrdered = false;
    this._items = items || [];
  }

  _createClass(OrderedList, [{
    key: "add",
    value: function add(key, item, order) {
      this._items.push({
        key: key,
        item: item,

        after: order ? order.after || null : null,
        before: order ? order.before || null : null
      });
    }
  }, {
    key: "get",
    value: function get(key, value) {
      for (var i = 0; i < this._items.length; i++) {
        if (this._items[i].key === key) {
          return this._items[i].item;
        }
      }

      return value;
    }
  }, {
    key: "has",
    value: function has(key) {
      return this.get(key) !== undefined;
    }
  }, {
    key: "values",
    value: function values() {
      var values = [];
      for (var i = 0; i < this._items.length; i++) {
        values.push(this._items[i].item);
      }
      return values;
    }
  }, {
    key: "order",
    value: function order(values_only) {
      if (!this.isOrdered) {
        this._items = this._order(this._items);
        this.isOrdered = true;
      }

      if (values_only || typeof values_only === "undefined") {
        return this.values();
      } else {
        return this._items;
      }
    }
  }, {
    key: "orderedValues",
    value: function orderedValues() {
      return this.order(true);
    }
  }, {
    key: "_order",
    value: function _order(unordered) {
      // Index of unordered items
      var index = [];
      unordered.forEach(function (item) {
        index.push(item.key);
      });

      // Ordered items
      var ordered = [];
      var ordering = [];

      // First pass: register items that
      // don't specify their order
      unordered.forEach(function (item) {
        if (!item.after && !item.before) {
          ordered.push(item);
          ordering.push(item.key);
        }
      });

      // Second pass: register items that
      // specify their before to "_end"
      unordered.forEach(function (item) {
        if (item.before === "_end") {
          ordered.push(item);
          ordering.push(item.key);
        }
      });

      // Third pass: keep iterating items
      // until we hit iterations limit or finish
      // ordering list
      function insertItem(item) {
        var insertAt = -1;
        if (ordering.indexOf(item.key) === -1) {
          if (item.after) {
            insertAt = ordering.indexOf(item.after);
            if (insertAt !== -1) {
              insertAt += 1;
            }
          } else if (item.before) {
            insertAt = ordering.indexOf(item.before);
          }

          if (insertAt !== -1) {
            ordered.splice(insertAt, 0, item);
            ordering.splice(insertAt, 0, item.key);
          }
        }
      }

      var iterations = 200;
      while (iterations > 0 && index.length !== ordering.length) {
        iterations -= 1;
        unordered.forEach(insertItem);
      }

      return ordered;
    }
  }]);

  return OrderedList;
}();

exports.default = OrderedList;

},{}],388:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.int = int;
exports.range = range;
function int(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

function range(min, max) {
  var array = new Array(int(min, max));
  for (var i = 0; i < array.length; i++) {
    array[i] = i;
  }

  return array;
}

},{}],389:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function () {
  window.scrollTo(0, 0);
};

},{}],390:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (options) {
  var routes = {
    component: options.component || null,
    childRoutes: []
  };

  if (options.root) {
    routes.childRoutes = [{
      path: options.root,
      onEnter: function onEnter(nextState, replaceState) {
        replaceState(null, options.paths[0].path);
      }
    }].concat(options.paths);
  } else {
    routes.childRoutes = options.paths;
  }

  _reactDom2.default.render(_react2.default.createElement(
    _reactRedux.Provider,
    { store: _store2.default.getStore() },
    _react2.default.createElement(_reactRouter.Router, { routes: routes, history: _reactRouter.browserHistory })
  ), rootElement);
};

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _reactDom = require("react-dom");

var _reactDom2 = _interopRequireDefault(_reactDom);

var _reactRedux = require("react-redux");

var _reactRouter = require("react-router");

var _store = require("../services/store");

var _store2 = _interopRequireDefault(_store);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var rootElement = document.getElementById("page-mount");

},{"../services/store":377,"react":"react","react-dom":"react-dom","react-redux":"react-redux","react-router":"react-router"}],391:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.push = push;
exports.remove = remove;
exports.toggle = toggle;
function push(array, value) {
  if (array.indexOf(value) === -1) {
    var copy = array.slice();
    copy.push(value);
    return copy;
  } else {
    return array;
  }
}

function remove(array, value) {
  if (array.indexOf(value) >= 0) {
    return array.filter(function (i) {
      return i !== value;
    });
  } else {
    return array;
  }
}

function toggle(array, value) {
  if (array.indexOf(value) === -1) {
    var copy = array.slice();
    copy.push(value);
    return copy;
  } else {
    return array.filter(function (i) {
      return i !== value;
    });
  }
}

},{}],392:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (string, subString) {
  string = (string + "").toLowerCase();
  subString = (subString + "").toLowerCase();

  if (subString.length <= 0) return 0;

  var n = 0;
  var pos = 0;
  var step = subString.length;

  while (true) {
    pos = string.indexOf(subString, pos);
    if (pos >= 0) {
      n += 1;
      pos += step;
    } else {
      break;
    }
  }

  return n;
};

},{}],393:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.required = required;
exports.requiredTermsOfService = requiredTermsOfService;
exports.requiredPrivacyPolicy = requiredPrivacyPolicy;
exports.email = email;
exports.minLength = minLength;
exports.maxLength = maxLength;
exports.usernameMinLength = usernameMinLength;
exports.usernameMaxLength = usernameMaxLength;
exports.usernameContent = usernameContent;
exports.passwordMinLength = passwordMinLength;
var EMAIL = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
var USERNAME = new RegExp("^[0-9a-z]+$", "i");

function required(message) {
  return function (value) {
    if (value === false || value === null || $.trim(value).length === 0) {
      return message || gettext("This field is required.");
    }
  };
}

function requiredTermsOfService(message) {
  var error = gettext("You have to accept the terms of service.");
  return required(message || error);
}

function requiredPrivacyPolicy(message) {
  var error = gettext("You have to accept the privacy policy.");
  return required(message || error);
}

function email(message) {
  return function (value) {
    if (!EMAIL.test(value)) {
      return message || gettext("Enter a valid email address.");
    }
  };
}

function minLength(limitValue, message) {
  return function (value) {
    var returnMessage = "";
    var length = $.trim(value).length;

    if (length < limitValue) {
      if (message) {
        returnMessage = message(limitValue, length);
      } else {
        returnMessage = ngettext("Ensure this value has at least %(limit_value)s character (it has %(show_value)s).", "Ensure this value has at least %(limit_value)s characters (it has %(show_value)s).", limitValue);
      }
      return interpolate(returnMessage, {
        limit_value: limitValue,
        show_value: length
      }, true);
    }
  };
}

function maxLength(limitValue, message) {
  return function (value) {
    var returnMessage = "";
    var length = $.trim(value).length;

    if (length > limitValue) {
      if (message) {
        returnMessage = message(limitValue, length);
      } else {
        returnMessage = ngettext("Ensure this value has at most %(limit_value)s character (it has %(show_value)s).", "Ensure this value has at most %(limit_value)s characters (it has %(show_value)s).", limitValue);
      }
      return interpolate(returnMessage, {
        limit_value: limitValue,
        show_value: length
      }, true);
    }
  };
}

function usernameMinLength(lengthMin) {
  var message = function message(lengthMin) {
    return ngettext("Username must be at least %(limit_value)s character long.", "Username must be at least %(limit_value)s characters long.", lengthMin);
  };
  return minLength(lengthMin, message);
}

function usernameMaxLength(lengthMax) {
  var message = function message(lengthMax) {
    return ngettext("Username cannot be longer than %(limit_value)s character.", "Username cannot be longer than %(limit_value)s characters.", lengthMax);
  };
  return maxLength(lengthMax, message);
}

function usernameContent() {
  return function (value) {
    if (!USERNAME.test($.trim(value))) {
      return gettext("Username can only contain latin alphabet letters and digits.");
    }
  };
}

function passwordMinLength(limitValue) {
  return function (value) {
    var length = value.length;

    if (length < limitValue) {
      var returnMessage = ngettext("Valid password must be at least %(limit_value)s character long.", "Valid password must be at least %(limit_value)s characters long.", limitValue);

      return interpolate(returnMessage, {
        limit_value: limitValue,
        show_value: length
      }, true);
    }
  };
}

},{}]},{},[302,303,304,305,306,323,324,325,326,327,328,329,330,346,347,348,349,307,308,309,310,311,312,313,314,315,316,317,318,319,320,321,322,331,332,333,334,335,336,337,338,339,340,341,342,343,344,345]);
